export default function RegisterModalHeader() {
  return (
    <>
      <p
        style={{
          fontSize: "18px",
          fontWeight: "500",
          textAlign: "flex-start",
          lineHeight: "28px",
          color: "#101828",
        }}
      >
        ¿Desde dónde quieres realizar el pago?
      </p>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "400",
          textAlign: "flex-start",
          lineHeight: "20px",
          color: "#667085",
        }}
      >
        Esta información nos permitirá generar el reporte de conciliación y
        registrar el egreso en tu sistema contable.
      </p>
    </>
  );
}

import { NumericFormat } from "react-number-format";
import { formatLocaleDate } from "utils";

export const accountingReceiptColumns = (modifiedOnChange, isChecked) => [
  {
    sortable: false,
    renderCell: ({ row }) => {
      return (
        <div className="pill-header">
          <label className="lbl-checkbox-header">
            <input
              type="checkbox"
              className="input"
              onChange={() => modifiedOnChange(row)}
              checked={isChecked(row)}
            />
          </label>
        </div>
      );
    },
    width: 38,
  },
  {
    field: "issue_date",
    headerName: "Fecha elaboración",
    sortable: false,
    headerAlign: "center",
    renderCell: ({ row }) => {
      return formatLocaleDate(row.issue_date, "dd/MM/yyyy");
    },
    width: 140,
  },
  {
    field: "erp_third_name",
    headerName: "Nombre del tercero",
    sortable: false,
    headerAlign: "center",
    width: 200,
  },
  {
    field: "registered_amount",
    headerName: "Monto pendiente",
    sortable: false,
    headerAlign: "center",
    renderCell: ({ row }) => {
      return (
        <div class="MuiDataGrid-cellContent" title="67" role="presentation">
          <NumericFormat
            value={row.registered_amount}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      );
    },
    width: 160,
  },
  {
    field: "accounting_receipt_number",
    headerName: "Comprobante",
    sortable: false,
    headerAlign: "center",
    width: 200,
  },
];

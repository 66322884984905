import { IconButton, MenuItem, Select } from "@mui/material";
import { BlockIcon, DeleteIcon } from "../assets";
import { AutocompleteTable, Tooltip } from "commons/components";
import {
  AutocompleteWareHouse,
  InputDescription,
  InputEditNumberRow,
  InputNumberRow,
} from "commons/modals/ExpandCausationModal/components";
import { CheckboxPayana } from "commons/components/CheckboxPayana.js";
import "intro.js/introjs.css";

export const expandCausationColumns = (
  searchProductsOptions,
  taxesIva,
  taxesRete,
  handleOnChangeItem,
  handleDeleteItem,
  getSearchOptionsProducts,
  isCaused,
  selectItem,
  idCellRef,
  handleSelectAllItems,
  hasCompanyWareHouse
) => {
  const columns = [
    {
      field: "product",
      headerName: "Producto",
      sortable: false,
      flex: 1,
      cellClassName: (params) => {
        return params.row.isSelected
          ? "super-app-theme--cell  cell-select"
          : "super-app-theme--cell ";
      },
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            className="multipleSelect"
            ref={idCellRef}
          >
            <CheckboxPayana
              onClick={() => selectItem(params.row.id)}
              checked={params.row.isSelected}
            />
            <AutocompleteTable
              name="product"
              disabled={isCaused}
              options={searchProductsOptions}
              tabIndex={params.tabIndex}
              getOptionLabel={(option) =>
                option?.fname
                  ? `${option?.fcode.trim()} - ${option?.fname.trim()}`
                  : ""
              }
              onChange={(event, option) => {
                event.preventDefault();
                handleOnChangeItem(params, "product", option);
              }}
              onInputChange={(event) => {
                getSearchOptionsProducts("product", event?.target?.value);
              }}
              value={params.value || ""}
              tableConfig={{
                columns: [
                  {
                    headerName: "Código",
                    getText: (option) => option?.fcode,
                  },
                  {
                    headerName: "Descripción",
                    getText: (option) => option?.fname.trim(),
                  },
                ],
                rowClassName: "causation",
                getRowTooltipText: (option) =>
                  `${option?.fcode} - ${option?.fname}`,
              }}
            />
          </div>
        );
      },
      renderHeader: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              padding: "1px",
            }}
          >
            <CheckboxPayana onClick={handleSelectAllItems} />
            <p
              style={{
                fontSize: "12px",
                backgroundColor: "#F2F4F7",
                color: "#101828",
                fontWeight: 600,
                lineHeight: "18px",
                marginBottom: "0px",
              }}
            >
              Producto
            </p>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Descripción",
      sortable: false,
      flex: 1,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              backgroundColor: "#F2F4F7",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Descripción
          </p>
        );
      },
      renderCell: (params) => {
        return (
          <InputDescription
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) =>
              handleOnChangeItem(params, "description", value)
            }
          />
        );
      },
    },
    {
      field: "quantity",
      headerName: "Cant.",
      type: "number",
      editable: !isCaused,
      width: 80,
      sortable: false,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              backgroundColor: "#F2F4F7",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Cant.
          </p>
        );
      },
      renderEditCell: (params) => {
        return (
          <InputEditNumberRow
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) => handleOnChangeItem(params, "quantity", value)}
            formatMoney={false}
            {...params}
          />
        );
      },
    },
    {
      field: "unitValue",
      headerName: "Valor unitario",
      type: "number",
      editable: !isCaused,
      sortable: false,
      width: 160,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              backgroundColor: "#F2F4F7",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Valor unitario
          </p>
        );
      },
      renderCell: (params) => {
        return <InputNumberRow value={params.value} />;
      },
      renderEditCell: (params) => {
        return (
          <InputEditNumberRow
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) => handleOnChangeItem(params, "unitValue", value)}
            formatMoney={true}
            {...params}
          />
        );
      },
    },
    {
      field: "discount",
      headerName: "Descuento",
      type: "number",
      editable: !isCaused,
      sortable: false,
      width: 130,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              backgroundColor: "#F2F4F7",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Descuento
          </p>
        );
      },
      renderCell: (params) => {
        return <InputNumberRow value={params.value} />;
      },
      renderEditCell: (params) => {
        return (
          <InputEditNumberRow
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) => handleOnChangeItem(params, "discount", value)}
            formatMoney={true}
            {...params}
          />
        );
      },
    },
    {
      field: "taxIva",
      headerName: "Imp. Cargo",
      sortable: false,
      width: 115,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              backgroundColor: "#F2F4F7",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Imp. Cargo
          </p>
        );
      },
      cellClassName: (params) => {
        return params.row.isSelected ? "cell-select" : "";
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params?.value?.description}>
            <Select
              disabled={isCaused}
              name="taxIva"
              size="small"
              displayEmpty={true}
              onChange={(e) =>
                handleOnChangeItem(params, "taxIva", e.target.value)
              }
              sx={stylesSelectedTaxs}
              value={params.value.id}
            >
              <MenuItem value={null} style={{ height: "36px" }}>
                {" "}
              </MenuItem>
              {taxesIva.map((tax) => (
                <MenuItem key={tax.id} value={tax.id}>
                  {tax.description}
                </MenuItem>
              ))}
            </Select>
          </Tooltip>
        );
      },
    },
    {
      field: "taxRetefuente",
      headerName: "Imp. Retención",
      sortable: false,
      width: 115,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              backgroundColor: "#F2F4F7",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Imp. Retención
          </p>
        );
      },
      cellClassName: (params) => {
        return params.row.isSelected ? "cell-select" : "";
      },
      renderCell: (params) => (
        <Tooltip title={params?.value?.description}>
          <Select
            disabled={isCaused}
            name="taxRetefuente"
            size="small"
            displayEmpty={true}
            fullWidth
            onChange={(e) =>
              handleOnChangeItem(params, "taxRetefuente", e.target.value)
            }
            value={params.value.id}
            sx={stylesSelectedTaxs}
          >
            <MenuItem value={null} style={{ height: "36px" }}>
              {}
            </MenuItem>
            {taxesRete.map((tax) => (
              <MenuItem key={tax.id} value={tax.id}>
                {tax.description}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      ),
    },
    {
      field: "amountTotal",
      headerName: "Valor Total",
      sortable: false,
      width: 110,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              backgroundColor: "#F2F4F7",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Valor Total
          </p>
        );
      },
      type: "number",
      renderCell: (params) => {
        return <InputNumberRow value={params.value} />;
      },
    },
    {
      field: "deleteItem",
      headerName: "",
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleDeleteItem(params.id)}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  if (hasCompanyWareHouse) {
    const warehouseColumn = {
      field: "warehouse",
      headerName: "Bodega",
      width: 163,
      sortable: false,
      cellClassName: (params) => {
        return params.row?.isSelected &&
          params.row?.product?.is_inventory_control
          ? "super-app-theme--cell  cell-select"
          : "super-app-theme--cell ";
      },
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              backgroundColor: "#F2F4F7",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Bodega
          </p>
        );
      },
      renderCell: (params) => {
        const { is_inventory_control = false } = params.row?.product;

        if (!params.row?.product?.id) {
          return (
            <Tooltip title={"Ingresa el producto primero"}>
              <div
                style={{ height: "100%", width: "100%", cursor: "not-allowed" }}
              ></div>
            </Tooltip>
          );
        }

        return is_inventory_control ? (
          <AutocompleteWareHouse
            params={params}
            handleOnChangeItem={handleOnChangeItem}
          />
        ) : (
          <Tooltip
            title={
              "Este producto no requiere bodega debido a su configuración actual"
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#F2F4F7",
                width: "100%",
                height: "100%",
              }}
            >
              <BlockIcon width={24} height={24} fill="#667085" />
            </div>
          </Tooltip>
        );
      },
    };
    columns.splice(2, 0, warehouseColumn);
  }

  return columns;
};

const stylesSelectedTaxs = {
  fontSize: "13px",
  width: "100%",
  height: "100%",
  "& fieldset": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    display: "none",
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiSelect-select": {
    padding: "0px !important",
    width: "100%",
    height: "100%",
    maxHeight: "50px",
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
};

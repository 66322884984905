export const accountTypes = [
  {
    value: "SAVINGS_ACCOUNT",
    label: "Cuenta de ahorro",
  },
  {
    value: "CHECKING_ACCOUNT",
    label: "Cuenta corriente",
  },
];

export const getAccountTypeLabel = (type) => {
  return accountTypes.find(accountType => accountType.value === type)?.label
}

export const documentTypes = ["NIT", "CC", "CE", "TI", "Pasaporte", "PPT"];

export const sampleSupplierFile =
  "https://tap-payana-col-tbp-invoices-production.s3.amazonaws.com/Template+carga+proveedores.xlsx";

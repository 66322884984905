import { Stack } from "@mui/material";
import React from "react";
import { styles } from "../styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getDateByLocalTimeZone } from "utils";
import { ValidationMessage } from "commons/components";

export function ExpensesDateInput({ paidAt, errors, name, register }) {
  const value = getDateByLocalTimeZone(paidAt);
  const dateElaboration = register(name, { required: true });
  return (
    <Stack spacing={1}>
      <label htmlFor="dateElaboration" style={styles.inputLabel}>
        Fecha de elaboración
      </label>
      <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
        <DatePicker
          id={name}
          name={name}
          format="dd/MM/yyyy"
          slotProps={{
            textField: {
              InputProps: {
                style: { fontSize: 14 },
              },
              size: "small",
              error: false,
            },
          }}
          onChange={(value) => {
            dateElaboration.onChange({
              target: {
                name: name,
                value,
              },
            });
          }}
          value={value}
        />
      </LocalizationProvider>
      <ValidationMessage errors={errors} field={name} />
    </Stack>
  );
}

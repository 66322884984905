import React, { useContext, useState } from "react";
import {
  Box,
  Container,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { styles } from "./styles";
import { PayanaLogo } from "../../assets";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { RegisterUser } from "../../services";
import { UserContext } from "../../contexts/UserContext";
import {
  DocumentNumberInput,
  EmailInput,
  PhoneNumberInput,
  RequiredLabel,
  ValidationMessage,
  getValidationMessage,
} from "../../commons";
import { useForm, useWatch } from "react-hook-form";
import { getPasswordError } from "../../commons/forms/validation";
import { toastOptions } from "../../constants";
import { toast } from "react-toastify";
import { PHONE_NUMBER_VALIDATOR } from "../../utils";
import { PrimaryButton } from "commons/buttons";

export const Signup = () => {
  const navigate = useNavigate();
  const { login } = useContext(UserContext);
  const [confirmPass, setConfirmPass] = useState("");
  const [loading, isLoading] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const utmMedium = queryParameters.get("utm_medium") || "";
  const utmSource = queryParameters.get("utm_source") || "";
  const utmCampaign = queryParameters.get("utm_campaign") || "";
  const utmContent = queryParameters.get("utm_content") || "";
  const hash = queryParameters.get("hash") || "";

  const { register, handleSubmit, formState, control, setError, setValue } =
    useForm({
      defaultValues: {
        name: "",
        email: "",
        password: "",
        companyName: "",
        phone: "",
        identificationNumber: "",
        employeeAmount: "",
        referred: "",
      },
      mode: "onBlur",
    });
  const formValues = useWatch({ control });
  const [phone, setPhone] = useState(formValues.phone);

  const loginUser = () => {
    login(formValues.email, formValues.password).then(() => {
      navigate("/onboarding", { replace: true });
    });
  };

  const signupUser = async () => {
    isLoading(true);
    try {
      const values = {
        ...formValues,
        utm_medium: utmMedium,
        utm_source: utmSource,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        hash: hash,
      };
      const response = await RegisterUser(values);
      if (response.message === "ok") {
        loginUser();
      } else {
        response?.response?.data?.errors?.forEach((error) => {
          setError(error.field, {
            message: getValidationMessage(error.rule) || error.message,
          });
        });
      }
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    } finally {
      isLoading(false);
    }
  };

  const validatePassword = (password) => {
    const error = getPasswordError(password);
    if (error) {
      return error;
    }
    if (confirmPass && password !== confirmPass) {
      return "Las contraseñas no coinciden";
    }
    if (password.length < 8) {
      return "La contraseña debe tener al menos 8 caracteres";
    }
    return true;
  };

  const validateConfirmPassword = (password) => {
    const error = getPasswordError(password);
    if (error) {
      return error;
    }
    if (formValues.password !== confirmPass) {
      return "Las contraseñas no coinciden";
    }
    return true;
  };

  const passwordValidationProps = register("password", {
    required: true,
    validate: validatePassword,
  });

  const confirmPasswordValidationProps = register("confirmPassword", {
    required: true,
    validate: validateConfirmPassword,
  });

  const employeesOptions = [
    {
      value: "0-5",
      label: "0 - 5",
    },
    {
      value: "6-20",
      label: "6 - 20",
    },
    {
      value: "21-50",
      label: "21 - 50",
    },
    {
      value: "+50",
      label: "+ 50",
    },
  ];

  return (
    <Container>
      <form onSubmit={handleSubmit(signupUser)} style={{ paddingBottom: 100 }}>
        <Stack justifyContent="center" alignItems="center" mt={9}>
          <PayanaLogo width={190} height={42} />
          <Typography sx={styles.title} mb={4} mt={4}>
            ¡Comienza gratis!
          </Typography>
          <Stack
            spacing={3}
            sx={{
              width: 360,
            }}
          >
            <Box>
              <RequiredLabel label="Nombre completo" sx={styles.label} />
              <TextField
                {...register("name", { required: true })}
                variant="outlined"
                fullWidth
                placeholder="Ejemplo: José Pérez"
                type="text"
                size="small"
                InputProps={{
                  style: { fontSize: 14 },
                }}
              />
              <ValidationMessage errors={formState.errors} field="name" />
            </Box>
            <Box>
              <RequiredLabel label="Nombre de la empresa" sx={styles.label} />
              <TextField
                {...register("companyName", { required: true })}
                variant="outlined"
                fullWidth
                placeholder="Nombre empresa"
                type="text"
                size="small"
                InputProps={{
                  style: { fontSize: 14 },
                }}
              />
              <ValidationMessage
                errors={formState.errors}
                field="companyName"
              />
            </Box>
            <Stack spacing={1}>
              <DocumentNumberInput
                value={formValues.identificationNumber}
                setValue={(value) => {
                  setValue("identificationNumber", value, {
                    shouldValidate: false,
                  });
                }}
                documentType="NIT"
                register={register}
                errors={formState.errors}
                id="identificationNumber"
              />
            </Stack>
            <Box>
              <RequiredLabel label="Número de teléfono" sx={styles.label} />
              <PhoneNumberInput
                phone={phone}
                setPhone={(value) => {
                  setValue("phone", value, { shouldValidate: false });
                  setPhone(value);
                }}
                {...register("phone", PHONE_NUMBER_VALIDATOR(true))}
              />
              <ValidationMessage errors={formState.errors} field="phone" />
            </Box>
            <Box>
              <RequiredLabel label="Correo electrónico" sx={styles.label} />
              <EmailInput
                value={formValues.email}
                setValue={(value) => {
                  setValue("email", value, { shouldValidate: true });
                }}
                register={register}
              />
              <ValidationMessage errors={formState.errors} field="email" />
            </Box>
            <Box>
              <RequiredLabel label="Contraseña" sx={styles.label} />
              <TextField
                {...passwordValidationProps}
                onChange={(e) => {
                  if (!getPasswordError(e.target.value)) {
                    passwordValidationProps.onChange(e);
                  }
                }}
                value={formValues.password}
                fullWidth
                variant="outlined"
                type="password"
                placeholder="******"
                size="small"
              />
              <ValidationMessage errors={formState.errors} field="password" />
            </Box>
            <Box>
              <RequiredLabel label="Confirmar contraseña" sx={styles.label} />
              <TextField
                {...confirmPasswordValidationProps}
                fullWidth
                variant="outlined"
                type="password"
                placeholder="******"
                size="small"
                onChange={(e) => setConfirmPass(e.target.value)}
              />
              <ValidationMessage
                errors={formState.errors}
                field="confirmPassword"
              />
            </Box>
            <Box>
              <RequiredLabel label="Cantidad de empleados" sx={styles.label} />
              <Select
                size="small"
                variant="outlined"
                style={{ fontSize: "14px", width: "100%" }}
                value={formValues.employeeAmount}
                displayEmpty={true}
                inputProps={{
                  id: "employeeAmount",
                }}
                {...register("employeeAmount", { required: true })}
              >
                <MenuItem disabled value="">
                  Selecciona tamaño
                </MenuItem>
                {employeesOptions.map((option) => {
                  return (
                    <MenuItem
                      key={option.value}
                      style={{ fontSize: "14px" }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <ValidationMessage
                errors={formState.errors}
                field="employeeAmount"
              />
            </Box>
            <Box>
              <RequiredLabel
                label="¿Conoces otra empresa que use Payana?"
                sx={styles.label}
              />
              <Select
                size="small"
                variant="outlined"
                style={{ fontSize: "14px", width: "100%" }}
                value={formValues.referred}
                displayEmpty={true}
                inputProps={{
                  id: "referred",
                }}
                {...register("referred", { required: true })}
              >
                <MenuItem disabled value="">
                  Selecciona una opción
                </MenuItem>
                <MenuItem key={"yes"} style={{ fontSize: "14px" }} value={"Sí"}>
                  Sí
                </MenuItem>
                <MenuItem key={"no"} style={{ fontSize: "14px" }} value={"No"}>
                  No
                </MenuItem>
              </Select>
              <ValidationMessage errors={formState.errors} field="referred" />
            </Box>
            <PrimaryButton
              width="100%"
              isDisable={loading}
              action={handleSubmit(signupUser)}
              text={
                loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Crear cuenta"
                )
              }
            />
            <Stack
              mt={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={styles.footer} mr={1}>
                ¿Ya tienes cuenta?
              </Typography>
              <Link style={{ textDecoration: "none" }} to="/login">
                <Typography sx={styles.labelButton}>Inicia sesión</Typography>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Container>
  );
};

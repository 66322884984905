const primaryButton = {
  backgroundColor: "#6938EF",
  border: "1px solid #6938EF",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontWeight: "500",
  fontSize: "16px",
  padding: "10px 0px",
  "&:focus": {
    border: "none",
  },
};

export const styles = {
  title: {
    fontWeight: "600",
    fontSize: "20px",
    color: "#101828",
    textAlign: "left",
    alignSelf: "flex-start",
  },
  description: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#667085",
    textAlign: "left",
    alignSelf: "flex-start",
  },
  help: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#475467",
    textAlign: "left",
    alignSelf: "flex-start",
  },
  contact: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#5925DC",
  },
  label: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#101828",
  },
  errorLabel: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#ff0000",
  },
  labelButton: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#5925dc",
    "&:hover": {
      cursor: "pointer",
    },
  },
  primaryButton,
  primaryLoadingButton: {
    ...primaryButton,
    padding: "10px 0px 5px 0px",
  },
  terms: {
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    lineHeight: "20px",
    color: "#475467",
    alignSelf: "center",
    width: "100%",
    maxWidth: "614px",
    marginTop: "24px",
  },
};

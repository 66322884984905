export const codeToDocumentType = {
  13: "Cédula de ciudadanía",
  31: "NIT",
  22: "Cédula de extranjería",
  42: "Documento de identificación extranjero",
  50: "NIT de otro país",
  91: "NUIP",
  41: "Pasaporte",
  47: "Permiso especial de permanencia PEP",
  11: "Registro civil",
  43: "Sin identificación del exterior o para uso definido por la DIAN",
  21: "Tarjeta de extranjería",
  12: "Tarjeta de identidad",
};

export const documentTypeToCode = {
  "Cédula de ciudadanía": 13,
  NIT: 31,
  "Cédula de extranjería": 22,
  "Documento de identificación extranjero": 42,
  "NIT de otro país": 50,
  NUIP: 91,
  Pasaporte: 41,
  "Permiso especial de permanencia PEP": 47,
  "Registro civil": 11,
  "Sin identificación del exterior o para uso definido por la DIAN": 43,
  "Tarjeta de extranjería": 21,
  "Tarjeta de identidad": 12,
};

export const codeDocumentsType = [
  { code: 13, description: "Cédula de ciudadanía" },
  { code: 31, description: "NIT" },
  { code: 22, description: "Cédula de extranjería" },
  { code: 42, description: "Documento de identificación extranjero" },
  { code: 50, description: "NIT de otro país" },
  { code: 91, description: "NUIP" },
  { code: 41, description: "Pasaporte" },
  { code: 47, description: "Permiso especial de permanencia PEP" },
  { code: 11, description: "Registro civil" },
  {
    code: 43,
    description:
      "Sin identificación del exterior o para uso definido por la DIAN",
  },
  { code: 21, description: "Tarjeta de extranjería" },
  { code: 12, description: "Tarjeta de identidad" },
];

export const DEFAULT_FEATURE_VALUES = {
  featureId: null,
  featureType: null,
  freeDays: 0,
  freeTrialStartAt: null,
  status: null,
  retention: 0,
  discount: 0,
  price: null,
};

export const TYPE = {
  feature: "feature",
  status: "status",
  discount: "discount",
  freeDays: "freeDays",
  price: "price",
  billing: "billing",
};

export const VIEW = {
  subscription: "subscription",
  billing: "billing",
};

export const organizationTypeItems = [
  { value: "Person", description: "Natural" },
  { value: "Company", description: "Jurídica" },
];

export const stepsBilling = {
  initial: "initial",
  success: "success",
  inactive: "inactive",
  trial: "trial",
  billing: "billing",
  cancelled: "initial",
};

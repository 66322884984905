import React from "react";

export const DataIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g>
      <rect width="32" height="32" rx="16" fill="#F4F4F5" />
      <path
        d="M17.3333 15.3333H13.3333M14.6667 17.9999H13.3333M18.6667 12.6666H13.3333M21.3333 12.5333V19.4666C21.3333 20.5867 21.3333 21.1467 21.1153 21.5746C20.9236 21.9509 20.6176 22.2569 20.2413 22.4486C19.8135 22.6666 19.2534 22.6666 18.1333 22.6666H13.8667C12.7466 22.6666 12.1865 22.6666 11.7587 22.4486C11.3824 22.2569 11.0764 21.9509 10.8846 21.5746C10.6667 21.1467 10.6667 20.5867 10.6667 19.4666V12.5333C10.6667 11.4131 10.6667 10.8531 10.8846 10.4253C11.0764 10.0489 11.3824 9.74299 11.7587 9.55124C12.1865 9.33325 12.7466 9.33325 13.8667 9.33325H18.1333C19.2534 9.33325 19.8135 9.33325 20.2413 9.55124C20.6176 9.74299 20.9236 10.0489 21.1153 10.4253C21.3333 10.8531 21.3333 11.4131 21.3333 12.5333Z"
        stroke="#6938EF"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

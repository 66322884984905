import React from "react";
import { styles } from "../styles";
import { StarIcon } from "assets";
import { Typography } from "@mui/material";

export const IntroBanner = ({
  setShowIntro = () => {},
  setShowFeedback = () => {},
}) => {
  return (
    <div style={styles.introBannerContainer}>
      <div style={{ ...styles.row, gap: "12px" }}>
        <div
          style={{
            width: "24px",
            height: "24px",
            marginRight: "18px",
          }}
        >
          <StarIcon size={"42"} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography sx={styles.introBannerTitle}>
            Descubre el panel de transacciones
          </Typography>
          <Typography sx={styles.introBannerSubtitle}>
            ¿Ya lo conoces? Ayúdanos a mejorar la plataforma{" "}
            <span
              onClick={setShowFeedback}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              dejando tus comentarios aquí
            </span>
            .
          </Typography>
        </div>
      </div>
      <div style={{ ...styles.row, gap: "8px", alignSelf: "center" }}>
        <div style={styles.introBannerAction}>
          <Typography
            style={styles.introBannerLink}
            onClick={() => {
              setShowIntro(true);
            }}
          >
            Explorar
          </Typography>
        </div>
      </div>
    </div>
  );
};

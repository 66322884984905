export const styles = {
  body: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  modalTitle: {
    textAlign: "left",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    alignSelf: "flex-start",
  },
  modalText: {
    color: "#344054",
    textAlign: "left",
    fontWeight: 500,
    margin: "0px",
    alignSelf: "flex-start",
    fontSize: "16px",
  },
  errorModalTitle: {
    textAlign: "left",
    fontSize: "18px",
    fontWeight: 500,
    color: "#101828",
    alignSelf: "flex-start",
    marginTop: "8px",
  },
  errorModalText: {
    fontSize: "16px",
    textAlign: "left",
    fontWeight: 500,
    margin: "0px",
    color: "#667085",
    alignSelf: "flex-start",
  },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    textAlign: "left",
  },
  loading: {
    minHeight: "90px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  buttonGroup: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "flex-end",
    marginTop: "24px",
  },
};

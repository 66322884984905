import React from "react";
import { Stack } from "@mui/material";
import { ApprovedIcon } from "../../../../../assets";
import DrawerFooter from "./DrawerFooter";
import { PrimaryButton, SecondaryButton } from "../../../../buttons";

function DrawerSuccess({
  numberOfProvidersToComplete,
  isNextDisabled,
  onCancel,
  onNext,
}) {
  return (
    <div
      style={{
        maxWidth: 365,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div
        className="animated-element"
        style={{ margin: "auto", textAlign: "center" }}
      >
        <ApprovedIcon />
        <Stack my={2} spacing={2}>
          {numberOfProvidersToComplete === 0 ? (
            <>
              <div
                style={{
                  color: "#101828",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                ¡Listo!
              </div>
              <div
                style={{
                  color: "#101828",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                Todos los clientes seleccionados fueron completados con éxito
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  color: "#101828",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                ¡Excelente!
              </div>
              <div
                style={{
                  color: "#101828",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                Los datos de tu cliente fueron actualizados con éxito.
              </div>
              <div
                style={{
                  color: "#667085",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {numberOfProvidersToComplete === 1
                  ? `Queda 1 cliente para completar.`
                  : `Quedan ${numberOfProvidersToComplete} clientes para completar.`}
              </div>
            </>
          )}
        </Stack>
      </div>
      <DrawerFooter>
        <Stack
          direction="row"
          sx={{
            marginTop: 2,
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <SecondaryButton text="Cancelar" action={onCancel} />
          <PrimaryButton
            isDisable={isNextDisabled}
            text={
              numberOfProvidersToComplete === 0
                ? "Enviar notificaciones"
                : "Siguiente cliente"
            }
            width="fit-content"
            action={onNext}
          />
        </Stack>
      </DrawerFooter>
    </div>
  );
}

export default DrawerSuccess;

import React, { useEffect } from "react";
import { styles } from "../styles";
import { CustomDataGrid } from "commons/modals/ExpandAssistedExpenses/components";
import { useAssistedExpenses } from "hooks";
import { PrimaryButton } from "commons/buttons";
import { Spinner } from "react-bootstrap";
import { accountingReceiptColumns } from "../utils/accountingReceiptsColumns";

export const ReconcileForm = ({
  submitForm,
  createRequestInProcess,
  prevItems,
  advanceAmount,
  setAdvanceAmount,
  selectedMovement,
  changeAdvanceAmount,
  setSelectedRows,
  selectedCoincidences,
}) => {
  const { selectedRows, toggleSelectedRow, isChecked, setItems, items } =
    useAssistedExpenses({
      isAssistedEgress: false,
      movementAmount:
        selectedMovement.amount < 0
          ? selectedMovement.amount * -1
          : selectedMovement.amount,
    });

  useEffect(() => {
    setItems(prevItems);
  }, [prevItems]);

  useEffect(() => {
    setSelectedRows(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    if (
      selectedRows.reduce((acc, row) => acc + row.amountRegister, 0) <
      advanceAmount
    ) {
      setAdvanceAmount(
        advanceAmount -
          selectedRows.reduce((acc, row) => acc + row.amountRegister, 0)
      );
    } else if (
      selectedRows.reduce((acc, row) => acc + row.amountRegister, 0) ===
      advanceAmount
    ) {
      setAdvanceAmount(null);
    } else {
      changeAdvanceAmount(selectedMovement);
    }

    if (selectedRows.length === 0) {
      changeAdvanceAmount(selectedMovement);
    }
  }, [selectedRows, items]);

  return (
    <form onSubmit={submitForm} style={styles.fullWidth}>
      {items.length > 0 && (
        <div style={{ maxWidth: "max-content" }}>
          <CustomDataGrid
            columns={accountingReceiptColumns(toggleSelectedRow, isChecked)}
            rows={items}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          justifyContent:
            selectedRows.length > 0 && advanceAmount
              ? "space-between"
              : "flex-end",
          alignItems: "center",
          marginTop: "8px",
          position: "sticky",
          bottom: "-4px",
          backgroundColor: "#FFFFFF",
          marginLeft: "-16px",
          marginRight: "-16px",
          padding: "8px 16px 8px 16px",
        }}
      >
        <div style={styles.formButtonsContainer}>
          <PrimaryButton
            text={
              createRequestInProcess ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Conciliar"
              )
            }
            type="submit"
            width="182px"
            isDisable={
              createRequestInProcess ||
              (selectedRows.length === 0 && selectedCoincidences.length === 0)
            }
          />
        </div>
      </div>
    </form>
  );
};

export const handleFormattedCustomerResponse = (res) => ({
  name: res?.name || "",
  documentType: res?.document_type || "",
  documentNumber: res?.document_number || "",
  email: res?.email || "",
  phone: res?.phone || "",
});

export const handleFormattedCustomerForm = (formValues) => ({
  name: formValues?.name || "",
  document_type: formValues?.documentType || "",
  document_number: formValues?.documentNumber || "",
  email: formValues?.email || "",
  phone: formValues?.phone || "",
});

import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { UserContext } from "../../../../contexts";
import { getStatusPillStyle, getStatusWording } from "../../../../utils";

export const Status = ({
  status,
  isIncomplete = false,
  invoiceIsApproved = false,
  section = null,
  className = "",
}) => {
  const { currentUserIsAdmin, approvationNeeded } = useContext(UserContext);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const isReallyIncomplete =
    isIncomplete && status === "pending" ? true : false;
  const invoiceIsInProcess = status === "in_process";
  const invoiceIsPaid = status === "paid" || status === "paid_outside";

  if (section === "invoices") {
    if (currentUserIsAdmin && approvationNeeded && invoiceIsApproved) {
      return (
        <p
          className={className}
          style={
            isReallyIncomplete
              ? getStatusPillStyle("incomplete", isMobile)
              : getStatusPillStyle(status, isMobile)
          }
        >
          {isReallyIncomplete
            ? getStatusWording("incomplete", isMobile)
            : getStatusWording(status, isMobile)}
        </p>
      );
    } else if (
      currentUserIsAdmin &&
      approvationNeeded &&
      !invoiceIsApproved &&
      !invoiceIsInProcess &&
      !invoiceIsPaid
    ) {
      return (
        <p
          style={
            isReallyIncomplete
              ? getStatusPillStyle("incomplete", isMobile)
              : getStatusPillStyle("to_be_approved", isMobile)
          }
        >
          {isReallyIncomplete
            ? getStatusWording("incomplete", isMobile)
            : getStatusWording("to_be_approved", isMobile)}
        </p>
      );
    } else if (!currentUserIsAdmin && approvationNeeded && invoiceIsApproved) {
      return (
        <p
          style={
            isReallyIncomplete
              ? getStatusPillStyle("incomplete", isMobile)
              : getStatusPillStyle(status, isMobile)
          }
        >
          {isReallyIncomplete
            ? getStatusWording("incomplete", isMobile)
            : getStatusWording(status, isMobile)}
        </p>
      );
    } else if (
      !currentUserIsAdmin &&
      approvationNeeded &&
      !invoiceIsApproved &&
      !invoiceIsInProcess &&
      !invoiceIsPaid
    ) {
      return (
        <p
          style={
            isReallyIncomplete
              ? getStatusPillStyle("incomplete", isMobile)
              : getStatusPillStyle("to_be_checked", isMobile)
          }
        >
          {isReallyIncomplete
            ? getStatusWording("incomplete", isMobile)
            : getStatusWording("to_be_checked", isMobile)}
        </p>
      );
    } else {
      // If approvationNeeded is false, everything will look normal for everybody.
      return (
        <p
          style={
            isReallyIncomplete
              ? getStatusPillStyle("incomplete", isMobile)
              : getStatusPillStyle(status, isMobile)
          }
        >
          {isReallyIncomplete
            ? getStatusWording("incomplete", isMobile)
            : getStatusWording(status, isMobile)}
        </p>
      );
    }
  } else if (section === "collections") {
    return (
      <p
        className={className}
        style={getStatusPillStyle(status, isMobile, section)}
      >
        {getStatusWording(status, isMobile, section)}
      </p>
    );
  } else {
    // Not invoicesScreen should show normal pill.
    return (
      <p
        className={className}
        style={
          isReallyIncomplete
            ? getStatusPillStyle("incomplete", isMobile)
            : getStatusPillStyle(status, isMobile)
        }
      >
        {isReallyIncomplete
          ? getStatusWording("incomplete", isMobile)
          : getStatusWording(status, isMobile)}
      </p>
    );
  }
};

import { Stack, Typography } from "@mui/material";
import { SecondaryButton } from "../../../buttons";
import SiigoIntegrationURLForm from "./SiigoIntegrationURLForm";
import { useState } from "react";
import { ChevronRight } from "assets";
import { getWppLink, openInNewTab } from "utils";
import { SUPPORT_NUMBER } from "constants";

const StepFour = ({
  cancel,
  primaryAction,
  resendAccessToken,
  showStartDate = false,
  onlyShowDate = false,
  credentialsError = false,
  setCredentialsError = () => {},
  data = {},
}) => {
  const [loading, setLoading] = useState(false);

  const message =
    "Hola, necesito ayuda con la integración de mi cuenta de Siigo";

  return (
    <div>
      {onlyShowDate ? (
        <p className="dian-modal-title">
          <strong>Importa tus cuentas por cobrar</strong>
        </p>
      ) : (
        <>
          <p className="dian-modal-title">
            <strong>Obtener llave de acceso</strong>
          </p>
          <p className="siigo-modal-text">
            <span>
              Para continuar necesitas un código que te proporcionará Siigo y
              que podrás encontrar en tu{" "}
              <a
                href="https://siigonube.siigo.com/ISIIGO2/Login.aspx?ReturnUrl=%2fISIIGO2%2findex.html%23%2fapi-credentials%2f1862%7D"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  color: "#5925DC",
                  fontWeight: "500",
                }}
              >
                panel de Siigo
              </a>{" "}
              yendo a <u>Menu</u> {">"} <u>Alianzas</u> {">"}{" "}
              <u>Mi Credencial API</u>, allí debes completar los campos para
              obtener el Access Key. Luego lo copias y pegas aquí.
            </span>
          </p>
        </>
      )}
      <SiigoIntegrationURLForm
        loading={loading}
        showStartDate={showStartDate}
        setLoading={setLoading}
        primaryAction={primaryAction}
        resendAccessToken={resendAccessToken}
        onlyShowDate={onlyShowDate}
        credentialsError={credentialsError}
        setCredentialsError={setCredentialsError}
        data={data}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%", marginTop: 6 }}
      >
        {!onlyShowDate && (
          <SecondaryButton width="100%" text="Volver" action={cancel} />
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        style={{ width: "100%", marginTop: "24px" }}
      >
        {!onlyShowDate && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => openInNewTab(getWppLink(SUPPORT_NUMBER, message))}
          >
            <Typography sx={{ color: "#1D2939", fontSize: "13px" }}>
              ¿Problemas conectándote? Escríbenos, te ayudaremos.
            </Typography>
            <ChevronRight color="#667085" />
          </div>
        )}
      </Stack>
    </div>
  );
};

export default StepFour;

import React, { useContext } from "react";
import { styles } from "../styles";
import { Typography } from "@mui/material";
import { getWppLink, openInNewTab } from "utils";
import { PrimaryButton } from "commons/buttons";
import { CloseIcon, ReferralBannerImage } from "assets";
import { UserContext } from "contexts";

export const ReconciliationsBanner = ({ onClose }) => {
  const { currentCompany } = useContext(UserContext);

  const phoneNumber = "+573103619890";
  const message = `Hola! Escribo de la empresa ${currentCompany.name} y quiero saber más acerca de la solución de conciliación`;

  return (
    <div style={styles.referralContainer}>
      <ReferralBannerImage height="96px" />
      <div style={styles.referralContent}>
        <div style={styles.referralColumn}>
          <div style={styles.rowSpaceBetween}>
            <Typography sx={styles.referralTitle}>
              Próximamente: optimiza tu contabilidad con el nuevo panel de
              conciliaciones
            </Typography>
            <button
              style={{
                cursor: "pointer",
                marginTop: "-16px",
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={onClose}
            >
              <CloseIcon color={"#FFFFFF"} />
            </button>
          </div>
          <div style={styles.rowSpaceBetween}>
            <Typography sx={styles.referralInfo}>
              Pronto podrás registrar y conciliar tu banco y contabilidad desde
              un solo lugar.
            </Typography>
            <PrimaryButton
              text="Quiero saber más"
              action={() => openInNewTab(getWppLink(phoneNumber, message))}
              width="150px"
              style={styles.referralButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { createContext, useContext, useEffect, useState } from "react";
import { PAGES_WITH_SERVICE_INTERRUPTION_MODAL } from "../screens/SettingsScreen/constants";
import { UserContext } from "./UserContext";
import { getInterrumptedServiceCommunication } from "../services/api/communications";
import { useLocation } from "react-router-dom";
import { AttentionModal } from "../commons";

export const InterruptedServiceContext = createContext();

export function InterruptedServiceProvider({ children }) {
  const { currentUserIsLoggedIn } = useContext(UserContext);
  const [dataToPreview, setDataToPreview] = useState({});
  const [openPreview, setOpenPreview] = useState(false);
  const [isAPageToAlert, setIsAPageToAlert] = useState(false);
  const [communication, setCommunication] = useState({ is_active: false });
  useEffect(() => {
    if (currentUserIsLoggedIn) {
      getInterrumptedServiceCommunication().then(setCommunication);
    }
  }, [currentUserIsLoggedIn]);
  const location = useLocation();

  useEffect(() => {
    const isAPageToAlert = PAGES_WITH_SERVICE_INTERRUPTION_MODAL.includes(
      location.pathname
    );
    if (isAPageToAlert) {
      setIsAPageToAlert(isAPageToAlert);
    }
  }, [location]);

  const preview = (dataToPreview) => {
    setDataToPreview(dataToPreview);
    setOpenPreview(true);
  };

  return (
    <InterruptedServiceContext.Provider
      value={{
        communication,
        preview,
      }}
    >
      {children}
    </InterruptedServiceContext.Provider>
  );
}

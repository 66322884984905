import { Stack } from "@mui/material";
import { NewMultipleSelect } from "assets";
import { PrimaryButton } from "commons/buttons";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { getFromLocalStorage, saveInLocalStorage } from "utils";

export const CommunicationMultipleSelect = ({ isCausationEnabled = false }) => {
  const [
    showCommunicationMultipleSelectModal,
    setshowCommunicationMultipleSelectModal,
  ] = useState(
    getFromLocalStorage("showCommunicationMultipleSelectModal") || "true"
  );

  const isShowCommunicationMultipleSelectModal =
    showCommunicationMultipleSelectModal !== "false" && isCausationEnabled;

  return (
    <Modal show={isShowCommunicationMultipleSelectModal}>
      <Modal.Body>
        <Stack
          style={{
            padding: "24px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                width: "86px",
                padding: "8px 10px",
                borderRadius: "72px",
                background: "#D9D6FE",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: " 28px",
                marginBottom: "24px",
              }}
            >
              ¡Nuevo!
            </p>
          </div>

          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "8px",
              marginBottom: "16px",
            }}
          >
            Múltiple asignación de ítems en causación
          </p>
          <p
            style={{
              color: "#667085",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "21px",
              letterSpacing: "-0.14px",
              marginBottom: "16px",
            }}
          >
            Ahora puedes seleccionar varios items y asignar de forma múltiple el
            mismo valor.
          </p>
          <NewMultipleSelect />
          <PrimaryButton
            text="Entendido"
            width="100%"
            style={{ marginTop: "24px" }}
            action={() => {
              setshowCommunicationMultipleSelectModal("false");
              saveInLocalStorage(
                "showCommunicationMultipleSelectModal",
                "false"
              );
            }}
          />
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Collapse,
  Box,
  IconButton,
} from "@mui/material";
import {
  accountTypes,
  documentTypes,
  PAYANA_DOCUMENT_NUMBER,
} from "../../../../../constants";
import { BankEntitySelector, RequiredLabel } from "../../../../components";
import {
  PrimaryButton,
  SecondaryButton,
  WithoutBorderButton,
} from "../../../../buttons";
import { PaymentsContext } from "../../../../../contexts";
import { ValidationMessage } from "../../../../components";
import { styles } from "../../styles";
import stylesCSS from "./styles.module.css";
import {
  ACCOUNT_NUMBER_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
} from "../../../../../utils";
import DrawerFooter from "./DrawerFooter";
import {
  DocumentNumberInput,
  DocumentTypeInput,
  EmailInput,
  PhoneNumberInput,
} from "../../../../inputs";
import { BannerErrorIcon, CloseIcon } from "assets";

const SupplierForm = ({
  detailedSupplier,
  onCancel,
  onSubmit,
  isCompletingData,
  onSuccess = () => {},
  title,
  isRetryFlow = false,
  showDisclaimer = false,
  setDisclaimerIsOpen = () => {},
  cleanForm = () => {},
  errorMessage = "",
}) => {
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);
  const [bankSelected, setBankSelected] = useState("");
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    trigger,
    setValue,
  } = useForm({
    defaultValues: detailedSupplier,
    mode: "onBlur",
  });

  const isPayana =
    +detailedSupplier.documentNumber === PAYANA_DOCUMENT_NUMBER || false;

  const formValues = useWatch({ control });
  const { allBanks } = useContext(PaymentsContext);
  const submitForm = handleSubmit(async (formValues) => {
    const selectedBank = allBanks.find((bank) => bank.id === formValues.bankId);
    setCreateRequestInProcess(true);
    await onSubmit({ ...formValues, bankName: selectedBank.name });
    setCreateRequestInProcess(false);
    onSuccess();
  });

  const accountHasDifferentHolder =
    formValues.accountHasDifferentHolder || false;

  const accountHasDifferentHolderProps = register("accountHasDifferentHolder");

  useEffect(() => {
    reset(detailedSupplier);
  }, [detailedSupplier]);

  useEffect(() => {
    if (isCompletingData && detailedSupplier.name) {
      setTimeout(() => {
        trigger().then((formDataIsCompleted) => {
          if (!formDataIsCompleted) {
            submitForm(formValues);
          }
        });
      }, 200);
    }
  }, [detailedSupplier]);

  useEffect(() => {
    const bank = allBanks.find((bank) => bank.id === formValues.bankId) || {
      name: "",
    };
    setBankSelected(bank ? bank.name : "");
  }, [formValues.bankId]);

  const renderValueAccountTypeSelect = (value) => {
    if (!value) {
      return "Tipo de cuenta";
    }
    const valueSelected = accountTypes.find(
      (account) => account.value === value
    );
    return valueSelected?.label;
  };

  return (
    <form onSubmit={submitForm} style={{ height: "100%" }}>
      <div style={styles.content}>
        <div></div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={styles.supplierTitle}>{title}</Typography>
          {isRetryFlow && (
            <div style={styles.errorContainer}>
              <div style={styles.errorRow}>
                <div style={styles.errorIcon}>
                  <BannerErrorIcon />
                </div>
                <div style={styles.errorColumn}>
                  <Typography sx={styles.errorSectionTitle}>
                    No pudimos realizar el pago
                  </Typography>
                  <Typography sx={styles.errorSectionText}>
                    {errorMessage}. Por favor, modifica la información para
                    reintentar el pago.
                  </Typography>
                </div>
              </div>
            </div>
          )}
          {showDisclaimer && (
            <Box style={styles.disclaimerContainer}>
              <div style={styles.disclaimerContent}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    marginLeft: "6px",
                  }}
                >
                  Pre-cargamos este formulario con tu información en caso de que
                  quieras realizar un auto-pago para probar tu mismo el flujo de
                  pago completo.
                </Typography>
                <IconButton onClick={() => setDisclaimerIsOpen(false)}>
                  <CloseIcon color={"#93370D"} />
                </IconButton>
              </div>
              <WithoutBorderButton
                text="Borrar información precargada"
                action={() => cleanForm()}
                width="200px"
                style={styles.disclaimerSecondaryButton}
              />
            </Box>
          )}
          <div style={styles.centeredForm}>
            <Stack sx={styles.supplierBackground}>
              <Stack spacing={1} sx={{ marginBottom: 2 }}>
                <RequiredLabel
                  label="Nombre de la empresa"
                  sx={styles.inputLabel}
                />
                <TextField
                  id="name"
                  size="small"
                  variant="outlined"
                  placeholder="Nombre de la empresa"
                  {...register("name", { required: true })}
                  value={formValues.name}
                  InputProps={{
                    style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                  }}
                  disabled={isPayana}
                />
                <ValidationMessage errors={formState.errors} field="name" />
              </Stack>
              <Stack spacing={1} sx={{ marginBottom: 2 }}>
                <DocumentTypeInput
                  value={formValues.documentType}
                  setValue={(value) => {
                    setValue("documentType", value, { shouldValidate: false });
                  }}
                  setDocumentNumberValue={(value) => {
                    setValue("documentNumber", value, {
                      shouldValidate: false,
                    });
                  }}
                  register={register}
                  errors={formState.errors}
                  documentNumberValue={formValues.documentNumber}
                  disabled={isPayana}
                />
              </Stack>
              <Stack spacing={1} sx={{ marginBottom: 2 }}>
                <DocumentNumberInput
                  value={formValues.documentNumber}
                  setValue={(value) => {
                    setValue("documentNumber", value, {
                      shouldValidate: false,
                    });
                  }}
                  documentType={formValues.documentType}
                  register={register}
                  errors={formState.errors}
                  disabled={isPayana}
                />
              </Stack>
              <Stack spacing={1} sx={{ marginBottom: 2 }}>
                <label htmlFor="email" style={styles.inputLabel}>
                  Correo electrónico para envío de comprobantes
                </label>
                <EmailInput
                  value={formValues.email}
                  setValue={(value) => {
                    setValue("email", value, { shouldValidate: true });
                  }}
                  register={register}
                  required={false}
                  disabled={isPayana}
                />
                <ValidationMessage errors={formState.errors} field="email" />
              </Stack>
              <Stack spacing={1}>
                <label htmlFor="phone" style={styles.inputLabel}>
                  Número de teléfono
                </label>
                <PhoneNumberInput
                  phone={formValues.phone}
                  setPhone={(value) => {
                    setValue("phone", value, { shouldValidate: true });
                  }}
                  disabled={isPayana}
                  {...register("phone", PHONE_NUMBER_VALIDATOR())}
                />
                <ValidationMessage errors={formState.errors} field="phone" />
              </Stack>
            </Stack>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FCFCFD",
                borderRadius: "8px",
                padding: "24px",
              }}
            >
              <Stack sx={{ marginBottom: "12px", marginLeft: "12px" }}>
                <FormControlLabel
                  control={
                    <input
                      id="accountHasDifferentHolder"
                      type="checkbox"
                      className={stylesCSS.input}
                      checked={formValues.accountHasDifferentHolder}
                      {...accountHasDifferentHolderProps}
                      onChange={() =>
                        accountHasDifferentHolderProps.onChange({
                          target: {
                            name: "accountHasDifferentHolder",
                            value: !accountHasDifferentHolder,
                          },
                        })
                      }
                      disabled={isPayana}
                    />
                  }
                  label={
                    <label
                      htmlFor="accountHasDifferentHolder"
                      style={styles.checkboxInputLabel}
                    >
                      La cuenta bancaria tiene un titular diferente
                    </label>
                  }
                  labelPlacement="end"
                />
              </Stack>
              <Collapse in={accountHasDifferentHolder}>
                <Stack spacing={1} sx={{ marginBottom: 2 }}>
                  <label htmlFor="accountHolderName" style={styles.inputLabel}>
                    Nombre del titular
                  </label>
                  <TextField
                    id="accountHolderName"
                    size="small"
                    variant="outlined"
                    placeholder="Nombre del titular"
                    {...register("accountHolderName", {
                      required: accountHasDifferentHolder,
                    })}
                    value={formValues.accountHolderName || ""}
                    InputProps={{
                      style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                    }}
                  />
                  <ValidationMessage
                    errors={formState.errors}
                    field="accountHolderName"
                  />
                </Stack>
                <Stack spacing={1} sx={{ marginBottom: 2 }}>
                  <label
                    htmlFor="accountHolderDocumentType"
                    style={styles.inputLabel}
                  >
                    Tipo de identificación
                  </label>
                  <Select
                    size="small"
                    variant="outlined"
                    displayEmpty={true}
                    renderValue={(value) =>
                      value?.length
                        ? Array.isArray(value)
                          ? value.join(", ")
                          : value
                        : "Tipo de identificación"
                    }
                    value={formValues.accountHolderDocumentType || ""}
                    style={{
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "#FFFFFF",
                    }}
                    {...register("accountHolderDocumentType", {
                      required: accountHasDifferentHolder,
                    })}
                    inputProps={{
                      id: "accountHolderDocumentType",
                    }}
                  >
                    {documentTypes?.length === 0 && (
                      <MenuItem
                        key={"type"}
                        value={""}
                        style={{ fontSize: "14px" }}
                      >
                        {"Tipo de identificación"}
                      </MenuItem>
                    )}
                    {documentTypes?.map((type) => {
                      return (
                        <MenuItem
                          key={type}
                          value={type}
                          style={{ fontSize: "14px" }}
                          onClick={() => {
                            setValue("accountHolderDocumentNumber", "");
                            formState.errors.accountHolderDocumentNumber = "";
                          }}
                        >
                          {type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <ValidationMessage
                    errors={formState.errors}
                    field="accountHolderDocumentType"
                  />
                </Stack>
                <Stack spacing={1} sx={{ marginBottom: 2 }}>
                  <DocumentNumberInput
                    value={formValues.accountHolderDocumentNumber}
                    setValue={(value) => {
                      setValue("accountHolderDocumentNumber", value, {
                        shouldValidate: false,
                      });
                    }}
                    documentType={formValues.accountHolderDocumentType}
                    register={register}
                    errors={formState.errors}
                    id="accountHolderDocumentNumber"
                    required={accountHasDifferentHolder}
                  />
                </Stack>
              </Collapse>
              <Stack spacing={1} sx={{ marginBottom: 2 }}>
                <RequiredLabel
                  label="Entidad bancaria"
                  sx={styles.inputLabel}
                />
                <BankEntitySelector
                  bankSelectedId={formValues.bankId}
                  validationProps={register("bankId", { required: true })}
                  disabled={isPayana}
                />
                <ValidationMessage errors={formState.errors} field="bankId" />
              </Stack>
              {bankSelected.toLowerCase() !== "nequi" &&
                bankSelected.toLowerCase() !== "daviplata" && (
                  <Stack spacing={1}>
                    <RequiredLabel
                      label="Tipo de cuenta"
                      sx={styles.inputLabel}
                    />
                    <Select
                      size="small"
                      variant="outlined"
                      style={{ fontSize: "14px", backgroundColor: "#FFFFFF" }}
                      value={formValues.accountType}
                      displayEmpty={true}
                      renderValue={renderValueAccountTypeSelect}
                      inputProps={{
                        id: "accountType",
                      }}
                      {...register("accountType", { required: true })}
                      disabled={isPayana}
                    >
                      {accountTypes.map((type) => {
                        return (
                          <MenuItem
                            key={type.value}
                            value={type.value}
                            style={{ fontSize: "14px" }}
                          >
                            {type.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ValidationMessage
                      errors={formState.errors}
                      field="accountType"
                    />
                  </Stack>
                )}
              <Stack spacing={1} sx={{ marginTop: 2 }}>
                <RequiredLabel
                  label={
                    bankSelected.toLowerCase() === "nequi" ||
                    bankSelected.toLowerCase() === "daviplata"
                      ? "Número de celular"
                      : "Número de cuenta"
                  }
                  sx={styles.inputLabel}
                />
                <TextField
                  disabled={isPayana}
                  id="accountNumber"
                  size="small"
                  variant="outlined"
                  placeholder={
                    bankSelected.toLowerCase() === "nequi" ||
                    bankSelected.toLowerCase() === "daviplata"
                      ? "Número de celular"
                      : "Número de cuenta"
                  }
                  value={formValues.accountNumber}
                  {...register("accountNumber", ACCOUNT_NUMBER_VALIDATOR())}
                  InputProps={{
                    style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                  }}
                />
                <ValidationMessage
                  errors={formState.errors}
                  field="accountNumber"
                />
              </Stack>
            </div>
          </div>
        </div>

        <DrawerFooter customStyle={{ width: "100%" }}>
          <Stack direction="row" sx={styles.footer}>
            <SecondaryButton
              text="Cancelar"
              action={onCancel}
              width="162px"
              isDisable={isPayana}
            />
            <PrimaryButton
              isDisable={createRequestInProcess || isPayana}
              text={isRetryFlow ? "Guardar y reintentar pago" : "Guardar"}
              type="submit"
              width={isRetryFlow ? "201px" : "162px"}
            />
          </Stack>
        </DrawerFooter>
      </div>
    </form>
  );
};

export default SupplierForm;

import { format } from "date-fns";
import es from "date-fns/locale/es";
const locale = es;

export const formatLocaleDate = (date, formatType = "dd MMM, y") => {
  try {
    const data = format(new Date(date), formatType, {
      locale,
    });
    return data;
  } catch (error) {
    return "";
  }
};

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Dialog } from "@mui/material";
import { CloseButton } from "react-bootstrap";
import { CollectionsContext } from "../../../contexts";
import { SKELETON_CUSTOMER } from "./constants";
import {
  handleFormattedCustomerResponse,
  handleFormattedCustomerForm,
} from "./utils";
import CustomerForm from "./forms/CustomerForm";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import { styles } from "./styles";

export const CustomerDetails = ({
  isOpen,
  handleClose,
  id,
  needRedirectionCreate = false,
  needRedirectionEdit = false,
  handleSuccess = () => {},
  isBO = false,
  isRetryFlow = false,
  errorMessage = "",
}) => {
  const {
    updateCustomer,
    createCustomer,
    getCustomer,
    getBOCustomer,
    getCustomers,
    updateBOCustomer,
  } = useContext(CollectionsContext);

  const location = useLocation();
  const [detailedCustomer, setDetailedCustomer] = useState(SKELETON_CUSTOMER);

  const navigate = useNavigate();

  useEffect(() => {
    getCustomers(1, "all");
    if (isOpen) {
      if (id) {
        const getCustomerDetails = isBO ? getBOCustomer(id) : getCustomer(id);
        getCustomerDetails.then((res) =>
          setDetailedCustomer(handleFormattedCustomerResponse(res?.at(0)))
        );
      }
    }
  }, [isOpen, id]);

  const updateCustomerHandler = async (id, formValues) => {
    isBO
      ? await updateBOCustomer(id, formValues)
      : await updateCustomer(id, formValues);
    handleSuccess({
      ...formValues,
      has_identification_number_placeholder: false,
      id,
    });
  };

  const createCustomerHandler = async (formValuesToSend, bank_name = "") => {
    const createdCustomer = await createCustomer(formValuesToSend);
    if (createdCustomer?.response?.status === 400) {
      toast.warn(createdCustomer.response.data.message, {
        ...toastOptions,
        autoClose: 10000,
      });
    } else {
      const customer = {
        id: createdCustomer.customer_id,
        bank_name,
        ...formValuesToSend,
      };
      handleSuccess(customer);
      notifyCustomerUpdated();
    }
  };

  const updateOrCreateCustomerHandler = (formValues) => {
    const formValuesToSend = handleFormattedCustomerForm(formValues);
    if (id) {
      updateCustomerHandler(id, formValuesToSend);
    } else {
      createCustomerHandler(formValuesToSend, formValues?.bank_name);
    }
    closeAndReset(formValuesToSend);
  };

  const handleRedirect = (name) => {
    if (location?.state?.redirectUrl) {
      return navigate(location?.state?.redirectUrl, {
        state: {
          customerName: name,
        },
        replace: true,
      });
    }
    if (needRedirectionCreate) {
      navigate("/collections/index", {
        state: {
          openCreateObligationModal: true,
          customerName: name,
        },
        replace: true,
      });
    }
    if (needRedirectionEdit) {
      navigate("/collections/index", {
        replace: true,
      });
    }
  };

  const closeAndReset = (formValuesToSend) => {
    handleClose();
    setDetailedCustomer(SKELETON_CUSTOMER);
    handleRedirect(formValuesToSend?.name);
  };

  const notifyCustomerUpdated = () => {
    toast.success(
      id ? "¡El cliente se editó con éxito!" : "¡El cliente se creó con éxito!",
      toastOptions
    );
  };

  return (
    <Dialog fullScreen open={isOpen}>
      <Stack direction={"row"} justifyContent="flex-end" sx={styles.container}>
        <CloseButton style={{ boxShadow: "none" }} onClick={closeAndReset} />
      </Stack>
      <div style={styles.content}>
        <Stack sx={styles.formContainer}>
          <CustomerForm
            detailedCustomer={detailedCustomer}
            onSubmit={updateOrCreateCustomerHandler}
            onCancel={closeAndReset}
            title={id ? "Editar cliente" : "Crear cliente"}
            isRetryFlow={isRetryFlow}
            errorMessage={errorMessage}
          />
        </Stack>
      </div>
    </Dialog>
  );
};

import React from "react";

export const EmailIcon = ({ color }) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
    <path
      d="M1.33337 3.66699L6.77665 7.47729C7.21743 7.78583 7.43783 7.94011 7.67755 7.99986C7.8893 8.05265 8.11078 8.05265 8.32253 7.99986C8.56226 7.94011 8.78265 7.78583 9.22343 7.47729L14.6667 3.66699M4.53337 12.3337H11.4667C12.5868 12.3337 13.1469 12.3337 13.5747 12.1157C13.951 11.9239 14.257 11.618 14.4487 11.2416C14.6667 10.8138 14.6667 10.2538 14.6667 9.13366V4.86699C14.6667 3.74689 14.6667 3.18683 14.4487 2.75901C14.257 2.38269 13.951 2.07673 13.5747 1.88498C13.1469 1.66699 12.5868 1.66699 11.4667 1.66699H4.53337C3.41327 1.66699 2.85322 1.66699 2.42539 1.88498C2.04907 2.07673 1.74311 2.38269 1.55136 2.75901C1.33337 3.18683 1.33337 3.74689 1.33337 4.86699V9.13366C1.33337 10.2538 1.33337 10.8138 1.55136 11.2416C1.74311 11.618 2.04907 11.9239 2.42539 12.1157C2.85322 12.3337 3.41327 12.3337 4.53337 12.3337Z"
      stroke={color ? color : "#98A2B3"}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { useContext, useEffect, useState } from "react";
import { PayrollContext } from "../../contexts";
import { Drawer, Stack, Typography } from "@mui/material";
import { styles } from "../../commons/modals/SupplierDetails/styles";
import { CloseButton } from "../../commons";
import {
  EmployeeDrawerSuccess,
  EmployeeForm,
  SKELETON_EMPLOYEE,
  handleFormattedEmployeeForm,
  handleFormattedEmployeeResponse,
} from "commons/modals/EmployeeDetails";

const DrawerEmployeeForm = ({
  drawerState,
  closeAndReset = () => {},
  setDrawerState,
  onFinish = () => {},
  flow,
}) => {
  const id = drawerState?.employeeIdsToComplete?.at(0);
  const [detailedEmployee, setDetailedEmployee] = useState(SKELETON_EMPLOYEE);
  const { updateEmployee, createEmployee, getEmployee, getPayrolls } =
    useContext(PayrollContext);
  useEffect(() => {
    getEmployee(id).then((res) =>
      setDetailedEmployee(handleFormattedEmployeeResponse(res))
    );
  }, [drawerState]);

  const updateEmployeeHandler = async (id, formValues) => {
    await updateEmployee(id, formValues);
  };

  const createEmployeeHandler = async (formValuesToSend) => {
    await createEmployee(formValuesToSend);
  };

  const updateOrCreateEmployeeHandler = (formValues) => {
    const formValuesToSend = handleFormattedEmployeeForm(formValues);
    if (id) {
      updateEmployeeHandler(id, formValuesToSend, () =>
        closeAndReset(formValuesToSend)
      );
    } else {
      createEmployeeHandler(formValuesToSend);
      closeAndReset(formValuesToSend);
    }
  };

  const closeFlow = () => {
    getPayrolls(1);
    setDrawerState(() => {
      return { key: "none" };
    });
  };

  return (
    <Drawer
      anchor="right"
      open={drawerState?.key === "complete"}
      onClose={closeFlow}
      style={{ zIndex: 1301 }}
    >
      <Stack
        sx={{
          minWidth: "400px",
          height: "100%",
          overflow: "overlay",
          scrollbarGutter: "stable",
          paddingTop: 2,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        {drawerState?.viewToShow === "submitted" ? (
          <EmployeeDrawerSuccess
            numberOfProvidersToComplete={
              drawerState?.employeeIdsToComplete?.length
            }
            onNext={() => {
              if (drawerState?.employeeIdsToComplete?.length === 0) {
                closeFlow();
                return onFinish();
              }
              setDrawerState((state) => {
                return {
                  ...state,
                  viewToShow: "complete",
                };
              });
            }}
            onCancel={closeFlow}
            flow={flow}
          />
        ) : (
          <div style={{ marginBottom: "12px" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography sx={styles.title}>Completar empleado</Typography>
              <CloseButton style={{ boxShadow: "none" }} onClick={closeFlow} />
            </Stack>
            <EmployeeForm
              isCompletingData={true}
              detailedEmployee={detailedEmployee}
              onSubmit={updateOrCreateEmployeeHandler}
              onCancel={closeFlow}
              onSuccess={(employee) => {
                setDrawerState((state) => {
                  return {
                    ...state,
                    updated: [employee],
                    employeeIdsToComplete: state.employeeIdsToComplete.slice(1),
                    viewToShow: "submitted",
                  };
                });
              }}
            />
          </div>
        )}
      </Stack>
    </Drawer>
  );
};

export default DrawerEmployeeForm;

import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Stack, TextField, Typography } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../../buttons";
import { RequiredLabel, ValidationMessage } from "../../../../components";
import { styles } from "../../styles";
import { PHONE_NUMBER_VALIDATOR } from "../../../../../utils";
import {
  DocumentNumberInput,
  DocumentTypeInput,
  EmailInput,
  PhoneNumberInput,
} from "../../../../inputs";
import DrawerFooter from "./DrawerFooter";
import { BannerErrorIcon } from "assets";

const CustomerForm = ({
  detailedCustomer,
  onCancel,
  onSubmit,
  isCompletingData,
  onSuccess = () => {},
  title,
  isRetryFlow = false,
  errorMessage = "",
}) => {
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    trigger,
    setValue,
  } = useForm({
    defaultValues: detailedCustomer,
    mode: "onBlur",
  });

  const formValues = useWatch({ control });
  const submitForm = handleSubmit(async (formValues) => {
    setCreateRequestInProcess(true);
    await onSubmit(formValues);
    onSuccess();
    setCreateRequestInProcess(false);
  });

  useEffect(() => {
    reset(detailedCustomer);
  }, [detailedCustomer]);

  useEffect(() => {
    if (isCompletingData && detailedCustomer.name) {
      setTimeout(() => {
        trigger().then((formDataIsCompleted) => {
          if (!formDataIsCompleted) {
            submitForm(formValues);
          }
        });
      }, 200);
    }
  }, [detailedCustomer]);

  return (
    <form onSubmit={submitForm} style={styles.fullHeight}>
      <div style={styles.centeredFormContainer}>
        <div></div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Typography sx={styles.formTitle}>{title}</Typography>
          {isRetryFlow && (
            <div style={styles.errorContainer}>
              <div style={styles.errorRow}>
                <div style={styles.errorIcon}>
                  <BannerErrorIcon />
                </div>
                <div style={styles.errorColumn}>
                  <Typography sx={styles.errorSectionTitle}>
                    No pudimos realizar el pago
                  </Typography>
                  <Typography sx={styles.errorSectionText}>
                    {errorMessage}. Por favor, modifica la información para
                    reintentar el pago.
                  </Typography>
                </div>
              </div>
            </div>
          )}
          <div style={styles.centeredForm}>
            <Stack sx={styles.background} spacing={2}>
              <Stack spacing={1}>
                <RequiredLabel
                  label="Nombre del cliente"
                  sx={styles.inputLabel}
                />
                <TextField
                  id="name"
                  size="small"
                  variant="outlined"
                  placeholder="Nombre del cliente"
                  {...register("name", { required: true })}
                  value={formValues.name}
                  InputProps={{
                    style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                  }}
                />
                <ValidationMessage errors={formState.errors} field="name" />
              </Stack>
              <Stack spacing={1}>
                <DocumentTypeInput
                  value={formValues.documentType}
                  setValue={(value) => {
                    setValue("documentType", value, { shouldValidate: false });
                  }}
                  setDocumentNumberValue={(value) => {
                    setValue("documentNumber", value, {
                      shouldValidate: false,
                    });
                  }}
                  register={register}
                  errors={formState.errors}
                  documentNumberValue={formValues.documentNumber}
                />
              </Stack>
              <Stack spacing={1}>
                <DocumentNumberInput
                  value={formValues.documentNumber}
                  setValue={(value) => {
                    setValue("documentNumber", value, {
                      shouldValidate: false,
                    });
                  }}
                  documentType={formValues.documentType}
                  register={register}
                  errors={formState.errors}
                />
              </Stack>
              <Stack spacing={1}>
                <label htmlFor="email" style={styles.inputLabel}>
                  Correo electrónico para envío de comprobantes
                </label>
                <EmailInput
                  value={formValues.email}
                  setValue={(value) => {
                    setValue("email", value, { shouldValidate: true });
                  }}
                  register={register}
                  required={!formValues.phone || formValues.phone === "+57"}
                />
                <ValidationMessage errors={formState.errors} field="email" />
              </Stack>
              <Stack spacing={1}>
                <label htmlFor="phone" style={styles.inputLabel}>
                  Número de teléfono
                </label>
                <PhoneNumberInput
                  phone={formValues.phone}
                  setPhone={(value) => {
                    setValue("phone", value.toString(), {
                      shouldValidate: true,
                    });
                  }}
                  {...register(
                    "phone",
                    PHONE_NUMBER_VALIDATOR(
                      formValues.name &&
                        (!formValues.email || formValues.email === "")
                    )
                  )}
                />
                <ValidationMessage errors={formState.errors} field="phone" />
              </Stack>
            </Stack>
          </div>
        </div>
        <DrawerFooter customStyle={{ width: "100%" }}>
          <Stack direction="row" sx={styles.footer}>
            <SecondaryButton text="Cancelar" action={onCancel} width="162px" />
            <PrimaryButton
              text={isRetryFlow ? "Guardar y reintentar pago" : "Guardar"}
              isDisable={createRequestInProcess}
              type="submit"
              width={isRetryFlow ? "201px" : "162px"}
            />
          </Stack>
        </DrawerFooter>
      </div>
    </form>
  );
};
export default CustomerForm;

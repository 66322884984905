import React from "react";

export const CheckDoneIcon = ({
  color = "#344054",
  height = "18px",
  width = "18px",
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_680_8587)">
      <path
        d="M4.99935 12.5L6.66602 14.1667L10.416 10.4167M6.66602 6.66666V4.33332C6.66602 3.3999 6.66602 2.93319 6.84767 2.57667C7.00746 2.26307 7.26243 2.0081 7.57603 1.84831C7.93255 1.66666 8.39926 1.66666 9.33268 1.66666H15.666C16.5994 1.66666 17.0661 1.66666 17.4227 1.84831C17.7363 2.0081 17.9912 2.26307 18.151 2.57667C18.3327 2.93319 18.3327 3.3999 18.3327 4.33332V10.6667C18.3327 11.6001 18.3327 12.0668 18.151 12.4233C17.9912 12.7369 17.7363 12.9919 17.4227 13.1517C17.0661 13.3333 16.5994 13.3333 15.666 13.3333H13.3327M4.33268 18.3333H10.666C11.5994 18.3333 12.0661 18.3333 12.4227 18.1517C12.7363 17.9919 12.9912 17.7369 13.151 17.4233C13.3327 17.0668 13.3327 16.6001 13.3327 15.6667V9.33332C13.3327 8.3999 13.3327 7.93319 13.151 7.57667C12.9912 7.26307 12.7363 7.0081 12.4227 6.84831C12.0661 6.66666 11.5994 6.66666 10.666 6.66666H4.33268C3.39926 6.66666 2.93255 6.66666 2.57603 6.84831C2.26243 7.0081 2.00746 7.26307 1.84767 7.57667C1.66602 7.93319 1.66602 8.3999 1.66602 9.33332V15.6667C1.66602 16.6001 1.66602 17.0668 1.84767 17.4233C2.00746 17.7369 2.26243 17.9919 2.57603 18.1517C2.93255 18.3333 3.39926 18.3333 4.33268 18.3333Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_680_8587">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

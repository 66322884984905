import React from "react";

export const FileIcon = ({ color = "" }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.83335 7.33334H5.83335M7.16669 10H5.83335M11.1667 4.66667H5.83335M13.8334 4.53334V11.4667C13.8334 12.5868 13.8334 13.1468 13.6154 13.5747C13.4236 13.951 13.1177 14.2569 12.7413 14.4487C12.3135 14.6667 11.7535 14.6667 10.6334 14.6667H6.36669C5.24658 14.6667 4.68653 14.6667 4.25871 14.4487C3.88238 14.2569 3.57642 13.951 3.38467 13.5747C3.16669 13.1468 3.16669 12.5868 3.16669 11.4667V4.53334C3.16669 3.41323 3.16669 2.85318 3.38467 2.42535C3.57642 2.04903 3.88238 1.74307 4.25871 1.55132C4.68653 1.33334 5.24658 1.33334 6.36669 1.33334H10.6334C11.7535 1.33334 12.3135 1.33334 12.7413 1.55132C13.1177 1.74307 13.4236 2.04903 13.6154 2.42535C13.8334 2.85318 13.8334 3.41323 13.8334 4.53334Z"
      stroke={color ? color : "#667085"}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeinejoin="round"
    />
  </svg>
);

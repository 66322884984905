import { requestContainer } from "../requestContainer";

export async function GetTags() {
  return requestContainer({
    method: "get",
    url: `/tag`,
  });
}

export async function CreateTag({ tag }) {
  return requestContainer({
    method: "post",
    url: `/tag`,
    data: { name: tag },
  });
}

export async function UpdateTag({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/tag/${id}`,
    data: {
      ...formValues,
    },
  });
}

export async function DeleteTag({ id }) {
  return requestContainer({
    method: "delete",
    url: `/tag/${id}`,
  });
}

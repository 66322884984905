import { Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../buttons";

const StepSix = ({ title, subtitle, cancel, primaryAction }) => {
  return (
    <div>
      <p className="dian-modal-title">
        <strong>{title}</strong>
      </p>
      <p className="dian-modal-text">
        <span>{subtitle}</span>
      </p>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%" }}
      >
        <PrimaryButton width="100%" text="Reintentar" action={primaryAction} />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%", marginTop: 6 }}
      >
        <SecondaryButton width="100%" text="Cancelar" action={cancel} />
      </Stack>
    </div>
  );
};

export default StepSix;

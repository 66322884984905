import React from "react";

export const LightningIcon = ({
  strokeColor = "#6938EF",
  bgColor = "#EBE9FE",
}) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="16" fill={bgColor} />
    <path
      d="M16.6667 9.33337L10.729 16.4586C10.4965 16.7377 10.3802 16.8772 10.3785 16.995C10.3769 17.0975 10.4226 17.1949 10.5022 17.2593C10.5939 17.3334 10.7755 17.3334 11.1388 17.3334H16.0001L15.3334 22.6667L21.2711 15.5415C21.5036 15.2624 21.6199 15.1229 21.6217 15.0051C21.6232 14.9026 21.5776 14.8052 21.4979 14.7408C21.4062 14.6667 21.2246 14.6667 20.8614 14.6667H16.0001L16.6667 9.33337Z"
      stroke={strokeColor}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React, { useContext } from "react";
import { UserContext } from "contexts";
import { useState } from "react";
import { SUBSCRIPTION_STATUS } from "constants";

export const useSubscription = ({ type }) => {
  const { currentUser, getMe } = useContext(UserContext);
  const [showModalSubscription, setShowModalSubscription] = useState(false);
  const [callbackStore, setCallbackStore] = useState(null);
  const [isPayanaInvoice, setIsPayanaInvoice] = useState(false);

  const { subscriptions } = currentUser;

  const subscription = subscriptions?.find((sub) => sub.featureType === type);

  const isSubscriptionInactive =
    !subscription?.status ||
    subscription?.status === SUBSCRIPTION_STATUS.INACTIVE ||
    subscription?.status === SUBSCRIPTION_STATUS.TRIAL_EXPIRED ||
    subscription?.status === SUBSCRIPTION_STATUS.CANCELLED;

  const handleActionSubscription = (callback, onClose = () => {}) => {
    if (isSubscriptionInactive && !isPayanaInvoice) {
      setShowModalSubscription(true);
      setCallbackStore(() => callback);
      onClose();
      return;
    }

    return callback();
  };

  return {
    companySubscriptions: subscriptions,
    type,
    showIcon: isSubscriptionInactive,
    subscription,
    showModalSubscription,
    handleActionSubscription,
    hadleOpenModalSubscription: () => setShowModalSubscription(true),
    hadleCloseModalSubscription: () => setShowModalSubscription(false),
    getMe: () => getMe(),
    callback: callbackStore,
    handleSetIsPayanaInvoice: (value) => setIsPayanaInvoice(value),
    isPayanaInvoice,
  };
};

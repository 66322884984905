export const UncheckedIcon = (props) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.833496} y={0.5} width={15} height={15} rx={7.5} fill="white" />
    <rect
      x={0.833496}
      y={0.5}
      width={15}
      height={15}
      rx={7.5}
      stroke="#D0D5DD"
    />
  </svg>
);

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Dialog } from "@mui/material";
import { CloseButton } from "react-bootstrap";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import { PaymentsContext, UserContext } from "../../../contexts";
import { SKELETON_SERVICE, SKELETON_SUPPLIER } from "./constants";
import {
  handleFormattedSupplierResponse,
  handleFormattedSupplierForm,
  handleFormattedServiceResponse,
  handleFormattedServiceForm,
} from "./utils";
import { styles } from "./styles";
import Tabs from "./components/Tabs";
import ServicesForm from "./forms/ServicesForm";
import SupplierForm from "./forms/SupplierForm";

export const SupplierDetails = ({
  isOpen,
  handleClose,
  id,
  needRedirectionCreate = false,
  needRedirectionEdit = false,
  handleSuccess = () => {},
  isOnlyService,
  isOnlySupplier,
  pageIndex,
  providerId,
  title,
  isRetryFlow = false,
  errorMessage = "",
}) => {
  const {
    updateSupplier,
    createSupplier,
    getAllBanks,
    getSupplier,
    suppliers,
    createService,
    updateService,
    getService,
    getServices,
  } = useContext(PaymentsContext);
  const { currentUser, currentCompany, currentUserIsAdmin } =
    useContext(UserContext);

  const location = useLocation();
  const [disclaimerIsOpen, setDisclaimerIsOpen] = useState(
    currentUserIsAdmin ? suppliers?.length === 0 : false
  );
  const [detailedSupplier, setDetailedSupplier] = useState(SKELETON_SUPPLIER);
  const [detailedService, setDetailedService] = useState(SKELETON_SERVICE);
  const [formType, setFormType] = useState(0);
  const [isSupplierCompletedCase, setIsSupplierCompletedCase] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOnlySupplier) {
      setFormType(0);
    } else if (isOnlyService) setFormType(1);
  }, [isOnlyService, isOnlySupplier]);

  useEffect(() => {
    if (isOnlyService) {
      getServices(1, "all");
      if (isOpen) {
        if (id) {
          getService(id).then((res) =>
            setDetailedService(handleFormattedServiceResponse(res))
          );
        }
      }
    } else {
      setDisclaimerIsOpen(currentUserIsAdmin ? suppliers?.length === 0 : false);
      if (isOpen) {
        getAllBanks();
        if (id) {
          getSupplier(id).then((res) =>
            setDetailedSupplier(handleFormattedSupplierResponse(res))
          );
        }
        if (currentUserIsAdmin && suppliers?.length === 0) {
          setDisclaimerIsOpen(true);
          setDetailedSupplier({
            ...SKELETON_SUPPLIER,
            name: currentCompany?.name,
            documentType: "NIT",
            documentNumber: currentCompany?.identification_number,
            email: currentUser?.email,
            phone: currentUser?.phone || "",
          });
        }
      }
    }
  }, [isOpen, id]);

  const handleChangeFormType = (formType) => {
    setFormType(formType);
  };

  const createServiceHandler = async (formValues) => {
    const createdService = await createService(formValues);
    if (createdService) {
      toast.success("¡El servicio se creó con éxito!", toastOptions);
      getServices(1, "all");
    } else {
      toast.error("No se pudo crear el servicio", toastOptions);
    }
    handleSuccess(null, {
      id: createdService.company_service_id,
      alias: formValues.alias,
      referral_code: formValues.referral_code,
    });
    closeAndReset(formValues);
  };

  const updateServiceHandler = async (id, formValues) => {
    const updatedService = await updateService(id, formValues);
    if (updatedService) {
      toast.success("¡El servicio se editó con éxito!", toastOptions);
      getServices(1, "all");
    } else {
      toast.error("No se pudo editar el servicio", toastOptions);
    }
    handleSuccess(null, {
      service: updatedService.service,
      alias: formValues.alias,
      referral_code: formValues.referral_code,
    });
    closeAndReset(formValues);
  };

  const updateSupplierHandler = async (id, formValues) => {
    await updateSupplier(id, formValues, pageIndex, providerId);
    handleSuccess(
      {
        ...formValues,
        has_identification_number_placeholder: false,
        id,
      },
      null
    );
  };

  const createSupplierHandler = async (formValuesToSend, bank_name = "") => {
    const createdSupplier = await createSupplier(formValuesToSend);

    if (createdSupplier?.response?.status === 400) {
      toast.warn(createdSupplier.response.data.message, {
        ...toastOptions,
        autoClose: 10000,
      });
    } else {
      const supplier = {
        id: createdSupplier.provider_id,
        bank_name,
        ...formValuesToSend,
      };
      handleSuccess(supplier);
      closeAndReset(formValuesToSend);
      notifySupplierUpdated();
    }
  };

  const updateOrCreateSupplierHandler = (formValues) => {
    const formValuesToSend = handleFormattedSupplierForm(formValues);
    if (id) {
      updateSupplierHandler(id, formValuesToSend);
      closeAndReset(formValuesToSend);
      !isRetryFlow && notifySupplierUpdated();
    } else {
      createSupplierHandler(formValuesToSend, formValues?.bank_name);
    }
  };

  const updateOrCreateServiceHandler = (formValues) => {
    const formValuesToSend = handleFormattedServiceForm(formValues);
    if (id) {
      updateServiceHandler(id, formValuesToSend);
    } else {
      createServiceHandler(formValuesToSend);
    }
    closeAndReset(formValuesToSend);
  };

  const handleRedirect = (name) => {
    if (location?.state?.redirectUrl) {
      return navigate(location?.state?.redirectUrl, {
        state: {
          supplierName: name,
        },
        replace: true,
      });
    }
    if (needRedirectionCreate) {
      navigate("/payments/invoices", {
        state: {
          openCreateObligationModal: true,
          supplierName: name,
        },
        replace: true,
      });
    }
    if (needRedirectionEdit) {
      navigate("/payments/invoices", {
        replace: true,
      });
    }
  };

  const cleanForm = () => {
    setDetailedSupplier(SKELETON_SUPPLIER);
    setDetailedService(SKELETON_SERVICE);
    setDisclaimerIsOpen(false);
  };

  const closeAndReset = (formValuesToSend) => {
    handleClose();
    setDetailedSupplier(SKELETON_SUPPLIER);
    setDetailedService(SKELETON_SERVICE);
    handleRedirect(formValuesToSend?.name);
    setFormType(formType);
  };

  const notifySupplierUpdated = () => {
    toast.success(
      id
        ? "¡El proveedor se editó con éxito!"
        : "¡El proveedor se creó con éxito!",
      toastOptions
    );
  };

  const drawerTitle = title
    ? title
    : id
    ? formType === 0
      ? "Editar proveedor"
      : "Editar servicio"
    : formType === 0
    ? "Agendar proveedor"
    : "Agendar servicio";

  return (
    <Dialog fullScreen open={isOpen}>
      <Stack
        direction={"row"}
        justifyContent="flex-end"
        sx={{
          paddingTop: 2,
          paddingLeft: 2,
          paddingRight: "16px",
        }}
      >
        <CloseButton style={{ boxShadow: "none" }} onClick={closeAndReset} />
      </Stack>
      <div style={styles.form}>
        <Stack sx={styles.formContainer}>
          {!id && !isOnlySupplier && !isOnlyService && (
            <Tabs onChange={handleChangeFormType} tabDefaultValue={formType} />
          )}
          {formType === 0 && (
            <SupplierForm
              detailedSupplier={detailedSupplier}
              onSubmit={updateOrCreateSupplierHandler}
              onCancel={closeAndReset}
              id={id}
              isSupplierCompletedCase={isSupplierCompletedCase}
              setIsSupplierCompletedCase={setIsSupplierCompletedCase}
              title={drawerTitle}
              isRetryFlow={isRetryFlow}
              showDisclaimer={
                !isRetryFlow && disclaimerIsOpen && suppliers?.length === 0
              }
              setDisclaimerIsOpen={setDisclaimerIsOpen}
              cleanForm={cleanForm}
              errorMessage={errorMessage}
            />
          )}
          {formType === 1 && (
            <ServicesForm
              detailedService={detailedService}
              onSubmit={updateOrCreateServiceHandler}
              onCancel={closeAndReset}
              id={id}
              title={drawerTitle}
            />
          )}
        </Stack>
      </div>
    </Dialog>
  );
};

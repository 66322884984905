import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import { styles } from "./styles";
import { PayanaLogo } from "../../assets";
import { useLocation } from "react-router-dom";
import { RenderCompany } from "commons";
import { useMediaQuery } from "react-responsive";

export const Companies = () => {
  const location = useLocation();
  const companies = location.state.companies;
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const getCompanies = () => {
    return companies.map((company) => {
      return (
        <RenderCompany
          company={company}
          onClose={() => {}}
          showRole={!isMobile}
        />
      );
    });
  };

  return (
    <Container>
      <Stack justifyContent="center" alignItems="center" mt={9}>
        <PayanaLogo width={190} height={42} />
        <Typography
          sx={{ ...styles.title, alignSelf: "center", textAlign: "center" }}
          mb={4}
          mt={4}
        >
          Selecciona una empresa
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "12px",
            padding: "24px",
            maxWidth: "895px",
            width: "100%",
            boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.1)",
            marginBottom: "80px",
          }}
        >
          <div style={{ marginTop: "-16px", width: "100%" }}>
            {getCompanies()}
          </div>
        </div>
      </Stack>
    </Container>
  );
};

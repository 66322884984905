import React from "react";

export const ExpandIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 7.33333L16.5 1.5M16.5 1.5H11.5M16.5 1.5V6.5M7.33333 10.6667L1.5 16.5M1.5 16.5H6.5M1.5 16.5L1.5 11.5"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";

export const PercentageIcon = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M15.8334 4.16663L4.16675 15.8333M7.50008 5.83329C7.50008 6.75377 6.75389 7.49996 5.83341 7.49996C4.91294 7.49996 4.16675 6.75377 4.16675 5.83329C4.16675 4.91282 4.91294 4.16663 5.83341 4.16663C6.75389 4.16663 7.50008 4.91282 7.50008 5.83329ZM15.8334 14.1666C15.8334 15.0871 15.0872 15.8333 14.1667 15.8333C13.2463 15.8333 12.5001 15.0871 12.5001 14.1666C12.5001 13.2462 13.2463 12.5 14.1667 12.5C15.0872 12.5 15.8334 13.2462 15.8334 14.1666Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

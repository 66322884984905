class PaymentMethod {
  constructor(name) {
    this.name = name;
  }
  isNamed(nameToCompare) {
    return nameToCompare === this.name;
  }
}

export const PAYMENT_METHOD = {
  CreditCard: new PaymentMethod("card"),
  PSE: new PaymentMethod("others"),
  BankingCorrespondent: new PaymentMethod("banking_correspondent"),
};

export const getPasswordError = (password) => {
  const containsBlankSpaces = /\s/.test(password);
  if (containsBlankSpaces) {
    return "La contraseña no puede contener espacios en blanco.";
  }
  return "";
};

export const getEmailError = (email) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return !regex.test(email) ? "Ingresa un email válido." : "";
};

import { getAccountTypeLabel } from "../../../constants";

const getSourceOfFundsLabel = (source = {}) => {
  return source.type === "card_reference"
    ? source.card_reference
    : source.type === "bank_account"
    ? `${source.bank_name} - ${getAccountTypeLabel(source.account_type)} ${
        source.account_number
      }`
    : "";
};

export { getSourceOfFundsLabel };

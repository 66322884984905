import { createContext, useState } from "react";

export const UiContext = createContext();

export function UiProvider({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingLayout, setIsLoadingLayout] = useState(false);
  const [openModalKeys, setOpenModalKeys] = useState([]);

  const toogleModal = (val = !isModalOpen) => setIsModalOpen(val);
  const toggleLayoutLinearProgress = () => setIsLoadingLayout((val) => !val);

  const state = {
    isModalOpen,
    isLoadingLayout,
    openModalKeys: openModalKeys.flatMap((modal) => modal.name),
    openModalProps: openModalKeys.reduce((acc, modal) => {
      return {
        ...acc,
        [modal.name]: modal.props,
      };
    }, {}),
  };
  const hooks = {
    setIsModalOpen,
    setIsLoadingLayout,
    setOpenModalKeys: (name, props) => {
      setOpenModalKeys((modals) => [...modals, { name, props }]);
    },
    setClosedModalKeys: (modalNameToClose) => {
      setOpenModalKeys((modals) =>
        modals.filter((modal) => modal.name !== modalNameToClose)
      );
    },
  };
  const actions = {
    toogleModal,
    toggleLayoutLinearProgress,
  };

  return (
    <UiContext.Provider
      value={{
        state,
        hooks,
        actions,
      }}
    >
      {children}
    </UiContext.Provider>
  );
}

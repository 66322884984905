import { Footer } from "../../../commons";

export const CustomerPayFooter = ({
  selectedRows,
  totalAmount,
  openWompiModal,
  totalSelected,
}) => {
  const hasTransactionId = () =>
    selectedRows.some((invoice) => invoice?.original?.transaction_id);

  return (
    <Footer
      disabled={selectedRows.length === 0}
      totalAmount={totalAmount}
      openWompiModal={openWompiModal}
      disableWompi={selectedRows.length < 1 || totalAmount <= 0}
      totalSelected={totalSelected}
      disableDelete={hasTransactionId()}
      section="customer"
    />
  );
};

import { format } from "date-fns";
import { documentRow } from "./documentRow";

export const suppliersColumns = [
  {
    field: "name",
    Header: "Proveedor",
    headerName: "Proveedor",
    accessor: "name",
    id: "name",
    className: "provider-name-column",
    Cell: ({ value }) => {
      return <p style={{ margin: 0, maxWidth: "380px" }}>{value}</p>;
    },
  },
  {
    field: "email",
    Header: "Correo electrónico",
    headerName: "Correo electrónico",
    accessor: "email",
    id: "email",
    Cell: ({ value }) => {
      return <p style={{ margin: 0, maxWidth: "380px" }}>{value}</p>;
    },
  },
  {
    field: "document_number",
    Header: "Nro. de identificación",
    headerName: "Nro. de identificación",
    accessor: "document_number",
    id: "document_number",
    Cell: ({ row }) => {
      return documentRow(row);
    },
  },
  {
    field: "bank_name",
    Header: "Entidad bancaria",
    headerName: "Entidad bancaria",
    accessor: "bank_name",
    id: "bank_name",
  },
  {
    field: "created_at",
    Header: "Fecha de creación",
    headerName: "Fecha de creación",
    accessor: "created_at",
    id: "created_at",
    Cell: ({ value }) => {
      if (value) {
        return format(new Date(value.slice(0, -1)), "dd/MM/yyyy");
      }
    },
  },
];

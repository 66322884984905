import React from "react";
import { FeatureItem } from "./FeatureItem";
import { styles } from "./styles";

export const Features = ({ features, title, planSelected }) => (
  <div style={styles.featuresContainer}>
    <p style={styles.featuresText}>{title}</p>
    {features.map((item, index) => (
      <FeatureItem
        key={index}
        title={item.title}
        price={item.price}
        discount={item.discount}
        checked={item.value === planSelected}
        value={item.value}
        onClick={item.onClick}
      />
    ))}
  </div>
);

import React, { useState, useRef } from "react";
import {
  renderAutocomplete,
  ServicesEmptyState,
} from "screens/ServicesScreenCommons/components";
import { ListPage } from "screens/ListPage";
import { servicesTransactionsColumns, subMenuItems } from "utils";
import { ServicesCreatePaymentModal } from "../ServicesPaymentScreen/ServicesCreatePaymentModal";
import { MassiveCreateResultModal } from "../ServicesPaymentScreen/MassiveCreateModal/MassiveCreateResultModal"

export const ServicesTransactionsScreen = () => {
  const [query, setQuery] = useState("");
  const [isManualCreateOpen, setIsManualCreateOpen] = useState(false);
  const csvImportButton = useRef(null);
  const openManualCreate = () => setIsManualCreateOpen(true);
  const onClose = () => setIsManualCreateOpen(false);
  const openMassiveCreate = () => csvImportButton.current.click();
  const getSearchOptions = async (q) => setQuery(q);
  const url = `/transactions?page=1&type=service&text=${query}&per_page=100`;
  const autocomplete = () =>
    renderAutocomplete({
      isMobile: false,
      inputValue: query,
      searchOptions: [],
      onTableSearchSelected: () => {},
      getSearchOptions,
    });
  return (
    <>
      <ListPage
        section="serviceTrx"
        preventListRefreshWhen={() => query.length > 1 && query.length < 3}
        url={url}
        queries={query ? { query } : {}}
        pageTitle="Lotes"
        tableColumns={servicesTransactionsColumns}
        pageEmptyState={
          <ServicesEmptyState
            openManualCreate={openManualCreate}
            openMassiveCreate={openMassiveCreate}
            currentScreenTitle={'Lotes'}
          />
        }
        subMenuItems={subMenuItems}
        renderAutocomplete={autocomplete}
      />
      <ServicesCreatePaymentModal
        visible={isManualCreateOpen}
        onClose={onClose}
      />
      <MassiveCreateResultModal csvImportButton={csvImportButton} />
    </>
  );
};

import { ArrowRightIcon, AttentionIcon } from "../../../../assets";
import styles from "./provider-incompleted-message.module.css";

function SupplierIncompletedMessage({ openSupplierForm = () => {} }) {
  return (
    <div className={styles["provider-incompleted-message-container"]}>
      <div className={styles["provider-incompleted-message-title"]}>
        <AttentionIcon />
        <div>Falta completar información</div>
      </div>
      <div className={styles["provider-incompleted-message-description"]}>
        Nos faltan algunos datos para que puedas realizar el pago de tu
        proveedor
      </div>
      <a
        href="#"
        className={styles["provider-incompleted-message-link"]}
        onClick={(e) => {
          e.preventDefault();
          openSupplierForm();
        }}
      >
        <span>Editar proveedor</span>
        <ArrowRightIcon />
      </a>
    </div>
  );
}

export default SupplierIncompletedMessage;

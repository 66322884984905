import { format } from "date-fns";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const createXLSX = (response) => {
  const {
    transactionsProcessed,
    transactions,
    invoices,
    payrolls,
    collections,
  } = response;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const transactionsProcessedRows = XLSX.utils.json_to_sheet(
    transactionsProcessed
  );
  const transactionsRows = XLSX.utils.json_to_sheet(transactions);
  const invoicesRows = XLSX.utils.json_to_sheet(invoices);
  const payrollsRows = XLSX.utils.json_to_sheet(payrolls);
  const collectionsRows = XLSX.utils.json_to_sheet(collections);

  const wb = {
    Sheets: {
      "Dispersiones y recaudos": transactionsProcessedRows,
      "Lotes bancarios": transactionsRows,
      ...(invoices.length > 0 && { Facturas: invoicesRows }),
      ...(payrolls.length > 0 && { Nóminas: payrollsRows }),
      ...(collections.length > 0 && { Cobros: collectionsRows }),
    },
    SheetNames: ["Dispersiones y recaudos", "Lotes bancarios"],
  };
  invoices.length > 0 && wb.SheetNames.push("Facturas");
  payrolls.length > 0 && wb.SheetNames.push("Nóminas");
  collections.length > 0 && wb.SheetNames.push("Cobros");

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: fileType });

  const now = new Date();

  FileSaver.saveAs(
    data,
    `Conciliación-${format(now, "dd.MM.yyyy")}-Payana` + fileExtension
  );
};

export default createXLSX;

import { NumericFormat } from "react-number-format";
import { styles } from "./styles";

export const ItemsInReceipt = ({ trxStatus, name, amount, code }) => {
  return (
    <>
      <div key={code}>
        <div style={{ ...styles.trxRow, marginTop: "-4px" }}>
          <p style={styles.rowTextLeftWithoutLimit}>{code}</p>
          <p style={styles.rowText}>
            <NumericFormat
              value={parseInt(amount)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </p>
        </div>
        <hr style={{ ...styles.separator, marginBottom: "16px" }} />
      </div>
    </>
  );
};

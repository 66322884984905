import React, { useContext } from "react";
import { TextField, Autocomplete, Paper } from "@mui/material";
import { CollectionsContext, PaymentsContext } from "../../contexts";

export const BankEntitySelector = ({
  bankSelectedId,
  validationProps,
  context = "payments",
  disabled = false,
}) => {
  const { allBanks } = useContext(PaymentsContext);
  const { banks } = useContext(CollectionsContext);
  const banksList = context === "payments" ? allBanks : banks;
  const option = banksList.find((bank) => bank.id === bankSelectedId) || {
    name: "",
  };

  function CustomPaper({ children }) {
    return (
      <Paper
        sx={{
          "& .MuiAutocomplete-listbox": {
            "& .MuiAutocomplete-option": {
              fontSize: "14px",
            },
          },
        }}
      >
        {children}
      </Paper>
    );
  }
  return (
    <Autocomplete
      disabled={disabled}
      sx={{ zIndex: 1302, width: "100%" }}
      options={banksList}
      size="small"
      freeSolo
      disablePortal
      value={option}
      PaperComponent={CustomPaper}
      getOptionLabel={(option) => (option ? option.name : "")}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 14, backgroundColor: "#FFFFFF", width: "100%" },
            placeholder: "Ingresa la entidad bancaria",
          }}
        />
      )}
      {...validationProps}
      onChange={(event, option) => {
        validationProps.onChange({
          ...event,
          target: {
            ...event.target,
            name: "bankId",
            value: option ? option.id : "",
          },
        });
      }}
    />
  );
};

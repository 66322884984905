import React, { useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Dialog } from "@mui/material";
import { GetCollectionByHash } from "../../../services";
import { styles } from "../../Table/styles";
import {
  CustomerCollectionDetailModalSkeleton,
  CustomerCollectionFormWithImage,
} from "./components";
import { useChat } from "../../../hooks";

export const ExpandCustomerCollectionModal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [collection, setCollection] = useState();
  const [style, setStyle] = useState({});

  const queryParameters = new URLSearchParams(window.location.search);
  const hash = queryParameters.get("hash");

  useChat();

  useEffect(() => {
    GetCollectionByHash({ id: id, hash: hash }).then((res) => {
      if (!res.message) {
        setCollection(res);
      } else {
        navigate("/error", {
          state: {
            isCustomer: true,
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    if (collection) {
      setStyle({ padding: "0px" });
    }
  }, [collection]);

  const onClose = () => {
    navigate(`/pay?hash=${hash}`, {
      replace: true,
      state: { statusSelected: "paid" },
    });
  };

  return (
    <>
      <Dialog fullScreen open={id} style={style}>
        <div className="modal-header-payana">
          <div style={styles.headerRow}>
            <div onClick={() => onClose()}>
              <CloseButton style={{ boxShadow: "none" }} />
            </div>
            <div
              className="modalTitle"
              style={{ marginLeft: "16px", fontSize: "18px" }}
            >
              Detalle de comprobante
            </div>
          </div>
        </div>
        <Box sx={{ padding: 2, display: "flex" }} className="collectionForm">
          {!collection ? (
            <CustomerCollectionDetailModalSkeleton />
          ) : (
            <CustomerCollectionFormWithImage
              collection={collection}
              filePath={collection.file_path}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
};

import axios from "axios";
import { TrackJS } from "trackjs";
import { RefreshToken } from "./user";
import { getFromLocalStorage, saveInLocalStorage, logOut } from "../../utils";

export async function requestContainer({
  method,
  url,
  data,
  responseType,
  customContentType = null,
  ...customConfig
}) {
  let token = getFromLocalStorage("token");
  const reqConfig = {
    method: method,
    url: `${process.env.REACT_APP_BASE_API_URL}` + url,
    headers: {
      "content-type": customContentType || "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token,
    },
    ...customConfig,
  };
  if (data) {
    reqConfig.data = data;
  }
  if (responseType) {
    reqConfig.responseType = responseType;
  }
  try {
    TrackJS.console.info(reqConfig);
    const response = await axios(reqConfig);
    TrackJS.console.info(response);
    if (response.status === 401) {
      throw new Error("Failed to make request with token");
    }
    if (response.status !== 200) {
      throw new Error("Failed to make request.");
    }
    return response.data;
  } catch (error) {
    TrackJS.console.error(error);
    if (error?.response?.status === 403)
      throw new Error(error?.response?.data?.service_response?.message || "");
    if (error?.response?.status === 504)
      throw new Error(error?.response?.data?.service_response?.message || "");
    if (error?.response?.status === 401) {
      try {
        const refreshResponse = await RefreshToken();
        if (!refreshResponse.ok) {
          throw new Error("Failed to obtain a new token");
        }
        saveInLocalStorage("token", refreshResponse.token);

        await requestContainer(method, url, data);
      } catch (error) {
        console.error("Error obtaining a new token:", error);
        logOut();
        window.location.replace("/login");
      }
    } else if (
      error?.response?.status === 500 &&
      error?.response?.data.message === "Invalid Compact JWS"
    ) {
      logOut();
      window.location.replace("/login");
    } else {
      return error;
    }
  }
}

export async function requestContainerExternalApi({
  method,
  url,
  data,
  additionalHeaders,
}) {
  const reqConfig = {
    method,
    url,
    headers: {
      "content-type": "application/json",
      ...additionalHeaders,
    },
  };
  if (data) {
    reqConfig.data = data;
  }
  try {
    const response = await axios(reqConfig);
    if (response.status !== 200) {
      throw new Error("Failed to make request.");
    }
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data || "");
  }
}

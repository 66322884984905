import { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Switch from "react-ios-switch";
import { useMediaQuery } from "react-responsive";
import {
  ButtonWithIcon,
  DeleteItemModal,
  TableSkeleton,
  UserTutorialModal,
  UsersModal,
} from "../../../commons";
import { SecondaryButton } from "../../../commons/buttons";
import { toast } from "react-toastify";
import {
  ADMIN,
  permissions,
  toastOptions,
  userRoles,
} from "../../../constants";
import { PlusCircleIcon } from "../../../assets";
import { UserContext } from "../../../contexts";
import { DeleteUser, GetApprovalRequirements } from "../../../services";
import { styles } from "../styles";
import { UsersActionsDropdown } from "./UsersActionsDropdown";
import { SettingsFooter } from "./FooterHandler";
import { useIsUserAllowedTo } from "../../../utils";
import { useLocation } from "react-router-dom";

export const UsersTab = () => {
  const { getOtherUsers, otherUsers, currentUser, updateCompany } =
    useContext(UserContext);
  const location = useLocation();
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [personToEdit, setPersonToEdit] = useState();
  const [loading, setLoading] = useState(true);
  const [formModalIsVisible, setFormModalIsVisible] = useState(false);
  const [tutorialModalIsVisible, setTutorialModalIsVisible] = useState(
    location.state && location.state.goToUsers ? true : false
  );
  const [actionModal, setActionModal] = useState("");
  const [userToDelete, setUserToDelete] = useState(false);
  const [checked, setChecked] = useState(false);
  const [saveSettingsButtonIsDisabled, setSaveSettingsButtonIsDisabled] =
    useState(true);
  const [deleteUserModalIsVisible, setDeleteUserModalIsVisible] =
    useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  useEffect(() => {
    Promise.all([getOtherUsers(), GetApprovalRequirements()]).then(
      ([users, approvalRequirements]) => {
        setChecked(approvalRequirements.invoices);
        setLoading(false);
      }
    );
  }, []);

  const closeDeleteUserModal = () => setDeleteUserModalIsVisible(false);

  const openDeleteUserModal = (id) => {
    setUserToDelete(id);
    setDeleteUserModalIsVisible(true);
  };

  const closeFormUserModal = () => {
    setFormModalIsVisible(false);
    setPersonToEdit();
  };
  const openFormUsersModal = (selectedPerson, action) => {
    setFormModalIsVisible(true);
    setActionModal(action);

    if (action === "edit") setPersonToEdit(selectedPerson);
  };

  const deleteUser = async (id) => {
    const response = await DeleteUser(id);

    if (response && response.message === "user deleted") {
      toast.success("El usuario se eliminó con éxito.", toastOptions);
      await getOtherUsers();
    } else {
      toast.error("Hubo un error.", toastOptions);
    }

    response.status === 200 && closeDeleteUserModal();
  };

  const saveSettingsChanges = () => {
    updateCompany({
      approvalRequired: [
        {
          role_id: 2,
          invoices: checked,
          payrolls: false,
          collections: false,
        },
      ],
    }).then(() => {
      toast.success("Los cambios se guardaron con éxito.", toastOptions);
      setSaveSettingsButtonIsDisabled(true);
    });
  };

  const getUsersByRole = (role) => {
    return otherUsers
      .filter((user) => user.role_id === role)
      .map((person) => {
        const initials = person?.name
          ?.match(/\b(\w)/g)
          ?.join("")
          ?.toUpperCase()
          ?.slice(0, 2);

        return (
          <Box key={person.name} style={styles.userContainer}>
            <Stack direction={"row"} justifyContent="space-between">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  width: "50%",
                }}
              >
                <div
                  style={{
                    width: "53px",
                    height: "53px",
                    borderRadius: "150px",
                    backgroundColor: "#D0D5DD",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={styles.initials}>{initials}</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={styles.nameField}>
                    {person.name}{" "}
                    {person.id === currentUser.id && (
                      <span style={{ fontWeight: 400 }}>(tú)</span>
                    )}
                    {person.is_creator && (
                      <span style={{ fontWeight: 400, color: "#667085" }}>
                        {" "}
                        Gerente
                      </span>
                    )}
                  </Typography>
                  <Typography sx={styles.emailField}>{person.email}</Typography>
                </div>
              </div>
              <Typography sx={styles.roleText}>
                {
                  userRoles.find(({ value }) => value === person.role_name)
                    ?.label
                }
              </Typography>
              {currentUser.role.name === ADMIN ? (
                <UsersActionsDropdown
                  editAction={openFormUsersModal}
                  deleteAction={openDeleteUserModal}
                  person={person}
                />
              ) : (
                <div style={{ height: "40px", width: "40px" }}></div>
              )}
            </Stack>
          </Box>
        );
      });
  };

  return (
    <>
      <DeleteItemModal
        itemId={userToDelete}
        action={deleteUser}
        handleClose={closeDeleteUserModal}
        title="¿Quieres borrar este usuario?"
        subtitle="Si lo eliminas, no podrá ingresar a Payana nuevamente."
        visible={deleteUserModalIsVisible}
      />
      <UsersModal
        person={personToEdit}
        handleClose={closeFormUserModal}
        visible={formModalIsVisible}
        notificationHandler={toast}
        toastOptions={toastOptions}
        action={actionModal}
      />
      <UserTutorialModal
        handleClose={() => setTutorialModalIsVisible(false)}
        visible={tutorialModalIsVisible}
        openFormUsersModal={openFormUsersModal}
      />
      <Box
        sx={
          isMobile
            ? styles.usersScreenMobileContainer
            : styles.usersScreenContainer
        }
        px={4}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Typography sx={styles.title} noWrap>
            Roles y permisos
          </Typography>
          {otherUsers.length > 1 && (
            <div onClick={() => setTutorialModalIsVisible(true)}>
              <Typography sx={styles.titleLink} noWrap>
                ¿Cómo funciona?
              </Typography>
            </div>
          )}
        </div>
        {!loading ? (
          <>
            {otherUsers.length === 1 && (
              <Box style={styles.tutorialContainer}>
                <Typography sx={styles.tutorialTitle} noWrap>
                  Crea usuarios para que colaboren en tu Empresa.
                </Typography>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "14px" }}
                >
                  <SecondaryButton
                    text="¿Cómo funciona?"
                    action={() => setTutorialModalIsVisible(true)}
                    width={"160px"}
                  />
                  <ButtonWithIcon
                    action={() => openFormUsersModal(null, "create")}
                    buttonLabel={"Crear usuario"}
                    icon={
                      <PlusCircleIcon stroke="#FFFFFF" width="16" height="20" />
                    }
                    width={"160px"}
                  />
                </div>
              </Box>
            )}
            <Stack direction="row" justifyContent="flex-start" my={4}>
              <Box sx={styles.container}>
                {otherUsers.length === 1 ? (
                  <Typography sx={styles.subtitle} noWrap>
                    Usuarios
                  </Typography>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      justifyContent: "space-between",
                      alignItems: isMobile ? "flex-start" : "flex-end",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Typography sx={styles.subtitle} noWrap>
                        Usuarios
                      </Typography>
                      <Typography
                        sx={isMobile ? styles.mobileText : styles.text}
                        noWrap
                      >
                        ¿Quiénes quieres que colaboren en tus transacciones?
                      </Typography>
                    </div>
                    {currentUser.role.name === ADMIN && (
                      <ButtonWithIcon
                        action={() => openFormUsersModal(null, "create")}
                        buttonLabel={"Agregar usuario"}
                        icon={
                          <PlusCircleIcon
                            stroke="#FFFFFF"
                            width="16"
                            height="20"
                          />
                        }
                        width={"160px"}
                        style={{ marginTop: isMobile ? "8px" : "0px" }}
                      />
                    )}
                  </div>
                )}
                {otherUsers?.some((user) => user.role_id === 1) && (
                  <Box sx={styles.usersTableContainer}>
                    <Typography sx={styles.userCategoryTitle} noWrap>
                      ADMINISTRADORES
                    </Typography>
                    {getUsersByRole(1)}
                  </Box>
                )}
                {otherUsers?.some((user) => user.role_id === 2) && (
                  <Box sx={styles.usersTableContainer}>
                    <Typography sx={styles.userCategoryTitle} noWrap>
                      COLABORADORES
                    </Typography>
                    {getUsersByRole(2)}
                  </Box>
                )}
                {otherUsers?.some((user) => user.role_id === 3) && (
                  <Box sx={styles.usersTableContainer}>
                    <Typography sx={styles.userCategoryTitle} noWrap>
                      CONTADORES
                    </Typography>
                    {getUsersByRole(3)}
                  </Box>
                )}
                {isUserAllowedTo(permissions.CONFIG_APPROVAL_REQUIRMENTS) && (
                  <Box sx={styles.usersTableContainer}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={styles.subtitle} noWrap>
                        Requerimiento de aprobación de documentos
                      </Typography>
                      <Switch
                        checked={checked}
                        className={undefined}
                        disabled={
                          !isUserAllowedTo(
                            permissions.CONFIG_APPROVAL_REQUIRMENTS
                          )
                        }
                        handleColor="white"
                        name={undefined}
                        offColor="#D0D5DD"
                        onChange={() => {
                          setChecked(!checked);
                          setSaveSettingsButtonIsDisabled(false);
                        }}
                        onColor="#7F56D9"
                        pendingOffColor={undefined}
                        pendingOnColor={undefined}
                        style={{ transform: "scale(0.7)", fontSize: "14px" }}
                      />
                    </div>
                    <Typography sx={styles.approvalText}>
                      Activa esta opción para que los colaboradores deban
                      obtener la aprobación de un administrador en cada
                      documento del módulo de proveedores.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Stack>
          </>
        ) : (
          <TableSkeleton />
        )}
      </Box>
      {currentUser.role.name === ADMIN && (
        <SettingsFooter
          saveSettingsChanges={saveSettingsChanges}
          saveSettingsButtonIsDisable={saveSettingsButtonIsDisabled}
        />
      )}
    </>
  );
};

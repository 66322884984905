import React from "react";
import { SubscriptionIcon } from "./SubscriptionIcon";
import { SubscriptionTitle } from "./SubscriptionTitle";
import { BlockIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import { Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { styles } from "./styles";

export const ModalInactiveSuscriber = ({
  handleOpenModalPayment,
  invoicesSubscriptionPending,
  handleSuccessSubscription,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleRedirectToPaySubscription = () => {
    handleSuccessSubscription();
    if (location.pathname === "/payments/invoices") {
      handleOpenModalPayment();
    } else {
      navigate("/payments/invoices", {
        replace: true,
        state: {
          totalAmount: invoicesSubscriptionPending.amount_total,
          selectedRowsIds: [invoicesSubscriptionPending.id],
          action: "paySubscription",
        },
      });
    }
  };
  return (
    <Stack gap={"16px"}>
      <SubscriptionIcon icon={<BlockIcon />} />
      <SubscriptionTitle
        title={"Suscripción inactiva"}
        style={{ marginBottom: "0px" }}
      />
      <div>
        <p style={styles.inactiveFristText}>
          Tu acceso a la solución de Tesorería fue desactivado debido a un pago
          pendiente de la suscripción.
        </p>
        <p style={styles.inactiveSecondText}>
          Para seguir utilizando esta funcionalidad, abona la factura vencida.
        </p>
      </div>

      <PrimaryButton
        style={{ width: "100%" }}
        text={"Pagar ahora"}
        action={handleRedirectToPaySubscription}
      />
    </Stack>
  );
};

import React from "react";
import { Box } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { styles } from "./styles";
import { ModalDeleteIcon } from "../../../assets";

export const FullDeleteModal = ({
  title,
  subtitle = "",
  visible,
  handleClose,
  onClick,
}) => {
  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body style={{ boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)" }}>
        <Box sx={styles.container}>
          <ModalDeleteIcon />
          <p style={styles.title}>{title}</p>
          <p style={styles.subtitle}>{subtitle}</p>
          <div style={styles.row}>
            <Button
              variant="secondary"
              style={styles.modalSecondaryButton}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              style={styles.modalPrimaryButton}
              onClick={onClick}
            >
              Confirmar
            </Button>
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

import { Stack, Typography } from "@mui/material";
import { ValidationMessage } from "../../../components";
import { useForm, useWatch } from "react-hook-form";
import { styles } from "../../SupplierDetails/styles";
import { PrimaryButton, SecondaryButton } from "../../../buttons";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import addMonths from "date-fns/addMonths";
import { UserContext } from "contexts";
import { useContext } from "react";

const DianIntegrationURLForm = ({
  primaryAction,
  resendAccessToken,
  loading,
  setLoading,
  showStartDate,
  credentialsError = false,
  setCredentialsError = () => {},
  data = {},
}) => {
  const { dianUserType } = useContext(UserContext);
  const { register, handleSubmit, formState, control } = useForm({
    defaultValues: data || {
      url: "",
      startDate: "",
    },
  });
  const formValues = useWatch({ control });
  const extractedUrl = extractUrlFromText(formValues.url);
  const isFormComplete = !!extractedUrl;
  const validateDianURL = () => {
    const urlRegex = /^(https?:\/\/)?[\da-z.-]+\.[a-z.]{2,6}([/?].*)?$/i;
    if (!urlRegex.test(extractedUrl)) {
      return "URL inválida";
    }
    return true;
  };

  const helperText = credentialsError ? "Revisa los datos." : "";

  function extractUrlFromText(text) {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const matches = text?.match(urlPattern);
    if (matches && matches.length > 0) {
      return matches[0].trim();
    } else {
      return "";
    }
  }

  const onSubmit = async () => {
    setLoading(true);
    setCredentialsError(false);
    await primaryAction({
      url: extractedUrl,
      start_date: formValues.startDate,
    });
    setLoading(false);
  };

  const datePickerProps = showStartDate
    ? register("startDate", { required: true })
    : {};
  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Stack my={2} spacing={2}>
        <Stack>
          <p style={{ ...styles.inputLabel, marginBottom: "5px" }}>
            Cómo obtener el token de acceso
          </p>
          <ol style={{ marginBottom: "0px", paddingLeft: "20px" }}>
            <li
              style={{
                color: "#667085",
                fontSize: "14px",
              }}
            >
              {dianUserType === "natural"
                ? "Ingresa al portal de habilitación de la DIAN."
                : "Ingresa en el correo electrónico."}
            </li>
            <li
              style={{
                color: "#667085",
                fontSize: "14px",
              }}
            >
              {dianUserType === "natural"
                ? "Solicita el token de acceso."
                : 'Haz clic derecho en el botón "Ingrese aquí" y seleccione "Copiar la dirección del enlace".'}
            </li>
            <li
              style={{
                color: "#667085",
                fontSize: "14px",
              }}
            >
              Pegue en el siguiente campo la URL del token de acceso.
            </li>
          </ol>
        </Stack>

        <Stack spacing={1}>
          <label htmlFor="url" style={styles.inputLabel}>
            Token de acceso
          </label>
          <textarea
            rows={3}
            style={{
              resize: "none",
              padding: "6px",
              outline: "none",
              borderRadius: 8,
            }}
            spellCheck={false}
            {...register("url", { required: true, validate: validateDianURL })}
            value={extractedUrl}
          />
          {helperText && (
            <Typography sx={styles.inputError} noWrap>
              {helperText}
            </Typography>
          )}
          <ValidationMessage errors={formState.errors} field="url" />
        </Stack>
      </Stack>
      {showStartDate && (
        <Stack my={2} spacing={2}>
          <Stack spacing={1}>
            <label htmlFor="startDate" style={styles.inputLabel}>
              Desde cuando importaremos la información
            </label>
            <LocalizationProvider
              adapterLocale={es}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                value={formValues.startDate}
                format="dd/MM/yyyy"
                slotProps={{ textField: { error: false, size: "small" } }}
                maxDate={new Date()}
                minDate={addMonths(new Date(), -3)}
                {...datePickerProps}
                onChange={(value) => {
                  datePickerProps.onChange({
                    target: {
                      name: "startDate",
                      value,
                    },
                  });
                }}
              />
            </LocalizationProvider>
            <ValidationMessage errors={formState.errors} field="startDate" />
          </Stack>
        </Stack>
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <PrimaryButton
          width="100%"
          text="Importar información"
          isDisable={loading || !isFormComplete}
          loading={loading}
          type="submit"
        />
        {dianUserType !== "natural" && (
          <SecondaryButton
            isDisable={loading}
            width="100%"
            text="Solicitar nuevo token"
            action={resendAccessToken}
          />
        )}
      </div>
    </form>
  );
};
export default DianIntegrationURLForm;

import React from "react";

export const ModalFileIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <rect x="2" y="2" width="32" height="32" rx="16" fill="#EBE9FE" />
    <path
      d="M19.3334 11.5131V14.2668C19.3334 14.6402 19.3334 14.8269 19.4061 14.9695C19.47 15.0949 19.572 15.1969 19.6974 15.2608C19.84 15.3335 20.0267 15.3335 20.4001 15.3335H23.1538M23.3334 16.659V21.4668C23.3334 22.5869 23.3334 23.147 23.1154 23.5748C22.9237 23.9511 22.6177 24.2571 22.2414 24.4488C21.8136 24.6668 21.2535 24.6668 20.1334 24.6668H15.8667C14.7466 24.6668 14.1866 24.6668 13.7588 24.4488C13.3824 24.2571 13.0765 23.9511 12.8847 23.5748C12.6667 23.147 12.6667 22.5869 12.6667 21.4668V14.5335C12.6667 13.4134 12.6667 12.8533 12.8847 12.4255C13.0765 12.0492 13.3824 11.7432 13.7588 11.5515C14.1866 11.3335 14.7466 11.3335 15.8667 11.3335H18.0079C18.4971 11.3335 18.7417 11.3335 18.9719 11.3888C19.176 11.4377 19.371 11.5186 19.55 11.6282C19.7518 11.7519 19.9248 11.9249 20.2707 12.2708L22.3962 14.3962C22.7421 14.7421 22.915 14.9151 23.0387 15.1169C23.1484 15.2959 23.2292 15.491 23.2782 15.695C23.3334 15.9252 23.3334 16.1698 23.3334 16.659Z"
      stroke="#6938EF"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2"
      y="2"
      width="32"
      height="32"
      rx="16"
      stroke="#F4F3FF"
      strokeWidth="4"
    />
  </svg>
);

import { useContext, useState } from "react";
import DianIntegrationURLForm from "../DianIntegrationURLForm";
import DianIntegrationValues from "../DianIntegrationValues";
import { styles } from "../../../SupplierDetails/styles";
import { ContactSupport } from "commons/components";
import { UserContext } from "contexts";

export const StepTwo = ({
  primaryAction,
  dianData,
  editDianData,
  resendAccessToken,
  showStartDate = false,
  credentialsError = false,
  setCredentialsError = () => {},
  data = {},
}) => {
  const { dianUserType } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{ ...styles.column, paddingLeft: "16px", paddingRight: "16px" }}
    >
      <p style={{ ...styles.title, textAlign: "center" }}>
        <strong>Conectarse a la DIAN</strong>
      </p>
      {dianUserType === "natural" ? (
        <p
          className="dian-modal-text"
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
        >
          Debes ingresar en tu cuenta de la DIAN para obtener tu Token de
          acceso.
        </p>
      ) : (
        <>
          <p className="dian-modal-text">
            <span>
              Se ha enviado el token de acceso al correo registrado en el RUT de
              la persona natural que se autenticó en el sistema.
              <strong> El acceso estará disponible por 60 minutos</strong>.
            </span>
          </p>
          <DianIntegrationValues
            loading={loading}
            dianData={dianData}
            editDianData={editDianData}
          />
        </>
      )}
      <DianIntegrationURLForm
        loading={loading}
        showStartDate={showStartDate}
        setLoading={setLoading}
        primaryAction={primaryAction}
        resendAccessToken={resendAccessToken}
        credentialsError={credentialsError}
        setCredentialsError={setCredentialsError}
        data={data}
      />
      <ContactSupport />
    </div>
  );
};

import React, { useContext } from "react";
import { ArrowRightWithoutStick, CrownIcon, SiigoLogo } from "assets";
import { Typography } from "@mui/material";
import { WithoutBorderButton } from "commons/buttons";
import { styles } from "../styles";
import { Tooltip } from "commons";
import { useNavigate } from "react-router-dom";
import { TransactionsContext } from "contexts";

export const EgressSection = ({ isDisabled = true, transaction }) => {
  const { subscription } = useContext(TransactionsContext);
  const navigate = useNavigate();

  const styleContainer = {
    ...styles.egressSectionContainer,
    ...(isDisabled && { borderColor: "#D0D5DD", backgroundColor: "#F9FAFB" }),
  };

  const redirectToExpenses = (documentNumber, receiptId) => {
    navigate(`/expenses/${documentNumber}/${receiptId}`);
  };

  const handleAction = () => {
    subscription?.handleActionSubscription(() =>
      redirectToExpenses(
        transaction?.beneficiary_document_number,
        transaction?.id
      )
    );
  };

  return (
    <div style={styleContainer}>
      <div style={styles.egressSectionRow}>
        {subscription?.showIcon ? (
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CrownIcon size="small" fill="#EFA83C" />
          </div>
        ) : (
          <span style={styles.siigoLogoContainer}>
            <SiigoLogo
              style={
                isDisabled ? { filter: " grayscale(100%) opacity(50%)" } : {}
              }
            />
          </span>
        )}
        <Typography
          sx={{
            ...styles.egressSectionTitle,
            color: isDisabled ? "#98A2B3" : "#1D2939",
          }}
        >
          Registrar egreso
        </Typography>
      </div>
      <Tooltip
        title={
          isDisabled
            ? "Podrás realizar el egreso cuando el pago se haya dispersado totalmente"
            : ""
        }
      >
        <div>
          <WithoutBorderButton
            text="Ir"
            action={handleAction}
            width="48px"
            style={{
              ...styles.sectionButton,
              color: isDisabled ? "#98A2B3" : "#5925DC",
            }}
            contentStyle={{ justifyContent: "flex-end" }}
            endIcon={
              <ArrowRightWithoutStick
                stroke={isDisabled ? "#98A2B3" : "#5925DC"}
                width="20px"
                height="20px"
              />
            }
          />
        </div>
      </Tooltip>
    </div>
  );
};

import { Box, Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import { EmptyCheckIcon } from "../../../assets";
import { PrimaryButton, SecondaryButton } from "../../../commons/buttons";
import { getWppLink, openInNewTab } from "../../../utils";
import { useNavigate } from "react-router-dom";

export const CausationComingSoon = ({
  companyName,
  type,
  setCausationComingSoon,
}) => {
  const navigate = useNavigate();
  const phoneNumber = "+573103619890";
  const message = `Escribo de la empresa ${companyName} y quiero conocer más sobre el producto de causación`;

  const button = {
    siigo: (
      <PrimaryButton
        text="Hablar con un experto"
        action={() => openInNewTab(getWppLink(phoneNumber, message))}
        width="100%"
      />
    ),
    dian: (
      <PrimaryButton
        text="Empezar"
        action={() => setCausationComingSoon(false)}
        width="100%"
      />
    ),
    emptyState: (
      <PrimaryButton
        text="Empezar"
        action={() => setCausationComingSoon(false)}
        width="100%"
      />
    ),
  };

  const items = [
    {
      icon: <EmptyCheckIcon />,
      text: "Importamos tu información de Siigo como centros de costo, formas de pago y productos",
    },
    {
      icon: <EmptyCheckIcon />,
      text: "Importamos tus facturas directamente desde la DIAN",
    },
    {
      icon: <EmptyCheckIcon />,
      text: "Leemos con inteligencia artificial la información de la factura",
    },
    {
      icon: <EmptyCheckIcon />,
      text: "Completas unos pocos campos restantes en la factura",
    },
    {
      icon: <EmptyCheckIcon />,
      text: "¡Listo! Envías la factura a Siigo desde Payana",
    },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          ...styles.emptyStateBox,
          maxWidth: "480px",
          gap: "4px",
          height: "100%",
        }}
      >
        <Stack spacing={2} p={4} sx={styles.emptyStateStack}>
          <Box>
            <Typography sx={{ ...styles.emptyStateTitle, marginBottom: "4px" }}>
              Causa gratis tus facturas en Siigo
            </Typography>
            <Typography sx={styles.emptyStateSubtitle}>
              Agiliza el proceso de recepción de facturas y ahorra un 70% del
              tiempo que inviertes causando.
            </Typography>
          </Box>
          <div style={styles.containerComingSoonText}>
            <Typography sx={styles.emptyStateTitle}>
              ¿Cómo lo hacemos?
            </Typography>
            {items.map((item) => (
              <div style={styles.emptyRow}>
                <div style={{ width: "24px", height: "24px" }}>{item.icon}</div>
                <Typography sx={styles.emptyInfoText}>{item.text}</Typography>
              </div>
            ))}
          </div>
          {button[type]}
          <SecondaryButton
            text="Volver"
            action={() =>
              navigate("/payments/invoices", {
                replace: true,
              })
            }
            width="100%"
          />
        </Stack>
      </Box>
    </div>
  );
};

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Box, CssBaseline, LinearProgress } from "@mui/material";
import { Navbar } from "./Navbar";
import {
  PAGES_WITHOUT_NAVBAR,
  HIDE_NAVBAR_MENU_OPTIONS,
  HIDE_NAVBAR_MENU_USER,
} from "../../screens/SettingsScreen/constants";
import useUserGuiding from "../../hooks/useUserGuiding";
import { hasSession } from "../../utils";
import { UiContext } from "../../contexts/UiContext";
import { CancelTransactionModal } from "../modals/DeleteItemModal/CancelTransactionModal";
import { DeleteItemModal, PaymentModal } from "commons/modals";
import { CHECKOUT_MODAL, DELETE_ITEM_MODAL } from "commons/modals/keys";
import { UserContext } from "contexts";

export function Layout({ children }) {
  const location = useLocation();
  useUserGuiding();
  const hasNavbar =
    hasSession() && !PAGES_WITHOUT_NAVBAR.includes(location.pathname);
  const showMenuOptions =
    hasSession() && !HIDE_NAVBAR_MENU_OPTIONS.includes(location.pathname);

  const showUserMenu =
    hasSession() && !HIDE_NAVBAR_MENU_USER.includes(location.pathname);
  const { state } = useContext(UiContext);
  const { canPayWithCobre, displayCobreAlert } = useContext(UserContext);
  const { isLoadingLayout, openModalKeys, openModalProps } = state;
  return (
    <Box>
      <CssBaseline />
      {hasNavbar && (
        <Navbar
          isLoadingLayout={isLoadingLayout}
          showMenuOptions={showMenuOptions}
          showUserMenu={showUserMenu}
        />
      )}
      {isLoadingLayout && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress sx={{ height: 2 }} />
        </Box>
      )}
      <main>{children}</main>
      <CancelTransactionModal
        visible={openModalKeys.includes("cancel-transaction-modal")}
        {...openModalProps["cancel-transaction-modal"]}
      />
      <PaymentModal
        visible={openModalKeys.includes(CHECKOUT_MODAL)}
        {...openModalProps[CHECKOUT_MODAL]}
        canPayWithCobre={canPayWithCobre}
        displayCobreAlert={displayCobreAlert}
      />
      <DeleteItemModal
        visible={openModalKeys.includes(DELETE_ITEM_MODAL)}
        {...openModalProps[DELETE_ITEM_MODAL]}
      />
    </Box>
  );
}

export const CircleMas = ({ width = 24, height = 24, displayPlus = true }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="18" height="18" rx="9" fill="white" />
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="9"
      stroke="#98A2B3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="1 3"
    />
    {displayPlus && (
      <path
        d="M12 7.33337V16.6667M7.33337 12H16.6667"
        stroke="#98A2B3"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
  </svg>
);

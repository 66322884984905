import { useState } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { Modal } from "react-bootstrap";
import { ModalWarningIcon } from "../../../assets";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { useContext } from "react";
import { PayrollContext } from "../../../contexts";

export const PayrollUploadResultModal = ({
  visible,
  handleClose,
  serviceResponse,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const { getPayrolls, updateMultiplePayrollsPeriod } =
    useContext(PayrollContext);

  const closeAndReset = () => {
    setSelectedPeriod("");
    getPayrolls(1);
    handleClose();
  };

  const updatePayroll = () => {
    updateMultiplePayrollsPeriod(
      serviceResponse.created_payrolls_ids,
      selectedPeriod
    );
    closeAndReset();
  };

  return (
    <Modal
      show={visible}
      onHide={handleClose}
      centered
      style={{
        zIndex: 1310,
      }}
    >
      <Modal.Body style={{ boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)" }}>
        <Box sx={styles.container}>
          <ModalWarningIcon />
          <p style={styles.title}>Carga de archivo de nóminas</p>
          <Stack textAlign={"left"} px={4}>
            <Typography align="left" style={styles.subtitle}>
              {serviceResponse.status === "ok"
                ? "✅ La operación se realizó con éxito."
                : "❌ Hubo un error en la carga de nómina."}
            </Typography>
            {serviceResponse.new_employees_created && (
              <Typography style={styles.subtitle}>
                ✅ Para la carga de la nómina se agregaron nuevos empleados.
              </Typography>
            )}
            {!serviceResponse.has_period && (
              <Box sx={styles.container} mt={2}>
                <Typography style={styles.subtitle2}>
                  Para finalizar la carga de la nómina por favor, elija un
                  concepto.
                </Typography>
                <Stack spacing={1} mt={1}>
                  <Typography sx={styles.inputLabel}>Concepto</Typography>
                  <TextField
                    variant="outlined"
                    onChange={(e) => setSelectedPeriod(e.target.value)}
                    value={selectedPeriod}
                  />
                </Stack>
              </Box>
            )}
          </Stack>
          <Stack spacing={2} direction={"row"} mt={4} mb={2}>
            <SecondaryButton text="Cancelar" action={closeAndReset} />
            <PrimaryButton
              text="Aceptar"
              action={
                serviceResponse.has_period ? closeAndReset : updatePayroll
              }
            />
          </Stack>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Box, Fade, Stack, Typography } from "@mui/material";
import { AlegraLogo } from "../../../../assets";
import { FormInputs } from "./FormInputs";
import { PrimaryButton, SecondaryButton } from "../../../buttons";
import { styles } from "../styles";
import { checkCredencialsAndUpdateCompanyAlegra } from "../../../../services";
import { UserContext } from "../../../../contexts";
import { toast } from "react-toastify";
import { toastOptions } from "../../../../constants";

const SYSTEM_NAME = "Alegra";

export function FormUploadCredential({ onClose, handleSyncAlegra }) {
  const { currentCompany } = useContext(UserContext);

  const [formUser, setFormUser] = useState({
    user: currentCompany?.user_cs || "",
    password: currentCompany?.password_cs || "",
  });
  const [syncError, setSyncError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChangeForm = (event) => {
    const { name, value } = event.target;
    setFormUser((prev) => ({ ...prev, [name]: value }));
    syncError !== "" && setSyncError("");
  };

  const handleOnClickConfirmButton = async () => {
    try {
      setIsLoading(true);
      const resp = await checkCredencialsAndUpdateCompanyAlegra({
        user_cs: formUser.user,
        password_cs: formUser.password,
        name_cs: SYSTEM_NAME,
      });
      if (
        resp?.response?.data?.message ===
        "Alegra.updateCredentials.error Credenciales Incorrectas"
      ) {
        return setSyncError("Usuario o contraseña inválidos.");
      }

      handleSyncAlegra();
    } catch (error) {
      toast.error(
        "Ocurrió un error al momento de guardar las credenciales.",
        toastOptions
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ textAlign: "center" }} mb={3}>
        <AlegraLogo />
      </Box>
      <Typography textAlign={"center"} variant="h6" component="h2">
        Inicio de sesión en Alegra
      </Typography>
      <Typography textAlign={"center"} sx={{ mt: 1 }}>
        Ingrese sus credenciales para comenzar a operar
      </Typography>
      <Stack spacing={3} my={2}>
        <FormInputs
          label={"Usuario"}
          name="user"
          size="small"
          variant="outlined"
          placeholder="nombre@empresa.com"
          type="email"
          onChange={handleOnChangeForm}
          value={formUser.user}
        />
        <FormInputs
          label={"Contraseña"}
          name="password"
          size="small"
          variant="outlined"
          placeholder="********"
          type="password"
          onChange={handleOnChangeForm}
          value={formUser.password}
        />
        <Fade in={!!syncError}>
          <Typography sx={styles.errorLabel}>{syncError}</Typography>
        </Fade>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <SecondaryButton text="Cancelar" action={onClose} width="150px" />
          <PrimaryButton
            text="Confirmar"
            action={handleOnClickConfirmButton}
            width="150px"
            loading={isLoading}
          />
        </Stack>
      </Stack>
    </>
  );
}

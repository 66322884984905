import React from "react";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { DOCUMENT_NUMBER_VALIDATOR, documentTypesSpecs } from "../../utils";
import { RequiredLabel, ValidationMessage } from "../components";

export const DocumentNumberInput = ({
  label = "Número de identificación",
  value,
  setValue,
  documentType = "NIT",
  register,
  errors,
  disabled = false,
  backgroundColor = "#FFFFFF",
  id = "documentNumber",
  required = true,
}) => {
  const handleChange = (event) => {
    const formattedValue = event.replace(
      documentTypesSpecs[documentType.toLowerCase()].regex,
      ""
    );
    setValue(formattedValue);
  };

  return (
    <>
      <RequiredLabel
        label={label}
        sx={{ fontSize: "14px", cursor: "pointer", color: "#1D2939" }}
      />
      <TextField
        id={id}
        size="small"
        variant="outlined"
        placeholder={
          documentType
            ? documentTypesSpecs[documentType.toLowerCase()]?.placeholder
            : "Número de identificación"
        }
        disabled={!documentType || disabled}
        style={{ width: "100%", backgroundColor: backgroundColor }}
        InputProps={{
          style: { fontSize: 14 },
          startAdornment: (
            <InputAdornment position="start">
              {documentType} {documentType ? "|" : ""}
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength: documentTypesSpecs[documentType.toLowerCase()]?.maxLength,
        }}
        value={value?.toString()}
        {...register(
          id,
          DOCUMENT_NUMBER_VALIDATOR(required, documentType, handleChange)
        )}
      />
      {!errors[id] && (
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "500",
            cursor: "pointer",
            color: "#667085",
          }}
        >
          {!documentType
            ? "Seleccione el tipo de identificación para ingresar el número"
            : documentTypesSpecs[documentType.toLowerCase()]?.hint || ""}
        </Typography>
      )}
      <ValidationMessage errors={errors} field={id} />
    </>
  );
};

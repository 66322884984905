export const RefreshOneIcon = ({
  height = 32,
  width = 32,
  strokeColor = "#344054",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14 6.66667C14 6.66667 12.6634 4.84548 11.5775 3.75883C10.4916 2.67218 8.99098 2 7.33337 2C4.01967 2 1.33337 4.68629 1.33337 8C1.33337 11.3137 4.01967 14 7.33337 14C10.0688 14 12.3766 12.1695 13.0989 9.66667M14 6.66667V2.66667M14 6.66667H10"
      stroke={strokeColor}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";
import { Typography } from "@mui/material";
import { styles } from "../styles";
import { PAID_OUTSIDE } from "../../../../constants";
import CollectionTimelineItem from "./CollectionTimelineItem";

const CollectionTimeline = ({ collection }) => {
  return (
    <>
      {collection.status === PAID_OUTSIDE && (
        <div style={styles.paidOutsideContainer}>
          <Typography sx={styles.paidOutsideText}>
            Cobro abonado por fuera de Payana.
          </Typography>
        </div>
      )}
      <div style={styles.timelineContainer}>
        <div style={styles.timelineRow}>
          <CollectionTimelineItem
            isDone={!!collection.date_sent}
            text={"Enviada"}
            date={collection?.date_sent}
          />
          <div style={styles.timelineDivider} />
          <CollectionTimelineItem
            isDone={!!collection.transaction_date}
            text={"Por acreditar"}
            date={collection?.transaction_paid_at}
          />
          <div style={styles.timelineDivider} />
          <CollectionTimelineItem
            isDone={!!collection.paid_at || !!collection.date_paid}
            text={
              collection.status === PAID_OUTSIDE
                ? "Cobrada por fuera"
                : "Cobrada"
            }
            date={collection?.paid_at || collection?.date_paid}
          />
        </div>
      </div>
    </>
  );
};

export default CollectionTimeline;

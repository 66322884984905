export const RoleChecklistItem = ({ text, icon }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "flex-start",
        height: "auto",
        maxWidth: "410px",
        marginBottom: "-8px",
      }}
    >
      <div style={{ width: "16px", height: "16px" }}>{icon}</div>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
          color: "#475467",
          marginTop: "3px",
        }}
      >
        {text}
      </p>
    </div>
  );
};

import { Box, Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import { PayrollButtonGroup } from "./PayrollButtonGroup";
import { useContext } from "react";
import { PayrollContext } from "../../../contexts";
import { useState } from "react";
import { SelectPayrollPeriodModal } from "../../../commons";
import { InfoPayment } from "../../../commons/components/InfoPayment";

export const PayrollEmptyState = ({
  handleClose,
  buttonRef,
  openUploadFileModal,
}) => {
  const { selectPeriodModalIsOpen, setSelectPeriodModalIsOpen } =
    useContext(PayrollContext);
  const [periodModalAction, setPeriodModalAction] = useState();

  const openPeriodModal = (action) => {
    setPeriodModalAction(action);
    setSelectPeriodModalIsOpen(true);
  };

  return (
    <>
      <SelectPayrollPeriodModal
        visible={selectPeriodModalIsOpen}
        handleClose={handleClose}
        action={periodModalAction}
      />
      <Box sx={styles.box}>
        <Stack spacing={2} p={4} sx={styles.stack}>
          <Box>
            <Typography sx={styles.emptyStateTitle}>
              Prepara tu pago de nómina
            </Typography>
            <Typography sx={styles.emptyStateSubtitle}>
              Selecciona cómo ingresarás los detalles del pago y del
              destinatario.
            </Typography>
          </Box>
          <PayrollButtonGroup
            buttonRef={buttonRef}
            openPeriodModal={openPeriodModal}
            openUploadFileModal={openUploadFileModal}
          />
          <InfoPayment />
        </Stack>
      </Box>
    </>
  );
};

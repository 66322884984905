import React from "react";
import { styles } from "../styles";

export const BeneficiaryBankRow = ({ cell }) => {
  const row = cell.row.original;
  const type = row.transaction_type;
  const text = {
    invoice: row.bank_name,
    payroll: row.employee_bank_name,
    collection: "-",
  };

  return (
    <div style={styles.tableRow}>
      <p style={styles.revert}>{text[type]}</p>
    </div>
  );
};

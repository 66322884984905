import React, { useEffect, useState } from "react";
import { TextField, Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { PrimaryButton } from "../../buttons";
import {
  getNotesBO,
  saveInvoiceNote,
  deleteNote,
} from "../../../services/api/notes";
import Notes from "../../components/notes";
import { arrayIsEmpty } from "../../../utils/arrayUtils";
import { toastOptions } from "../../../constants";
import { ENTITY_RECEIPT } from "./constants";
import { handleFormattedNote } from "./utils";
import styles from "./styles.module.css";

const NotesModal = ({ handleClose, id, currentUserEmail }) => {
  const [messageInputValue, setMessageInputValue] = useState("");
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [notes, setNotes] = useState([]);

  const handleGetNotesBO = () => {
    getNotesBO({ entity: ENTITY_RECEIPT, id })
      .then((res) => {
        if (res?.data && !arrayIsEmpty(res?.data)) {
          setNotes(handleFormattedNote(res.data));
        } else setNotes([]);
      })
      .catch(() => {
        toast.error("Hubo un error al traer las notas!", toastOptions);
      });
  };

  useEffect(() => {
    handleGetNotesBO();
  }, []);

  const handleCreateNote = async () => {
    setLoadingCreate(true);
    await saveInvoiceNote({
      entity_type: ENTITY_RECEIPT,
      entity_id: id,
      comment: messageInputValue,
    })
      .then((res) => {
        handleGetNotesBO();
        setMessageInputValue("");
      })
      .catch((er) => {
        toast.error("Hubo un error al crear la nota!", toastOptions);
      });
    setLoadingCreate(false);
  };

  const handleDeleteNote = async ({ id }) => {
    setLoadingDelete(true);
    await deleteNote({ id })
      .then((res) => {
        handleGetNotesBO();
      })
      .catch((er) => {
        toast.error("Hubo un error al borrar la nota!", toastOptions);
      });
    setLoadingDelete(false);
  };

  return (
    <Dialog open onClose={handleClose}>
      <div className={styles.container}>
        <h1 className={styles.title}>Notas</h1>
        <div className={styles.textFieldContainer}>
          <TextField
            multiline
            size="small"
            placeholder="Deja aquí tu comentario..."
            rows={5}
            variant="outlined"
            onChange={(e) => setMessageInputValue(e.target.value)}
            value={messageInputValue}
          />
          <div className={styles.buttonContainer}>
            <PrimaryButton
              loading={loadingCreate}
              isDisable={messageInputValue.length < 2}
              text="Publicar"
              action={handleCreateNote}
              width="150px"
            />
          </div>
        </div>
        <div className={styles.containerNotes}>
          <Notes
            notes={notes}
            userEmail={currentUserEmail}
            loading={loadingCreate || loadingDelete}
            deleteNote={handleDeleteNote}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default NotesModal;

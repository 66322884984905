export const TYPE = {
  advance: "Anticipo",
  debtPayment: "Abono a deuda",
};

export const TYPE_MODAL = {
  success: "success",
  error: "error",
  loading: "loading",
};

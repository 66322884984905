export const truncateDecimalsDown = (number) => {
  const p = Math.floor(number * 1000) / 1000;
  const parts = p.toString().split(".");

  if (parts[1] && parts[1].length > 2) {
    const thirdDecimal = parseInt(parts[1][2], 10);
    if (thirdDecimal > 5) {
      return Math.ceil(p * 100) / 100;
    } else {
      return Math.floor(number * 100) / 100;
    }
  }

  return p;
};

export const truncateDecimalsUp = (number) => {
  const p = Math.floor(number * 1000) / 1000;
  const parts = p.toString().split(".");

  if (parts[1] && parts[1].length > 2) {
    const thirdDecimal = parseInt(parts[1][2], 10);
    if (thirdDecimal >= 5) {
      return Math.ceil(p * 100) / 100;
    } else {
      return Math.floor(number * 100) / 100;
    }
  }

  return p;
};

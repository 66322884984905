export const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
    padding: "12px",
    width: "-webkit-fill-available",
    marginTop: "24px",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    borderRadius: "12px",
    border: "1px solid #D0D5DD",
    background: "#FCFCFD",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
  iconTextContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    height: "42px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "left",
    color: "#101828",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
};

import { styles } from "./styles";

function UpdateOption({ type, id, label, text, onClick, selectedOption }) {
  const isSelected = selectedOption === type;
  return (
    <div
      style={
        isSelected
          ? styles.radioButtonSelectedContainer
          : styles.radioButtonContainer
      }
      onClick={() => onClick()}
    >
      <div className="form-check">
        <input
          style={{ cursor: "pointer" }}
          className="form-check-input"
          type="radio"
          name="paymentMethod"
          id={id}
          value={type}
          onChange={onClick}
          checked={isSelected}
        />
      </div>
      <div
        style={{
          ...styles.radioTextContainer,
          marginTop: text ? "32px" : "14px",
        }}
      >
        <p style={isSelected ? styles.radioSelectedTitle : styles.radioTitle}>
          {label}
          {text && (
            <p style={isSelected ? styles.radioSelectedText : styles.radioText}>
              {text}
            </p>
          )}
        </p>
      </div>
    </div>
  );
}
export default UpdateOption;

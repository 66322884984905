import React from "react";

export const SubscriptionTitle = ({ title, style }) => {
  return (
    <p
      style={{
        margin: "24px 0px",
        textAlign: "center",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600,
        ...style,
      }}
    >
      {title}
    </p>
  );
};

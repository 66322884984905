export const styles = {
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  modalSaveButton: {
    backgroundColor: "#6938EF",
    border: "1px solid #6938EF",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "14px",
    marginLeft: "8px",
    whiteSpace: "nowrap",
  },
  cardTitle: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#26272B",
  },
  cardRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
  },
  container: {
    backgroundColor: "#F2F4F7",
    borderRadius: "8px",
  },
  cardContainer: {
    padding: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardText: {
    fontSize: "14px",
    fontWeight: "400",
    margin: "0px",
    lineHeight: "20px",
  },
  inputLabel: {
    color: "#101828",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "20px",
  },
  textArea: {
    padding: "10px 14px 10px 14px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    opacity: "0px",
    boxShadow: "0px 1px 2px 0px #1018280D",
    background: "#FFFFFF",
    fontSize: "14px",
    height: "auto",
    color: "#667085",
  },
};

import React, { useState } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Divider,
  Typography,
  Link,
} from "@mui/material";
import { CloseButton, Modal, Stack } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { styles } from "./styles";
import {
  AddFileIcon,
  InfoIcon,
  ModalDownloadIcon,
  ModalFileIcon,
} from "../../../assets";
import {
  GetTotalInvoices,
  GetTotalpayrolls,
  UploadFileTxt,
  UploadInvoiceFileCSV,
  UploadInvoiceFilePDF,
  UploadSupplierFileCSV,
} from "../../../services";
import { toast } from "react-toastify";
import {
  sampleInvoiceFile,
  sampleSupplierFile,
  toastOptions,
} from "../../../constants";
import { useContext } from "react";
import { PaymentsContext, PayrollContext } from "../../../contexts";
import { checkCompanyNews, getNews } from "../../../utils";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { UploadFileModalTitle } from "./UploadFileModalTitle";
import { UploadFileModalInfo } from "./UploadFileModalInfo";
import { UploadFileModalFooter } from "./UploadFileModalFooter";
import { UploadFileBody } from "./UploadFileBody";

export const UploadFileModal = ({
  visible,
  handleClose,
  type,
  setTotals,
  entity,
  openResultModal,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const { internalGetInvoices } = useContext(PaymentsContext);
  const [isLoading, setIsLoading] = useState(false);

  const closeAndReset = () => {
    setSelectedFiles(undefined);
    handleClose();
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
    }
  };

  const errorMsg = (msg) => {
    if (msg === "Error: Faltan campos requeridos") {
      toast.error(
        "El archivo tiene información faltante o incorrecta. Revisa los campos obligatorios e intenta de nuevo.",
        toastOptions
      );
    } else if (msg === "Error: plantilla incorrecta") {
      toast.error(
        "El archivo cargado no es correcto. Por favor, utiliza la plantilla provista por Payana.",
        toastOptions
      );
    } else if (msg === "Error: No se pudo descargar el archivo") {
      toast.error(
        "Ha ocurrido un error. Por favor, intenta nuevamente.",
        toastOptions
      );
    }
  };

  //TODO: Refactor this function, hay una mezcla barbara de async/await y promesas
  const upload = () => {
    setIsLoading(true);
    type !== "SupplierCSV" && getNews();
    var formdata = new FormData();

    if (type === "PDF") {
      selectedFiles.forEach((file, index) => {
        formdata.append(
          "files",
          selectedFiles[index],
          selectedFiles[index].name
        );
      });
      UploadInvoiceFilePDF(formdata)
        .then(async (res) => {
          if (res) {
            handleClose();
            const newsInterval = setInterval(async () => {
              const updated = await checkCompanyNews();
              if (updated) {
                toast.success("¡El archivo se subió con éxito!", toastOptions);
                internalGetInvoices(1);
                GetTotalInvoices({
                  status: "all",
                  providerId: "all",
                  tags: [],
                }).then((res) => {
                  setTotals(res);
                });
                setIsLoading(false);
                closeAndReset();
              }
            }, 2000);
          }
        })
        .catch((err) => {
          toast.error("Hubo un error subiendo el archivo", toastOptions);
          setIsLoading(false);
          closeAndReset();
        });
    } else if (type === "CSV") {
      formdata.append("files", selectedFiles[0], selectedFiles[0].name);
      UploadInvoiceFileCSV(formdata)
        .then(async (res) => {
          if (res) {
            const response = JSON.parse(res.invoiceService.Payload);
            if (response.statusCode === 200) {
              toast.success("¡El archivo se subió con éxito!", toastOptions);
              const newsInterval = setInterval(async () => {
                const updated = await checkCompanyNews();
                if (updated) {
                  internalGetInvoices(1);
                  GetTotalInvoices({
                    status: "all",
                    providerId: "all",
                    tags: [],
                  }).then((res) => {
                    setTotals(res);
                  });
                }
              }, 2000);
            } else if (response.statusCode === 403) {
              errorMsg(response.body.error);
            } else {
              toast.error("Hubo un error subiendo el archivo", toastOptions);
            }
          }
        })
        .catch((err) => {
          toast.error("Hubo un error subiendo el archivo", toastOptions);
        })
        .finally(() => {
          setIsLoading(false);
          closeAndReset();
        });
    } else if (type === "SupplierCSV") {
      formdata.append("files", selectedFiles[0], selectedFiles[0].name);
      UploadSupplierFileCSV(formdata)
        .then(async (res) => {
          if (res) {
            const response = JSON.parse(res.supplierService.Payload);
            if (response.statusCode === 200) {
              toast.success("¡El archivo se subió con éxito!", toastOptions);
            } else if (response.statusCode === 403) {
              errorMsg(JSON.parse(response.body).error);
            } else {
              toast.error("Hubo un error subiendo el archivo", toastOptions);
            }
          }
        })
        .catch((err) => {
          toast.error(`${err}`, toastOptions);
        })
        .finally(() => {
          setIsLoading(false);
          closeAndReset();
        });
    } else if (type === "TXT") {
      formdata.append("file", selectedFiles[0], selectedFiles[0].name);
      UploadFileTxt(formdata, entity)
        .then((res) => {
          const response = JSON.parse(res.respLambda.Payload);
          if (response.statusCode === 200) {
            const message = `${response.body.totalProcess.completed} de ${response.body.totalProcess.total} fueron subidos!`;

            if (
              response.body.totalProcess.completed ===
              response.body.totalProcess.total
            ) {
              toast.success(message, toastOptions);
            } else {
              toast.warn(message, { ...toastOptions, autoClose: 10000 });
            }

            if (response.body.rowsError.length < 6) {
              for (let i = 0; i < response.body.rowsError.length; i++) {
                toast.error(response.body.rowsError[i].name);
              }
            }

            if (
              entity == "payrolls" &&
              response.body.created_payrolls_ids.length > 0
            ) {
              openResultModal(response.body);
            } else if (entity == "invoices") {
              const newsInterval = setInterval(async () => {
                const updated = await checkCompanyNews();
                if (updated) {
                  internalGetInvoices(1);
                  GetTotalInvoices({
                    status: "all",
                    providerId: "all",
                  }).then((res) => {
                    setTotals(res);
                  });
                }
              }, 2000);
            }
          }
        })
        .catch((err) => {
          toast.error(`${err}`, toastOptions);
        })
        .finally(() => {
          setIsLoading(false);
          closeAndReset();
        });
    }
  };

  const removeFile = (file) => () => {
    let acceptedFiles = [...selectedFiles];
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setSelectedFiles(acceptedFiles);
  };

  return (
    <Modal
      show={visible}
      onHide={closeAndReset}
      fullscreen
      centered
      id="sidebarModal"
    >
      <Modal.Body>
        <Box sx={styles.modalBoxSM}>
          <UploadFileModalTitle
            entity={entity}
            type={type}
            closeAndReset={closeAndReset}
          />
          <UploadFileModalInfo type={type} entity={entity} />
          <UploadFileBody
            type={type}
            entity={entity}
            isLoading={isLoading}
            onDrop={onDrop}
            selectedFiles={selectedFiles}
            removeFile={removeFile}
          />
          <UploadFileModalFooter
            isLoading={isLoading}
            closeAndReset={closeAndReset}
            upload={upload}
            selectedFiles={selectedFiles}
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
};

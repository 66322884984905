import { Box, Stack, Typography } from "@mui/material";
import { ReconciliationEmpty } from "../../../assets";
import { PrimaryButton } from "../../../commons/buttons";
import { getWppLink, openInNewTab } from "../../../utils";
import { styles } from "screens/ReconciliationsScreen/styles";

export const ReconciliationsComingSoon = ({ companyName }) => {
  const phoneNumber = "+573103619890";
  const message = `Hola! Escribo de la empresa ${companyName} y quiero saber más acerca de la solución de conciliación`;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Box sx={styles.emptyStateBox}>
        <Stack spacing={2} p={4} sx={styles.emptyStateStack}>
          <ReconciliationEmpty />
          <Box>
            <Typography sx={{ ...styles.emptyStateTitle, marginBottom: "8px" }}>
              Próximamente
            </Typography>
            <Typography
              sx={{ ...styles.emptyStateSubtitle, marginBottom: "24px" }}
            >
              Pronto podrás registrar y sincronizar automáticamente tus
              movimientos bancarios con los registros contables de forma más
              rápida y sencilla.
            </Typography>
          </Box>
          <PrimaryButton
            text="Quiero saber más"
            action={() => openInNewTab(getWppLink(phoneNumber, message))}
            width="206px"
          />
        </Stack>
      </Box>
    </div>
  );
};

import React from "react";
import { styles } from "../styles";

export const BeneficiaryDocRow = ({ cell }) => {
  const row = cell.row.original;
  const type = row.transaction_type;
  const text = {
    invoice: row.provider_document_number,
    payroll: row.employee_document_number,
    collection: row.customer_document_number,
  };

  return (
    <div style={styles.tableRow}>
      <p style={styles.revert}>{text[type]}</p>
    </div>
  );
};

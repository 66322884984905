import React, { useState } from "react";
import { Box, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { NumericFormat } from "react-number-format";
import {
  CustomRadio,
  DateDropdown,
  StyledFormControlLabel,
} from "screens/ReconciliationsScreen/components";
import { addMonths } from "date-fns";
import { CustomTextField } from "../CollectionObligationModal";
import { getAccountingReceipts } from "services";
import { formatLocaleDate } from "utils";

export const ReconcileFiltersModal = ({
  visible,
  setIsReconcileFiltersModalVisible,
  setItems,
  selectedBank,
  beneficiary,
  setLoadingSupplierItems,
}) => {
  const [date, setDate] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedDate, setSelectedDate] = useState([
    { startDate: "all", endDate: "all" },
  ]);
  const [exactAmount, setExactAmount] = useState(null);
  const [amountMin, setAmountMin] = useState(null);
  const [amountMax, setAmountMax] = useState(null);
  const [amountType, setAmountType] = useState(null);
  const [transactionType, setTransactionType] = useState(null);
  const [accountingReceiptNumber, setAccountingReceiptNumber] = useState(null);

  const handleConfirm = () => {
    setIsReconcileFiltersModalVisible(false);
    setLoadingSupplierItems(true);
    getAccountingReceipts({
      reconciliationBankId: selectedBank.id,
      status: "not_reconciled",
      thirdName: beneficiary,
      dateFrom:
        selectedDate.at(0).startDate !== "all" &&
        formatLocaleDate(date[0].startDate, "yyyy-MM-dd"),
      dateTo:
        selectedDate.at(0).endDate !== "all" &&
        formatLocaleDate(date[0].endDate, "yyyy-MM-dd"),
      transactionType,
      exactAmount,
      amountMin,
      amountMax,
      amountType,
      accountingReceiptNumber,
    })
      .then((res) => {
        if (res?.response?.status !== 400) {
          setItems(res);
        }
      })
      .finally(() => {
        setLoadingSupplierItems(false);
      });
  };

  return (
    <Modal
      show={visible}
      onHide={() => setIsReconcileFiltersModalVisible(false)}
      centered
    >
      <Modal.Body style={{ boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)" }}>
        <Box sx={styles.container}>
          <p style={styles.title}>Filtra para hacer una búsqueda específica</p>
          <p style={styles.subtitle}>Puedes hacer una selección múltiple</p>
          <div
            style={{
              borderRadius: "8px",
              gap: "8px",
              width: "100%",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "normal",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#101828",
                alignSelf: "flex-start",
              }}
            >
              Tipo de operación
            </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={transactionType}
              name="radio-buttons-group"
              onChange={(e) => {
                setTransactionType(e.target.value);
              }}
            >
              <StyledFormControlLabel
                value="credit"
                control={
                  <CustomRadio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                      },
                    }}
                  />
                }
                label="Egreso"
                sx={{ marginLeft: "1px" }}
              />

              <StyledFormControlLabel
                value="debit"
                control={
                  <CustomRadio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                      },
                    }}
                  />
                }
                label="Ingreso"
                sx={styles.radioInputLabel}
              />
            </RadioGroup>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#101828",
                alignSelf: "flex-start",
                marginTop: "8px",
              }}
            >
              Período
            </Typography>
            <DateDropdown
              date={date}
              setDate={setDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              defaultText="Elige aquí"
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#101828",
                alignSelf: "flex-start",
                marginTop: "8px",
              }}
            >
              Monto
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={amountType}
              name="radio-buttons-group"
              onChange={(e) => {
                setAmountType(e.target.value);
              }}
            >
              <StyledFormControlLabel
                value="exact"
                control={
                  <CustomRadio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                      },
                    }}
                  />
                }
                label="Monto exacto"
                sx={{ marginLeft: "1px" }}
              />
              <NumericFormat
                id="exactAmount"
                name="exactAmount"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                isNumericString={true}
                value={exactAmount}
                customInput={CustomTextField}
                sx={{ width: "100%", marginBottom: "6px" }}
                onValueChange={(values, sourceInfo) => {
                  setExactAmount(values.floatValue);
                }}
                placeholder="Buscar"
                disabled={amountType !== "exact"}
              />
              <StyledFormControlLabel
                value="range"
                control={
                  <CustomRadio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                      },
                    }}
                  />
                }
                label="Rango de montos"
                sx={styles.radioInputLabel}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "12px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <NumericFormat
                  id="amountMin"
                  name="amountMin"
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  isNumericString={true}
                  value={amountMin}
                  customInput={CustomTextField}
                  sx={{ width: "100%" }}
                  onValueChange={(values, sourceInfo) => {
                    setAmountMin(values.floatValue);
                  }}
                  placeholder="Min"
                  disabled={amountType !== "range"}
                />
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "500",
                    color: "#1D2939",
                    alignSelf: "center",
                  }}
                >
                  hasta
                </Typography>
                <NumericFormat
                  id="amountMax"
                  name="amountMax"
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  isNumericString={true}
                  value={amountMax}
                  customInput={CustomTextField}
                  sx={{ width: "100%" }}
                  onValueChange={(values, sourceInfo) => {
                    setAmountMax(values.floatValue);
                  }}
                  placeholder="Max"
                  disabled={amountType !== "range"}
                />
              </div>
            </RadioGroup>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#101828",
                alignSelf: "flex-start",
                marginTop: "8px",
              }}
            >
              Número de comprobante
            </Typography>
            <TextField
              id="accountingReceiptNumber"
              size="small"
              variant="outlined"
              placeholder="Ejemplo: RP-12-345"
              value={accountingReceiptNumber}
              InputProps={{
                style: { fontSize: 14, backgroundColor: "#FFFFFF" },
              }}
              onChange={(e) => setAccountingReceiptNumber(e.target.value)}
            />
          </div>
          <Stack
            direction={"row"}
            spacing={1}
            pt={4}
            justifyContent="flex-end"
            alignSelf="flex-end"
          >
            <SecondaryButton
              text={"Limpiar"}
              action={() => {
                setTransactionType(null);
                setExactAmount(null);
                setAmountMin(null);
                setAmountMax(null);
                setAmountType(null);
                setAccountingReceiptNumber(null);
                setDate([
                  {
                    startDate: addMonths(new Date(), -1),
                    endDate: new Date(),
                    key: "selection",
                  },
                ]);
                setSelectedDate([{ startDate: "all", endDate: "all" }]);
                handleConfirm();
              }}
              width="136px"
            />
            <PrimaryButton
              text={"Aplicar y buscar"}
              action={() => handleConfirm()}
              width="136px"
            />
          </Stack>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

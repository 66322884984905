import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { TrackJS } from "trackjs";
import * as amplitude from "@amplitude/analytics-browser";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { APP_ENV_PRODUCTION } from "constants";

if (process.env.REACT_APP_TRACKJS_TOKEN) {
  TrackJS.install({
    token: process.env.REACT_APP_TRACKJS_TOKEN,
    application: process.env.REACT_APP_TRACKJS_APPLICATION,
  });
}

if (process.env.REACT_APP_ENVIRONMENT === APP_ENV_PRODUCTION) {
  amplitude.init("d960ca0e9c15e839b87cadaf6e69a8ef", {
    defaultTracking: {
      attribution: false,
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
    },
    trackingOptions: {
      deviceManufacturer: true,
      deviceModel: true,
      ipAddress: true,
      language: true,
      osName: true,
      osVersion: true,
      platform: true,
    },
  });
}

try {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    // </React.StrictMode>
  );
} catch (error) {
  TrackJS.track(error);
}

// Pola Rules!
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

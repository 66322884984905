import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { BankEntitySelector, ValidationMessage } from "../../components";
import { accountTypes } from "../../../constants";

export default function BankAccountForm(props) {
  const { register, formValues, formState } = props;
  return (
    <form
      className="source-of-funds-form bank-account-form"
      name="bank_account_form"
    >
      <Stack my={2} spacing={2}>
        <Stack spacing={1}>
          <label style={{}}>Entidad bancaria</label>
          <BankEntitySelector
            bankSelectedId={formValues.bankId}
            validationProps={register("bankId", { required: true })}
          />
          <ValidationMessage errors={formState.errors} field="bankId" />
        </Stack>
        <Stack spacing={1}>
          <label style={{}} htmlFor="account_type">
            Tipo de cuenta
          </label>
          <Select
            size="small"
            variant="outlined"
            value={formValues.account_type}
            {...register("account_type", { required: true })}
            inputProps={{
              id: "account_type",
              placeholder: "Seleccione tipo de cuenta",
            }}
          >
            {accountTypes.map((type) => {
              return (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              );
            })}
          </Select>
          <ValidationMessage errors={formState.errors} field="account_type" />
        </Stack>
        <Stack spacing={1}>
          <label style={{}} htmlFor="account_number">
            Número de cuenta / Referencia
          </label>
          <TextField
            id="account_number"
            size="small"
            variant="outlined"
            placeholder="Ingrese número de cuenta"
            {...register("account_number", { required: true })}
          />
          <ValidationMessage errors={formState.errors} field="account_number" />
        </Stack>
      </Stack>
    </form>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Stack, TextField, Typography } from "@mui/material";
import { TrackJS } from "trackjs";
import { styles } from "./styles";
import { PayanaLogo } from "../../assets";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { addTrackingMetadata } from "../../utils/addTrackingMetadata";
import LoadingButton from "../../commons/buttons/LoadingButton";
import { PhoneNumberInput, ValidationMessage } from "commons";
import { useForm, useWatch } from "react-hook-form";
import { PHONE_NUMBER_VALIDATOR, openInNewTab } from "utils";
import { ChangeUserCompany, GetManagerData, UpdateManagerData } from "services";
import { Spinner } from "react-bootstrap";
import { userRoles } from "constants";
import { TERMS_AND_CONDITIONS_LINK } from "constants";

export const ManagerWelcome = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const hash = queryParameters.get("hash");
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [pass, setPass] = useState(null);
  const [managerData, setManagerData] = useState({});
  const navigate = useNavigate();
  const { login, loginError } = useContext(UserContext);

  const { register, handleSubmit, formState, control, setError, setValue } =
    useForm({
      defaultValues: {
        email: "",
        phone: "",
      },
      mode: "onBlur",
    });
  const formValues = useWatch({ control });
  const [phone, setPhone] = useState(formValues.phone);

  useEffect(() => {
    GetManagerData(hash).then((res) => {
      setDataLoading(false);
      setManagerData(res);
      setValue("email", res.email);
    });
  }, [hash]);

  const switchCompany = () => {
    ChangeUserCompany({ id: managerData.companyId }).then(() => {
      navigate("/onboarding", { replace: true });
      window.location.reload(true);
    });
  };

  const loginUser = async () => {
    const res = await login(formValues.email, pass);
    if (res?.status !== 401) {
      TrackJS.console.info(res);
    }
    if (res.status === 200) {
      addTrackingMetadata(res.user);
      if (res.user.backoffice_user) {
        navigate("/backoffice-receipts", { replace: true });
      } else {
        switchCompany();
      }
    }
  };

  const loginOrCreateUser = async () => {
    setLoading(true);
    try {
      if (managerData.isPayanaUser) {
        loginUser();
      } else {
        await UpdateManagerData({
          password: pass,
          phone: phone,
          email: managerData.email,
          companyId: managerData.companyId,
        });
        loginUser();
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        TrackJS.console.info(formValues.email);
        TrackJS.track(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(loginOrCreateUser)}>
        <Stack justifyContent="center" alignItems="center" mt={9}>
          <PayanaLogo width={190} height={42} />
          <div
            style={{
              marginTop: "24px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "12px",
              padding: "40px",
              maxWidth: "480px",
              boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.1)",
            }}
          >
            {dataLoading ? (
              <Spinner animation="border" />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Typography sx={styles.title}>
                    El{" "}
                    {
                      userRoles.find(
                        ({ id }) => id === parseInt(managerData.roleId)
                      )?.label
                    }{" "}
                    de la empresa {managerData.companyName} te está invitando a
                    unirte como gerente.
                  </Typography>
                  <Typography sx={styles.description} mb={4}>
                    {managerData.isPayanaUser
                      ? "Por favor, ingresa con la contraseña que utilizas habitualmente en Payana."
                      : `Crea una contraseña para unirte a ${managerData.companyName} y comienza a usar Payana.`}
                  </Typography>
                </div>
                <Stack
                  spacing={3}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography mb={1} sx={styles.label}>
                      Email registrado
                    </Typography>
                    <Typography mb={1} sx={styles.help}>
                      {managerData.email}
                    </Typography>
                  </Box>
                  {!managerData.isPayanaUser && (
                    <Box>
                      <label
                        htmlFor="phone"
                        style={{ ...styles.label, marginBottom: "8px" }}
                      >
                        Número de teléfono
                      </label>
                      <PhoneNumberInput
                        phone={phone}
                        setPhone={(value) => {
                          setValue("phone", value, { shouldValidate: false });
                          setPhone(value);
                        }}
                        {...register("phone", PHONE_NUMBER_VALIDATOR(true))}
                      />
                      <ValidationMessage
                        errors={formState.errors}
                        field="phone"
                      />
                    </Box>
                  )}
                  <Box>
                    <Stack
                      mb={1}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography sx={styles.label}>
                        <Typography sx={styles.label}>
                          {managerData.isPayanaUser
                            ? "Ingresa tu contraseña"
                            : "Crea una contraseña"}
                        </Typography>
                      </Typography>
                      {managerData.isPayanaUser && (
                        <Typography
                          sx={styles.labelButton}
                          onClick={() => navigate("/restore-password")}
                          style={{ cursor: "pointer" }}
                        >
                          ¿Olvidaste tu contraseña?
                        </Typography>
                      )}
                    </Stack>

                    <TextField
                      fullWidth
                      variant="outlined"
                      type="password"
                      placeholder="******"
                      onChange={(e) => setPass(e.target.value)}
                      size="small"
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && pass && formValues.email) {
                          loginOrCreateUser();
                        }
                      }}
                    />
                  </Box>
                  {loginError && (
                    <Typography sx={styles.errorLabel}>
                      Contraseña inválida.
                    </Typography>
                  )}
                  <LoadingButton
                    loading={loading}
                    disabled={!formValues.email || !pass}
                    onClick={loginOrCreateUser}
                  >
                    {managerData.isPayanaUser ? "Ingresar" : "Unirme a Payana"}
                  </LoadingButton>
                </Stack>
                {!managerData.isPayanaUser && (
                  <div style={styles.terms}>
                    Al registrarte estarás aceptando los{" "}
                    <span
                      style={{ color: "#6938EF", cursor: "pointer" }}
                      onClick={() => openInNewTab(TERMS_AND_CONDITIONS_LINK)}
                    >
                      términos y condiciones y políticas de privacidad
                    </span>
                    .
                  </div>
                )}
              </>
            )}
          </div>
        </Stack>
      </form>
    </Container>
  );
};

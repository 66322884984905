import { requestContainer } from "../requestContainer";

export async function GetCustomers({ pageIndex, customerId }) {
  return requestContainer({
    method: "get",
    url: `/customers?page=${pageIndex}&customer_id=${customerId}&per_page=100`,
  });
}

export async function GetAllCustomers(pageIndex) {
  return requestContainer({
    method: "get",
    url: `/backoffice/customers?page=${pageIndex}`,
  });
}

export async function GetTotalCustomers({ customerId }) {
  return requestContainer({
    method: "get",
    url: `/customers/total?customer_id=${customerId}`,
  });
}

export async function DeleteCustomer({ id }) {
  return requestContainer({
    method: "delete",
    url: `/customers/${id}`,
  });
}

export async function GetCustomer({ id }) {
  return requestContainer({
    method: "get",
    url: `/customers/${id}`,
  });
}

export async function GetBOCustomer({ id }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/customers/${id}`,
  });
}

export async function GetCustomerByHash({ hash }) {
  return requestContainer({
    method: "get",
    url: `/customers/customer-by-hash?hash=${hash}`,
  });
}

export async function UpdateCustomer({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/customers/${id}`,
    data: {
      ...formValues,
    },
  });
}

export async function UpdateBOCustomer({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/backoffice/customers/${id}`,
    data: {
      ...formValues,
    },
  });
}

export async function CreateCustomer({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/customers`,
    data: {
      ...formValues,
    },
  });
}

export async function SearchCustomers(query) {
  return requestContainer({
    method: "get",
    url: `/customers/search?q=${query}`,
  });
}

export async function SearchAllCustomers(query) {
  return requestContainer({
    method: "get",
    url: `/backoffice/customers/search?q=${query}`,
  });
}

export function UploadCustomerFileCSV(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/customers",
    data: formData,
  });
}

export function UploadCustomerFileCSVV2(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/csv/customers",
    data: formData,
  });
}

export async function GetCustomerPayExpirationValues({ hash }) {
  return requestContainer({
    method: "get",
    url: `/collections/to-pay-customer?hash=${hash}`,
  });
}

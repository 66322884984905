export const WithoutBorderButton = ({
  text,
  action,
  isDisable = false,
  height = "40px",
  width = "100px",
  fontSize = "14px",
  style = {},
  contentStyle = {},
  icon = null,
  edge = null,
  endIcon = null,
}) => {
  const styles = {
    backgroundColor: "transparent",
    height: height,
    color: isDisable ? "#5925DC80" : "#5925DC",
    fontSize: fontSize,
    fontWeight: "500",
    lineHeight: "20px",
    width: width,
    edge: edge,
    border: "none",
    "&:hover": {
      cursor: "pointer",
      color: "#F2F3Fb",
    },
    textAlign: "left",
    textWrap: "nowrap",
    ...style,
  };

  const contentStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
    ...contentStyle,
  };

  return (
    <button type="button" onClick={action} disabled={isDisable} style={styles}>
      <div style={contentStyles}>
        {icon}
        <span>{text}</span>
        {endIcon}
      </div>
    </button>
  );
};

const isCustomerIncomplete = (customer) => {
  const { name, document_type, document_number, email, phone } = customer;
  return (
    !name ||
    !document_type ||
    !document_number ||
    ((!email || email === "") && (!phone || phone === "+57"))
  );
};

export default isCustomerIncomplete;

const DisabledLeyend = () => {
  return (
    <div style={{ fontSize: "12px", color: "#667085" }}>
      <p style={{}}>
        El monto máximo para pagar de este modo es de $3.000.000.
      </p>
    </div>
  );
};

export default DisabledLeyend;

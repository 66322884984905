import React, { useContext, useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { TrackJS } from "trackjs";
import { Box, Dialog } from "@mui/material";
import {
  GetCollection,
  SearchCustomers,
  SendNotification,
} from "../../../services";
import { styles } from "../../Table/styles";
import {
  CollectionDetailModalSkeleton,
  CollectionFormWithImage,
} from "./components";
import { CollectionsContext, UserContext } from "../../../contexts";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { objectIsEmpty } from "../../../utils/objectUtils";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { permissions, toastOptions } from "../../../constants";
import { useIsUserAllowedTo, disabledByReceiversData } from "../../../utils";
import {
  getCollectionComissionOrDefault,
  getDetailsToCollect,
} from "../../../screens/CollectionsScreen/comission/calcCollectionsComission";
import {
  getFormDataFromCollection,
  getDefaultFormData,
} from "./getFormDataFromCollection";
import { useChat } from "../../../hooks";
import { CustomerDetails } from "../CustomerDetails";
import { ReceiverDetails } from "../ReceiverDetails";
import { CrownIcon } from "assets";

const resolveFilePath = (uploadedCollection, collection) =>
  uploadedCollection?.location || collection.file_path;

export const ExpandCollectionModal = () => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [collection, setCollection] = useState();
  const [customer, setCustomer] = useState({});
  const [style, setStyle] = useState({});
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [createdCustomer, setCreatedCustomer] = useState(null);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [receiverDrawerIsOpen, setReceiverDrawerIsOpen] = useState(false);
  const {
    getCustomers,
    getCustomer,
    updateCollection,
    uploadCollectionFile,
    mainReceiver,
    getMainReceiver,
    internalGetCollections,
    statusSelected,
    perPageSelected,
    sortingSelected,
    collectionsScreen,
    subscription,
  } = useContext(CollectionsContext);

  const [formValues, setFormValues] = useState(getDefaultFormData());
  const collectionStatusIncludes = (...states) =>
    states.includes(collection?.status?.toLowerCase());

  useChat();

  const pageIndex = collectionsScreen?.pageIndex;
  const customerIdSelected = collectionsScreen?.customerIdSelected?.value;

  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const [collectionNumberError, setCollectionNumberError] = useState({
    helperText: "",
    error: false,
  });
  const [conceptError, setConceptError] = useState({
    helperText: "",
    error: false,
  });

  useEffect(() => {
    getMainReceiver();
    getCustomers(1, "all");
    GetCollection({ id: id }).then((res) => {
      if (res.id) {
        setCollection(res);
        setCustomer({
          id: res.customer_id,
          name: res.customer_name,
        });
      } else {
        navigate("/error");
      }
    });
  }, []);

  useEffect(() => {
    if (createdCustomer) {
      setCustomer(createdCustomer);
      setFormValues((formValues) => {
        return {
          ...formValues,
          customer_id: createdCustomer.id,
        };
      });
    }
  }, [createdCustomer]);

  useEffect(() => {
    if (collection) {
      if (collection.customer_id) {
        getCustomer(collection.customer_id).then((invoiceCustomer) => {
          invoiceCustomer && setCustomer(invoiceCustomer);
        });
      }
      setFormValues(getFormDataFromCollection(collection));
      setStyle({ padding: "0px" });
    }
  }, [collection]);

  const userContextValues = useContext(UserContext);
  const hasCollectionReceiversEnabled =
    userContextValues.hasCollectionReceiversEnabled;
  const companyCollectionsCommission = getCollectionComissionOrDefault(
    collection,
    userContextValues.companyCollectionsCommission
  );
  const companyCollectionsFixedCommission =
    userContextValues.companyCollectionsFixedCommission;
  const { round_comission, total_to_collector } = getDetailsToCollect(
    formValues.amount,
    companyCollectionsCommission,
    companyCollectionsFixedCommission,
    formValues.first_receiver_amount,
    formValues.second_receiver_amount,
    formValues.amount_type
  );

  const saveAndRedirect = async (sendNow = false) => {
    const uploadedFile = await uploadCollectionFile(selectedFiles);

    const response = await updateCollection(id, {
      ...formValues,
      receivers: [
        ...formValues.receivers,
        {
          receiver_id: mainReceiver.id,
          amount: total_to_collector,
        },
      ],
      file_path: resolveFilePath(uploadedFile, collection),
    });
    if (response === 200) {
      if (sendNow) {
        SendNotification([id]).then((res) => {
          if (res?.message) {
            toast.error(
              "Ocurrió un error, por favor revisa el email o número de teléfono de tu cliente",
              toastOptions
            );
          } else {
            toast.success(
              `La notificación fue enviada con éxito`,
              toastOptions
            );
            internalGetCollections(
              pageIndex,
              statusSelected,
              customerIdSelected,
              perPageSelected,
              sortingSelected
            );
          }
        });
      }
      navigate("/collections/index");
    }
  };

  const resetForm = () => setFormValues(getDefaultFormData());

  const getAndSetCustomer = (customer) => {
    getCustomer(customer.id).then((cus) => {
      cus && setCustomer(cus);
    });
  };

  const getSearchOptions = (query) => {
    SearchCustomers(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const onClose = () => {
    resetForm();
    navigate(-1);
  };

  const isReceiversDataCompleted = !disabledByReceiversData(
    formValues.checked,
    formValues.secondReceiverOptionSelected,
    formValues.amount_type,
    formValues.firstReceiverOptionSelected,
    formValues.first_receiver_amount,
    formValues,
    round_comission,
    formValues.secondReceiverOptionSelected,
    formValues.second_receiver_amount
  );
  const hasMainReceiver = !objectIsEmpty(mainReceiver);
  const isAllowToSubmit =
    formValues.amount &&
    formValues.amount > 5000 &&
    formValues.customer_id &&
    formValues.concept &&
    formValues.collection_number &&
    formValues.expiration_date &&
    hasMainReceiver &&
    isReceiversDataCompleted &&
    !collectionNumberError.error &&
    !conceptError.error;

  const handleError = (error) => {
    TrackJS.console.error(error);
    navigate("/error");
  };

  const handleCustomerUpdateSuccess = async (customer) => {
    try {
      setCreatedCustomer(customer);
      const collection = await GetCollection({ id });
      if (collection.id) {
        setCollection(collection);
      } else {
        handleError(new Error(`collection id undefined collection_id: ${id}`));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setDrawerIsOpen(false);
    }
  };

  return (
    <>
      <Dialog fullScreen open={id} style={style}>
        <div className="modal-header-payana">
          <div style={styles.headerRow}>
            <div onClick={() => onClose()}>
              <CloseButton style={{ boxShadow: "none" }} />
            </div>
            <div
              className="modalTitle"
              style={{ marginLeft: "16px", fontSize: "18px" }}
            >
              Detalle de comprobante
            </div>
          </div>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0px",
            }}
          >
            {collection &&
              collectionStatusIncludes("pending", "outstanding_balance") &&
              isUserAllowedTo(permissions.COLLECTIONS_UPDATE) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <SecondaryButton
                    text={"Guardar"}
                    action={() => saveAndRedirect(false)}
                    isDisable={!isAllowToSubmit}
                    width="100%"
                  />
                  <PrimaryButton
                    icon={
                      subscription?.showIcon ? (
                        <CrownIcon size="small" fill="#EFA83C" />
                      ) : null
                    }
                    text={"Enviar ahora"}
                    action={() =>
                      subscription.handleActionSubscription(() =>
                        saveAndRedirect(true)
                      )
                    }
                    isDisable={!isAllowToSubmit}
                    style={{ marginLeft: isMobile ? "0px" : "16px" }}
                    width="100%"
                  />
                </div>
              )}
          </Box>
        </div>
        <Box sx={{ padding: 2 }} className="collectionForm">
          {!collection ? (
            <CollectionDetailModalSkeleton />
          ) : (
            <CollectionFormWithImage
              hasCollectionReceiversEnabled={hasCollectionReceiversEnabled}
              collectionId={id}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              formValues={formValues}
              setFormValues={setFormValues}
              collection={collection}
              customer={customer}
              setCustomer={getAndSetCustomer}
              customers={[...searchOptions, customer]}
              createdCustomer={createdCustomer}
              setDrawerIsOpen={setDrawerIsOpen}
              getOptions={(query) => getSearchOptions(query)}
              filePath={collection.file_path}
              setReceiverDrawerIsOpen={setReceiverDrawerIsOpen}
              currentUser={userContextValues.currentUser}
              companyCollectionsCommission={companyCollectionsCommission}
              companyCollectionsFixedCommission={
                companyCollectionsFixedCommission
              }
              conceptError={conceptError}
              setConceptError={setConceptError}
              collectionNumberError={collectionNumberError}
              setCollectionNumberError={setCollectionNumberError}
              openCustomerForm={() => setDrawerIsOpen(collection?.customer_id)}
            />
          )}
        </Box>
      </Dialog>
      <CustomerDetails
        isOpen={!!drawerIsOpen}
        id={drawerIsOpen}
        handleClose={() => setDrawerIsOpen(false)}
        handleSuccess={(customer) => {
          handleCustomerUpdateSuccess(customer);
        }}
      />
      <ReceiverDetails
        isOpen={!!receiverDrawerIsOpen}
        id={Number.isFinite(receiverDrawerIsOpen) ? receiverDrawerIsOpen : null}
        handleClose={() => {
          setReceiverDrawerIsOpen(false);
        }}
        handleSuccess={() => {}}
        mainReceiver={mainReceiver}
      />
    </>
  );
};

import React from "react";

export const ChevronUpIcon = ({
  color = "#344054",
  width = "20",
  height = "20",
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M15 12.5L10 7.5L5 12.5"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

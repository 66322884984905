import axios from "axios";
import { getFromLocalStorage } from "../../../utils";
import { requestContainer } from "../requestContainer";

export async function UpdatePassword(oldPass, newPass) {
  return requestContainer({
    method: "put",
    url: `/user/update-password`,
    data: {
      password: newPass,
      old_password: oldPass,
    },
  });
}

export async function UpdateUser({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/user/${id}`,
    data: {
      ...formValues,
    },
  });
}

export async function UpdateCompany(id, formValues) {
  return requestContainer({
    method: "put",
    url: `/company/${id}`,
    data: {
      ...formValues,
    },
  });
}

export function GetCompanyNews() {
  return requestContainer({
    method: "get",
    url: `/company/news`,
  });
}

export async function checkCredencialsAndUpdateCompanyAlegra(formValues) {
  return requestContainer({
    method: "put",
    url: `/credentials/alegra`,
    data: {
      ...formValues,
    },
  });
}

export async function uploadCompanyLogo(formData) {
  return requestContainer({
    method: "post",
    url: `/company/upload/logo`,
    data: formData,
  });
}

export async function StoreErp(formValues) {
  return requestContainer({
    method: "post",
    url: `/erp/store`,
    data: {
      ...formValues,
    },
  });
}

export async function CheckSyncErp() {
  return requestContainer({
    method: "get",
    url: `/sync/check`,
  });
}

export async function SyncErp() {
  return requestContainer({
    method: "post",
    url: `/erp/sync`,
  });
}

export async function SyncSiigo() {
  return requestContainer({
    method: "post",
    url: `/sync/siigo`,
  });
}

export async function SyncAlegra() {
  return requestContainer({
    method: "post",
    url: `/sync/alegra`,
  });
}

export async function getMultiCompaniesSiigo(id) {
  return requestContainer({
    method: "get",
    url: `/siigo/multiple-accounts?company_id=${id}`,
  });
}

export async function SelectSiigoAccount(id) {
  return requestContainer({
    method: "put",
    url: `/siigo/multiple-accounts/${id}`,
  });
}

export async function Login({ email, password }) {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_API_URL}/login`,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    data: {
      email: email,
      password: password,
    },
  }).catch((error) => {
    return error.response;
  });
  return response;
}

export function RegisterUser(formValues) {
  return requestContainer({
    method: "post",
    url: `/register?hash=${formValues.hash}`,
    data: { ...formValues },
  });
}

export function storeNewUser(formValues) {
  return requestContainer({
    method: "post",
    url: `/user`,
    data: { ...formValues },
  });
}

export async function RefreshToken() {
  let refreshToken = getFromLocalStorage("refreshToken");
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_API_URL}/refresh`,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    data: {
      refresh_token: refreshToken,
    },
  }).catch((error) => {});
  return response;
}

export function GetUsers() {
  return requestContainer({
    method: "get",
    url: `/user`,
  });
}

export function DeleteUser(id) {
  return requestContainer({
    method: "delete",
    url: `/user/${id}`,
  });
}

export const GetUser = async () => {
  return requestContainer({
    method: "get",
    url: `/user/me`,
  });
};

export const GetNotificationEmails = async () => {
  return requestContainer({
    method: "get",
    url: `/company/notification/email`,
  });
};

export const AddNotificationEmail = async (email) => {
  return requestContainer({
    method: "post",
    url: `/company/notification/email`,
    data: { email },
  });
};

export const DeleteNotificationEmail = async (id) => {
  return requestContainer({
    method: "delete",
    url: `/company/notification/email/${id}`,
  });
};

export const GetReminders = async () => {
  return requestContainer({
    method: "get",
    url: `/reminders`,
  });
};

export async function UpdateReminders({ id, data }) {
  return requestContainer({
    method: "put",
    url: `/reminders/${id}`,
    data: {
      ...data,
    },
  });
}

export async function GetRoles() {
  return requestContainer({
    method: "get",
    url: `/roles`,
  });
}

export async function GetApprovalRequirements() {
  return requestContainer({
    method: "get",
    url: `/approval-requirements`,
  });
}

export async function GetUserCompanies() {
  return requestContainer({
    method: "get",
    url: `/user/companies`,
  });
}

export async function ChangeUserCompany({ id }) {
  return requestContainer({
    method: "post",
    url: `/user/change-company`,
    data: { company_id: id },
  });
}

export async function ShowBannerCausation() {
  return requestContainer({
    method: "get",
    url: `/erp/causation/company/showBannerCausationPayment`,
  });
}

export function CreateCompany(formValues) {
  return requestContainer({
    method: "post",
    url: `/company/register-new-company`,
    data: { ...formValues },
  });
}

export function CheckIfCompanyExists(identificationNumber) {
  return requestContainer({
    method: "get",
    url: `/company/check-company-exists/${identificationNumber}`,
  });
}

export function GetManagerData(hash) {
  return requestContainer({
    method: "get",
    url: `/user/welcome-manager/${hash}`,
  });
}

export function UpdateManagerData(formValues) {
  return requestContainer({
    method: "put",
    url: `/user/welcome-manager`,
    data: { ...formValues },
  });
}

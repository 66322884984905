import React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { styles } from "./styles";
import { useContext } from "react";
import { PaymentsContext, UserContext } from "../../../contexts";
import { useEffect } from "react";
import { SelectSiigoAccount, getMultiCompaniesSiigo } from "../../../services";
import { useState } from "react";
import { PrimaryButton } from "../../buttons";
import { useSyncAccountingSystem } from "../../../hooks/useSyncAccountingSystem";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";

export const MultiAccountModal = () => {
  const { multiAccountModalIsOpen, setMultiAccountModalIsOpen } =
    useContext(PaymentsContext);
  const { currentCompany } = useContext(UserContext);
  const [accounts, setAccounts] = useState([]);
  const { syncAccountingSystem } = useSyncAccountingSystem();

  useEffect(() => {
    getMultiCompaniesSiigo(currentCompany.id).then((res) => {
      if (res && res.length >= 1) {
        setAccounts(res);
      } else if (res && res.length < 1) {
        toast.error("No se encontró ninguna empresa.", toastOptions);
        setMultiAccountModalIsOpen(false);
      }
    });
  }, []);

  const selectAccount = (id) => {
    SelectSiigoAccount(id).then((res) => {
      syncAccountingSystem("Siigo");
      setMultiAccountModalIsOpen(false);
    });
  };

  return (
    <Modal
      show={multiAccountModalIsOpen}
      onHide={() => setMultiAccountModalIsOpen(false)}
      centered
    >
      <Modal.Body
        style={{
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box sx={styles.container}>
          <p style={styles.title}>Mis empresas</p>
          <p style={styles.subtitle}>
            Selecciona la empresa a la que desees ingresar:
          </p>
          <Box my={4} sx={{ width: "70%", textAlign: "center" }}>
            {accounts.length >= 1 ? (
              <Stack spacing={2}>
                {accounts.map((account) => {
                  return (
                    <>
                      <Stack
                        key={account.id}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Typography
                          sx={{ maxWidth: "70%", textAlign: "left" }}
                          variant="body1"
                        >
                          {account.name}
                        </Typography>
                        <PrimaryButton
                          text={"Ingresar"}
                          action={() => selectAccount(account.id)}
                          width="80px"
                        />
                      </Stack>
                      <Divider mt={0} />
                    </>
                  );
                })}
              </Stack>
            ) : (
              <CircularProgress size={40} />
            )}
          </Box>
          <div style={styles.row}>
            <Button
              variant="secondary"
              style={styles.modalSecondaryButton}
              onClick={() => setMultiAccountModalIsOpen(false)}
            >
              Cancelar
            </Button>
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

import React, { useRef, useState } from "react";
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Menu,
  Typography,
} from "@mui/material";
import { SecondaryButton } from "commons/buttons";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { TypeButton } from "./TypeButton";
import { styles } from "../styles";
import stylesCSS from "../styles.module.css";

export const TypeDropdown = ({
  setPageIndex,
  amountSignSelected,
  setAmountSignSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(amountSignSelected);
  const searchRef = useRef(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    searchRef?.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetType = () => {
    setPageIndex(1);
    handleClose();
    setSelectedOptions("all");
    setAmountSignSelected("all");
  };

  const onSelect = (value) => {
    if (selectedOptions === value) {
      setSelectedOptions("all");
      setAmountSignSelected("all");
    } else {
      setSelectedOptions(value);
      setAmountSignSelected(value);
    }
    handleClose();
  };

  const filterName = {
    positive: "Ingresos",
    negative: "Salidas",
    all: "Filtrar",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <TypeButton
          open={open}
          action={handleClick}
          text={filterName[selectedOptions]}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          slotProps={{
            paper: {
              style: {
                height: 270,
                minHeight: 90,
                maxHeight: 270,
                width: 390,
                padding: 16,
              },
            },
          }}
        >
          <Typography sx={styles.typeFilterTitle}>Filtrar</Typography>
          <Divider style={styles.divider} />
          <FormGroup>
            <FormControlLabel
              control={
                <input
                  id="negative"
                  type="checkbox"
                  className={stylesCSS.input}
                  defaultChecked={selectedOptions === "negative"}
                  onClick={() => onSelect("negative")}
                />
              }
              label={
                <label htmlFor="negative" style={styles.checkboxInputLabel}>
                  Salidas
                </label>
              }
              labelPlacement="end"
              sx={{ marginLeft: "2px", marginBottom: "12px" }}
            />
            <FormControlLabel
              control={
                <input
                  id="positive"
                  type="checkbox"
                  className={stylesCSS.input}
                  defaultChecked={selectedOptions === "positive"}
                  onClick={() => onSelect("positive")}
                />
              }
              label={
                <label htmlFor="positive" style={styles.checkboxInputLabel}>
                  Ingresos
                </label>
              }
              labelPlacement="end"
              sx={{ marginLeft: "2px", marginBottom: "12px" }}
            />
          </FormGroup>
          <Divider style={styles.divider} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "12px",
            }}
          >
            <SecondaryButton
              text="Limpiar"
              action={() => {
                resetType();
              }}
              width="88px"
            />
          </div>
        </Menu>
      </div>
    </>
  );
};

export const styles = {
  buttonOpenInputsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: "16px",
  },
  buttonOpenInputsStartIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  buttonOpenInputsText: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: " 14px",
    letterSpacing: "-0.14px",
    marginBottom: "0px",
  },
  buttonOpenInputsEndIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tutorialStepContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  tutorialStepFristText: {
    color: "#5522FF",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    marginBottom: "6px",
  },
  tutorialStepSecondText: {
    color: "#5522FF",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
  },
};

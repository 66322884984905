export const CheckedIcon = (props) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1.16675} y={0.5} width={15} height={15} rx={7.5} fill="#7F56D9" />
    <path
      d="M12.0002 5.5L7.41683 10.0833L5.3335 8"
      stroke="white"
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={1.16675}
      y={0.5}
      width={15}
      height={15}
      rx={7.5}
      stroke="#7F56D9"
    />
  </svg>
);

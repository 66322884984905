import React from "react";
import { Box, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ApprovedIcon } from "assets";
import { Button, Modal } from "react-bootstrap";
import { RejectedIcon } from "assets/svg/RejectedIcon";
import { styles } from "./styles";

export const ModalReponse = ({ open, type, text = "", onClose = () => {} }) => {
  const navigate = useNavigate();
  const action = {
    success: () => {
      onClose();
      navigate(-1);
    },
    error: onClose,
  };

  const modalViews = {
    success: success(action[type], text),
    error: error(action[type], text),
    loading: loading(),
  };

  return (
    <Modal show={open && type} onClose={onClose} centered>
      <Modal.Body
        style={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          minWidth: "500px",
        }}
      >
        {modalViews[type]}
      </Modal.Body>
    </Modal>
  );
};

function success(onClose, text) {
  return (
    <>
      <ApprovedIcon />
      <p style={{ ...styles.modalTitle, color: "#039855" }}>¡Listo!</p>
      <div style={{ textAlign: "center", paddingBottom: "5px" }}>
        <p style={styles.modalText}>{text}</p>
      </div>
      <div style={{ width: "100%" }}>
        <Button
          style={{
            width: "100%",
            background: "#6938EF",
            borderColor: "#6938EF",
          }}
          onClick={onClose}
        >
          Entendido
        </Button>
      </div>
    </>
  );
}

function error(onClose, text) {
  const handleOnClickHelp = () => {
    window.fcWidget.open();
    onClose();
  };
  return (
    <>
      <RejectedIcon />
      <p style={styles.modalTitle}>Hubo un problema realizando el egreso</p>
      <p style={styles.modalText}>{text}</p>
      <div style={styles.buttonGroup}>
        <Button
          style={{
            width: "100%",
            background: "#6938EF",
            borderColor: "#6938EF",
          }}
          onClick={onClose}
        >
          Entendido
        </Button>
        <Button
          variant="light"
          style={{
            width: "100%",
            color: "#5925DC",
          }}
          onClick={handleOnClickHelp}
        >
          Necesito ayuda
        </Button>
      </div>
    </>
  );
}

function loading() {
  return (
    <div style={styles.loading}>
      <p style={{ ...styles.title, textAlign: "center" }}>
        <strong>Enviando información a Siigo</strong>
      </p>
      <p
        style={{
          color: "#475467",
          fontSize: 14,
          textAlign: "center",
          fontWeight: 400,
        }}
      >
        <span>
          Este proceso puede demorar. Por favor no cierres esta ventana.
        </span>
      </p>
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    </div>
  );
}

import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Dialog, Stack } from "@mui/material";
import { ConfirmSourceOfFundsModal } from "./ConfirmSourceOfFundsModal";
import {
  getSourceOfFunds,
  saveSourceOfFund,
} from "../../../services/api/SourceOfFunds.service";
import { PaymentsContext, UserContext } from "../../../contexts";
import { useForm, useWatch } from "react-hook-form";
import { getSourceOfFundsLabel } from "./getSourceOfFundsLabel";
import { PaymentModal } from "../PaymentModal";
import RegisterModalHeader from "./RegisterModalHeader";
import SourceOfFundsSelector from "./SourceOfFundsSelector";
import BankAccountForm from "./BankAccountForm";
import CardReferenceForm from "./CardReferenceForm";
import RegisterModalFooter from "./RegisterModalFooter";
import "./source-of-funds.css";
import ConfirmCobreStep from "../PaymentModal/ConfirmCobreStep";
import { useWompi, useCobre } from "../../../hooks";
import { TrackJS } from "trackjs";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import { useMediaQuery } from "react-responsive";

const CustomPaper = ({ children }) => {
  return (
    <Box sx={{ background: "#fff", borderRadius: "0.5rem" }}>{children}</Box>
  );
};

const RegisterSourceOfFundsModal = (props) => {
  const {
    visible,
    totalAmount,
    handleClose,
    selectedRowsIds,
    selectedSuppliers,
    redirectUrl = `${process.env.REACT_APP_BASE_URL}/payments/invoices`,
    canPayWithCobre,
    displayCobreAlert,
    trxId,
    type,
    selectedId = null,
    setOpenDeletedAlert = () => {},
    section = null,
    setLimitExceededAlert = () => {},
    amountTotalInvoicePayana,
  } = props;
  const { getAllBanks } = useContext(PaymentsContext);
  const { currentCompany } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    sources: [],
  });

  const [totalAmountToPay, setTotalAmountToPay] = useState(null);
  const [confirmCobreStep, setConfirmCobreStep] = useState(false);
  const companyId = currentCompany?.id;
  const sourcesOfFunds = state.sources;
  const selected = state.selected;
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const setSelected = (selectedOne) => {
    setState((prevState) => ({ ...prevState, selected: selectedOne }));
  };

  const transactionPayload = {
    trxId,
    selectedRowsIds,
    type,
    sourceOfFundsId: state.selectedId,
    paymentGateway: confirmCobreStep ? "cobre" : "wompi",
    paymentMethod: confirmCobreStep ? "others" : "card",
  };
  const selectedSourcesOfFunds = state.sources.find(
    (sourceOfFunds) => sourceOfFunds.id === (state.selectedId || selectedId)
  );
  const selectedPaymentMethod =
    selectedSourcesOfFunds?.type === "card_reference"
      ? "card"
      : selectedSourcesOfFunds?.type === "bank_account"
      ? "others"
      : "";
  const aElement = useRef(null);
  const { wompiButton, openWompiFlow } = useWompi(
    totalAmountToPay,
    redirectUrl,
    selectedPaymentMethod,
    transactionPayload,
    selectedRowsIds,
    section,
    setOpenDeletedAlert,
    handleClose,
    setLimitExceededAlert
  );
  const { makePaymentInCobre } = useCobre(
    transactionPayload,
    redirectUrl,
    setOpenDeletedAlert,
    setLimitExceededAlert
  );

  useEffect(() => {
    getAllBanks();
  }, []);

  useEffect(() => {
    if (visible) {
      setLoading(true);
      getSourceOfFunds(currentCompany.id)
        .then((response) => {
          const sources = Array.isArray(response) ? response : [];
          const selected =
            sources.length > 0 ? "confirm_source_view" : "confirm_source_view";
          setState({
            sources,
            selected,
          });
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [visible]);

  const { register, formState, control } = useForm({
    defaultValues: {},
  });

  const formValues = useWatch({ control });
  const sourcesOfFundsOptions = sourcesOfFunds.map((source) => ({
    id: source.id,
    label: getSourceOfFundsLabel(source),
    type: source.type,
  }));

  const onSelected = (selectedId) => {
    setState((prevState) => ({
      ...prevState,
      registering: false,
      selectedId,
    }));
  };

  const saveNewSource = async () => {
    try {
      setLoading(true);
      const sourceOfFundCreated = await saveSourceOfFund(
        formValues,
        companyId,
        selected
      );
      const sources = await getSourceOfFunds(currentCompany.id);
      setState((prevState) => ({
        ...prevState,
        sources,
        registering: false,
        selected: "confirm_source_view",
        selectedId: sourceOfFundCreated?.id,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const closeFlow = () => {
    if (state.from_view === "confirm_source_view") {
      setState((state) => ({
        ...state,
        selected: "confirm_source_view",
        from_view: "",
      }));
    } else {
      handleClose();
      setState((state) => ({
        ...state,
        registering: false,
        selectedId: "",
        skipped_flow: false,
      }));
    }
    setConfirmCobreStep(false);
  };
  const skipFlow = () => {
    setState((prevState) => ({
      ...prevState,
      from_view: "",
      skipped_flow: true,
    }));
  };
  const onRegisterNew = () => {
    setSelected("bank_account");
    setState((state) => ({
      ...state,
      from_view: "confirm_source_view",
    }));
  };
  const className = `my-container ${
    selected === "bank_account"
      ? "bank-account"
      : selected === "card_reference"
      ? "card-reference"
      : "register-new"
  }`;

  const openCobreFlow = async () => {
    try {
      if (displayCobreAlert) {
        setConfirmCobreStep(true);
      } else {
        setLoading(true);
        await makePaymentInCobre();
        handleClose();
      }
    } catch (error) {
      TrackJS.console.error(error);
      toast.error("Ha ocurrido un error, intente nuevamente", toastOptions);
    } finally {
      setLoading(false);
    }
  };

  return state.skipped_flow ? (
    <PaymentModal
      canPayWithCobre={canPayWithCobre}
      displayCobreAlert={displayCobreAlert}
      visible={visible}
      totalAmount={totalAmount}
      handleClose={closeFlow}
      selectedRowsIds={selectedRowsIds}
      selectedSuppliers={selectedSuppliers}
      loading={loading}
      type={type}
      trxId={trxId}
      setOpenDeletedAlert={setOpenDeletedAlert}
      setLimitExceededAlert={setLimitExceededAlert}
      amountTotalInvoicePayana={amountTotalInvoicePayana}
    />
  ) : (
    <Dialog open={visible} onClose={closeFlow} PaperComponent={CustomPaper}>
      <Box
        style={{
          padding: 24,
          maxWidth: isMobile ? 380 : 420,
          minWidth: isMobile ? 380 : 420,
        }}
      >
        {confirmCobreStep && displayCobreAlert ? (
          <ConfirmCobreStep primaryAction={makePaymentInCobre} />
        ) : (
          <Stack justifyContent="space-between" style={{ width: "100%" }}>
            <RegisterModalHeader />
            <div className={className}>
              {selected !== "confirm_source_view" && (
                <SourceOfFundsSelector
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
              {selected === "bank_account" && (
                <BankAccountForm
                  register={register}
                  formValues={formValues}
                  formState={formState}
                />
              )}
              {selected === "card_reference" && (
                <CardReferenceForm
                  register={register}
                  formValues={formValues}
                  formState={formState}
                />
              )}
              {selected === "confirm_source_view" && (
                <ConfirmSourceOfFundsModal
                  canPayWithCobre={canPayWithCobre}
                  openCobreFlow={openCobreFlow}
                  openWompiFlow={openWompiFlow}
                  onSkipFlow={skipFlow}
                  sourcesOfFunds={sourcesOfFundsOptions}
                  onRegisterNew={onRegisterNew}
                  onSelected={onSelected}
                  selectedId={state.selectedId || selectedId}
                  totalAmount={totalAmount}
                  handleClose={closeFlow}
                  selectedRowsIds={selectedRowsIds}
                  selectedSuppliers={selectedSuppliers}
                  loading={loading}
                  type={type}
                  section={section}
                  setTotalAmountToPay={setTotalAmountToPay}
                  amountTotalInvoicePayana={amountTotalInvoicePayana}
                />
              )}
            </div>

            {selected !== "confirm_source_view" && (
              <RegisterModalFooter
                closeFlow={() => {
                  setState((prevState) => ({
                    ...prevState,
                    registering: false,
                    selected: "confirm_source_view",
                  }));
                }}
                saveNewSource={saveNewSource}
                loading={loading}
                disabled={
                  selected === "card_reference"
                    ? !formValues.card_reference &&
                      formValues.card_reference?.length < 3
                    : selected === "bank_account"
                    ? !formValues.bankId ||
                      !formValues.account_type ||
                      !formValues.account_number
                    : ""
                }
              />
            )}
            <form
              style={{ visibility: "hidden", height: 0 }}
              ref={wompiButton}
            ></form>
          </Stack>
        )}
      </Box>
      <a href="#" target="_blank" ref={aElement}></a>
    </Dialog>
  );
};

export { RegisterSourceOfFundsModal };

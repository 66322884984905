import { toastOptions } from "constants";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getBillinInformation,
  getBOCompany,
  getBOCompanyBillings,
  getBOCompanyFeatures,
  getFeatures,
  SearchBOCompanies,
} from "services";
import {
  calculateAmountPrice,
  DEFAULT_FEATURE_VALUES,
  VIEW,
  getTotalTrialDays,
  TYPE,
} from "./utils";

export const useBOSubscription = () => {
  const [companies, setCompanies] = useState([]);
  const [companySelected, setCompanySelected] = useState(null);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [featuresTable, setFeaturesTable] = useState([]);
  const [featuresStore, setFeaturesStore] = useState([]);
  const [billingModal, setBillingModal] = useState(false);
  const [billing, setBilling] = useState({});
  const [view, setView] = useState(VIEW.subscription);
  const [billings, setBillings] = useState([]);
  const [isShowDetail, setIsShowDetail] = useState(false);

  const searchBOCompanies = async (query) => {
    try {
      const response = await SearchBOCompanies(query);
      setCompanies(response);
    } catch (error) {
      toast.error(
        "No se pudo obtener la información de las compañias",
        toastOptions
      );
    }
  };

  const handleSelectedCompany = async (company) => {
    try {
      if (!company) {
        setCompanySelected(null);
        return;
      }
      const respCompany = await getBOCompany(company.id);
      setCompanySelected(respCompany.company);
      await formatSubscriptionCompanyTable(respCompany.company);
    } catch (error) {
      toast.error(
        "No se pudo obtener la información/suscripciones de las compañías",
        toastOptions
      );
    }
  };

  const handleChangeSubscription = (row, value, type) => {
    try {
      const updateFeatures = [...featuresTable];
      const indexFeatureToUpdate = updateFeatures.findIndex(
        (feature) => feature.type === row.type
      );

      if (type === TYPE.feature) {
        const featureSelected = featuresStore.find(
          (feature) => feature.id === value
        );
        updateFeatures[indexFeatureToUpdate] = {
          ...updateFeatures[indexFeatureToUpdate],
          ...DEFAULT_FEATURE_VALUES,
          featureId: value,
          price: featureSelected.price,
          amount: calculateAmountPrice({
            price: featureSelected.price,
            retencion: companySelected.retentions,
          }),
          featureName: featureSelected.name,
          discount: 0,
          retention: 0,
          status: updateFeatures[indexFeatureToUpdate].status || "active",
        };
      } else if (type === TYPE.status) {
        updateFeatures[indexFeatureToUpdate] = {
          ...updateFeatures[indexFeatureToUpdate],
          status: value,
        };
      } else if (type === TYPE.discount) {
        const featureSelected = featuresStore.find(
          (feature) =>
            feature.id === updateFeatures[indexFeatureToUpdate].featureId
        );
        updateFeatures[indexFeatureToUpdate] = {
          ...updateFeatures[indexFeatureToUpdate],
          discount: value,
          amount: calculateAmountPrice({
            discount: value,
            price: row?.price ? row?.price : featureSelected.price,
            retencion: companySelected.retentions,
          }),
        };
      } else if (type === TYPE.freeDays) {
        updateFeatures[indexFeatureToUpdate] = {
          ...updateFeatures[indexFeatureToUpdate],
          freeDays: getTotalTrialDays(value, row?.freeTrialStartAt),
        };
      } else if (type === TYPE.price) {
        updateFeatures[indexFeatureToUpdate] = {
          ...updateFeatures[indexFeatureToUpdate],
          price: value,
          amount: calculateAmountPrice({
            price: value,
            retencion: companySelected.retentions,
            discount: row.discount,
          }),
        };
      } else if (type === TYPE.billing) {
        updateFeatures[indexFeatureToUpdate] = {
          ...updateFeatures[indexFeatureToUpdate],
          billingId: value,
        };
      }
      setFeaturesTable(updateFeatures);
    } catch (error) {
      toast.error("No se pudo actualizar la suscripción", toastOptions);
    }
  };

  const openBillingModal = async (billingId, isEditable = false) => {
    try {
      if (billingId) {
        const getBilling = await getBillinInformation(billingId);
        setBilling(getBilling);
      } else {
        setBilling({});
      }
      setIsShowDetail(isEditable);
      setBillingModal(true);
    } catch (error) {
      toast.error(
        "No se pudo obtener la información de facturación",
        toastOptions
      );
    }
  };

  const refreshSubscription = async () => {
    try {
      await formatSubscriptionCompanyTable(companySelected);
    } catch (error) {
      toast.error(
        "No se pudo obtener la información/suscripciones de las compañías",
        toastOptions
      );
    }
  };

  const handleCleanSubscriptionsTable = () => {
    setFormatFeatureTable(featuresStore);
    setCompanySelected(null);
  };

  const formatSubscriptionCompanyTable = async (company) => {
    try {
      const getFeatures = await getBOCompanyFeatures(company.id);
      const updateFeatures = setFormatFeatureTable(featuresStore);
      getFeatures.forEach((getFeature) => {
        const indexFeatureToUpdate = updateFeatures.findIndex(
          (feature) => feature.type === getFeature.featureType
        );
        updateFeatures[indexFeatureToUpdate] = {
          ...updateFeatures[indexFeatureToUpdate],
          ...getFeature,
          amount: calculateAmountPrice({
            price: getFeature?.price,
            retencion: company.retentions,
            discount: getFeature.discount,
          }),
        };
      });
      setFeaturesTable(updateFeatures);
    } catch (error) {
      toast.error(
        "No se pudo obtener la información/suscripciones de las compañías",
        toastOptions
      );
    }
  };

  const setFormatFeatureTable = (features) => {
    const formatFeature = {};
    features.forEach((feature) => {
      if (!formatFeature[feature.type]) {
        formatFeature[feature.type] = [];
      }
      formatFeature[feature.type].push(feature);
    });
    const featuresToArray = Object.keys(formatFeature).map((key) => ({
      type: key,
      features: formatFeature[key],
      ...DEFAULT_FEATURE_VALUES,
    }));
    setFeaturesTable(featuresToArray);
    return featuresToArray;
  };

  useEffect(() => {
    getFeatures().then((resp) => {
      setFormatFeatureTable(resp);
      setFeaturesStore(resp);
    });
  }, []);

  useEffect(() => {
    setCompanyBillings();
  }, [companySelected]);

  const setCompanyBillings = async () => {
    if (!companySelected) return;

    const queryParams = new URLSearchParams();

    if (companySelected.accountant_billing_id) {
      queryParams.append(
        "accountant_billing_id",
        companySelected.accountant_billing_id
      );
    }

    if (companySelected.billing_id) {
      queryParams.append("company_billing_id", companySelected.billing_id);
    }

    const query = queryParams.toString();

    const resp = await getBOCompanyBillings(query);
    setBillings(resp);
  };

  const refreshSetCompanyBillings = async () => {
    const respCompany = await getBOCompany(companySelected.id);
    setCompanySelected(respCompany.company);
  };

  return {
    companies,
    showModalAlert,
    companySelected,
    handleSelectedCompany,
    searchBOCompanies,
    handleChangeSubscription,
    refreshSubscription,
    handleCleanSubscriptionsTable,
    featuresTable,
    billing,
    billingModal,
    view,
    billings,
    refreshSetCompanyBillings,
    isShowDetail,
    handleChangeView: (value) => setView(value),
    handleShowModalAlert: (value) => setShowModalAlert(value),
    openBillingModal,
    closeBillingModal: () => setBillingModal(false),
    handleSetBilling: (value) => setBilling(value),
  };
};

import React, { useState } from "react";
import { Box } from "@mui/material";
import { Modal, Spinner } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { ModalSuccessIcon, ModalWarningIcon } from "../../../assets";
import { NumericFormat } from "react-number-format";
import UpdateOption from "./UpdateOption";
export const UploadResultModal = ({
  visible,
  handleNextStep,
  handleClose,
  uploadResult,
  warningTitle,
  warningText,
}) => {
  const [step, setStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("yes");
  const nextStep = (item) => {
    if (selectedOption === "no") {
      setSelectedOption("yes");
      setStep(step + 1);
    } else {
      setLoading(true);
      handleNextStep(selectedOption, item.invoices, {
        id: item.original_data.at(0).id,
        name: item.name,
        document_type: item.document_type,
        phone: item.phone,
        email: item.email,
        account_type: item.account_type,
        account_number: item.account_number,
        bank_id: item.bank_id,
      });
      setSelectedOption("yes");
      setStep(step + 1);
      setLoading(false);
    }
    step + 1 === uploadResult?.length && setSuccess(true);
  };
  const handleCloseEvent = () => {
    handleClose();
    setTimeout(() => {
      setStep(0);
      setWarning(false);
      setSuccess(false);
    }, 3000);
  };
  const renderContent = (item) => {
    return (
      <>
        <p style={{ ...styles.subtitle, maxWidth: "100%" }}>
          Recientemente has cargado las siguientes facturas para{" "}
          {item.document_type} {item.document_number}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "100%",
          }}
        >
          {item.invoices?.map((invoice) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "45px",
                  backgroundColor: "#F9FAFB",
                  borderRadius: "4px",
                  padding: "8px",
                  width: "100%",
                  height: "40px",
                }}
              >
                <p style={styles.invoiceNumber}>{invoice.invoice_number}</p>
                <p style={styles.subtitle}>
                  <NumericFormat
                    value={invoice.amount_total}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                    decimalScale={2}
                  />
                </p>
              </div>
            );
          })}
          <p style={{ ...styles.title, maxWidth: "100%" }}>
            ¿Corresponden a {item.original_data.at(0).name?.toUpperCase()}?
          </p>
          <UpdateOption
            type="yes"
            selectedOption={selectedOption}
            id="optionYes"
            label="Sí"
            onClick={() => setSelectedOption("yes")}
          />
          <UpdateOption
            type="update"
            selectedOption={selectedOption}
            id="optionUpdate"
            label="Sí, actualizar con nueva información"
            text="Importaremos tus facturas y reemplazaremos este NIT con nueva información"
            onClick={() => setSelectedOption("update")}
          />
          <UpdateOption
            type="no"
            selectedOption={selectedOption}
            id="optionNo"
            label="No, esas facturas corresponden a otro proveedor"
            onClick={() => setSelectedOption("no")}
          />
          <div style={{ display: "flex", alignSelf: "flex-end" }}>
            <PrimaryButton
              text={
                step + 1 < uploadResult?.length
                  ? `Siguiente caso (${step + 1}/${uploadResult?.length})`
                  : `Finalizar importación (${step + 1}/${
                      uploadResult?.length
                    })`
              }
              action={() =>
                step + 1 <= uploadResult?.length
                  ? nextStep(item)
                  : setSuccess(true)
              }
              width="fit-content"
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <Modal show={visible} onHide={() => setWarning(true)} centered>
      <Modal.Body>
        <Box style={styles.container}>
          {loading ? (
            <Spinner animation="border" variant="secondary" />
          ) : success ? (
            <>
              <ModalSuccessIcon />
              <p style={styles.title}>¡Listo!</p>
              <p style={styles.subtitle}>La importación fue exitosa.</p>
              <div style={{ display: "flex", alignSelf: "flex-end" }}>
                <PrimaryButton
                  text="Entendido"
                  action={() => handleCloseEvent()}
                  width="fit-content"
                />
              </div>
            </>
          ) : warning ? (
            <>
              <ModalWarningIcon />
              <p style={styles.title}>{warningTitle}</p>
              <p style={styles.subtitle}>{warningText}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "16px",
                }}
              >
                <SecondaryButton
                  text="Volver"
                  action={() => setWarning(false)}
                  width="-webkit-fill-available"
                />
                <PrimaryButton
                  text="Continuar y salir"
                  action={() => handleCloseEvent()}
                  width="-webkit-fill-available"
                />
              </div>
            </>
          ) : (
            <>
              <ModalWarningIcon />
              <p style={styles.title}>Inconsistencias en la información</p>
              {step < uploadResult?.length &&
                renderContent(uploadResult?.at(step))}
            </>
          )}
        </Box>
      </Modal.Body>
    </Modal>
  );
};

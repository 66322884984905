import React, { useState } from "react";
import { styles } from "../styles";
import { Divider } from "@mui/material";
import {
  ExportReconciliationsFile,
  deleteReconciliationProcess,
} from "services";
import createReconciliationsXLSX from "commons/modals/PickDatesToExportModal/downloadReconciliationsXLSX";
import { formatLocaleDate } from "utils";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { ChevronDownIcon, ChevronUpIcon } from "assets";
import { DropdownButton } from "commons/buttons";
import { Dropdown } from "react-bootstrap";

export const SeeMoreDropdown = ({
  process,
  bank,
  setIsEndPeriodModalVisible,
  setSelectedProcessId,
  setSelectedBank,
  setIsNewPeriodModalVisible,
  setIsRefreshing,
  setDeleteReconciliationProcessModalIsVisible,
  setReconciliationProcessToDelete,
}) => {
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen((prevValue) => !prevValue);
  };

  const runExport = () => {
    ExportReconciliationsFile(process.id)
      .then((response) =>
        createReconciliationsXLSX({
          response,
          fileName: `Conciliación_${bank.name}_${formatLocaleDate(
            process?.start_date,
            "yyyy-MM-dd"
          )}_${formatLocaleDate(process?.end_date, "yyyy-MM-dd")}_Payana`,
        })
      )
      .then(() => {
        toast.success("¡Archivo exportado con éxito!", toastOptions);
      })
      .catch((error) => {
        toast.error(
          "Hubo un error exportando el archivo, por favor intente nuevamente.",
          toastOptions
        );
      });
  };

  const deleteReconciliation = async () => {
    const res = await deleteReconciliationProcess(process.id);

    if (res?.response && res.response.status !== 200) {
      if (res.response.status === 400) {
        toast.error(
          "No puedes eliminar una conciliación con movimientos asignados.",
          toastOptions
        );
      } else {
        toast.error("Hubo un error eliminando la conciliación.", toastOptions);
      }
      return;
    }

    setIsRefreshing(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Dropdown
          onToggle={() => {
            toggleDropdown();
          }}
        >
          <DropdownButton
            text="Más acciones"
            open={open}
            openedIcon={<ChevronUpIcon />}
            closedIcon={<ChevronDownIcon />}
            style={{
              width: "148px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "8px 12px 8px 12px",
              alignItems: "center",
            }}
          />
          <Dropdown.Menu style={styles.noPadding}>
            {process?.status === "reconciled" ? (
              <Dropdown.Item
                onClick={() => {
                  // Lógica para crear un nuevo período
                  setSelectedProcessId(process.id);
                  setSelectedBank(bank);
                  setIsNewPeriodModalVisible(true);
                }}
              >
                <div style={styles.dropdownItemContainer} type="dropdown">
                  <div style={styles.dropdownItemTextContainer}>
                    <p style={styles.dropdownItemText}>Crear nuevo período</p>
                  </div>
                </div>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={() => {
                  // Lógica para cerrar conciliación
                  setSelectedProcessId(process.id);
                  setSelectedBank(bank);
                  setIsEndPeriodModalVisible(true);
                }}
              >
                <div style={styles.dropdownItemContainer} type="dropdown">
                  <div style={styles.dropdownItemTextContainer}>
                    <p style={styles.dropdownItemText}>Cerrar conciliación</p>
                  </div>
                </div>
              </Dropdown.Item>
            )}
            <Divider />
            <Dropdown.Item
              onClick={() => {
                runExport();
              }}
            >
              <div style={styles.dropdownItemContainer} type="dropdown">
                <div
                  style={{
                    ...styles.dropdownItemTextContainer,
                    marginTop: "0px",
                  }}
                >
                  <p style={styles.dropdownItemText}>Descargar conciliación</p>
                </div>
              </div>
            </Dropdown.Item>
            <Divider />
            <Dropdown.Item
              onClick={() => {
                setDeleteReconciliationProcessModalIsVisible(true);
                setReconciliationProcessToDelete(process.id);
              }}
            >
              <div style={styles.dropdownItemContainer} type="dropdown">
                <div
                  style={{
                    ...styles.dropdownItemTextContainer,
                    marginTop: "0px",
                  }}
                >
                  <p style={styles.dropdownItemText}>Borrar conciliación</p>
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

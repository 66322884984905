import React, { useContext, useEffect, useMemo, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { styles } from "./styles";
import { GetTotalTransactions, GetTransaction } from "../../services";
import {
  PayrollContext,
  TransactionsContext,
  UserContext,
} from "../../contexts";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteItemModal,
  NotifyWompiModal,
  SelectPayrollPeriodModal,
  Submenu,
  Table,
  TableSkeleton,
  PaymentModal,
  LimitExceededModal,
} from "../../commons";
import { payrollTransactionsColumns, payrollScreens } from "../../utils";
import { TableEmptyState } from "../../commons/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useChat } from "../../hooks";
import { EditSourceOfFundsModal } from "../../commons/modals/RegisterSourceOfFundsModal/EditSourceOfFundsModal";
import { RegisterSourceOfFundsModal } from "../../commons/modals/RegisterSourceOfFundsModal";
import { useMediaQuery } from "react-responsive";
import { SearchIcon } from "../../assets";
import { TransactionsDetails } from "commons/modals/TransactionDetails";
import { SubscriptionModal } from "commons/modals";

export const PayrollTransactionsScreen = (props) => {
  const location = useLocation();
  const [pageIndex, setPageIndex] = useState(1);
  const [totals, setTotal] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const { selectPeriodModalIsOpen, setSelectPeriodModalIsOpen, subscription } =
    useContext(PayrollContext);
  const { payrollTransactions, deletePendingTransaction, getTransactions } =
    useContext(TransactionsContext);
  const {
    canPayWithCobre,
    displayCobreAlert,
    sourceOfFundsNeeded,
    currentUser,
  } = useContext(UserContext);
  const [deleteItemModalIsVisible, setDeleteItemModalIsVisible] =
    useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const [itemToClone, setItemToClone] = useState();
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [notifyWompiModalIsVisible, setNotifyWompiModalIsVisible] =
    useState(id);
  const [detailsModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [selectedTrx, setSelectedTrx] = useState();
  const [wompiModalProps, setWompiModalProps] = useState({
    visible: false,
    totalAmount: 0,
    selectedRowsIds: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [trxRows, setTrxRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sourceOfFunds, setSourceOfFunds] = useState({});
  const [sourceOfFundsModal, setSourceOfFundsModal] = useState(false);
  const [searchInputText, setSearchInputText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [limitExceededAlert, setLimitExceededAlert] = useState({});
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const navigate = useNavigate();
  const redirectUrl = `${process.env.REACT_APP_BASE_URL}/transactions`;

  useChat();

  const openDetailsModal = (id) => {
    setSelectedTrx(id);
    setDetailModalIsOpen(true);
    setIsLoading(true);
    GetTransaction({ id: id })
      .then((res) => {
        setTransaction(res.transactions.transaction);
        setTrxRows(res.transactions.rows);
        setSourceOfFunds(res.transactions.source);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const refreshTransactionsTable = () => {
    getTransactions(pageIndex, "payroll", searchInputText).finally(() => {
      setLoading(false);
    });
    GetTotalTransactions({ type: "payroll", text: searchInputText }).then(
      (res) => {
        setTotal(res);
      }
    );
  };

  useEffect(() => {
    amplitude.logEvent("VIEW_LOTS_TAB", {
      user_id: currentUser.id,
      module: "pagos_empleados",
    });
    refreshTransactionsTable();
    location.state &&
      location.state.openDetail &&
      openDetailsModal(location.state.openDetail);
  }, [pageIndex]);

  useEffect(() => {
    refreshTransactionsTable();
  }, [pageIndex, searchInputText]);

  useEffect(() => {
    if (totals) {
      if (totals.total > 100) {
        setTotalPages(Math.ceil(totals.total / 100));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const closeDeleteItemModal = () => setDeleteItemModalIsVisible(false);
  const openDeleteItemModal = (id) => {
    setItemToDelete(id);
    setDeleteItemModalIsVisible(true);
  };
  const openCloneItemModal = (id) => {
    setItemToClone(id);
    setSelectPeriodModalIsOpen(true);
  };
  const closeNotifyWompiModal = (id) => {
    setNotifyWompiModalIsVisible(false);
    getTransactions(pageIndex, "payroll", searchInputText);
    navigate("/transactions", {
      replace: true,
    });
  };

  const openWompiModal = ({ visible, totalAmount, selectedRowsIds }) =>
    setWompiModalProps({
      visible: visible,
      totalAmount: totalAmount,
      selectedRowsIds: selectedRowsIds,
    });

  const closeWompiModal = () => {
    setWompiModalProps({
      ...wompiModalProps,
      visible: false,
    });
    refreshTransactionsTable();
  };

  useEffect(() => {
    if (wompiModalProps.visible) {
      setDetailModalIsOpen(false);
    }
  }, [wompiModalProps]);

  const getSearchOptions = (query) => {
    setSearchInputText(query);
  };

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onInputChange={(event) => {
          getSearchOptions(event.target.value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar por código"}
          />
        )}
      />
    );
  };

  const columns = useMemo(
    () => payrollTransactionsColumns(searchInputText),
    [searchInputText]
  );

  const handleCloseLimitExceededAlert = () => {
    setLimitExceededAlert((limitExceeded) => {
      return {
        ...limitExceeded,
        visible: false,
      };
    });
  };

  return (
    <>
      {subscription?.showModalSubscription && (
        <SubscriptionModal subscription={subscription} />
      )}
      <NotifyWompiModal
        visible={notifyWompiModalIsVisible}
        handleClose={closeNotifyWompiModal}
        type="payroll"
      />
      {sourceOfFundsNeeded ? (
        <RegisterSourceOfFundsModal
          visible={wompiModalProps.visible}
          handleClose={closeWompiModal}
          canPayWithCobre={canPayWithCobre}
          redirectUrl={redirectUrl}
          type="payroll"
          selectedId={transaction.source_of_funds_id}
          displayCobreAlert={displayCobreAlert}
          trxId={selectedTrx}
          setLimitExceededAlert={setLimitExceededAlert}
          {...wompiModalProps}
        />
      ) : (
        <PaymentModal
          handleClose={closeWompiModal}
          canPayWithCobre={canPayWithCobre}
          redirectUrl={redirectUrl}
          type="payroll"
          trxId={selectedTrx}
          displayCobreAlert={displayCobreAlert}
          setLimitExceededAlert={setLimitExceededAlert}
          {...wompiModalProps}
        />
      )}
      <SelectPayrollPeriodModal
        visible={selectPeriodModalIsOpen}
        handleClose={() => setSelectPeriodModalIsOpen(false)}
        action="clone"
        trxId={itemToClone}
      />
      <DeleteItemModal
        itemId={itemToDelete}
        action={deletePendingTransaction}
        handleClose={closeDeleteItemModal}
        title="¿Quieres borrar esta transacción pendiente de pago?"
        subtitle="Si la eliminas, no aparecerá más en Payana"
        visible={deleteItemModalIsVisible}
      />
      <LimitExceededModal
        onClose={handleCloseLimitExceededAlert}
        data={limitExceededAlert}
      />
      <TransactionsDetails
        subscription={subscription}
        visible={detailsModalIsOpen}
        onClose={() => {
          setDetailModalIsOpen(false);
          refreshTransactionsTable();
        }}
        sourceOfFunds={sourceOfFunds}
        setSourceOfFundsModal={setSourceOfFundsModal}
        id={selectedTrx}
        type={"payroll"}
        openWompiModal={openWompiModal}
        sourceOfFundsActive={true}
        isLoading={isLoading}
        transaction={transaction}
        trxRows={trxRows}
        closeDrawer={() => {
          setDetailModalIsOpen(false);
        }}
      />
      <EditSourceOfFundsModal
        transactionId={transaction?.id}
        selectedId={sourceOfFunds?.id}
        sourceOfFunds={sourceOfFunds}
        visible={sourceOfFundsModal}
        handleClose={() => setSourceOfFundsModal(false)}
        handleSuccess={setSourceOfFunds}
      />
      <div>
        <Submenu items={payrollScreens} />
        <div className="layout-container">
          <div style={styles.titleContainer}>
            <Typography sx={styles.title} noWrap>
              Lotes
            </Typography>
          </div>
          {loading ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={columns}
              data={payrollTransactions}
              setPageIndex={setPageIndex}
              section="payrollTrx"
              moneyIcon
              expandIcon
              expandAction={openDetailsModal}
              checkboxVisible={false}
              pageCount={totalPages}
              deleteIcon
              openDeleteItemModal={openDeleteItemModal}
              copyIcon
              copyAction={openCloneItemModal}
              renderAutocomplete={renderAutocomplete}
              emptyState={
                <TableEmptyState
                  title={"Tu empresa aún no tiene transacciones"}
                  subtitle={
                    "Aquí se mostrarán los pagos o agrupamientos de nóminas que realices."
                  }
                />
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

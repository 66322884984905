import { PrimaryButton } from "commons/buttons";

export const SearchButton = ({ onClick, disabled, setIsLoading }) => {
  return (
    <PrimaryButton
      text="Buscar"
      action={async () => {
        try {
          setIsLoading(true);
          await onClick();
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }}
      width="150px"
      isDisable={disabled}
      style={{
        alignSelf: "flex-end",
      }}
    />
  );
};

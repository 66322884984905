import { useEffect } from "react";
import { requestContainer } from "services/api/requestContainer";

export const useAbortableRequest = ({
  requestConfig,
  onInitRequest = () => {},
  onAbortRequest = () => {},
  preventRequest = () => false,
  requestThen = () => {},
  requestCatch = () => {},
  debounceMs = 1000,
}) => {
  useEffect(() => {
    if (preventRequest()) {
      return;
    }
    const controller = new AbortController();
    onInitRequest();
    const timeoutId = setTimeout(() => {
      requestContainer({
        ...requestConfig,
        signal: controller.signal,
      })
        .then((response) => {
          if (response?.code === "ERR_CANCELED") {
            return onAbortRequest(response);
          }
          requestThen(response);
        })
        .catch(requestCatch);
    }, debounceMs);
    return () => {
      clearTimeout(timeoutId);
      controller?.abort();
      onAbortRequest();
    };
  }, [requestConfig.url]);
};

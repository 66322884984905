import React from "react";

export const DownloadDocumentIcon = ({
  height = "14",
  width = "16",
  stroke = "#344054",
}) => (
  <svg width={width} height={height} viewBox="0 0 14 16" fill="none">
    <path
      d="M7.33333 1.33337H9.13333C10.2534 1.33337 10.8135 1.33337 11.2413 1.55136C11.6176 1.74311 11.9236 2.04907 12.1153 2.42539C12.3333 2.85322 12.3333 3.41327 12.3333 4.53337V11.4667C12.3333 12.5868 12.3333 13.1469 12.1153 13.5747C11.9236 13.951 11.6176 14.257 11.2413 14.4487C10.8135 14.6667 10.2534 14.6667 9.13333 14.6667H4.86667C3.74656 14.6667 3.18651 14.6667 2.75869 14.4487C2.38236 14.257 2.0764 13.951 1.88465 13.5747C1.66667 13.1469 1.66667 12.5868 1.66667 11.4667V11M9.66667 8.66671H6.66667M9.66667 6.00004H7.33333M9.66667 11.3334H4.33333M3 6.66671V3.00004C3 2.44776 3.44772 2.00004 4 2.00004C4.55228 2.00004 5 2.44776 5 3.00004V6.66671C5 7.77128 4.10457 8.66671 3 8.66671C1.89543 8.66671 1 7.77128 1 6.66671V4.00004"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { CrownIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import React from "react";

export const SubscriptionButton = ({
  subscription,
  isDisable,
  text,
  action,
  width,
  style,
  ...props
}) => {
  const handleAction = (callback) => {
    if (subscription) {
      subscription.handleActionSubscription(callback);
    } else {
      callback();
    }
  };

  return (
    <PrimaryButton
      icon={subscription?.showIcon && <CrownIcon size="small" fill="#EFA83C" />}
      isDisable={isDisable}
      text={text}
      action={() => handleAction(action)}
      width={width}
      style={style}
      {...props}
    />
  );
};

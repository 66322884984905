import { SearchButton } from "./SearchButton";
import { Title } from "./Title";
import { Subtitle } from "./Subtitle";
import { ModalitiesDropdown } from "./ModalitiesDropdown";
import { IdentifierValue } from "./IdentifierValue";
import { ServiceCompanyDropdown } from "./ServiceCompanyDropdown";
import "./debts-search.css";

export const DebtsSearch = ({
  options,
  onChange,
  searchOptionSelected,
  setIdentifierValue,
  searchDebts,
  modality,
  setModality,
  setServiceSearchInputText,
  searchingCompanyServiceByText,
  inputValue,
  identifierValue,
  isLoading,
  setIsLoading,
}) => {
  const className = isLoading
    ? " fade-out-with-translation"
    : " fade-in-with-translation";
  return (
    <div className={`debts-search-container${className}`}>
      <Title>Busca tus cuentas por pagar</Title>
      <Subtitle>
        Una vez que realices un pago, te avisaremos los próximos vencimientos.
      </Subtitle>
      <div className="debts-search-form-container">
        <ServiceCompanyDropdown
          options={options}
          onInputChange={setServiceSearchInputText}
          onChange={onChange}
          value={searchOptionSelected}
          loading={searchingCompanyServiceByText}
          inputValue={inputValue}
        />
        <ModalitiesDropdown
          searchOptionSelected={searchOptionSelected}
          modalitySelected={modality}
          onChange={setModality}
        />
        <IdentifierValue
          onChange={setIdentifierValue}
          identifierValue={identifierValue}
        />
      </div>
      <SearchButton
        onClick={searchDebts}
        disabled={!identifierValue || isLoading}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

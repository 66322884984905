import { Grid, Stack } from "@mui/material";
import React from "react";
import { Delete } from "./Delete";
import { isAdvance } from "../../constants";
import { PaymentSelect } from "./PaymentSelect";
import { InputDate } from "./InputDate";
import { SelectAdvance } from "./SelectAdvance";
import { InputAmount } from "./InputAmount";

export const PaymentRow = ({
  paymentsSelected = [],
  handleOnChangePayment,
  paymentsSearch,
  paymentsAdvances,
  handleDeletePayment,
}) => {
  return (
    <Stack gap={"8px"} width={651}>
      {paymentsSelected.map((payment, index) => (
        <Grid
          key={index}
          container
          direction={"row"}
          justifyContent={"space-between"}
          spacing={1}
        >
          <Grid item xs={5}>
            <PaymentSelect
              paymentsSearch={paymentsSearch}
              payment={payment}
              index={index}
              handleOnChangePayment={handleOnChangePayment}
            />
          </Grid>
          <Grid item xs={3}>
            {payment?.accounting_concept !== isAdvance && (
              <InputDate
                payment={payment}
                index={index}
                handleOnChangePayment={handleOnChangePayment}
              />
            )}
            {payment?.accounting_concept === isAdvance && (
              <SelectAdvance
                paymentsAdvances={paymentsAdvances}
                payment={payment}
                index={index}
                handleOnChangePayment={handleOnChangePayment}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <InputAmount
              payment={payment}
              index={index}
              handleOnChangePayment={handleOnChangePayment}
            />
          </Grid>
          <Grid item xs={1}>
            <Delete index={index} handleDeletePayment={handleDeletePayment} />
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
};

export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowMarginTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "24px",
  },
  rowMarginTopBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "24px",
    marginBottom: "24px",
  },
  fixedRight: {
    marginTop: "12px",
    marginLeft: "auto",
  },
  container: {
    backgroundColor: "#FEF0C7",
    border: "1px solid #eaecf0",
    borderRadius: "8px",
    padding: "8px",
    color: "#7A2E0E",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    marginBottom: "20px",
  },
  display: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "6px",
  },
  title: {
    fontWeight: 600,
    fontSize: "14px",
    marginLeft: "6px",
  },
  text: {
    fontWeight: 400,
    fontSize: "12px",
    marginLeft: "6px",
  },
};

import React from "react";

import styles from "../styles.module.css";
import { useMediaQuery } from "react-responsive";

export const AccountingTabs = ({ tabSelected, setTabSelected }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const handleChangeTab = (tabSelected) => {
    setTabSelected(tabSelected);
  };

  return (
    <div
      className={
        isMobile ? styles.tabsContainerMobile : styles.accountingTabsContainer
      }
    >
      <button
        className={`${styles.accountingTabBase} ${
          tabSelected === "reconcile" && styles.accountingTabSelected
        }`}
        onClick={() => handleChangeTab("reconcile")}
      >
        <label
          className={`${styles.accountingLabelBase} ${
            tabSelected === "reconcile" && styles.accountingLabelSelected
          }`}
        >
          Conciliar
        </label>
      </button>
      <button
        className={`${styles.accountingTabBase} ${
          tabSelected === "register" && styles.accountingTabSelected
        } `}
        onClick={() => handleChangeTab("register")}
      >
        <label
          className={`${styles.accountingLabelBase} ${
            tabSelected === "register" && styles.accountingLabelSelected
          }`}
        >
          Registrar
        </label>
      </button>
    </div>
  );
};

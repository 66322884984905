import React from "react";

import { IconButton } from "@mui/material";
import { transactionDetailTitle } from "constants";
import { styles } from "../styles";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { ClipboardIcon, DownloadFileIcon3 } from "assets";
import { commissionType } from "constants";
import { operationType } from "constants";
import { StatusPill } from "commons";
import { downloadFile, formatLocaleDate } from "utils";
import { getTransactionsAccountTypeLabel } from "constants";
import { WithoutBorderButton } from "commons/buttons";

export const GeneralInformationSection = ({
  transaction,
  sourceOfFunds,
  beneficiaryType = null,
}) => {
  const operation = {
    payana_commission: commissionType[transaction.commission_type],
    collection:
      beneficiaryType === "receiver"
        ? operationType["collectionReceiver"]
        : operationType["collectionCustomer"],
  };

  return (
    <div style={styles.sectionContainer}>
      <p style={styles.sectionTitle}>
        {transactionDetailTitle[transaction.operation_type]}
      </p>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          Código de pago
        </p>
        <div style={styles.sectionRowWithIcon}>
          <p style={styles.transactionHash} className="card-subtitle mb-2">
            {transaction?.operation_number?.toUpperCase()}
            <IconButton
              sx={styles.sectionIcon}
              onClick={() => {
                navigator.clipboard.writeText(
                  transaction?.operation_number?.toUpperCase()
                );
                toast.success(
                  "El código de pago ha sido copiado al portapapeles.",
                  toastOptions
                );
              }}
            >
              <ClipboardIcon />
            </IconButton>
          </p>
        </div>
      </div>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          Tipo de operación
        </p>
        <p style={styles.transactionHash} className="card-subtitle mb-2">
          {operation[transaction.operation_type] ||
            operationType[transaction.operation_type]}
        </p>
      </div>
      {transaction.operation_type === 'service' && (
        <div style={styles.sectionRow}>
          <p style={styles.title} className="card-subtitle mb-2">
            Servicio
          </p>
          <p style={styles.transactionHash} className="card-subtitle mb-2">
            {transaction.beneficiary_name}
          </p>
        </div>
      )}
      {transaction.operation_type === 'service' && (
        <div style={styles.sectionRow}>
          <p style={styles.title} className="card-subtitle mb-2">
            Alias
          </p>
          <p style={styles.transactionHash} className="card-subtitle mb-2">
            {transaction.alias}
          </p>
        </div>
      )}
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          Estado
        </p>
        <p style={styles.transactionHash} className="card-subtitle mb-2">
          <StatusPill
            status={transaction?.status}
            section={"transactions"}
            receipts={transaction?.receipts}
            className="tx-status-pill"
          />
        </p>
      </div>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          Fecha de pago
        </p>
        <p style={styles.transactionHash} className="card-subtitle mb-2">
          {formatLocaleDate(transaction.date_hour, "d MMM yyyy, hh:mm")}
        </p>
      </div>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          Código del lote
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p style={styles.transactionHash} className="card-subtitle mb-2">
            {transaction?.batch_number?.toUpperCase()}
            <IconButton
              sx={styles.sectionIcon}
              onClick={() => {
                navigator.clipboard.writeText(
                  transaction?.batch_number?.toUpperCase()
                );
                toast.success(
                  "El código del lote ha sido copiado al portapapeles.",
                  toastOptions
                );
              }}
            >
              <ClipboardIcon />
            </IconButton>
          </p>
        </div>
      </div>
      {sourceOfFunds.accountNumber && (
        <div style={styles.sectionRow}>
          <p style={styles.title} className="card-subtitle mb-2">
            Origen de fondos
          </p>
          <p style={styles.transactionHash} className="card-subtitle mb-2">
            {sourceOfFunds.bankName}{" "}
            {getTransactionsAccountTypeLabel(sourceOfFunds.accountType)}{" "}
            {sourceOfFunds.accountNumber}
          </p>
        </div>
      )}
      {transaction.operation_type !== "invoice" &&
        transaction.operation_type !== "collection" &&
        transaction.document_concept && (
          <div style={styles.sectionRow}>
            <p style={styles.title} className="card-subtitle mb-2">
              Concepto
            </p>
            <p style={styles.transactionHash} className="card-subtitle mb-2">
              {transaction?.document_concept}
            </p>
          </div>
        )}
      {transaction.receipt_file_path && (
        <div style={styles.sectionRow}>
          <p style={styles.title} className="card-subtitle mb-2"></p>
          <WithoutBorderButton
            text="Descargar soporte"
            action={() => downloadFile(transaction.receipt_file_path)}
            width="154px"
            style={styles.sectionButton}
            contentStyle={{ justifyContent: "flex-end" }}
            endIcon={<DownloadFileIcon3 stroke="#5925DC" size="20px" />}
          />
        </div>
      )}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { styles } from "./styles";
import * as CausationService from "../../../services/api/causation";
import { ApprovedIcon, ModalWarningIcon } from "../../../assets";
import { Box, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "commons/buttons";

export function CausationResponseModal({
  open,
  type,
  text = "",
  statusCode,
  onClose = () => {},
  id,
}) {
  const navigate = useNavigate();
  const [modalData, setModalData] = useState({
    title: "No pudimos causar tu factura.",
    description: "Por favor, inténtalo de nuevo en unos minutos.",
    twoButtons: false,
  });
  const action = {
    success: () => {
      onClose();
      navigate("/payments/invoices", { replace: true });
    },
    error: onClose,
  };

  const handleErrorMessage = {
    CAUSATION_ERROR: () => {
      setModalData({
        title: "No pudimos causar tu factura.",
        description: "Por favor, inténtalo de nuevo en unos minutos.",
        twoButtons: false,
      });
    },
    SERVICE_SIIGO_ERROR: () => {
      setModalData({
        title: "No pudimos causar tu factura.",
        description:
          "Siigo está con intermitencias. Por favor, vuelve a intentar de nuevo en unos minutos.",
        twoButtons: false,
      });
    },
    DUPLICATE_INVOICE: () => {
      setModalData({
        title: "El comprobante ya existe",
        description:
          "Para poder causar tu factura, es necesario que ingreses en Siigo y edites el consecutivo.",
        twoButtons: false,
      });
    },
    INVALID_OBJECT: () => {
      setModalData({
        title: "No pudimos procesar tu factura.",
        description:
          "Existen diferencias entre los valores de débito y crédito. Nuestro equipo está trabajando en solucionarlo. Por favor, inténtalo nuevamente en unos minutos.",
        twoButtons: false,
      });
    },
    NUMBERS_DIFFERENTS: () => {
      setModalData({
        title: "No pudimos causar tu factura.",
        description: "Por favor, inténtalo de nuevo en unos minutos.",
        twoButtons: false,
      });
    },
    EXPIRED_SUBSCRIPTION: () => {
      setModalData({
        title: "Suscripción vencida en Siigo.",
        description:
          "Para poder causar tu factura, es necesario que actualices la suscripción en Siigo.",
        twoButtons: false,
      });
    },
    METHOD_PAYMENT_UNABLED: () => {
      setModalData({
        title: "El método de pago no está activo en Siigo.",
        description:
          "Para poder causar tu factura, es necesario que actualices el método de pago en Siigo.",
        twoButtons: false,
      });
    },
    UPDATE_SIIGO_INFO: () => {
      setModalData({
        title: "No pudimos causar tu factura.",
        description:
          "Para poder causar tu factura, es necesario que actualices tu información en Siigo.",
        twoButtons: false,
      });
    },
  };

  useEffect(() => {
    open && text && handleErrorMessage[text]();
  }, [open, text]);

  const modalViews = {
    success: successCausation(action[type], statusCode),
    error: errorCausation(action[type], modalData, id),
    loading: loading(),
  };

  return (
    <Modal show={open} onClose={onClose} centered>
      <Modal.Body style={styles.body}>{modalViews[type]}</Modal.Body>
    </Modal>
  );
}

function successCausation(onClose, statusCode) {
  return (
    <div
      style={{ maxWidth: "400px", display: "flex", flexDirection: "column" }}
    >
      <div style={{ display: "flex", alignSelf: "center" }}>
        <ApprovedIcon />
      </div>
      <p style={{ ...styles.modalTitle, color: "#039855", marginTop: "16px" }}>
        ¡Causación exitosa!
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          paddingBottom: "5px",
          gap: "4px",
        }}
      >
        <p style={styles.modalText}>
          Podrás verlo reflejado desde el listado de compra y gasto en Siigo.
        </p>
        {statusCode === 200 && (
          <span
            style={{
              ...styles.modalText,
              marginTop: "4px",
            }}
          >
            En Payana hemos actualizado tu factura y agregado etiquetas.
          </span>
        )}
      </div>
      <div style={styles.buttonGroup}>
        <PrimaryButton
          text="Entendido"
          action={onClose}
          style={{ width: "50%" }}
        />
      </div>
    </div>
  );
}

function errorCausation(onClose, modalData, id) {
  const handleMarkAsCaused = () => {
    CausationService.updateInvoices([id]);
    onClose();
  };
  return (
    <div
      style={{ maxWidth: "400px", display: "flex", flexDirection: "column" }}
    >
      <div style={{ display: "flex", alignSelf: "center" }}>
        <ModalWarningIcon />
      </div>
      <p style={styles.errorModalTitle}>{modalData.title}</p>
      <p style={styles.errorModalText}>{modalData.description}</p>
      <div style={styles.buttonGroup}>
        {modalData.twoButtons ? (
          <>
            <SecondaryButton
              text="Salir"
              action={onClose}
              style={{ width: "100%" }}
            />
            <PrimaryButton
              text="Marcar como causada"
              action={handleMarkAsCaused}
              style={{ width: "100%" }}
            />
          </>
        ) : (
          <PrimaryButton
            text="Entendido"
            action={onClose}
            style={{ width: "50%" }}
          />
        )}
      </div>
    </div>
  );
}

function loading() {
  return (
    <div style={styles.loading}>
      <p style={{ ...styles.title, textAlign: "center" }}>
        <strong>Enviando información a Siigo</strong>
      </p>
      <p
        style={{
          color: "#475467",
          fontSize: 14,
          textAlign: "center",
          fontWeight: 400,
        }}
      >
        <span>
          Este proceso puede demorar. Por favor no cierres esta ventana.
        </span>
      </p>
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    </div>
  );
}

import React from "react";
import logo from "./bancolombia.png";
export const BancolombiaPNG = ({ width = 32, height = 32 }) => (
  <img
    width={width}
    height={height}
    src={logo}
    style={{ marginRight: "8px" }}
    alt=""
  />
);

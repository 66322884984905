import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts";

export const LoginBubbleScreen = () => {
  const { login } = useContext(UserContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get("email");
  const password = queryParameters.get("id");
  const payment_obligation = queryParameters.get("payment_obligation");
  const navigate = useNavigate();

  const loginUser = (email, password) => {
    login(email, window.atob(password)).then((response) => {
      response.status === 200
        ? navigate(
            payment_obligation
              ? "/payments/invoices?payment_obligation=true"
              : "/payments/invoices",
            { replace: true }
          )
        : navigate(`/login?email=${email}`, { replace: true });
    });
  };

  useEffect(() => {
    loginUser(email, password);
  });
  return null;
};

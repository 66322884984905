import React from "react";

export const AddresseeIcon = ({
  background = "#F4F4F5",
  color = "#6938EF",
}) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g>
      <rect width="32" height="32" rx="16" fill={background} />
      <path
        d="M10 21.3333C11.5572 19.6817 13.6713 18.6667 16 18.6667C18.3287 18.6667 20.4428 19.6817 22 21.3333M19 13C19 14.6569 17.6569 16 16 16C14.3431 16 13 14.6569 13 13C13 11.3431 14.3431 10 16 10C17.6569 10 19 11.3431 19 13Z"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

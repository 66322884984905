import React from "react";
import { Box, Typography } from "@mui/material";
import { PayanaAlegra } from "../../../../assets";

export function LoaderSyncAlegra() {
  return (
    <Box>
      <PayanaAlegra />
      <Typography
        style={{
          fontSize: 18,
          fontWeight: 600,
          lineHeight: "28px",
          color: "#101828",
          textAlign: "center",
        }}
      >
        Estamos importando información
      </Typography>
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
          color: "#475467",
          textAlign: "center",
        }}
      >
        Este proceso puede demorar hasta 10 minutos. Por favor no cierres la
        pestaña de tu navegador.
      </Typography>
    </Box>
  );
}

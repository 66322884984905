import { Box, Divider, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts";
import { GetReminders, UpdateReminders } from "../../../services";
import { styles } from "../styles";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import {
  PhoneNumberInput,
  SwitchWithText,
  TableSkeleton,
} from "../../../commons";
import { MediaTabs } from "./MediaTabs";
import { phoneChars } from "../../../utils";
import { SettingsFooter } from "./FooterHandler";

export const RemindersTab = () => {
  const { currentUser, companyWithDuesReminders } = useContext(UserContext);
  const [response, setResponse] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState({
    helperText: "",
    error: false,
  });
  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState({
    helperText: "",
    error: false,
  });
  const [beforeTabSelected, setBeforeTabSelected] = useState("whatsapp");
  const [afterTabSelected, setAfterTabSelected] = useState("whatsapp");
  const [oneDayBeforeChecked, setOneDayBeforeChecked] = useState(true);
  const [threeDaysBeforeChecked, setThreeDaysBeforeChecked] = useState(true);
  const [sevenDaysBeforeChecked, setSevenDaysBeforeChecked] = useState(true);
  const [oneDayAfterChecked, setOneDayAfterChecked] = useState(true);
  const [sevenDaysAfterChecked, setSevenDaysAfterChecked] = useState(true);
  const [fourteenDaysAfterChecked, setFourteenDaysAfterChecked] =
    useState(true);
  const [twentyoneDaysAfterChecked, setTwentyoneDaysAfterChecked] =
    useState(true);
  const [sixtyDaysAfterChecked, setSixtyDaysAfterChecked] = useState(true);
  const [saveSettingsButtonIsDisabled, setSaveSettingsButtonIsDisabled] =
    useState(true);

  useEffect(() => {
    GetReminders().then((res) => {
      setResponse(res);
      if (res.id) {
        setEmail(res.email);
        setPhone(res.phone);
        setOneDayBeforeChecked(res.before_expiration_date.at(0).enabled);
        setThreeDaysBeforeChecked(res.before_expiration_date.at(1).enabled);
        setSevenDaysBeforeChecked(res.before_expiration_date.at(2).enabled);
        setOneDayAfterChecked(res.after_expiration_date.at(0).enabled);
        setSevenDaysAfterChecked(res.after_expiration_date.at(1).enabled);
        setFourteenDaysAfterChecked(res.after_expiration_date.at(2).enabled);
        setTwentyoneDaysAfterChecked(res.after_expiration_date.at(3).enabled);
        setSixtyDaysAfterChecked(res.after_expiration_date?.at(4)?.enabled);
      }
    });
  }, []);

  const updateRemindersHandler = async () => {
    try {
      await UpdateReminders({
        id: response.id,
        data: {
          email: email,
          phone: phone,
          beforeExpirationDate: [
            {
              days: 1,
              enabled: oneDayBeforeChecked,
            },
            {
              days: 3,
              enabled: threeDaysBeforeChecked,
            },
            {
              days: 7,
              enabled: sevenDaysBeforeChecked,
            },
          ],
          afterExpirationDate: [
            {
              days: 1,
              enabled: oneDayAfterChecked,
            },
            {
              days: 7,
              enabled: sevenDaysAfterChecked,
            },
            {
              days: 14,
              enabled: fourteenDaysAfterChecked,
            },
            {
              days: 21,
              enabled: twentyoneDaysAfterChecked,
            },
            {
              days: 60,
              enabled: sixtyDaysAfterChecked,
            },
          ],
        },
      });
      toast.success(
        "¡Los recordatorios se actualizaron con éxito!",
        toastOptions
      );
      setSaveSettingsButtonIsDisabled(true);
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
  };

  const renderWording = () =>
    companyWithDuesReminders ? "cuotas" : "facturas";

  const renderBeforeNotificationWpp = () => {
    return (
      <Typography sx={{ ...styles.remindersLabel, width: "100%" }}>
        ¡Hola! 👋 Solo un recordatorio rápido: tienes {renderWording()} próximas
        a vencer con {currentUser.company.name}.
        <br />
        <br />
        [Detalle de las {renderWording()}]
        <br />
        <br />
        En el siguiente link podrás completar tu pago fácilmente:
        [link-de-pago].
        <br />
        <br />
        Si necesitas ayuda, contáctanos por email a {email} o por teléfono al{" "}
        {phone}.
      </Typography>
    );
  };

  const renderBeforeNotificationEmail = () => {
    return (
      <Typography sx={{ ...styles.remindersLabel, width: "100%" }}>
        Estimado cliente,
        <br />
        <br />
        Le recordamos que sus {companyWithDuesReminders
          ? "cuotas"
          : "facturas"}{" "}
        pendientes de pago con {currentUser.company.name} se encuentran próximas
        a vencer.
        <br />
        <br />
        [Detalle de las {renderWording()}]
        <br />
        <br />
        ¿Tienes alguna consulta? Contacta a {currentUser.company.name} por email
        a {email} o por teléfono al {phone}.
      </Typography>
    );
  };

  const renderAfterNotificationWpp = () => {
    return (
      <Typography sx={{ ...styles.remindersLabel, width: "100%" }}>
        Hola, te informamos que tus {renderWording()} con{" "}
        {currentUser.company.name} están vencidas.
        <br />
        <br />
        [Detalle de las {renderWording()}]
        <br />
        <br />
        Realice el pago de inmediato en el siguiente link: [link-de-pago].
        <br />
        <br />
        Si ya pagaste o necesitas ayuda, contáctanos a {email} o al {phone}.
      </Typography>
    );
  };

  const renderAfterNotificationEmail = () => {
    return (
      <Typography sx={{ ...styles.remindersLabel, width: "100%" }}>
        Estimado cliente,
        <br />
        <br />
        Le informamos que tiene {renderWording()} vencidas con{" "}
        {currentUser.company.name}
        . Realice el pago de inmediato para cancelar sus deudas y evitar
        inconvenientes. Si ya pagó, ignore este aviso.
        <br />
        <br />
        Para cualquier duda, contacte a {currentUser.company.name} por email a{" "}
        {email} o por teléfono al {phone}.
        <br />
        <br />
        [Detalle de las {renderWording()}]
      </Typography>
    );
  };

  const switchPreExpiration = [
    {
      day: 1,
      checked: oneDayBeforeChecked,
      setChecked: setOneDayBeforeChecked,
    },
    {
      day: 3,
      checked: threeDaysBeforeChecked,
      setChecked: setThreeDaysBeforeChecked,
    },
    {
      day: 7,
      checked: sevenDaysBeforeChecked,
      setChecked: setSevenDaysBeforeChecked,
    },
  ];

  const switchPostExpiration = [
    {
      day: 1,
      checked: oneDayAfterChecked,
      setChecked: setOneDayAfterChecked,
    },
    {
      day: 7,
      checked: sevenDaysAfterChecked,
      setChecked: setSevenDaysAfterChecked,
    },
    {
      day: 14,
      checked: fourteenDaysAfterChecked,
      setChecked: setFourteenDaysAfterChecked,
    },
    {
      day: 21,
      checked: twentyoneDaysAfterChecked,
      setChecked: setTwentyoneDaysAfterChecked,
    },
    {
      day: 60,
      checked: sixtyDaysAfterChecked,
      setChecked: setSixtyDaysAfterChecked,
    },
  ];

  return (
    <>
      {response ? (
        <>
          <Box sx={{ width: "100%", marginBottom: "32px" }} px={4}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={4}
              sx={{ width: "100%" }}
            >
              <Box sx={{ justifyContent: "flex-start" }}>
                <Typography sx={styles.inputLabel} noWrap>
                  Información para soporte en recaudos
                </Typography>
                <Typography sx={styles.inputSubLabel}>
                  Esta información será compartida con tus clientes en las
                  notificaciones de cobro.
                </Typography>
              </Box>
              <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
                <Stack spacing={1} sx={{ width: "60%" }}>
                  <Typography sx={styles.inputLabel} noWrap>
                    Email
                  </Typography>
                  <TextField
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFFFFF",
                      color: "#D0D5DD",
                    }}
                    size="small"
                    variant="outlined"
                    value={email}
                    InputProps={{ style: { fontSize: 14 } }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (e.target.value.length === 0) {
                        setEmailError({
                          helperText: "Este campo no puede estar vacío",
                          error: true,
                        });
                      } else {
                        if (!e.target.value.match(/\S+@\S+\.\S+/)) {
                          setEmailError({
                            helperText: "El formato del correo no es válido",
                            error: true,
                          });
                        } else {
                          setEmailError({
                            helperText: "",
                            error: false,
                          });
                        }
                      }
                      setSaveSettingsButtonIsDisabled(false);
                    }}
                    error={emailError.error}
                  />
                  {emailError.error && (
                    <Typography sx={styles.inputError} noWrap>
                      {emailError.helperText}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={1} sx={{ width: "60%" }}>
                  <Typography sx={styles.inputLabel} noWrap>
                    Teléfono
                  </Typography>
                  <PhoneNumberInput
                    phone={phone || "+57"}
                    setPhone={(value) => {
                      setPhone(value);
                      if (value.length === 0) {
                        setPhoneError({
                          helperText: "Este campo no puede quedar vacío.",
                          error: true,
                        });
                      } else if (
                        value.length < phoneChars(value) ||
                        value.length > phoneChars(value)
                      ) {
                        setPhoneError({
                          helperText: `El número ingresado debe tener ${phoneChars(
                            value
                          )} caracteres.`,
                          error: true,
                        });
                      } else {
                        setPhoneError({
                          helperText: "",
                          error: false,
                        });
                      }
                      setSaveSettingsButtonIsDisabled(false);
                    }}
                  />
                  {phoneError.error && (
                    <Typography sx={styles.inputError} noWrap>
                      {phoneError.helperText}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Divider sx={styles.divider} />
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={4}
              sx={{ width: "100%" }}
            >
              <Box sx={{ justifyContent: "flex-start" }}>
                <Typography sx={styles.inputLabel} noWrap>
                  Notificaciones pre-vencimento
                </Typography>
                <Typography sx={styles.inputSubLabel}>
                  Estos recordatorios se enviaran vía email y WhatsApp a partir
                  de que crees un cobro.
                </Typography>
              </Box>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Stack spacing={1}>
                  {switchPreExpiration.map((switchItem) => (
                    <SwitchWithText
                      key={switchItem.day}
                      text={
                        <span style={styles.switchText}>
                          Enviar recordatorio{" "}
                          <span style={styles.switchHighlightedText}>
                            {switchItem.day} día antes
                          </span>{" "}
                          de la fecha de vencimiento
                        </span>
                      }
                      checked={switchItem.checked}
                      setChecked={switchItem.setChecked}
                      type="reminders"
                      enableSaveButton={() =>
                        setSaveSettingsButtonIsDisabled(false)
                      }
                    />
                  ))}
                </Stack>
              </Stack>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  backgroundColor: "#F2F4F7",
                  borderRadius: "8px",
                  padding: "16px 16px 16px 16px",
                  width: "60%",
                }}
              >
                <MediaTabs
                  tabSelected={beforeTabSelected}
                  setTabSelected={setBeforeTabSelected}
                />
                {beforeTabSelected === "whatsapp"
                  ? renderBeforeNotificationWpp()
                  : renderBeforeNotificationEmail()}
              </Box>
            </Stack>
            <Divider sx={styles.divider} />
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={4}
            >
              <Box sx={{ justifyContent: "flex-start" }}>
                <Typography sx={styles.inputLabel} noWrap>
                  Notificaciones post-vencimiento
                </Typography>
                <Typography sx={styles.inputSubLabel}>
                  Las notificaciones posteriores al vencimiento sólo se enviarán
                  si no se identifica el pago.
                </Typography>
              </Box>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                {switchPostExpiration.map((switchItem) => (
                  <Stack spacing={1} key={switchItem.day}>
                    <SwitchWithText
                      text={
                        <span style={styles.switchText}>
                          Enviar recordatorio{" "}
                          <span style={styles.switchHighlightedText}>
                            {switchItem.day} día después
                          </span>{" "}
                          de la fecha de vencimiento
                        </span>
                      }
                      checked={switchItem.checked}
                      setChecked={switchItem.setChecked}
                      type="reminders"
                      enableSaveButton={() =>
                        setSaveSettingsButtonIsDisabled(false)
                      }
                    />
                  </Stack>
                ))}
              </Stack>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  backgroundColor: "#F2F4F7",
                  borderRadius: "8px",
                  padding: "16px 16px 16px 16px",
                  width: "60%",
                }}
              >
                <MediaTabs
                  tabSelected={afterTabSelected}
                  setTabSelected={setAfterTabSelected}
                />
                {afterTabSelected === "whatsapp"
                  ? renderAfterNotificationWpp()
                  : renderAfterNotificationEmail()}
              </Box>
            </Stack>
          </Box>
          <SettingsFooter
            saveSettingsChanges={updateRemindersHandler}
            saveSettingsButtonIsDisable={saveSettingsButtonIsDisabled}
          />
        </>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
};

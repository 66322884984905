import { Footer } from "../../../commons";

export const SettingsFooter = ({
  cancelButton = null,
  saveSettingsChanges = () => {},
  cancelSettingsChanges = () => {},
  saveSettingsButtonIsDisable = false,
}) => {
  return (
    <Footer
      section="settings"
      saveSettingsChanges={saveSettingsChanges}
      saveSettingsButtonIsDisable={saveSettingsButtonIsDisable}
      showAmount={false}
      cancelButton={cancelButton}
      cancelSettingsChanges={cancelSettingsChanges}
    />
  );
};

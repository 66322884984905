import React from "react";
import { styles } from "../styles";

export const BeneficiaryRow = ({
  cell,
  openSupplier,
  openEmployee,
  openCustomer,
}) => {
  const row = cell.row.original;
  const type = row.transaction_type;
  const text = {
    invoice: row.provider_name,
    payroll: row.employee_name,
    collection: row.customer_name,
  };

  const action = {
    invoice: () => row.provider_id && openSupplier(row.provider_id),
    payroll: () => row.employee_id && openEmployee(row.employee_id),
    collection: () => row.customer_id && openCustomer(row.customer_id),
  };

  return (
    <div style={styles.tableRow} onClick={action[type]}>
      <p
        style={{
          ...styles.revert,
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "18px",
          color: "#101828",
          textTransform: "uppercase",
        }}
      >
        {text[type]}
      </p>
    </div>
  );
};

function BankingCorrespondantIcon() {
  return (
    <svg
      width="46"
      height="32"
      viewBox="0 0 46 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="45" height="31" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="45" height="31" rx="3.5" stroke="#F2F4F7" />
      <path
        d="M25 13H22.5C21.6716 13 21 13.6716 21 14.5C21 15.3284 21.6716 16 22.5 16H23.5C24.3284 16 25 16.6716 25 17.5C25 18.3284 24.3284 19 23.5 19H21M23 12V13M23 19V20M29 16H29.01M17 16H17.01M13 12.2L13 19.8C13 20.9201 13 21.4802 13.218 21.908C13.4097 22.2843 13.7157 22.5903 14.092 22.782C14.5198 23 15.0799 23 16.2 23L29.8 23C30.9201 23 31.4802 23 31.908 22.782C32.2843 22.5903 32.5903 22.2843 32.782 21.908C33 21.4802 33 20.9201 33 19.8V12.2C33 11.0799 33 10.5198 32.782 10.092C32.5903 9.7157 32.2843 9.40974 31.908 9.21799C31.4802 9 30.9201 9 29.8 9L16.2 9C15.0799 9 14.5198 9 14.092 9.21799C13.7157 9.40973 13.4097 9.71569 13.218 10.092C13 10.5198 13 11.0799 13 12.2ZM29.5 16C29.5 16.2761 29.2761 16.5 29 16.5C28.7239 16.5 28.5 16.2761 28.5 16C28.5 15.7239 28.7239 15.5 29 15.5C29.2761 15.5 29.5 15.7239 29.5 16ZM17.5 16C17.5 16.2761 17.2761 16.5 17 16.5C16.7239 16.5 16.5 16.2761 16.5 16C16.5 15.7239 16.7239 15.5 17 15.5C17.2761 15.5 17.5 15.7239 17.5 16Z"
        stroke="#027A48"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BankingCorrespondantIcon;

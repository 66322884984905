import React from "react";
import { Box } from "@mui/material";
import { Button } from "react-bootstrap";
import { styles } from "./styles";
import { ModalDeleteIcon } from "../../../assets";
import ModalShell from "../PaymentModal/ModalShell";

export const CancelTransactionModal = ({ visible, onClose, onConfirm }) => {
  return (
    <ModalShell visible={visible} onClose={onClose}>
      <Box sx={styles.container}>
        <ModalDeleteIcon />
        <p style={styles.title}>
          ¿Estás seguro que deseas eliminar esta transacción?
        </p>
        <p style={styles.subtitle}>
          Si continúas, tus documentos volverán al estado{" "}
          <strong>“Por Pagar”</strong> y la transacción desaparecerá.
        </p>
        <p style={{ ...styles.subtitle, color: "red" }}>
          Si la transacción ya fue abonada, evita eliminarla.
        </p>
        <div style={styles.row}>
          <Button
            variant="secondary"
            style={styles.modalSecondaryButton}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            style={styles.modalPrimaryButton}
            onClick={onConfirm}
          >
            Confirmar
          </Button>
        </div>
      </Box>
    </ModalShell>
  );
};

import { NotesIcon } from "../../../assets/svg/NotesIcon";

export const NumberOfNotesIcon = ({ numberOfNotes }) => {
  const hasNotes = Number.isFinite(numberOfNotes) && numberOfNotes > 0;
  return hasNotes ? (
    <span
      style={{ margin: "0px 10px" }}
      title={getNumberOfNotesTitle(numberOfNotes)}
    >
      <NotesIcon />
    </span>
  ) : (
    <span style={{ margin: "0px 16px" }} />
  );
};

const getNumberOfNotesTitle = (numberOfNotes) =>
  `Tiene ${numberOfNotes} ${numberOfNotes > 1 ? "notas" : "nota"}`;

import { Stack } from "@mui/material";
import { SmallInfoIcon } from "assets";

export const HelpText = ({ children }) => (
  <Stack
    direction="row"
    alignItems="center"
    gap={0.5}
    sx={{ paddingLeft: "24px", paddingTop: "16px" }}
  >
    <SmallInfoIcon />
    <span className="help-text">{children}</span>
  </Stack>
);

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import { CHANGE_SERVICE_COMPANY_ALIAS_MODAL } from "../keys";
import { CloseButton } from "..";
import { RequiredLabel } from "commons/components";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { useState } from "react";
import { changeAliasFromServiceCompany } from "services";

export const ChangeServiceAliasModal = ({
  openModalProps,
  setClosedModalKeys,
  visible,
}) => {
  const [aliasToChange, setAliasToChange] = useState("");
  return (
    <Dialog
      fullScreen
      open={visible}
      {...openModalProps[CHANGE_SERVICE_COMPANY_ALIAS_MODAL]}
    >
      <DialogTitle>
        <CloseButton
          onClick={openModalProps[CHANGE_SERVICE_COMPANY_ALIAS_MODAL]?.onClose}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <h1>Agregar alias</h1>
          <RequiredLabel label="Ingresa el alias" />
          <TextField
            size="small"
            variant="outlined"
            placeholder="Ejemplo: Claro"
            fullWidth
            value={aliasToChange}
            onChange={(event) => {
              setAliasToChange(event.target.value);
            }}
          />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <SecondaryButton
          text="Cancelar"
          action={() => setClosedModalKeys(CHANGE_SERVICE_COMPANY_ALIAS_MODAL)}
        />
        <PrimaryButton
          text="Guardar"
          action={async () => {
            await changeAliasFromServiceCompany(
              openModalProps[CHANGE_SERVICE_COMPANY_ALIAS_MODAL]?.row?.original
                ?.id,
              aliasToChange
            );
            openModalProps[CHANGE_SERVICE_COMPANY_ALIAS_MODAL]?.onClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

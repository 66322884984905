export const HelpCircleIcon = ({ style = {} }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={style}>
    <path
      d="M6.06004 6.00102C6.21678 5.55546 6.52614 5.17976 6.93334 4.94044C7.34055 4.70112 7.8193 4.61364 8.28483 4.69349C8.75035 4.77334 9.17259 5.01537 9.47676 5.3767C9.78093 5.73804 9.94741 6.19537 9.94671 6.66768C9.94671 8.00102 7.94671 8.66768 7.94671 8.66768M8.00004 11.3344H8.00671M14.6667 8.00102C14.6667 11.6829 11.6819 14.6677 8.00004 14.6677C4.31814 14.6677 1.33337 11.6829 1.33337 8.00102C1.33337 4.31912 4.31814 1.33435 8.00004 1.33435C11.6819 1.33435 14.6667 4.31912 14.6667 8.00102Z"
      stroke="#475467"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

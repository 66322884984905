import { NumericFormat } from "react-number-format";
import { styles } from "./styles";
import "./styles.css";
import { IconButton, Link } from "@mui/material";
import { DownloadFileIcon } from "../../../assets";
import { useNavigate, useLocation } from "react-router-dom";
import getReceiptFileURL from "./getReceiptFileURL";
import { StatusPill } from "../../Table/components";

export const ItemsInTransaction = ({
  index,
  rows,
  suppliers,
  type = "invoice",
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const supplier = suppliers[index];
  const navigateTo = (url) =>
    navigate(url, {
      state: { referrer: location.pathname },
    });
  return (
    <>
      <div style={styles.column}>
        <div style={styles.trxRow}>
          <p style={styles.textWithoutLimit} className="card-subtitle mb-2">
            {supplier.toUpperCase()}
          </p>
          {rows[0].status === "paid" && (
            <Link
              href={getReceiptFileURL(rows)}
              target="_blank"
              sx={{ marginBottom: "6px" }}
            >
              <IconButton>
                <DownloadFileIcon size={20} stroke={"#98A2B3"} />
              </IconButton>
            </Link>
          )}
        </div>
        {rows.map((row, i) => {
          const { id, amount, concept, amount_total, invoice_number, status } =
            row;
          return (
            <div
              key={type === "invoice" ? invoice_number : concept}
              onClick={() =>
                type === "invoice"
                  ? navigateTo(`/payments/invoices/${id}`)
                  : navigateTo(`/collections/index/${id}`)
              }
            >
              <div
                style={{
                  ...styles.trxRow,
                  marginTop: "-4px",
                  cursor: "pointer",
                }}
              >
                <p style={styles.rowTextLeftWithoutLimit}>
                  <NumericFormat
                    value={parseInt(type === "invoice" ? amount_total : amount)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                    decimalScale={2}
                  />{" "}
                  {invoice_number ||
                    (concept && (
                      <>
                        <span style={styles.biggerRowTextLeftWithoutLimit}>
                          {" › "}
                        </span>
                        {type === "invoice" ? invoice_number : concept}
                      </>
                    ))}
                </p>
                <div style={{ marginTop: "-10px" }}>
                  <StatusPill
                    status={status}
                    invoiceIsApproved={row.approved}
                    transactionStatus={row.transaction_status}
                    receipts={row.receipts}
                    section={"invoices"}
                  />
                </div>
              </div>
              <hr style={{ ...styles.separator, marginBottom: "16px" }} />
            </div>
          );
        })}
      </div>
    </>
  );
};

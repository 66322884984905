import React, { useContext } from "react";
import { NumericFormat } from "react-number-format";
import { Divider } from "@mui/material";
import { styles } from "../../../Table/styles";
import { PAID_OUTSIDE, getAccountTypeLabel } from "../../../../constants";
import { applyFormatDate } from "../../../../utils/formatDate";
import { ThirdButton } from "../../../buttons";
import { AddresseeIcon, DataIcon, PaymentIcon } from "../../../../assets";
import { InProcessDisclaimer } from "./InProcessDisclaimer";
import { useNavigate } from "react-router-dom";
import { Status } from "./Status";
import { getSourceOfFundsLabel } from "../../RegisterSourceOfFundsModal/getSourceOfFundsLabel";
import { PaymentsContext } from "../../../../contexts";
import Downloader from "../../../../services/Downloader";
import { StatusPill } from "../../../Table/components";

export const InvoiceDetail = ({ invoice, approvationNeeded, supplier }) => {
  const invoiceStatusIncludes = (...states) =>
    states.includes(invoice.status.toLowerCase());
  const navigate = useNavigate();
  const { invoicesScreen } = useContext(PaymentsContext);
  const setProviderIdSelected = invoicesScreen?.providerIdSelected?.setter;
  const isService = invoice.type === "service";

  const downloadFile = (filePath, type, id) =>
    Downloader.push(filePath, `${type}-${id}.pdf`);

  const closeAndFilterSupplier = () => {
    setProviderIdSelected(supplier.id);
    invoicesScreen?.supplierFilter?.setter({
      id: supplier.id,
      name: supplier.name,
      document_number: supplier.document_number,
    });
    navigate("/payments/invoices", {
      replace: false,
    });
    return null;
  };

  const transaction_paid_at = invoice?.transaction_paid_at?.slice(0, -1);
  const transaction_created_at = invoice?.transaction_created_at?.slice(0, -1);
  const expiration_date = invoice?.expiration_date?.slice(0, -1);

  return (
    <>
      {invoiceStatusIncludes("in_process") && <InProcessDisclaimer />}
      <div style={styles.buttonsContainer}>
        {invoice.receipt_file_path && (
          <ThirdButton
            text={"Descargar soporte"}
            action={() =>
              downloadFile(invoice.receipt_file_path, "Soporte", invoice?.id)
            }
            width={235}
          />
        )}
        {invoice.file_path && (
          <ThirdButton
            text={"Descargar documento"}
            action={() =>
              downloadFile(invoice.file_path, "Documento", invoice?.id)
            }
            width={235}
          />
        )}
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <DataIcon />
          <span style={styles.cardTitle}>Datos</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Monto</span>
          <span style={styles.cardData}>
            <NumericFormat
              value={invoice.amount_total}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </span>
        </div>
        {invoice.invoice_number && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Comprobante</span>
              <span style={styles.cardData}>{invoice.invoice_number}</span>
            </div>
          </>
        )}

        {expiration_date && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Fecha de vencimiento</span>
              <span style={styles.cardData}>
                {applyFormatDate(expiration_date)}
              </span>
            </div>
          </>
        )}
        {invoice.concept && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Concepto</span>
              <span style={styles.cardData}>{invoice.concept}</span>
            </div>
          </>
        )}
        {invoice.tags.length > 0 && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Etiquetado</span>
              <span style={styles.cardData}>
                {invoice.tags.map((tag) => {
                  return (
                    <span className="tag-pill-default" title={tag.name}>
                      {tag.name}
                    </span>
                  );
                })}
              </span>
            </div>
          </>
        )}
        {approvationNeeded && invoice.approved && invoice.approved_by && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Aprobador</span>
              <span style={styles.cardData}>{invoice.approved_by}</span>
            </div>
          </>
        )}
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <AddresseeIcon />
          <span style={styles.cardTitle}>Destinatario</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>
            {isService ? "Servicio" : "Proveedor"}
          </span>
          <span
            style={isService ? styles.cardData : styles.cardDataLink}
            onClick={() => !isService && closeAndFilterSupplier()}
          >
            {isService ? invoice.service_name?.toUpperCase() : supplier.name}
          </span>
        </div>
        {isService && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Alias</span>
              <span style={styles.cardData}>
                {invoice.service_alias?.toUpperCase()}
              </span>
            </div>
          </>
        )}
        {!isService && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Entidad bancaria</span>
              <span style={styles.cardData}>{supplier.bank_name}</span>
            </div>
          </>
        )}
        {!isService && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>
                {supplier.account_type
                  ? getAccountTypeLabel(supplier.account_type)
                  : "Número de celular"}
              </span>
              <span style={styles.cardData}>{supplier.account_number}</span>
            </div>
          </>
        )}
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>
            {isService ? "Información de pago" : "NIT"}
          </span>
          <span style={styles.cardData}>
            {isService ? invoice.service_number : supplier.document_number}
          </span>
        </div>
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <PaymentIcon />
          <span style={styles.cardTitle}>Detalles del pago</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Estado</span>
          <span>
            <StatusPill
              section="transactions"
              status={
                invoice.status === PAID_OUTSIDE
                  ? PAID_OUTSIDE
                  : invoice.transaction_status
              }
              receipts={invoice.receipts}
            />
          </span>
        </div>
        {invoice.status !== PAID_OUTSIDE && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Código de transacción</span>
              <span style={styles.cardData}>
                {invoice?.transaction_hash?.toUpperCase()}
              </span>
            </div>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>
                {transaction_paid_at ? "Fecha de pago" : "Fecha de creación"}
              </span>
              <span style={styles.cardData}>
                {transaction_paid_at
                  ? applyFormatDate(transaction_paid_at)
                  : transaction_created_at
                  ? applyFormatDate(transaction_created_at)
                  : null}
              </span>
            </div>
          </>
        )}
        {invoice.source_of_funds_id && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Origen de fondos</span>
              <span style={styles.cardData}>
                {getSourceOfFundsLabel(invoice.source)}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

import { Button } from "@mui/material";
import { CalendarIcon } from "assets";
import { styles } from "../styles";

export const DateButton = ({ action, text, open, resetDate }) => {
  return (
    <Button
      id="basic-button"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      {...(action ? { onClick: action } : {})}
      style={styles.dateButton}
    >
      <CalendarIcon />
      <div style={styles.dateButtonTextContainer}>
        <span
          style={{
            paddingLeft: 6,
            alignSelf: "center",
            color: "#667085",
            textTransform: "none",
          }}
        >
          {text}
        </span>
      </div>
    </Button>
  );
};

import React from "react";

export const ListCheckIcon = ({
  stroke = "#12B76A",
  strokeWidth = "1.33333",
}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13.3334 4L6.00008 11.3333L2.66675 8"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

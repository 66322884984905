import React, { useContext, useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Stack, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { styles } from "./styles";
import { Spinner } from "react-bootstrap";
import { ChevronLeftIcon } from "../../assets";
import { GetTransactionV2, RetryPayment } from "services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SupplierDetails } from "commons/modals/SupplierDetails";
import {
  BeneficiarySection,
  CardCommissionSummarySection,
  DocumentsSection,
  EgressSection,
  GeneralInformationSection,
  MoneyJourneySection,
  OthersCommissionSummarySection,
  PaymentErrorSection,
  TotalAmountSection,
  TransactionsDetailsIntro,
} from "./components";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { CustomerDetails, EmployeeDetails, ReceiverDetails } from "commons";
import { CollectionsContext, TransactionsContext, UserContext } from "contexts";
import { transactionEventType } from "constants";
import { getFromLocalStorage, saveInLocalStorage } from "utils";
import { ModalErrorSiigo } from "./components/ModalErrorSiigo";
import { SubscriptionModal } from "commons/modals";

export const TransactionDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(UserContext);
  const { mainReceiver, getMainReceiver } = useContext(CollectionsContext);
  const { subscription } = useContext(TransactionsContext);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [trxRows, setTrxRows] = useState([]);
  const [sourceOfFunds, setSourceOfFunds] = useState({});
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [isIntroEnabled, setIsIntroEnabled] = useState(false);
  const [showTutorial, setShowTutorial] = useState(
    getFromLocalStorage("showTransactionsDetailsTutorial") || "true"
  );
  const [openModalSiigoError, setOpenModalSiigoError] = useState({
    open: false,
    type: "",
  });

  const errorParams = location?.state?.error;

  const isTutorialVisible = showTutorial !== "false";

  const { canDisplayAssistedEgress } = useContext(UserContext);
  const hasEgress =
    canDisplayAssistedEgress && transaction?.accounting_receipt_numbers;

  const goBack = () => {
    navigate("/transactions");
  };

  useEffect(() => {
    setIsLoading(true);
    GetTransactionV2({
      id: id,
      ...(location.state?.isPayanaCommission && {
        type: "payana_commission",
      }),
      ...(location.state?.beneficiaryType && {
        beneficiaryType: location.state.beneficiaryType,
      }),
    })
      .then((res) => {
        setTransaction(res.transaction);
        setTrxRows(res.transaction.documents);
        setSourceOfFunds(res.transaction.source_of_funds);
        res.transaction.operation_type === "collection" && getMainReceiver();
      })
      .finally(() => {
        setIsLoading(false);
        setIsIntroEnabled(
          !location.state?.isPayanaCommission && isTutorialVisible
        );
      });
  }, []);

  const handleRefundPayment = async () => {
    try {
      amplitude.logEvent("START_REFUND_FLOW", {
        user_id: currentUser.id,
        transaction_id: transaction.transaction_id,
        receipt_id: transaction.id,
        module: transactionEventType[transaction.operation_type],
        error_message: transaction.receipt_histories.at(
          transaction.receipt_histories.length - 1
        )?.errorMessage,
      });
      const data = {
        amount: transaction?.total_amount,
        supplierName: transaction?.beneficiary_name,
        hash: transaction?.batch_number,
        type: transaction?.operation_type,
      };
      window.Intercom("showNewMessage", formatMessageRefundIntercom(data));
    } catch (error) {
      toast.error("No pudimos armar el mensaje a intercom", toastOptions);
    }
  };

  const formatMessageRefundIntercom = ({
    hash,
    supplierName,
    amount,
    type = "invoice",
  }) => {
    const payTo = type === "payroll" ? "empleado" : "proveedor";

    return `Hola Payana! Solicito la devolución de los $${amount} correspondientes al pago al ${payTo} ${supplierName} de la transacción ${hash}. Gracias!`;
  };

  const retryPayment = () => {
    setIsLoading(true);
    RetryPayment({ receiptId: id }).then(() => {
      amplitude.logEvent("END_REPROCESS_FLOW", {
        user_id: currentUser.id,
        transaction_id: transaction.transaction_id,
        receipt_id: transaction.id,
        module: transactionEventType[transaction.operation_type],
      });
      window.location.reload(true);
    });
  };

  const handleOnCloseModal = () => {
    setOpenModalSiigoError({ open: false, type: "" });
    const currentPath = location.pathname;
    navigate(currentPath, { replace: true });
  };

  useEffect(() => {
    if (errorParams) {
      const documentNumber = location?.state?.data?.documentNumber;
      setOpenModalSiigoError({
        open: true,
        type: errorParams,
        documentNumber: documentNumber ? documentNumber : "",
      });
    }
  }, [errorParams]);

  return (
    <>
      {subscription?.showModalSubscription && (
        <SubscriptionModal
          subscription={subscription}
          openWompiModal={() => {}}
        />
      )}
      <ModalErrorSiigo
        state={openModalSiigoError}
        onClose={handleOnCloseModal}
      />
      <TransactionsDetailsIntro
        enabled={isIntroEnabled}
        onComplete={() => {
          saveInLocalStorage("showTransactionsDetailsTutorial", "false");
          setShowTutorial("false");
        }}
      />
      <div className="tx-detail-layout-container">
        <Stack sx={{ height: "100%" }} justifyContent="flex-start">
          <Stack
            direction={"column"}
            justifyContent="flex-start"
            p={2}
            sx={{ paddingLeft: isMobile ? "24px" : "16px" }}
          >
            <div style={styles.backContainer} onClick={goBack}>
              <ChevronLeftIcon color="#5925DC" width="20px" height="20px" />
              <Typography style={styles.backText}>Volver</Typography>
            </div>
          </Stack>
          <Typography sx={styles.modalTitle}>
            Detalle de la transacción
          </Typography>
          {transaction.operation_type === "collection" &&
            transaction.beneficiary_type === "customer" && (
              <Typography sx={styles.modalSubtitle}>
                El monto total es el correspondiente a la suma de documentos
                abonados.
              </Typography>
            )}
          <Stack
            my={2}
            spacing={2}
            sx={{
              marginTop: "0px",
              height: "90%",
              paddingLeft: isMobile ? "8px" : "0px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {isLoading ? (
              <div style={styles.spinnerContainer}>
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <div style={styles.txDetailContainer}>
                <div style={styles.txColumn}>
                  <TotalAmountSection
                    amount={parseInt(
                      transaction.operation_type === "collection" &&
                        transaction.beneficiary_type === "customer"
                        ? transaction?.batch_total_amount
                        : transaction?.total_amount
                    )}
                  />
                  <GeneralInformationSection
                    transaction={transaction}
                    sourceOfFunds={sourceOfFunds}
                    beneficiaryType={location.state?.beneficiaryType}
                  />
                  {transaction.operation_type === "payana_commission" && (
                    <>
                      {transaction.commission_type === "card" && (
                        <CardCommissionSummarySection
                          transaction={transaction}
                        />
                      )}
                      {transaction.commission_type === "others" && (
                        <OthersCommissionSummarySection
                          transaction={transaction}
                        />
                      )}
                    </>
                  )}
                  {transaction.operation_type !== "payana_commission" && (
                    <BeneficiarySection
                      transaction={transaction}
                      beneficiaryType={location.state?.beneficiaryType}
                    />
                  )}
                  {transaction.operation_type !== "payroll" &&
                    transaction.operation_type !== "payana_commission" && (
                      <DocumentsSection
                        transaction={transaction}
                        trxRows={trxRows}
                        hasEgress={hasEgress}
                      />
                    )}
                </div>
                {transaction.operation_type !== "payana_commission" && (
                  <div style={styles.txColumn}>
                    {canDisplayAssistedEgress &&
                      !transaction?.accounting_receipt_numbers &&
                      transaction?.operation_type === "invoice" && (
                        <EgressSection
                          isDisabled={transaction?.status !== "paid"}
                          transaction={transaction}
                        />
                      )}
                    {transaction.status === "rejected" && (
                      <PaymentErrorSection
                        transaction={transaction}
                        setDrawerIsOpen={setDrawerIsOpen}
                        handleRefundPayment={handleRefundPayment}
                      />
                    )}
                    <MoneyJourneySection transaction={transaction} />
                  </div>
                )}
              </div>
            )}
          </Stack>
        </Stack>
      </div>
      {transaction.operation_type === "invoice" && (
        <SupplierDetails
          isOpen={drawerIsOpen}
          id={Number.isFinite(drawerIsOpen) ? drawerIsOpen : null}
          handleClose={() => {
            setDrawerIsOpen(false);
          }}
          handleSuccess={(supplier, service) => {
            retryPayment();
          }}
          isOnlySupplier={true}
          isRetryFlow={true}
          errorMessage={
            transaction.receipt_histories.at(
              transaction.receipt_histories.length - 1
            )?.errorMessage
          }
        />
      )}
      {transaction.operation_type === "payroll" && (
        <EmployeeDetails
          id={Number.isFinite(drawerIsOpen) ? drawerIsOpen : null}
          isOpen={drawerIsOpen}
          handleClose={() => {
            setDrawerIsOpen(false);
          }}
          handleSuccess={(employee) => {
            retryPayment();
          }}
          isRetryFlow={true}
          errorMessage={
            transaction.receipt_histories.at(
              transaction.receipt_histories.length - 1
            )?.errorMessage
          }
        />
      )}
      {transaction.operation_type === "collection" &&
        location.state?.beneficiaryType === "customer" && (
          <CustomerDetails
            isOpen={drawerIsOpen}
            id={Number.isFinite(drawerIsOpen) ? drawerIsOpen : null}
            handleClose={() => {
              setDrawerIsOpen(false);
            }}
            handleSuccess={(customer) => {
              retryPayment();
            }}
            isRetryFlow={true}
            errorMessage={
              transaction.receipt_histories.at(
                transaction.receipt_histories.length - 1
              )?.errorMessage
            }
          />
        )}
      {transaction.operation_type === "collection" &&
        location.state?.beneficiaryType === "receiver" && (
          <ReceiverDetails
            isOpen={!!drawerIsOpen}
            id={Number.isFinite(drawerIsOpen) ? drawerIsOpen : null}
            handleClose={() => {
              setDrawerIsOpen(false);
            }}
            handleSuccess={(receiver) => {
              retryPayment();
            }}
            isRetryFlow={true}
            errorMessage={
              transaction.receipt_histories.at(
                transaction.receipt_histories.length - 1
              )?.errorMessage
            }
            mainReceiver={mainReceiver}
          />
        )}
    </>
  );
};

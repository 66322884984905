import {
  Autocomplete,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { WithoutBorderButton } from "../../../buttons";
import { styles } from "../../../Table/styles";
import { useContext, useEffect, useState } from "react";
import { SearchReceivers } from "../../../../services";
import {
  getAccountTypeLabel,
  minimumLengthForSearch,
} from "../../../../constants";
import { objectIsEmpty } from "../../../../utils/objectUtils";
import { CollectionsContext } from "../../../../contexts";
import { NumericFormat } from "react-number-format";
import { CustomReceiverAmountField } from "./CustomReceiverAmountField";
import { CustomReceiverPercentageField } from "./CustomReceiverPercentageField";

export const Receiver = ({
  receiverNumber,
  amount,
  setAmount,
  option,
  setReceiverDrawerIsOpen,
  selectedReceiver,
  setSelectedReceiver,
  isExceeded,
  totalAmount = 0,
  deleteSecondReceiver = () => {},
}) => {
  const defaultSearchOptions = selectedReceiver ? [selectedReceiver] : [];
  const [searchOptions, setSearchOptions] = useState(defaultSearchOptions);
  const { getBanks } = useContext(CollectionsContext);

  useEffect(() => {
    getBanks();
  }, []);

  const getSearchOptions = (query) => {
    SearchReceivers(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const handleOnChangeAutocomplete = (event, newValue) => {
    if (newValue?.id) {
      setSelectedReceiver(newValue);
    } else {
      setSelectedReceiver({});
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "-8px",
        }}
      >
        <Typography sx={styles.invoiceCardTitleRevert}>
          Destinatario {receiverNumber}
        </Typography>
        <WithoutBorderButton
          text={receiverNumber === 2 ? "Eliminar" : "Crear nuevo"}
          action={() => {
            if (receiverNumber === 2) {
              deleteSecondReceiver();
            } else {
              setReceiverDrawerIsOpen(true);
            }
          }}
          width={receiverNumber === 2 ? "65px" : "86px"}
          style={{
            color: receiverNumber === 2 ? "#F04438" : "#5925DC",
          }}
        />
      </div>
      <Autocomplete
        options={searchOptions}
        freeSolo
        getOptionLabel={(option) => option?.name || option?.receiverName || ""}
        value={selectedReceiver || null}
        onChange={handleOnChangeAutocomplete}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
            }}
            placeholder={"Ingresa el nombre"}
          />
        )}
        size="small"
        onInputChange={(event) => {
          if (event?.target?.value?.length >= minimumLengthForSearch) {
            getSearchOptions(event?.target?.value);
          }
        }}
      />
      {!objectIsEmpty(selectedReceiver) && (
        <div style={styles.supplierDataCardContainer}>
          <div style={styles.supplierDataCard}>
            <p
              style={styles.invoiceSupplierCardTitle}
              className="card-subtitle mb-2"
            >
              Entidad bancaria
            </p>
            <p
              style={styles.invoiceSupplierCardText}
              className="card-subtitle mb-2"
            >
              {selectedReceiver?.bank_name || selectedReceiver?.bankName}
            </p>
          </div>
          <Divider sx={{ marginBottom: "8px" }} />
          <div style={styles.supplierDataCard}>
            <p
              style={styles.invoiceSupplierCardTitle}
              className="card-subtitle mb-2"
            >
              {selectedReceiver?.account_type ||
              selectedReceiver?.receiverAccountType
                ? getAccountTypeLabel(
                    selectedReceiver?.account_type ||
                      selectedReceiver?.receiverAccountType
                  )
                : "Número de celular"}
            </p>
            <p
              style={styles.invoiceSupplierCardText}
              className="card-subtitle mb-2"
            >
              {selectedReceiver.account_number ||
                selectedReceiver?.receiversAccountNumber}
            </p>
          </div>
          <Divider sx={{ marginBottom: "8px" }} />
          <div style={styles.supplierDataCard}>
            <p
              style={styles.invoiceSupplierCardTitle}
              className="card-subtitle mb-2"
            >
              {selectedReceiver?.document_type
                ? selectedReceiver.document_type
                : "NIT"}
            </p>
            <p
              style={styles.invoiceSupplierCardText}
              className="card-subtitle mb-2"
            >
              {selectedReceiver.document_number ||
                selectedReceiver?.receiversDocumentNumber}
            </p>
          </div>
        </div>
      )}
      <Stack spacing={1} style={{ marginTop: "24px", marginBottom: "20px" }}>
        <Typography sx={styles.invoiceCardTitleRevert}>Monto</Typography>
        <div style={styles.rowSpaceAround}>
          {option === "amount" ? (
            <NumericFormat
              id="amount"
              name="amount"
              className={
                isExceeded ? "receiverAmountExceeded" : "receiverAmount"
              }
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={0}
              allowNegative={false}
              isNumericString={true}
              value={amount}
              customInput={CustomReceiverAmountField}
              onValueChange={(values) => {
                setAmount(values.floatValue);
              }}
            />
          ) : (
            <NumericFormat
              id="amount"
              name="amount"
              className={
                isExceeded ? "receiverAmountExceeded" : "receiverAmount"
              }
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={0}
              allowNegative={false}
              isNumericString={true}
              value={amount}
              customInput={CustomReceiverPercentageField}
              isAllowed={(values) => {
                const { formattedValue } = values;
                return formattedValue === "" || formattedValue.length < 4;
              }}
              onValueChange={(values) => {
                setAmount(values.floatValue);
              }}
            />
          )}
        </div>
        {option === "amount" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              ...styles.invoiceCardTitle,
              color: "#667085",
            }}
          >
            <p>
              El máximo total que tienes disponible para distribuir es $
              <NumericFormat
                value={totalAmount}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
              />
            </p>
          </div>
        ) : (
          <Typography sx={{ ...styles.invoiceCardTitle, color: "#667085" }}>
            Tienes hasta el 100% para distribuir entre tus destinatarios
          </Typography>
        )}
      </Stack>
    </>
  );
};

import { PrimaryButton, SecondaryButton } from "../../../../buttons";
import { ModalWarningIcon } from "assets";

export const StepSeven = ({ cancel, errorMessage }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <ModalWarningIcon />
      </div>
      <p className={"title"}>{errorMessage.title}</p>
      <p className={"description"}>{errorMessage.description}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <SecondaryButton text={"Cancelar"} action={cancel} width="100%" />
        <PrimaryButton
          text={errorMessage.button}
          action={errorMessage.action}
          style={{ marginLeft: "16px" }}
          width="100%"
        />
      </div>
    </>
  );
};

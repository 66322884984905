import React from "react";

export const AlertIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="16" fill="#EBE9FE" />
    <path
      d="M16 13.3333V16M16 18.6667H16.0067M10 13.2943V18.7057C10 18.9341 10 19.0484 10.0337 19.1502C10.0634 19.2403 10.1121 19.3231 10.1764 19.3929C10.2491 19.4718 10.349 19.5272 10.5486 19.6382L15.482 22.3789C15.6711 22.4839 15.7656 22.5365 15.8657 22.557C15.9543 22.5753 16.0457 22.5753 16.1343 22.557C16.2344 22.5365 16.3289 22.4839 16.518 22.3789L21.4514 19.6382C21.651 19.5272 21.7509 19.4718 21.8236 19.3929C21.8879 19.3231 21.9366 19.2403 21.9663 19.1502C22 19.0484 22 18.9341 22 18.7057V13.2943C22 13.0659 22 12.9517 21.9663 12.8498C21.9366 12.7597 21.8879 12.677 21.8236 12.6072C21.7509 12.5283 21.651 12.4728 21.4514 12.3619L16.518 9.62114C16.3289 9.5161 16.2344 9.46358 16.1343 9.44299C16.0457 9.42477 15.9543 9.42477 15.8657 9.44299C15.7656 9.46358 15.6711 9.5161 15.482 9.62114L10.5486 12.3619C10.349 12.4728 10.2491 12.5283 10.1764 12.6072C10.1121 12.677 10.0634 12.7597 10.0337 12.8498C10 12.9517 10 13.0659 10 13.2943Z"
      stroke="#5925DC"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

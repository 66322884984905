import { InputAdornment, TextField } from "@mui/material";

export const CustomTextField = (props = {}) => {
  return (
    <TextField
      size="small"
      placeholder=""
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      sx={{
        "& fieldset": {
          borderRadius: "8px",
        },
      }}
      {...props}
    />
  );
};

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const PayrollScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/payroll/salaries");
  });
  return null;
};

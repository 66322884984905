import React from "react";
import { WarningIcon } from "../../../../assets";
import { Box, Typography } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../buttons";

export function AdviceAlegra({ onClose, handleNextStep }) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"20px"}
      alignItems={"center"}
    >
      <WarningIcon width={56} height={56} />
      <Typography
        style={{
          fontSize: 18,
          fontWeight: 500,
          lineHeight: "28px",
        }}
      >
        Recuerda que tienes que tener tus proveedores cargados en Alegra
      </Typography>
      <Typography
        style={{
          fontSize: 16,
          fontWeight: 500,
          lineHeight: "24px",
          color: "#667085",
        }}
      >
        Para importar correctamente todas las facturas, es importante que
        primero crees todos tus proveedores en Alegra.
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"12px"}
        width={"100%"}
      >
        <PrimaryButton
          text="Ya tengo la información cargada"
          width="100%"
          action={handleNextStep}
        />
        <SecondaryButton
          text="Importar más tarde"
          width="100%"
          action={onClose}
        />
      </Box>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { getAccountingAccounts } from "services";
import { SearchIcon } from "assets";

function AccountingAccountSelect({ onAccountChange, value }) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (inputValue.length > 1) {
      setLoading(true);
      getAccountingAccounts(inputValue)
        .then((res) => {
          setOptions(res);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [inputValue]);

  return (
    <Autocomplete
      disablePortal
      freeSolo
      sx={{ width: "100%" }}
      options={options}
      getOptionLabel={(option) => `${option.Code} - ${option.Name}`}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        onAccountChange(newValue ? newValue.Code : ""); // Notificamos al componente padre sobre la opción seleccionada
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 14, backgroundColor: "#FFFFFF", width: "100%" },
            placeholder: "Selecciona del listado",
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

export default AccountingAccountSelect;

import { requestContainer } from "../requestContainer";

export async function GetInvoicesInternal({
  pageIndex,
  status = "all",
  providerId = "all",
  serviceId = "all",
  perPage = 100,
  sorting = "",
  tags = "",
  causation = "",
}) {
  const tagsIds = String(tags.map((tag) => tag.id));
  return requestContainer({
    method: "get",
    url: `/invoices?page=${pageIndex}&status=${status}&provider_id=${providerId}&service_id=${serviceId}&per_page=${perPage}&sort=${sorting}&tag_ids=${tagsIds}&causation=${causation}`,
  });
}

export async function GetTotalInvoices({
  status,
  providerId,
  serviceId = "all",
  tags = [],
  causation = "",
}) {
  const tagsIds = String(tags.map((tag) => tag.id));
  return requestContainer({
    method: "get",
    url: `/invoices/total?status=${status}&provider_id=${providerId}&service_id=${serviceId}&tag_ids=${tagsIds}&causation=${causation}`,
  });
}

export async function GetExpirationValues() {
  return requestContainer({
    method: "get",
    url: "/invoices/to-pay",
  });
}

export async function GetInvoice({ id }) {
  return requestContainer({
    method: "get",
    url: `/invoices/${id}`,
  });
}

export async function GetInvoiceAmount({ id }) {
  return requestContainer({
    method: "get",
    url: `/invoices/${id}`,
  });
}

export async function MarkInvoicesAsPaid({ ids }) {
  const data = { invoices: ids };
  return requestContainer({
    method: "put",
    url: `/invoices/paid`,
    data: data,
  });
}

export async function markInvoicesAsPending({ ids }) {
  const data = { invoices: ids };
  return requestContainer({
    method: "put",
    url: `/invoices/pending`,
    data: data,
  });
}

export async function DeleteInvoice({ id }) {
  return requestContainer({
    method: "delete",
    url: `/invoices/${id}`,
  });
}

export async function DeleteInvoices(ids) {
  const data = { invoices: ids };
  return requestContainer({
    method: "delete",
    url: `/invoices`,
    data: data,
  });
}

export function UploadInvoiceFilePDF(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/qr",
    data: formData,
  });
}

export function UploadInvoiceFileCSV(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/invoices",
    data: formData,
  });
}

export function UploadInvoiceFileCSVV2(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/csv/invoices",
    data: formData,
  });
}

export function UploadServiceCSVFile(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/csv/services",
    data: formData,
  });
}

export async function ModifyAmountTotal({ id, amount }) {
  const data = { id: id, amount: amount };
  return requestContainer({
    method: "put",
    url: `/invoices/update-amount`,
    data: data,
  });
}

export async function InvoicesApproval(ids) {
  const data = { ids: ids, approved: true };
  return requestContainer({
    method: "put",
    url: `/invoices/update-approval`,
    data: data,
  });
}

export async function MakePartialPayment({ id, amount }) {
  const data = { id: id, amount: amount };
  return requestContainer({
    method: "post",
    url: `/invoices/partial-payment`,
    data: data,
  });
}

export async function ChargeWithholdings({ id, amount, retention_type }) {
  const data = { id: id, amount: amount, retention_type: retention_type };
  return requestContainer({
    method: "put",
    url: `/invoices/update-withholding-amount`,
    data: data,
  });
}

export async function UpdateInvoice({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/invoices/${id}`,
    data: { ...formValues },
  });
}

export async function CreateInvoice({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/invoices`,
    data: { ...formValues },
  });
}

export function UploadInvoicesFile(fileToUpload) {
  const formdata = new FormData();
  formdata.append("file", fileToUpload, fileToUpload.name);
  return requestContainer({
    method: "post",
    url: "/upload/single/invoices",
    data: formdata,
  });
}

export function UploadInvoicesFileForCompany(fileToUpload, companyId) {
  const formdata = new FormData();
  formdata.append("file", fileToUpload, fileToUpload.name);
  return requestContainer({
    method: "post",
    url: `/backoffice/upload/single/invoices?company_id=${companyId}`,
    data: formdata,
  });
}

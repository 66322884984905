import React from "react";

export const CrownIcon = ({ fill = "#6938EF", size = "small" }) => {
  const renderBySize = {
    small: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
      >
        <path
          d="M17.931 3.9487C17.9339 4.00006 17.9301 4.05232 17.9172 4.10423L16.6282 11.2455C16.5702 11.4775 16.3622 11.6407 16.1232 11.6419L9.21521 11.6768H9.21256H2.30454C2.06422 11.6768 1.85482 11.5131 1.79677 11.2799L0.783111 4.12119C0.769853 4.06788 0.766016 4.01415 0.769365 3.96147C0.323976 3.82115 0 3.40437 0 2.9132C0 2.30719 0.492977 1.81421 1.09899 1.81421C1.70501 1.81421 2.19799 2.30719 2.19799 2.9132C2.19799 3.25448 2.04162 3.55983 1.79677 3.76156L3.23823 5.2139C3.60253 5.581 4.10807 5.79145 4.62526 5.79145C5.23679 5.79145 5.81985 5.50104 6.18702 5.01448L8.55596 1.87561C8.35695 1.67675 8.23373 1.40197 8.23373 1.09899C8.23373 0.492977 8.7267 0 9.33272 0C9.93873 0 10.4317 0.492977 10.4317 1.09899C10.4317 1.3929 10.315 1.65965 10.1264 1.85705L10.1285 1.8595L12.4802 5.00701C12.8473 5.49824 13.4324 5.79152 14.0457 5.79152C14.5676 5.79152 15.0584 5.58826 15.4275 5.21913L16.878 3.7686C16.628 3.56695 16.4675 3.25867 16.4675 2.9132C16.4675 2.30719 16.9604 1.81421 17.5664 1.81421C18.1725 1.81421 18.6654 2.30719 18.6654 2.9132C18.6654 3.39125 18.3581 3.79784 17.931 3.9487ZM16.6535 13.7806C16.6535 13.4916 16.4192 13.2573 16.1302 13.2573H2.59341C2.3044 13.2573 2.07008 13.4916 2.07008 13.7806V15.0366C2.07008 15.3256 2.3044 15.5599 2.59341 15.5599H16.1302C16.4192 15.5599 16.6535 15.3256 16.6535 15.0366V13.7806Z"
          fill={fill}
        />
      </svg>
    ),
    medium: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.7409 6.76965C30.7458 6.85769 30.7394 6.94729 30.7173 7.03629L28.5074 19.2793C28.4079 19.6771 28.0514 19.9568 27.6415 19.9589L15.7986 20.0187H15.794H3.95089C3.5389 20.0187 3.1799 19.7381 3.08037 19.3383L1.34256 7.06536C1.31984 6.97397 1.31326 6.88186 1.319 6.79154C0.555424 6.55097 0 5.83644 0 4.99439C0 3.95544 0.845159 3.11028 1.88411 3.11028C2.92307 3.11028 3.76822 3.95544 3.76822 4.99439C3.76822 5.57948 3.50014 6.10297 3.08037 6.44881L5.55161 8.93871C6.17618 9.56806 7.04287 9.92885 7.92954 9.92885C8.97794 9.92885 9.97754 9.43097 10.607 8.59682L14.6683 3.21555C14.3271 2.87462 14.1159 2.40353 14.1159 1.88411C14.1159 0.845159 14.961 0 16 0C17.039 0 17.8841 0.845159 17.8841 1.88411C17.8841 2.38798 17.6841 2.84531 17.3607 3.18373L17.3642 3.18792L21.3961 8.58402C22.0253 9.42618 23.0285 9.92897 24.0799 9.92897C24.9747 9.92897 25.8162 9.5805 26.4489 8.94768L28.9357 6.46089C28.507 6.11517 28.2318 5.58666 28.2318 4.99439C28.2318 3.95544 29.0769 3.11028 30.1159 3.11028C31.1548 3.11028 32 3.95544 32 4.99439C32 5.81395 31.473 6.51101 30.7409 6.76965ZM28.5508 23.6254C28.5508 23.1299 28.1491 22.7282 27.6536 22.7282H4.44615C3.95065 22.7282 3.54895 23.1299 3.54895 23.6254V25.7787C3.54895 26.2742 3.95065 26.6759 4.44615 26.6759H27.6536C28.1491 26.6759 28.5508 26.2742 28.5508 25.7787V23.6254Z"
          fill={fill}
        />
      </svg>
    ),
  };

  return renderBySize[size];
};

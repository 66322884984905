import { createContext, useState } from "react";
import { GetBOReceiptsServices, UpdateReceiptStatus } from "../../services";
import { toast } from "react-toastify";
import { toastOptions } from "../../constants";

export const BackofficeReceiptServicesContext = createContext();

export function BackofficeReceiptServicesProvider({ children }) {
  const [BOreceiptsService, setBOreceiptsService] = useState([]);
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);
  const [statusSelected, setStatusSelected] = useState("all");
  const [serviceIdSelected, setServiceIdSelected] = useState("all");
  const [companyIdSelected, setCompanyIdSelected] = useState("all");
  const [hashSelected, setHashSelected] = useState("");
  const [createRequest, setCreateRequest] = useState(false);

  const getBOreceiptsService = async ({
    pageIndex = 1,
    status,
    serviceId,
    companyId,
    transactionHash,
  }) => {
    setCreateRequest(true);
    const gottenReceipts = await GetBOReceiptsServices({
      pageIndex: pageIndex,
      status: status || statusSelected,
      serviceId: serviceId || serviceIdSelected,
      companyId: companyId || companyIdSelected,
      transactionHash: transactionHash || hashSelected,
    });
    if (gottenReceipts) {
      setBOreceiptsService(gottenReceipts);
    }
    setCreateRequest(false);
  };

  const changeBOReceiptServiceStatus = async (id, status, pageIndex = 1) => {
    const response = await UpdateReceiptStatus(id, status);
    if (response) {
      toast.success("¡El soporte se actualizó con éxito!", toastOptions);
      getBOreceiptsService({
        pageIndex: pageIndex,
        status: statusSelected,
        serviceId: serviceIdSelected,
        companyId: companyIdSelected,
        transactionHash: hashSelected,
      });
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  return (
    <BackofficeReceiptServicesContext.Provider
      value={{
        createRequest,
        BOreceiptsService,
        getBOreceiptsService,
        statusModalIsOpen,
        setStatusModalIsOpen,
        changeBOReceiptServiceStatus,
        statusSelected,
        setStatusSelected,
        serviceIdSelected,
        setServiceIdSelected,
        companyIdSelected,
        setCompanyIdSelected,
        hashSelected,
        setHashSelected,
      }}
    >
      {children}
    </BackofficeReceiptServicesContext.Provider>
  );
}

import { useContext, useEffect, useState } from "react";
import {
  Box,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { InterruptedServiceContext, UserContext } from "../../../contexts";
import { styles } from "../styles";
import { PrimaryButton, SecondaryButton } from "../../../commons/buttons";
import { useForm, useWatch } from "react-hook-form";
import { ValidationMessage } from "../../../commons";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import { updateInterrumptedServiceCommunication } from "../../../services/api/communications";

const CommunicationsTab = () => {
  const { communication, preview } = useContext(InterruptedServiceContext);
  const { currentUserIsBackofficer } = useContext(UserContext);
  const [isChecked, setIsChecked] = useState(communication.is_active);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState, control, setValue } = useForm({
    defaultValues: {
      title: "",
      description: "",
      is_active: false,
    },
  });
  const formValues = useWatch({ control });
  useEffect(() => {
    if (communication.title) {
      setValue("title", communication.title);
    }
    if (communication.description) {
      setValue("description", communication.description);
    }
  }, [communication]);

  const save = async ({ title, description }) => {
    try {
      setLoading(true);
      await updateInterrumptedServiceCommunication(
        title,
        description,
        isChecked
      );
      toast.success("¡La notificación se actualizó con éxito!", toastOptions);
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    } finally {
      setLoading(false);
    }
  };
  if (!currentUserIsBackofficer) {
    return null;
  }
  return (
    <form onSubmit={handleSubmit(save)} name="service-interrumption">
      <Box sx={{ width: "60%", paddingBottom: "20px" }} px={8}>
        <Stack direction="row" alignItems="center" spacing={10} my={4}>
          <Typography sx={styles.inputLabel} noWrap>
            Título
          </Typography>
          <div style={{ width: "100%" }}>
            <TextField
              sx={{
                ...styles.input,
                width: "100%",
              }}
              size="small"
              variant="outlined"
              {...register("title", { required: true })}
            />
            <ValidationMessage errors={formState.errors} field="title" />
          </div>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={10} my={4}>
          <Typography sx={styles.inputLabel} noWrap>
            Descripción
          </Typography>
          <div style={{ width: "100%" }}>
            <TextField
              rows={4}
              multiline
              sx={{
                ...styles.input,
                width: "100%",
              }}
              size="small"
              variant="outlined"
              {...register("description", { required: true })}
            />
            <ValidationMessage errors={formState.errors} field="description" />
          </div>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={10} my={4}>
          <Typography sx={styles.inputLabel} noWrap>
            Visible
          </Typography>
          <Select
            value={isChecked}
            onChange={(e) => setIsChecked(e.target.value)}
            sx={{ width: "100%" }}
            size="small"
            defaultValue={isChecked}
          >
            {[
              { label: "Sí", value: true },
              { label: "No", value: false },
            ].map((option) => {
              return (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end" my={4}>
          <SecondaryButton
            loading={loading}
            title="Ver preview de la notificación"
            text="Preview"
            action={() => preview(formValues)}
          />
          <PrimaryButton loading={loading} text="Guardar" type="submit" />
        </Stack>
      </Box>
      <Divider />
    </form>
  );
};

export default CommunicationsTab;

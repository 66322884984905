import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Dialog } from "@mui/material";
import { CloseButton } from "react-bootstrap";
import { CollectionsContext, UserContext } from "../../../contexts";
import { SKELETON_RECEIVER } from "./constants";
import {
  handleFormattedReceiverResponse,
  handleFormattedReceiverForm,
} from "./utils";
import { styles } from "./styles";
import ReceiverForm from "./forms/ReceiverForm";
import { objectIsEmpty } from "../../../utils/objectUtils";
import { toast } from "react-toastify";
import { toastOptions } from "constants";

export const ReceiverDetails = ({
  isOpen,
  handleClose,
  id,
  needRedirectionCreate = false,
  needRedirectionEdit = false,
  handleSuccess = () => {},
  pageIndex,
  providerId,
  mainReceiver,
  disableFields = false,
  isRetryFlow = false,
  errorMessage = "",
}) => {
  const { updateReceiver, createReceiver, getBanks, getReceiver } =
    useContext(CollectionsContext);
  const { currentUser, currentCompany } = useContext(UserContext);

  const location = useLocation();
  const [disclaimerIsOpen, setDisclaimerIsOpen] = useState(
    objectIsEmpty(mainReceiver)
  );
  const [detailedReceiver, setDetailedReceiver] = useState(SKELETON_RECEIVER);

  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      getBanks();
      if (id) {
        getReceiver(id).then((res) =>
          setDetailedReceiver(handleFormattedReceiverResponse(res))
        );
      }
      if (objectIsEmpty(mainReceiver) || id) {
        setDisclaimerIsOpen(true);
        setDetailedReceiver({
          ...SKELETON_RECEIVER,
          name: currentCompany?.name,
          documentType: "NIT",
          documentNumber: currentCompany?.identification_number,
          email: currentUser?.email,
          phone_number: currentUser?.phone,
        });
      }
    }
  }, [isOpen, id]);

  const updateReceiverHandler = async (id, formValues) => {
    await updateReceiver(id, formValues, pageIndex, providerId);
    handleSuccess({
      ...formValues,
      has_identification_number_placeholder: false,
      id,
    });
    notifyReceiverUpdated();
  };

  const createReceiverHandler = async (formValuesToSend, bank_name = "") => {
    const createdReceiver = objectIsEmpty(mainReceiver)
      ? await createReceiver({ ...formValuesToSend, type: "main" })
      : await createReceiver(formValuesToSend);
    const receiver = {
      id: createdReceiver.customer_id,
      bank_name,
      ...formValuesToSend,
    };
    handleSuccess(receiver);
    notifyReceiverUpdated();
  };

  const updateOrCreateReceiverHandler = (formValues) => {
    const formValuesToSend = handleFormattedReceiverForm(formValues);
    if (id) {
      updateReceiverHandler(id, formValuesToSend);
    } else {
      createReceiverHandler(formValuesToSend, formValues?.bank_name);
    }
    closeAndReset(formValuesToSend);
  };

  const handleRedirect = (name) => {
    if (location?.state?.redirectUrl) {
      return navigate(location?.state?.redirectUrl, {
        state: {
          receiverName: name,
        },
        replace: true,
      });
    }
    if (needRedirectionCreate) {
      navigate("/collections/index", {
        state: {
          openCreateObligationModal: true,
          supplierName: name,
        },
        replace: true,
      });
    }
    if (needRedirectionEdit) {
      navigate("/collections/index", {
        replace: true,
      });
    }
  };

  const cleanForm = () => {
    setDetailedReceiver(SKELETON_RECEIVER);
    setDisclaimerIsOpen(false);
  };

  const closeAndReset = (formValuesToSend) => {
    handleClose();
    setDetailedReceiver(SKELETON_RECEIVER);
    handleRedirect(formValuesToSend?.name);
  };

  const notifyReceiverUpdated = () => {
    toast.success(
      objectIsEmpty(mainReceiver)
        ? "¡Actualizaste tus datos con éxito!"
        : id
        ? "¡El destinatario se editó con éxito!"
        : "¡El destinatario se creó con éxito!",
      toastOptions
    );
  };

  return (
    <Dialog fullScreen open={isOpen}>
      <Stack direction={"row"} justifyContent="flex-end" sx={styles.container}>
        <CloseButton style={{ boxShadow: "none" }} onClick={closeAndReset} />
      </Stack>
      <div style={styles.content}>
        <Stack sx={styles.formContainer}>
          <ReceiverForm
            detailedReceiver={detailedReceiver}
            onSubmit={updateOrCreateReceiverHandler}
            onCancel={closeAndReset}
            mainReceiver={mainReceiver}
            disableFields={disableFields}
            isRetryFlow={isRetryFlow}
            errorMessage={errorMessage}
            title={
              objectIsEmpty(mainReceiver) || disableFields
                ? "Datos de tu empresa"
                : id
                ? "Editar destinatario"
                : "Crear destinatario"
            }
            showDisclaimer={
              !isRetryFlow && disclaimerIsOpen && objectIsEmpty(mainReceiver)
            }
          />
        </Stack>
      </div>
    </Dialog>
  );
};

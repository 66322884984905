import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";

export const ChangeStatusModal = ({
  itemId,
  isOpen,
  setIsOpen,
  action,
  prevStatus,
  pageIndex,
  isReceipts = false,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(prevStatus);
  const options = [
    {
      value: "paid",
      label: "Pagada",
    },
    {
      value: "in_process",
      label: "En proceso",
    },
    {
      value: "refunded",
      label: "Devuelta",
    },
    !isReceipts && {
      value: "pending",
      label: "Por pagar",
    },
  ];

  return (
    <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
      <Modal.Body style={{ boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)" }}>
        <Box sx={styles.container}>
          <p style={styles.title}>Modificar estado de transacción</p>
          {selectedStatus ? (
            <Box sx={{ width: "80%" }}>
              <FormControl fullWidth size="small">
                <Select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  {options.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <CircularProgress size={20} />
          )}
          <Stack direction={"row"} spacing={1} pt={4} justifyContent="flex-end">
            <SecondaryButton text="Cancelar" action={() => setIsOpen(false)} />
            <PrimaryButton
              text="Confirmar"
              action={() => {
                action(itemId, selectedStatus, pageIndex);
                setIsOpen(false);
              }}
              isDisable={!selectedStatus}
            />
          </Stack>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

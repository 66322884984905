import React, { useContext, useState } from "react";
import { CrownIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import {
  ContactHelp,
  Footer,
  Features,
  CardInformation,
  SubscriptionTitle,
  SubscriptionIcon,
  TransactionCost,
  TextAddEgress,
} from "./";
import {
  TITLE_FEATURE,
  TITLE,
  PRICE_FEATURE,
  stepsBilling,
  DISCOUNT,
  SUBSCRIPTION_TYPE,
} from "constants";
import { UserContext } from "contexts";

export const ModalSuscriber = ({
  type,
  features,
  handleChangeStep,
  subscription,
  setPlanSelected,
  planSelected,
  createSubscription,
}) => {
  const { currentCompany } = useContext(UserContext);

  const featureFormatted = features?.map((feature) => ({
    title: TITLE_FEATURE[feature?.months],
    price: PRICE_FEATURE[type][feature?.months],
    discount: DISCOUNT[feature?.months],
    value: feature,
    onClick: () => handlePlanSelected(feature),
  }));

  const [isLoading, setIsLoading] = useState(false);

  const handlePlanSelected = (value) => {
    setPlanSelected(value);
  };

  const handleSubscription = async () => {
    setIsLoading(true);
    await createSubscription();
    setIsLoading(false);
  };

  const handleSubscriptionV2 = async () => {
    if (!currentCompany?.billingId) {
      handleChangeStep(stepsBilling.billing);
    } else {
      handleSubscription();
    }
  };

  const isDisabled = () => {
    if (SUBSCRIPTION_TYPE.EXPENSES === type) return false;

    return !planSelected || planSelected === null || !planSelected?.id;
  };

  return (
    <>
      <SubscriptionIcon icon={<CrownIcon size="medium" />} />
      <SubscriptionTitle title={TITLE[type]} />
      <div
        style={{
          width: "474px",
          padding: "16px",
          borderRadius: "8px",
          border: "1px solid #D0D5DD",
        }}
      >
        <CardInformation type={type} />
        {SUBSCRIPTION_TYPE.EXPENSES !== type && (
          <Features
            features={featureFormatted}
            title={"Nuestros planes"}
            planSelected={planSelected}
          />
        )}
        {SUBSCRIPTION_TYPE.TREASURY === type && <TransactionCost />}
        {SUBSCRIPTION_TYPE.EXPENSES === type && (
          <TextAddEgress
            treasury={subscription?.companySubscriptions?.find(
              (feature) => feature?.featureType === SUBSCRIPTION_TYPE.TREASURY
            )}
          />
        )}
        <Footer>
          <PrimaryButton
            text={"Suscribirme"}
            style={{ width: "100%", fontSize: "16px" }}
            action={handleSubscriptionV2}
            loading={isLoading}
            isDisable={isDisabled()}
          />
          <ContactHelp type={type} />
        </Footer>
      </div>
    </>
  );
};

import React from "react";
import styles from "./styles.module.css";

const ServiceDetailsInvoiceForm = ({ referralCode = "" }) => (
  <div className={styles.container}>
    <div className={styles.cardContainer}>
      <p className={`card-subtitle mb-2 ${styles.cardTitle}`}>
        Información de pago
      </p>
      <p className={`card-subtitle mb-2 ${styles.cardText}`}>{referralCode}</p>
    </div>
  </div>
);

export default ServiceDetailsInvoiceForm;

import React from "react";

export const ArrowNarrowUpRightIcon = ({
  width = "25",
  height = "24",
  stroke = "#D92D20",
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
    <path
      d="M6.5 18L18.5 6M18.5 6H10.5M18.5 6V14"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";
import { Dropdown } from "react-bootstrap";
import { Divider } from "@mui/material";
import { DeleteIcon, EditIcon } from "../../../assets";
import { styles } from "../styles";
import { ActionsButton } from "./ActionsButton";

export const UsersActionsDropdown = ({
  editAction,
  deleteAction,
  person = {},
}) => {
  const { id } = person;
  return (
    <Dropdown style={{ display: "flex", alignSelf: "center" }}>
      <ActionsButton />
      <Dropdown.Menu style={styles.noPadding}>
        <Dropdown.Item
          style={styles.actionsDropdownPadding}
          onClick={() => editAction(person, "edit")}
        >
          <div style={styles.dropdownItemContainer} type="dropdown">
            <EditIcon />
            <div style={styles.dropdownItemTextContainer}>
              <p style={styles.actionsDropdownItemText}>Editar usuario</p>
            </div>
          </div>
        </Dropdown.Item>
        {!person.is_creator && (
          <>
            <Divider />
            <Dropdown.Item
              style={styles.actionsDropdownPadding}
              onClick={() => {
                deleteAction(id);
              }}
            >
              <div style={styles.dropdownItemContainer} type="dropdown">
                <DeleteIcon height={"18px"} width={"14px"} stroke={"#B42318"} />
                <div style={styles.dropdownItemTextContainer}>
                  <p style={styles.actionsDropdownItemDeleteText}>Eliminar</p>
                </div>
              </div>
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

import React, { useContext, useEffect, useState } from "react";
import ModalShell from "../PaymentModal/ModalShell";
import { interpretError } from "../../../services/api/dian";
import Steps from "./Steps";
import { UserContext } from "../../../contexts";
import "./siigo-integration-modal.css";
import {
  StoreErp,
  SyncErp,
  SyncErpCollections,
  UpdateCompany,
  syncCausation,
} from "../../../services";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const SiigoIntegrationModal = ({
  visible,
  setIsSiigoModalVisible,
  refreshTable = () => {},
  isCausation = false,
  isCollections = false,
  isReconciliations = false,
  initialStep = null,
  setSyncBanner = () => {},
}) => {
  const { currentCompany, getMe } = useContext(UserContext);
  const navigate = useNavigate();
  const [step, setStep] = useState(2);
  const [formValues, setFormValues] = useState({
    companyDocumentNumber: currentCompany?.identification_number,
  });
  const [credentialsError, setCredentialsError] = useState(false);
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    visible && initialStep && setStep(initialStep);
  }, [visible]);

  const close = () => {
    setIsSiigoModalVisible(false);
    setCredentialsError(false);
    setData({});
    setTimeout(() => {
      setStep(2);
    }, 500);
  };

  const showErrorStep = ({ data, title, description, button }) => {
    setData(data);
    setErrorMessage({
      title,
      description,
      button,
    });
    setStep(8);
  };

  const handleErrorMessage = {
    retry: (data) => {
      setData(data);
      showErrorStep({
        data,
        title: "No pudimos importar tu información.",
        description: "Por favor intenta de nuevo más tarde.",
        button: "Reintentar",
      });
    },
    wrongCredentials: (data) => {
      setData(data);
      setCredentialsError(true);
      showErrorStep({
        data,
        title: "Alguno de los datos que ingresaste son incorrectos.",
        description: "Por favor, revisa la información y vuelve a intentarlo.",
        button: "Revisar datos",
      });
    },
    siigoIntermittencies: (data) => {
      setData(data);
      showErrorStep({
        data,
        title: "Siigo se encuentra con intermitencias.",
        description:
          "No pudimos importar tu información. Por favor intenta de nuevo más tarde.",
        button: "Reintentar",
      });
    },
    fiveMinutes: (data) => {
      setData(data);
      showErrorStep({
        data,
        title: "No pudimos importar tu información.",
        description:
          "Para que puedas sincronizar nuevamente, es necesario que pasen al menos 5 minutos de la última sincronización.",
        button: "Reintentar",
      });
    },
  };

  const handleErrorCases = ({ redirectUrl, res, data }) => {
    const responseMessage = res?.response?.data?.message;
    if (responseMessage === "Error at syncing siigo causation") {
      handleErrorMessage["siigoIntermittencies"](data);
    } else if (
      responseMessage === "Falló la lambda al subir las facturas." ||
      responseMessage === "Erp debe ser tipo Siigo." ||
      responseMessage === "ERP no válido" ||
      responseMessage === "Falta fecha de creación." ||
      responseMessage === "Error at lambda siigo-causation"
    ) {
      handleErrorMessage["retry"](data);
    } else if (responseMessage === "Credentials is empty") {
      handleErrorMessage["wrongCredentials"](data);
    } else if (
      responseMessage === "You can try again in a few minutes." ||
      responseMessage === "Please retry in a few minutes."
    ) {
      handleErrorMessage["fiveMinutes"](data);
    } else {
      refreshTable();
      navigate(redirectUrl, {
        replace: !isReconciliations,
        ...(isReconciliations && { state: { importSuccess: true } }),
      });
      setSyncBanner((syncBanner) => {
        return {
          ...syncBanner,
          visible: true,
          type: "success",
          title:
            "¡Importación exitosa! Ya puedes visualizar la información que trajimos desde Siigo",
        };
      });
      setStep(2);
      close();
    }
  };

  const notifyResponse = async (res, startDate = null, data = {}) => {
    const responseMessage = res?.response?.data?.message;
    if (responseMessage === "Credenciales incorrectas (obtener token)") {
      handleErrorMessage["wrongCredentials"](data);
      return;
    }

    if (res.message === "Datos almacenados exitosamente") {
      if (isCollections) {
        setStep(5);
        SyncErpCollections(startDate).then((res) => {
          handleErrorCases({ redirectUrl: "/collections/index", res, data });
        });
      } else if (isCausation) {
        syncCausation().then((res) => {
          handleErrorCases({ redirectUrl: "/payments/causation", res, data });
        });
      } else if (isReconciliations) {
        handleErrorCases({ redirectUrl: "/reconciliations", res, data });
      } else {
        await SyncErp().then((res) => {
          handleErrorCases({ redirectUrl: "/payments", res, data });
        });
      }
    } else if (res.message === "Credenciales incorrectas (obtener token)") {
      handleErrorMessage["wrongCredentials"](data);
      return;
    } else if (res.response.status === 400) {
      handleErrorMessage["retry"](data);
      return;
    } else {
      handleErrorMessage["retry"](data);
      return;
    }
  };

  const handleError = async (fn) => {
    const invalidCredentials = () => {
      handleErrorMessage["wrongCredentials"](data);
    };
    const expiredCredentials = () => {
      handleErrorMessage["wrongCredentials"](data);
    };
    const genericError = () => {
      handleErrorMessage["retry"](data);
    };
    const errorHandlers = {
      invalidCredentials,
      expiredCredentials,
      genericError,
    };
    try {
      await fn();
    } catch (error) {
      interpretError(error, errorHandlers);
    }
  };

  const primaryAction = async (data) => {
    handleError(async () => {
      if (step === 1) {
        setStep(2);
      } else if (step === 2) {
        setStep(3);
        await UpdateCompany(currentCompany.id, {
          user_cs: data.user,
          password_cs: data.pass,
          ...(!isCausation && { name_cs: "Siigo" }),
        }).then(async () => {
          await getMe().then(() => {
            setStep(4);
          });
        });
      } else if (step === 4) {
        setStep(5);
        const timeoutId = setTimeout(async () => {
          const startDate = data?.startDate
            ? format(data?.startDate, "yyyy-MM-dd")
            : "";

          await StoreErp({
            accessKey: data.key,
            userName: data.userName,
            dueDateStart: startDate,
            companyId: currentCompany.id,
            isCausation: isCausation,
          }).then((res) => notifyResponse(res, startDate, data));
        }, 3000);
        return () => {
          clearTimeout(timeoutId);
        };
      } else if (step === 7) {
        setStep(5);
        const timeoutId = setTimeout(() => {
          SyncErpCollections(
            data?.startDate ? format(data?.startDate, "yyyy-MM-dd") : null
          ).then(() => {
            refreshTable();
            navigate("/collections/index", { replace: true });
            setSyncBanner((syncBanner) => {
              return {
                ...syncBanner,
                visible: true,
                type: "success",
                title:
                  "¡Importación exitosa! Ya puedes visualizar la información que trajimos desde Siigo",
              };
            });
            setStep(2);
            close();
          });
        }, 3000);
        return () => {
          clearTimeout(timeoutId);
        };
      } else if (step === 8) {
        setStep(4);
      }
    });
  };

  useEffect(() => {
    if (
      currentCompany.identification_number &&
      !formValues.companyDocumentNumber
    ) {
      setFormValues({
        companyDocumentNumber: currentCompany.identification_number,
      });
    }
  }, [currentCompany]);

  return (
    <ModalShell visible={visible}>
      <Steps
        step={step}
        primaryAction={primaryAction}
        siigoData={formValues}
        cancel={close}
        isCausation={isCausation}
        credentialsError={credentialsError}
        setCredentialsError={setCredentialsError}
        data={data}
        errorMessage={errorMessage}
      />
    </ModalShell>
  );
};

export { SiigoIntegrationModal };

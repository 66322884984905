import React from "react";
import { ModalWarningIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import styles from "../company-switch-modal.module.css";

export const CreateError = ({ cancel }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "12px",
        }}
      >
        <ModalWarningIcon />
      </div>
      <p className={styles["success-title"]}>No pudimos crear la empresa</p>
      <p className={styles["description"]}>
        Ya existe una empresa con ese número de identificación.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <PrimaryButton
          text={"Regresar"}
          action={cancel}
          style={{ alignSelf: "flex-end" }}
          width="50%"
        />
      </div>
    </>
  );
};

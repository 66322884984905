import { documentRow } from "./documentRow";

export const employeesColumns = [
  {
    field: "name",
    Header: "Nombre completo",
    headerName: "Nombre completo",
    accessor: "name",
    id: "name",
    Cell: ({ row }) =>
      row.original.name ? row.original.name : "Empleado Incompleto",
  },
  {
    field: "email",
    Header: "Correo electrónico",
    headerName: "Correo electrónico",
    accessor: "email",
    id: "email",
  },
  {
    field: "document_number",
    Header: "Nro. de identificación",
    headerName: "Nro. de identificación",
    accessor: "document_number",
    id: "document_number",
    Cell: ({ row }) => {
      return documentRow(row);
    },
  },
  {
    field: "bank_name",
    Header: "Entidad bancaria",
    headerName: "Entidad bancaria",
    accessor: "bank_name",
    id: "bank_name",
  },
];

import { requestContainer } from "services/api/requestContainer";

export const searchDebts = async (searchDebtsRequestPayload) => {
  const response = await requestContainer({
    url: "/services/debts",
    method: "post",
    data: searchDebtsRequestPayload,
  });
  return response?.debts || [];
};

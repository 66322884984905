import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  Autocomplete,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { ServicesSearch } from "../../../../../services";
import { ValidationMessage } from "../../../../components";
import { PrimaryButton, SecondaryButton } from "../../../../buttons";
import { styles } from "../../styles";
import DividerTicket from "../../../../modals/SupplierDetails/components/DividerTicket";
import { BackofficeCompanyContext } from "../../../../../contexts/Backoffice/company";
import { objectIsEmpty } from "../../../../../utils/objectUtils";
import stylesCSS from "./styles.module.css";

const ServicesForm = ({ onCancel, onSubmit }) => {
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);
  const [serviceSelected, setServiceSelected] = useState({});
  const [searchOptions, setSearchOptions] = useState([]);

  const { register, handleSubmit, formState, control } = useForm({});
  const formValues = useWatch({ control });

  const { companies } = useContext(BackofficeCompanyContext);

  const submitForm = handleSubmit(async (formValues) => {
    setCreateRequestInProcess(true);
    await onSubmit({
      company_id: formValues?.companyId,
      service_id: serviceSelected?.id,
      alias: formValues?.alias,
      referral_code: formValues?.referral_code,
    });
    setCreateRequestInProcess(false);
  });

  const getSearchOptions = (query) => {
    setServiceSelected({ name: query });
    ServicesSearch(query).then((res) => {
      setSearchOptions(res);
    });
  };

  return (
    <form className={stylesCSS.form} onSubmit={submitForm}>
      <div>
        <Stack spacing={1}>
          <label htmlFor="company" style={styles.inputLabel}>
            Cliente
          </label>
          <Select
            size="small"
            variant="outlined"
            value={formValues.companyId}
            style={{ fontSize: "14px" }}
            {...register("companyId", { required: true })}
          >
            {companies.map((company) => {
              return (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              );
            })}
          </Select>
          <ValidationMessage errors={formState.errors} field="companyId" />
        </Stack>
        <Stack spacing={1}>
          <Typography sx={styles.inputLabel}>Nombre de la empresa</Typography>
          <Autocomplete
            sx={{ zIndex: 1302 }}
            options={searchOptions}
            freeSolo
            disablePortal
            getOptionLabel={(option) => option?.name || ""}
            value={serviceSelected}
            onChange={(event, newValue) => {
              if (newValue?.id) {
                setServiceSelected({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else {
                setServiceSelected({
                  id: null,
                  name: "",
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14 },
                }}
                placeholder={"Ingresa el nombre"}
              />
            )}
            size="small"
            onInputChange={(event) => {
              if (event?.target?.value?.length >= 1) {
                getSearchOptions(event?.target?.value);
              }
            }}
          />
        </Stack>
        <Stack my={2} spacing={2}>
          <Stack spacing={1}>
            <Typography sx={styles.inputLabel}>Alias</Typography>
            <TextField
              id="alias"
              size="small"
              variant="outlined"
              {...register("alias", { required: true })}
              value={formValues.alias}
              InputProps={{
                style: { fontSize: 14 },
                placeholder: "Ej: ENEL Colombia/oficina 1",
              }}
            />
            <ValidationMessage errors={formState.errors} field="alias" />
            <Typography sx={styles.inputLabelLegend}>
              Elige un nombre que te ayuder a recordar el servicio
            </Typography>
          </Stack>
        </Stack>
        <Stack my={2} spacing={2}>
          <Stack spacing={1}>
            <Typography sx={styles.inputLabel}>
              Información para el pago
            </Typography>
            <TextField
              id="referral_code"
              size="small"
              variant="outlined"
              placeholder="Información para el pago"
              {...register("referral_code", { required: true })}
              value={formValues.name}
              InputProps={{
                style: { fontSize: 14 },
              }}
            />
            <ValidationMessage
              errors={formState.errors}
              field="referral_code"
            />
            <Typography sx={styles.inputLabelLegend}>
              Digita el número de cliente, teléfono, referencia de pago o lo que
              creas necesario para proceder con el pago.
            </Typography>
          </Stack>
        </Stack>
      </div>
      <div className={stylesCSS.buttonsContainer}>
        <DividerTicket className={stylesCSS.divider} />
        <div className={stylesCSS.buttonContainer}>
          <SecondaryButton
            text="Cancelar"
            action={onCancel}
            style={{ marginRight: "10px" }}
          />
          <PrimaryButton
            isDisable={createRequestInProcess || objectIsEmpty(serviceSelected)}
            text="Guardar"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default ServicesForm;

import React, { useState } from "react";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton } from "../../buttons";
import { useMediaQuery } from "react-responsive";
import {
  CausationTutorialStepOne,
  CausationTutorialStepTwo,
  CausationTutorialStepZero,
  PaginationDotIcon,
} from "../../../assets";

export const CausationModal = ({ visible, handleClose, buttons = null }) => {
  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep(step + 1);
  };

  const texts = {
    0: {
      title: "Causar nunca fue tan sencillo",
      label: "¡Integración de los módulos de Causación y Cuentas por Pagar!",
      secondLabel: "",
    },
    1: {
      title: 'Visualiza el estado de tus documentos desde la columna "Causada"',
      label: "Podrás ver los estados de todos tus documentos desde este Panel",
      secondLabel: "",
    },
    2: {
      title: " Creación de Cuentas de Cobro desde Cuentas por Pagar",
      label:
        'Ahora, desde la pestaña de "Cuentas por Pagar", podrá crear cuentas de cobro',
      secondLabel: "con toda la información necesaria al alcance de su mano.",
    },
  };

  const image = {
    0: <CausationTutorialStepZero />,
    1: <CausationTutorialStepOne />,
    2: <CausationTutorialStepTwo />,
  };

  const close = () => {
    handleClose();
    setStep(0);
  };

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body>
        <Box style={styles.container}>
          <p style={styles.header}>¡Nuevo!</p>
          <p style={styles.title}>{texts[step]?.title}</p>
          <p style={styles.subtitle}>{texts[step]?.label}</p>
          <p style={styles.subtitle}>{texts[step]?.secondLabel}</p>
          {image[step]}
          <div style={{ marginTop: "16px" }}>
            <PaginationDotIcon step={step} />
          </div>
          {buttons || (
            <div style={styles.row}>
              <PrimaryButton
                text={step !== 2 ? "Siguiente" : "Entendido"}
                action={step !== 2 ? nextStep : close}
                width="114px"
              />
            </div>
          )}
        </Box>
      </Modal.Body>
    </Modal>
  );
};

import { SortingHeader } from "../commons/Table/components";
import { formatLocaleDate } from "./formatLocaleDate";

export const customerColumns = (setSorting, sorting) => [
  {
    field: "concept",
    headerName: "Concepto",
    accessor: "concept",
    id: "concept",
    disableSortBy: true,
    className: "provider-name-column",
    Header: () => <div className="pill-header">Concepto</div>,
  },
  {
    field: "collection_number",
    headerName: "Comprobante",
    accessor: "collection_number",
    id: "collection_number",
    className: "invoice_number-column",
    disableSortBy: true,
    Header: () => <div className="pill-header">Comprobante</div>,
    Cell: ({ value }) => {
      if (value) {
        return value.toUpperCase();
      }
    },
  },
  {
    field: "issue_date",
    headerName: "Fecha de emisión",
    accessor: "issue_date",
    id: "issue_date",
    className: "invoice_dates-column",
    Cell: ({ value }) => {
      if (value) {
        return formatLocaleDate(value.slice(0, -1), "dd MMM, y");
      }
    },
    Header: () => {
      return (
        <SortingHeader
          fieldName="issue_date"
          label="Fecha de emisión"
          setSorting={setSorting}
          sorting={sorting}
        />
      );
    },
  },
  {
    field: "expiration_date",
    headerName: "Fecha de vto.",
    accessor: "expiration_date",
    id: "expiration_date",
    className: "invoice_dates-column",
    Header: () => {
      return (
        <SortingHeader
          fieldName="expiration_date"
          label="Fecha de vto."
          setSorting={setSorting}
          sorting={sorting}
        />
      );
    },
  },
  {
    field: "status",
    headerName: "Estado",
    accessor: "status",
    id: "status",
    disableSortBy: true,
    Header: () => <div className="pill-header">Estado</div>,
  },
  {
    field: "amount",
    headerName: "Monto",
    accessor: "amount",
    id: "amount",
    className: "amount_total-column",
    Header: () => {
      return (
        <SortingHeader
          className="sorting-amount"
          fieldName="amount"
          label="Monto"
          setSorting={setSorting}
          sorting={sorting}
        />
      );
    },
  },
];

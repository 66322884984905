export const searchGroupTitle = {
  provider: "Proveedores",
  employee: "Empleados",
  customer: "Clientes",
  receiver: "Cobradores adicionales",
};

export const searchGroupText = {
  provider: "proveedor",
  employee: "empleado",
  customer: "cliente",
  receiver: "cobrador adicional",
};

export const storeType = {
  paymentRecord: "RP",
  cashRegisterReceipt: "RC",
  accountingReceipt: "CC",
};

export const reconciledType = {
  RP: "registro de pago",
  RC: "recibo de caja",
  CC: "comprobante contable",
};

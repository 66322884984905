import React from "react";
import { getTransactionsAccountTypeLabel } from "constants";
import { styles } from "../styles";

export const SourceOfFundsRow = ({ row }) => {
  const sourceOfFunds = row.original.source_of_funds;

  const nameByType = sourceOfFunds.type
    ? sourceOfFunds.type === "bank_account"
      ? sourceOfFunds.bankName
      : "Tarjeta de crédito"
    : null;

  const bankName = {
    invoices: nameByType,
    payrolls: nameByType,
    collections: nameByType,
    invoice: row.original.beneficiary_bank_name,
    payroll: row.original.beneficiary_bank_name,
    collection: row.original.beneficiary_bank_name,
  };

  const accountType = {
    invoices: sourceOfFunds.accountType,
    payrolls: sourceOfFunds.accountType,
    collections: sourceOfFunds.accountType,
    invoice: row.original.beneficiary_account_type,
    payroll: row.original.beneficiary_account_type,
    collection: row.original.beneficiary_account_type,
  };

  const accountNumber = {
    invoices: sourceOfFunds.accountNumber,
    payrolls: sourceOfFunds.accountNumber,
    collections: sourceOfFunds.accountNumber,
    invoice: row.original.beneficiary_account_number,
    payroll: row.original.beneficiary_account_number,
    collection: row.original.beneficiary_account_number,
  };

  return sourceOfFunds &&
    row.original.operation_type !== "payana_commission" ? (
    <div style={styles.revertRow}>
      <p
        style={{
          color: "#4A5578",
          fontSize: "14px",
          fontWeight: row.original.type === "section" ? "600" : "400",
          justifyContent: "flex-start",
          margin: "0px",
        }}
      >
        {bankName[row.original.operation_type]}
      </p>
      <p
        style={{
          color: "#98A2B3",
          fontSize: "12px",
          fontWeight: row.original.type === "section" ? "600" : "400",
          justifyContent: "flex-start",
          margin: "0px",
        }}
      >
        {getTransactionsAccountTypeLabel(
          accountType[row.original.operation_type]
        )}{" "}
        {accountNumber[row.original.operation_type]}
      </p>
    </div>
  ) : null;
};

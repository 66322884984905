import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  DaviviendaIcon,
  BellRingin,
  BancolombiaPNG,
  GrupoAvalIcon,
} from "../../assets";

const styles = {
  infoPaymentContainer: {
    background: "#FCFCFD",
    padding: "16px 15px",
    borderRadius: "8px 8px 0px 0px",
    borderBottom: "1px solid #EAECF0",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  infoPaymentText: {
    alignSelf: "stretch",
    color: "#344054",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "-0.14px",
  },
  infoPaymentBankContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
  },
  infoPaymentBank: {
    borderRadius: "30px",
    border: "1px solid #EAECF0",
    background: "#FFF",
    boxhadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    height: "36px",
    width: "210px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    fontWeight: "bold",
    fontSize: "14px",
  },
  infoPaymentFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "11px",
    padding: "15px 16px",
    background: "#F4F3FF",
    borderRadius: "0px 0px 8px 8px",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
  },
};
export const InfoPayment = () => {
  return (
    <Stack style={{ width: "100%" }}>
      <Box sx={styles.infoPaymentContainer}>
        <Typography sx={styles.infoPaymentText}>
          El dinero enviado a cuentas de los siguientes bancos{" "}
          <strong>se acreditará en las próximas 2 horas</strong>
        </Typography>
        <Box style={styles.infoPaymentBankContainer}>
          <div style={styles.infoPaymentBank}>
            <BancolombiaPNG width={25} height={20} />
            Bancolombia
          </div>
          <div style={styles.infoPaymentBank}>
            <DaviviendaIcon />
            Davivienda
          </div>
          <div style={styles.infoPaymentBank}>
            <GrupoAvalIcon />
            Grupo Aval
          </div>
        </Box>
        <Typography sx={styles.infoPaymentText}>
          El dinero enviado a otros bancos se acreditará de acuerdo a los{" "}
          <strong>tiempos de procesamiento de ACH.</strong>
        </Typography>
      </Box>
      <Box sx={styles.infoPaymentFooter}>
        <BellRingin />
        <Typography sx={styles.infoPaymentText}>
          En ambos casos el destinatario recibirá la notificación de pago en
          camino y otra al acreditarse automáticamente junto al soporte de pago.
        </Typography>
      </Box>
    </Stack>
  );
};

import React from "react";
import { Typography } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  ArrowNarrowBottomLeftIcon,
  ArrowNarrowUpRightIcon,
  PartiallyReconciledIcon,
  PayanaIcon,
} from "assets";
import { NumericFormat } from "react-number-format";
import { Tooltip } from "commons";

export const BankMovementItem = ({
  selectedMovement,
  movement,
  setSelectedMovement,
  handleSelectMovement,
}) => {
  return (
    <div
      style={
        selectedMovement.id === movement.id
          ? {
              width: "100%",
              border: "2px solid #9B8AFB",
              padding: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              backgroundColor: "#FAFAFF",
              borderRadius: "8px",
            }
          : {
              width: "100%",
              border: "1px solid #EAECF0",
              padding: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }
      }
      onClick={() =>
        selectedMovement.id === movement.id
          ? setSelectedMovement({})
          : handleSelectMovement(movement)
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "2px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#344054",
          }}
        >
          {movement.issue_date}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {movement.is_from_payana && (
            <Tooltip title="Movimiento Payana">
              <span>
                <PayanaIcon width="16px" height="16px" />
              </span>
            </Tooltip>
          )}
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#344054",
            }}
            className="limit-text"
          >
            {movement.description}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#101828",
            }}
          >
            <NumericFormat
              value={movement.amount}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </Typography>{" "}
          {movement.amount > 0 ? (
            <ArrowNarrowBottomLeftIcon width="19px" height="24px" />
          ) : (
            <ArrowNarrowUpRightIcon width="19px" height="24px" />
          )}
        </div>
        {movement.status === "partially_reconciled" && (
          <Tooltip title="Parcialmente conciliado">
            <span style={{ alignSelf: "flex-end" }}>
              <PartiallyReconciledIcon />
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const primaryButton = {
  backgroundColor: "#6938EF",
  border: "1px solid #6938EF",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontWeight: "500",
  fontSize: "16px",
  padding: "10px 0px",
};

export const styles = {
  title: {
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "30px",
    color: "#101828",
  },
  label: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#101828",
    marginBottom: "12px",
  },
  labelButton: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#5925dc",
    "&:hover": {
      cursor: "pointer",
    },
  },
  primaryButton,
  primaryLoadingButton: {
    ...primaryButton,
    padding: "10px 0px 5px 0px",
  },
  footer: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#101828",
  },
};

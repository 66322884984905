import React from "react";

export const NotesIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity="0.4" d="M0 0H12V8L8 12H0V0Z" fill="#FEDF89" />
    <path opacity="0.4" d="M8 8H12L8 12V8Z" fill="#FDB022" />
    <rect opacity="0.4" width="12" height="3" fill="#FDB022" />
  </svg>
);

import React from "react";
import { Button, Typography } from "@mui/material";

export function OptionGoTo({ action, icon, buttonLabel, styles }) {
  return (
    <Button onClick={action} endIcon={icon} sx={styles}>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          textAlign: "left",
          color: "#1D2939",
        }}
      >
        {buttonLabel}
      </Typography>
    </Button>
  );
}

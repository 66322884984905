export const styles = {
  footer: {
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #EAECF0",
    position: "fixed",
    width: "100%",
    bottom: "0",
    color: "white",
    fontSize: "25px",
    boxShadow: "0px -6px 16px -4px rgba(16, 24, 40, 0.08)",
    height: "64px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  smallFooterMobile: {
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #EAECF0",
    position: "fixed",
    width: "100%",
    bottom: "0",
    color: "white",
    fontSize: "25px",
    boxShadow: "0px -6px 16px -4px rgba(16, 24, 40, 0.08)",
    height: "120px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  bigFooterMobile: {
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #EAECF0",
    position: "fixed",
    width: "100%",
    bottom: "0",
    color: "white",
    fontSize: "25px",
    boxShadow: "0px -6px 16px -4px rgba(16, 24, 40, 0.08)",
    height: "150px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  footerElements: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  receiversFooterElements: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  footerElementsMobile: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  footerText: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    marginRight: "12px",
    display: "flex",
    alignSelf: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  footerSubText: {
    color: "#475467",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    marginRight: "12px",
    display: "flex",
    alignSelf: "flex-end",
    marginTop: "auto",
    marginBottom: "auto",
  },
  footerSubTextMobile: {
    color: "#475467",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    display: "flex",
    alignSelf: "center",
    marginTop: "4px",
    marginBottom: "8px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  dropdownPadding: {
    padding: "2px 16px 2px 16px",
  },
  noPadding: {
    padding: "0px",
  },
  dropdownItemContainer: {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center",
  },
  dropdownItemIconContainer: {
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    margin: "0px",
  },
  dropdownItemTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "16px",
    textAlign: "initial",
    marginTop: "16px",
  },
  dropdownItemDeleteText: {
    color: "#B42318",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  dropdownItemText: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "8px",
  },
};

import {
  Autocomplete,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { SearchIcon } from "assets";
import { InputForm } from "commons";
import { SecondaryButton } from "commons/buttons";
import { toastOptions } from "constants";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { getBillingsType } from "services";
import { billingTypeItems } from "../utils";

const labelType = {
  company: "",
  accountant: "Selecciona tu contador o crea uno nuevo",
  conglomerate: "Selecciona tu conglomerado o crea uno nuevo",
};

const buttonSelectStyle = { backgroundColor: "#6938EF", color: "#FFF" };

export const BillingBusinessType = ({
  isShowDetail,
  isCreateBilling,
  businessType,
  handleChangeBusinessType,
  reset,
  handleChangeBilling,
  handleChangeEditable,
  billings = [],
}) => {
  const [businessBillings, setBusinessBillings] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const handleGetBillingsType = async (type, query = "") => {
    try {
      const resp = await getBillingsType(type, query);
      setBusinessBillings(resp);
    } catch (error) {
      toast.error(
        "No se pudo obtener la información de facturación",
        toastOptions
      );
    }
  };

  const handleReset = () => {
    handleChangeBilling({});
    reset({
      id: null,
      organization_type: null,
      business_name: null,
      document_type: null,
      document_number: null,
      address: null,
      email: null,
      contact_name: null,
      contact_surname: null,
    });
    setAutocompleteValue("");
  };

  const handleChangeAutocomplete = (option) => {
    setAutocompleteValue(option);
    const formatOption = {
      id: option.id,
      organization_type: option.organizationType,
      business_name: option.businessName,
      document_type: option.documentType,
      document_number: option.documentNumber,
      address: option.address,
      phone: option.phone,
      email: option.email,
      contact_name: option.contactName,
      contact_surname: option.contactSurname,
      city: option.city,
    };
    reset({
      ...formatOption,
    });
    handleChangeBilling(formatOption);
    handleChangeEditable(false);
  };

  let showCompanyBilling = true;
  let showAccountantBilling = true;

  billings?.forEach((billing) => {
    if (
      billing.type === billingTypeItems.company ||
      billing.type === billingTypeItems.conglomerate
    ) {
      showCompanyBilling = false;
    }

    if (billing.type === billingTypeItems.accountant) {
      showAccountantBilling = false;
    }
  });

  if (!showCompanyBilling) {
    handleChangeBusinessType(billingTypeItems.accountant);
  }

  const buttons = [
    {
      show: showCompanyBilling,
      text: "Compañía",
      style: businessType === billingTypeItems.company ? buttonSelectStyle : {},
      action: () => {
        handleChangeBusinessType(billingTypeItems.company);
        setBusinessBillings([]);
        handleReset();
        setBusinessBillings([]);
      },
    },
    {
      show: showAccountantBilling,
      text: "Contador",
      style:
        businessType === billingTypeItems.accountant ? buttonSelectStyle : {},
      action: () => {
        handleGetBillingsType(billingTypeItems.accountant);
        handleChangeBusinessType(billingTypeItems.accountant);
        handleReset();
        setBusinessBillings([]);
      },
    },
    {
      show: showCompanyBilling,
      text: "Conglomerado",
      style:
        businessType === billingTypeItems.conglomerate ? buttonSelectStyle : {},
      action: () => {
        handleGetBillingsType(billingTypeItems.conglomerate);
        handleChangeBusinessType(billingTypeItems.conglomerate);
        handleReset();
        setBusinessBillings([]);
      },
    },
  ];
  return (
    (isShowDetail || isCreateBilling) && (
      <>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          gap={"8px"}
          style={{ marginBottom: "16px" }}
        >
          {buttons.map((button, index) => (
            <SecondaryButton
              isDisable={!button.show}
              key={index}
              text={button.text}
              width="100%"
              style={button.style}
              action={button.action}
            />
          ))}
        </Stack>
        {businessType !== billingTypeItems.company && (
          <InputForm
            label={labelType[businessType]}
            name="business_billing"
            width={"100%"}
            customInput={
              <Autocomplete
                options={businessBillings}
                size="small"
                freeSolo
                filterOptions={(options, { inputValue }) => {
                  const inputValueLower = inputValue.toLowerCase();
                  return options.filter((option) =>
                    option.businessName.toLowerCase().includes(inputValueLower)
                  );
                }}
                getOptionLabel={(option) => {
                  return option?.businessName || "";
                }}
                onChange={(event, option) => {
                  if (!option) return handleReset();
                  handleChangeAutocomplete(option);
                }}
                onInputChange={(event) => {
                  if (event?.target?.value?.length >= 3) {
                    handleGetBillingsType(businessType, event.target.value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: 14 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={"Elige aquí"}
                  />
                )}
                value={autocompleteValue}
              />
            }
          />
        )}
        <Divider style={{ marginBottom: "16px" }} />
      </>
    )
  );
};

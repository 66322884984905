import React from "react";
import { useMediaQuery } from "react-responsive";
import { Stack, Box } from "@mui/material";
import { CollectionForm } from ".";
import CollectionTimeline from "./CollectionTimeline";

export const CollectionFormWithImage = ({
  collectionId,
  formValues,
  setFormValues,
  collection,
  customer,
  setCustomer,
  selectedFiles,
  setSelectedFiles,
  customers,
  createdCustomerName,
  setDrawerIsOpen,
  getOptions,
  filePath,
  setReceiverDrawerIsOpen,
  currentUser,
  companyCollectionsCommission,
  companyCollectionsFixedCommission,
  conceptError,
  setConceptError,
  collectionNumberError,
  setCollectionNumberError,
  openCustomerForm,
  hasCollectionReceiversEnabled,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const canModifyCustomer =
    collection.status === "pending" ||
    collection.status === "outstanding_balance";

  if (isMobile) {
    return (
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ margin: "auto", height: "100%" }}
      >
        <Box>
          <CollectionForm
            hasCollectionReceiversEnabled={hasCollectionReceiversEnabled}
            collectionId={collectionId}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            formValues={formValues}
            setFormValues={setFormValues}
            collection={collection}
            customer={customer[0]}
            setCustomer={setCustomer}
            customers={customers}
            createdCustomerName={createdCustomerName}
            setDrawerIsOpen={setDrawerIsOpen}
            getOptions={getOptions}
            setReceiverDrawerIsOpen={setReceiverDrawerIsOpen}
            currentUser={currentUser}
            companyCollectionsCommission={companyCollectionsCommission}
            companyCollectionsFixedCommission={
              companyCollectionsFixedCommission
            }
            conceptError={conceptError}
            setConceptError={setConceptError}
            collectionNumberError={collectionNumberError}
            setCollectionNumberError={setCollectionNumberError}
            openCustomerForm={openCustomerForm}
          />
        </Box>
        {filePath ? (
          <Box>
            <iframe
              src={`${filePath}#toolbar=0#view=fitH`}
              height="100%"
              width="100%"
              title="collection_file"
            />
          </Box>
        ) : null}
      </Stack>
    );
  } else {
    return (
      <>
        {!canModifyCustomer && <CollectionTimeline collection={collection} />}
        <Stack
          direction={"row"}
          spacing={2}
          sx={{ margin: "auto", height: "100%" }}
        >
          {filePath ? (
            <Box sx={{ width: "40%" }}>
              <iframe
                src={`${filePath}#toolbar=0#view=fitH`}
                height="100%"
                width="100%"
                title="collection_file"
              />
            </Box>
          ) : null}
          <Box sx={{ width: filePath ? "60%" : "100%" }}>
            <CollectionForm
              hasCollectionReceiversEnabled={hasCollectionReceiversEnabled}
              collectionId={collectionId}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              formValues={formValues}
              setFormValues={setFormValues}
              collection={collection}
              customer={customer[0]}
              setCustomer={setCustomer}
              customers={customers}
              createdCustomerName={createdCustomerName}
              setDrawerIsOpen={setDrawerIsOpen}
              getOptions={getOptions}
              setReceiverDrawerIsOpen={setReceiverDrawerIsOpen}
              currentUser={currentUser}
              companyCollectionsCommission={companyCollectionsCommission}
              companyCollectionsFixedCommission={
                companyCollectionsFixedCommission
              }
              conceptError={conceptError}
              setConceptError={setConceptError}
              collectionNumberError={collectionNumberError}
              setCollectionNumberError={setCollectionNumberError}
              openCustomerForm={openCustomerForm}
            />
          </Box>
        </Stack>
      </>
    );
  }
};

import { requestContainer } from "./requestContainer";

export const confirmPassword = async (password, hash) => {
  const response = await requestContainer({
    method: "post",
    url: "/confirm-password",
    data: {
      password,
      hash,
    },
  });
  if (response?.response?.status && response?.response?.status !== 200) {
    throw response;
  }
  return response;
};

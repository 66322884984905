import { DEFAULT_COLLECTION_CARD_FEE } from "../../../constants";

export const getAmountToPayWithCreditCard = (totalAmount) => {
  return Math.round(
    totalAmount + (totalAmount / 100) * DEFAULT_COLLECTION_CARD_FEE
  );
};

export const getAmountInCents = (
  totalAmount,
  selectedPaymentMethod,
  selectedRowsIds,
  newPricing,
  newPricingMetadata,
  transaction,
  section = null
) => {
  if (section === "customer") {
    return totalAmount
      ? selectedPaymentMethod === "card"
        ? Math.round(
            totalAmount + (totalAmount / 100) * DEFAULT_COLLECTION_CARD_FEE
          ) * 100
        : Math.round(totalAmount) * 100
      : 100;
  }

  return totalAmount
    ? selectedPaymentMethod === "card"
      ? Math.round(
          (transaction?.amount - transaction.amount_comission) * (1 + 0.04)
        ) * 100
      : newPricing
      ? (selectedRowsIds.length * newPricingMetadata?.amount +
          Math.round(totalAmount - transaction.amount_comission)) *
        100
      : Math.round(totalAmount - transaction.amount_comission) * 100
    : 100;
};

import React from "react";
import { IconButton } from "@mui/material";
import { ExpandIcon } from "../../../assets";
import { Link } from "react-router-dom";
import { Tooltip } from "commons/components";

export const ExpandItemContainer = ({ linkTo, itemId, action, section }) => {
  if (linkTo && !action) {
    return (
      <Tooltip title="Ver detalle">
        <Link to={linkTo + itemId} state={{ from: section }}>
          <IconButton>
            <ExpandIcon />
          </IconButton>
        </Link>
      </Tooltip>
    );
  } else if (!linkTo && action) {
    return (
      <Tooltip title="Ver detalle">
        <IconButton onClick={() => action(itemId)}>
          <ExpandIcon />
        </IconButton>
      </Tooltip>
    );
  }
};

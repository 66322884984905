export const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    marginTop: "12px",
  },
  subtitle: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
  },
  subtitle2: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "600",
  },
};

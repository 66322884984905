import React from "react";
import { Box, Dialog, Stack } from "@mui/material";
import { ModalWarningIcon } from "../../../assets";
import { PrimaryButton } from "../../buttons";
import { useMediaQuery } from "react-responsive";
import styles from "./attention-modal.module.css";

export const AttentionModal = ({
  onClose,
  open,
  title,
  description = null,
  buttonText = "Entendido",
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  return (
    <Dialog onClose={onClose} open={!!open}>
      <Box style={{ padding: 24, width: isMobile ? 330 : 400 }}>
        <div className={styles["icon-container"]}>
          <ModalWarningIcon />
        </div>
        <p className={styles["title"]}>{title}</p>
        {description && (
          <div className={styles["description"]}>{description}</div>
        )}
        <Stack className={styles["button-container"]} alignItems="flex-end">
          <PrimaryButton
            text={buttonText}
            action={onClose}
            style={{ width: "auto" }}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};

import React from "react";

export const ErrorIcon = () => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none">
    <rect x="5" y="5" width="56" height="56" rx="28" fill="#EBE9FE" />
    <rect
      x="5"
      y="5"
      width="56"
      height="56"
      rx="28"
      stroke="#F4F3FF"
      strokeWidth="10"
    />
    <path
      d="M33.0007 28.3334V33M33.0007 37.6667H33.0123M44.6673 33C44.6673 39.4434 39.444 44.6667 33.0007 44.6667C26.5573 44.6667 21.334 39.4434 21.334 33C21.334 26.5567 26.5573 21.3334 33.0007 21.3334C39.444 21.3334 44.6673 26.5567 44.6673 33Z"
      stroke="#6938EF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

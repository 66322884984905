import { requestContainer } from "../requestContainer";

export function GetBOInvoices({
  pageIndex,
  status = "all",
  providerId = "all",
  companyId = "all",
}) {
  return requestContainer({
    method: "get",
    url: `/backoffice/invoices?page=${pageIndex}&status=${status}&provider_id=${providerId}&company_id=${companyId}&per_page=50`,
  });
}

export function GetBOTotalInvoices({ status, providerId, companyId }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/invoices/total?status=${status}&provider_id=${providerId}&company_id=${companyId}`,
  });
}

export async function GetBOInvoice({ id }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/invoices/${id}`,
  });
}

export async function DeleteBOInvoice({ id }) {
  return requestContainer({
    method: "delete",
    url: `/backoffice/invoices/${id}`,
  });
}

export async function UpdateBOInvoice({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/backoffice/invoices/${id}`,
    data: { ...formValues },
  });
}

export async function CreateBOInvoice({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/backoffice/invoices`,
    data: { ...formValues },
  });
}

export async function SearchBOCompanies(query) {
  return requestContainer({
    method: "get",
    url: `/backoffice/companies/search?q=${query}`,
  });
}

export async function GetBOCompanies() {
  return requestContainer({
    method: "get",
    url: `/backoffice/companies`,
  });
}

export async function GetBOCompany(id) {
  return requestContainer({
    method: "get",
    url: `/backoffice/companies/${id}`,
  });
}

export async function UpdateInvoiceStatus(id, status) {
  return requestContainer({
    method: "put",
    url: `/backoffice/invoices/status/${id}`,
    data: { status: status },
  });
}

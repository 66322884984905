import { objectIsEmpty } from "./objectUtils";

export const disabledByReceiversData = (
  checked,
  secondReceiver,
  receiverOption,
  selectedReceiver,
  firstReceiverAmount,
  formValues,
  amountComission,
  selectedSecondReceiver,
  secondReceiverAmount
) => {
  if (!checked) {
    return false;
  } else {
    if (
      (!objectIsEmpty(selectedReceiver) && !firstReceiverAmount) ||
      (!objectIsEmpty(selectedSecondReceiver) && !secondReceiverAmount)
    ) {
      return true;
    }
    if (!secondReceiver) {
      return (
        objectIsEmpty(selectedReceiver) ||
        (receiverOption === "amount"
          ? parseInt(firstReceiverAmount) >
            parseInt(formValues.amount) - amountComission
          : parseInt(firstReceiverAmount) > 100)
      );
    } else {
      return (
        objectIsEmpty(selectedReceiver) ||
        objectIsEmpty(selectedSecondReceiver) ||
        (receiverOption === "amount"
          ? parseInt(firstReceiverAmount) + parseInt(secondReceiverAmount) >
            parseInt(formValues.amount) - amountComission
          : parseInt(firstReceiverAmount) + parseInt(secondReceiverAmount) >
            100)
      );
    }
  }
};

import React, { useRef, useState } from "react";
import ModalShell from "./ModalShell";
import ConfirmCobreStep from "./ConfirmCobreStep";
import PaymentMethodSelectionStep from "./PaymentMethodSelectionStep";
import { useWompi, useCobre } from "../../../hooks";
import { TrackJS } from "trackjs";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import usePaymentMethods from "../../../hooks/usePaymentMethods";

const DEFAULT_SELECTED_PAYMENT_METHOD = "others";

export const PaymentModal = ({
  visible,
  handleClose,
  totalAmount,
  totalEmployees = null,
  selectedRowsIds,
  selectedSuppliers,
  redirectUrl = `${process.env.REACT_APP_BASE_URL}/payments/invoices`,
  type,
  trxId = null,
  canPayWithCobre,
  sourceOfFundsId,
  displayCobreAlert,
  section = null,
  customerHash,
  amountTotalInvoicePayana,
  setOpenDeletedAlert = () => {},
  setLimitExceededAlert = () => {},
  customPayload = {},
}) => {
  const {
    isCardLimitExceeded,
    showBankingCorrespondent,
    isBankingCorrespondentLimitExceeded,
  } = usePaymentMethods(totalAmount, customerHash, section);
  const [isLoading, setLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    DEFAULT_SELECTED_PAYMENT_METHOD
  );
  const [state, setState] = useState({
    confirmCobreStep: false,
    paymentMethodSelectionStep: true,
  });
  const [totalAmountToPay, setTotalAmountToPay] = useState(null);
  const transactionPayload = {
    trxId,
    selectedRowsIds,
    type,
    sourceOfFundsId,
    paymentGateway: state.confirmCobreStep ? "cobre" : "wompi",
    section,
    ...customPayload,
  };
  const onClose = () => {
    handleClose();
    setSelectedPaymentMethod(DEFAULT_SELECTED_PAYMENT_METHOD);
    setState({
      confirmCobreStep: false,
      paymentMethodSelectionStep: true,
    });
  };
  const aElement = useRef(null);
  const { wompiButton, openWompiFlow } = useWompi(
    totalAmountToPay,
    `${redirectUrl}?hash=${customerHash}`,
    selectedPaymentMethod,
    transactionPayload,
    selectedRowsIds,
    section,
    setOpenDeletedAlert,
    onClose,
    setLimitExceededAlert
  );
  const { makePaymentInCobre } = useCobre(
    transactionPayload,
    redirectUrl,
    setOpenDeletedAlert,
    setLimitExceededAlert,
    section
  );

  const showConfirmCobreStep = () => {
    setState({
      confirmCobreStep: true,
      paymentMethodSelectionStep: false,
    });
  };

  const showNextStepAfterMethodSelected = async () => {
    if (canPayWithCobre && selectedPaymentMethod === "others") {
      try {
        if (displayCobreAlert) {
          showConfirmCobreStep();
        } else {
          setLoading(true);
          await makePaymentInCobre(section === "customer");
          handleClose();
        }
      } catch (error) {
        TrackJS.console.error(error);
        toast.error("Ha ocurrido un error, intente nuevamente", toastOptions);
      } finally {
        setLoading(false);
      }
    } else {
      return openWompiFlow();
    }
  };

  return (
    <ModalShell onClose={onClose} visible={visible}>
      {state.paymentMethodSelectionStep && (
        <PaymentMethodSelectionStep
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          action={showNextStepAfterMethodSelected}
          wompiButton={wompiButton}
          isLoading={isLoading}
          totalAmount={totalAmount}
          totalEmployees={totalEmployees}
          selectedRowsIds={selectedRowsIds}
          selectedSuppliers={selectedSuppliers}
          trxId={trxId}
          type={type}
          section={section}
          isCardLimitExceeded={isCardLimitExceeded}
          showBankingCorrespondent={showBankingCorrespondent}
          isBankingCorrespondentLimitExceeded={
            isBankingCorrespondentLimitExceeded
          }
          amountTotalInvoicePayana={amountTotalInvoicePayana}
          setTotalAmountToPay={setTotalAmountToPay}
        />
      )}
      {state.confirmCobreStep && (
        <ConfirmCobreStep primaryAction={makePaymentInCobre} />
      )}
      <a href="#" target="_blank" ref={aElement}></a>
    </ModalShell>
  );
};

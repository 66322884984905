import React from "react";
import { Box } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { styles } from "./styles";
import { ModalWarningIcon } from "../../../assets";

export const DifferencesInPayrollModal = ({ visible, handleClose }) => {
  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body>
        <Box sx={styles.container}>
          <ModalWarningIcon />
          <p style={styles.title}>¡La nómina se cargó con éxito!</p>
          <p style={styles.subtitle}>
            Pero haxitían diferencias con la lista de empleados actual. Sólo se
            cargaron nómina para tus empleados actuales.
          </p>
          <div style={styles.row}>
            <Button
              variant="primary"
              style={styles.modalPrimaryButton}
              onClick={() => {
                handleClose();
              }}
            >
              Aceptar
            </Button>
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

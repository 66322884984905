export const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    marginTop: "12px",
    textAlign: "center",
  },
  subtitle: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    justifyContent: "center",
  },
  inputLabel: { fontSize: "15px", fontWeight: "500" },
};

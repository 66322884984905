import { createContext, useState } from "react";
import {
  CreateBOInvoice,
  DeleteBOInvoice,
  GetBOInvoice,
  UpdateBOInvoice,
  GetBOInvoices,
  UpdateInvoiceStatus,
} from "../../services";
import { toast } from "react-toastify";
import { toastOptions } from "../../constants";

export const BackofficeInvoiceContext = createContext();

export function BackofficeInvoiceProvider({ children }) {
  const [BOinvoices, setBOinvoices] = useState([]);
  const [statusSelected, setStatusSelected] = useState("all");
  const [providerIdSelected, setProviderIdSelected] = useState("all");
  const [companyIdSelected, setCompanyIdSelected] = useState("all");
  const [companySelected, setCompanySelected] = useState(null);

  const getInvoices = async ({
    pageIndex = 1,
    status,
    providerId,
    companyId,
  }) => {
    const gottenInvoices = await GetBOInvoices({
      pageIndex: pageIndex,
      status: status || statusSelected,
      providerId: providerId || providerIdSelected,
      companyId: companyId || companyIdSelected,
    });
    if (gottenInvoices !== null) {
      setBOinvoices(gottenInvoices.data);
    }
  };

  const getInvoice = async (id) => {
    if (id) {
      const gottenInvoice = await GetBOInvoice({
        id,
      });
      return gottenInvoice;
    }
  };

  const changeInvoiceStatus = (ids, status) => {
    const updatedInvoices = BOinvoices.map((item, index) => {
      if (ids.includes(item.id)) {
        return {
          ...item,
          status: status,
        };
      } else {
        return item;
      }
    });
    setBOinvoices(updatedInvoices);
  };

  const deleteInvoice = async (invoiceId, pageIndex) => {
    const response = await DeleteBOInvoice({ id: invoiceId });
    if (response) {
      getInvoices({
        pageIndex: pageIndex,
        status: statusSelected,
        providerId: providerIdSelected,
        companyId: companyIdSelected,
      });
      toast.success("¡La factura se borró con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const createInvoice = async (formValues) => {
    const response = await CreateBOInvoice({
      formValues: formValues,
    });
    if (response) {
      await getInvoices({
        pageIndex: 1,
        status: statusSelected,
        providerId: providerIdSelected,
        companyId: companyIdSelected,
      });
      return 200;
    } else {
      return "error";
    }
  };

  const updateInvoice = async (id, formValues) => {
    const response = await UpdateBOInvoice({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      getInvoices({
        pageIndex: 1,
        status: statusSelected,
        providerId: providerIdSelected,
        companyId: companyIdSelected,
      });
      return 200;
    } else {
      return "error";
    }
  };

  const changeBOInvoiceStatus = async (id, status, pageIndex) => {
    const response = await UpdateInvoiceStatus(id, status);
    if (response) {
      toast.success("¡La factura se actualizó con éxito!", toastOptions);
      getInvoices({
        pageIndex: pageIndex,
        status: statusSelected,
        providerId: providerIdSelected,
        companyId: companyIdSelected,
      });
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  return (
    <BackofficeInvoiceContext.Provider
      value={{
        BOinvoices,
        changeInvoiceStatus,
        getInvoices,
        getInvoice,
        deleteInvoice,
        createInvoice,
        updateInvoice,
        changeBOInvoiceStatus,
        statusSelected,
        setStatusSelected,
        providerIdSelected,
        setProviderIdSelected,
        companyIdSelected,
        setCompanyIdSelected,
        companySelected,
        setCompanySelected,
      }}
    >
      {children}
    </BackofficeInvoiceContext.Provider>
  );
}

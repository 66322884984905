import React from 'react'
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { styles } from "./styles";

export const UploadFileModalFooter = ({isLoading, closeAndReset, upload, selectedFiles}) => {
    return (
        <div style={styles.modalFooter}>
            <SecondaryButton
                text={isLoading ? "Cerrar" : "Cancelar"}
                action={closeAndReset}
            />
            <PrimaryButton
                text="Guardar"
                action={upload}
                isDisable={!selectedFiles || isLoading}
            />
        </div>
    )
}

import { requestContainer } from "../requestContainer";

export async function getInterrumptedServiceCommunication() {
  const communications = await requestContainer({
    method: "get",
    url: "/backoffice/communications",
  });
  return communications[0] || {};
}

export async function updateInterrumptedServiceCommunication(
  title,
  description,
  isChecked
) {
  return requestContainer({
    url: "/backoffice/communications",
    method: "put",
    data: {
      title: title,
      description: description,
      is_active: isChecked,
    },
  });
}

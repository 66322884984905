import React, { useMemo, useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import fileDownload from "js-file-download";
import {
  DeleteItemModal,
  RenderIfPermissionEnabled,
  Submenu,
  Table,
  TableEmptyState,
  TableSkeleton,
  Tooltip,
} from "../../commons";
import { employeesColumns, filterOptions } from "../../utils";
import { styles } from "./styles";
import {
  EmployeeSearch,
  ExportEmployeesFile,
  GetTotalEmployees,
} from "../../services";
import { PayrollContext, UserContext } from "../../contexts";
import { ExportButton, NewButton } from "../../commons/buttons";
import { payrollScreens } from "../../utils";
import { DeleteIcon, EditIcon, SearchIcon } from "../../assets";
import { permissions } from "../../constants";
import { useChat } from "../../hooks";
import { EmployeeDetails } from "commons/modals/EmployeeDetails";

export const EmployeesScreen = (props) => {
  const columns = useMemo(() => employeesColumns, []);
  const { currentCompany } = useContext(UserContext);
  const [pageIndex, setPageIndex] = useState(1);
  const [employeeIdSelected, setEmployeeIdSelected] = useState("all");
  const [totals, setTotals] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [deleteEmployeeModalIsVisible, setDeleteEmployeeModalIsVisible] =
    useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState();
  const { employees, getEmployees, deleteEmployee } =
    useContext(PayrollContext);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [searchOptions, setSearchOptions] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  useChat();

  useEffect(() => {
    GetTotalEmployees({ employeeId: employeeIdSelected }).then((res) => {
      setTotals(res);
    });
  }, []);

  const getSearchOptions = (query) => {
    EmployeeSearch(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const openDeleteEmployeeModal = (item) => {
    setEmployeeToDelete(item.id);
    setDeleteEmployeeModalIsVisible(true);
  };

  useEffect(() => {
    getEmployees(pageIndex, employeeIdSelected);
  }, [pageIndex, employeeIdSelected]);

  useEffect(() => {
    if (totals) {
      if (totals.total > 100) {
        setTotalPages(Math.ceil(totals.total / 100));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const removeEmployee = (id) => {
    deleteEmployee(id);
    setDeleteEmployeeModalIsVisible(false);
  };

  const openEmployeeDrawer = (id) => {
    setDrawerIsOpen(true);
    setSelectedEmployee(id);
  };
  const openEmptyEmployeeDrawer = () => {
    setDrawerIsOpen(true);
  };

  const handleSearchChange = (employee) => {
    if (employee) {
      setEmployeeIdSelected(employee.id);
    } else {
      setEmployeeIdSelected("all");
    }
    setPageIndex(1);
  };

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onChange={(event, option) => {
          if (option?.id) {
            handleSearchChange(option);
          } else {
            handleSearchChange();
          }
        }}
        onInputChange={(event) => {
          if (event?.target?.value?.length >= 3) {
            getSearchOptions(event.target.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar empleado"}
          />
        )}
      />
    );
  };

  const runExport = async () => {
    await ExportEmployeesFile().then((response) => {
      fileDownload(response, `Empleados_${currentCompany.name}.xls`);
    });
  };

  const renderButtons = (row) => {
    return (
      <>
        <RenderIfPermissionEnabled permission={permissions.PAYROLLS_UPDATE}>
          <Tooltip title="Editar">
            <IconButton onClick={() => openEmployeeDrawer(row.original.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </RenderIfPermissionEnabled>
        <RenderIfPermissionEnabled permission={permissions.PAYROLLS_DELETE}>
          <Tooltip title="Eliminar">
            <IconButton onClick={() => openDeleteEmployeeModal(row.original)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </RenderIfPermissionEnabled>
      </>
    );
  };
  const renderRowEndButtons = (row) => {
    if (isMobile) {
      return <>{renderButtons(row)}</>;
    } else {
      return (
        <td style={{ backgroundColor: "transparent" }}>{renderButtons(row)}</td>
      );
    }
  };

  return (
    <>
      <DeleteItemModal
        itemId={employeeToDelete}
        action={removeEmployee}
        handleClose={() => setDeleteEmployeeModalIsVisible(false)}
        title="¿Quieres borrar este empleado?"
        subtitle="Si lo eliminas, no aparecerá más en Payana"
        visible={deleteEmployeeModalIsVisible}
      />
      <EmployeeDetails
        id={selectedEmployee}
        isOpen={drawerIsOpen}
        handleClose={() => {
          setSelectedEmployee(null);
          setDrawerIsOpen(false);
        }}
      />
      <div>
        <Submenu items={payrollScreens} />
        <div className="layout-container">
          <div style={styles.titleContainer}>
            <Typography sx={styles.title} noWrap>
              Empleados
            </Typography>
            <div style={styles.rowEnd}>
              <ExportButton onClick={() => runExport()} />
              <RenderIfPermissionEnabled
                permission={permissions.PAYROLLS_UPDATE}
              >
                <NewButton
                  action={openEmptyEmployeeDrawer}
                  text="Nuevo empleado"
                />
              </RenderIfPermissionEnabled>
            </div>
          </div>
          {employees.length < 1 ? (
            <TableEmptyState
              title={"Tu empresa aún no tiene empleados cargados"}
              subtitle={"Aquí se mostrarán tus empleados."}
            />
          ) : employees.length >= 1 ? (
            <Table
              columns={columns}
              data={employees}
              section="employees"
              editIcon
              editAction={openEmployeeDrawer}
              setPageIndex={setPageIndex}
              checkboxVisible={false}
              pageCount={totalPages}
              deleteIcon
              openDeleteItemModal={openDeleteEmployeeModal}
              renderAutocomplete={renderAutocomplete}
              renderRowEndButtons={renderRowEndButtons}
            />
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </>
  );
};

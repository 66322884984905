export const boCompanyColumns = [
  {
    field: "id",
    Header: "ID",
    id: "id",
    accessor: "id",
    disableSortBy: true,
  },
  {
    field: "name",
    Header: "Nombre de compañia",
    id: "name",
    accessor: "name",
    disableSortBy: true,
  },
  {
    field: "identification_number",
    Header: "NIT",
    id: "identification_number",
    accessor: "identification_number",
    disableSortBy: true,
  },
];

import { Skeleton } from "@mui/material";
import { styles } from "../styles";

export const ModalSkeleton = () => {
  return (
    <div style={{ maxWidth: 800, margin: "0 auto" }}>
      <Skeleton variant="rectangular" width={"100%"} height={64} />
      <div style={styles.row}>
        {[...Array(3)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={"33%"}
            height={72}
            style={{ marginTop: "12px" }}
          />
        ))}
      </div>
      <div style={styles.rowMarginTop}>
        {[...Array(2)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={"49%"}
            height={80}
            style={{ marginTop: "12px" }}
          />
        ))}
      </div>
      <div style={styles.rowMarginTopBottom}>
        {[...Array(2)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={"49%"}
            height={80}
            style={{ marginTop: "12px" }}
          />
        ))}
      </div>
      {[...Array(3)].map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={"40%"}
          height={24}
          style={styles.fixedRight}
        />
      ))}
    </div>
  );
};

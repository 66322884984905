import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import { DeleteIcon, EditIcon } from "../../../assets";
import { ThirdButton } from "../../../commons/buttons";
import { toastOptions } from "../../../constants";
import { styles } from "../styles";
import { CreateTag, DeleteTag, GetTags, UpdateTag } from "../../../services";
import {
  CreateTagModal,
  DeleteItemModal,
  EditTagModal,
} from "../../../commons";

const TagsEditor = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [tagToDelete, setTagToDelete] = useState();
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);

  useEffect(() => {
    GetTags().then((res) => {
      setTags(res);
    });
  }, []);

  const handleOpenCreateModal = () => setOpenCreateModal(true);
  const handleCloseCreateModal = () => setOpenCreateModal(false);
  const handleOpenEditModal = (tag) => {
    setOpenEditModal(true);
    setSelectedTag(tag);
  };
  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleOpenDeleteModal = (tag) => {
    setOpenDeleteModal(true);
    setTagToDelete(tag);
  };
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const tagExist = (tagName, tags) => tags.some((tag) => tag.name === tagName);

  const addTag = (tag) => {
    if (tagExist(tag, tags)) {
      toast.error("Esta etiqueta ya existe", toastOptions);
    } else {
      setCreateRequestInProcess(true);
      CreateTag({ tag: tag })
        .then(() => {
          GetTags()
            .then((res) => {
              setTags(res);
            })
            .finally(() => {
              setCreateRequestInProcess(false);
              toast.success("La etiqueta se creó con éxito", toastOptions);
              handleCloseCreateModal();
            });
        })
        .catch(() => {
          toast.error("Hubo un error al crear la etiqueta", toastOptions);
        })
        .finally(() => {
          setCreateRequestInProcess(false);
          handleCloseCreateModal();
        });
    }
  };

  const editTag = (tag) => {
    if (tagExist(tag.name, tags)) {
      toast.error("Esta etiqueta ya existe", toastOptions);
    } else {
      setCreateRequestInProcess(true);
      UpdateTag({ id: tag.id, formValues: { name: tag.name } })
        .then(() => {
          GetTags()
            .then((res) => {
              setTags(res);
            })
            .finally(() => {
              setCreateRequestInProcess(false);
              toast.success("La etiqueta se editó con éxito", toastOptions);
              handleCloseEditModal();
            });
        })
        .catch(() => {
          toast.error("Hubo un error al editar la etiqueta", toastOptions);
        })
        .finally(() => {
          setCreateRequestInProcess(false);
          handleCloseEditModal();
        });
    }
  };

  const removeTag = () => {
    DeleteTag({ id: tagToDelete.id })
      .then((res) => {
        GetTags()
          .then((res) => {
            setTags(res);
          })
          .finally(() => {
            toast.success("La etiqueta se eliminó con éxito", toastOptions);
            handleCloseDeleteModal();
          });
      })
      .catch(() => {
        toast.error("Hubo un error al eliminar la etiqueta", toastOptions);
      })
      .finally(() => {
        handleCloseDeleteModal();
      });
  };

  return (
    <Box sx={styles.notificationTable}>
      <CreateTagModal
        visible={openCreateModal}
        handleClose={handleCloseCreateModal}
        addTag={addTag}
        requestInProcess={createRequestInProcess}
      />
      <EditTagModal
        visible={openEditModal}
        handleClose={handleCloseEditModal}
        editTag={editTag}
        requestInProcess={createRequestInProcess}
        selectedTag={selectedTag}
      />
      <DeleteItemModal
        itemId={tagToDelete}
        action={removeTag}
        handleClose={() => setOpenDeleteModal(false)}
        title="¿Quieres borrar esta etiqueta?"
        subtitle="Si la eliminas, no aparecerá más en Payana y se borrará de los documentos asociados"
        visible={openDeleteModal}
      />
      <Box sx={styles.notificationContainer}>
        {tags.length > 0 && (
          <Box sx={styles.tableContainer}>
            {tags.map((tag) => (
              <Box key={tag.id}>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  my={1}
                >
                  <Typography sx={styles.emailField}>{tag.name}</Typography>
                  <div>
                    <IconButton onClick={() => handleOpenEditModal(tag)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteModal(tag)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Stack>
                <Divider />
              </Box>
            ))}
          </Box>
        )}
        <ThirdButton
          text="Nueva etiqueta"
          width="150px"
          action={handleOpenCreateModal}
        />
      </Box>
    </Box>
  );
};

export default TagsEditor;

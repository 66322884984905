export const subMenuItems = [
  {
    name: "Cuentas por pagar",
    to: "/services/payments",
  },
  {
    name: "Lotes",
    to: "/services/transactions",
  },
  {
    name: "Agenda",
    to: "/services/agenda",
  },
];

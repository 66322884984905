import {
  CreateCollectionTransaction,
  CreateTransaction,
  CreateTransactionToPay,
} from "../../../services";

export const getTransactionToPay = async ({
  trxId,
  selectedRowsIds,
  type,
  sourceOfFundsId,
  paymentGateway,
  paymentMethod,
  section,
  ...otherProps
}) => {
  if (trxId) {
    const payload = {
      id: trxId,
      payment_gateway: paymentGateway,
      payment_method: paymentMethod,
      source_of_funds_id: sourceOfFundsId,
      ...otherProps,
    };
    return CreateTransactionToPay(payload).then((res) => ({
      ...res.data,
      integrity_key: res.integrity_key,
    }));
  } else {
    const payload = {
      ids: selectedRowsIds,
      type,
      source_of_funds_id: sourceOfFundsId,
      payment_gateway: paymentGateway,
      payment_method: paymentMethod,
      ...otherProps,
    };
    if (section === "customer") {
      return CreateCollectionTransaction(payload).then((res) => ({
        ...(res.data || res.response.data),
        integrity_key: res.integrity_key,
      }));
    } else {
      return CreateTransaction(payload).then((res) => ({
        ...(res.data || res.response.data),
        integrity_key: res.integrity_key,
      }));
    }
  }
};

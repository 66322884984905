import React from "react";
import { Tooltip } from "./Tooltip";

export const Circle = ({
  title,
  placement,
  offset,
  fill,
  style,
  className = "",
}) => {
  return (
    <Tooltip title={title} placement={placement} offset={offset}>
      <div
        style={{
          width: "12px",
          height: "12px",
          backgroundColor: fill,
          borderRadius: "50%",
          ...style,
        }}
        className={className}
      ></div>
    </Tooltip>
  );
};

import React from "react";
import banner from "./referralBanner.png";

export const ReferralBannerImage = ({ width = "100%", height = 110 }) => (
  <img
    src={banner}
    width={width}
    height={height}
    alt="Referidos"
    style={{ position: "relative" }}
  />
);

import { createContext, useContext } from "react";
import { UserContext } from "./UserContext";
import { CompanySwitchModal } from "../commons";

export const CompanySwitchContext = createContext();

export function CompanySwitchProvider({ children }) {
  const { isCompanySwitchModalVisible, setIsCompanySwitchModalVisible } =
    useContext(UserContext);

  return (
    <CompanySwitchContext.Provider value={{ isCompanySwitchModalVisible }}>
      <CompanySwitchModal
        onClose={() => setIsCompanySwitchModalVisible(false)}
        visible={isCompanySwitchModalVisible}
      />
      {children}
    </CompanySwitchContext.Provider>
  );
}

export const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    color: "#FAFAFF",
    fontSize: "18px",
    fontWeight: "700",
    textAlign: "center",
    backgroundColor: "#5925DC",
    padding: "8px 10px 8px 10px",
    borderRadius: "72px",
    marginTop: "16px",
  },
  title: {
    color: "#1D2939",
    fontSize: "18px",
    fontWeight: "700",
    marginTop: "12px",
    textAlign: "left",
    alignSelf: "flex-start",
    minWidth: "352px",
    maxWidth: "352px",
  },
  subtitle: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "left",
    alignSelf: "flex-start",
    minWidth: "352px",
    maxWidth: "352px",
  },
  invoiceNumber: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "600",
    minWidth: "100px",
    maxWidth: "200px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
    justifyContent: "flex-end",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    justifyContent: "center",
  },
  modalSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
    marginRight: "12px",
  },
  modalPrimaryButton: {
    backgroundColor: "#D92D20",
    border: "1px solid #6938EF",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
  },
  radioButtonContainer: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    padding: "8px",
    alignItems: "center",
    marginBottom: "12px",
    justifyContent: "flex-start",
    width: "100%",
    cursor: "pointer",
    height: "72px",
  },
  radioButtonSelectedContainer: {
    display: "flex",
    flexDirection: "row",
    height: "72px",
    border: "1px solid #7F56D9",
    borderRadius: "8px",
    padding: "8px",
    alignItems: "center",
    marginBottom: "12px",
    justifyContent: "flex-start",
    backgroundColor: "#F2F3FF",
    width: "100%",
    cursor: "pointer",
  },
  radioTitle: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
  },
  radioSelectedTitle: {
    color: "#53389E",
    fontSize: "14px",
    fontWeight: "500",
  },
  radioText: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    maxWidth: "500px",
  },
  radioSelectedText: {
    color: "#7F56D9",
    fontSize: "14px",
    fontWeight: "400",
    maxWidth: "500px",
  },
  radioTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "8px",
    marginRight: "8px",
    alignItems: "center",
  },
};

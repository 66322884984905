import { Tooltip } from "commons/components";
import { NumericFormat } from "react-number-format";

export const invoicesExpensesColumns = () => {
  return [
    {
      field: "invoice_number",
      headerName: "Documento",
      sortable: false,
      headerAlign: "center",
      width: 122,
    },
    {
      field: "amount",
      headerName: "Monto",
      sortable: false,
      headerAlign: "center",
      width: 134,
      renderCell: ({ row }) => {
        return (
          <div class="MuiDataGrid-cellContent" title="67" role="presentation">
            <NumericFormat
              value={row.amount_total}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </div>
        );
      },
    },
    {
      field: "tag",
      headerName: "Etiquetas",
      sortable: false,
      headerAlign: "center",
      flex: 1,
      renderCell: ({ row }) => {
        if (row?.tags.length > 0) {
          const text = row.tags.map((tag) => tag.name).join(" - ");
          return (
            <Tooltip title={text}>
              <p
                style={{
                  margin: "0px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {text}
              </p>
            </Tooltip>
          );
        }
      },
    },
  ];
};

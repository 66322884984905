import { Box, Dialog } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const CustomPaper = ({ children }) => {
  return (
    <Box sx={{ background: "#fff", borderRadius: "0.5rem" }}>{children}</Box>
  );
};

function ModalShell({ children, visible, onClose }) {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  return (
    <Dialog open={visible} onClose={onClose} PaperComponent={CustomPaper}>
      <Box
        style={{
          padding: 24,
          maxWidth: isMobile ? 380 : 440,
          minWidth: isMobile ? 380 : 440,
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
}

export default ModalShell;

import * as amplitude from "@amplitude/analytics-browser";
import { APP_ENV_PRODUCTION } from "constants";
import { useMediaQuery } from "react-responsive";

export const useAmplitude = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const formatUserId = (user) => {
    const length = user.id.toString().length;
    const prefix = {
      1: "0000",
      2: "000",
      3: "00",
      4: "0",
    };
    return `${prefix[length] || ""}${user.id.toString()}`;
  };

  const setAmplitudeProps = (user) => {
    if (process.env.REACT_APP_ENVIRONMENT !== APP_ENV_PRODUCTION) {
      return;
    }
    amplitude.setUserId(formatUserId(user));
    const userProperties = new amplitude.Identify();
    userProperties.set("Role", user.role.name);
    userProperties.set("Company ID", user.company.id);
    userProperties.set("Device Type", isMobile ? "mobile" : "desktop");
    userProperties.set("Suscription", !!user.company.suscribed);
    userProperties.set("Causation", !!user.company.isCausationEnabled);
    userProperties.set("Assisted Egress", !!user.company.hasOneAssistedEgress);
    userProperties.set(
      "Transactional Limit",
      !!user.company.hasTransactionsLimit
    );
    amplitude.identify(userProperties);
  };

  return { setAmplitudeProps };
};

import { requestContainer } from "../requestContainer";

export const fetchInvoicesNotes = (invoiceId) =>
  requestContainer({
    method: "get",
    url: `/invoices/${invoiceId}/notes`,
  }).then((res) =>
    res.data.map((note) => ({
      id: note.id,
      date: note.created_at,
      authorEmail: note.user?.email,
      comment: note.comment,
    }))
  );

export const saveInvoiceNote = async ({
  entity_type,
  entity_id,
  comment,
  invoiceId,
}) =>
  requestContainer({
    method: "post",
    url: "/notes",
    data: invoiceId
      ? {
          comment,
          invoice_id: invoiceId,
        }
      : {
          entity_type,
          entity_id,
          comment,
        },
  });

export const deleteNote = async (invoiceNote) =>
  requestContainer({
    method: "delete",
    url: `/notes/${invoiceNote.id}`,
  });

export const getNotesBO = async ({ entity, id }) =>
  requestContainer({
    method: "get",
    url: `/notes/${entity}/${id}`,
  });

export const fetchCollectionsNotes = (collectionId) =>
  requestContainer({
    method: "get",
    url: `/notes/collection/${collectionId}`,
  }).then((res) =>
    res.data.map((note) => ({
      id: note.id,
      date: note.created_at,
      authorEmail: note.user?.email,
      comment: note.comment,
    }))
  );

export const saveCollectionNote = async ({ comment, collectionId }) =>
  requestContainer({
    method: "post",
    url: "/notes",
    data: collectionId
      ? {
          comment,
          collection_id: collectionId,
          entity_type: "collection",
          entity_id: collectionId,
        }
      : {
          entity_type: "collection",
          entity_id: collectionId,
          comment,
        },
  });

import React from "react";
import { Box, Dialog, Stack } from "@mui/material";
import { ModalWarningIcon } from "../../../assets";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { useMediaQuery } from "react-responsive";
import styles from "./limit-exceeded-modal.module.css";

export const LimitExceededModal = ({ onClose, data }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const { visible, availableAmount, limitAmount } = data;
  let formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const goToSupport = () => {
    onClose();
    window.Intercom(
      "showNewMessage",
      `¡Hola! Solicito su ayuda para habilitar mi cuenta y operar sin el límite de COP ${formatAmount
        .format(limitAmount)
        .replace("$", "")
        .trim()}`
    );
  };

  return (
    <Dialog onClose={onClose} open={!!visible} sx={{ borderRadius: "16px" }}>
      <Box
        style={{
          padding: 24,
          width: isMobile ? 330 : 400,
        }}
      >
        <div className={styles["icon-container"]}>
          <ModalWarningIcon />
        </div>
        <p className={styles["title"]}>Alcanzaste el monto máximo permitido</p>
        <div className={styles["description"]}>
          El pago excede el máximo permitido de COP{" "}
          {formatAmount.format(limitAmount).replace("$", "").trim()} para operar
          en Payana sin presentar documentación.
        </div>
        <div className={styles["description"]}>
          Contáctate con soporte para habilitar tu cuenta.
        </div>
        <div className={styles["available"]}>
          Tienes disponibles COP{" "}
          {formatAmount.format(availableAmount).replace("$", "").trim()}
        </div>
        <Stack className={styles["button-container"]}>
          <SecondaryButton
            text="Cancelar"
            action={onClose}
            style={{ width: "100%" }}
          />
          <PrimaryButton
            text="Hablar a soporte"
            action={goToSupport}
            style={{ width: "100%" }}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};

import React from "react";

export const PaginationDotIcon = ({ step = 0 }) => (
  <svg width="48" height="8" viewBox="0 0 48 8" fill="none">
    <rect
      width="8"
      height="8"
      rx="4"
      fill={step === 0 ? "#5522FF" : "#F2F4F7"}
    />
    <g>
      <rect
        x="20"
        width="8"
        height="8"
        rx="4"
        fill={step === 1 ? "#5522FF" : "#F2F4F7"}
      />
    </g>
    <g>
      <rect
        x="40"
        width="8"
        height="8"
        rx="4"
        fill={step === 2 ? "#5522FF" : "#F2F4F7"}
      />
    </g>
  </svg>
);

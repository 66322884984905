import React, { useEffect, useState } from "react";
import { ButtonOpenInputs } from "./ButtonOpenInputs";
import { InputsSelect } from "./InputsSelect";
import { getFromLocalStorage } from "utils";

export const MultipleSelect = ({
  products,
  taxesIva,
  taxesRete,
  getSearchOptionsProducts,
  handleChangeMultipleSelect,
  numberItemSelected,
  hasCompanyWareHouse,
}) => {
  const [showInputs, setShowInputs] = useState(false);

  useEffect(() => {
    if (
      numberItemSelected ||
      JSON.parse(getFromLocalStorage("showMultipleSelectTutorial")) === null
    ) {
      return setShowInputs(true);
    }

    setShowInputs(false);
  }, [numberItemSelected]);

  return !showInputs ? (
    <ButtonOpenInputs />
  ) : (
    <InputsSelect
      hasCompanyWareHouse={hasCompanyWareHouse}
      numberItemSelected={numberItemSelected}
      products={products}
      taxesIva={taxesIva}
      taxesRete={taxesRete}
      getSearchOptionsProducts={getSearchOptionsProducts}
      handleChangeMultipleSelect={handleChangeMultipleSelect}
    />
  );
};

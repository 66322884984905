import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const CollectionsRedirectScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/collections/index");
  });
  return null;
};

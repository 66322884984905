import { requestContainer } from "../requestContainer";

const saveRetention = (retentions) =>
  requestContainer({
    url: "/companies/retentions",
    method: "put",
    data: {
      retentions,
    },
  });

export { saveRetention };

const isSupplierIncomplete = (row) => {
  return (
    !row.original.provider_name ||
    !row.original.provider_document_type ||
    !row.original.provider_document_number ||
    !row.original.provider_bank_name ||
    (!row.original.provider_account_type &&
      row.original.provider_bank_name.toLowerCase() !== "nequi" &&
      row.original.provider_bank_name.toLowerCase() !== "daviplata") ||
    !row.original.provider_account_number
  );
};

export default isSupplierIncomplete;

import { useState } from "react";
import { Dialog } from "@mui/material";
import CsvHandler from "commons/modals/UploadFileModal/CsvHandler";
import { MassiveCreateDebtList } from "../MassiveCreateDebtList";
import MassiveCreateFailedModal from "../MassiveCreateFailedModal";
import { getFailedResults } from "./getFailedResults";
import { getSucceededResults } from "./getFailedResults";

export const MassiveCreateResultModal = ({
  csvImportButton,
  onClose = () => {},
}) => {
  const [items, setItems] = useState([]);
  const errors = getFailedResults(items);
  const results = getSucceededResults(items);
  const isVisibleMassiveCreateSuccessList =
    errors.length === 0 && results.length > 0;
  const isVisibleMassiveCreateFailedResult = errors.length > 0;
  const openDialog =
    isVisibleMassiveCreateSuccessList || isVisibleMassiveCreateFailedResult;
  const setResponseItems = ({ results }) => setItems(results);
  return (
    <>
      <CsvHandler
        entity="service"
        licenseKey={process.env.REACT_APP_CSVBOX_SERVICES_LICENSE_KEY}
        buttonRef={csvImportButton}
        openResultModal={setResponseItems}
      />
      <Dialog maxWidth={false} fullScreen open={openDialog} scroll="paper">
        {isVisibleMassiveCreateSuccessList && (
          <MassiveCreateDebtList
            onClose={() => {
              setItems([]);
              onClose();
            }}
            debts={results}
          />
        )}
        {isVisibleMassiveCreateFailedResult && (
          <MassiveCreateFailedModal
            items={errors}
            primaryAction={() => setItems(results)}
          />
        )}
      </Dialog>
    </>
  );
};

import { useContext } from "react";
import { UserContext } from "../contexts";

export const IsUserAllowedTo = (permission) => {
  const { currentUserPermissions } = useContext(UserContext);
  return currentUserPermissions[permission]?.enabled;
};
export const useIsUserAllowedTo = () => {
  const { currentUserPermissions } = useContext(UserContext);
  const isUserAllowedTo = (permission) => {
    return currentUserPermissions[permission]?.enabled;
  };
  return {
    isUserAllowedTo,
  };
};

import React from "react";
import { styles } from "../styles";
import { ReconciliationRegisterEmpty } from "assets";
import { Typography } from "@mui/material";

export const AccountingRegisterEmptyState = () => {
  return (
    <div style={styles.accountingRegisterEmptyContainer}>
      <ReconciliationRegisterEmpty />
      <Typography sx={styles.accountingRegisterEmptyText}>
        Selecciona una transacción bancaria para comenzar
      </Typography>
    </div>
  );
};

import React from "react";

export function Dot({
  color = "#7F56D9",
  height = "32",
  width = "32",
  circle = true,
}) {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_14608_1055)">
        <g clipPath="url(#clip0_14608_1055)">
          {circle && (
            <rect x="4" y="4.5" width="24" height="24" rx="12" fill="#F2F3FF" />
          )}
          {circle && (
            <rect x="4" y="4.5" width="24" height="24" rx="12" fill="#F2F3FF" />
          )}
          <circle cx="16" cy="16.5" r="4" fill={color} />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_14608_1055"
          x="0"
          y="0.5"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_14608_1055"
          />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.921569 0 0 0 0 0.913725 0 0 0 0 0.996078 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_14608_1055"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14608_1055"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_14608_1055">
          <rect x="4" y="4.5" width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

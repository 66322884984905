import React, { useState, useEffect } from "react";
import { Box, TextField, Dialog } from "@mui/material";
import { Spinner } from "react-bootstrap";
import { PrimaryButton, SecondaryButton } from "../../buttons";

import { styles } from "./styles";

export const CreateTagModal = ({
  visible,
  handleClose,
  addTag,
  requestInProcess,
}) => {
  const [tag, setTag] = useState("");

  useEffect(() => {
    setTag("");
  }, [visible]);

  return (
    <Dialog onClose={handleClose} open={visible}>
      <Box sx={styles.container}>
        <p style={styles.title}>Agregar una nueva etiqueta</p>
        <Box sx={styles.form}>
          <TextField
            sx={styles.input}
            size="small"
            variant="outlined"
            value={tag}
            required={true}
            onChange={(e) => setTag(e.target.value)}
          />
        </Box>
        <div style={styles.row}>
          <SecondaryButton width="200px" text="Cancelar" action={handleClose} />
          <PrimaryButton
            width="200px"
            isDisable={tag === ""}
            text={
              requestInProcess ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Crear"
              )
            }
            action={() => addTag(tag)}
          />
        </div>
      </Box>
    </Dialog>
  );
};

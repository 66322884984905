import { CustomLink } from "commons";
import React from "react";

export const SubmenuSubscription = ({ items, handleChangeView, view }) => {
  const styleSelected = {
    color: "#6927da",
    backgroundColor: "#f5f3ff",
  };
  return (
    <nav
      className="submenu"
      style={{ marginLeft: "2.5%", marginRight: "2.5%" }}
    >
      <div className="layout-container">
        <div className="submenuDiv">
          <ul>
            {items.map((item, i) => (
              <li
                key={i}
                style={{
                  textDecoration: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "20px",
                  padding: "8px 12px",
                  borderRadius: "6px",
                  ...(view === item.view
                    ? styleSelected
                    : { cursor: "pointer" }),
                }}
                onClick={() => handleChangeView(item.view)}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

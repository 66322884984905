import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import Switch from "react-ios-switch";
import { styles } from "../styles";
import { documentTypeINITIAL, searchType } from "../constants";
import { SiigoLogo, UpdateIcon } from "assets";
import { ButtonWithIcon, SyncBanner } from "commons/components";
import * as CausationService from "../../../../services/api/causation";
import FileUpload from "commons/modals/ExpandInvoiceModal/components/FileUpload";
import { CausationContext } from "contexts";
import { formatLocaleDate, handleSyncErpResponse } from "utils";
import { Spinner } from "react-bootstrap";
import { checkSiigoCredential, syncCausation } from "services";

export function Form({
  formValues,
  search,
  checked,
  handleOnChange,
  handleOnChangeAutoComplete,
  getSearchOptionsSupplier,
  setFormValues,
  setChecked,
  isCaused,
  hasFilePath = false,
  selectedFiles,
  setSelectedFiles,
  id,
  lastSyncCausationAt = null,
  supplierFound = false,
  setSupplierFound = () => {},
}) {
  const { causationScreen } = useContext(CausationContext);
  const [syncingSiigo, setSyncingSiigo] = useState(false);
  const [lastSyncDate, setLastSyncDate] = useState(lastSyncCausationAt);

  const syncBanner = causationScreen?.syncBanner?.value;
  const setSyncBanner = causationScreen?.syncBanner?.setter;

  const havePaymentDueDate =
    formValues?.paymentType?.due_type === -1 ? true : false;

  const haveCenterCost = formValues.documentType?.use_cost_center
    ? true
    : false;

  const updateSiigoSupplier = async () => {
    if (formValues.supplier.document_number) {
      const res = await CausationService.search(
        searchType.supplier,
        formValues.supplier.document_number
      );
      if (res.at(0)?.id) {
        await handleOnChangeAutoComplete("supplier", res.at(0));
      }
      const storedSupplier = await CausationService.searchErpSupplier({
        documentNumber: formValues.supplier.document_number,
      });
      setSupplierFound(storedSupplier);
    }
  };

  const onSuccess = () => {
    setSyncingSiigo(false);
    checkSiigoCredential().then((res) => {
      setLastSyncDate(res?.syncValidation?.lastSyncCausationAt);
      updateSiigoSupplier();
    });
  };

  useEffect(() => {
    updateSiigoSupplier();
  }, []);

  const syncSiigo = () => {
    setSyncingSiigo(true);
    syncCausation().then((res) => {
      handleSyncErpResponse(res, onSuccess, setSyncBanner);
      setSyncingSiigo(false);
    });
  };

  return (
    <Box sx={{ ...styles.container, paddingLeft: checked ? "32px" : "" }}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <div
            style={{
              background: "#FFFFFF",
              padding: "4px 6px 4px 14px",
              borderRadius: "16px",
              border: "1px solid #EAECF0",
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              width: "-webkit-fill-available",
              justifyContent: "space-between",
              alignItems: "center",
              height: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
              }}
            >
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "8px",
                }}
              >
                <SiigoLogo style={{ marginBottom: "2px" }} />
              </div>
              {lastSyncDate && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "#667085",
                      marginTop: "2px",
                    }}
                  >
                    Última actualización
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "#1D2939",
                      marginTop: "2px",
                    }}
                  >
                    {formatLocaleDate(lastSyncDate, "d MMM yyyy, HH:mm")}
                  </Typography>
                </div>
              )}
            </div>
            {syncingSiigo ? (
              <Spinner
                style={{ marginRight: "8px" }}
                animation="border"
                size="sm"
                variant="primary"
              />
            ) : (
              <ButtonWithIcon
                buttonLabel={"Actualizar"}
                endIcon={<UpdateIcon />}
                width="fit-content"
                style={{
                  height: "28px",
                  borderRadius: "16px",
                  padding: "4px 12px 4px 12px",
                }}
                action={() => {
                  setSyncingSiigo(true);
                  syncSiigo();
                }}
              />
            )}
          </div>
          {syncBanner.visible && (
            <SyncBanner
              title={syncBanner.title}
              description={syncBanner.description}
              type={syncBanner.type}
              setSyncBanner={setSyncBanner}
              customStyle={{ marginLeft: "0px", marginRight: "0px" }}
            />
          )}
        </Stack>
        <Stack spacing={1}>
          <label htmlFor="documentType" style={styles.inputLabel}>
            Tipo de documento
          </label>
          <Select
            name="documentType"
            size="small"
            variant="outlined"
            displayEmpty={true}
            onChange={handleOnChange}
            style={{ fontSize: "14px" }}
            value={formValues.documentType}
            inputProps={{
              id: "documentType",
            }}
            disabled={isCaused}
          >
            <MenuItem
              value={documentTypeINITIAL}
              style={{ display: "none" }}
            ></MenuItem>
            {search.invoiceType.map((type) => (
              <MenuItem key={type.id} value={type}>
                {type.erp_doc_class} -{" "}
                {type?.internal_description || type?.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack spacing={1}>
          <label htmlFor="supplier" style={styles.inputLabel}>
            Proveedor
          </label>
          <Autocomplete
            disabled={isCaused}
            name="supplier"
            size="small"
            options={search.supplier}
            freeSolo
            getOptionLabel={(option) => option?.full_name || ""}
            onChange={(event, value) => {
              handleOnChangeAutoComplete("supplier", value);
              setSupplierFound(true);
            }}
            onInputChange={(event) => {
              getSearchOptionsSupplier("supplier", event?.target?.value);
              setSupplierFound(true);
            }}
            filterOptions={(options, { inputValue }) => {
              const inputValueLower = inputValue.toLowerCase();
              return options.filter(
                (option) =>
                  option.full_name.toLowerCase().includes(inputValueLower) ||
                  option.identification.toString().includes(inputValueLower)
              );
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?.id}>
                  {option.full_name}
                </li>
              );
            }}
            value={formValues.supplier}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14 },
                }}
                placeholder={"Ingresa el nombre"}
              />
            )}
          />
          {!formValues.supplier?.id && !formValues.supplier?.full_name && (
            <Typography
              style={{
                color: "#667085",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Si no encuentras el proveedor, cárgalo en Siigo y vuelve a
              actualizar.
            </Typography>
          )}
          {formValues.supplier?.full_name && !supplierFound && (
            <Typography
              style={{
                color: "#F04438",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              No encontramos el proveedor en Siigo, créalo y vuelve a
              actualizar.
            </Typography>
          )}
        </Stack>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          spacing={1}
          sx={{
            "& .MuiGrid-item": { paddingRight: "8px", paddingLeft: "0px" },
          }}
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <label htmlFor="nroComprobante" style={styles.inputLabel}>
                Número de comprobante
              </label>
              <TextField
                disabled={isCaused}
                id="nroComprobante"
                name="nroComprobante"
                size="small"
                variant="outlined"
                onChange={handleOnChange}
                value={formValues.nroComprobante}
                InputProps={{
                  style: { fontSize: 14 },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <label htmlFor="costCenter" style={styles.inputLabel}>
                Centro de costos
              </label>
              <Autocomplete
                disabled={!haveCenterCost || isCaused}
                name="costCenter"
                size="small"
                options={search.costCenter}
                freeSolo
                getOptionLabel={(option) =>
                  option?.code && option?.name
                    ? `${option.code} - ${option.name}`
                    : ""
                }
                onChange={(event, value) =>
                  handleOnChangeAutoComplete("costCenter", value)
                }
                onInputChange={(event) => {
                  getSearchOptionsSupplier("costCenter", event?.target?.value);
                }}
                filterOptions={(options, { inputValue }) => {
                  const inputValueLower = inputValue.toLowerCase();
                  return options.filter(
                    (option) =>
                      option.name.toLowerCase().includes(inputValueLower) ||
                      option.code.toString().includes(inputValueLower)
                  );
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option?.id}>
                      {option?.code} - {option?.name}
                    </li>
                  );
                }}
                sx={{
                  background: !formValues.documentType?.use_cost_center
                    ? "#F9FAFB"
                    : "",
                }}
                value={formValues.costCenter}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: 14 },
                    }}
                    placeholder={"Ingresa el centro de costo"}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          spacing={1}
          sx={{
            "& .MuiGrid-item": { paddingRight: "8px", paddingLeft: "0px" },
          }}
        >
          <Grid item xs={12} md={havePaymentDueDate ? 4 : 6}>
            <Stack spacing={1}>
              <label htmlFor="dateElaboration" style={styles.inputLabel}>
                Fecha de elaboración
              </label>
              <LocalizationProvider
                adapterLocale={es}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  id="dateElaboration"
                  name="dateElaboration"
                  value={formValues.dateElaboration}
                  format="dd/MM/yyyy"
                  onChange={(value) =>
                    setFormValues((prev) => ({
                      ...prev,
                      dateElaboration: value,
                    }))
                  }
                  slotProps={{
                    textField: {
                      InputProps: {
                        style: { fontSize: 14 },
                      },
                      size: "small",
                      error: false,
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
        </Grid>
        {id ? (
          <Box sx={styles.switchContainer}>
            <Switch
              checked={checked}
              className={undefined}
              disabled={!hasFilePath}
              handleColor="white"
              name={undefined}
              offColor="#D0D5DD"
              onChange={() => {
                setChecked(!checked);
              }}
              onColor="#7F56D9"
              pendingOffColor={undefined}
              pendingOnColor={undefined}
              style={{ transform: "scale(0.7)", fontSize: "14px" }}
            />
            <span style={styles.switchText}>Visualizar archivo</span>
          </Box>
        ) : (
          <Box>
            <FileUpload
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              isInvoiceFile={true}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
}

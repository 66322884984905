import { Button } from "@mui/material";
import { FilterIcon } from "assets";
import { styles } from "../styles";

export const CausationFilterButton = ({ action, text, open }) => {
  return (
    <Button
      id="causation-filter-button"
      aria-controls={open && "basic-menu"}
      aria-haspopup="true"
      aria-expanded={open && "true"}
      onClick={action}
      style={styles.causationButton}
    >
      <FilterIcon />
      <div style={styles.causationButtonTextContainer}>
        <span style={styles.causationButtonText}>{text}</span>
      </div>
    </Button>
  );
};

import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import { typePaymentOnChange } from "../../constants";

export const InputDate = ({ payment, handleOnChangePayment, index }) => {
  return (
    <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={
          payment?.accounting_concept === "" || payment?.data?.due_type !== -1
        }
        value={payment?.data?.dueDate || null}
        name="paymentDueDate"
        format="dd/MM/yyyy"
        onChange={(value) =>
          handleOnChangePayment(index, value, typePaymentOnChange.dueDate)
        }
        slotProps={{
          textField: {
            InputProps: {
              style: { fontSize: 14 },
            },
            size: "small",
            error: false,
          },
        }}
        sx={{
          backgroundColor: "#FFF",
          width: "100%",
        }}
      />
    </LocalizationProvider>
  );
};

export const handleFormattedReceiverResponse = (res) => ({
  name: res?.name || "",
  documentType: res?.document_type || "",
  documentNumber: res?.document_number || "",
  email: res?.email || "",
  phone_number: res?.phone_number || "",
  bankId: res?.bank_id || "",
  accountType: res?.account_type || "",
  accountNumber: res?.account_number || "",
  // accountHolderName: res?.account_holder_name || "",
  // accountHolderDocumentType: res?.account_holder_document_type || "",
  // accountHolderDocumentNumber: res?.account_holder_document_number || "",
});

export const handleFormattedReceiverForm = (formValues) => ({
  name: formValues?.name || "",
  document_type: formValues?.documentType || "",
  document_number: formValues?.documentNumber || "",
  email: formValues?.email || "",
  phone_number: formValues?.phone_number || "",
  bank_id: formValues?.bankId || "",
  account_type: formValues?.accountType || "",
  account_number: formValues?.accountNumber || "",
  // account_holder_name: formValues?.accountHolderName || "",
  // account_holder_document_type: formValues?.accountHolderDocumentType || "",
  // account_holder_document_number: formValues?.accountHolderDocumentNumber || "",
});

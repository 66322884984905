import React from "react";
import { TabPanel } from "@mui/lab";
import { styles } from "../../../Table/styles";
import { InputAdornment, TextField } from "@mui/material";

export const PartialPayment = ({ value, amount, setPartialAmount }) => {
  const handleChange = (event) => {
    setPartialAmount(event.target.value);
  };

  return (
    <TabPanel value={value}>
      <p style={styles.tabPanelTitle}>Pago parcial</p>
      <div className="input-group mb-3">
        <TextField
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputProps: { min: 0, max: amount },
          }}
          placeholder="0"
          onChange={handleChange}
          sx={{
            width: "97%",
            "& fieldset": {
              borderRadius: "8px",
            },
          }}
        />
      </div>
      <p style={styles.tabPanelText}>
        Al apretar Confirmar, se cambiará el importe de esta factura y se creará
        otra con el importe restante.
      </p>
    </TabPanel>
  );
};

import React, { useContext, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { Modal, Spinner } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { RequiredLabel, ValidationMessage } from "commons/components";
import { useForm, useWatch } from "react-hook-form";
import { UserContext } from "contexts";
import { markAsReconciled } from "services";
import { storeType } from "screens/ReconciliationsScreen/utils";

export const MarkAsReconciledModal = ({
  visible,
  setIsMarkAsReconciledModalVisible,
  selectedMovement,
  accountingType,
  resetAccounting,
  setSelectedMovement,
  setReconciliationOption,
  setStatusSelected,
}) => {
  const { currentUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState, reset, control } = useForm({
    defaultValues: { accountingReceiptNumber: "", comments: "" },
    mode: "onBlur",
  });

  const formValues = useWatch({ control });

  const submitForm = handleSubmit(async (formValues) => {
    setLoading(true);
    markAsReconciled({
      reconciliationMovementId: selectedMovement.id,
      accountingReceiptNumber: formValues.accountingReceiptNumber,
      amountRegistered:
        selectedMovement.amount < 0
          ? selectedMovement.amount * -1
          : selectedMovement.amount,
      type: storeType[accountingType],
      comments: formValues.comments,
      ...(selectedMovement?.receipt_id && {
        receiptId: selectedMovement.receipt_id,
      }),
    }).then(() => {
      resetAccounting();
      setSelectedMovement({});
      setReconciliationOption("not_reconciled");
      setStatusSelected("not_reconciled");
      amplitude.logEvent("SET_AS_RECONCILIATED", {
        user_id: currentUser.id,
      });
      reset({ accountingReceiptNumber: "", comments: "" });
      setLoading(false);
      setIsMarkAsReconciledModalVisible(false);
    });
  });

  return (
    <Modal
      show={visible}
      onHide={() => setIsMarkAsReconciledModalVisible(false)}
      centered
    >
      <Modal.Body style={{ boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)" }}>
        <form onSubmit={submitForm} style={{ height: "100%" }}>
          <Box sx={styles.container}>
            <p style={styles.title}>Marcar movimiento como conciliado</p>
            <p style={styles.subtitle}>
              El estado será “Marcado como conciliado”
            </p>
            <div
              style={{
                backgroundColor: "#F9FAFB",
                padding: "16px",
                borderRadius: "8px",
                gap: "12px",
                width: "100%",
                alignSelf: "flex-start",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <RequiredLabel
                label="Número de comprobante"
                sx={styles.inputLabel}
              />
              <TextField
                id="accountingReceiptNumber"
                sx={{ width: "100%" }}
                size="small"
                disabled={loading}
                variant="outlined"
                placeholder="RP-1-1111"
                value={formValues.accountingReceiptNumber}
                {...register("accountingReceiptNumber", { required: true })}
                InputProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: "#FFFFFF",
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  cursor: "pointer",
                  color: "#667085",
                }}
              >
                Ingresa el nro. de comprobante de Siigo.
              </Typography>
              <ValidationMessage
                errors={formState.errors}
                field="accountingReceiptNumber"
              />
              <label htmlFor="comments" style={styles.inputLabel}>
                Comentarios
              </label>
              <TextField
                id="comments"
                sx={{ width: "100%" }}
                size="small"
                disabled={loading}
                variant="outlined"
                placeholder="Ejemplo: Se concilió el mes pasado"
                value={formValues.comments}
                {...register("comments", { required: false })}
                InputProps={{
                  style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                }}
              />
            </div>
            <Stack
              direction={"row"}
              spacing={1}
              pt={4}
              justifyContent="space-between"
              sx={{ width: "100%", alignSelf: "center" }}
            >
              <SecondaryButton
                text="Cancelar"
                action={() => setIsMarkAsReconciledModalVisible(false)}
                width="100%"
              />
              <PrimaryButton
                text={
                  loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Confirmar"
                  )
                }
                disabled={loading}
                type="submit"
                width="100%"
              />
            </Stack>
          </Box>
        </form>
      </Modal.Body>
    </Modal>
  );
};

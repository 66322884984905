import React from "react";

export const PayanaLogo = ({ width = 144, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 144 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9382 22.1835L21.4195 13.5725C22.1553 11.008 21.8056 9.72949 20.3971 9.72949H9.30845L6.38901 19.514C5.85255 21.3004 6.65122 22.1835 8.74893 22.1835H18.9382Z"
      fill="#5925DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.797 0.388947C36.938 0.388947 37.7343 1.27214 37.2027 3.05854L32.2904 19.5139C31.7539 21.3003 30.4356 22.1835 28.3018 22.1835H18.9382L21.4195 13.5725C22.1553 11.008 21.8056 9.72949 20.3971 9.72949H9.30842L11.2989 3.05854C11.8353 1.27214 13.1729 0.388947 15.2874 0.388947H34.797ZM9.30842 9.72949H7.67437C6.23915 9.72949 5.17062 11.008 4.4348 13.5725L0.371984 27.6636C-0.36384 30.2431 -0.00199948 31.5241 1.40408 31.5241H14.1195C15.5547 31.5241 16.6402 30.2431 17.3591 27.6636L18.9382 22.1835H8.7489C6.6512 22.1835 5.85253 21.3003 6.38898 19.5139L9.30842 9.72949Z"
      fill="#9B8AFB"
    />
    <path
      d="M48.1362 7.33821C48.5794 6.65713 49.191 6.10702 49.971 5.68789C50.7511 5.26877 51.664 5.0592 52.7099 5.0592C53.9332 5.0592 55.0411 5.36482 56.0339 5.97604C57.0266 6.58727 57.8066 7.46045 58.3739 8.59559C58.9589 9.73072 59.2514 11.0492 59.2514 12.5511C59.2514 14.053 58.9589 15.3802 58.3739 16.5328C57.8066 17.6679 57.0266 18.5498 56.0339 19.1785C55.0411 19.7898 53.9332 20.0954 52.7099 20.0954C51.6817 20.0954 50.7688 19.8858 49.971 19.4667C49.191 19.0476 48.5794 18.5062 48.1362 17.8426V26.8538H43.5891V5.26877H48.1362V7.33821ZM54.6245 12.5511C54.6245 11.4334 54.3054 10.5602 53.6672 9.93155C53.0468 9.2854 52.2756 8.96232 51.3538 8.96232C50.4497 8.96232 49.6785 9.2854 49.0403 9.93155C48.4199 10.5777 48.1096 11.4596 48.1096 12.5773C48.1096 13.695 48.4199 14.5769 49.0403 15.223C49.6785 15.8692 50.4497 16.1923 51.3538 16.1923C52.2579 16.1923 53.029 15.8692 53.6672 15.223C54.3054 14.5594 54.6245 13.6688 54.6245 12.5511Z"
      fill="black"
    />
    <path
      d="M59.9351 12.5511C59.9351 11.0492 60.2188 9.73072 60.786 8.59559C61.371 7.46045 62.1599 6.58727 63.1527 5.97604C64.1454 5.36482 65.2534 5.0592 66.4766 5.0592C67.5225 5.0592 68.4355 5.26877 69.2155 5.68789C70.0132 6.10702 70.6248 6.65713 71.0503 7.33821V5.26877H75.5974V19.8858H71.0503V17.8164C70.6071 18.4975 69.9866 19.0476 69.1889 19.4667C68.4089 19.8858 67.4959 20.0954 66.45 20.0954C65.2445 20.0954 64.1454 19.7898 63.1527 19.1785C62.1599 18.5498 61.371 17.6679 60.786 16.5328C60.2188 15.3802 59.9351 14.053 59.9351 12.5511ZM71.0503 12.5773C71.0503 11.4596 70.7312 10.5777 70.093 9.93155C69.4725 9.2854 68.7103 8.96232 67.8062 8.96232C66.902 8.96232 66.1309 9.2854 65.4927 9.93155C64.8722 10.5602 64.562 11.4334 64.562 12.5511C64.562 13.6688 64.8722 14.5594 65.4927 15.223C66.1309 15.8692 66.902 16.1923 67.8062 16.1923C68.7103 16.1923 69.4725 15.8692 70.093 15.223C70.7312 14.5769 71.0503 13.695 71.0503 12.5773Z"
      fill="black"
    />
    <path
      d="M93.2464 5.26877L83.9394 26.8276H79.0466L82.4503 19.3881L76.4141 5.26877H81.493L84.9233 14.411L88.327 5.26877H93.2464Z"
      fill="black"
    />
    <path
      d="M93.1889 12.5511C93.1889 11.0492 93.4725 9.73072 94.0398 8.59559C94.6248 7.46045 95.4137 6.58727 96.4064 5.97604C97.3992 5.36482 98.5071 5.0592 99.7303 5.0592C100.776 5.0592 101.689 5.26877 102.469 5.68789C103.267 6.10702 103.879 6.65713 104.304 7.33821V5.26877H108.851V19.8858H104.304V17.8164C103.861 18.4975 103.24 19.0476 102.443 19.4667C101.663 19.8858 100.75 20.0954 99.7037 20.0954C98.4983 20.0954 97.3992 19.7898 96.4064 19.1785C95.4137 18.5498 94.6248 17.6679 94.0398 16.5328C93.4725 15.3802 93.1889 14.053 93.1889 12.5511ZM104.304 12.5773C104.304 11.4596 103.985 10.5777 103.347 9.93155C102.726 9.2854 101.964 8.96232 101.06 8.96232C100.156 8.96232 99.3846 9.2854 98.7464 9.93155C98.126 10.5602 97.8158 11.4334 97.8158 12.5511C97.8158 13.6688 98.126 14.5594 98.7464 15.223C99.3846 15.8692 100.156 16.1923 101.06 16.1923C101.964 16.1923 102.726 15.8692 103.347 15.223C103.985 14.5769 104.304 13.695 104.304 12.5773Z"
      fill="black"
    />
    <path
      d="M120.384 5.1116C122.121 5.1116 123.504 5.67043 124.532 6.7881C125.578 7.88831 126.101 9.40765 126.101 11.3461V19.8858H121.581V11.9486C121.581 10.9706 121.324 10.211 120.81 9.6696C120.295 9.12823 119.604 8.85754 118.735 8.85754C117.867 8.85754 117.175 9.12823 116.661 9.6696C116.147 10.211 115.89 10.9706 115.89 11.9486V19.8858H111.343V5.26877H115.89V7.20723C116.351 6.56108 116.972 6.05463 117.752 5.68789C118.532 5.3037 119.409 5.1116 120.384 5.1116Z"
      fill="black"
    />
    <path
      d="M127.559 12.5511C127.559 11.0492 127.843 9.73072 128.41 8.59559C128.995 7.46045 129.784 6.58727 130.777 5.97604C131.77 5.36482 132.878 5.0592 134.101 5.0592C135.147 5.0592 136.06 5.26877 136.84 5.68789C137.637 6.10702 138.249 6.65713 138.674 7.33821V5.26877H143.222V19.8858H138.674V17.8164C138.231 18.4975 137.611 19.0476 136.813 19.4667C136.033 19.8858 135.12 20.0954 134.074 20.0954C132.869 20.0954 131.77 19.7898 130.777 19.1785C129.784 18.5498 128.995 17.6679 128.41 16.5328C127.843 15.3802 127.559 14.053 127.559 12.5511ZM138.674 12.5773C138.674 11.4596 138.355 10.5777 137.717 9.93155C137.097 9.2854 136.334 8.96232 135.43 8.96232C134.526 8.96232 133.755 9.2854 133.117 9.93155C132.496 10.5602 132.186 11.4334 132.186 12.5511C132.186 13.6688 132.496 14.5594 133.117 15.223C133.755 15.8692 134.526 16.1923 135.43 16.1923C136.334 16.1923 137.097 15.8692 137.717 15.223C138.355 14.5769 138.674 13.695 138.674 12.5773Z"
      fill="black"
    />
  </svg>
);

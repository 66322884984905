import React, { useState } from "react";
import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { CompanyIcon, InfoIcon, RoleIcon } from "assets";
import styles from "../company-switch-modal.module.css";
import { RequiredLabel, ValidationMessage } from "commons/components";
import { RoleChecklist } from "commons/modals/UsersModal";
import { DocumentNumberInput } from "commons/inputs";
import { useForm, useWatch } from "react-hook-form";
import { PrimaryButton } from "commons/buttons";
import { CheckIfCompanyExists, CreateCompany } from "services";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { Spinner } from "react-bootstrap";
import { userRolesWithManager } from "constants";
import { TERMS_AND_CONDITIONS_LINK } from "constants";
import { openInNewTab } from "utils";

export const NewCompanyForm = ({
  setContent,
  setCreatedCompany,
  setCompanyData,
  companyData,
}) => {
  const [loading, setLoading] = useState(false);
  const defaultFormValues = {
    companyName: "",
    identificationNumber: "",
    roleName: "",
    employeeAmount: "",
  };
  const { register, handleSubmit, formState, control, setError, setValue } =
    useForm({
      defaultValues: companyData || defaultFormValues,
      mode: "onBlur",
    });
  const formValues = useWatch({ control });

  const employeesOptions = [
    {
      value: "0-5",
      label: "0 - 5",
    },
    {
      value: "6-20",
      label: "6 - 20",
    },
    {
      value: "21-50",
      label: "21 - 50",
    },
    {
      value: "+50",
      label: "+ 50",
    },
  ];

  const isManager = formValues.roleName === "manager";

  const createCompany = async () => {
    try {
      const response = await CreateCompany({
        ...formValues,
        isCreator: isManager,
        roleId: userRolesWithManager.find(
          ({ value }) => value === formValues.roleName
        )?.id,
      });
      if (response.id) {
        setCreatedCompany(response);
        setContent("managerSuccess");
      } else if (
        response.response.data ===
        "Ya existe una empresa con ese número de identificación."
      ) {
        setContent("error");
      }
      setLoading(false);
    } catch (error) {
      setContent("newCompany");
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
  };

  const validateCompany = async () => {
    setLoading(true);
    try {
      if (isManager) {
        await createCompany();
      } else {
        const response = await CheckIfCompanyExists(
          formValues.identificationNumber
        );
        if (!response) {
          setCompanyData({
            ...formValues,
            isCreator: isManager,
            roleId: userRolesWithManager.find(
              ({ value }) => value === formValues.roleName
            )?.id,
          });
          setContent("managerForm");
        } else {
          setContent("error");
        }
      }
    } catch (error) {
      setContent("newCompany");
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(validateCompany)}>
      <Stack
        direction={"column"}
        alignItems="center"
        justifyContent="center"
        gap={"8px"}
      >
        <CompanyIcon />
        <Stack
          direction={"column"}
          alignItems="center"
          justifyContent="flex-start"
          gap={"8px"}
        >
          <div className={styles["new-title"]}>Crear nueva empresa</div>
          <div className={styles["new-description"]}>
            Registra a una nueva empresa y sigue colaborando en las finanzas en
            Payana.
          </div>
        </Stack>
        <Box className={styles["form"]}>
          <RequiredLabel
            label="Nombre de la empresa"
            className={styles["label"]}
          />
          <TextField
            className={styles["input"]}
            size="small"
            variant="outlined"
            required={true}
            {...register("companyName", { required: true })}
            placeholder="Ejemplo: Construcciones SA"
            InputProps={{ style: { fontSize: 14 } }}
          />
          <ValidationMessage errors={formState.errors} field="companyName" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "8px",
                width: "100%",
              }}
            >
              <DocumentNumberInput
                value={formValues.identificationNumber}
                setValue={(value) => {
                  setValue("identificationNumber", value, {
                    shouldValidate: false,
                  });
                }}
                documentType="NIT"
                register={register}
                errors={formState.errors}
                id="identificationNumber"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "8px",
                width: "100%",
              }}
            >
              <RequiredLabel
                label="Cantidad de empleados"
                className={styles["label"]}
              />
              <Select
                size="small"
                variant="outlined"
                style={{ fontSize: "14px", width: "100%" }}
                className={
                  !formValues.employeeAmount
                    ? styles["emptyInput"]
                    : styles["input"]
                }
                value={formValues.employeeAmount}
                displayEmpty
                inputProps={{
                  id: "employeeAmount",
                }}
                {...register("employeeAmount", { required: true })}
              >
                <MenuItem style={{ fontSize: "14px" }} disabled value="">
                  Seleccione
                </MenuItem>
                {employeesOptions.map((option) => {
                  return (
                    <MenuItem
                      key={option.value}
                      style={{ fontSize: "14px" }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <ValidationMessage
                errors={formState.errors}
                field="employeeAmount"
              />
            </div>
          </div>
          <RequiredLabel
            label="Elige tu rol dentro de la empresa"
            className={styles["label"]}
          />
          <Select
            onChange={(e) => {
              setValue("roleName", e.target.value, {
                shouldValidate: false,
              });
            }}
            style={{ fontSize: "14px" }}
            className={
              !formValues.roleName ? styles["emptyInput"] : styles["input"]
            }
            size="small"
            value={formValues.roleName || ""}
            {...register("roleName", { required: true })}
            displayEmpty
            startAdornment={
              <InputAdornment position="start">
                <RoleIcon />
              </InputAdornment>
            }
          >
            <MenuItem style={{ fontSize: "14px" }} disabled value="">
              Elige el rol del usuario
            </MenuItem>
            {userRolesWithManager.map((role) => {
              return (
                <MenuItem
                  style={{ fontSize: "14px" }}
                  key={role.label}
                  value={role.value}
                >
                  {role.label}
                </MenuItem>
              );
            })}
          </Select>
          <ValidationMessage errors={formState.errors} field="roleName" />
          {formValues.roleName && (
            <RoleChecklist
              role={formValues.roleName}
              label={
                userRolesWithManager.find(
                  ({ value }) => value === formValues.roleName
                )?.label
              }
            />
          )}
        </Box>
        {isManager && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "8px",
              alignSelf: "flex-start",
            }}
          >
            <InfoIcon color="#667085" />
            <div className={styles["hint"]}>
              Este usuario no puede eliminarse dentro de Payana.
            </div>
          </div>
        )}
        <PrimaryButton
          action={handleSubmit(validateCompany)}
          text={
            loading ? <Spinner animation="border" size="sm" /> : "Continuar"
          }
          width={"100%"}
          style={{ marginTop: "16px", marginBottom: "16px" }}
          disabled={loading}
        />
        <div className={styles["terms"]}>
          Al registrarte estarás aceptando los{" "}
          <span
            style={{ color: "#6938EF", cursor: "pointer" }}
            onClick={() => openInNewTab(TERMS_AND_CONDITIONS_LINK)}
          >
            términos y condiciones y políticas de privacidad
          </span>
          .
        </div>
      </Stack>
    </form>
  );
};

import React from "react";
import { Stack, Box, IconButton, Fade, Skeleton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { format } from "date-fns";

const Notes = ({ notes = [], userEmail, loading, deleteNote }) => {
  return notes.map((note) => {
    const canDeleteNote = userEmail ? note.authorEmail === userEmail : true;
    return (
      <Stack direction="row" sx={{ alignItems: "flex-end", marginBottom: 2 }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            boxShadow: "1px 1px 3px #ccc",
            width: "100%",
            borderRadius: 2,
            padding: "10px",
          }}
        >
          <Stack
            direction="row"
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            {loading ? (
              <div>
                <Skeleton width="100px" />
              </div>
            ) : (
              <div style={{ color: "#5925DC" }}>
                {note.name || note.authorEmail}
              </div>
            )}

            <Fade in={canDeleteNote && !loading}>
              <IconButton
                sx={{ marginLeft: 1, cursor: "pointer" }}
                onClick={() => deleteNote(note)}
                aria-label="delete"
                color="primary"
              >
                <Delete />
              </IconButton>
            </Fade>
          </Stack>
          {loading ? (
            <div>
              <Skeleton width="50%" />
              <Skeleton width="35%" />
              <Skeleton width="15%" />
            </div>
          ) : (
            <div style={{ paddingRight: 30 }}>
              {note.comment.split("\n").map((line) => (
                <div>{line}</div>
              ))}
            </div>
          )}
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "flex-end",
              fontSize: 14,
            }}
          >
            <div>
              {loading ? (
                <Skeleton width="25px" />
              ) : (
                format(new Date(note?.date), "dd/MM/yy, h:mm aaa")
              )}
            </div>
          </Stack>
        </Box>
      </Stack>
    );
  });
};

export default Notes;

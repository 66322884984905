import React from 'react'
import { Divider, Stack, Typography, Button, Link } from '@mui/material'
import { AddFileIcon, InfoIcon, ModalFileIcon } from '../../../assets'
import { styles } from './styles'
import { sampleInvoiceFile, sampleSupplierFile } from "../../../constants";

export const UploadFileModalInfo = ({ type }) => {
    const infoPDF = () => {
        return <div style={styles.modalInfoContainer} >
            <ModalFileIcon />
            <div style={styles.modalInfoContainerText} >
                <p style={styles.modalInfoText} >
                    También puedes enviarnos tus facturas por mail
                </p>
                <p style={styles.modalInfoSubText} >
                    Las recibimos en <u>facturascolombia@payana.la</u> y las
                    cargamos por ti para que simplemente las pagues desde tu
                    cuenta.
                </p>
            </div>
        </div>
    }

    const infoCSV = () => {
        return <Link
            my={2}
            href={type === "CSV" ? sampleInvoiceFile : sampleSupplierFile}
            target="_blank"
            underline="none"
        >
            <Divider />
            <Button
                key="one"
                startIcon={<AddFileIcon />}
                sx={styles.modalInfoCSVButton}
            >
                <Stack ml={2}>
                    <Typography
                        sx={{
                            ...styles.emptyStateButtonTitle,
                            textDecoration: "underline",
                        }}
                    >
                        Descargue una plantilla para ver un ejemplo del formato
                        requerido.
                    </Typography>
                </Stack>
            </Button>
        </Link>
    }

    const infoTXT = () => {
        return <div style={styles.modalInfoContainer} >
            <InfoIcon />
            <div style={styles.modalInfoContainerText} >
                <p style={styles.modalInfoText} >
                    Este formato suele ser exportado por varios sistemas contable en un archivo con extension .TXT
                </p>
            </div>
        </div>
    }

    return (
        <>
            {type === "PDF" && infoPDF()}
            {type === "CSV" && infoCSV()}
            {type === "TXT" && infoTXT()}
        </>
    )
}
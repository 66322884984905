export const styles = {
  container: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "32px",
    width: "1070px",
  },
  title: {
    color: "#344054",
    fontSize: "24px",
    fontWeight: "500",
    marginTop: "12px",
    textAlign: "left",
    alignSelf: "flex-start",
    maxWidth: "400px",
  },
  subtitle: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "left",
    alignSelf: "flex-start",
    maxWidth: "400px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
    justifyContent: "space-between",
    width: "100%",
    gap: "8px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    justifyContent: "center",
  },
  modalSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
    marginRight: "12px",
    "&:focus": {
      border: "none",
    },
  },
  modalPrimaryButton: {
    backgroundColor: "#D92D20",
    border: "none",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    "&:focus": {
      border: "none",
    },
  },
  headerContainer: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    borderRadius: "8px",
    backgroundColor: "#F9FAFB",
    width: "100%",
    height: "auto",
    gap: "4px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  headerTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#344054",
    marginBottom: "8px",
  },
  headerTextContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "flex-start",
    height: "auto",
    marginBottom: "-8px",
  },
  headerText: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#344054",
    marginTop: "-2px",
  },
  contentTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#344054",
    marginBottom: "16px",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
    height: "auto",
    width: "100%",
  },
  tableColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: "auto",
    width: "100%",
    border: "1px solid #D0D5DD",
  },
  buttons: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
  },
};

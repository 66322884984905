import { useState } from "react";
import { Box, Dialog, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ApprovedIcon } from "../../assets";
import { PrimaryButton } from "../buttons";

const WelcomeModal = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const [dialog] = useState(() => queryParameters.get("dialog"));
  const closeWelcomeModal = () => {
    navigate("/payments/invoices", {
      replace: true,
    });
  };
  const content =
    dialog === "welcome" ? (
      <p>
        Ya puedes comenzar a utilizar la nueva versión de Payana. Recuerda que
        si necesitas ayuda podrás pedirla desde el botón violeta flotante que se
        encuentra en la esquina inferior derecha.
      </p>
    ) : dialog === "welcome_multiple" ? (
      <>
        <p>
          Ya puedes comenzar a utilizar la nueva versión de Payana.{" "}
          <span style={{ fontWeight: 600 }}>
            Recuerda que todos los usuarios de tu empresa deberán cambiar la
            contraseña la próxima vez que ingresen.
          </span>
        </p>
        <p>
          Si necesitas ayuda podrás pedirla desde el botón violeta flotante que
          se encuentra en la esquina inferior derecha.
        </p>
      </>
    ) : null;

  const open = ["welcome_multiple", "welcome"].includes(
    queryParameters.get("dialog")
  );

  return (
    <Dialog open={open} onClose={closeWelcomeModal}>
      <Box style={{ padding: 40, maxWidth: 400, minWidth: 336 }}>
        <div style={{ textAlign: "center" }}>
          <ApprovedIcon />
        </div>
        <p
          style={{
            fontSize: "20px",
            marginTop: "20px",
            fontWeight: "600",
            textAlign: "center",
            lineHeight: "30px",
          }}
        >
          ¡Todo está listo!
        </p>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "400",
            textAlign: "center",
            lineHeight: "22px",
          }}
        >
          {content}
        </div>
        <Stack style={{ marginTop: 20 }} alignItems="flex-end">
          <PrimaryButton
            text="Entendido"
            action={closeWelcomeModal}
            style={{ width: "100%" }}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};

export { WelcomeModal };

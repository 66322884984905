export const styles = {
  emptyStateTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
  },
  emptyStateSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
  },
  box: {
    border: "1px solid #EAECF0",
    backgroundColor: "#F9FAFB",
    width: "95%",
    minHeight: "400px",
    margin: "2rem auto",
    display: "flex",
    justifyContent: "center",
  },
  stack: {
    maxWidth: "500px",
    alignItems: "center",
  },
};

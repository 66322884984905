import { requestContainer } from "../requestContainer";

export function GetBOReceipts({
  pageIndex,
  status,
  providerId,
  companyId,
  transactionHash,
  employeeId,
  type,
}) {
  return requestContainer({
    method: "get",
    url: `/backoffice/receipts?page=${pageIndex}&status=${status}&provider_id=${providerId}&company_id=${companyId}&transaction_hash=${transactionHash}&employee_id=${employeeId}&type=${type}`,
  });
}

export async function GetBOReceipt({ id }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/receipts/${id}`,
  });
}

export function GetBOTotalReceipts({
  status,
  providerId,
  companyId,
  transactionHash,
  employeeId,
  type,
}) {
  return requestContainer({
    method: "get",
    url: `/backoffice/receipts/total?status=${status}&provider_id=${providerId}&company_id=${companyId}&transaction_hash=${transactionHash}&employee_id=${employeeId}&type=${type}`,
  });
}

export function UploadReceiptFile(formData) {
  return requestContainer({
    method: "post",
    url: "/backoffice/upload/receipts",
    data: formData,
  });
}

export function SendEmail({ data }) {
  return requestContainer({
    method: "post",
    url: "/backoffice/receipts/notificate",
    data: data,
  });
}

export async function UpdateReceiptStatus(id, status) {
  return requestContainer({
    method: "put",
    url: `/backoffice/receipts/${id}`,
    data: { status: status },
  });
}

export async function UpdateSendSupport(id) {
  return requestContainer({
    method: "post",
    url: `/backoffice/receipts/send-support`,
    data: { receipt_id: id },
  });
}

export async function sendChatMessage(data) {
  return requestContainer({
    method: "post",
    url: `/backoffice/chat`,
    data,
  });
}

export async function sendReceiptToWPP(data) {
  return requestContainer({
    method: "post",
    url: `/backoffice/tochat`,
    data,
  });
}

export function GenerateAutoReceipt(data) {
  return requestContainer({
    method: "post",
    url: "/backoffice/receipts/generate",
    data: data,
  });
}

export async function RemoveFile({ id }) {
  return requestContainer({
    method: "put",
    url: `/backoffice/receipts/remove-file/${id}`,
  });
}

export async function GetAllBanksBO() {
  return requestContainer({
    method: "get",
    url: `/banks`,
  });
}

import React from "react";

export const CheckCircleIcon = ({ color, width = "16", height = "16" }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_12264_1155)">
      <path
        d="M4.99998 8.00016L6.99998 10.0002L11 6.00016M14.6666 8.00016C14.6666 11.6821 11.6819 14.6668 7.99998 14.6668C4.31808 14.6668 1.33331 11.6821 1.33331 8.00016C1.33331 4.31826 4.31808 1.3335 7.99998 1.3335C11.6819 1.3335 14.6666 4.31826 14.6666 8.00016Z"
        stroke={color ? color : "#667085"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12264_1155">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import React from "react";
import { Box } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { styles } from "./styles";
import { ModalWarningIcon } from "../../../assets";

export const WarningModal = ({
  title,
  subtitle = "",
  action,
  visible,
  handleClose,
}) => {
  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body>
        <Box sx={styles.container}>
          <ModalWarningIcon />
          <p style={styles.title}>{title}</p>
          <p style={styles.subtitle}>{subtitle}</p>
          <div style={styles.row}>
            <Button
              variant="primary"
              style={styles.modalPrimaryButton}
              onClick={() => {
                action();
                handleClose();
              }}
            >
              Confirmar
            </Button>
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export const getBodyTextLoading = (entity, type) => {
  const text = {
    invoices: {
      SupplierCSV:
        "Estamos analizando tus archivos, aparecerán individualmente en tu lista de proveedores.",
      TXT: "Estamos analizando tus archivos, aparecerán individualmente en tu lista.",
    },
    payrolls: {
      TXT: "Estamos analizando tus archivos, aparecerán individualmente en tu lista.",
    },
  };

  return text[entity][type]
    ? text[entity][type]
    : "Estamos analizando tus archivos, aparecerán individualmente en tu lista de cuentas por pagar.";
};

export const getBodyTextAccept = (type) => {
  const text = {
    PDF: "Asegúrate de elegir archivos en formato PDF, DOC, ZIP o JPG (máx. 25 mb.)",
    TXT: "Asegúrate de elegir un archivo en formato TXT (máx. 25 mb.)",
    CSV: "Asegúrate de elegir un archivo en formato XLS o CSV (máx. 25 mb.)",
  };
  return text[type] ? text[type] : "";
};

export const acceptedDropzoneFiles = {
  PDF: {
    "application/zip": [".zip"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "image/jpeg": [".jpg", ".jpeg"],
  },
  TXT: {
    "text/plain": [".txt"],
  },
  CSV: {
    "text/csv": [".csv"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  },
};

export const getTitle = (entity, type) => {
  const title = {
    invoices: {
      PDF: "Subir documento a pagar",
      CSV: "Subir listado de cuentas a pagar",
      SupplierCSV: "Subir listado de proveedores",
      TXT: "Subir listado de cuentas a pagar",
    },
    payrolls: {
      TXT: "Subir nómina con formato Bancolombia",
    },
    collections: {
      PDF: "Subir documento a cobrar",
      CSV: "Subir listado de cuentas a cobrar",
      TXT: "Subir listado de cuentas a cobrar",
    },
  };
  return title[entity][type] ? title[entity][type] : "";
};

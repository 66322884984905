import React from "react";
import { SubscriptionIcon } from "./SubscriptionIcon";
import { ApprovedIcon } from "assets";
import { SubscriptionTitle } from "./SubscriptionTitle";
import { PrimaryButton } from "commons/buttons";
import { styles } from "./styles";

export const ModalSuccessSuscriber = ({ handleSuccessContinue }) => {
  return (
    <>
      <SubscriptionIcon icon={<ApprovedIcon />} />
      <SubscriptionTitle title={"¡Listo!"} style={styles.successTitle} />
      <p style={styles.successFirstText}>
        Tu suscripción ha comenzado con éxito.
      </p>
      <p style={styles.successSecondText}>
        Visualizarás tu factura al inicio del próximo mes en tu panel de Cuentas
        por Pagar.
      </p>
      <p
        style={{
          ...styles.successSecondText,
          margin: "0px",
        }}
      >
        Ya puedes continuar con el pago de los documentos seleccionados.
      </p>

      <div style={styles.successButton}>
        <PrimaryButton
          text={"Continuar"}
          style={{ width: "180px" }}
          action={handleSuccessContinue}
        />
      </div>
    </>
  );
};

import React from "react";

export const WppIcon = ({ color }) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
    <path
      d="M13.9998 6.66667C13.9998 9.79628 11.4627 12.3333 8.3331 12.3333C7.61525 12.3333 6.92857 12.1999 6.29654 11.9564C6.18098 11.9118 6.12321 11.8896 6.07726 11.879C6.03207 11.8686 5.99935 11.8642 5.95301 11.8624C5.90589 11.8606 5.8542 11.866 5.75081 11.8767L2.3368 12.2296C2.0113 12.2632 1.84856 12.2801 1.75255 12.2215C1.66894 12.1705 1.61198 12.0853 1.59682 11.9885C1.57942 11.8774 1.65719 11.7335 1.81273 11.4456L2.90317 9.42721C2.99297 9.26099 3.03787 9.17788 3.05821 9.09797C3.07829 9.01905 3.08315 8.96213 3.07672 8.88095C3.07022 8.79875 3.03415 8.69175 2.96202 8.47774C2.77034 7.90906 2.66644 7.3 2.66644 6.66667C2.66644 3.53705 5.20349 1 8.3331 1C11.4627 1 13.9998 3.53705 13.9998 6.66667Z"
      stroke={color ? color : "#98A2B3"}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

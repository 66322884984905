import React from "react";

export const EditIcon = ({ color = "#667085" }) => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    type="svg"
  >
    <path
      d="M8.99998 15.6667H16.5M1.5 15.6667H2.89545C3.3031 15.6667 3.50693 15.6667 3.69874 15.6206C3.8688 15.5798 4.03138 15.5124 4.1805 15.4211C4.34869 15.318 4.49282 15.1739 4.78107 14.8856L15.25 4.41665C15.9404 3.7263 15.9404 2.60701 15.25 1.91665C14.5597 1.2263 13.4404 1.2263 12.75 1.91665L2.28105 12.3856C1.9928 12.6739 1.84867 12.818 1.7456 12.9862C1.65422 13.1353 1.58688 13.2979 1.54605 13.4679C1.5 13.6597 1.5 13.8636 1.5 14.2712V15.6667Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React, { useState, useEffect } from "react";
import { Box, TextField, Dialog } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { getEmailError } from "../../forms/validation";
import { ValidationMessage } from "../../components";

import { styles } from "./styles";

export const NotificationEmailsModal = ({ visible, handleClose, addEmail }) => {
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail("");
    setErrors({});
  }, [visible]);

  const handleOnChange = (e) => {
    const error = getEmailError(e.target.value);
    if (error) {
      setErrors(() => ({ email: { message: error } }));
    } else {
      setErrors(({ email, ...errors }) => errors);
    }
    setEmail(e.target.value);
  };

  const handleAddEmail = () => addEmail(email);

  return (
    <Dialog onClose={handleClose} open={visible}>
      <Box sx={styles.container}>
        <p style={styles.title}>Agregar un nuevo correo electrónico</p>
        <p style={styles.text}>
          Ingresa un nuevo correo electrónico para que reciba las notificaciones
          de Payana vía mail.
        </p>
        <Box sx={styles.form}>
          <TextField
            sx={styles.input}
            size="small"
            variant="outlined"
            value={email}
            required={true}
            onChange={handleOnChange}
          />
          <ValidationMessage errors={errors} field="email" />
        </Box>
        <div style={styles.row}>
          <SecondaryButton width="200px" text="Cancelar" action={handleClose} />
          <PrimaryButton
            width="200px"
            isDisable={Object.values(errors).length}
            text="Crear"
            action={handleAddEmail}
          />
        </div>
      </Box>
    </Dialog>
  );
};

export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowMarginTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "24px",
  },
  rowMarginTopBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "24px",
    marginBottom: "24px",
  },
  fixedRight: {
    marginTop: "12px",
    marginLeft: "auto",
  },
  headerRow: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
  },
  transactionNetCommissionTitle: {
    color: "#667085",
    fontWeight: "600",
    fontSize: "16px",
  },
  text: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
    width: 150,
  },
  date: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
  },
  sourceOfFunds: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
    maxWidth: 200,
    textAlign: "right",
  },
  transactionHash: {
    color: "#101828",
    fontWeight: "500",
    fontSize: "14px",
    maxWidth: "380px",
  },
  transactionNetCommission: {
    color: "#101828",
    fontWeight: "600",
    fontSize: "16px",
  },
  rowText: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
    width: 100,
    textAlign: "right",
    marginRight: "6px",
    alignSelf: "flex-end",
  },
  rowTextLeft: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
    width: 100,
    textAlign: "left",
  },
  amountTitle: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
  },
  amount: {
    color: "#344054",
    fontWeight: "600",
    fontSize: "16px",
  },
  white: {
    backgroundColor: "#FFFFFF",
  },
  noPadding: {
    padding: "0px",
  },
  spinnerContainer: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  greyContainer: {
    backgroundColor: "#f5f6f7",
    borderColor: "#EAECF0",
    borderWidth: "1px 0px",
    padding: "16px",
  },
  firstItem: {
    marginTop: "-12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  middleItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  lastItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    overflowY: "scroll",
  },
  trxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "4px",
  },
  trxRowLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "4px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textWithoutLimit: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
  },
  rowTextLeftWithoutLimit: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "left",
  },
  biggerRowTextLeftWithoutLimit: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "16px",
    textAlign: "left",
  },
  separator: {
    height: "1px",
    width: "100%",
    backgroundColor: "#E9ECF0",
    margin: "revert",
    opacity: "1",
    border: "0",
    marginTop: "-1px",
  },
  modalTitle: {
    paddingLeft: "16px",
    color: "#101828",
    fontSize: "18px",
    fontWeight: "600",
  },
  modalSubtitle: {
    marginTop: "6px",
    paddingLeft: "16px",
    color: "#667085",
    fontSize: "16px",
    fontWeight: "400",
  },
  backContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer",
  },
  backText: {
    color: "#5925DC",
    fontSize: "14px",
    fontWeight: "500",
  },
  txDetailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    gap: "24px",
    padding: "16px",
  },
  txColumn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "12px",
  },
  amountSectionContainer: {
    width: "100%",
    background: "#FFFFFF",
    padding: "16px",
    borderRadius: "8px",
    borderBottom: "1px solid #EAECF0",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    height: "70px",
  },
  amountSectionTitle: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "600",
  },
  amountSectionText: {
    color: "#101828",
    fontSize: "30px",
    fontWeight: "700",
  },
  sectionContainer: {
    width: "100%",
    background: "#FFFFFF",
    padding: "16px",
    borderRadius: "8px",
    borderBottom: "1px solid #EAECF0",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  egressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    backgroundColor: "#F9FAFB",
    color: "#667085",
    height: "40px",
    padding: "8px 16px 8px 16px",
    borderRadius: "8px",
    opacity: "0px",
  },
  egressText: {
    fontSize: "16px",
    fontWeight: 500,
  },
  sectionTitle: {
    color: "#1D2939",
    fontWeight: "600",
    fontSize: "16px",
    alignSelf: "flex-start",
  },
  sectionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  sectionRowWithIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sectionIcon: {
    cursor: "pointer",
    backgroundColor: "transparent",
    padding: "0px 0px 0px 8px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  sectionButton: {
    height: "20px",
    paddingRight: "0px",
  },
  sectionDivider: {
    backgroundColor: "#EAECF0",
    height: "1px",
    width: "100%",
    marginBottom: "4px",
  },
  documentRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
  },
  egressSectionContainer: {
    width: "100%",
    height: "50px",
    background: "#FAFAFF",
    padding: "8px",
    borderRadius: "216px",
    border: "1px solid #6938EF",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
  },
  egressSectionRow: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
  },
  siigoLogoContainer: {
    width: "37px",
    height: "37px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30.33px",
    background: "#FFFFFF",
    border: "1px solid #E7E7E8",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    margin: "0px",
  },
  egressSectionTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#1D2939",
  },
  errorContainer: {
    background: "#FFFBFA",
    padding: "16px 15px",
    borderRadius: "8px",
    border: "1px solid #FDA29B",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  errorRow: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  errorIcon: {
    width: "28.5px",
    height: "16.25px",
    marginTop: "-4px",
  },
  errorColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  errorSectionTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#B42318",
  },
  errorSectionText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#D92D20",
  },
  moneyJourneySectionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    gap: "12px",
    marginBottom: "6px",
  },
  moneyJourneySectionTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#344054",
    alignSelf: "center",
    height: "0px",
  },
  moneyJourneySectionDate: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    alignSelf: "center",
    color: "#667085",
    height: "0px",
  },
  moneyJourneySectionText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    alignSelf: "flex-start",
    color: "#667085",
    height: "20px",
  },
  tooltipColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  tooltipTitle: {
    color: "#5522FF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    marginBottom: "6px",
  },
  tooltipSubtitle: {
    color: "#5522FF",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
  },
};

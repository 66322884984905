import React from "react";
import Switch from "react-ios-switch";

export const SwitchWithText = ({
  text,
  checked,
  setChecked,
  type = "null",
  enableSaveButton = () => {},
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "60px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        padding: "24px",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        border: "1px solid rgba(234, 236, 240, 1)",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      }}
    >
      <Switch
        checked={checked}
        disabled={false}
        handleColor="white"
        offColor="#D0D5DD"
        onChange={() => {
          setChecked(!checked);
          type === "reminders" && enableSaveButton();
        }}
        onColor="#7F56D9"
        readOnly={false}
        style={{ transform: "scale(0.7)" }}
      />
      {text}
    </div>
  );
};

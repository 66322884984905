import React from "react";

import styles from "./styles.module.css";
import { CheckCircleIcon, FileSearchIcon } from "../../../assets";
import { useMediaQuery } from "react-responsive";

export const StatusTabs = ({ onChange, tabSelected, setTabSelected }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const handleChangeTab = (tabSelected) => {
    setTabSelected(tabSelected);
    onChange(tabSelected);
  };

  return (
    <div
      className={isMobile ? styles.tabsContainerMobile : styles.tabsContainer}
    >
      <button
        className={`${styles.tabBase} ${
          tabSelected === "sent" && styles.tabSelected
        } `}
        onClick={() => handleChangeTab("sent")}
      >
        <FileSearchIcon color={tabSelected === "sent" && "#6938ef"} />
        <label
          className={`${styles.labelBase} ${
            tabSelected === "sent" && styles.labelSelected
          }`}
        >
          Por pagar
        </label>
      </button>
      <button
        className={`${styles.tabBase} ${
          tabSelected === "paid" && styles.tabSelected
        }`}
        onClick={() => handleChangeTab("paid")}
      >
        <CheckCircleIcon color={tabSelected === "paid" && "#6938ef"} />
        <label
          className={`${styles.labelBase} ${
            tabSelected === "paid" && styles.labelSelected
          }`}
        >
          Pagadas
        </label>
      </button>
    </div>
  );
};

import { toastOptions } from "constants";
import { toast } from "react-toastify";

export const calculateAmountPrice = ({
  price = 0,
  discount = 0,
  retencion = 0,
}) => {
  try {
    const subtotal = price - discount;
    return subtotal - subtotal * (+retencion / 100);
  } catch (error) {
    toast.error("No se pudo calcular el monto a cobrar", toastOptions);
  }
};

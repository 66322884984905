import React from "react";
import { ListCheckIcon } from "assets";
import { LIST_CARD_INFORMATION } from "constants";
import { styles } from "./styles";

export const CardInformation = ({ type }) => {
  return (
    <>
      <p style={styles.cardInformationTitle}>¿Qué incluye? </p>
      <div>
        {LIST_CARD_INFORMATION[type]?.map((item, index) => (
          <div key={index} style={styles.cardInformationListContainer}>
            <ListCheckIcon stroke="#6938EF" strokeWidth="2.5" />
            {item.text}
          </div>
        ))}
      </div>
    </>
  );
};

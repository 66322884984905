import React from "react";

export const ListXIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M11.3334 4.6665L4.66675 11.3332M4.66675 4.6665L11.3334 11.3332"
      stroke="#F04438"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";
import { BannerErrorIcon, CloseIcon, SiigoLogo, SuccessIcon } from "assets";
import { Typography } from "@mui/material";
import { Spinner } from "react-bootstrap";
import { styles } from "screens/InvoicesScreen/styles";

export const SyncBanner = ({
  title,
  description = null,
  type,
  setSyncBanner,
  customStyle = {},
}) => {
  const style = {
    loading: styles.loadingSiigoBanner,
    success: styles.successSiigoBanner,
    error: styles.errorSiigoBanner,
    warning: styles.warningSiigoBanner,
  };

  const titleStyle = {
    loading: styles.siigoLoadingBannerTitle,
    success: styles.siigoSuccessBannerTitle,
    error: styles.siigoErrorBannerTitle,
    warning: styles.siigoWarningBannerTitle,
  };

  const icon = {
    loading: <SiigoLogo />,
    success: <SuccessIcon />,
    error: <BannerErrorIcon />,
    warning: <BannerErrorIcon color="#B54708" />,
  };

  const color = {
    loading: "#5925DC",
    success: "#12B76A",
    error: "#F04438",
    warning: "#B54708",
  };

  return (
    <div style={{ ...style[type], ...customStyle }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <div
          style={{
            width: "28.5px",
            height: "16.25px",
            marginTop: "-4px",
          }}
        >
          {icon[type]}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography sx={titleStyle[type]}>{title}</Typography>
          {description && (
            <Typography sx={styles.siigoErrorBannerDescription}>
              {description}
            </Typography>
          )}
        </div>
      </div>
      {type === "loading" ? (
        <Spinner animation="border" size="sm" style={{ color: color[type] }} />
      ) : (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSyncBanner((syncBanner) => {
              return {
                ...syncBanner,
                visible: false,
              };
            });
          }}
        >
          <CloseIcon color={color[type]} />
        </div>
      )}
    </div>
  );
};

import { useContext } from "react";
import { SecondaryButton } from "../../../commons/buttons";
import { CollectionsContext, UserContext } from "../../../contexts";
import { Typography, Stack, Fade } from "@mui/material";
import { RefreshIcon } from "../../../assets";
import { styles } from "../styles";
import { formatLocaleDate } from "../../../utils";

export const SyncButton = ({ handleSyncErp = () => {} }) => {
  const { currentCompany } = useContext(UserContext);
  const { syncIsLoading, setSyncIsLoading, syncHasError, syncBtnIsDisabled } =
    useContext(CollectionsContext);

  return (
    <div className="sync-cs">
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Fade in={syncHasError && syncBtnIsDisabled}>
          <Typography color={"red"} variant="subtitle2">
            Ocurrió un error durante la sincronización.
          </Typography>
        </Fade>
        {currentCompany?.last_sync_at_collections && (
          <div style={styles.syncContainer}>
            Actualizado:{" "}
            {formatLocaleDate(
              new Date(currentCompany?.last_sync_at_collections),
              "dd MMM yyyy, h:mmaaa"
            )}
          </div>
        )}
        <SecondaryButton
          fontSize="12px"
          height="40px"
          width="180px"
          textStyle={{
            paddingLeft: 8,
            fontSize: "14px",
            color: "#344054",
          }}
          text={`Sincronizar Siigo`}
          action={async () => {
            setSyncIsLoading(true);
            handleSyncErp().then(() => setSyncIsLoading(false));
          }}
          isDisable={syncIsLoading || syncBtnIsDisabled}
          icon={<RefreshIcon />}
        />
      </Stack>
    </div>
  );
};

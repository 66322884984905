import React from "react";
import { Tab } from "@mui/material";
import { TabList } from "@mui/lab";
import { styles } from "../../../Table/styles";

export const ModalTabs = ({ handleChange }) => {
  return (
    <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto">
      <Tab
        label="Modificar valor total"
        value="3"
        aria-controls="simple-tabpanel-3"
        style={styles.amountModalTab}
      />
      <Tab
        label="Hacer pago parcial"
        value="2"
        aria-controls="simple-tabpanel-2"
        style={styles.amountModalTab}
      />
    </TabList>
  );
};

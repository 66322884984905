import {
  addDays,
  endOfDay,
  startOfDay,
  addMonths,
  isSameDay,
  addYears,
} from "date-fns";

const defined = {
  today: new Date(),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfWeek: addDays(new Date(), -7),
  startOfLastMonth: addMonths(new Date(), -1),
  startOfLastThreeMonths: addMonths(new Date(), -3),
  startOfLastSixMonths: addMonths(new Date(), -6),
  startOfLastYear: addYears(new Date(), -1),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: "Hoy",
    range: () => ({
      startDate: defined.startOfToday,
      endDate: defined.endOfToday,
    }),
  },
  {
    label: "Última semana",
    range: () => ({
      startDate: defined.startOfWeek,
      endDate: defined.today,
    }),
  },
  {
    label: "Último mes",
    range: () => ({
      startDate: defined.startOfLastMonth,
      endDate: defined.today,
    }),
  },
  {
    label: "Últimos 3 meses",
    range: () => ({
      startDate: defined.startOfLastThreeMonths,
      endDate: defined.today,
    }),
  },
  {
    label: "Últimos 6 meses",
    range: () => ({
      startDate: defined.startOfLastSixMonths,
      endDate: defined.today,
    }),
  },
]);

import { Stack } from "@mui/material";
import Switch from "react-ios-switch";
import { AddresseeIcon, PlusCircleIcon } from "assets";
import {
  OptionsTabs,
  Receiver,
} from "commons/modals/CollectionObligationModal";
import { ThirdButton } from "commons/buttons";
import { styles } from "../../Table/styles";

const ReceiversSection = (props) => {
  const {
    setChecked,
    checked,
    setSelectedReceiver,
    setSelectedSecondReceiver,
    setFirstReceiverAmount,
    setSecondReceiverAmount,
    setSecondReceiver,
    objectIsEmpty,
    mainReceiver,
    onOptionSelectedChanged,
    receiverOption,
    setReceiverOption,
    firstReceiverAmountState,
    setReceiverDrawerIsOpen,
    selectedReceiver,
    isReceiverAmountExceeded,
    formValues,
    amountComission,
    secondReceiver,
    secondReceiverAmountState,
    selectedSecondReceiver,
    deleteSecondReceiver,
  } = props;
  return (
    <Stack spacing={1}>
      <div style={{ ...styles.sectionRow, marginTop: "24px" }}>
        <AddresseeIcon />
        <span style={styles.cardTitle}>Destinatario</span>
      </div>
      <div
        style={{
          marginTop: "24px",
          width: "100%",
          height: "28px",
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          padding: "24px",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Switch
          checked={checked}
          className={undefined}
          disabled={false}
          handleColor="white"
          name={undefined}
          offColor="#D0D5DD"
          onChange={() => {
            setChecked(!checked);
            if (!checked) {
              setSelectedReceiver({});
              setSelectedSecondReceiver({});
              setFirstReceiverAmount("");
              setSecondReceiverAmount("");
              setSecondReceiver(false);
            }
          }}
          onColor="#7F56D9"
          pendingOffColor={undefined}
          pendingOnColor={undefined}
          readOnly={objectIsEmpty(mainReceiver)}
          style={{ transform: "scale(0.7)" }}
        />
        <span style={styles.switchText}>Habilitar destinatario</span>
      </div>
      {objectIsEmpty(mainReceiver) && (
        <div style={{ ...styles.emptyReceiverDataCard, height: "70px" }}>
          <p style={styles.receiverCardTitle} className="card-subtitle mb-2">
            Para poder habilitar destinatarios necesitas cargar los datos de tu
            cuenta bancaria.
          </p>
        </div>
      )}
      {checked && (
        <>
          <OptionsTabs
            onChange={onOptionSelectedChanged}
            tabSelected={receiverOption}
            setTabSelected={setReceiverOption}
          />
          <Receiver
            receiverNumber={1}
            setSecondReceiver={setSecondReceiver}
            amount={firstReceiverAmountState}
            setAmount={setFirstReceiverAmount}
            option={receiverOption}
            setReceiverDrawerIsOpen={setReceiverDrawerIsOpen}
            selectedReceiver={selectedReceiver}
            setSelectedReceiver={setSelectedReceiver}
            isExceeded={isReceiverAmountExceeded}
            totalAmount={parseInt(formValues.amount - amountComission) || 0}
          />
          {!secondReceiver && (
            <ThirdButton
              text={"Sumar destinatario"}
              action={() => setSecondReceiver(true)}
              width="100%"
              style={{ marginBottom: "24px" }}
              icon={<PlusCircleIcon />}
            />
          )}
          {secondReceiver && (
            <Receiver
              receiverNumber={2}
              setSecondReceiver={setSecondReceiver}
              amount={secondReceiverAmountState}
              setAmount={setSecondReceiverAmount}
              option={receiverOption}
              selectedReceiver={selectedSecondReceiver}
              setSelectedReceiver={setSelectedSecondReceiver}
              isExceeded={isReceiverAmountExceeded}
              totalAmount={parseInt(formValues.amount - amountComission) || 0}
              deleteSecondReceiver={deleteSecondReceiver}
            />
          )}
        </>
      )}
    </Stack>
  );
};

export default ReceiversSection;

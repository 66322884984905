import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Stack, TextField, Typography, Autocomplete } from "@mui/material";
import { RequiredLabel, ValidationMessage } from "../../../../components";
import { PrimaryButton, SecondaryButton } from "../../../../buttons";
import { styles } from "../../styles";
import { ServicesSearch } from "../../../../../services";
import { objectIsEmpty } from "../../../../../utils/objectUtils";
import DrawerFooter from "../SupplierForm/DrawerFooter";

const ServicesForm = ({ detailedService, onCancel, onSubmit, id, title }) => {
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [serviceSelected, setServiceSelected] = useState({});

  const { register, handleSubmit, formState, control, reset } = useForm({
    defaultValues: detailedService,
    mode: "onBlur",
  });
  const formValues = useWatch({ control });

  const submitForm = handleSubmit(async (formValues) => {
    setCreateRequestInProcess(true);
    await onSubmit({ ...formValues, service_id: serviceSelected?.id });
    setCreateRequestInProcess(false);
  });

  const getSearchOptions = (query) => {
    ServicesSearch(query).then((res) => {
      setSearchOptions(res);
    });
  };

  useEffect(() => {
    reset(detailedService);
  }, [detailedService]);

  return (
    <form onSubmit={submitForm} style={{ height: "100%" }}>
      <div style={styles.content}>
        <div></div>
        <div style={styles.column}>
          <Typography sx={styles.serviceTitle}>{title}</Typography>
          <div style={styles.serviceCenteredForm}>
            <Stack sx={styles.background}>
              <Stack spacing={1}>
                <RequiredLabel label="Servicio" sx={styles.inputLabel} />
                {id ? (
                  <TextField
                    id="service"
                    size="small"
                    variant="outlined"
                    placeholder="Nombre del servicio"
                    value={formValues.service}
                    InputProps={{
                      style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                    }}
                    disabled
                  />
                ) : (
                  <Autocomplete
                    sx={{ zIndex: 1302 }}
                    options={searchOptions}
                    freeSolo
                    disablePortal
                    getOptionLabel={(option) => option?.name || ""}
                    value={serviceSelected}
                    onChange={(event, newValue) => {
                      if (newValue?.id) {
                        setServiceSelected({
                          id: newValue.id,
                          name: newValue.name,
                        });
                      } else {
                        setServiceSelected({
                          id: null,
                          name: "",
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                        }}
                        placeholder={"Ingresa el nombre"}
                      />
                    )}
                    size="small"
                    onInputChange={(event) => {
                      if (event?.target?.value?.length >= 1) {
                        getSearchOptions(event?.target?.value);
                      }
                    }}
                  />
                )}
              </Stack>
              <Stack my={2} spacing={2}>
                <Stack spacing={1}>
                  <RequiredLabel label="Alias" sx={styles.inputLabel} />
                  <TextField
                    id="alias"
                    size="small"
                    variant="outlined"
                    {...register("alias", { required: true })}
                    value={formValues.alias}
                    InputProps={{
                      style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                      placeholder: "Ej: ENEL Colombia/oficina 1",
                    }}
                  />
                  <ValidationMessage errors={formState.errors} field="alias" />
                  <Typography sx={styles.inputLabelLegend}>
                    Elige un nombre que te ayuder a recordar el servicio
                  </Typography>
                </Stack>
              </Stack>
              <Stack my={2} spacing={2}>
                <Stack spacing={1}>
                  <RequiredLabel
                    label="Información para el pago"
                    sx={styles.inputLabel}
                  />
                  <TextField
                    id="referral_code"
                    size="small"
                    variant="outlined"
                    placeholder="Información para el pago"
                    {...register("referral_code", { required: true })}
                    value={formValues.referral_code}
                    InputProps={{
                      style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                    }}
                  />
                  <ValidationMessage
                    errors={formState.errors}
                    field="referral_code"
                  />
                  <Typography sx={styles.inputLabelLegend}>
                    Digita el número de cliente, teléfono, referencia de pago o
                    lo que creas necesario para proceder con el pago.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </div>
        </div>
        <DrawerFooter customStyle={{ width: "100%" }}>
          <Stack direction="row" sx={styles.footer}>
            <SecondaryButton text="Cancelar" action={onCancel} width="162px" />
            <PrimaryButton
              isDisable={
                createRequestInProcess ||
                (!id && objectIsEmpty(serviceSelected))
              }
              text="Guardar"
              type="submit"
              width="162px"
            />
          </Stack>
        </DrawerFooter>
      </div>
    </form>
  );
};

export default ServicesForm;

import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ArrowRightWithoutStick } from "assets";
import { useNavigate } from "react-router-dom";
import { ChangeUserCompany } from "services";
import styles from "../company-switch-modal.module.css";
import { toastOptions } from "constants";
import { toast } from "react-toastify";
import { userRoles } from "constants";
import { useMediaQuery } from "react-responsive";

export const RenderCompany = ({ company, onClose, showRole = false }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const initials = company?.companyName
    ?.match(/\b(\w)/g)
    ?.join("")
    ?.toUpperCase()
    ?.slice(0, 2);

  return (
    <Box
      key={company.name}
      className={styles["user-container"]}
      onClick={() => {
        ChangeUserCompany({ id: company.companyId }).then((res) => {
          onClose();
          if (res?.message === "Company changed") {
            navigate("/payments/invoices", { replace: true });
            window.location.reload(true);
          } else {
            toast.error(
              "Ha ocurrido un error, intente nuevamente",
              toastOptions
            );
          }
        });
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            width: isMobile ? "100%" : "50%",
          }}
        >
          {company?.filePathLogo ? (
            <img
              style={{
                height: "53px",
                width: "53px",
                borderRadius: "50%",
              }}
              src={company?.filePathLogo}
              alt="companyLogo"
            />
          ) : (
            <div
              style={{
                width: "53px",
                height: "53px",
                borderRadius: "150px",
                backgroundColor: "#D0D5DD",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                  color: "#344054",
                }}
              >
                {initials}
              </Typography>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "14px",
                textOverflow: "ellipsis",
                alignSelf: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {company.companyName}{" "}
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                textOverflow: "ellipsis",
                color: "#667085",
                fontWeight: "500",
              }}
            >
              NIT {company.documentNumber}
            </Typography>
          </div>
        </div>
        {showRole && (
          <Typography
            sx={{
              color: "#475467",
              fontSize: "14px",
              alignSelf: "center",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              textAlign: "left",
              width: "15%",
            }}
          >
            {userRoles.find(({ value }) => value === company.roleName)?.label}
          </Typography>
        )}
        <ArrowRightWithoutStick stroke={"#667085"} />
      </Stack>
    </Box>
  );
};

import React from "react";
import { Divider } from "@mui/material";
import styles from "./styles.module.css";

const SupplierDetailsInvoiceForm = ({
  bankName = "",
  accountTypeTitle,
  accountNumber = "",
  documentType = "NIT",
  documentNumber = "",
  email = "",
}) => (
  <div className={styles.container}>
    <div className={styles.cardContainer}>
      <p className={`card-subtitle mb-2 ${styles.cardTitle}`}>
        Entidad bancaria
      </p>
      <p className={`"card-subtitle mb-2 ${styles.cardText}`}>{bankName}</p>
    </div>
    <Divider sx={{ marginBottom: "8px" }} />
    <div className={styles.cardContainer}>
      <p className={`card-subtitle mb-2 ${styles.cardTitle}`}>
        {accountTypeTitle}
      </p>
      <p className={`"card-subtitle mb-2 ${styles.cardText}`}>
        {accountNumber}
      </p>
    </div>
    <Divider sx={{ marginBottom: "8px" }} />
    <div className={styles.cardContainer}>
      <p className={`card-subtitle mb-2 ${styles.cardTitle}`}>{documentType}</p>
      <p className={`"card-subtitle mb-2 ${styles.cardText}`}>
        {documentNumber}
      </p>
    </div>
    <Divider sx={{ marginBottom: "8px" }} />
    <div className={styles.cardContainer}>
      <p className={`card-subtitle mb-2 ${styles.cardTitle}`}>
        Correo electrónico
      </p>
      <p className={`"card-subtitle mb-2 ${styles.cardText}`}>{email}</p>
    </div>
  </div>
);

export default SupplierDetailsInvoiceForm;

export default function getComissionDetails(
  newPricing,
  newPricingMetadata,
  selectedPaymentMethod,
  cardFeePercentage,
  formatAmount,
  type,
  payanaComission,
  section
) {
  const value = formatAmount.format(payanaComission);
  if (
    newPricing &&
    !newPricingMetadata.payment_percentage &&
    selectedPaymentMethod === "others" &&
    section !== "customer"
  ) {
    const comissionFixedAmount = formatAmount.format(newPricingMetadata.amount);
    const entityName = type === "invoice" ? "proveedor" : "documento";
    return {
      label: `Comisión Payana (${comissionFixedAmount}/${entityName})`,
      value,
    };
  }
  if (selectedPaymentMethod === "card") {
    return {
      label: `Comisión tarjeta de crédito (${cardFeePercentage}%)`,
      value,
    };
  }
  if (
    newPricing &&
    newPricingMetadata.payment_percentage &&
    selectedPaymentMethod === "others" &&
    section !== "customer"
  ) {
    return {
      label: `Comisión Payana (${newPricingMetadata.payment_percentage}%)`,
      value,
    };
  }
}

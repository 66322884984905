import { styles } from "./styles";

const PaymentModalRow = ({ label, value }) => (
  <div style={styles.sourceOfFundsInfoRow}>
    <p style={styles.sourceOfFundsInfoTitle}>{label}</p>
    <p style={styles.sourceOfFundsInfoTotal}>{value}</p>
  </div>
);

export default PaymentModalRow;

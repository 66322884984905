import React from "react";

export const DownloadFileIcon = ({ size = "18", stroke = "#98A2B3" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.6665 13.1667L8.99984 16.5M8.99984 16.5L12.3332 13.1667M8.99984 16.5V9M15.6665 12.9524C16.6844 12.1117 17.3332 10.8399 17.3332 9.41667C17.3332 6.88536 15.2811 4.83333 12.7498 4.83333C12.5677 4.83333 12.3974 4.73833 12.3049 4.58145C11.2182 2.73736 9.21186 1.5 6.9165 1.5C3.46472 1.5 0.666504 4.29822 0.666504 7.75C0.666504 9.47175 1.36271 11.0309 2.48896 12.1613"
      stroke={stroke}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";

export const EditUserIcon = () => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none">
    <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#EBE9FE" />
    <rect
      x="4.5"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#F4F3FF"
      strokeWidth="8"
    />
    <path
      d="M25.5 31.5H24C22.6044 31.5 21.9067 31.5 21.3389 31.6722C20.0605 32.06 19.06 33.0605 18.6722 34.3389C18.5 34.9067 18.5 35.6044 18.5 37M31 23.5C31 25.9853 28.9853 28 26.5 28C24.0147 28 22 25.9853 22 23.5C22 21.0147 24.0147 19 26.5 19C28.9853 19 31 21.0147 31 23.5ZM27.5 37L30.6014 36.1139C30.7499 36.0715 30.8241 36.0502 30.8934 36.0184C30.9549 35.9902 31.0134 35.9558 31.0679 35.9158C31.1293 35.8707 31.1839 35.8161 31.2932 35.7068L37.75 29.25C38.4404 28.5597 38.4404 27.4403 37.75 26.75C37.0597 26.0596 35.9404 26.0596 35.25 26.75L28.7932 33.2068C28.6839 33.3161 28.6293 33.3707 28.5842 33.4321C28.5442 33.4866 28.5098 33.5451 28.4816 33.6066C28.4497 33.6759 28.4285 33.7501 28.3861 33.8987L27.5 37Z"
      stroke="#7F56D9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

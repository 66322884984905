import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { Divider } from "@mui/material";
import {
  DeleteIcon,
  DownloadDocumentIcon,
  DownloadFileIcon2,
} from "../../../assets";
import { styles } from "../styles";
import { ActionsButton } from "./ActionsButton";
import Downloader from "../../../services/Downloader";
import { UiContext } from "../../../contexts/UiContext";
import { fetchDianPDF } from "../../../services/fetch-dian-url";
import { invoiceCanBeDeleted } from "./utils";

export const ActionsDropdown = ({ deleteAction, row = {}, disabled }) => {
  const { id, file_path, receipt_file_path, status, transaction_status } = row;
  const {
    actions: { toggleLayoutLinearProgress },
  } = useContext(UiContext);
  const downloadFile = async (filePath, invoiceId, type) => {
    if (filePath.includes("https://catalogo-vpfe.dian.gov.co/document")) {
      toggleLayoutLinearProgress();
      const response = await fetchDianPDF(invoiceId);
      toggleLayoutLinearProgress();
      return Downloader.push(response.url, `${type}-${invoiceId}.pdf`);
    }
    return Downloader.push(filePath, `${type}-${invoiceId}.pdf`);
  };

  const isPendingWithoutTrxId =
    status === "pending" && invoiceCanBeDeleted(row);

  return (isPendingWithoutTrxId || receipt_file_path || file_path) &&
    !disabled ? (
    <Dropdown>
      <ActionsButton />
      <Dropdown.Menu style={styles.noPadding}>
        {receipt_file_path && (
          <Dropdown.Item
            style={styles.actionsDropdownPadding}
            onClick={() => downloadFile(receipt_file_path, id, "Soporte")}
          >
            <div style={styles.dropdownItemContainer} type="dropdown">
              <DownloadFileIcon2
                height={"18px"}
                width={"14px"}
                stroke={"#344054"}
              />
              <div style={styles.dropdownItemTextContainer}>
                <p style={styles.actionsDropdownItemText}>Descargar soporte</p>
              </div>
            </div>
          </Dropdown.Item>
        )}
        {file_path && (
          <Dropdown.Item
            style={styles.actionsDropdownPadding}
            onClick={() => downloadFile(file_path, id, "Documento")}
          >
            <div style={styles.dropdownItemContainer} type="dropdown">
              <DownloadDocumentIcon
                height={"18px"}
                width={"14px"}
                stroke={"#344054"}
              />
              <div style={styles.dropdownItemTextContainer}>
                <p style={styles.actionsDropdownItemText}>
                  Descargar documento
                </p>
              </div>
            </div>
          </Dropdown.Item>
        )}
        <Divider />
        {isPendingWithoutTrxId && (
          <Dropdown.Item
            style={styles.actionsDropdownPadding}
            onClick={() => {
              deleteAction([id]);
            }}
          >
            <div style={styles.dropdownItemContainer} type="dropdown">
              <DeleteIcon height={"18px"} width={"14px"} stroke={"#B42318"} />
              <div style={styles.dropdownItemTextContainer}>
                <p style={styles.actionsDropdownItemDeleteText}>Eliminar</p>
              </div>
            </div>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

import React from "react";
import { styles } from "../styles";
import { CloseIcon, StarIcon } from "assets";
import { Typography } from "@mui/material";

export const CausationInfoBanner = ({
  setShowCausationModal = () => {},
  saveInLocalStorage = () => {},
  setCausationModalVisible = () => {},
  setCausationInfoVisible = () => {},
}) => {
  return (
    <div style={styles.causationBannerContainer}>
      <div style={{ ...styles.row, gap: "12px" }}>
        <div
          style={{
            width: "24px",
            height: "24px",
            marginRight: "18px",
            marginTop: "-8px",
          }}
        >
          <StarIcon size={"42"} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "4px",
            marginTop: "2px",
          }}
        >
          <Typography sx={styles.causationBannerTitle}>
            ¡DESCUBRE CÓMO <strong>CAUSAR</strong> DESDE AQUÍ!
          </Typography>
        </div>
      </div>
      <div style={{ ...styles.row, gap: "8px" }}>
        <div style={styles.causationBannerAction}>
          <Typography
            style={styles.causationBannerLink}
            onClick={() => {
              setShowCausationModal(true);
            }}
          >
            Ver más
          </Typography>
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            saveInLocalStorage("isCausationModalVisible", "false");
            saveInLocalStorage("isCausationInfoVisible", "false");
            setCausationModalVisible(false);
            setCausationInfoVisible("false");
          }}
        >
          <CloseIcon color={"#667085"} />
        </div>
      </div>
    </div>
  );
};

import React from "react";
import "./styles.css";

export const CheckboxPayana = ({ checked, value, onClick }) => {
  return (
    <input
      type="checkbox"
      className={"checkbox-custom"}
      checked={checked}
      value={value}
      onClick={onClick}
    />
  );
};

import { IconButton } from "@mui/material";
import { SendIcon } from "assets";
import { Tooltip } from "commons";
import { toastOptions } from "constants";
import { toast } from "react-toastify";

const SendCollectionToClient = ({
  row,
  blockedCompany,
  setOpenBlockedModal,
  setOpenCollectionIncomplete,
  collectionIsIncomplete,
  isChecked,
  toggleSelectedRow,
  setOpenCustomerIncomplete,
  SendNotification,
  refreshCollectionsTable,
  handleAction,
}) => {
  return (
    <Tooltip title="Enviar notificación al cliente">
      <IconButton
        sx={{ margin: "0px 10px" }}
        onClick={() => {
          if (blockedCompany) {
            setOpenBlockedModal(true);
          } else {
            if (!row.original.expiration_date) {
              setOpenCollectionIncomplete(true);
            } else if (collectionIsIncomplete(row.original)) {
              !isChecked(row.original) && toggleSelectedRow(row.original);
              setOpenCustomerIncomplete(true);
            } else {
              handleAction(() =>
                SendNotification([row.original.id]).then((res) => {
                  if (res?.message) {
                    toast.error(
                      "Ocurrió un error, por favor revisa el email o número de teléfono de tu cliente",
                      toastOptions
                    );
                  } else {
                    toast.success(
                      `La notificación fue enviada con éxito`,
                      toastOptions
                    );
                    refreshCollectionsTable();
                  }
                })
              );
            }
          }
        }}
      >
        <SendIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SendCollectionToClient;

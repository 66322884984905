import React, { useState } from "react";
import { toast } from "react-toastify";

import { IMAGE_TYPES, toastOptions } from "../../../constants";

import { uploadCompanyLogo } from "../../../services/api/user";
import FileUpload from "../../../commons/modals/ExpandInvoiceModal/components/FileUpload";

const ChangeCompanyLogo = ({ getMe }) => {
  const [selectedImage, setSelectedImage] = useState();
  const [error, setError] = useState(null);

  const handleUploadImage = async (img) => {
    var formData = new FormData();
    formData.append("files", img[0], img[0].name);
    await uploadCompanyLogo(formData)
      .then(() => {
        getMe();
        toast.success("¡El archivo se subió con éxito!", toastOptions);
      })
      .catch(() =>
        toast.success("Hubo un error subiendo el archivo", toastOptions)
      );
  };

  const handleSetSelectedImage = (img) => {
    if (Array.isArray(img)) {
      if (!IMAGE_TYPES.includes(img[0]?.type)) {
        setSelectedImage(null);
        setError("El formato de imagen debe ser .jpg, .jpeg o .png.");
      } else {
        setError(null);
        setSelectedImage(img);
        handleUploadImage(img);
      }
    }
  };
  return (
    <div style={{ width: "512px" }}>
      <FileUpload
        selectedFiles={selectedImage}
        setSelectedFiles={handleSetSelectedImage}
        renderImg
      />
      <span style={{ color: "red" }}>{error}</span>
    </div>
  );
};
export default ChangeCompanyLogo;

import { createContext, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../../constants";
import {
  CreateBOSupplier,
  CreateBOSupplierServices,
  DeleteBOSupplier,
  GetBOSupplier,
  GetBOSuppliers,
  UpdateBOSupplier,
  GetAllSuppliers,
} from "../../services";

export const BackofficeSuppliersContext = createContext();

export function BackofficeSupplierProvider({ children }) {
  const [BOSuppliers, setBOSuppliers] = useState([]);
  const [allBOSuppliers, setAllBOSuppliers] = useState([]);

  const getSuppliers = async (
    pageIndex,
    providerId,
    companyId,
    querySearch
  ) => {
    const gottenSuppliers = await GetBOSuppliers({
      pageIndex,
      providerId,
      companyId,
      querySearch,
    });
    if (gottenSuppliers) {
      setBOSuppliers(gottenSuppliers);
    }
  };

  const getAllBOSuppliers = async (pageIndex = 1) => {
    const gottenSuppliers = await GetAllSuppliers(pageIndex);
    if (gottenSuppliers) {
      setAllBOSuppliers(gottenSuppliers);
    }
  };

  const getSupplier = async (id) => {
    if (id) {
      const gottenSupplier = await GetBOSupplier({
        id,
      });
      return gottenSupplier;
    }
  };

  const createSupplier = async (formValues) => {
    const response = await CreateBOSupplier({
      formValues: formValues,
    });
    if (response?.provider_id) {
      toast.success("¡El proveedor se creó con éxito!", toastOptions);
      getSuppliers(1, "all");
      return 200;
    }
    return "error";
  };

  const createSupplierServices = async (formValues) => {
    const response = await CreateBOSupplierServices({
      formValues: formValues,
    });
    if (response?.company_service_id) {
      toast.success("¡El servicio se creó con éxito!", toastOptions);
      getSuppliers(1, "all");
      return 200;
    }
    toast.error("¡El servicio no se pudo crear", toastOptions);
    return "error";
  };

  const deleteSupplier = async (id) => {
    const response = await DeleteBOSupplier({ id: id });
    if (response) {
      toast.success("¡El proveedor se borró con éxito!", toastOptions);
      getSuppliers(1, "all");
      return 200;
    }
    return "error";
  };

  const updateSupplier = async (id, formValues) => {
    const response = await UpdateBOSupplier({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      toast.success("¡El proveedor se actualizó con éxito!", toastOptions);
      getSuppliers(1, "all");
      return 200;
    }
    return "error";
  };

  return (
    <BackofficeSuppliersContext.Provider
      value={{
        BOSuppliers,
        getSuppliers,
        getSupplier,
        createSupplier,
        deleteSupplier,
        updateSupplier,
        allBOSuppliers,
        getAllBOSuppliers,
        setBOSuppliers,
        createSupplierServices,
      }}
    >
      {children}
    </BackofficeSuppliersContext.Provider>
  );
}

import React from "react";

import { Divider } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { styles } from "../styles";

export const CardCommissionSummarySection = ({ transaction }) => {
  return (
    <div style={styles.sectionContainer}>
      <p style={styles.sectionTitle}>Resumen</p>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          Monto del lote
        </p>
        <p style={styles.transactionHash} className="card-subtitle mb-2">
          <NumericFormat
            value={parseInt(transaction?.lote_amount)}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            decimalScale={2}
          />
        </p>
      </div>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          Comisión
        </p>
        <p style={styles.transactionHash} className="card-subtitle mb-2">
          {transaction?.commission_percentage}
        </p>
      </div>
      <Divider sx={styles.sectionDivider} />
      <div style={styles.sectionRow}>
        <p
          style={styles.transactionNetCommissionTitle}
          className="card-subtitle mb-2"
        >
          Monto neto
        </p>
        <p
          style={styles.transactionNetCommission}
          className="card-subtitle mb-2"
        >
          <NumericFormat
            value={parseInt(transaction?.amount_net_comission)}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            decimalScale={2}
          />
        </p>
      </div>
    </div>
  );
};

import React from "react";

export const PlusCircleIcon = ({
  stroke = "#5925DC",
  width = "21",
  height = "20",
}) => (
  <svg width={width} height={height} viewBox="0 0 21 20" fill="none">
    <g id="plus-circle">
      <path
        id="Icon"
        d="M10.5001 6.66675V13.3334M7.16675 10.0001H13.8334M18.8334 10.0001C18.8334 14.6025 15.1025 18.3334 10.5001 18.3334C5.89771 18.3334 2.16675 14.6025 2.16675 10.0001C2.16675 5.39771 5.89771 1.66675 10.5001 1.66675C15.1025 1.66675 18.8334 5.39771 18.8334 10.0001Z"
        stroke={stroke}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

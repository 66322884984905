import { useState } from "react";

export const useSelectedRows = (identifierField = "id") => {
  const [selectedRows, setSelectedRows] = useState([]);

  const isChecked = (row) => {
    return !!selectedRows.find(
      (selectedRow) =>
        selectedRow.original[identifierField] === row[identifierField]
    );
  };

  const toggleSelectedRow = (row) => {
    if (isChecked(row)) {
      setSelectedRows((rows) =>
        rows.filter((r) => r.original[identifierField] !== row[identifierField])
      );
    } else {
      setSelectedRows((rows) => [...rows, { original: row }]);
    }
  };

  const toggleSelectedIds = (ids) => {
    ids.map((id) =>
      setSelectedRows((rows) =>
        rows.filter((r) => r.original[identifierField] !== id)
      )
    );
  };

  const toggleSelectedAll = (row, selectAllChecked) => {
    if (selectAllChecked) {
      !isChecked(row) &&
        setSelectedRows((rows) => [...rows, { original: row }]);
    } else {
      setSelectedRows((rows) =>
        rows.filter((r) => r.original[identifierField] !== row[identifierField])
      );
    }
  };

  const resetSelectedRows = () => {
    setSelectedRows([]);
  };

  const selectedRowsIds = selectedRows.map(
    (row) => row.original[identifierField]
  );

  return {
    selectedRows,
    isChecked,
    toggleSelectedRow,
    toggleSelectedIds,
    toggleSelectedAll,
    resetSelectedRows,
    selectedRowsIds,
  };
};

import React from "react";
import { PlusCircleIcon } from "assets";
import styles from "../company-switch-modal.module.css";
import { ButtonWithIcon } from "commons/components";
import { RenderCompany } from "./RenderCompany";
import { MarginTwoTone } from "@mui/icons-material";

export const MultiAccount = ({ companies, onClick, onClose }) => {
  const getCompanies = () => {
    return companies.map((company) => {
      return (
        <RenderCompany company={company} onClose={onClose} showRole={true} />
      );
    });
  };

  return (
    <>
      <div className="modal-header--sticky-2">
        <div className={styles["multi-title"]}>Empresas</div>
        <div className={styles["row"]}>
          <div className={styles["description"]}>
            Elige la empresa a la cual quieres ir
          </div>
          <ButtonWithIcon
            action={() => onClick()}
            buttonLabel={"Crear empresa"}
            icon={<PlusCircleIcon stroke="#FFFFFF" width="20" height="20" />}
            width={"155px"}
            style={{ marginTop: "12px", marginBottom: "12px" }}
          />
        </div>
      </div>
      {getCompanies()}
    </>
  );
};

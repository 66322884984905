import { Skeleton } from "@mui/material";
import { styles } from "../styles";

export const TableSkeleton = () => {
  return (
    <div style={styles.skeletonMargins}>
      <Skeleton variant="rectangular" width={"100%"} height={64} />
      {[...Array(5)].map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={"100%"}
          height={40}
          style={{ marginTop: "12px" }}
        />
      ))}
      <Skeleton
        variant="rectangular"
        width={"40%"}
        height={24}
        style={{
          marginTop: "12px",
          marginLeft: "auto",
        }}
      />
    </div>
  );
};

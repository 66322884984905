import React from "react";
import { Box } from "@mui/material";
import { Button, CloseButton } from "react-bootstrap";
import { SecondaryButton } from "commons/buttons";
import { styles } from "../styles";
import { useNavigate } from "react-router-dom";

export function ExpandAssistedExpensesHeader({
  handleSubmit,
  disabledButtonToSiigo,
}) {
  const navigate = useNavigate();
  return (
    <div className="modal-header-payana">
      <div style={styles.headerRow}>
        <div>
          <CloseButton
            style={{ boxShadow: "none" }}
            onClick={() => navigate(-1)}
          />
        </div>
        <div
          className="modalTitle"
          style={{ marginLeft: "16px", fontSize: "18px" }}
        >
          Registro de pago
        </div>
      </div>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          onClick={() => handleSubmit()}
          variant="primary"
          style={styles.modalSaveButton}
          disabled={!disabledButtonToSiigo}
        >
          Registrar egreso en Siigo
        </Button>
      </Box>
    </div>
  );
}

export const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    marginTop: "12px",
    textAlign: "center",
  },
  subtitle: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    justifyContent: "center",
  },
  modalSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
    marginRight: "12px",
  },
  modalPrimaryButton: {
    backgroundColor: "#D92D20",
    border: "1px solid #6938EF",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
  },
};

import { useState } from "react";
import addRetentionToSelectedRetentions from "./addRetentionToSelectedRetentions";

function useRetentionsSelection(initialRetentionKeys = []) {
  const [selectedRetentions, setSelectedRetentions] = useState(() =>
    initialRetentionKeys.length > 0 ? initialRetentionKeys : ["none"]
  );
  const selectRetention = (retention) => {
    setSelectedRetentions((retentions) =>
      addRetentionToSelectedRetentions(retentions, retention)
    );
  };
  return {
    selectedRetentions,
    selectRetention,
  };
}

export default useRetentionsSelection;

export const collectionFileUploadStyles = {
  styles: {
    imageContainer: {
      height: 83,
      display: "flex",
      flexDirection: "column",
      maxWidth: 330,
      padding: "16px",
    },
    image: {
      height: "75%",
      width: "100%",
      objectFit: "scale-down",
    },
    span: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
};

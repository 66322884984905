export function PricingIcon({ color = "#93370D" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00008 9.16668V12.5M15.0001 7.50001V10.8333M14.1667 3.33334C16.2073 3.33334 17.311 3.64564 17.8602 3.88788C17.9333 3.92014 17.9699 3.93627 18.0754 4.03699C18.1387 4.09736 18.2542 4.2745 18.2839 4.35675C18.3334 4.49396 18.3334 4.56896 18.3334 4.71896V13.676C18.3334 14.4333 18.3334 14.8119 18.2199 15.0066C18.1043 15.2045 17.9929 15.2966 17.7767 15.3727C17.5641 15.4475 17.1351 15.365 16.2769 15.2001C15.6762 15.0847 14.9638 15 14.1667 15C11.6667 15 9.16675 16.6667 5.83341 16.6667C3.79283 16.6667 2.68914 16.3544 2.13998 16.1121C2.06685 16.0799 2.03028 16.0638 1.92475 15.963C1.86149 15.9027 1.74601 15.7255 1.71631 15.6433C1.66675 15.5061 1.66675 15.4311 1.66675 15.2811L1.66675 6.32405C1.66675 5.56675 1.66675 5.1881 1.78031 4.99346C1.89583 4.79548 2.00724 4.70344 2.22346 4.62735C2.43603 4.55255 2.86511 4.635 3.72327 4.79988C4.32393 4.91529 5.03631 5.00001 5.83341 5.00001C8.33342 5.00001 10.8334 3.33334 14.1667 3.33334ZM12.0834 10C12.0834 11.1506 11.1507 12.0833 10.0001 12.0833C8.84949 12.0833 7.91675 11.1506 7.91675 10C7.91675 8.84942 8.84949 7.91668 10.0001 7.91668C11.1507 7.91668 12.0834 8.84942 12.0834 10Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

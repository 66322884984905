import { formatLocaleDate } from "./formatLocaleDate";

export const invoicesTransactionsColumns = (searchInputText = "") => [
  {
    field: "hash",
    Header: "Código de transacción",
    headerName: "Código de transacción",
    accessor: "hash",
    id: "hash",
    Cell: ({ value }) => {
      const hash = value?.toUpperCase();
      if (value && searchInputText) {
        const startIndex = hash.indexOf(searchInputText.toUpperCase());
        const endIndex = startIndex + searchInputText.length;
        return (
          <span>
            {hash.slice(0, startIndex)}
            <strong>{searchInputText.toUpperCase()}</strong>
            {hash.slice(endIndex)}
          </span>
        );
      }
      if (value) {
        return hash;
      }
    },
  },
  {
    field: "created_at",
    Header: "Fecha de creación",
    headerName: "Fecha de creación",
    accessor: "created_at",
    id: "created_at",
    Cell: ({ value }) => {
      if (value) {
        return formatLocaleDate(value, "d MMM yyyy, h:mm aaaa");
      }
    },
  },
  {
    field: "paid_at",
    Header: "Fecha de pago",
    headerName: "Fecha de pago",
    accessor: "paid_at",
    id: "paid_at",
    Cell: ({ value }) => {
      if (value) {
        return formatLocaleDate(value, "d MMM yyyy, h:mm aaaa");
      }
    },
  },
  {
    field: "amount",
    Header: "Monto",
    headerName: "Monto",
    accessor: "amount",
    headerClassName: "amount-header",
    id: "amount",
  },
  {
    field: "status",
    Header: "Estado",
    headerName: "Estado",
    accessor: "status",
    id: "status",
  },
];

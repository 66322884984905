import React, { useContext, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Stack, Typography } from "@mui/material";
import { CloseButton } from "react-bootstrap";
import { PaymentsContext } from "../../../contexts";
import { BackofficeSuppliersContext } from "../../../contexts/Backoffice/suppliers";
import Tabs from "../../modals/SupplierDetails/components/Tabs";
import SupplierForm from "./forms/SupplierForm";
import ServicesForm from "./forms/ServicesForm";
import {
  handleFormattedFormValues,
  handleFormattedDetailSupplier,
} from "./utils";
import { styles } from "./styles";

export const SupplierDetails = ({
  isOpen,
  handleClose,
  id,
  needRedirectionCreate = false,
  needRedirectionEdit = false,
}) => {
  const { getAllBanks } = useContext(PaymentsContext);
  const {
    updateSupplier,
    createSupplier,
    getSupplier,
    createSupplierServices,
  } = useContext(BackofficeSuppliersContext);
  const [formType, setFormType] = useState(0);

  const [detailedSupplier, setDetailedSupplier] = useState(
    handleFormattedDetailSupplier()
  );

  const [accountHasDifferentHolder, setAccountHasDifferentHolder] =
    useState(false);

  useEffect(() => {
    if (isOpen) {
      getAllBanks();
      if (id) {
        getSupplier(id).then((res) => {
          setDetailedSupplier(handleFormattedDetailSupplier(res));
          if (res.account_holder_name) {
            setAccountHasDifferentHolder(true);
          }
        });
      }
    }
  }, [isOpen, id]);

  const createServiceHandle = (formValues) => {
    createSupplierServices(formValues);
    handleClose();
  };

  const handleChangeFormType = (formType) => {
    setFormType(formType);
  };

  const closeAndReset = () => {
    setAccountHasDifferentHolder(false);
    setDetailedSupplier(handleFormattedDetailSupplier());
    handleClose();
  };

  const updateOrCreateSupplierHandler = async (formValues) => {
    const formValuesToSend = handleFormattedFormValues(formValues);
    if (id) {
      updateSupplier(id, {
        ...formValuesToSend,
        has_identification_number_placeholder: false,
      });
    } else {
      createSupplier(formValuesToSend);
    }
    closeAndReset();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={closeAndReset}>
      <Stack
        sx={{ minWidth: "400px", height: "100%" }}
        px={3}
        py={2}
        spacing={2}
      >
        <Stack direction={"row"} justifyContent="space-between">
          <Typography sx={styles.title}>
            {id ? "Editar proveedor" : "Crear proveedor"}
          </Typography>
          <CloseButton style={{ boxShadow: "none" }} onClick={closeAndReset} />
        </Stack>
        {!id && (
          <Tabs onChange={handleChangeFormType} tabDefaultValue={formType} />
        )}
        {formType === 0 && (
          <SupplierForm
            detailedSupplier={detailedSupplier}
            onCancel={closeAndReset}
            onSubmit={updateOrCreateSupplierHandler}
            supplierHasDifferentHolder={accountHasDifferentHolder}
          />
        )}
        {formType === 1 && (
          <ServicesForm
            onSubmit={createServiceHandle}
            onCancel={closeAndReset}
          />
        )}
      </Stack>
    </Drawer>
  );
};

import { format } from "date-fns";
import { TrackJS } from "trackjs";

export const formatCellDate = (dateToFormat) => {
  try {
    return dateToFormat
      ? format(new Date(dateToFormat.slice(0, 19)), "dd/MM/yyyy")
      : "";
  } catch (error) {
    TrackJS.console.error(error);
    return "";
  }
};

import React from "react";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { SaveIcon } from "../../../assets";
import { markInvoicesAsPending } from "../../../services";
import { useContext } from "react";
import { PaymentsContext } from "../../../contexts";
import { useMediaQuery } from "react-responsive";
import { PrimaryButton, SecondaryButton } from "commons/buttons";

export const MarkAsPendingModal = ({
  visible,
  handleClose,
  selectedRowsIds,
}) => {
  const { changeStatus } = useContext(PaymentsContext);

  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const handleClick = async () => {
    const response = await markInvoicesAsPending({ ids: selectedRowsIds });
    if (response) {
      changeStatus(selectedRowsIds, "pending");
    }

    handleClose(selectedRowsIds);
  };

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body style={{ padding: 24, width: isMobile ? "330px" : "400px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SaveIcon />
          <p
            style={{
              color: "#101828",
              fontSize: "18px",
              fontWeight: "500",
              marginTop: "12px",
              textAlign: "left",
              alignSelf: "flex-start",
            }}
          >
            Cambiar estado de cuentas por pagar
          </p>
          <p
            style={{
              color: "#667085",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "left",
              alignSelf: "flex-start",
            }}
          >
            Presionar este botón hará que las cuentas por pagar seleccionadas
            cambien su estado a "Por pagar"
          </p>
          <div style={{ ...styles.row, gap: "8px", width: "100%" }}>
            <SecondaryButton
              text="Cancelar"
              action={() => handleClose(null)}
              style={{ width: "50%" }}
            />

            <PrimaryButton
              text="Confirmar"
              action={() => handleClick()}
              style={{ width: "50%" }}
            />
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

import React, { useContext } from "react";
import { styles } from "../styles";
import { CloseIcon, DocumentIcon, ModalDownloadIcon } from "../../../assets";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import { Button, IconButton, Link } from "@mui/material";
import {
  GenerateAutoReceipt,
  RemoveFile,
  UploadReceiptFile,
} from "../../../services/api/backoffice";
import { BackofficeReceiptContext } from "../../../contexts/Backoffice/receipts";
import { BackofficeReceiptServicesContext } from "../../../contexts/Backoffice/receiptsServices";
import Dropzone from "react-dropzone";

export const FileRow = ({ section, cell, pageIndex }) => {
  const { getBOreceipts } = useContext(BackofficeReceiptContext);
  const { getBOreceiptsService } = useContext(BackofficeReceiptServicesContext);
  const { id, provider_name, file_path, service_id, status, company_id } =
    cell.row.original;

  const generateReceipt = () => {
    GenerateAutoReceipt({ receipt_id: id })
      .then(() => {
        toast.success("¡Soporte generado con éxito!", toastOptions);
        getBOreceipts({ pageIndex: pageIndex + 1 });
      })
      .catch(() =>
        toast.error(
          "Ha ocurrido un error, por favor intente más tarde.",
          toastOptions
        )
      );
  };

  const removeFile = () => {
    RemoveFile({ id: id })
      .then((res) => {
        toast.success("¡El soporte se eliminó con éxito!", toastOptions);
        if (section === "BOReceiptsServices") {
          getBOreceiptsService({ pageIndex: pageIndex + 1 });
        } else getBOreceipts({ pageIndex: pageIndex + 1 });
      })
      .catch((err) => {
        toast.error("Hubo un error eliminando el soporte", toastOptions);
      });
  };

  const upload = (selectedFiles) => {
    var formdata = new FormData();
    formdata.append("receipt_id", `${id}`);
    formdata.append("company_id", `${company_id}`);
    formdata.append("files", selectedFiles[0], selectedFiles[0].name);
    UploadReceiptFile(formdata)
      .then((res) => {
        toast.success("¡El archivo se subió con éxito!", toastOptions);
        if (section === "BOReceiptsServices") {
          getBOreceiptsService({ pageIndex: pageIndex + 1 });
        } else getBOreceipts({ pageIndex: pageIndex + 1 });
      })
      .catch((err) => {
        toast.error("Hubo un error subiendo el archivo", toastOptions);
      });
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      upload(files);
    }
  };

  return (
    <>
      {(status === "paid" || section === "BOReceiptsServices") &&
      (provider_name || service_id) ? (
        <>
          {file_path ? (
            <div style={styles.row}>
              <Link href={file_path} target="_blank">
                <Button
                  startIcon={<DocumentIcon width="24" height="24" />}
                  variant="outlined"
                >
                  Bajar
                </Button>
              </Link>
              <IconButton onClick={() => removeFile()}>
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <div style={styles.column}>
              {section === "BOReceiptsServices" && (
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ className: "mini-dropzone" })}>
                        <input {...getInputProps()} />
                        <Button
                          startIcon={<ModalDownloadIcon size={"24"} />}
                          variant="outlined"
                        >
                          Subir
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
              {!service_id && (
                <Button
                  style={{
                    height: "37px",
                    minWidth: "84px",
                    marginBottom: "4px",
                  }}
                  variant="outlined"
                  onClick={() => generateReceipt()}
                >
                  Generar
                </Button>
              )}
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

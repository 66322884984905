import React from "react";
export const ModalSuccessIcon = () => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none">
    <rect x="5" y="5" width="56" height="56" rx="28" fill="#D1FADF" />
    <rect
      x="5"
      y="5"
      width="56"
      height="56"
      rx="28"
      stroke="#ECFDF3"
      strokeWidth="10"
    />
    <path
      d="M29.5007 32.9997L31.834 35.333L37.084 30.083M27.5567 23.4548C28.4945 23.38 29.3848 23.0112 30.1009 22.401C31.7718 20.977 34.2295 20.977 35.9004 22.401C36.6165 23.0112 37.5068 23.38 38.4446 23.4548C40.633 23.6295 42.3709 25.3673 42.5455 27.5557C42.6203 28.4935 42.9891 29.3838 43.5993 30.0999C45.0233 31.7709 45.0233 34.2285 43.5993 35.8995C42.9891 36.6155 42.6203 37.5058 42.5455 38.4436C42.3709 40.6321 40.633 42.3699 38.4446 42.5445C37.5068 42.6194 36.6165 42.9881 35.9004 43.5984C34.2295 45.0223 31.7718 45.0223 30.1009 43.5984C29.3848 42.9881 28.4945 42.6194 27.5567 42.5445C25.3683 42.3699 23.6304 40.6321 23.4558 38.4436C23.381 37.5058 23.0122 36.6155 22.402 35.8995C20.978 34.2285 20.978 31.7709 22.402 30.0999C23.0122 29.3838 23.381 28.4935 23.4558 27.5557C23.6304 25.3673 25.3683 23.6295 27.5567 23.4548Z"
      stroke="#039855"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";
import { Stack, TextField, Typography } from "@mui/material";

export function FormInputs({ label, ...inputProps }) {
  return (
    <Stack spacing={1}>
      <Typography sx={{ fontWeight: "600" }} noWrap>
        {label}
      </Typography>
      <TextField {...inputProps} />
    </Stack>
  );
}

export const handleFormattedEmployeeResponse = (res) => ({
  name: res?.name || "",
  documentType: res?.document_type || "",
  documentNumber: res?.document_number || "",
  email: res?.email || "",
  phone: res?.phone || "",
  bankId: res?.bank_id || "",
  bankName: res?.bank_name || "",
  accountType: res?.account_type || "",
  accountNumber: res?.account_number || "",
});

export const handleFormattedEmployeeForm = (formValues) => ({
  name: formValues?.name || "",
  document_type: formValues?.documentType || "",
  document_number: formValues?.documentNumber || "",
  email: formValues?.email || "",
  phone: formValues?.phone || "",
  bankId: formValues?.bankId || "",
  bankName: formValues?.bankName || "",
  accountType: formValues?.accountType || "",
  accountNumber: formValues?.accountNumber || "",
});

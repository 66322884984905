import React from "react";
import { styles } from "../styles";
import { accountTypes } from "../../../constants";

export const BankRow = ({ cell, section }) => {
  const row = cell.row.original;
  const type = accountTypes.find((o) => o.value === row.account_type);
  const supplierText =
    row.bank_name === "No definido"
      ? row.bank_name
      : `${row.bank_name} - ${type?.label} - ${row.account_number}`;

  const customersText = `${row.bank_name} ${
    type?.label ? `-  ${type?.label}` : ""
  } - ${row.account_number}`;

  return (
    <>
      <div style={styles.tableRow}>
        <p style={styles.revert}>
          {section === "customers" ? customersText : supplierText}
        </p>
      </div>
    </>
  );
};

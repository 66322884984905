import React from "react";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { ModalDeleteIcon } from "../../../assets";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { useMediaQuery } from "react-responsive";

export const DeleteItemModal = ({
  title,
  subtitle = "",
  action,
  visible,
  handleClose,
  itemId,
  itemsIds,
  cleanSelectedRows,
  pageIndex,
  deleteButtonText,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body
        style={{
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)",
          padding: 24,
          width: isMobile ? "330px" : "400px",
        }}
      >
        <Box sx={styles.container}>
          <ModalDeleteIcon />
          <p style={styles.title}>{title}</p>
          <p style={styles.subtitle}>{subtitle}</p>
          <div style={{ ...styles.row, gap: "8px", width: "100%" }}>
            <SecondaryButton
              width={"50%"}
              text="Cancelar"
              action={handleClose}
            />
            <PrimaryButton
              text={deleteButtonText || "Sí, borrar"}
              width={"50%"}
              action={() => {
                itemId && action(itemId, pageIndex);
                itemsIds && action(itemsIds, pageIndex);
                cleanSelectedRows && cleanSelectedRows();
                handleClose();
              }}
            />
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

import React from "react";
import { styles } from "./styles";
import { ListCheckIcon, RightArrowIcon } from "assets";
import { Stack } from "@mui/material";

export const TransactionCost = () => {
  return (
    <>
      <p
        style={{
          ...styles.cardInformationTitle,
          marginTop: "16px",
          marginBottom: "8px",
        }}
      >
        Costo por transacción
      </p>
      <div>
        <div style={styles.cardInformationListContainer}>
          <ListCheckIcon stroke="#6938EF" strokeWidth="2.5" />
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <p style={styles.transactionCostTextList}>{"Pagos"}</p>
            <RightArrowIcon size="10px" />
            <p style={styles.transactionCostTextList}>{"$3.300/proveedor"}</p>
          </Stack>
        </div>
        <div style={styles.cardInformationListContainer}>
          <ListCheckIcon stroke="#6938EF" strokeWidth="2.5" />
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <p style={styles.transactionCostTextList}>{"Cobros"}</p>
            <RightArrowIcon size="10px" />
            <p style={styles.transactionCostTextList}>{"$5.000/proveedor"}</p>
          </Stack>
        </div>
      </div>
    </>
  );
};

export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
    gap: "12px",
  },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    marginTop: "12px",
    textAlign: "flex-start",
    alignSelf: "flex-start",
  },
  subtitle: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    marginTop: "-4px",
    textAlign: "flex-start",
    alignSelf: "flex-start",
    maxWidth: "410px",
  },
  text: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
  },
  label: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "-6px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "16px 32px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "12px",
    gap: "12px",
    width: "100%",
  },
  input: {
    width: "100%",
    fontSize: "14px",
  },
  emptyInput: {
    width: "100%",
    color: "#A2A2A2",
    fontSize: "14px",
  },
  roleChecklistContainer: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    borderRadius: "8px",
    backgroundColor: "#F9FAFB",
    width: "100%",
    height: "auto",
    gap: "4px",
  },
  roleChecklistTitle: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#1D2939",
    marginBottom: "4px",
  },
};

export const SKELETON_SUPPLIER = {
  name: "",
  documentType: "",
  documentNumber: "",
  email: "",
  phone: "",
  bankId: "",
  accountType: "",
  accountNumber: "",
  accountHolderName: "",
  accountHolderDocumentType: "",
  accountHolderDocumentNumber: "",
  accountHasDifferentHolder: false,
};

export const SKELETON_SERVICE = {
  alias: "",
  service: "",
  referral_code: "",
};

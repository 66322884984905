import { useContext, useEffect, useState } from "react";
import {
  handleFormattedSupplierForm,
  handleFormattedSupplierResponse,
} from "../../commons/modals/SupplierDetails/utils";
import SupplierForm from "../../commons/modals/SupplierDetails/forms/SupplierForm";
import { PaymentsContext } from "../../contexts";
import { Drawer, Stack, Typography } from "@mui/material";
import DrawerSuccess from "../../commons/modals/SupplierDetails/forms/SupplierForm/DrawerSuccess";
import { SKELETON_SUPPLIER } from "../../commons/modals/SupplierDetails/constants";
import { styles } from "../../commons/modals/SupplierDetails/styles";
import { CloseButton } from "../../commons";

const DrawerSupplierForm = ({
  drawerState,
  closeAndReset = () => {},
  setDrawerState,
  onFinish = () => {},
  flow,
}) => {
  const id = drawerState?.providerIdsToComplete?.at(0);
  const [detailedSupplier, setDetailedSupplier] = useState(SKELETON_SUPPLIER);
  const { updateSupplier, createSupplier, getSupplier } =
    useContext(PaymentsContext);
  useEffect(() => {
    getSupplier(id).then((res) =>
      setDetailedSupplier(handleFormattedSupplierResponse(res))
    );
  }, [drawerState]);

  const updateSupplierHandler = async (id, formValues) => {
    await updateSupplier(id, formValues);
  };

  const createSupplierHandler = async (formValuesToSend) => {
    await createSupplier(formValuesToSend);
  };

  const updateOrCreateSupplierHandler = (formValues) => {
    const formValuesToSend = handleFormattedSupplierForm(formValues);
    if (id) {
      updateSupplierHandler(id, formValuesToSend, () =>
        closeAndReset(formValuesToSend)
      );
    } else {
      createSupplierHandler(formValuesToSend, formValues?.bank_name);
      closeAndReset(formValuesToSend);
    }
  };

  const closeFlow = () => {
    setDrawerState(() => {
      return { key: "none" };
    });
  };

  return (
    <Drawer
      anchor="right"
      open={drawerState?.key === "complete"}
      onClose={closeFlow}
      style={{ zIndex: 1301 }}
    >
      <Stack
        sx={{
          minWidth: "400px",
          height: "100%",
          overflow: "overlay",
          scrollbarGutter: "stable",
          paddingTop: 2,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        {drawerState?.viewToShow === "submitted" ? (
          <DrawerSuccess
            numberOfProvidersToComplete={
              drawerState?.providerIdsToComplete?.length
            }
            onNext={() => {
              if (drawerState?.providerIdsToComplete?.length === 0) {
                closeFlow();
                return onFinish();
              }
              setDrawerState((state) => {
                return {
                  ...state,
                  viewToShow: "complete",
                };
              });
            }}
            onCancel={closeFlow}
            flow={flow}
          />
        ) : (
          <>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography sx={styles.title}>Completar proveedor</Typography>
              <CloseButton style={{ boxShadow: "none" }} onClick={closeFlow} />
            </Stack>
            <SupplierForm
              isCompletingData={true}
              detailedSupplier={detailedSupplier}
              onSubmit={updateOrCreateSupplierHandler}
              onCancel={closeFlow}
              onSuccess={(provider) => {
                setDrawerState((state) => {
                  return {
                    ...state,
                    updated: [provider],
                    providerIdsToComplete: state.providerIdsToComplete.slice(1),
                    viewToShow: "submitted",
                  };
                });
              }}
            />
          </>
        )}
      </Stack>
    </Drawer>
  );
};

export default DrawerSupplierForm;

import { FormControlLabel, Radio, styled } from "@mui/material";

export const CustomIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  marginLeft: "-8px",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

export const CustomCheckedIcon = styled(CustomIcon)({
  backgroundColor: "#F2F3FF",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  outline: "1px solid #7F56D9",
  outlineOffset: -1,
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#7F56D9,#7F56D9 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#F2F3FF",
  },
});

export const CustomStepsCheckedIcon = styled(CustomIcon)({
  backgroundColor: "#7F56D9",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  outline: "1px solid #7F56D9",
  outlineOffset: -1,
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    content: '""',
    backgroundImage:
      'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M9 16.2l-3.5-3.5-1.4 1.4 5 5 12-12-1.4-1.4L9 16.2z"/></svg>\')',
    backgroundSize: "12px 12px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
});

export const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
    marginLeft: "2px",
  },
}));

export function CustomRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomCheckedIcon />}
      icon={<CustomIcon />}
      {...props}
    />
  );
}

export function CustomStepsRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomStepsCheckedIcon />}
      icon={<CustomIcon />}
      {...props}
    />
  );
}

export const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: "#475467",
  fontWeight: "600",
  fontSize: "14px",
  backgroundColor: "white",
}));

export const GroupItems = styled("ul")({
  padding: 0,
});

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { styles } from "./styles";
import { ModalWarningIcon } from "../../../assets";
import { GetBOReceipt, SendEmail } from "../../../services";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";

export const SendEmailModal = ({ visible, onClose, id, type }) => {
  const [receipt, setReceipt] = useState({});

  let formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    GetBOReceipt({ id: id }).then((res) => {
      setReceipt(res.receipts.receipt);
    });
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleClick = () => {
    const data = {
      receipt_id: receipt.id,
      gateway_method: receipt.payment_gateway,
    };
    SendEmail({ data })
      .then((res) => {
        toast.success("¡El mail se envió con éxito!", toastOptions);
        onClose();
      })
      .catch((err) => {
        toast.error("Hubo un error enviando el mail", toastOptions);
        onClose();
      });
  };

  return (
    <Modal show={visible} onHide={onClose} centered>
      <Modal.Body>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ModalWarningIcon />
          <p style={styles.modalTitle}>¿Enviar comprobantes por mail?</p>
          <p style={styles.modalText}>
            Monto total: {formatAmount.format(receipt.amount)}
            <br />
            {type === "invoice"
              ? `Proveedor: ${receipt.provider_name}`
              : `Empleado: ${receipt.employee_name}`}
            <br />
            {type === "invoice"
              ? `Mail proveedor: ${receipt.provider_email}`
              : `Mail empleado: ${receipt.employee_email}`}
          </p>
          <div style={styles.row}>
            <Button
              variant="secondary"
              style={styles.modalSecondaryButton}
              onClick={() => handleClose()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              style={styles.modalPrimaryButton}
              onClick={() => handleClick()}
            >
              Confirmar
            </Button>
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

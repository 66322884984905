import { MenuItem, Select } from "@mui/material";
import { Show } from "commons";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};

export const ModalitiesDropdown = ({
  searchOptionSelected,
  modalitySelected,
  onChange,
}) => {
  return (
    <Show condition={searchOptionSelected?.modalities?.length > 1}>
      <div style={styles.container}>
        <Select
          size="small"
          variant="outlined"
          style={{ fontSize: "14px" }}
          value={modalitySelected}
          onChange={(e) => onChange(e.target.value)}
          displayEmpty={true}
        >
          {searchOptionSelected?.modalities.map((modality) => (
            <MenuItem
              key={modality.modalityId}
              value={modality}
              style={{ fontSize: "14px" }}
            >
              {modality.modalityTitle}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Show>
  );
};

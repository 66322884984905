import { CustomLink } from "../components";

export const Submenu = (items) => {
  return (
    <nav className="submenu">
      <div className="layout-container">
        <div className="submenuDiv">
          <ul>
            {items.items.map((item, i) => {
              return (
                <CustomLink key={i} to={item.to}>
                  {item.name}
                </CustomLink>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

import { useContext, useEffect, useState } from "react";
import { CollectionsContext } from "../../contexts";
import { Drawer, Stack, Typography } from "@mui/material";
import { styles } from "../../commons/modals/SupplierDetails/styles";
import { CloseButton } from "../../commons";
import { SKELETON_CUSTOMER } from "../../commons/modals/CustomerDetails/constants";
import {
  handleFormattedCustomerForm,
  handleFormattedCustomerResponse,
} from "../../commons/modals/CustomerDetails/utils";
import CustomerForm from "../../commons/modals/CustomerDetails/forms/CustomerForm";
import DrawerSuccess from "../../commons/modals/CustomerDetails/forms/CustomerForm/DrawerSuccess";
import { toast } from "react-toastify";
import { toastOptions } from "../../constants";

const DrawerCustomerForm = ({
  drawerState,
  closeAndReset = () => {},
  setDrawerState,
  onFinish = () => {},
}) => {
  const id = drawerState?.customerIdsToComplete?.at(0);
  const [detailedCustomer, setDetailedCustomer] = useState(SKELETON_CUSTOMER);
  const {
    updateCustomer,
    createCustomer,
    getCustomer,
    internalGetCollections,
    collectionsScreen,
    statusSelected,
    perPageSelected,
    sortingSelected,
  } = useContext(CollectionsContext);

  const pageIndex = collectionsScreen?.pageIndex;
  const customerIdSelected = collectionsScreen?.customerIdSelected?.value;

  useEffect(() => {
    getCustomer(id).then((res) =>
      setDetailedCustomer(handleFormattedCustomerResponse(res?.at(0)))
    );
  }, [drawerState]);

  const updateCustomerHandler = async (id, formValues) => {
    await updateCustomer(id, formValues);
  };

  const createCustomerHandler = async (formValuesToSend) => {
    const createdCustomer = await createCustomer(formValuesToSend);
    if (createdCustomer?.response?.status === 400) {
      toast.warn(createdCustomer.response.data.message, {
        ...toastOptions,
        autoClose: 10000,
      });
    }
  };

  const updateOrCreateCustomerHandler = (formValues) => {
    const formValuesToSend = handleFormattedCustomerForm(formValues);
    if (id) {
      updateCustomerHandler(id, formValuesToSend, () =>
        closeAndReset(formValuesToSend)
      );
    } else {
      createCustomerHandler(formValuesToSend);
      closeAndReset(formValuesToSend);
    }
  };

  const closeFlow = () => {
    internalGetCollections(
      pageIndex,
      statusSelected,
      customerIdSelected,
      perPageSelected,
      sortingSelected
    );
    setDrawerState(() => {
      return { key: "none" };
    });
  };

  return (
    <Drawer
      anchor="right"
      open={drawerState?.key === "complete"}
      onClose={closeFlow}
      style={{ zIndex: 1301 }}
    >
      <Stack
        sx={{
          minWidth: "400px",
          height: "100%",
          overflow: "overlay",
          scrollbarGutter: "stable",
          paddingTop: 2,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        {drawerState?.viewToShow === "submitted" ? (
          <DrawerSuccess
            numberOfProvidersToComplete={
              drawerState?.customerIdsToComplete?.length
            }
            onNext={() => {
              if (drawerState?.customerIdsToComplete?.length === 0) {
                closeFlow();
                return onFinish();
              }
              setDrawerState((state) => {
                return {
                  ...state,
                  viewToShow: "complete",
                };
              });
            }}
            onCancel={closeFlow}
          />
        ) : (
          <>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography sx={styles.title}>Completar cliente</Typography>
              <CloseButton style={{ boxShadow: "none" }} onClick={closeFlow} />
            </Stack>
            <CustomerForm
              isCompletingData={true}
              detailedCustomer={detailedCustomer}
              onSubmit={updateOrCreateCustomerHandler}
              onCancel={closeFlow}
              onSuccess={(customer) => {
                setDrawerState((state) => {
                  return {
                    ...state,
                    updated: [customer],
                    customerIdsToComplete: state.customerIdsToComplete.slice(1),
                    viewToShow: "submitted",
                  };
                });
              }}
            />
          </>
        )}
      </Stack>
    </Drawer>
  );
};

export default DrawerCustomerForm;

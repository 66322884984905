import { NumericFormat } from "react-number-format";
import { formatLocaleDate } from "./formatLocaleDate";

export const servicesColumns = [
  {
    field: "alias",
    Header: "Alias",
    headerName: "Alias",
    accessor: "alias",
    id: "alias",
    className: "provider-name-column",
    Cell: ({ value }) => {
      return (
        <p className="limit-text" style={{ margin: 0 }}>
          {value.toUpperCase()}
        </p>
      );
    },
  },
  {
    field: "service",
    Header: "Servicio",
    headerName: "Servicio",
    accessor: "service",
    id: "service",
    className: "provider-name-column",
    Cell: ({ value }) => {
      return (
        <p className="limit-text" style={{ margin: 0 }}>
          {value?.toUpperCase()}
        </p>
      );
    },
  },
  {
    field: "referral_code",
    Header: "Información de pago",
    headerName: "Información de pago",
    accessor: "referral_code",
    id: "referral_code",
    className: "provider-name-column",
    Cell: ({ value }) => {
      return (
        <p className="limit-text" style={{ margin: 0 }}>
          {value}
        </p>
      );
    },
  },
];
export const servicesPaymentColumns = () => [
  {
    field: "service_name",
    headerName: "service_name",
    accessor: "service_name",
    id: "service_name",
    Header: () => <div className="pill-header">Empresa</div>,
  },
  {
    field: "due_date",
    headerName: "due_date",
    accessor: "expiration_date",
    id: "due_date",
    Header: () => <div className="pill-header">Vencimiento</div>,
    Cell: ({ value }) => {
      if (value) {
        return formatLocaleDate(value.slice(0, -1), "dd MMM, y");
      }
    },
  },
  {
    field: "amount_total_service",
    headerName: "amount_total_service",
    accessor: "amount_total",
    id: "amount_total_service",
    className: "amount_total-column",
    Header: () => <div className="pill-header">Monto factura</div>,
    Cell: ({ value }) => {
      if (value) {
        return (
          <NumericFormat
            value={parseFloat(value)}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="$"
            decimalScale={2}
          />
        );
      }
    },
  },
  {
    field: "status",
    headerName: "status",
    accessor: "status",
    id: "status",
    Header: () => <div className="pill-header">Estado</div>,
  },
];
export const servicesAgendaColumns = () => [
  {
    field: "service_name",
    headerName: "service_name",
    accessor: "name",
    id: "service_name",
    Header: () => <div className="pill-header">Empresa</div>,
  },
  {
    field: "reference",
    headerName: "reference",
    accessor: "referral_code",
    id: "reference",
    Header: () => <div className="pill-header">Referencia</div>,
  },
  {
    field: "alias",
    headerName: "alias",
    accessor: "alias",
    id: "alias",
    Header: () => <div className="pill-header">Alias</div>,
  },
];
export const servicesTransactionsColumns = () => [
  {
    field: "hash",
    headerName: "Código de transacción",
    accessor: "hash",
    id: "hash",
    Header: () => <div className="pill-header">Código de transacción</div>,
  },
  {
    field: "created_at",
    Header: "Fecha de creación",
    headerName: "Fecha de creación",
    accessor: "created_at",
    id: "created_at",
    Cell: ({ value }) => {
      if (value) {
        return formatLocaleDate(value, "d MMM yyyy, h:mm aaaa");
      }
    },
  },
  {
    field: "paid_at",
    Header: "Fecha de pago",
    headerName: "Fecha de pago",
    accessor: "paid_at",
    id: "paid_at",
    Cell: ({ value }) => {
      if (value) {
        return formatLocaleDate(value, "d MMM yyyy, h:mm aaaa");
      }
    },
  },
  {
    field: "amount",
    Header: "Monto",
    headerName: "Monto",
    accessor: "amount",
    headerClassName: "amount-header",
    id: "amount",
  },
  {
    field: "status",
    Header: "Estado",
    headerName: "Estado",
    accessor: "status",
    id: "status",
  },
];

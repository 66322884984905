import { requestContainer } from "../requestContainer";

export async function GetSuppliers({ pageIndex, providerId }) {
  return requestContainer({
    method: "get",
    url: `/suppliers?page=${pageIndex}&provider_id=${providerId}&per_page=100`,
  });
}

export async function GetAllSuppliers(pageIndex) {
  return requestContainer({
    method: "get",
    url: `/backoffice/suppliers?page=${pageIndex}`,
  });
}

export async function GetAllBanks({ pageIndex }) {
  return requestContainer({
    method: "get",
    url: `/banks?page=${pageIndex}`,
  });
}

export async function GetTotalSuppliers({ providerId }) {
  return requestContainer({
    method: "get",
    url: `/suppliers/total?provider_id=${providerId}`,
  });
}

export async function DeleteSupplier({ id }) {
  return requestContainer({
    method: "delete",
    url: `/suppliers/${id}`,
  });
}

export async function GetSupplier({ id }) {
  return requestContainer({
    method: "get",
    url: `/suppliers/${id}`,
  });
}

export async function UpdateSupplier({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/suppliers/${id}`,
    data: {
      ...formValues,
    },
  });
}

export async function CreateSupplier({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/suppliers`,
    data: {
      ...formValues,
    },
  });
}

export async function SearchSuppliers({ query, withAnayap }) {
  return requestContainer({
    method: "get",
    url: `/suppliers/search?q=${query}&with_anayap=${withAnayap}`,
  });
}

export async function SearchAllSuppliers(query) {
  return requestContainer({
    method: "get",
    url: `/backoffice/suppliers/search?q=${query}`,
  });
}

export function UploadSupplierFileCSV(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/suppliers",
    data: formData,
  });
}

export function UploadSupplierFileCSVV2(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/csv/suppliers",
    data: formData,
  });
}

export function UploadFileTxt(formData, query) {
  return requestContainer({
    method: "post",
    url: `/upload/txt?type=${query}`,
    data: formData,
  });
}

import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { styles } from "./styles";
import { CloseButton } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { ModalDownloadIcon } from "../../../assets";
import {
  acceptedDropzoneFiles,
  getBodyTextLoading,
  getBodyTextAccept,
} from "./utils";

export const UploadFileBody = ({
  type,
  entity,
  isLoading,
  onDrop,
  selectedFiles,
  removeFile,
}) => {
  return (
    <div style={styles.modalBodyContainer}>
      {isLoading ? (
        <Box sx={styles.modalBodyContainerLoading}>
          <div style={styles.modalBodyContainerLoadingText}>
            <p style={styles.modalBodyLoadingText}>
              {getBodyTextLoading(entity, type)}
            </p>
          </div>
          <CircularProgress />
        </Box>
      ) : (
        <Dropzone
          onDrop={onDrop}
          accept={acceptedDropzoneFiles[type] || {}}
          maxFiles={10}
        >
          {({ getRootProps, getInputProps, acceptedFiles }) => (
            <section>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {selectedFiles && selectedFiles.length ? (
                  selectedFiles.map((file, index) => (
                    <div key={index} className="selected-file">
                      {file.name} <CloseButton onClick={removeFile(file)} />
                    </div>
                  ))
                ) : (
                  <div style={styles.modalBodyContainerDropzone}>
                    <ModalDownloadIcon />
                    <div style={styles.modalBodyContainerDropzoneText}>
                      <p style={styles.modalBodyDropzoneText}>
                        <span style={styles.modalBodyDropzoneSpan}>
                          Sube el archivo
                        </span>{" "}
                        o arrástralo aquí
                      </p>
                    </div>
                    <p style={styles.modalBodyDropzoneSubText}>
                      {getBodyTextAccept(type)}
                    </p>
                    {type === "PDF" && (
                      <p style={styles.modalBodyDropzoneTextMaxFile}>
                        Puedes subir <strong>hasta 10</strong> archivos a la
                        vez.
                      </p>
                    )}
                  </div>
                )}
              </div>
            </section>
          )}
        </Dropzone>
      )}
    </div>
  );
};

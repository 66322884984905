import React from "react";

export const PaperclipIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14.1017 7.26598L8.09133 13.2764C6.72449 14.6432 4.50842 14.6432 3.14158 13.2764C1.77475 11.9095 1.77475 9.69347 3.14158 8.32664L9.15199 2.31623C10.0632 1.40501 11.5406 1.40501 12.4518 2.31623C13.363 3.22745 13.363 4.70484 12.4518 5.61606L6.67712 11.3908C6.2215 11.8464 5.48281 11.8464 5.0272 11.3908C4.57159 10.9352 4.57159 10.1965 5.0272 9.74085L10.0948 4.67325"
        stroke="#475467"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { Typography } from "@mui/material";
import React from "react";

export const Pills = ({
  text,
  bgColor = "#EBE9FE",
  textColor = "",
  bgStyles,
  textStyle,
  icon,
}) => {
  const styles = {
    container: {
      display: "flex",
      padding: "0px 6px",
      alignItems: "center",
      borderRadius: "4px",
      background: bgColor,
      ...bgStyles,
    },
    text: {
      color: textColor,
      textAlign: "center",
      fontSize: "12px",
      fontWeight: 600,
      margin: "0px",
      ...textStyle,
    },
  };
  return (
    <div style={styles.container}>
      {icon}
      <Typography style={styles.text}>{text}</Typography>
    </div>
  );
};

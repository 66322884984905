import { Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../buttons";

export default function RegisterModalFooter(props) {
  const { closeFlow, saveNewSource, loading, disabled, pt = 4 } = props;
  return (
    <Stack direction="row" justifyContent="space-between" spacing={1} pt={pt}>
      <SecondaryButton width="100%" text="Cancelar" action={closeFlow} />
      <PrimaryButton
        width="100%"
        loading={loading}
        isDisable={disabled || loading}
        text="Guardar"
        action={saveNewSource}
      />
    </Stack>
  );
}

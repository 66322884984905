export class SortingQuery {
  constructor(initialSorting = "") {
    this.queries = !initialSorting ? [] : initialSorting.split(",");
  }
  get sort() {
    return this.queries.join(",");
  }
  addField(fieldNameToSort) {
    const isAscIncluded = this.queries.includes(fieldNameToSort);
    const isDescIncluded = this.queries.includes(`-${fieldNameToSort}`);
    if (!isAscIncluded && !isDescIncluded && this.queries.length > 0) {
      this.queries = [`-${fieldNameToSort}`];
    } else if (isAscIncluded) {
      this.queries = this.queries.filter(
        (innerFieldName) => innerFieldName !== fieldNameToSort
      );
    } else if (isDescIncluded) {
      this.queries = this.queries.filter(
        (innerFieldName) => innerFieldName !== `-${fieldNameToSort}`
      );
      this.queries.push(fieldNameToSort);
    } else {
      this.queries.push(`-${fieldNameToSort}`);
    }
  }
}

import React, { useContext, useEffect, useState } from "react";
import { Stack, TextField, Box, Zoom } from "@mui/material";
import { UserContext } from "../../../../contexts";
import { Spinner } from "react-bootstrap";
import { PrimaryButton } from "../../../buttons";
import { toast } from "react-toastify";
import { toastOptions } from "../../../../constants";
import Notes from "../../../components/notes";
import {
  fetchInvoicesNotes,
  saveInvoiceNote,
  deleteNote,
} from "../../../../services/api/notes";
import { useMediaQuery } from "react-responsive";

const InvoicesNotes = ({ invoiceId }) => {
  const [invoicesNotes, setInvoicesNotes] = useState([]);
  const [loadingInvoicesNotes, setLoadingInvoicesNotes] = useState(false);
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [messageInputValue, setMessageInputValue] = useState("");
  const { currentUser } = useContext(UserContext);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const getInvoicesNotes = async () => {
    setLoadingInvoicesNotes(true);
    await fetchInvoicesNotes(invoiceId).then(setInvoicesNotes);
    setLoadingInvoicesNotes(false);
  };

  useEffect(() => {
    getInvoicesNotes();
  }, []);

  const saveNote = async () => {
    setIsSavingNote(true);
    try {
      await saveInvoiceNote({
        comment: messageInputValue,
        invoiceId,
      });
      toast.success("¡La nota se creó con éxito!", toastOptions);
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    } finally {
      setIsSavingNote(false);
      setMessageInputValue("");
      getInvoicesNotes();
    }
  };

  const makeDeleteNote = async (invoiceToDelete) => {
    setLoadingInvoicesNotes(true);
    await deleteNote(invoiceToDelete);
    setLoadingInvoicesNotes(false);
    getInvoicesNotes();
  };

  return (
    <Box
      sx={{
        padding: "0px 10px",
        paddingBottom: 10,
        backgroundColor: "#f9fafb",
        minWidth: isMobile ? 360 : 544,
        maxWidth: isMobile ? 360 : 544,
        margin: "auto",
        marginBottom: 10,
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: 1,
        }}
      >
        <div className="modalTitle">Notas ({invoicesNotes.length})</div>
      </Stack>
      <Zoom in={loadingInvoicesNotes}>
        <Stack
          direction="row"
          sx={{ justifyContent: "center", marginBottom: 2 }}
        >
          <Spinner animation="border" variant="secondary" />
        </Stack>
      </Zoom>
      <Box sx={{ margin: "10px 0px", marginBottom: 4 }}>
        <Stack>
          <TextField
            multiline
            size="small"
            placeholder="Deja aquí tu comentario..."
            rows={5}
            variant="outlined"
            onChange={(e) => setMessageInputValue(e.target.value)}
            value={messageInputValue}
          />
        </Stack>
        <Stack alignItems="flex-end" sx={{ margin: "10px 0px" }}>
          <PrimaryButton
            loading={isSavingNote}
            isDisable={messageInputValue.length < 2}
            text="Publicar"
            action={saveNote}
            width="150px"
          />
        </Stack>
      </Box>
      {invoicesNotes.length > 0 && (
        <Notes
          loading={loadingInvoicesNotes}
          notes={invoicesNotes}
          userEmail={currentUser.email}
          deleteNote={makeDeleteNote}
        />
      )}
    </Box>
  );
};

export default InvoicesNotes;

import { Button, Typography } from "@mui/material";
import { DownloadFileIcon2 } from "../../assets";

export const ExportButton = ({ onClick, styles = null }) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<DownloadFileIcon2 width="16" height="20" />}
      sx={styles}
    >
      <Typography
        sx={{
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 600,
        }}
      >
        Exportar
      </Typography>
    </Button>
  );
};

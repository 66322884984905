import { documentRow } from "./documentRow";

export const receiversColumns = [
  {
    field: "name",
    Header: "Destinatario",
    headerName: "Destinatario",
    accessor: "name",
    id: "name",
  },
  {
    field: "email",
    Header: "Correo electrónico",
    headerName: "Correo electrónico",
    accessor: "email",
    id: "email",
  },
  {
    field: "document_number",
    Header: "Nro. de identificación",
    headerName: "Nro. de identificación",
    accessor: "document_number",
    id: "document_number",
    Cell: ({ row }) => {
      return documentRow(row);
    },
  },
  {
    field: "bank_info",
    Header: "Datos bancarios",
    headerName: "Datos bancarios",
    accessor: "bank_info",
    id: "bank_info",
  },
];

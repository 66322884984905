import { PrimaryButton, WithoutBorderButton } from "commons/buttons";
import { UserContext } from "contexts";
import { useContext } from "react";

export const StepZero = ({ setStep }) => {
  const { setDianUserType } = useContext(UserContext);

  return (
    <div>
      <p className="dian-modal-title">
        <strong>Conectarse a la DIAN</strong>
      </p>
      <p className="dian-modal-step-1-text">
        <span>
          Para ingresar tus credenciales, selecciona el tipo de registro que
          tienes en la DIAN.
        </span>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginTop: "24px",
        }}
      >
        <PrimaryButton
          width="100%"
          text="Soy una persona jurídica"
          action={() => {
            setDianUserType("legal");
            setStep(1);
          }}
        />
        <WithoutBorderButton
          width="100%"
          text="Soy una persona natural"
          action={() => {
            setDianUserType("natural");
            setStep(2);
          }}
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
    </div>
  );
};

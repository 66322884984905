import { Autocomplete, Stack, TextField } from "@mui/material";
import React from "react";
import { styles } from "./styles";
import { ValidationMessage } from "commons/components";

export const PaymentMethodInput = ({
  register,
  paymentMethods,
  handleChangeForm,
  onChangeInputPaymentMethod,
  formState,
  errors,
}) => {
  return (
    <Stack spacing={1}>
      <label htmlFor="paymentMethod" style={styles.inputLabel}>
        Forma de pago
      </label>
      <Autocomplete
        {...register("paymentMethod", { required: true })}
        name="paymentMethod"
        size="small"
        options={paymentMethods}
        freeSolo
        getOptionLabel={(option) =>
          option?.Name && option?.AccountingConcept
            ? `${option.Name} - ${option.AccountingConcept}`
            : ""
        }
        onChange={(event, value) => handleChangeForm("paymentMethod", value)}
        onInputChange={(event) => {
          onChangeInputPaymentMethod(event?.target?.value);
        }}
        filterOptions={(options, { inputValue }) => {
          const inputValueLower = inputValue.toLowerCase();
          return options.filter(
            (option) =>
              option.Name.toLowerCase().includes(inputValueLower) ||
              option.AccountingConcept.toString()
                .toLowerCase()
                .includes(inputValueLower)
          );
        }}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={option.ACPaymentMeanID}
              value={option.ACPaymentMeanID}
              style={styles.selectOptionContainer}
            >
              <snap style={styles.selectOptionTitle}>{option.Name}</snap>
              <snap style={styles.selectOptionSubtitle}>
                {option.AccountingConcept}
              </snap>
            </li>
          );
        }}
        value={formState.paymentMethods}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
            }}
            placeholder={"Seleccione una forma de pago"}
          />
        )}
      />
      <ValidationMessage errors={errors} field={"paymentMethod"} />
    </Stack>
  );
};

import React from "react";

export const CornerDownRightIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={{ marginTop: "-6px" }}
  >
    <g id="corner-down-right">
      <path
        id="Icon"
        d="M2.66675 2.66663V3.59996C2.66675 5.84017 2.66675 6.96027 3.10272 7.81592C3.48622 8.56857 4.09814 9.18049 4.85079 9.56398C5.70643 9.99996 6.82654 9.99996 9.06675 9.99996H13.3334M13.3334 9.99996L10.0001 6.66663M13.3334 9.99996L10.0001 13.3333"
        stroke="#667085"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

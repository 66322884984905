import { styles } from "./styles";

function PaymentSummaryItem({ label, value }) {
  return (
    <div style={styles.sourceOfFundsInfoRow}>
      <p style={styles.sourceOfFundsInfoTitle}>{label}</p>
      <p style={styles.sourceOfFundsInfoTotal}>{value}</p>
    </div>
  );
}

export default PaymentSummaryItem;

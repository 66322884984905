import React from "react";
import { TabPanel } from "@mui/lab";
import { styles } from "../../../Table/styles";
import { NumericFormat } from "react-number-format";

export const FullValue = ({ value, amount, setFullAmount }) => {
  return (
    <TabPanel value={value}>
      <p style={styles.tabPanelTitle}>Monto a pagar</p>
      <div className="input-group mb-3">
        <NumericFormat
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          isNumericString={true}
          value={amount}
          style={{
            borderRadius: "8px",
            width: "100%",
            height: "40px",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "8px",
            paddingBottom: "8px",
            border: "1px #D0D5DD solid",
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          }}
          prefix="$"
          onValueChange={(values, sourceInfo) => {
            setFullAmount(values.floatValue);
          }}
        />
      </div>
      <p style={styles.tabPanelText}>
        Al apretar Confirmar, se cambiará el monto total a pagar. Utilizar esta
        pestaña únicamente si se desea cambiar el valor total de la factura.
      </p>
    </TabPanel>
  );
};

import React, { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { CloseButton } from "react-bootstrap";
import { PayrollContext } from "../../../contexts";
import { AddFileIcon, ModalDownloadIcon } from "../../../assets";
import { samplePayrollFile, toastOptions } from "../../../constants";
import Dropzone from "react-dropzone";
import { UploadPayroll } from "../../../services";
import { createPortal } from "react-dom";
import { PayrollUploadResultModal } from "../../modals/PayrollUploadResultModal/PayrollUploadResultModal";
import { toast } from "react-toastify";

export const UploadPayrollFile = ({ handleClose }) => {
  const { setUploadFileDrawerIsOpen, uploadFileDrawerIsOpen, getPayrolls } =
    useContext(PayrollContext);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [resultModalIsOpen, setResultModalIsOpen] = useState(false);
  const [uploadResult, setUploadResult] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
    }
  };

  const closeAndReset = () => {
    setUploadFileDrawerIsOpen(false);
    setSelectedFiles(undefined);
    handleClose();
  };

  const openResultModal = (uploadResult) => {
    setResultModalIsOpen(true);
    setUploadResult(uploadResult);
  };

  const errorMsg = (msg) => {
    if (msg === "Error: Faltan campos requeridos") {
      toast.error(
        "El archivo tiene información faltante o incorrecta. Revisa los campos obligatorios e intenta de nuevo.",
        toastOptions
      );
    } else if (msg === "Error: plantilla incorrecta") {
      toast.error(
        "El archivo cargado no es correcto. Por favor, utiliza la plantilla provista por Payana.",
        toastOptions
      );
    } else if (msg === "Error: No se pudo descargar el archivo") {
      toast.error(
        "Ha ocurrido un error. Por favor, intenta nuevamente.",
        toastOptions
      );
    } else if (
      msg ===
      "Error - Para agregar nuevos empleados, se debe especificar el tipo de documento"
    ) {
      toast.error(
        "Por favor, especifica el tipo de documento de los empleados cargados e intenta nuevamente.",
        toastOptions
      );
    }
  };

  const upload = () => {
    setIsLoading(true);
    var formdata = new FormData();
    formdata.append("files", selectedFiles[0], selectedFiles[0].name);

    UploadPayroll(formdata)
      .then((res) => {
        if (res) {
          const response = JSON.parse(res.payrollService.Payload);
          if (response.statusCode === 200) {
            toast.success("¡El archivo se subió con éxito!", toastOptions);
            getPayrolls(1);
            openResultModal(JSON.parse(response.body));
          } else if (response.statusCode === 403) {
            errorMsg(response.body.error);
          } else {
            toast.error("Hubo un error subiendo el archivo", toastOptions);
          }
        }
      })
      .catch((err) => {
        toast.error(`${err}`, toastOptions);
      })
      .finally(() => {
        setIsLoading(false);
        closeAndReset();
      });
  };

  const removeFile = (file) => () => {
    let acceptedFiles = [...selectedFiles];
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setSelectedFiles(acceptedFiles);
  };

  return (
    <>
      {resultModalIsOpen &&
        uploadResult &&
        createPortal(
          <PayrollUploadResultModal
            visible={resultModalIsOpen}
            handleClose={handleClose}
            serviceResponse={uploadResult}
          />,
          document.body
        )}

      <Drawer
        anchor="right"
        open={uploadFileDrawerIsOpen}
        onClose={closeAndReset}
      >
        <Stack
          sx={{ width: "450px", height: "100%" }}
          px={3}
          py={3}
          justifyContent="space-between"
        >
          <Stack direction={"row"} justifyContent="space-between">
            <Typography sx={styles.title}>Subir listado de nómina</Typography>
            <CloseButton
              style={{ boxShadow: "none" }}
              onClick={closeAndReset}
            />
          </Stack>
          <Stack sx={{ height: "100%" }} justifyContent="flex-start">
            <Link
              my={2}
              href={samplePayrollFile}
              target="_blank"
              underline="none"
            >
              <Divider />
              <Button
                key="one"
                startIcon={<AddFileIcon />}
                sx={{
                  textTransform: "inherit",
                  textAlign: "inherit",
                  justifyContent: "flex-start",
                  padding: "10px",
                }}
              >
                <Stack ml={2}>
                  <Typography
                    sx={{
                      ...styles.emptyStateButtonTitle,
                      textDecoration: "underline",
                    }}
                  >
                    Descargue una plantilla para ver un ejemplo del formato
                    requerido.
                  </Typography>
                </Stack>
              </Button>
              <Divider />
            </Link>
            <Box
              sx={{
                height: "100%",
                backgroundColor: "#FCFCFD",
                border: "1px dashed #D0D5DD",
                borderRadius: "8px",
              }}
              my={1}
              p={3}
            >
              {isLoading ? (
                <Box sx={{ textAlign: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Dropzone
                  onDrop={onDrop}
                  accept={{
                    "application/vnd.ms-excel": [".xls"],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      [".xlsx"],
                  }}
                  maxFiles={10}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        {selectedFiles && selectedFiles.length ? (
                          selectedFiles.map((file) => (
                            <Box
                              key={file.name}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                              }}
                            >
                              <CloseButton
                                style={{ boxShadow: "none" }}
                                onClick={removeFile(file)}
                              />
                              <Typography noWrap variant="subtitle1">
                                {file.name}
                              </Typography>
                            </Box>
                          ))
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ModalDownloadIcon />
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  color: "#5925DC",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  marginRight: "4px",
                                }}
                              >
                                Sube el archivo
                              </p>
                              <p
                                style={{
                                  color: "#667085",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                o arrástralo aquí
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={1} pt={4} justifyContent="flex-end">
            <SecondaryButton text="Cancelar" action={closeAndReset} />
            <PrimaryButton
              text="Guardar"
              action={upload}
              isDisable={!selectedFiles}
            />
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export const styles = {
  emptyStateTitle: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
  },
  emptyStateSubtitle: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#475467",
  },
  emptyStateBox: {
    backgroundColor: "#F2F4F7",
    minHeight: "400px",
    height: "90vh",
    display: "flex",
    justifyContent: "center",
  },
  emptyStateStack: {
    maxWidth: "500px",
    alignItems: "center",
    padding: "32px 0px",
  },
};

import React from "react";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { NewTxPanel } from "assets";

export const TransactionsFeedbackModal = ({
  visible,
  handleConfirm,
  handleSkip,
}) => {
  return (
    <Modal show={visible} onHide={handleSkip} centered>
      <Modal.Body>
        <Box style={styles.container}>
          <div style={styles.columnContainer}>
            <p style={styles.title}>¡Ayúdanos a mejorar!</p>
            <p
              style={{
                ...styles.subtitle,
                maxWidth: "298px",
                textAlign: "start",
                alignSelf: "flex-start",
              }}
            >
              Tu opinión sobre el nuevo panel de transacciones es muy importante
              para nosotros.
            </p>
            <div style={styles.row}>
              <PrimaryButton
                text={"Dejar valoración"}
                action={handleConfirm}
                width="140px"
              />
              <SecondaryButton
                text={"Omitir"}
                action={handleSkip}
                width="140px"
              />
            </div>
          </div>
          <div style={styles.columnContainer}>
            <NewTxPanel />
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

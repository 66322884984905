import React from "react";
import { styles } from "../styles";
import { AlertIcon, CloseIcon } from "assets";
import { Typography } from "@mui/material";

export const CommunicationBanner = ({
  title,
  description,
  setCommunicationHash,
  saveInLocalStorage,
  setCommunicationVisible,
}) => {
  return (
    <div style={styles.communicationContainer}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <div
          style={{
            width: "24px",
            height: "24px",
            marginRight: "8px",
          }}
        >
          <AlertIcon />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography sx={styles.communicationTitle}>{title}</Typography>
          <Typography sx={styles.communicationInfo}>{description}</Typography>
        </div>
      </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setCommunicationHash(window.btoa(description));
          saveInLocalStorage(window.btoa(description), "false");
          setCommunicationVisible("false");
        }}
      >
        <CloseIcon color={"#9E77ED"} />
      </div>
    </div>
  );
};

import React from "react";

export const PartiallyReconciledIcon = () => (
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none">
    <circle cx="11.5" cy="12.4355" r="11" fill="#7A5AF8" stroke="#7A5AF8" />
    <path
      d="M2.51221 12.4355C2.7589 7.39948 6.71072 3.43555 11.5 3.43555C16.2893 3.43555 20.2411 7.39948 20.4878 12.4355L2.51221 12.4355Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

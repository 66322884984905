import React from "react";
import { CloseButton } from "react-bootstrap";
import { styles } from "./styles";
import { getTitle } from "./utils";

export const UploadFileModalTitle = ({ type, entity, closeAndReset }) => {
  return (
    <div style={styles.modalTitleContainer}>
      <p style={styles.modalTitleText}>{getTitle(entity, type)}</p>
      <CloseButton style={{ boxShadow: "none" }} onClick={closeAndReset} />
    </div>
  );
};

import jwtDecode from "jwt-decode";
import { getFromLocalStorage } from "./localStorageHandler";

const isTokenExpired = (token) => {
  if (!token) {
    return false;
  }
  const decodedToken = jwtDecode(token);
  const expirationDate = new Date(decodedToken.exp * 1000);
  return expirationDate < new Date();
};

export const hasSession = () => {
  const token = getFromLocalStorage("token");
  return token !== "xxx" && token !== null && !isTokenExpired(token);
};

import React from "react";

export const MoneyIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99996 9.16666V12.5M15 7.49999V10.8333M14.1666 3.33333C16.2072 3.33333 17.3109 3.64563 17.8601 3.88786C17.9332 3.92012 17.9698 3.93625 18.0753 4.03697C18.1386 4.09734 18.254 4.27449 18.2837 4.35674C18.3333 4.49394 18.3333 4.56894 18.3333 4.71895V13.6759C18.3333 14.4333 18.3333 14.8119 18.2197 15.0065C18.1042 15.2045 17.9928 15.2966 17.7766 15.3727C17.564 15.4475 17.1349 15.365 16.2768 15.2001C15.6761 15.0847 14.9637 15 14.1666 15C11.6666 15 9.16663 16.6667 5.83329 16.6667C3.7927 16.6667 2.68901 16.3544 2.13986 16.1121C2.06673 16.0799 2.03016 16.0637 1.92463 15.963C1.86136 15.9026 1.74589 15.7255 1.71618 15.6433C1.66663 15.506 1.66663 15.431 1.66663 15.281L1.66663 6.32404C1.66663 5.56673 1.66663 5.18808 1.78019 4.99345C1.89571 4.79546 2.00712 4.70343 2.22334 4.62734C2.43591 4.55254 2.86499 4.63498 3.72315 4.79987C4.32381 4.91528 5.03619 4.99999 5.83329 4.99999C8.33329 4.99999 10.8333 3.33333 14.1666 3.33333ZM12.0833 9.99999C12.0833 11.1506 11.1506 12.0833 9.99996 12.0833C8.84937 12.0833 7.91663 11.1506 7.91663 9.99999C7.91663 8.8494 8.84937 7.91666 9.99996 7.91666C11.1506 7.91666 12.0833 8.8494 12.0833 9.99999Z"
      stroke="#7A5AF8"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React, { useContext, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { styles } from "./styles";
import { CloseButton, Spinner } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { ItemsInReceipt } from "./ItemsInReceipt";
import {
  GetBOReceipt,
  GetBOReceiptServices,
} from "../../../services/api/backoffice";
import { StatusPill } from "../../Table";
import { format } from "date-fns";
import { UserContext } from "../../../contexts";
import { GetService } from "../../../services/api/services/Services.service";
import { GetReceipt } from "../../../services";

export const ReceiptDetails = ({
  visible,
  onClose,
  id,
  isReceiptsServicesBo,
}) => {
  const [receipt, setReceipt] = useState({});
  const [trxRows, setTrxRows] = useState([]);
  const [type, setType] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (isReceiptsServicesBo) {
      currentUser.backoffice_user
        ? GetBOReceiptServices({ id }).then((res) => {
            setReceipt(res.receipts.receipt);
            setTrxRows(res.receipts.rows);
            setType("invoice");
          })
        : GetService({ id }).then((res) => {
            setReceipt(res.receipts.receipt);
            setTrxRows(res.receipts.rows);
            setType("invoice");
          });
    } else {
      currentUser.backoffice_user
        ? GetBOReceipt({ id: id }).then((res) => {
            setReceipt(res.receipts.receipt);
            setTrxRows(res.receipts.rows);
            setType(
              res.receipts.receipt?.provider_name ||
                res.receipts.receipt?.service_name
                ? "invoice"
                : "payroll"
            );
          })
        : GetReceipt({ id: id }).then((res) => {
            setReceipt(res.receipts.receipt);
            setTrxRows(res.receipts.rows);
            setType(
              res.receipts.receipt?.provider_name ||
                res.receipts.receipt?.service_name
                ? "invoice"
                : "payroll"
            );
          });
    }
  }, [visible]);

  const closeAndReset = () => {
    setReceipt({});
    setTrxRows([]);
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={visible}
      onClose={closeAndReset}
      sx={{ overflowY: "hidden" }}
    >
      <Stack
        sx={{ minWidth: "400px", height: "100%" }}
        justifyContent="flex-start"
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          p={2}
          sx={{ paddingLeft: isMobile ? "24px" : "16px" }}
        >
          <Typography sx={styles.modalTitle}>Orden de pago</Typography>
          <CloseButton style={{ boxShadow: "none" }} onClick={closeAndReset} />
        </Stack>
        <Stack
          my={2}
          spacing={2}
          sx={{
            marginTop: "0px",
            height: "90%",
            paddingLeft: isMobile ? "8px" : "0px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {trxRows.length < 1 ? (
            <div style={styles.spinnerContainer}>
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <div style={styles.greyContainer}>
                <div style={styles.firstItem}>
                  <p style={styles.title} className="card-subtitle mb-2">
                    Estado
                  </p>
                  <div style={{ paddingTop: isMobile ? "4px" : "0px" }}>
                    <StatusPill
                      section="receipts"
                      status={receipt?.status}
                      statusPaymentGateway={receipt?.status_payment_gateway}
                    />
                  </div>
                </div>
                <div style={styles.middleItem}>
                  <p style={styles.title} className="card-subtitle mb-2">
                    Código de transacción
                  </p>
                  <p
                    style={{ ...styles.text, textTransform: "uppercase" }}
                    className="card-subtitle mb-2"
                  >
                    {receipt?.transaction_hash}
                  </p>
                </div>
                <div style={styles.lastItem}>
                  <p style={styles.title} className="card-subtitle mb-2">
                    Fecha de creación
                  </p>
                  <p style={styles.text} className="card-subtitle mb-2">
                    {format(new Date(receipt?.created_at), "dd/MM/yyyy")}
                  </p>
                </div>
              </div>
              <div style={styles.rowsContainer}>
                {trxRows.length && (
                  <>
                    <div style={styles.column}>
                      <div style={styles.trxRow}>
                        <p
                          style={{
                            ...styles.textWithoutLimit,
                            textTransform: "uppercase",
                          }}
                          className="card-subtitle mb-2"
                        >
                          {type === "invoice"
                            ? receipt?.provider_name || receipt?.service_name
                            : receipt?.employee_name}
                        </p>
                        {receipt?.service_name &&
                          !trxRows[0].invoice_number && (
                            <p style={styles.rowText}>
                              <NumericFormat
                                value={parseInt(trxRows[0].amount_total)}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"$"}
                                decimalScale={2}
                              />
                            </p>
                          )}
                      </div>
                      {receipt?.service_name && !trxRows[0].invoice_number && (
                        <hr
                          style={{ ...styles.separator, marginBottom: "16px" }}
                        />
                      )}
                      {(!receipt?.service_name ||
                        (receipt?.service_name && trxRows[0].invoice_number)) &&
                        trxRows.map((row, index) => {
                          return (
                            <ItemsInReceipt
                              key={index}
                              trxStatus={receipt.status}
                              name={
                                type === "invoice"
                                  ? receipt.provider_name
                                  : receipt.employee_name
                              }
                              amount={
                                type === "invoice"
                                  ? row.amount_total
                                  : receipt.amount
                              }
                              code={
                                type === "invoice"
                                  ? row.invoice_number
                                  : row.period
                              }
                            />
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Stack>
        {receipt && (
          <Stack
            spacing={2}
            justifyContent="flex-end"
            sx={{
              backgroundColor: "#f5f6f7",
              position: "absolute",
              bottom: "0px",
              left: "0px",
              right: "0px",
            }}
          >
            <Box
              style={styles.footer}
              p={2}
              sx={{ paddingLeft: isMobile ? "24px" : "16px" }}
            >
              <Typography style={styles.amountTitle}>
                {receipt.status === "pay_later"
                  ? "Monto total a pagar:"
                  : "Monto total transferido:"}
              </Typography>
              <Typography style={styles.amount}>
                <NumericFormat
                  value={parseInt(receipt?.amount)}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                  decimalScale={2}
                />
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
};

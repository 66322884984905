import { Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../commons/buttons";
import ModalShell from "../../commons/modals/PaymentModal/ModalShell";
import { ModalWarningIcon } from "../../assets";
import styles from "./complete-customer-before-pay-modal.module.css";

function CompleteCustomersBeforePayModal({
  numberOfIncompleted,
  visible = false,
  close = () => {},
  primaryAction = () => {},
}) {
  return (
    <ModalShell visible={visible}>
      <div style={{ textAlign: "center" }}>
        <ModalWarningIcon />
      </div>
      <p className={styles["complete-customer-before-pay-modal-title"]}>
        ¡Un paso más hacia tus cobros!
      </p>
      <div className={styles["complete-customer-before-pay-modal-description"]}>
        {numberOfIncompleted === 1
          ? "La selección que has realizado incluye un cliente al que le falta información."
          : `La selección que has realizado incluye ${numberOfIncompleted} clientes a los cuales les falta información.`}
      </div>
      <Stack
        sx={{ marginTop: 2 }}
        alignItems="flex-end"
        direction="row"
        gap={2}
      >
        <SecondaryButton
          text="Quizás más tarde"
          action={close}
          style={{ width: "100%" }}
        />
        <PrimaryButton
          text="Entendido"
          action={primaryAction}
          style={{ width: "100%" }}
        />
      </Stack>
    </ModalShell>
  );
}

export default CompleteCustomersBeforePayModal;

import React from "react";

export const PayrollIcon = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    style={{ marginRight: "6px" }}
  >
    <path
      d="M18 19.0869L21 16.0869M21 16.0869L18 13.0869M21 16.0869H15M14.5 1.37767C15.9659 1.97106 17 3.40823 17 5.08691C17 6.7656 15.9659 8.20277 14.5 8.79616M11 13.0869H7C5.13623 13.0869 4.20435 13.0869 3.46927 13.3914C2.48915 13.7974 1.71046 14.5761 1.30448 15.5562C1 16.2913 1 17.2231 1 19.0869M12.5 5.08691C12.5 7.29605 10.7091 9.08691 8.5 9.08691C6.29086 9.08691 4.5 7.29605 4.5 5.08691C4.5 2.87777 6.29086 1.08691 8.5 1.08691C10.7091 1.08691 12.5 2.87777 12.5 5.08691Z"
      stroke="#6938EF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

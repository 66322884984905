import { createContext, useState } from "react";
import {
  GetBOPayrolls,
  UpdatePayrollStatus,
  UpdateSendSupport,
} from "../../services";
import { toast } from "react-toastify";
import { toastOptions } from "../../constants";

export const BackofficePayrollContext = createContext();

export function BackofficePayrollProvider({ children }) {
  const [payrolls, setPayrolls] = useState(null);
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);
  const [statusSelected, setStatusSelected] = useState("all");
  const [employeeIdSelected, setEmployeeIdSelected] = useState("all");
  const [companyIdSelected, setCompanyIdSelected] = useState("all");

  const getPayrolls = async (pageIndex, employeeId, companyId, status) => {
    const response = await GetBOPayrolls({
      pageIndex,
      employeeId: employeeId || employeeIdSelected,
      companyId: companyId || companyIdSelected,
      status: status || statusSelected,
    });
    if (response) {
      setPayrolls([...response.payroll.data]);
    }
  };

  const changeBOPayrollStatus = async (id, status, pageIndex) => {
    const response = await UpdatePayrollStatus(id, status);
    if (response) {
      toast.success("¡La nómina se actualizó con éxito!", toastOptions);
      getPayrolls(
        pageIndex,
        employeeIdSelected,
        companyIdSelected,
        statusSelected
      );
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const updateSendSupport = async (id, pageIndex = 1) => {
    const response = await UpdateSendSupport(id);
    if (response) {
      toast.success("¡El soporte se actualizó con éxito!", toastOptions);
      getPayrolls({
        pageIndex: pageIndex,
        employeeId: employeeIdSelected,
        companyId: companyIdSelected,
        status: statusSelected,
      });
    } else {
      toast.error("Hubo un error al actulizar el soporte", toastOptions);
    }
  };

  return (
    <BackofficePayrollContext.Provider
      value={{
        payrolls,
        getPayrolls,
        setStatusModalIsOpen,
        statusModalIsOpen,
        changeBOPayrollStatus,
        statusSelected,
        setStatusSelected,
        employeeIdSelected,
        setEmployeeIdSelected,
        companyIdSelected,
        setCompanyIdSelected,
        updateSendSupport,
      }}
    >
      {children}
    </BackofficePayrollContext.Provider>
  );
}

import React from "react";

export const AddFileIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#EBE9FE" />
    <path
      d="M21.3333 16.3333V12.5333C21.3333 11.4132 21.3333 10.8532 21.1153 10.4253C20.9236 10.049 20.6176 9.74305 20.2413 9.5513C19.8134 9.33331 19.2534 9.33331 18.1333 9.33331H13.8666C12.7465 9.33331 12.1865 9.33331 11.7586 9.5513C11.3823 9.74305 11.0764 10.049 10.8846 10.4253C10.6666 10.8532 10.6666 11.4132 10.6666 12.5333V19.4666C10.6666 20.5868 10.6666 21.1468 10.8846 21.5746C11.0764 21.951 11.3823 22.2569 11.7586 22.4487C12.1865 22.6666 12.7465 22.6666 13.8665 22.6666H16.3333M17.3333 15.3333H13.3333M14.6666 18H13.3333M18.6666 12.6666H13.3333M18 20.6666L20 22.6666M20 22.6666L22 20.6666M20 22.6666V18.6666"
      stroke="#7A5AF8"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { Stack } from "@mui/material";
import Switch from "react-ios-switch";
import { styles } from "../../../Table/styles";
import { AddresseeIcon, PlusCircleIcon } from "assets";
import {
  OptionsTabs,
  Receiver,
} from "commons/modals/CollectionObligationModal";
import { ThirdButton } from "commons/buttons";

const ReceiversSection = (props) => {
  const {
    checked,
    setChecked,
    receiverOption,
    setReceiverOption,
    firstReceiverAmountState,
    setFirstReceiverAmount,
    setReceiverDrawerIsOpen,
    formValues,
    setFirstSelectedReceiver,
    isReceiverAmountExceeded,
    amountWithoutComission,
    isSecondReceiverFormShown,
    showSecondReceiverForm,
    secondReceiverAmountState,
    setSecondReceiverAmount,
    setSecondSelectedReceiver,
    deleteSecondReceiver,
  } = props;
  return (
    <Stack spacing={1}>
      <div style={{ ...styles.sectionRow, marginTop: "24px" }}>
        <AddresseeIcon />
        <span style={styles.cardTitle}>Destinatario</span>
      </div>
      <div
        style={{
          marginTop: "24px",
          width: "100%",
          height: "28px",
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          padding: "24px",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Switch
          checked={checked}
          className={undefined}
          disabled={false}
          handleColor="white"
          name={undefined}
          offColor="#D0D5DD"
          onChange={() => {
            setChecked(!checked);
          }}
          onColor="#7F56D9"
          pendingOffColor={undefined}
          pendingOnColor={undefined}
          style={{ transform: "scale(0.7)" }}
        />
        <span style={styles.switchText}>Habilitar destinatario</span>
      </div>
      {checked && (
        <>
          <OptionsTabs
            tabSelected={receiverOption}
            setTabSelected={setReceiverOption}
          />
          <Receiver
            receiverNumber={1}
            amount={firstReceiverAmountState}
            setAmount={setFirstReceiverAmount}
            option={receiverOption}
            setOption={setReceiverOption}
            setReceiverDrawerIsOpen={setReceiverDrawerIsOpen}
            selectedReceiver={formValues?.firstReceiverOptionSelected}
            setSelectedReceiver={setFirstSelectedReceiver}
            isExceeded={isReceiverAmountExceeded}
            totalAmount={amountWithoutComission}
          />
          {!isSecondReceiverFormShown && !formValues?.receivers?.at(1) && (
            <ThirdButton
              text={"Sumar destinatario"}
              action={showSecondReceiverForm}
              width="100%"
              style={{ marginBottom: "24px" }}
              icon={<PlusCircleIcon />}
            />
          )}
          {(isSecondReceiverFormShown || !!formValues?.receivers?.at(1)) && (
            <Receiver
              receiverNumber={2}
              amount={secondReceiverAmountState}
              setAmount={setSecondReceiverAmount}
              option={receiverOption}
              selectedReceiver={formValues?.secondReceiverOptionSelected}
              setSelectedReceiver={setSecondSelectedReceiver}
              isExceeded={isReceiverAmountExceeded}
              totalAmount={amountWithoutComission}
              deleteSecondReceiver={deleteSecondReceiver}
            />
          )}
        </>
      )}
    </Stack>
  );
};

export default ReceiversSection;

import React from "react";
import { ArrowDownIcon } from "../../../assets/svg/ArrowDownIcon";
import { ArrowUpIcon } from "../../../assets/svg/ArrowUpIcon";

export const SortingHeader = ({
  fieldName,
  label,
  setSorting,
  sorting,
  className = "",
}) => (
  <div
    onClick={() => setSorting(fieldName)}
    colSpan="1"
    role="columnheader"
    className={`sorting-header ${className}`}
  >
    <span style={{ marginRight: 6 }}>{label}</span>
    <span
      className={
        !sorting.includes(fieldName) && !sorting.includes("-" + fieldName)
          ? "sorting-arrow"
          : ""
      }
    >
      {sorting.includes(fieldName) && !sorting.includes("-" + fieldName) ? (
        <ArrowUpIcon />
      ) : (
        <ArrowDownIcon on={sorting.includes("-" + fieldName)} />
      )}
    </span>
  </div>
);

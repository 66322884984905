import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { TrackJS } from "trackjs";
import { styles } from "./styles";
import {
  NotifyCollectionTransactionId,
  NotifyTransactionId,
} from "../../../services";
import { PaymentsContext } from "../../../contexts";
import {
  ApprovedIcon,
  BancolombiaCircleIcon,
  DaviviendaIcon,
  GrupoAvalIcon,
  WarningIcon,
} from "../../../assets";
import { getWppLink, openInNewTab } from "../../../utils";
import { PrimaryButton } from "../../buttons";
import { PAYMENT_METHOD } from "../../../constants/paymentMethod";
import { SUPPORT_NUMBER } from "constants";
import { APPROVED } from "constants";

export const NotifyWompiModal = ({
  visible,
  handleClose,
  type = "invoice",
}) => {
  const { changeStatus } = useContext(PaymentsContext);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const message =
    type === "customer"
      ? "Hola, estoy teniendo problemas abonando el pago que me enviaron por Payana"
      : "Hola, necesito ayuda con mi pago";

  useEffect(() => {
    if (!visible) {
      return;
    }
    const notify = async () => {
      try {
        const res =
          type === "customer"
            ? await NotifyCollectionTransactionId({ id: id })
            : await NotifyTransactionId({ id: id });
        if (res.message === "ok") {
          setResponse(res.data);
          setTransactionId(res.transaction_id);
          const ids = res?.rows?.map((row) => row.id);
          type === "customer" &&
            res?.data?.transaction?.status?.toLowerCase() === APPROVED &&
            changeStatus(ids, "in_process");
          type !== "customer" &&
            res.status?.toLowerCase() === APPROVED &&
            changeStatus(ids, "in_process");
        }
        setLoading(false);
        return;
      } catch (error) {
        TrackJS.track(error.message);
        setLoading(false);
        return;
      }
    };
    notify();
  }, []);

  const isPaymentMethodBankingCorrespondent = (response) => {
    return PAYMENT_METHOD.BankingCorrespondent.isNamed(
      response?.transaction?.payment_method
    );
  };

  useEffect(() => {
    if (isPaymentMethodBankingCorrespondent(response)) {
      handleConfirm("sent");
    }
  }, [response]);

  const handleConfirm = async (statusSelected) => {
    type === "customer"
      ? handleClose(response?.rows?.at(0)?.customer_hash, statusSelected)
      : handleClose(transactionId);
  };

  return (
    <Modal show={visible} centered>
      <Modal.Body style={styles.column}>
        {loading ? (
          <Spinner animation="border" variant="secondary" />
        ) : type === "customer" ? (
          response?.transaction?.status?.toLowerCase() === APPROVED ? (
            <CustomerApprovedModal
              handleConfirm={handleConfirm}
              amount={response?.transaction?.amount}
              hash={response?.transaction?.hash?.toUpperCase()}
            />
          ) : isPaymentMethodBankingCorrespondent(response) ? null : (
            <SorryModal
              handleConfirm={handleConfirm}
              phoneNumber={SUPPORT_NUMBER}
              message={message}
            />
          )
        ) : response?.status?.toLowerCase() === APPROVED ? (
          <ApprovedModal
            handleConfirm={handleConfirm}
            amount={response.data.amount_in_cents / 100}
            hash={response.data.reference.toUpperCase()}
          />
        ) : (
          <SorryModal
            handleConfirm={handleConfirm}
            phoneNumber={SUPPORT_NUMBER}
            message={message}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

function ApprovedModal({ handleConfirm, amount, hash }) {
  return (
    <>
      <ApprovedIcon />
      <p style={styles.modalTitle}>¡Pago exitoso!</p>
      <p style={styles.modalAmount}>
        <NumericFormat
          value={amount}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          decimalScale={2}
        />
      </p>
      <p style={styles.reference}>Código de transacción: {hash}</p>
      <p style={styles.modalText}>El dinero enviado a cuentas de</p>
      <div style={styles.modalBankRow}>
        <div style={styles.modalContainer}>
          <BancolombiaCircleIcon />
          <span style={styles.modalBank}>Bancolombia</span>
        </div>
        <div style={styles.modalContainer}>
          <DaviviendaIcon />
          <span style={styles.modalBank}>Davivienda</span>
        </div>
      </div>
      <div style={styles.modalBankRow}>
        <div style={styles.modalContainer}>
          <GrupoAvalIcon />
          <span style={styles.modalBank}>Grupo Aval</span>
          <div style={styles.modalNewTagContainer}>
            <span style={styles.modalNewTag}>NUEVO</span>
          </div>
        </div>
      </div>
      <p style={styles.modalText}>
        se acreditará en las <strong>próximas 2 horas.</strong>
      </p>
      <p style={styles.modalText}>
        El dinero enviado a otros bancos se acreditará de acuerdo a los{" "}
        <strong>tiempos de procesamiento de ACH.</strong>
      </p>
      <p style={styles.modalFooterText}>Payana procesa pagos todos los días</p>
      <PrimaryButton text="Entendido" action={handleConfirm} width="100%" />
    </>
  );
}

function CustomerApprovedModal({ handleConfirm, amount, hash }) {
  return (
    <>
      <ApprovedIcon />
      <p style={styles.modalTitle}>¡Pago exitoso!</p>
      <p style={styles.modalAmount}>
        <NumericFormat
          value={amount}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          decimalScale={2}
        />
      </p>
      <p style={styles.reference}>Código de transacción: {hash}</p>
      <p style={styles.modalText}>Informaremos que el pago fue realizado.</p>
      <Button
        variant="primary"
        style={styles.modalPrimaryButton}
        onClick={handleConfirm}
        width="90%"
      >
        Entendido
      </Button>
    </>
  );
}

function SorryModal({ handleConfirm, phoneNumber, message }) {
  return (
    <>
      <WarningIcon />
      <p style={styles.modalTitle}>¡Lo sentimos!</p>
      <p style={styles.modalDeclinedText}>
        En este momento no es posible realizar el pago. Intenta nuevamente más
        tarde. Si el problema continúa, contáctanos al{" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => openInNewTab(getWppLink(phoneNumber, message))}
        >
          <strong>+57 317 4724545</strong>
        </span>
        .
      </p>
      <Button
        variant="primary"
        style={styles.modalPrimaryButton}
        onClick={handleConfirm}
      >
        Entendido
      </Button>
    </>
  );
}

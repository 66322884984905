export const TutorialTableHeader = ({ text }) => {
  return (
    <div
      style={{
        backgroundColor: "#F9FAFB",
        color: "#344054",
        fontSize: "16px",
        fontWeight: "600",
        padding: "16px",
        height: "54px",
        width: "100%",
      }}
    >
      {text}
    </div>
  );
};

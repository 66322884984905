import React from "react";
import { Button } from "@mui/material";
import { styles } from "../styles";

export const CollectionsAgendaFilter = ({
  typeSelected,
  setTypeSelected,
  setPageIndex,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.rowSpaceBetween}>
        <div style={styles.row}>
          <Button
            variant="text"
            key="customer"
            sx={
              typeSelected === "customer"
                ? styles.firstStatusOptionsSelected
                : styles.firstStatusOptions
            }
            onClick={() => {
              setTypeSelected("customer");
              setPageIndex(1);
            }}
          >
            Clientes
          </Button>
          <Button
            variant="text"
            key="receiver"
            sx={
              typeSelected === "receiver"
                ? styles.firstStatusOptionsSelected
                : styles.firstStatusOptions
            }
            onClick={() => {
              setTypeSelected("receiver");
              setPageIndex(1);
            }}
          >
            Destinatarios
          </Button>
        </div>
      </div>
      <hr style={styles.separator} />
    </div>
  );
};

import { requestContainer } from "../../../services/api/requestContainer";

export const getLinkToCobre = async (
  hash,
  redirectUrl,
  isCollections,
  sourceOfFundsId
) => {
  const payload = {
    hash,
    redirectUrl: `${redirectUrl}?reference=${hash}`,
    ...(!isCollections && { sourceOfFundsId }),
  };
  const res = await requestContainer({
    method: "post",
    url: isCollections ? "/collections/pay-in" : "/cobre/pay-in",
    data: payload,
  });
  if (res?.response?.status && res?.response?.status === 500) {
    throw new Error(res?.response?.data);
  }
  const { linkUrl } = res;
  return linkUrl;
};

export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    userSelect: "none",
  },
  modalSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
    marginRight: "12px",
  },
  modalPrimaryButton: {
    backgroundColor: "#6938EF",
    border: "1px solid #6938EF",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
  },
  radioButtonContainer: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    padding: "8px",
    alignItems: "center",
    marginBottom: "12px",
    justifyContent: "space-between",
    width: "100%",
    cursor: "pointer",
    height: 72,
  },
  radioButtonDisabledContainer: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    padding: "8px",
    alignItems: "center",
    marginBottom: "12px",
    justifyContent: "space-between",
    width: "100%",
    background: "#F9FAFB",
  },
  radioButtonSelectedContainer: {
    display: "flex",
    flexDirection: "row",
    height: "72px",
    border: "1px solid #7F56D9",
    borderRadius: "8px",
    padding: "8px",
    alignItems: "center",
    marginBottom: "12px",
    justifyContent: "space-between",
    backgroundColor: "#F2F3FF",
    width: "100%",
    cursor: "pointer",
  },
  radioTitle: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "8px",
  },
  radioTitleDisabled: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "8px",
  },
  radioSelectedTitle: {
    color: "#53389E",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "8px",
  },
  radioText: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "8px",
  },
  radioSelectedText: {
    color: "#7F56D9",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "8px",
  },
  radioTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // marginLeft: "16px",
    marginTop: "16px",
    marginRight: "8px",
  },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    // textAlign: "center",
  },
  subtitle: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
    alignSelf: "start",
    margin: 0,
  },
  box: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
  },
  primaryButton: {
    backgroundColor: "#6938EF",
    borderRadius: "8px",
    height: "40px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  sourceOfFundsInfoContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    padding: "8px",
    alignItems: "flex-start",
    marginBottom: "12px",
    justifyContent: "space-between",
    backgroundColor: "#F9FAFB",
    gap: "4px",
    width: "100%",
  },
  sourceOfFundsInfoRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "-webkit-fill-available",
    marginLeft: "16px",
    marginTop: "16px",
    marginRight: "16px",
  },
  divider: {
    backgroundColor: "#D0D5DD",
    height: "1px",
    width: "-webkit-fill-available",
    marginLeft: "16px",
    marginRight: "16px",
  },
  sourceOfFundsInfoTitle: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "8px",
  },
  sourceOfFundsInfoTotal: {
    color: "#334054",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "8px",
  },
};

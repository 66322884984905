const addRetentionToSelectedRetentions = (retentions, retention) => {
  if (retention === "none" && retentions.includes("none")) {
    return retentions;
  } else if (retention === "none" && !retentions.includes("none")) {
    return [retention];
  } else {
    if (retentions.includes(retention)) {
      const filtered = retentions.filter((r) => r !== retention);
      return filtered.length > 0 ? filtered : ["none"];
    }
    return [
      ...retentions.filter((retention) => retention !== "none"),
      retention,
    ];
  }
};

export default addRetentionToSelectedRetentions;

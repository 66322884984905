import React from "react";

export const TrxStepPendingIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect
      x="0.75"
      y="0.75"
      width="22.5"
      height="22.5"
      rx="11.25"
      stroke="#98A2B3"
      strokeWidth="1.5"
    />
    <circle cx="12" cy="12" r="4" fill="#98A2B3" />
  </svg>
);

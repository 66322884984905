export const daysFreeTrial = (freeDays, freeTrialStartAt) => {
  if (!freeTrialStartAt || isNaN(new Date(freeTrialStartAt).getTime())) {
    return freeDays;
  }

  const today = new Date();
  const trialStartDate = new Date(freeTrialStartAt);

  const trialStartDateMidnight = new Date(trialStartDate.setHours(0, 0, 0, 0));
  const todayMidnight = new Date(today.setHours(0, 0, 0, 0));

  const trialEndDate = new Date(trialStartDateMidnight);
  trialEndDate.setDate(trialEndDate.getDate() + freeDays);

  const differenceTime = trialEndDate - todayMidnight;
  const daysRemaining = Math.ceil(differenceTime / (1000 * 60 * 60 * 24));

  return daysRemaining > 0 ? daysRemaining : 0;
};

import React from "react";
import { Dropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { ExcelIcon, ArrowIcon, SiigoLogo } from "../../../assets";
import { styles } from "../styles";
import { Divider } from "@mui/material";
import { NewButton } from "../../../commons/buttons";

export const NewCollectionsDropdown = ({
  openUploadFileModal,
  openCollectionObligationModal,
  handleSyncErp,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  return (
    <Dropdown>
      <NewButton text="Cobro" />
      <Dropdown.Menu style={styles.noPadding}>
        <Dropdown.Item
          id="dropdown_button_create_payment"
          style={styles.newDropdownPadding}
          onClick={openCollectionObligationModal}
        >
          <div style={styles.dropdownItemContainer}>
            <span style={styles.dropdownItemIconContainer}>
              <ArrowIcon />
            </span>
            <div style={styles.dropdownItemTextContainer}>
              <p style={styles.dropdownItemTitle}>Cobro manual</p>
            </div>
          </div>
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item
          id="dropdown_button_updload_payments_list"
          style={styles.newDropdownPadding}
          onClick={() => handleSyncErp()}
        >
          <div style={styles.dropdownItemContainer}>
            <span style={styles.dropdownItemIconContainer}>
              <SiigoLogo />
            </span>
            <div style={styles.dropdownItemTextContainer}>
              <p style={styles.dropdownItemTitle}>Sincronizar Siigo</p>
            </div>
          </div>
        </Dropdown.Item>
        <Divider />
        {!isMobile && (
          <>
            <Dropdown.Item
              id="dropdown_button_updload_payments_list"
              style={styles.newDropdownPadding}
              onClick={() => openUploadFileModal("CSV")}
            >
              <div style={styles.dropdownItemContainer}>
                <span style={styles.dropdownItemIconContainer}>
                  <ExcelIcon width="24" height="24" />
                </span>
                <div style={styles.dropdownItemTextContainer}>
                  <p style={styles.dropdownItemTitle}>Archivo Excel</p>
                </div>
              </div>
            </Dropdown.Item>

            <Divider />
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

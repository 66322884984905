import { useState } from "react";
import { styles } from "../../SupplierDetails/styles";
import SiigoIntegrationForm from "./SiigoIntegrationForm";

const StepTwo = ({ primaryAction }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div style={styles.column}>
      <p style={{ ...styles.title, textAlign: "center" }}>
        <strong>Conectarse a Siigo</strong>
      </p>
      <p className="dian-modal-text">
        <span>Ingrese sus credenciales para comenzar a operar</span>
      </p>
      <SiigoIntegrationForm
        loading={loading}
        setLoading={setLoading}
        primaryAction={primaryAction}
      />
    </div>
  );
};

export default StepTwo;

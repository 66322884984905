import React from "react";
import { IconButton } from "@mui/material";
import { DeleteIcon } from "../../../assets";
import { Tooltip } from "commons/components";

export const DeleteIconContainer = ({ openDeleteItemModal, itemId }) => {
  return (
    <>
      <Tooltip title="Eliminar">
        <IconButton onClick={() => openDeleteItemModal(itemId)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

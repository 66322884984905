import React from "react";

export const SendIcon = ({ strokeColor = "#98A2B3" }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49952 12.5002L19.9995 2.00017M9.6271 12.8282L12.2552 19.5862C12.4867 20.1816 12.6025 20.4793 12.7693 20.5662C12.9139 20.6415 13.0862 20.6416 13.2308 20.5664C13.3977 20.4797 13.5139 20.1822 13.7461 19.5871L20.3364 2.69937C20.5461 2.16219 20.6509 1.8936 20.5935 1.72197C20.5437 1.57292 20.4268 1.45596 20.2777 1.40616C20.1061 1.34883 19.8375 1.45364 19.3003 1.66327L2.41258 8.25361C1.8175 8.48584 1.51997 8.60195 1.43326 8.76886C1.35809 8.91354 1.35819 9.08579 1.43353 9.23039C1.52043 9.39719 1.81811 9.51296 2.41345 9.74448L9.17146 12.3726C9.29231 12.4196 9.35273 12.4431 9.40361 12.4794C9.44871 12.5115 9.48815 12.551 9.52031 12.5961C9.55661 12.647 9.58011 12.7074 9.6271 12.8282Z"
      stroke={strokeColor}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const collectionStatusesStyles = {
  pending: {
    color: "#344054",
    backgroundColor: "#F2F4F7",
  },
  sent: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  approved: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  success: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  paid: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  paid_outside: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  in_process: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  outstanding_balance: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  incomplete: {
    color: "#B54708",
    backgroundColor: "#FFFAEB",
  },
  expired: {
    color: "#B54708",
    backgroundColor: "#FFFAEB",
  },
  to_be_approved: {
    color: "#c11574",
    backgroundColor: "#fff5f6",
  },
  to_be_checked: {
    color: "#c11574",
    backgroundColor: "#fff5f6",
  },
  rejected: {
    color: "#c11574",
    backgroundColor: "#fff5f6",
  },
  transfer: {
    color: "#B54708",
    backgroundColor: "#FFFAEB",
  },
  error: {
    color: "#c11574",
    backgroundColor: "#fff5f6",
  },
  finished: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  not_started: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  dispersion: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
};

export const collectionStatusPillWording = {
  pending: "Por notificar",
  pay_later: "Agrupada",
  approved: "Cobrada",
  success: "Cobrada",
  paid: "Cobrada",
  paid_outside: "Cobrada por fuera",
  in_process: "Por acreditar",
  outstanding_balance: "Saldo pendiente",
  to_be_approved: "Por revisar",
  to_be_checked: "En revisión",
  rejected: "Rechazada",
  transfer: "Inicio",
  error: "Error",
  finished: "Finalizada",
  not_started: "Por cobrar",
  expired: "Vencidas",
  sent: "Por cobrar",
  dispersion: "En dispersión",
};

const collectionsBaseStatusesFilter = {
  all: {
    value: "all",
    label: "Todas",
  },
  pending: {
    value: "pending",
    label: "Por notificar",
  },
  sent: {
    value: "sent",
    label: "Por cobrar",
  },
  in_process: {
    value: "in_process",
    label: "Por acreditar",
  },
  paid: {
    value: "paid",
    label: "Cobradas",
  },
};

export const collectionsAvailableStatusesFilters = Object.values(
  collectionsBaseStatusesFilter
);

export const collectionsMinimumLengthForSearch = 3;

export const collectionsTableStatusFilters = (
  approvationNeeded,
  currentUserRole
) => {
  return [
    collectionsBaseStatusesFilter.all,
    collectionsBaseStatusesFilter.pending,
    collectionsBaseStatusesFilter.sent,
    collectionsBaseStatusesFilter.in_process,
    collectionsBaseStatusesFilter.paid,
  ];
};

export const DEFAULT_COLLECTION_COMISSION = 1.3;
export const DEFAULT_COLLECTION_CARD_FEE = 3;

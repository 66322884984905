import React from "react";
import { Divider } from "@mui/material";
import styles from "./styles.module.css";

const CustomerDetailsCollectionForm = ({
  documentType = "NIT",
  documentNumber = "",
  email = "",
}) => (
  <div className={styles.container}>
    <div className={styles.cardContainer}>
      <p className={`card-subtitle mb-2 ${styles.cardTitle}`}>{documentType}</p>
      <p className={`"card-subtitle mb-2 ${styles.cardText}`}>
        {documentNumber}
      </p>
    </div>
    <Divider sx={{ marginBottom: "8px" }} />
    <div className={styles.cardContainer}>
      <p className={`card-subtitle mb-2 ${styles.cardTitle}`}>
        Correo electrónico
      </p>
      <p className={`"card-subtitle mb-2 ${styles.cardText}`}>{email}</p>
    </div>
  </div>
);

export default CustomerDetailsCollectionForm;

import React from "react";

import styles from "./styles.module.css";
import { useMediaQuery } from "react-responsive";
import { CurrencyIcon, PercentageIcon } from "../../../../assets";

export const OptionsTabs = ({ tabSelected, setTabSelected }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const handleChangeTab = (tabSelected) => {
    setTabSelected(tabSelected);
  };

  return (
    <div
      className={isMobile ? styles.tabsContainerMobile : styles.tabsContainer}
    >
      <button
        className={`${styles.tabBase} ${
          tabSelected === "amount" && styles.tabSelected
        } `}
        onClick={() => handleChangeTab("amount")}
      >
        <CurrencyIcon
          color={tabSelected === "amount" ? "#5925DC" : "#344054"}
        />
        <label
          className={`${styles.labelBase} ${
            tabSelected === "amount" && styles.labelSelected
          }`}
        >
          Monto
        </label>
      </button>
      <button
        className={`${styles.tabBase} ${
          tabSelected === "percentage" && styles.tabSelected
        }`}
        onClick={() => handleChangeTab("percentage")}
      >
        <PercentageIcon
          color={tabSelected === "percentage" ? "#5925DC" : "#344054"}
        />
        <label
          className={`${styles.labelBase} ${
            tabSelected === "percentage" && styles.labelSelected
          }`}
        >
          Porcentaje
        </label>
      </button>
    </div>
  );
};

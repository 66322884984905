export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowMarginTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "24px",
  },
  rowMarginTopBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "24px",
    marginBottom: "24px",
  },
  fixedRight: {
    marginTop: "12px",
    marginLeft: "auto",
  },
  headerRow: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
  },
  text: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
  },
  rowText: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
  },
  amountTitle: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
  },
  amount: {
    color: "#344054",
    fontWeight: "600",
    fontSize: "16px",
  },
  white: {
    backgroundColor: "#FFFFFF",
  },
  noPadding: {
    padding: "0px",
  },
  spinnerContainer: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  greyContainer: {
    backgroundColor: "#f5f6f7",
    borderColor: "#EAECF0",
    borderWidth: "1px 0px",
    padding: "16px",
  },
  firstItem: {
    marginTop: "-12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  middleItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  lastItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    overflowY: "scroll",
  },
  trxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "16px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textWithoutLimit: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
  },
  rowTextLeftWithoutLimit: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "left",
  },
  biggerRowTextLeftWithoutLimit: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "16px",
    textAlign: "left",
  },
  separator: {
    height: "1px",
    width: "100%",
    backgroundColor: "#E9ECF0",
    margin: "revert",
    opacity: "1",
    border: "0",
    marginTop: "-1px",
  },
};

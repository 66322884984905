const OptionComponent = ({
  props,
  option,
  className,
  getRowTooltipText,
  columns,
}) => {
  return (
    <li
      {...props}
      className={className}
      key={option.id}
      style={{
        cursor: "pointer",
      }}
      title={getRowTooltipText(option)}
    >
      <div style={{ display: "flex", padding: "8px 3px" }}>
        {columns.map((column) => {
          return (
            <div
              key={column.headerName}
              style={{
                textWrap: "nowrap",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                minWidth: column?.width || "100px",
              }}
            >
              {column.getText(option)}
            </div>
          );
        })}
      </div>
    </li>
  );
};

export default OptionComponent;

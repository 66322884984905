import { CircleMas } from "assets/svg/CircleMas";
import { Tooltip } from "commons";
import { format } from "date-fns";

export const receiptsColumns = (
  redirectToExpenses,
  canDisplayAssistedEgress
) => {
  const columns = [
    {
      field: "provider_name",
      Header: "Proveedor",
      headerName: "Proveedor",
      accessor: "provider_name",
      id: "provider_name",
      Cell: ({ row }) =>
        row.original.provider_name
          ? row.original.provider_name
          : row.original.service_name,
    },
    {
      field: "created_at",
      Header: "Fecha de pago",
      headerName: "Fecha de pago",
      accessor: "created_at",
      id: "created_at",
      Cell: ({ value }) => {
        if (value) {
          return format(new Date(value.slice(0, -1)), "dd/MM/yyyy");
        }
      },
    },
    {
      field: "amount",
      Header: "Monto",
      headerName: "Monto",
      accessor: "amount",
      id: "amount",
      headerClassName: "amount-header",
    },
    {
      field: "status",
      Header: "Estado",
      headerName: "Estado",
      accessor: "status",
      id: "status",
    },
  ];

  if (canDisplayAssistedEgress) {
    columns.push({
      field: "expenses",
      Header: "Egreso en Siigo",
      headerName: "Egreso en Siigo",
      accessor: "expenses",
      id: "expenses",
      Cell: ({ row }) => {
        if (row.original.accounting_receipt_number) {
          return row.original.accounting_receipt_number;
        } else if (row.original.service_id) {
          return;
        } else if (row.original.status_payment_gateway === "FINISHED") {
          return (
            <div
              onClick={() => {
                redirectToExpenses(
                  row.original.provider_document_number,
                  row.original.id
                );
              }}
            >
              <CircleMas />
            </div>
          );
        } else {
          return (
            <Tooltip title="Podrás realizar el egreso cuando el pago se haya dispersado totalmente">
              <div>
                <CircleMas displayPlus={false} />
              </div>
            </Tooltip>
          );
        }
      },
    });
  }

  return columns;
};

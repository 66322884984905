import React from "react";

import { Divider } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { styles } from "../styles";
import { retentionName } from "constants";

export const OthersCommissionSummarySection = ({ transaction }) => {
  const commissionType = transaction.transaction_commission_type;

  const commissionBeneficiaryTitle = {
    invoice: "Proveedores abonados",
    payroll: "Empleados abonados",
    collection: "Documentos cobrados",
  };

  const commissionTitle = {
    invoice: "Comisión por proveedor",
    payroll: "Comisión por empleado",
    collection: "Comisión por documento",
  };

  return (
    <div style={styles.sectionContainer}>
      <p style={styles.sectionTitle}>Resumen</p>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          {commissionBeneficiaryTitle[transaction.transaction_type]}
        </p>
        <p style={styles.transactionHash} className="card-subtitle mb-2">
          {transaction?.beneficiaries_amount}
        </p>
      </div>
      <div style={styles.sectionRow}>
        <p style={styles.title} className="card-subtitle mb-2">
          {commissionType === "fixed"
            ? commissionTitle[transaction.transaction_type]
            : "Comisión"}
        </p>
        <p style={styles.transactionHash} className="card-subtitle mb-2">
          {transaction.transaction_commission_type === "fixed" ? (
            <NumericFormat
              value={parseInt(transaction?.comission_value)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          ) : (
            `${transaction?.comission_value}%`
          )}
        </p>
      </div>
      <Divider sx={styles.sectionDivider} />
      {transaction?.commission_retentions.length > 0 && (
        <>
          <div style={styles.sectionRow}>
            <p style={styles.title} className="card-subtitle mb-2">
              Monto bruto
            </p>
            <p style={styles.transactionHash} className="card-subtitle mb-2">
              <NumericFormat
                value={parseInt(transaction?.amount_comission)}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                decimalScale={2}
              />
            </p>
          </div>
          {transaction?.commission_retentions.map((retention) => {
            return (
              <div style={styles.sectionRow}>
                <p style={styles.title} className="card-subtitle mb-2">
                  {retentionName[retention?.key]}
                </p>
                <p
                  style={styles.transactionHash}
                  className="card-subtitle mb-2"
                >
                  <NumericFormat
                    value={parseInt(retention.amount)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"-$"}
                    decimalScale={2}
                  />
                </p>
              </div>
            );
          })}
          <Divider sx={styles.sectionDivider} />
        </>
      )}
      <div style={styles.sectionRow}>
        <p
          style={styles.transactionNetCommissionTitle}
          className="card-subtitle mb-2"
        >
          Monto neto
        </p>
        <p
          style={styles.transactionNetCommission}
          className="card-subtitle mb-2"
        >
          <NumericFormat
            value={parseInt(transaction?.amount_net_comission)}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            decimalScale={2}
          />
        </p>
      </div>
    </div>
  );
};

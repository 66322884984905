import React from "react";

export const BankIcon = ({
  color = "#7F56D9",
  width = "16",
  height = "16",
}) => (
  <svg width={width} height={height} viewBox="0 0 23 23" fill="none">
    <path
      d="M2.82654 20.6528L19.5135 20.6528M2.82654 9.52736L19.5135 9.52736M5.6077 12.3093L5.6077 17.8716M11.17 12.3093L11.17 17.8716M16.7323 12.3093V17.8716M2.82654 6.74618L11.17 2.11172L19.5135 6.74618"
      stroke={color}
      strokeWidth="1.85411"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";

export const UpdateIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M5.69758 13.6783C7.297 14.3879 9.20197 14.3494 10.8333 13.4075C13.5436 11.8427 14.4722 8.37704 12.9074 5.66672L12.7408 5.37804M3.09217 11.3335C1.52737 8.62314 2.45599 5.15746 5.16632 3.59265C6.79764 2.65081 8.70261 2.61227 10.302 3.32185M1.66211 11.3892L3.48348 11.8773L3.97151 10.0559M12.0283 6.94393L12.5163 5.12256L14.3377 5.6106"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

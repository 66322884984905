import { ADMIN, COLAB } from "./roles";

export const documentType = [
  { value: "advance", name: "Anticipo" },
  { value: "credit", name: "Crédito" },
  { value: "purchase", name: "Compra" },
  { value: "support", name: "Documento soporte" },
  { value: "invoice", name: "Factura" },
];

export const serviceTypeArray = [
  { value: "service", name: "Factura servicio" },
];
export const serviceType = "service";

export const statusesStyles = {
  pending: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  pay_later: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  approved: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  success: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  paid: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  in_process: {
    color: "#175CD3",
    backgroundColor: "#EFF8FF",
  },
  outstanding_balance: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  incomplete: {
    color: "#B54708",
    backgroundColor: "#FFFAEB",
  },
  to_be_approved: {
    color: "#c11574",
    backgroundColor: "#fff5f6",
  },
  to_be_checked: {
    color: "#c11574",
    backgroundColor: "#fff5f6",
  },
  rejected: {
    color: "#c11574",
    backgroundColor: "#fff5f6",
  },
  transfer: {
    color: "#B54708",
    backgroundColor: "#FFFAEB",
  },
  error: {
    color: "#c11574",
    backgroundColor: "#fff5f6",
  },
  finished: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  not_started: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },

  batched: {
    color: "#3538CD",
    backgroundColor: "#EEF4FF",
  },
  to_disperse: {
    color: "#026AA2",
    backgroundColor: "#F0F9FF",
  },
  in_dispersion: {
    color: "#026AA2",
    backgroundColor: "#F0F9FF",
  },
  paid_outside: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  dispersed: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  receipt_paid: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  tx_in_process: {
    color: "#175CD3",
    backgroundColor: "#EFF8FF",
  },
  tx_paid: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  tx_collected: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  tx_rejected: {
    color: "#c11574",
    backgroundColor: "#fff5f6",
  },
  tx_pay_later: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  refunded: {
    color: "#2D3282",
    backgroundColor: "#F5F8FF",
  },
  not_reconciled: {
    color: "#B54708",
    backgroundColor: "#FFFAEB",
  },
  partially_reconciled: {
    color: "#B54708",
    backgroundColor: "#FFFAEB",
  },
  reconciled: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  reconciled_outside: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
};

export const statusPillWording = {
  pending: "Por pagar",
  pay_later: "En lote",
  approved: "Pagada",
  success: "Pagada",
  in_process: "En proceso",
  outstanding_balance: "Saldo pendiente",
  incomplete: "Incompleta",
  to_be_approved: "Por revisar",
  to_be_checked: "En revisión",
  rejected: "Rechazada",
  transfer: "Inicio",
  error: "Error",
  finished: "Finalizado",
  not_started: "No inicio",
  paid_outside: "Pagada por fuera",
  in_dispersion: "En dispersión",
  paid: "Pagada",
  batched: "En lote",
  to_disperse: "Por dispersar",
  receipt_paid: "Pagado",
  dispersed: (status) => `Dispersado ${status.proccesed}/${status.total}`,
  tx_in_process: "En proceso",
  tx_paid: "Pagado",
  tx_collected: "Cobrado",
  tx_rejected: "Rechazado",
  tx_pay_later: "Por pagar",
  refunded: "Devuelto",
  not_reconciled: "No conciliado",
  partially_reconciled: "Parcialmente conciliado",
  reconciled: "Conciliado",
  reconciled_outside: "Marcado como conciliado",
};

const baseStatusesFilter = {
  all: {
    value: "all",
    label: "Todas",
  },
  pending: {
    value: "pending",
    label: "Por pagar",
  },
  batched: {
    value: "batched",
    label: "En lote",
  },
  to_disperse: {
    value: "to_disperse",
    label: "Por dispersar",
  },
  in_dispersion: {
    value: "in_dispersion",
    label: "En dispersión",
  },
  in_process: {
    value: "in_process",
    label: "En proceso",
  },
  paid: {
    value: "paid",
    label: "Pagadas",
  },
  to_be_approved: {
    value: "to_be_approved",
    label: statusPillWording["to_be_approved"],
  },
  to_be_checked: {
    value: "to_be_checked",
    label: statusPillWording["to_be_checked"],
  },
};

export const availableStatusesFilters = Object.values(baseStatusesFilter);

export const sampleInvoiceFile =
  "https://tap-payana-col-tbp-invoices-production.s3.amazonaws.com/Template+carga+masiva+de+facturas.xlsx";

export const minimumLengthForSearch = 3;

export const invoicesTableStatusFilters = (
  approvationNeeded,
  currentUserRole
) => {
  if (approvationNeeded && currentUserRole === ADMIN) {
    return [
      baseStatusesFilter.all,
      baseStatusesFilter.to_be_approved,
      baseStatusesFilter.pending,
      baseStatusesFilter.batched,
      baseStatusesFilter.in_process,
      baseStatusesFilter.to_disperse,
      baseStatusesFilter.in_dispersion,
      baseStatusesFilter.paid,
    ];
  }
  if (approvationNeeded && currentUserRole === COLAB) {
    return [
      baseStatusesFilter.all,
      baseStatusesFilter.to_be_checked,
      baseStatusesFilter.pending,
      baseStatusesFilter.batched,
      baseStatusesFilter.in_process,
      baseStatusesFilter.to_disperse,
      baseStatusesFilter.in_dispersion,
      baseStatusesFilter.paid,
    ];
  }
  return [
    baseStatusesFilter.all,
    baseStatusesFilter.pending,
    baseStatusesFilter.batched,
    baseStatusesFilter.in_process,
    baseStatusesFilter.to_disperse,
    baseStatusesFilter.in_dispersion,
    baseStatusesFilter.paid,
  ];
};
export const DEFAULT_PAYMENT_CARD_FEE = 4;

export const typeInvoice = {
  invoice: "invoice",
};

export const originInvoice = {
  dian: "dian",
  manual: "manual",
  api: "api",
};

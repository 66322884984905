export const RightArrowIcon = ({ color = "#344054", size = "14px" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path
        d="M1.16602 7.00008H12.8327M12.8327 7.00008L6.99935 1.16675M12.8327 7.00008L6.99935 12.8334"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { requestContainer } from "./api/requestContainer";

const fetchDianPDF = (invoiceId) => {
  return requestContainer({
    url: "/invoices/fetch-pdf",
    method: "post",
    data: {
      invoice_id: invoiceId,
    },
  });
};

export { fetchDianPDF };

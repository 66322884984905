import { Box, LinearProgress } from "@mui/material";
import { styles } from "./styles";
import { Modal } from "react-bootstrap";

export const LinearProgressModal = ({
  visible,
  handleClose,
  title,
  subtitle,
}) => {
  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body>
        <div style={styles.column}>
          <p style={{ ...styles.title, textAlign: "center" }}>
            <strong>{title}</strong>
          </p>
          <p
            style={{
              color: "#475467",
              fontSize: 14,
              textAlign: "center",
              fontWeight: 400,
            }}
          >
            <span>{subtitle}</span>
          </p>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      </Modal.Body>
    </Modal>
  );
};

import { PlusIcon2 } from "../../../assets";
import { PrimaryButton, SecondaryButton } from "../../../commons/buttons";

const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
    gap: "16px",
  },
};

export const ServicesCreateButtons = ({
  openManualCreate,
  openMassiveCreate,
}) => (
  <div style={styles.row}>
    <SecondaryButton
      icon={<PlusIcon2 color="#1D2939" style={{ marginRight: "4px" }} />}
      text="Carga masiva"
      action={openMassiveCreate}
      width="150px"
    />
    <PrimaryButton
      text="Carga manual"
      action={openManualCreate}
      width="150px"
      loading={false}
    />
  </div>
);

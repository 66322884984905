import React from "react";
import { Divider, Tooltip, Typography } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  ArrowNarrowBottomLeftIcon,
  ArrowNarrowUpRightIcon,
  PartiallyReconciledIcon,
  PayanaIcon,
  ReconciledIcon,
} from "assets";
import { NumericFormat } from "react-number-format";
import { formatLocaleDate } from "utils";
import { styles } from "../styles";

export const PayanaReceiptItem = ({
  selectedMovement,
  movement,
  setSelectedMovement,
  handleSelectMovement,
}) => {
  const beneficiaryName = {
    invoice: movement?.provider?.name,
    collection: movement?.customer?.name,
    payroll: movement?.employee?.name,
    commission: "Comisión Payana",
  };

  const documents = {
    invoice: movement?.invoices,
    collection: movement?.collectionsReceivers,
    payroll: movement?.payrolls,
    commission: null,
  };

  const documentNumber = {
    invoice: (document) => {
      return document.invoice_number;
    },
    collection: (document) => {
      return document.concept;
    },
    payroll: (document) => {
      return document.period;
    },
  };

  return (
    <div
      style={
        selectedMovement.id === movement.id
          ? styles.selectedMovement
          : styles.movement
      }
      onClick={() => {
        if (selectedMovement.id === movement.id) {
          setSelectedMovement({});
        } else {
          handleSelectMovement(movement);
        }
      }}
    >
      <div style={styles.movementRow}>
        <div style={styles.movementColumn}>
          <Typography sx={styles.movementText}>
            {formatLocaleDate(movement?.createdAt, "dd/MM/yyyy")}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <PayanaIcon width="16px" height="16px" />
            <Typography
              sx={{
                ...styles.movementText,
                fontWeight: "500",
              }}
            >
              {beneficiaryName[movement?.transactionType]}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={styles.movementCenteredRow}>
            <Typography sx={styles.movementAmount}>
              <NumericFormat
                value={
                  movement?.transactionType === "collection" ||
                  movement?.transactionType === "commission"
                    ? movement?.amount
                    : -movement?.amount
                }
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                decimalScale={2}
              />
            </Typography>
            {movement?.transactionType === "collection" ? (
              <ArrowNarrowBottomLeftIcon width="19px" height="24px" />
            ) : (
              <ArrowNarrowUpRightIcon width="19px" height="24px" />
            )}
          </div>
          {movement.reconciliationStatus === "reconciled" && (
            <Tooltip title="Conciliado">
              <span style={{ alignSelf: "flex-end" }}>
                <ReconciledIcon />
              </span>
            </Tooltip>
          )}
          {movement.reconciliationStatus === "partially_reconciled" && (
            <Tooltip title="Parcialmente conciliado">
              <span style={{ alignSelf: "flex-end" }}>
                <PartiallyReconciledIcon />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      {documents[movement?.transactionType] && (
        <Typography sx={styles.movementDocumentsTitle}>
          Documentos de la transacción
        </Typography>
      )}
      {documents[movement?.transactionType]?.map((document, index) => {
        return (
          <>
            <div style={styles.movementDocumentContainer}>
              <Typography sx={styles.movementDocumentText}>
                {documentNumber[movement?.transactionType](document)}
              </Typography>
              <Typography sx={styles.movementDocumentText}>
                <NumericFormat
                  value={document.amount_total || document.amount}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                  decimalScale={2}
                />
              </Typography>
            </div>
            {index !== documents[movement?.transactionType].length - 1 && (
              <Divider
                sx={{
                  ...styles.reconciledDivider,
                  marginBottom: "6px",
                }}
              />
            )}
          </>
        );
      })}
    </div>
  );
};

import { useContext } from "react";
import { SecondaryButton } from "../../../commons/buttons";
import { PaymentsContext, UserContext } from "../../../contexts";
import { Typography, Stack, Fade } from "@mui/material";
import { useSyncAccountingSystem } from "../../../hooks/useSyncAccountingSystem";
import { RefreshIcon } from "../../../assets";
import { styles } from "../styles";
import { CheckSyncErp, SyncErp } from "../../../services";
import { formatLocaleDate, handleSyncErpResponse } from "../../../utils";

export const SyncAccountingSystem = ({
  openDianIntegrationModal = () => {},
  openSiigoIntegrationModal = () => {},
  refreshInvoiceTable = () => {},
  setSyncBanner = () => {},
}) => {
  const { currentCompany } = useContext(UserContext);
  const { syncIsLoading, setSyncIsLoading, syncHasError, syncBtnIsDisabled } =
    useContext(PaymentsContext);
  const { syncAccountingSystem } = useSyncAccountingSystem();

  const openModal = () => {
    openSiigoIntegrationModal();
    setSyncIsLoading(false);
  };

  const onSuccess = () => {
    setSyncBanner((syncBanner) => {
      return {
        ...syncBanner,
        visible: true,
        type: "success",
        title: "Importación exitosa.",
      };
    });
    refreshInvoiceTable();
  };

  return (
    <div className="sync-cs">
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Fade in={syncHasError && syncBtnIsDisabled}>
          <Typography color={"red"} variant="subtitle2">
            Ocurrió un error durante la sincronización.
          </Typography>
        </Fade>
        {currentCompany?.last_sync_at && (
          <div style={styles.syncContainer}>
            Actualizado:{" "}
            {formatLocaleDate(
              new Date(currentCompany?.last_sync_at),
              "dd MMM yyyy, h:mmaaa"
            )}
          </div>
        )}
        <SecondaryButton
          fontSize="12px"
          height="40px"
          width="180px"
          textStyle={{
            paddingLeft: 8,
            fontSize: "14px",
            color: "#344054",
          }}
          text={`Sincronizar ${currentCompany.name_cs}`}
          action={async () => {
            if (currentCompany.name_cs === "DIAN") {
              openDianIntegrationModal();
            } else if (currentCompany?.name_cs?.toLowerCase() === "alegra") {
              syncAccountingSystem(currentCompany.name_cs);
            } else {
              setSyncIsLoading(true);
              setSyncBanner((syncBanner) => {
                return {
                  ...syncBanner,
                  visible: true,
                  type: "loading",
                  title: "Actualizando información de Siigo",
                };
              });
              await CheckSyncErp().then((response) => {
                const syncValidation = response?.syncValidation;
                syncValidation?.credentials
                  ? SyncErp().then((res) => {
                      handleSyncErpResponse(res, onSuccess, setSyncBanner);
                      setSyncIsLoading(false);
                    })
                  : openModal();
              });
            }
          }}
          isDisable={syncIsLoading || syncBtnIsDisabled}
          icon={<RefreshIcon />}
        />
      </Stack>
    </div>
  );
};

export const saveInLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  return value;
};

export const logOut = () => {
  localStorage.setItem("token", "xxx");
  localStorage.setItem("refreshToken", "xxx");
};

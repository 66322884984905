import { Stack } from "@mui/material";

export default function SourceOfFundsSelector(props) {
  const { selected, setSelected } = props;
  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      gap={2}
      style={{
        background: "#F9FAFB",
        padding: "6px",
      }}
    >
      <SourceOfFundsOption
        active={selected === "bank_account"}
        onClick={() => setSelected("bank_account")}
        label="Cuenta bancaria"
        icon={
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.66667 7.50006V14.1667M8.41667 7.50006V14.1667M12.5833 7.50006V14.1667M16.3333 7.50006V14.1667M3 15.5001L3 16.1667C3 16.6334 3 16.8668 3.09083 17.045C3.17072 17.2019 3.29821 17.3293 3.45501 17.4092C3.63327 17.5001 3.86662 17.5001 4.33333 17.5001H16.6667C17.1334 17.5001 17.3667 17.5001 17.545 17.4092C17.7018 17.3293 17.8293 17.2019 17.9092 17.045C18 16.8668 18 16.6334 18 16.1667V15.5001C18 15.0333 18 14.8 17.9092 14.6217C17.8293 14.4649 17.7018 14.3374 17.545 14.2576C17.3667 14.1667 17.1334 14.1667 16.6667 14.1667H4.33333C3.86662 14.1667 3.63327 14.1667 3.45501 14.2576C3.29821 14.3374 3.17072 14.4649 3.09083 14.6217C3 14.8 3 15.0333 3 15.5001ZM10.2108 2.56433L4.04409 3.9347C3.67154 4.01749 3.48527 4.05889 3.34622 4.15906C3.22358 4.24742 3.12727 4.36747 3.06762 4.50636C3 4.66383 3 4.85465 3 5.23629L3 6.16672C3 6.63343 3 6.86679 3.09083 7.04505C3.17072 7.20185 3.29821 7.32933 3.45501 7.40923C3.63327 7.50006 3.86662 7.50006 4.33333 7.50006H16.6667C17.1334 7.50006 17.3667 7.50006 17.545 7.40923C17.7018 7.32933 17.8293 7.20185 17.9092 7.04505C18 6.86679 18 6.63343 18 6.16672V5.23629C18 4.85465 18 4.66383 17.9324 4.50636C17.8727 4.36747 17.7764 4.24742 17.6538 4.15906C17.5147 4.05889 17.3285 4.01749 16.9559 3.9347L10.7892 2.56433C10.6813 2.54035 10.6273 2.52835 10.5728 2.52357C10.5244 2.51932 10.4756 2.51932 10.4272 2.52357C10.3727 2.52835 10.3187 2.54035 10.2108 2.56433Z"
              stroke={selected === "bank_account" ? "#5925DC" : "#667085"}
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      />
      <SourceOfFundsOption
        active={selected === "card_reference"}
        onClick={() => setSelected("card_reference")}
        label="Tarjeta"
        icon={
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.8333 8.33334H2.16663M9.66663 11.6667H5.49996M2.16663 6.83334L2.16663 13.1667C2.16663 14.1001 2.16663 14.5668 2.34828 14.9233C2.50807 15.2369 2.76304 15.4919 3.07664 15.6517C3.43316 15.8333 3.89987 15.8333 4.83329 15.8333L16.1666 15.8333C17.1 15.8333 17.5668 15.8333 17.9233 15.6517C18.2369 15.4919 18.4919 15.2369 18.6516 14.9233C18.8333 14.5668 18.8333 14.1001 18.8333 13.1667V6.83334C18.8333 5.89992 18.8333 5.43321 18.6516 5.07669C18.4919 4.76309 18.2369 4.50812 17.9233 4.34833C17.5668 4.16667 17.1 4.16667 16.1666 4.16667L4.83329 4.16667C3.89987 4.16667 3.43316 4.16667 3.07664 4.34833C2.76304 4.50812 2.50807 4.76308 2.34828 5.07669C2.16663 5.43321 2.16663 5.89992 2.16663 6.83334Z"
              stroke={selected === "card_reference" ? "#5925DC" : "#667085"}
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      />
    </Stack>
  );
}

function SourceOfFundsOption({ icon, label, active = false, onClick }) {
  return (
    <div
      onClick={onClick}
      className={
        active ? "source-of-funds-option actived" : "source-of-funds-option"
      }
    >
      {icon}
      <span style={{ paddingLeft: 10 }}>{label}</span>
    </div>
  );
}

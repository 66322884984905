import React from "react";
import { Box } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { styles } from "./styles";
import { ModalDeleteIcon } from "../../../assets";

export const RepeatPayrollModal = ({
  action,
  visible,
  handleClose,
  formValues,
}) => {
  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body>
        <Box sx={styles.container}>
          <ModalDeleteIcon />
          <p style={styles.title}>¡Nómina repetida!</p>
          <p style={styles.subtitle}>
            Ya existe una nómina pendiente que contiene al empleado
            seleccionado. ¿Desea reemplazarla?
          </p>
          <div style={styles.row}>
            <Button
              variant="secondary"
              style={styles.modalSecondaryButton}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              style={styles.modalPrimaryButton}
              onClick={() => {
                action(formValues, true).then((res) => {
                  handleClose();
                });
              }}
            >
              Reemplazar
            </Button>
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

import { requestContainer } from "../requestContainer";

export async function GetPayroll({ pageIndex, employeeId }) {
  return requestContainer({
    method: "get",
    url: `/payroll?page=${pageIndex}&employee_id=${employeeId}`,
  });
}

export async function GetTotalpayrolls({ employeeId }) {
  return requestContainer({
    method: "get",
    url: `/payroll/total?employee_id=${employeeId}`,
  });
}

export async function CreatePayroll({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/payroll`,
    data: {
      ...formValues,
    },
  });
}

export async function UpdatePayroll({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/payroll/${id}`,
    data: {
      ...formValues,
    },
  });
}

export async function UpdateMultiplePayrolls(ids, period) {
  return requestContainer({
    method: "put",
    url: `/payroll/multiple-period`,
    data: {
      ids: ids,
      period: period,
    },
  });
}

export async function DeletePayrollItem({ id }) {
  return requestContainer({ method: "delete", url: `/payroll/${id}` });
}

export async function DeletePayroll() {
  return requestContainer({ method: "delete", url: `/payroll` });
}

export function UploadPayroll(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/payroll",
    data: formData,
  });
}

export function UploadPayrollV2(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/csv/payrolls",
    data: formData,
  });
}

export async function ClonePayroll(id = null, period) {
  return requestContainer({
    method: "post",
    url: `/payroll/clone`,
    data: {
      transactionId: id,
      period: period,
    },
  });
}

export async function CreatePayrollfromEmployees(period) {
  return requestContainer({
    method: "post",
    url: `/payroll/by-employees?period=${period}`,
  });
}

export async function MarkAsPaid() {
  return requestContainer({ method: "post", url: `/payroll/mark-as-paid` });
}

import { Box } from "@mui/material";

const CustomPaper =
  (columns) =>
  ({ children }) => {
    return (
      <Box className="autocomplete-table-container">
        <div
          style={{
            display: "flex",
            padding: "8px 3px",
            borderBottom: "2px solid #ddd",
          }}
        >
          {columns.map((column) => {
            return (
              <div
                key={column.headerName}
                style={{
                  minWidth: column.width || "100px",
                  textWrap: "nowrap",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {column.headerName}
              </div>
            );
          })}
        </div>
        {children}
      </Box>
    );
  };

export default CustomPaper;

import React from "react";
import { Box, Dialog, Stack } from "@mui/material";
import { CrownIcon } from "../../../assets";
import { PrimaryButton } from "../../buttons";
import styles from "./trial-modal.module.css";

export const ReconciliationsTrialModal = ({
  onClose,
  open,
  buttonText = "Entendido",
}) => {
  return (
    <Dialog onClose={onClose} open={!!open}>
      <Box style={{ padding: 24, width: 467 }}>
        <div className={styles["icon-container"]}>
          <CrownIcon size="medium" />
        </div>
        <p className={styles["title"]}>Inició tu prueba gratis</p>
        <p className={styles["description"]}>
          Tienes 14 días para probar la solución de conciliaciones.
        </p>
        <p className={styles["text"]}>Luego, esta funcionalidad será paga.</p>

        <Stack className={styles["button-container"]} alignItems="center">
          <PrimaryButton
            text={buttonText}
            action={onClose}
            style={{ width: "100%" }}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};

import React from "react";
import { Steps } from "intro.js-react";
import { styles } from "../styles";

export const TransactionsDetailsIntro = ({ enabled, onComplete }) => {
  const tutorialSteps = [
    {
      element: ".moneyJourneySection",
      intro: (
        <div style={styles.tooltipColumn}>
          <p style={styles.tooltipTitle}>
            Sigue todos los pasos del recorrido de tu dinero.
          </p>
        </div>
      ),
      position: "left",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".documentsSection",
      intro: (
        <div style={styles.tooltipColumn}>
          <p style={styles.tooltipTitle}>
            Aquí encontrarás los documentos involucrados en esta transacción.
          </p>
        </div>
      ),
      position: "right",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
  ];

  return (
    <Steps
      enabled={enabled}
      steps={tutorialSteps}
      initialStep={0}
      onComplete={onComplete}
      onExit={() => {}}
      options={{
        showBullets: true,
        prevLabel: "Anterior",
        nextLabel: "Siguiente",
        doneLabel: "Entendido",
        hidePrev: true,
      }}
    />
  );
};

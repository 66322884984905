import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
} from "@mui/material";
import FailedMessageItem from "./FailedMessageItem";

import "./massive-create-failed-modal.css";
import { PrimaryButton } from "commons/buttons";
import { HelpText } from "commons";

function CustomPaper({ children }) {
  return (
    <Paper
      sx={{
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            fontSize: "14px",
          },
        },
      }}
    >
      {children}
    </Paper>
  );
}

const MassiveCreateFailedModal = ({ items, primaryAction }) => {
  return (
    <Dialog open PaperComponent={CustomPaper}>
      <div className="dialog-container">
        <DialogTitle id="scroll-dialog-title">
          <Stack>
            <p className="dialog-header-title">
              No pudimos cargar los siguientes servicios:
            </p>
            <p className="dialog-header-subtitle">
              Revisa la información de cada uno y de ser el caso, vuelve a
              intentar.
            </p>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: "24px 14px 24px 24px" }}>
          <div className="dialog-container">
            <div className="failed-message-items-container">
              {items.map((item) => (
                <FailedMessageItem key={item.modalityValue} item={item} />
              ))}
            </div>
            <div className="scroll-gradient"></div>
          </div>
        </DialogContent>
      </div>
      <HelpText>
        Te sugerimos tomar una captura de pantalla de esta información
      </HelpText>
      <DialogActions sx={{ paddingRight: "24px", paddingBottom: "24px" }}>
        <Stack direction="row" className="dialog-button-container">
          <PrimaryButton text="Entendido" action={primaryAction} />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default MassiveCreateFailedModal;

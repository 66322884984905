import { useContext, useRef } from "react";
import { TrackJS } from "trackjs";
import {
  getAmountInCents,
  getWompiKeyByPaymentMethod,
  getTransactionToPay,
} from "../../commons/modals/PaymentModal";
import { UserContext } from "../../contexts";
import { toast } from "react-toastify";
import { toastOptions } from "../../constants";
import { hasSomeAssociatedDocumentDeleted } from "../../utils";

const removeAllChildNodes = (ref) => {
  while (ref.firstChild) {
    ref.removeChild(ref.firstChild);
  }
};

export const useWompi = (
  totalAmount,
  redirectUrl,
  selectedPaymentMethod,
  transactionPayload,
  selectedRowsIds = [],
  section = null,
  setOpenDeletedAlert = () => {},
  handleClose = () => {},
  setLimitExceededAlert = () => {}
) => {
  const { newPricing, newPricingMetadata } = useContext(UserContext);
  const wompiButton = useRef(null);

  const amountTotalRound = Math.round(totalAmount);

  const openWompiFlow = async () => {
    try {
      removeAllChildNodes(wompiButton.current);

      transactionPayload.paymentGateway = "wompi";
      transactionPayload.paymentMethod = selectedPaymentMethod;
      const transaction = await getTransactionToPay(transactionPayload);

      if (transaction?.errors && section !== "customer") {
        setLimitExceededAlert((limitExceeded) => {
          return {
            ...limitExceeded,
            visible: true,
            availableAmount: transaction.errors[0].availableAmount,
            limitAmount: transaction.errors[0].limitAmount,
          };
        });
        handleClose();
        return;
      }

      if (hasSomeAssociatedDocumentDeleted(transaction)) {
        setOpenDeletedAlert(true);
        handleClose();
        return;
      }

      const script = document.createElement("script");

      script.setAttribute("src", "https://checkout.wompi.co/widget.js");
      script.setAttribute("data-render", "button");
      script.setAttribute(
        "data-public-key",
        getWompiKeyByPaymentMethod(selectedPaymentMethod)
      );
      script.setAttribute("data-currency", "COP");
      script.setAttribute("data-amount-in-cents", amountTotalRound * 100);
      script.setAttribute("data-reference", transaction?.hash);
      script.setAttribute("data-redirect-url", redirectUrl);
      if (transaction.integrity_key) {
        script.setAttribute(
          "data-signature:integrity",
          transaction.integrity_key
        );
      }
      wompiButton.current?.appendChild(script);

      return new Promise((resolve) => {
        setTimeout(() => {
          wompiButton.current[0]?.click();
          resolve();
          handleClose();
        }, 2000);
      });
    } catch (error) {
      TrackJS.console.error(error.message);
      toast.error("Ha ocurrido un error, intente nuevamente", toastOptions);
    }
  };
  return {
    wompiButton,
    openWompiFlow,
  };
};

import React from "react";
import { styles } from "../styles";
import { RefreshOneIcon } from "assets";
import { Typography } from "@mui/material";

export const InvoicesProcess = ({
  reloadInvoices = () => {},
  onClick = () => {},
}) => {
  return (
    <div
      style={{
        ...styles.causationBannerContainer,
        borderRadius: "12px",
        border: "1px solid #D0D5DD",
        background: "#FCFCFD",
        boxShadow:
          "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      }}
    >
      <div style={{ ...styles.row, gap: "12px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "4px",
            marginTop: "2px",
          }}
        >
          <Typography
            sx={{
              ...styles.causationBannerTitle,
              color: "#101828",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Por favor, actualiza para ver las facturas procesadas.
          </Typography>
        </div>
      </div>
      <div style={{ ...styles.row, gap: "8px" }} onClick={reloadInvoices}>
        <div style={styles.causationBannerAction}>
          <Typography style={styles.causationBannerLink}>Actualizar</Typography>
        </div>
        <div style={{ cursor: "pointer" }} onClick={onClick}>
          <RefreshOneIcon height={20} width={20} strokeColor="#5925DC" />
        </div>
      </div>
    </div>
  );
};

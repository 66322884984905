import React, { useEffect, useState } from "react";
import { Steps } from "intro.js-react";
import { getFromLocalStorage, saveInLocalStorage } from "utils";
import { styles } from "./style-multiple-selected";

export const MultipleSelectedIntro = ({ idCellRef }) => {
  const [introEnabled, setIntroEnabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const fieldLocalStore = "showMultipleSelectTutorial";

  const tutorialStep = [
    {
      element: ".multipleSelect",
      intro: (
        <div style={styles.tutorialStepContainer}>
          <p style={styles.tutorialStepFristText}>
            Selecciona los ítems que quieras asignar
          </p>
          <p style={styles.tutorialStepSecondText}>
            Puedes seleccionar múltiples
          </p>
        </div>
      ),
      position: "top",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".multipleSelect-1",
      intro: (
        <div style={styles.tutorialStepContainer}>
          <p style={styles.tutorialStepFristText}>
            Asígnale a cada columna un valor
          </p>
          <p style={styles.tutorialStepSecondText}>Para Producto</p>
        </div>
      ),
      position: "top",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".multipleSelect-2",
      intro: (
        <div style={styles.tutorialStepContainer}>
          <p style={styles.tutorialStepFristText}>
            Asígnale a cada columna un valor
          </p>
          <p style={styles.tutorialStepSecondText}>Para Impuestos Cargo</p>
        </div>
      ),
      position: "top",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".multipleSelect-3",
      intro: (
        <div style={styles.tutorialStepContainer}>
          <p style={styles.tutorialStepFristText}>
            Asígnale a cada columna un valor
          </p>
          <p style={styles.tutorialStepSecondText}>Para Impuestos Retención</p>
        </div>
      ),
      position: "top",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
  ];

  useEffect(() => {
    if (getFromLocalStorage(fieldLocalStore) === "false") return;
    const timer = setTimeout(() => {
      if (idCellRef.current) {
        idCellRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        setTimeout(() => {
          setIntroEnabled(true);
        }, 750);
      }
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Steps
      enabled={introEnabled}
      steps={tutorialStep}
      initialStep={0}
      onChange={(nextStep) => setCurrentStep(nextStep + 1)}
      onComplete={() => {
        saveInLocalStorage(fieldLocalStore, "false");
        setIntroEnabled(false);
      }}
      onExit={() => {
        saveInLocalStorage(fieldLocalStore, "false");
        setIntroEnabled(false);
      }}
      options={{
        prevLabel: "Anterior",
        nextLabel: `Siguiente ${currentStep}/4`,
        showBullets: false,
        doneLabel: "Entendido 4/4",
        scrollToElement: false,
        hidePrev: true,
      }}
    />
  );
};

import React from "react";

export const MagicWandIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.04683 5.8104L8.08788 5.77677L8.08031 5.76753C7.22107 4.71881 7.37513 3.16742 8.42385 2.30817L8.43309 2.30061L8.39946 2.25956L8.39022 2.26713C7.3415 3.12637 5.79011 2.97231 4.93087 1.92359L4.9233 1.91435L4.88225 1.94798L4.88982 1.95722C5.74907 3.00594 5.595 4.55733 4.54628 5.41657L4.53705 5.42414L4.57068 5.46519L4.57991 5.45762C5.62863 4.59837 7.18002 4.75244 8.03927 5.80116L8.04683 5.8104Z"
        fill="#5925DC"
      />
      <path
        d="M18.0488 5.46152L18.0898 5.42789L18.0823 5.41865C17.223 4.36993 17.3771 2.81854 18.4258 1.9593L18.435 1.95173L18.4014 1.91068L18.3922 1.91825C17.3435 2.7775 15.7921 2.62343 14.9328 1.57471L14.9253 1.56547L14.8842 1.5991L14.8918 1.60834C15.751 2.65706 15.597 4.20845 14.5482 5.0677L14.539 5.07526L14.5726 5.11631L14.5819 5.10874C15.6306 4.2495 17.182 4.40356 18.0412 5.45228L18.0488 5.46152Z"
        fill="#5925DC"
      />
      <path
        d="M17.6992 14.1273L17.7402 14.0937L17.7327 14.0844C16.8734 13.0357 17.0275 11.4843 18.0762 10.6251L18.0854 10.6175L18.0518 10.5765L18.0426 10.584C16.9938 11.4433 15.4425 11.2892 14.5832 10.2405L14.5756 10.2312L14.5346 10.2649L14.5422 10.2741C15.4014 11.3228 15.2473 12.8742 14.1986 13.7335L14.1894 13.741L14.223 13.7821L14.2323 13.7745C15.281 12.9153 16.8324 13.0693 17.6916 14.1181L17.6992 14.1273Z"
        fill="#5925DC"
      />
      <path
        d="M12.7618 4.49714C12.3809 4.11619 11.8094 4.11619 11.4285 4.49714L0.285711 15.6399C-0.0952371 16.0208 -0.0952371 16.5923 0.285711 16.9732L2.47617 19.1636C2.85711 19.5446 3.42854 19.5446 3.80948 19.1636L14.9523 8.02091C15.3332 7.63996 15.3332 7.06854 14.9523 6.68759L12.7618 4.49714ZM11.7142 9.73518L9.71422 7.7352L11.9999 5.44951L13.9999 7.44949L11.7142 9.73518Z"
        fill="#5925DC"
      />
    </svg>
  );
};

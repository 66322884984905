export const TutorialTableItem = ({ text, icon }) => {
  return (
    <div
      style={{
        height: "72px",
        padding: "16px",
        borderTop: "1px solid #D0D5DD",
        width: "100%",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "flex-start",
          height: "auto",
          marginBottom: "-8px",
        }}
      >
        <div style={{ width: "16px", height: "16px" }}>{icon}</div>
        <p
          style={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "20px",
            color: "#475467",
            marginTop: "3px",
          }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

import React from "react";

import { Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { styles } from "../styles";

export const TotalAmountSection = ({ amount }) => {
  return (
    <div style={styles.amountSectionContainer}>
      <Typography sx={styles.amountSectionTitle}>Monto total</Typography>
      <Typography sx={styles.amountSectionText}>
        <NumericFormat
          value={amount}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          decimalScale={2}
        />
      </Typography>
    </div>
  );
};

export const debounce = (func, delay) => {
  let timeoutId;

  return function (...args) {
    // Cancelar el último timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Iniciar un nuevo timeout
    timeoutId = setTimeout(() => {
      func.apply(this, args); // Ejecutar la función después del delay
    }, delay);
  };
};

import React, { useContext } from "react";
import { Button } from "@mui/material";
import { styles } from "../styles";
import { collectionsTableStatusFilters } from "../../../constants";
import { UserContext } from "../../../contexts";

export const CollectionStatusFilters = ({
  statusSelected,
  setStatusSelected,
  isBO = false,
  isMobile = false,
  renderDownloadButton = () => {},
}) => {
  const { approvationNeeded, currentUser } = useContext(UserContext);
  return (
    <div style={styles.container}>
      <div style={styles.rowSpaceBetween}>
        <div style={styles.row}>
          {collectionsTableStatusFilters(
            approvationNeeded,
            currentUser.role?.name
          ).map((statusFilter) => {
            return (
              <Button
                variant="text"
                key={statusFilter.label}
                sx={
                  statusSelected === statusFilter.value
                    ? styles.firstStatusOptionsSelected
                    : styles.firstStatusOptions
                }
                onClick={() => {
                  setStatusSelected(statusFilter.value);
                }}
              >
                {statusFilter.label}
              </Button>
            );
          })}
        </div>
      </div>
      <hr style={styles.separator} />
    </div>
  );
};

import React, { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import { styles } from "../styles";
import { formatLocaleDate } from "../../../utils";
import { CircleAlertIcon } from "../../../assets";
import { CollectionsContext } from "../../../contexts";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import { Tooltip } from "commons/components";

export const ExpirationDateRow = ({ cell, section, pageIndex }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { updateCollection, internalGetCollections } =
    useContext(CollectionsContext);

  const row = cell.row.original;
  const currentDate = new Date();
  const expired = currentDate >= new Date(row.expiration_date);

  const pickDate = (value) => {
    setIsLoading(true);
    setVisible(false);
    updateCollection(row.id, {
      expiration_date: value,
    })
      .then((res) => {
        if (res === 200) {
          internalGetCollections(pageIndex);
          toast.success(
            `¡La fecha de vencimiento fue actualizada con éxito!`,
            toastOptions
          );
        } else {
          toast.warn(
            `Hubo un error al intentar cargar la fecha de vencimiento, por favor intente nuevamente.`,
            { ...toastOptions, autoClose: 10000 }
          );
        }
      })
      .finally(() => setIsLoading(false));
  };

  return row.expiration_date ? (
    <>
      <div style={styles.tableRow}>
        <p
          style={{
            ...styles.revert,
            color:
              expired && (row.status === "pending" || row.status === "sent")
                ? "#B42318"
                : "#667085",
          }}
        >
          {formatLocaleDate(row.expiration_date.slice(0, -1), "dd MMM, y")}
        </p>
      </div>
    </>
  ) : section === "collections" ? (
    <>
      <div style={styles.expirationRow}>
        <Tooltip title="Para poder enviar el cobro, es necesario que completes la fecha límite de pago.">
          <div
            style={styles.expirationEmptyRow}
            onClick={() => setVisible(true)}
          >
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <p style={{ ...styles.revert, marginRight: "4px" }}>
                  DD/MM/YYYY
                </p>
                <CircleAlertIcon />
              </>
            )}
          </div>
        </Tooltip>
      </div>
      <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          sx={{ display: "none" }}
          showToolbar={false}
          onChange={(value) => pickDate(value)}
          slotProps={{
            textField: {
              InputProps: {
                style: { fontSize: 14 },
              },
              size: "small",
            },
            layout: {
              sx: {
                ".MuiPickersToolbar-root": {
                  display: "none",
                },
                ".MuiDialogActions-root": {
                  display: "none",
                },
              },
            },
          }}
          popperProps={{ strategy: "fixed" }}
          format="dd/MM/yyyy"
          open={visible}
          onClose={() => setVisible(false)}
        />
      </LocalizationProvider>
    </>
  ) : null;
};

import { Box, Stack, Typography } from "@mui/material";
import { EmptyStateSearchIcon } from "assets";
import { ServicesCreateButtons } from "./ServicesCreateButtons";
const styles = {
  box: {
    backgroundColor: "#F9FAFB",
    minHeight: "400px",
    display: "flex",
    justifyContent: "center",
  },
  stack: {
    alignItems: "center",
    padding: "32px 0px",
    justifyContent: "center",
  },
  emptyStateTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
  },
  emptyStateSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
  },
  title: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#101828",
  },
  titleContainer: {
    justifyContent: "space-between",
    display: "flex",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "16px",
    alignItems: "center",
    height: "40px",
  },
};

export const ServicesEmptyState = ({
  openManualCreate,
  openMassiveCreate,
  currentScreenTitle,
}) => (
  <div className="layout-container">
    <div style={styles.titleContainer}>
      <Typography sx={{ ...styles.title, marginBottom: "12px" }} noWrap>
        {currentScreenTitle}
      </Typography>
    </div>
    <Box sx={styles.box}>
      <Stack spacing={2} p={4} sx={styles.stack}>
        <EmptyStateSearchIcon />
        <Box>
          <Typography sx={styles.emptyStateTitle}>
            Todavía no tienes servicios agendados
          </Typography>
          <Typography sx={styles.emptyStateSubtitle}>
            Una vez que realices un pago o agendes un servicio, te avisaremos
            los próximos vencimientos
          </Typography>
        </Box>
        <ServicesCreateButtons
          openManualCreate={openManualCreate}
          openMassiveCreate={openMassiveCreate}
        />
      </Stack>
    </Box>
  </div>
);

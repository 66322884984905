import { NumericFormat } from "react-number-format";
import { styles } from "./styles";
import "./styles.css";
import { IconButton, Link } from "@mui/material";
import { DocumentIcon, DownloadFileIcon } from "../../../assets";
import { toastOptions } from "../../../constants";
import { GenerateAutoReceipt } from "../../../services";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import { Status } from "..";

export const ItemsInPayrollTransaction = ({
  row,
  requestInProcess,
  setRequestInProcess,
}) => {
  const [pathGenerated, setPathGenerated] = useState(null);

  const onClickGenerate = async () => {
    setRequestInProcess(true);
    try {
      await GenerateAutoReceipt({ receipt_id: row.receipt_id }).then((path) => {
        setPathGenerated(path);
      });
      toast.success("¡Soporte generado con éxito!", toastOptions);
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    } finally {
      setRequestInProcess(false);
    }
  };

  return (
    <>
      <div style={styles.trxRow}>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={styles.textWithoutLimit}
              className="card-subtitle mb-2 fullname"
            >
              {row.name} {row.lastname}
            </p>
            <p style={styles.rowTextLeft} className="card-subtitle mb-2">
              <NumericFormat
                value={parseInt(row.amount)}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                decimalScale={2}
              />
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {row.status === "paid" && row.receipt_id ? (
              row.receipt_file_path || pathGenerated ? (
                <Link
                  href={row.receipt_file_path || pathGenerated}
                  target="_blank"
                  sx={{ marginBottom: "6px" }}
                >
                  <IconButton>
                    <DownloadFileIcon size={20} stroke={"#98A2B3"} />
                  </IconButton>
                </Link>
              ) : requestInProcess ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <IconButton onClick={onClickGenerate}>
                  <DocumentIcon width={28} height={28} stroke={"#98A2B3"} />
                </IconButton>
              )
            ) : null}
            <Status
              className="transaction-details-status-pill"
              status={row.status}
            />
          </div>
        </>
      </div>
      <hr style={styles.separator} />
    </>
  );
};

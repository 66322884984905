import React from "react";

export const SyncIcon = ({ color = "#5925DC" }) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      d="M17.5442 10.7442C17.3127 12.9191 16.0804 14.9572 14.0412 16.1345C10.6533 18.0905 6.3212 16.9298 4.36519 13.5419L4.15686 13.181M3.45515 9.25584C3.68665 7.08092 4.91898 5.04282 6.95813 3.86551C10.346 1.90951 14.6781 3.07029 16.6341 6.45819L16.8425 6.81904M3.41101 15.055L4.02105 12.7783L6.29776 13.3884M14.702 6.61167L16.9787 7.22172L17.5888 4.94501"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

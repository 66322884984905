import React from "react";
export const ModalInfoIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
    <rect
      x="1.52422"
      y="1.325"
      width="60.95"
      height="60.95"
      rx="30.475"
      fill="#EBE9FE"
    />
    <rect
      x="1.52422"
      y="1.325"
      width="60.95"
      height="60.95"
      rx="30.475"
      stroke="#F4F3FF"
      strokeWidth="2.65"
    />
    <path
      d="M32 26.5008V31.8008M32 37.1008H32.0133M45.25 31.8008C45.25 39.1186 39.3178 45.0508 32 45.0508C24.6822 45.0508 18.75 39.1186 18.75 31.8008C18.75 24.483 24.6822 18.5508 32 18.5508C39.3178 18.5508 45.25 24.483 45.25 31.8008Z"
      stroke="#6938EF"
      strokeWidth="2.65"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

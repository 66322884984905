export const filterOptions = (options, state) => {
  const filteredOptions = options.filter((option) => {
    return (
      option?.name?.toLowerCase()?.includes(state?.inputValue?.toLowerCase()) ||
      option?.alias
        ?.toLowerCase()
        ?.includes(state?.inputValue?.toLowerCase()) ||
      option?.document_number?.includes(state?.inputValue)
    );
  });
  return filteredOptions;
};

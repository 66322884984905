import React from "react";
import { styles } from "../styles";
import { Select, Stack } from "@mui/material";
import { ValidationMessage } from "commons/components";

export function ExpensesInputSelect({
  name,
  label,
  value,
  onChange,
  options,
  children,
  errors,
  register,
  renderValue = null,
  ...rest
}) {
  return (
    <Stack gap={"6px"}>
      <label htmlFor={name} style={styles.inputLabel}>
        {label}
      </label>
      <Select
        name={name}
        size="small"
        variant="outlined"
        displayEmpty={true}
        style={{ fontSize: "14px" }}
        value={value}
        renderValue={renderValue}
        {...rest}
        inputProps={{
          id: name,
        }}
      >
        {children}
      </Select>
      <ValidationMessage errors={errors} field={name} />
    </Stack>
  );
}

import { requestContainer } from "./requestContainer";

const getSourceOfFunds = (companyId) =>
  requestContainer({
    url: `/company/${companyId}/source-of-funds`,
    method: "get",
  });
const saveSourceOfFund = (formValues, companyId, selected) =>
  requestContainer({
    url: `/company/${companyId}/source-of-funds`,
    method: "post",
    data: { ...formValues, type: selected },
  });
const updateTransactionSourceOfFund = (transactionId, selectedId) =>
  requestContainer({
    url: `/transactions/${transactionId}`,
    method: "put",
    data: {
      source_of_funds_id: selectedId,
    },
  });

export { getSourceOfFunds, saveSourceOfFund, updateTransactionSourceOfFund };

import React from "react";

export const ArrowRightWithoutStick = ({
  width = 24,
  height = 24,
  stroke = "#1D2939",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M9 18L15 12L9 6"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const wompiCardKey =
  process.env.REACT_APP_WOMPI_CARD_KEY ||
  "pub_test_sZFqs9UUdauW7d7rytS6x6YXATjMcIoH";
const wompiOthersKey =
  process.env.REACT_APP_WOMPI_OTHERS_KEY ||
  "pub_test_0ymmng7Ncn7UlPyf5Pecbx0dPUTnNjQb";
const wompiBankingCorrespondentKey =
  process.env.REACT_APP_BANK_CORRESPONDENT_KEY ||
  "pub_test_PPuWZHIEGsGBQtvBkXSaLesdZ47V9f5f";

const wompiKeysByPaymentMethod = {
  banking_correspondent: wompiBankingCorrespondentKey,
  card: wompiCardKey,
  others: wompiOthersKey,
};

export const getWompiKeyByPaymentMethod = (selectedPaymentMethod) =>
  wompiKeysByPaymentMethod[selectedPaymentMethod];

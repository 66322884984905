import { WarningIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import "./debt-list-empty-state.css";

export const DebtListEmptyState = ({ backToServiceCompanySearch }) => {
  return (
    <div className="container">
      <WarningIcon />
      <div className="title">
        No encontramos deuda pendiente para el número de referencia y el servicio solicitados.
      </div>
      <div>
        Puedes buscar la deuda de otro servicio.
      </div>
      <div className="button-container">
        <PrimaryButton
          text="Revisar información"
          action={backToServiceCompanySearch}
          width="150px"
        />
      </div>
    </div>
  );
};

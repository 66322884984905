import { Typography } from "@mui/material";
import { styles } from "../styles";

export const InProcessDisclaimer = () => {
  return (
    <div style={styles.container}>
      <div style={styles.display}>
        <Typography sx={styles.title}>Tu pago está procesándose</Typography>
        <Typography sx={styles.text}>
          Si el dinero fue enviado a cuentas de Bancolombia o Davivienda se
          acreditará en un plazo máximo de 2 horas. Si realizaste un pago a
          otros bancos, se acreditará de acuerdo a los tiempos de procesamiento
          de ACH.
        </Typography>
      </div>
    </div>
  );
};

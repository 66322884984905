import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { TabContext } from "@mui/lab";
import { Button, Modal } from "react-bootstrap";
import {
  FullValue,
  ModalTabs,
  PartialPayment,
  Withholdings,
} from "./ModalTabs";
import { styles } from "../../Table/styles";
import {
  ChargeWithholdings,
  GetInvoiceAmount,
  MakePartialPayment,
  ModifyAmountTotal,
} from "../../../services";
import { PaymentsContext } from "../../../contexts";

export const AmountModal = ({
  visible,
  handleClose,
  id,
  pageIndex,
  status,
  providerId,
  toggleSelected,
  isChecked = null,
}) => {
  const { changeAmount } = useContext(PaymentsContext);
  const [value, setValue] = useState("3");
  const [fullAmount, setFullAmount] = useState(0);
  const [partialAmount, setPartialAmount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("amount");
  const [withholdings, setWithholdings] = useState(0);
  const [newTotal, setNewTotal] = useState(fullAmount);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleConfirm = async () => {
    const response = await changeAmount(
      value,
      id,
      withholdings,
      selectedOption,
      partialAmount,
      fullAmount,
      pageIndex
    );
    isChecked && toggleSelected();
    handleClose();
  };

  useEffect(() => {
    if (!visible) {
      return;
    }
    const getInvoiceData = async () => {
      const response = await GetInvoiceAmount({ id: id });
      setFullAmount(Math.floor(response.amount_total));
      return;
    };
    getInvoiceData();
  }, [visible, id]);

  return (
    <Modal show={visible} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle">Editar monto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={styles.modalBox}>
              <ModalTabs handleChange={handleChange} />
            </Box>
            <FullValue
              value="3"
              amount={fullAmount}
              setFullAmount={setFullAmount}
            />
            <PartialPayment
              value="2"
              amount={fullAmount}
              setPartialAmount={setPartialAmount}
            />
          </TabContext>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          style={styles.modalSecondaryButton}
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          style={styles.modalPrimaryButton}
          onClick={handleConfirm}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

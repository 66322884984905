import React from "react";

export const MoneyCurrentStepIcon = ({
  stroke = "#6938EF",
  fill = "#F2F3FF",
  width = "24",
  height = "24",
}) => (
  <svg width={width} height={height} viewBox="0 0 33 32" fill="none">
    <g filter="url(#filter0_d_1454_3826)">
      <g clipPath="url(#clip0_1454_3826)">
        <rect x="4.5" y="4" width="24" height="24" rx="12" fill={fill} />
        <rect
          x="5.25"
          y="4.75"
          width="22.5"
          height="22.5"
          rx="11.25"
          fill={fill}
        />
        <rect
          x="5.25"
          y="4.75"
          width="22.5"
          height="22.5"
          rx="11.25"
          stroke={stroke}
          strokeWidth="1.5"
        />
        <circle cx="16.5" cy="16" r="4" fill={stroke} />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1454_3826"
        x="0.5"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_1454_3826"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.921569 0 0 0 0 0.913725 0 0 0 0 0.996078 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1454_3826"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1454_3826"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1454_3826">
        <rect x="4.5" y="4" width="24" height="24" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

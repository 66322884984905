import React from "react";

export const PaymentIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g>
      <rect width="32" height="32" rx="16" fill="#F4F4F5" />
      <g clipPath="url(#clip0_11335_155)">
        <path
          d="M13.6667 17.7777C13.6667 18.6368 14.3631 19.3333 15.2222 19.3333H16.6667C17.5872 19.3333 18.3333 18.5871 18.3333 17.6666C18.3333 16.7461 17.5872 15.9999 16.6667 15.9999H15.3333C14.4129 15.9999 13.6667 15.2537 13.6667 14.3333C13.6667 13.4128 14.4129 12.6666 15.3333 12.6666H16.7778C17.6369 12.6666 18.3333 13.363 18.3333 14.2221M16 11.6666V12.6666M16 19.3333V20.3333M22.6667 15.9999C22.6667 19.6818 19.6819 22.6666 16 22.6666C12.3181 22.6666 9.33334 19.6818 9.33334 15.9999C9.33334 12.318 12.3181 9.33325 16 9.33325C19.6819 9.33325 22.6667 12.318 22.6667 15.9999Z"
          stroke="#6938EF"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11335_155">
        <rect width="16" height="16" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

export const SKELETON_EMPLOYEE = {
  name: "",
  documentType: "",
  documentNumber: "",
  email: "",
  phone: "",
  bankId: "",
  bankName: "",
  accountType: "",
  accountNumber: "",
};

import { Typography } from "@mui/material";
import { ChevronRight } from "assets";
import { SUPPORT_NUMBER } from "constants";
import React from "react";
import { getWppLink, openInNewTab } from "utils";

export const ContactSupport = () => {
  const message =
    "Hola, necesito ayuda con la integración de mi cuenta de DIAN";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
        marginTop: "24px",
      }}
      onClick={() => openInNewTab(getWppLink(SUPPORT_NUMBER, message))}
    >
      <Typography sx={{ color: "#1D2939", fontSize: "13px" }}>
        ¿Problemas conectándote? Escríbenos, te ayudaremos.
      </Typography>
      <ChevronRight color="#667085" />
    </div>
  );
};

import React from 'react'

export const UsersTwoIcon = ({width="32", height="32", strokeColor="#344054"}) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={width} 
        height={height} 
        viewBox="0 0 16 16" 
        fill="none"
    >
        <path 
            d="M10.6667 2.31184C11.6545 2.80274 12.3334 3.82209 12.3334 5C12.3334 6.17791 11.6545 7.19726 10.6667 7.68816M12 11.1776C13.0077 11.6336 13.9151 12.3767 14.6667 13.3333M1.33337 13.3333C2.63103 11.6817 4.39283 10.6667 6.33337 10.6667C8.27392 10.6667 10.0357 11.6817 11.3334 13.3333M9.33337 5C9.33337 6.65685 7.99023 8 6.33337 8C4.67652 8 3.33337 6.65685 3.33337 5C3.33337 3.34315 4.67652 2 6.33337 2C7.99023 2 9.33337 3.34315 9.33337 5Z" 
            stroke={strokeColor} 
            strokeWidth="1.33333" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </svg>
)

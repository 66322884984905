import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { Spinner } from "react-bootstrap";

import {
  ArrowNarrowBottomLeftIcon,
  ArrowNarrowUpRightIcon,
  PartiallyReconciledIcon,
  PayanaLogo,
} from "../../../assets";
import { NumericFormat } from "react-number-format";
import { finishReconciliationProcess } from "services";

export const EndPeriodModal = ({
  visible,
  setIsEndPeriodModalVisible,
  setSelectedProcessId,
  setSelectedBank,
  bankMovements,
  setIsNewPeriodModalVisible,
  selectedProcess,
  setIsRefreshing,
}) => {
  const [step, setStep] = useState("initial"); // los estados posibles son initial o review
  const [unreconciledMovements, setUnreconciledMovements] =
    useState(bankMovements);

  const [finishingProcess, setFinishingProcess] = useState(false);

  const finishProcess = async () => {
    setFinishingProcess(true);
    await finishReconciliationProcess(
      selectedProcess?.reconciliationProcess?.id
    );

    setFinishingProcess(false);
  };

  const renderMovement = (movement) => {
    return (
      <div
        style={{
          width: "100%",
          border: "1px solid #EAECF0",
          padding: "16px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "2px",
          }}
        >
          {movement.is_from_payana && <PayanaLogo width="72px" height="16px" />}
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#344054",
            }}
          >
            {movement.issue_date}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#344054",
            }}
          >
            {movement.description}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {movement.amount > 0 ? (
              <ArrowNarrowBottomLeftIcon width="19px" height="24px" />
            ) : (
              <ArrowNarrowUpRightIcon width="19px" height="24px" />
            )}
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#101828",
              }}
            >
              <NumericFormat
                value={movement.amount}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                decimalScale={2}
              />
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {movement.status === "partially_reconciled" && (
            <Tooltip title="Parcialmente conciliado">
              <span>
                <PartiallyReconciledIcon />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const content = {
    initial: {
      title:
        "El balance total presenta diferencias que deben resolverse antes de cerrar el período.",
      label: "Para poder cerrar el período puedes:",
      items: [
        "• Continuar conciliando en Payana hasta que la diferencia sea 0 (cero).",
        "• Realizar los ajustes o anulaciones de documentos en Siigo, actualizar Siigo desde Payana y continuar conciliando.",
        "• O marcar los registros pendientes de conciliación como diferencias temporales.",
      ],
    },
    review: {
      title: "Estos son los movimientos bancarios pendientes de conciliación:",
      content: (
        <>
          <div
            style={{
              width: "100%",
              maxHeight: "462px",
              overflowY: "scroll",
              scrollbarGutter: "stable",
              scrollbarWidth: "thin !important",
              scrollbarColor: "#667085 #ededed !important",
              marginBottom: "8px",
            }}
          >
            {/* acá en lugar de bankMovements tenes que poner el listado de movimientos que hayan quedado pendientes de conciliación */}
            {unreconciledMovements.map((movement) => {
              return renderMovement(movement);
            })}
          </div>
          <p style={styles.title}>Diferencia total para el próximo período:</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              border: "1px solid #D92D20",
              borderRadius: "8px",
              gap: "4px",
              padding: "12px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#475467",
                alignSelf: "flex-start",
              }}
            >
              Diferencia
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#B42318",
                alignSelf: "flex-start",
              }}
            >
              <NumericFormat
                value={
                  parseInt(
                    selectedProcess?.reconciliationProcess?.end_balance
                  ) -
                  parseInt(
                    selectedProcess?.reconciliationProcess?.erp_end_balance
                  )
                } // acá tenes que poner el valor de la diferencia total en lugar de este número hardcodeado
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                prefix={"$"}
              />
            </Typography>
          </div>
        </>
      ),
    },
  };

  const buttons = {
    initial: (
      <div style={styles.row}>
        <SecondaryButton
          text="Corregir los errores"
          action={() => setIsEndPeriodModalVisible(false)}
          width="100%"
        />
        <PrimaryButton
          text="Agregar diferencias temporales"
          action={() => {
            const unreconciledMovements = bankMovements.filter(
              (movement) => movement.status !== "reconciled"
            );
            setUnreconciledMovements(unreconciledMovements);
            setStep("review");
          }}
          width="100%"
        />
      </div>
    ),
    review: (
      <div style={styles.row}>
        <SecondaryButton
          text={
            finishingProcess ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Cerrar período y salir"
            )
          }
          action={() => {
            // acá tenes que ejecutar la request para cerrar el período
            finishProcess();
            setIsEndPeriodModalVisible(false);
            setStep("initial");
            setIsRefreshing(true);
            setSelectedProcessId(null);
          }}
          width="100%"
        />
        <PrimaryButton
          text="Confirmar y abrir nuevo período"
          action={() => {
            // acá tenes que ejecutar la request para cerrar el período
            finishProcess();
            setIsEndPeriodModalVisible(false);
            setIsNewPeriodModalVisible(true);
          }}
          width="100%"
        />
      </div>
    ),
  };

  return (
    <Modal
      show={visible}
      onHide={() => setIsEndPeriodModalVisible(false)}
      centered
    >
      <Modal.Body>
        <Box style={styles.container}>
          <p style={styles.title}>{content[step]?.title}</p>
          {content[step]?.label && (
            <p style={styles.subtitle}>{content[step]?.label}</p>
          )}
          {content[step]?.items &&
            content[step]?.items.map((item, index) => {
              return (
                <p key={index} style={styles.subtitle}>
                  {item}
                </p>
              );
            })}
          {content[step]?.content && content[step]?.content}
          {buttons[step]}
        </Box>
      </Modal.Body>
    </Modal>
  );
};

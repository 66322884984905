import { useEffect, useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { parseISO, isAfter, subDays } from "date-fns";

function isWithin7Days(dateString) {
  const date = parseISO(dateString);
  const currentDate = new Date();
  const sevenDaysAgo = subDays(currentDate, 7);
  return isAfter(date, sevenDaysAgo);
}

const useUserGuiding = () => {
  const { currentCompany } = useContext(UserContext);
  const condition = isWithin7Days(currentCompany?.created_at);
  useEffect(() => {
    if (condition) {
      (function (g, u, i, d, e, s) {
        g[e] = g[e] || [];
        var f = u.getElementsByTagName(i)[0];
        var k = u.createElement(i);
        k.async = true;
        k.src =
          "https://static.userguiding.com/media/user-guiding-" +
          s +
          "-embedded.js";
        f.parentNode.insertBefore(k, f);
        if (g[d]) return;
        var ug = (g[d] = { q: [] });
        ug.c = function (n) {
          return function () {
            ug.q.push([n, arguments]);
          };
        };
        var m = [
          "previewGuide",
          "finishPreview",
          "track",
          "identify",
          "hideChecklist",
          "launchChecklist",
        ];
        for (var j = 0; j < m.length; j += 1) {
          ug[m[j]] = ug.c(m[j]);
        }
      })(
        window,
        document,
        "script",
        "userGuiding",
        "userGuidingLayer",
        "166885373ID"
      );

      return () => {
        console.log("Remove user guiding.");
      };
    }
  }, [condition]);
};

export default useUserGuiding;

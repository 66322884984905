import React from "react";

export const MoneyDotIcon = ({
  stroke = "#6938EF",
  width = "24",
  height = "24",
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
    <rect width="10" height="10" transform="translate(7.5 7)" fill="white" />
    <circle cx="12.5" cy="12" r="5" fill={stroke} />
  </svg>
);

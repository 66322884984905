import { RejectedIcon } from "assets/svg/RejectedIcon";
import React from "react";

export const AlertSupplierNotDeb = () => {
  return (
    <div
      style={{
        width: "100%",
        padding: "16px",
        gap: "12px",
        borderRadius: "8px",
        border: "1px solid #667085",
        opacity: "0px",
        background: "#00000005",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <RejectedIcon
        fill="#667085"
        stroke="#667085"
        width={24}
        height={24}
        circle={false}
      />
      <p
        style={{
          margin: "0px",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          color: "#667085",
        }}
      >
        El proveedor no presenta deuda pendiente en Siigo.
      </p>
    </div>
  );
};

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const createReconciliationsXLSX = ({ response, fileName }) => {
  const { bankMovementsExport } = response;

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const bankMovementsExportRows = XLSX.utils.json_to_sheet(bankMovementsExport);

  const wb = {
    Sheets: { Conciliación: bankMovementsExportRows },
    SheetNames: ["Conciliación"],
  };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName + fileExtension);
};

export default createReconciliationsXLSX;

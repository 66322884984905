export const handleFormattedFormValues = (formValues) => ({
  name: formValues.name,
  document_type: formValues.documentType,
  document_number: formValues.documentNumber,
  email: formValues.email,
  phone: formValues.phone,
  bank_id: formValues.bankId,
  account_type: formValues.accountType,
  account_number: formValues.accountNumber,
  account_holder_name: formValues.accountHolderName,
  account_holder_document_type: formValues.accountHolderDocumentType,
  account_holder_document_number: formValues.accountHolderDocumentNumber,
  company_id: formValues.companyId,
  has_another_bank_account_holder: !!formValues?.accountHasDifferentHolder,
});

export const handleFormattedDetailSupplier = (data) => ({
  name: data?.name || "",
  documentType: data?.document_type || "",
  documentNumber: data?.document_number || "",
  email: data?.email || "",
  phone: data?.phone_number || "",
  bankId: data?.bank_id || "",
  accountType: data?.account_type || "",
  accountNumber: data?.account_number || "",
  accountHolderName: data?.account_holder_name || "",
  accountHolderDocumentType: data?.account_holder_document_type || "",
  accountHolderDocumentNumber: data?.account_holder_document_number || "",
  companyId: data?.company_id || "",
  accountHasDifferentHolder: !!data?.has_another_bank_account_holder,
});

import React from "react";

export const BankNoteIcon = ({ height = "18px", width = "18px" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M4.99935 8.33332V11.6667M14.9993 8.33332V11.6667M1.66602 6.83332L1.66602 13.1667C1.66602 14.1001 1.66602 14.5668 1.84767 14.9233C2.00746 15.2369 2.26243 15.4919 2.57603 15.6517C2.93255 15.8333 3.39926 15.8333 4.33268 15.8333L15.666 15.8333C16.5994 15.8333 17.0661 15.8333 17.4227 15.6517C17.7363 15.4919 17.9912 15.2369 18.151 14.9233C18.3327 14.5668 18.3327 14.1001 18.3327 13.1667V6.83332C18.3327 5.8999 18.3327 5.43319 18.151 5.07667C17.9912 4.76307 17.7363 4.5081 17.4227 4.34831C17.0661 4.16666 16.5994 4.16666 15.666 4.16666L4.33268 4.16666C3.39926 4.16666 2.93255 4.16666 2.57603 4.34831C2.26243 4.5081 2.00746 4.76307 1.84767 5.07667C1.66602 5.43319 1.66602 5.8999 1.66602 6.83332ZM12.0827 9.99999C12.0827 11.1506 11.1499 12.0833 9.99935 12.0833C8.84876 12.0833 7.91602 11.1506 7.91602 9.99999C7.91602 8.8494 8.84876 7.91666 9.99935 7.91666C11.1499 7.91666 12.0827 8.8494 12.0827 9.99999Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

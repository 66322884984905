import { useContext } from "react";
import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import {
  BancolombiaPNG,
  SiigoLogo,
  ExcelIcon,
  DianIconSvg,
  ArrowIcon,
  Alegra,
} from "../../../assets";
import { PaymentsContext, UserContext } from "../../../contexts";
import { styles } from "../styles";
import { useNavigate } from "react-router-dom";
import { CheckSyncErp, SyncErp } from "../../../services";
import {
  useIsUserAllowedTo,
  handleSyncErpResponse,
  openSiigoSyncStep,
} from "../../../utils";
import { permissions } from "constants";

export const InvoicesButtonGroup = ({
  openUploadFileModal,
  openPaymentObligationModal,
  setIsDianModalVisible,
  setIsSiigoModalVisible,
  setIsLinearProgressModalVisible,
  setOpenModalAlegra,
  setInitialStep,
  setSyncBanner,
}) => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const { currentCompany, currentUserIsAdmin, canIntegrateWithDian } =
    useContext(UserContext);
  const { internalGetInvoices } = useContext(PaymentsContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const sync = () => {
    setIsLinearProgressModalVisible(true);
    SyncErp().then((res) => {
      const onSuccess = () => {
        internalGetInvoices(1);
        navigate("/payments", { replace: true });
        setSyncBanner((syncBanner) => {
          return {
            ...syncBanner,
            visible: true,
            type: "success",
            title: "Importación exitosa.",
          };
        });
      };
      handleSyncErpResponse(res, onSuccess);
      setIsLinearProgressModalVisible(false);
    });
  };

  const handleSyncErp = async () => {
    if (currentCompany?.name_cs?.toLowerCase() === "siigo") {
      await CheckSyncErp().then((res) => {
        const syncValidation = res?.syncValidation;
        syncValidation?.credentials
          ? sync()
          : currentCompany.user_cs && currentCompany.password_cs
          ? openSiigoSyncStep(4, setInitialStep, setIsSiigoModalVisible)
          : setIsSiigoModalVisible(true);
      });
    } else {
      setIsSiigoModalVisible(true);
    }
  };

  return (
    <ButtonGroup
      orientation="vertical"
      fullWidth
      size="large"
      sx={{
        backgroundColor: "#ffffff",
        boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
        borderRadius: "8px",
      }}
    >
      {canIntegrateWithDian && (
        <Button
          key="zero"
          id="empty_button_integration_dian"
          startIcon={<DianIconSvg />}
          sx={styles.emptyStateBtnInGroup}
          onClick={() => {
            setIsDianModalVisible(true);
          }}
          disabled={!isUserAllowedTo(permissions.INVOICES_CREATE)}
        >
          <Stack ml={2}>
            <Typography sx={styles.emptyStateButtonTitle}>
              Importar desde DIAN
            </Typography>
            <Typography sx={styles.emptyStateButtonSubtitle}>
              Necesitarás acceso al correo electrónico registrado.
            </Typography>
          </Stack>
        </Button>
      )}
      <Button
        key="one"
        id="empty_button_integration_siigo"
        startIcon={<SiigoLogo />}
        sx={styles.emptyStateBtnInGroup}
        onClick={() => handleSyncErp()}
        disabled={!isUserAllowedTo(permissions.INVOICES_CREATE)}
      >
        <Stack ml={2}>
          <Typography sx={styles.emptyStateButtonTitle}>
            Importar desde Siigo
          </Typography>
          <Typography sx={styles.emptyStateButtonSubtitle}>
            Estamos integrados con Siigo
          </Typography>
        </Stack>
      </Button>
      <Button
        key="two"
        id="empty_button_integration_alegra"
        startIcon={<Alegra />}
        sx={styles.emptyStateBtnInGroup}
        onClick={setOpenModalAlegra}
        disabled={!isUserAllowedTo(permissions.INVOICES_CREATE)}
      >
        <Stack ml={2}>
          <Typography sx={styles.emptyStateButtonTitle}>
            Importar desde Alegra
          </Typography>
          <Typography sx={styles.emptyStateButtonSubtitle}>
            Importamos la información de tu sistema contable
          </Typography>
        </Stack>
      </Button>
      {!isMobile && (
        <>
          <Button
            key="three"
            id="empty_button_updload_txt"
            startIcon={<BancolombiaPNG width="24" height="24" />}
            sx={styles.emptyStateBtnInGroup}
            onClick={() => openUploadFileModal("TXT")}
            disabled={!isUserAllowedTo(permissions.INVOICES_CREATE)}
          >
            <Stack ml={2}>
              <Typography sx={styles.emptyStateButtonTitle}>
                Subir pagos preparados con formato Bancolombia
              </Typography>
              <Typography sx={styles.emptyStateButtonSubtitle}>
                Archivo .TXT provisto por varios sistemas contables.
              </Typography>
            </Stack>
          </Button>
          <Button
            key="four"
            id="empty_button_updload_payments_list"
            startIcon={<ExcelIcon width="24" height="24" />}
            sx={styles.emptyStateBtnInGroup}
            onClick={() => openUploadFileModal("CSV")}
            disabled={!isUserAllowedTo(permissions.INVOICES_CREATE)}
          >
            <Stack ml={2}>
              <Typography sx={styles.emptyStateButtonTitle}>
                Subir un listado de cuentas a pagar
              </Typography>
              <Typography sx={styles.emptyStateButtonSubtitle}>
                Soportamos archivo XLS, XLSX o CSV.
              </Typography>
            </Stack>
          </Button>
        </>
      )}
      <Button
        key="five"
        id="empty_button_create_payment"
        startIcon={<ArrowIcon stroke={"#000"} circle={false} />}
        sx={styles.emptyStateBtnInGroup}
        onClick={openPaymentObligationModal}
        disabled={!isUserAllowedTo(permissions.INVOICES_CREATE)}
      >
        <Stack ml={2}>
          <Typography sx={styles.emptyStateButtonTitle}>
            Crear pago manual
          </Typography>
          <Typography sx={styles.emptyStateButtonSubtitle}>
            Ingresa únicamente el monto a pagar y los datos del proveedor.
          </Typography>
        </Stack>
      </Button>
    </ButtonGroup>
  );
};

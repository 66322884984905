import { InfoIcon, MagicWandIcon } from "assets";
import { Tooltip } from "commons/components";
import React from "react";
import { styles } from "./style-multiple-selected";

export const ButtonOpenInputs = () => {
  return (
    <div style={styles.buttonOpenInputsContainer}>
      <div style={styles.buttonOpenInputsStartIconContainer}>
        <MagicWandIcon />
        <p style={styles.buttonOpenInputsText}>
          Asigna el mismo valor a varios ítems.
        </p>
      </div>
      <Tooltip title={"Selecciona los ítems y asígnales el mismo valor"}>
        <div style={styles.buttonOpenInputsEndIconContainer}>
          <InfoIcon color="#1D2939" />
        </div>
      </Tooltip>
    </div>
  );
};

const ACCOUNT_TYPES = {
  SAVINGS_ACCOUNT: "Cuenta de ahorro",
  CHECKING_ACCOUNT: "Cuenta corriente",
};

export const handleFormattedMessageChat = (row) => {
  const titular = row?.account_holder_name
    ? row?.account_holder_name
    : row?.provider_name;

  const docTypeProvider = row?.account_holder_name
    ? row?.account_holder_document_type
    : row?.provider_document_type;

  const docProvider = row?.account_holder_name
    ? row?.account_holder_document_number
    : row?.provider_document_number;
  let userNumber = row?.user?.phone;

  const accountTypeFormatted = row.account_type
    ? ACCOUNT_TYPES[row.account_type]
    : "";

  return {
    userNumber: userNumber,
    titular,
    providerName: row?.provider_name,
    bank: row?.bank_name,
    bankId: row?.bank_id,
    docTypeProvider: docTypeProvider,
    docNumberProvider: docProvider,
    accountType: accountTypeFormatted,
    accountNumber: row?.account_number,
    companyName: row?.company_name,
    errorMessage: row?.error_message,
  };
};

const handleFormattedInvoicesID = (invoices) =>
  invoices.map((invoice) => invoice.invoice_number).join("/");

export const handleFormatedMessageToChat = ({
  provider_name,
  invoices,
  amount,
  provider_phone_number,
  company_name,
  file_path,
}) => {
  const fomattedInvoicesId = handleFormattedInvoicesID(invoices);
  const formatedAmount = amount.toLocaleString("es-CO", {
    currency: "COP",
    style: "currency",
  });

  return {
    provider_number: provider_phone_number,
    provider_name,
    company_name,
    amount: formatedAmount,
    invoices_id: fomattedInvoicesId,
    file_path,
  };
};

export const handlePayrollFormattedMessageChat = (row) => {
  const accountTypeFormatted = row.employee_account_type
    ? ACCOUNT_TYPES[row.employee_account_type]
    : "";

  return {
    toNumber: row?.user?.phone,
    companyName: row?.company_name,
    employeeName: row?.employee_name,
    bank: row?.employee_bank_name,
    employeeDocumentType: row?.employee_document_type,
    employeeDocumentNumber: row?.employee_document_number,
    accountType: accountTypeFormatted,
    accountNumber: row?.employee_account_number,
    errorMessage: row?.error_message,
  };
};

import React, { createContext, useState } from "react";

export const CausationContext = createContext();

export function CausationProvider({ children }) {
  const [statusSelected, setStatusSelected] = useState("all");
  const [providerIdSelected, setProviderIdSelected] = useState("all");
  const [pageIndex, setPageIndex] = useState(1);
  const [syncBannerForCausationScreen, setSyncBannerForCausationScreen] =
    useState({ visible: false, type: "", title: "", text: "" });

  const onStatusSelectedChanged = (status) => {
    setStatusSelected(status);
    setPageIndex(1);
  };

  const handleSearchChange = (sup) => {
    if (sup) {
      setProviderIdSelected(sup.id);
    } else {
      setProviderIdSelected("all");
    }
    setPageIndex(1);
  };

  const handleChangePageIndex = (index) => {
    setPageIndex(index);
  };

  return (
    <CausationContext.Provider
      value={{
        statusSelected,
        providerIdSelected,
        pageIndex,
        onStatusSelectedChanged,
        handleSearchChange,
        handleChangePageIndex,
        causationScreen: {
          syncBanner: {
            value: syncBannerForCausationScreen,
            setter: setSyncBannerForCausationScreen,
          },
        },
      }}
    >
      {children}
    </CausationContext.Provider>
  );
}

import React, { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
  DianIconSvg,
  SiigoLogo,
  Alegra,
  ChevronDownIcon,
  ChevronUpIcon,
} from "../../../assets";
import { styles } from "../styles";
import { PaymentsContext, UserContext } from "../../../contexts";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { DropdownButton } from "../../../commons/buttons";
import { CheckSyncErp, SyncErp } from "../../../services";
import { handleSyncErpResponse, openSiigoSyncStep } from "../../../utils";
import { Tooltip } from "commons";

export const SyncDropdown = ({
  setIsDianModalVisible,
  setIsSiigoModalVisible,
  setOpenModalAlegra,
  setInitialStep,
  disabled,
  setSyncBanner = () => {},
}) => {
  const { currentCompany, currentUserIsAdmin, canIntegrateWithDian } =
    useContext(UserContext);
  const { internalGetInvoices } = useContext(PaymentsContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const [open, setOpen] = useState(false);

  const onSuccess = () => {
    internalGetInvoices(1);
    navigate("/payments", { replace: true });
    setSyncBanner((syncBanner) => {
      return {
        ...syncBanner,
        visible: true,
        type: "success",
        title: "Importación exitosa.",
      };
    });
  };

  const sync = () => {
    setSyncBanner((syncBanner) => {
      return {
        ...syncBanner,
        visible: true,
        type: "loading",
        title: "Actualizando información de Siigo",
      };
    });
    SyncErp().then((res) => {
      handleSyncErpResponse(res, onSuccess, setSyncBanner);
    });
  };

  const handleSyncErp = async () => {
    if (currentCompany?.name_cs?.toLowerCase() === "siigo") {
      await CheckSyncErp().then((res) => {
        const syncValidation = res?.syncValidation;
        syncValidation?.credentials
          ? sync()
          : currentCompany.user_cs && currentCompany.password_cs
          ? openSiigoSyncStep(4, setInitialStep, setIsSiigoModalVisible)
          : setIsSiigoModalVisible(true);
      });
    } else {
      setIsSiigoModalVisible(true);
    }
  };

  const hasDianSync = currentCompany?.name_cs?.toLowerCase() === "dian";
  const hasSiigoSync =
    currentCompany?.name_cs?.toLowerCase() === "siigo" &&
    currentCompany.last_sync_at;
  const hasAlegraSync =
    currentCompany?.name_cs?.toLowerCase() === "alegra" &&
    currentCompany.last_sync_at;

  const erpSyncEnabled =
    currentUserIsAdmin &&
    !isMobile &&
    !hasDianSync &&
    !hasSiigoSync &&
    !hasAlegraSync;

  return (
    <Tooltip
      title={disabled ? "El rol contador no permite realizar esta acción" : ""}
    >
      <Dropdown
        onToggle={() => {
          setOpen(!open);
        }}
        align="end"
      >
        <span>
          <DropdownButton
            text="Sincronizar"
            disabled={disabled}
            style={{
              width: "172px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "8px 12px 8px 12px",
              alignItems: "center",
            }}
            open={open}
            openedIcon={<ChevronUpIcon />}
            closedIcon={<ChevronDownIcon />}
          />
        </span>
        <Dropdown.Menu style={styles.noPadding}>
          {canIntegrateWithDian &&
            !hasDianSync &&
            !hasSiigoSync &&
            !hasAlegraSync && (
              <>
                <Dropdown.Item
                  id="dropdown_button_integration_dian"
                  style={styles.syncDropdownPadding}
                  onClick={() => {
                    setIsDianModalVisible(true);
                  }}
                >
                  <div style={styles.dropdownItemContainer}>
                    <span style={styles.roundedDropdownItemIconContainer}>
                      <DianIconSvg />
                    </span>
                    <div style={styles.dropdownItemTextContainer}>
                      <p
                        style={{
                          ...styles.dropdownItemTitle,
                          fontWeight: "500",
                        }}
                      >
                        Importar desde DIAN
                      </p>
                    </div>
                  </div>
                </Dropdown.Item>
                <Divider />
              </>
            )}
          {erpSyncEnabled && (
            <>
              <Dropdown.Item
                id="dropdown_button_integration"
                style={styles.syncDropdownPadding}
                onClick={() => handleSyncErp()}
              >
                <div style={styles.dropdownItemContainer}>
                  <span style={styles.roundedDropdownItemIconContainer}>
                    <SiigoLogo />
                  </span>
                  <div style={styles.dropdownItemTextContainer}>
                    <p
                      style={{ ...styles.dropdownItemTitle, fontWeight: "500" }}
                    >
                      Importar desde Siigo
                    </p>
                  </div>
                </div>
              </Dropdown.Item>
              <Divider />
            </>
          )}
          {erpSyncEnabled && (
            <>
              <Dropdown.Item
                id="dropdown_button_integration_alegra"
                style={styles.syncDropdownPadding}
                onClick={setOpenModalAlegra}
              >
                <div style={styles.dropdownItemContainer}>
                  <span style={styles.roundedDropdownItemIconContainer}>
                    <Alegra />
                  </span>
                  <div style={styles.dropdownItemTextContainer}>
                    <p
                      style={{ ...styles.dropdownItemTitle, fontWeight: "500" }}
                    >
                      Importar desde Alegra
                    </p>
                  </div>
                </div>
              </Dropdown.Item>
              <Divider />
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Tooltip>
  );
};

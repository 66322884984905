import React, { useRef, useState } from "react";
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuList,
  Typography,
} from "@mui/material";
import "../stylesFilter.css";
import { styles } from "../styles";
import { SecondaryButton } from "commons/buttons";
import stylesCSS from "../styles.module.css";
import { CausationFilterButton } from "./CausationFilterButton";

const VALUE_TYPE = {
  caused: "done",
  notCaused: "draft",
};

const DEFAULT_VALUE = {
  label: "",
  value: "",
};

export const CausationFilterDropdown = ({
  selectFilterCausation,
  setSelectFilterCausation,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const searchRef = useRef(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    searchRef?.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (value) => {
    if (selectFilterCausation === value) {
      setSelectFilterCausation(...DEFAULT_VALUE);
    } else {
      setSelectFilterCausation(value);
    }
    handleClose();
  };

  const options = [
    { title: "Causadas", label: "Causadas", value: VALUE_TYPE.caused },
    { title: "No causadas", label: "No causadas", value: VALUE_TYPE.notCaused },
  ];

  return (
    <div style={styles.causationFilterContainer}>
      <CausationFilterButton
        open={open}
        action={handleClick}
        text={selectFilterCausation?.label || "Causación"}
      />
      <Menu
        id="causation-menu-filter"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        slotProps={{
          paper: {
            style: {
              height: 270,
              minHeight: 90,
              maxHeight: 270,
              width: 390,
              padding: 16,
            },
          },
          root: { sx: { ".MuiList-root": { padding: 0 } } },
        }}
      >
        <Typography sx={styles.typeFilterTitle}>
          Filtra por estado de causación
        </Typography>
        <Typography sx={styles.typeFilterSubtitle}>
          Elige entre cuentas por pagar causadas o no causadas
        </Typography>
        <Divider style={styles.divider} />
        <FormGroup>
          <MenuList sx={{ display: "flex", flexDirection: "column" }}>
            {options.map((option) => (
              <FilterItem
                key={option.value}
                onSelect={onSelect}
                title={option.title}
                value={option.value}
                label={option.label}
                isChecked={selectFilterCausation?.value === option.value}
              />
            ))}
          </MenuList>
        </FormGroup>
        <Divider style={styles.divider} />
        <div style={styles.causationFilterFooter}>
          <SecondaryButton
            text="Limpiar"
            action={() => {
              setSelectFilterCausation("");
              handleClose();
            }}
            width="88px"
          />
        </div>
      </Menu>
    </div>
  );
};

const FilterItem = ({ onSelect, title, value, label, isChecked }) => {
  return (
    <FormControlLabel
      control={
        <input
          id="typeCollection"
          type="checkbox"
          className={stylesCSS.input}
          defaultChecked={isChecked}
          checked={isChecked}
          onChange={(e) => onSelect({ label, value })}
        />
      }
      label={
        <label htmlFor="typeCollection" style={styles.checkboxInputLabel}>
          {title}
        </label>
      }
      labelPlacement="end"
      sx={{ marginLeft: "2px", marginBottom: "12px" }}
    />
  );
};

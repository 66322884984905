import { requestContainer } from "../requestContainer";

export function GetBOSuppliers({
  pageIndex,
  providerId,
  companyId,
  querySearch = "",
}) {
  return requestContainer({
    method: "get",
    url: `/backoffice/suppliers?page=${pageIndex}&per_page=50&provider_id=${providerId}${
      companyId ? `&company_id=${companyId}&q=${querySearch}` : ""
    }`,
  });
}

export function GetBOTotalSuppliers({ providerId, companyId }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/suppliers/total?provider_id=${providerId}${
      companyId ? `&company_id=${companyId}` : ""
    }`,
  });
}

export async function SearchBOSuppliers(query) {
  return requestContainer({
    method: "get",
    url: `/backoffice/suppliers/search?q=${query}`,
  });
}

export async function DeleteBOSupplier({ id }) {
  return requestContainer({
    method: "delete",
    url: `/backoffice/suppliers/${id}`,
  });
}

export async function GetBOSupplier({ id }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/suppliers/${id}`,
  });
}

export async function UpdateBOSupplier({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/backoffice/suppliers/${id}`,
    data: {
      ...formValues,
    },
  });
}

export async function CreateBOSupplier({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/backoffice/suppliers/store`,
    data: {
      ...formValues,
    },
  });
}

export async function CreateBOSupplierServices({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/backoffice/services`,
    data: {
      ...formValues,
    },
  });
}

import { Stack, TextField } from "@mui/material";
import { ValidationMessage } from "../../components";

export default function CardReferenceForm(props) {
  const { register, formValues, formState } = props;
  return (
    <form
      className="source-of-funds-form card-reference-form"
      name="card_reference_form"
    >
      <Stack my={2} spacing={2}>
        <Stack spacing={1}>
          <label style={{}} htmlFor="card_reference">
            Referencia
          </label>
          <TextField
            id="card_reference"
            name="card_reference"
            size="small"
            variant="outlined"
            placeholder="Ejemplo: Visa 1234"
            value={formValues.card_reference}
            {...register("card_reference", { required: true })}
          />
          <ValidationMessage errors={formState.errors} field="card_reference" />
        </Stack>
      </Stack>
    </form>
  );
}

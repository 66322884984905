import React from "react";

export const PlusIcon2 = ({
  color = "#7A5AF8",
  style = { marginRight: "4px", marginBottom: "3px" },
}) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" style={style}>
    <path
      d="M7.00008 1.16667V12.8333M1.16675 7H12.8334"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

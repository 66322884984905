import React from "react";
import { styles } from "../styles";
import { NumericFormat } from "react-number-format";
import { ExpandIcon } from "assets";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "commons";
import { Divider } from "@mui/material";

export const DocumentsSection = ({ transaction, trxRows, hasEgress }) => {
  const navigate = useNavigate();
  if (transaction.operation_type === "service") {
    return null
  }
  return (
    <div style={styles.sectionContainer} className="documentsSection">
      <p style={styles.sectionTitle}>Documentos de la transacción</p>
      {(transaction.operation_type === "invoice" ||
        transaction.operation_type === "collection") &&
        trxRows.length > 0 &&
        trxRows.map((document, index) => {
          return (
            <div key={document.id}>
              <div style={styles.sectionRow}>
                <p style={styles.title} className="card-subtitle mb-2">
                  {document.number}
                </p>
                <div style={styles.documentRow}>
                  <p
                    style={styles.transactionHash}
                    className="card-subtitle mb-2"
                  >
                    <NumericFormat
                      value={parseInt(document?.amount)}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      decimalScale={2}
                    />
                  </p>
                  <Tooltip title="Ver detalle">
                    <span
                      onClick={() => {
                        transaction.operation_type === "invoice"
                          ? navigate(`/payments/invoices/${document.id}`)
                          : navigate(`/collections/index/${document.id}`);
                      }}
                      style={{ marginBottom: "12px", cursor: "pointer" }}
                    >
                      <ExpandIcon />
                    </span>
                  </Tooltip>
                </div>
              </div>
              {index !== trxRows.length - 1 && (
                <Divider
                  sx={{
                    backgroundColor: "#EAECF0",
                    height: "1px",
                    width: "100%",
                    marginBottom: "6px",
                  }}
                />
              )}
            </div>
          );
        })}
      {hasEgress && (
        <EgressRow receiptNumber={transaction.accounting_receipt_numbers} />
      )}
    </div>
  );
};

const EgressRow = ({ receiptNumber }) => {
  return (
    <>
      <Divider
        sx={{
          backgroundColor: "#EAECF0",
          height: "1px",
          width: "100%",
          marginBottom: "6px",
        }}
      />
      <div style={styles.egressContainer}>
        <p style={styles.egressText}>Egreso en Siigo</p>
        <p style={styles.egressText}>{receiptNumber}</p>
      </div>
    </>
  );
};

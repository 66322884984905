import React from "react";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
  buildCountryData,
} from "react-international-phone";
import "react-international-phone/style.css";

export const PhoneNumberInput = ({
  phone = "+57",
  setPhone,
  backgroundColor = "white",
  disabled = false,
}) => {
  const countriesTranslation = {
    us: "Estados Unidos",
    mx: "México",
    pa: "Panamá",
  };

  const countries = defaultCountries.map((country) => {
    const parsedCountry = parseCountry(country);
    parsedCountry.name =
      countriesTranslation[parsedCountry.iso2] || parsedCountry.name;
    return buildCountryData(parsedCountry);
  });

  return (
    <PhoneInput
      defaultCountry="co"
      value={phone}
      onChange={(phone) => setPhone(phone)}
      countries={countries}
      preferredCountries={["co", "us", "pa", "mx", "ar"]}
      localization={"es"}
      disabled={disabled}
      style={{
        width: "100%",
        backgroundColor: backgroundColor,
        borderRadius: "8px",
      }}
      inputStyle={{
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
        border: "1px solid #D0D5DD",
        width: "100%",
        backgroundColor: backgroundColor,
      }}
      countrySelectorStyleProps={{
        buttonStyle: {
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          padding: "4px",
          backgroundColor: backgroundColor,
        },
        dropdownStyleProps: {
          style: {
            backgroundColor: "white",
            opacity: "1",
            zIndex: "9999",
          },
        },
      }}
    />
  );
};

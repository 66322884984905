import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  Stack,
  TextField,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import { accountTypes } from "../../../../../constants";
import { BankEntitySelector, RequiredLabel } from "../../../../components";
import { PrimaryButton, SecondaryButton } from "../../../../buttons";
import { CollectionsContext } from "../../../../../contexts";
import { ValidationMessage } from "../../../../components";
import { styles } from "../../styles";
import { objectIsEmpty } from "../../../../../utils/objectUtils";
import {
  ACCOUNT_NUMBER_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
} from "../../../../../utils";
import {
  DocumentNumberInput,
  DocumentTypeInput,
  EmailInput,
  PhoneNumberInput,
} from "../../../../inputs";
import { BannerErrorIcon } from "assets";
import DrawerFooter from "./DrawerFooter";

const ReceiverForm = ({
  detailedReceiver,
  onCancel,
  onSubmit,
  mainReceiver,
  disableFields = false,
  isRetryFlow = false,
  errorMessage = "",
  title = "",
  showDisclaimer = () => {},
}) => {
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);
  const [bankSelected, setBankSelected] = useState("");
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      defaultValues: detailedReceiver,
      mode: "onBlur",
    });

  const formValues = useWatch({ control });
  const { banks } = useContext(CollectionsContext);
  const submitForm = handleSubmit(async (formValues) => {
    const selectedBank = banks.find((bank) => bank.id === formValues.bankId);
    setCreateRequestInProcess(true);
    await onSubmit({ ...formValues, bankName: selectedBank.name });
    setCreateRequestInProcess(false);
  });

  const accountHasDifferentHolder =
    formValues.accountHasDifferentHolder || false;

  const accountHasDifferentHolderProps = register("accountHasDifferentHolder");

  useEffect(() => {
    reset(detailedReceiver);
  }, [detailedReceiver]);

  useEffect(() => {
    const bank = banks.find((bank) => bank.id === formValues.bankId) || {
      name: "",
    };
    setBankSelected(bank ? bank.name : "");
  }, [formValues.bankId]);

  const renderValueAccountTypeSelect = (value) => {
    if (!value) {
      return "Tipo de cuenta";
    }
    const valueSelected = accountTypes.find(
      (account) => account.value === value
    );
    return valueSelected?.label;
  };

  return (
    <form onSubmit={submitForm} style={{ height: "100%" }}>
      <div style={styles.centeredFormContainer}>
        <div></div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={styles.formTitle}>{title}</Typography>
          {showDisclaimer && (
            <Box style={styles.disclaimerContainer}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{ fontWeight: 400, fontSize: "12px", marginLeft: "6px" }}
                >
                  Para empezar a recaudar es necesario que cargues los datos de
                  tu cuenta bancaria.
                </Typography>
              </div>
            </Box>
          )}
          {isRetryFlow && (
            <div style={styles.errorContainer}>
              <div style={styles.errorRow}>
                <div style={styles.errorIcon}>
                  <BannerErrorIcon />
                </div>
                <div style={styles.errorColumn}>
                  <Typography sx={styles.errorSectionTitle}>
                    No pudimos realizar el pago
                  </Typography>
                  <Typography sx={styles.errorSectionText}>
                    {errorMessage}. Por favor, modifica la información para
                    reintentar el pago.
                  </Typography>
                </div>
              </div>
            </div>
          )}
          <div style={styles.centeredForm}>
            <Stack sx={styles.background} spacing={2}>
              <Stack spacing={1}>
                <RequiredLabel
                  label="Nombre del destinatario"
                  sx={styles.inputLabel}
                />
                <TextField
                  id="name"
                  size="small"
                  variant="outlined"
                  placeholder="Nombre del destinatario"
                  {...register("name", { required: true })}
                  value={formValues.name}
                  InputProps={{
                    style: {
                      fontSize: 14,
                      backgroundColor:
                        objectIsEmpty(mainReceiver) || disableFields
                          ? "#F2F4F7"
                          : "white",
                    },
                  }}
                  disabled={objectIsEmpty(mainReceiver) || disableFields}
                />
                <ValidationMessage errors={formState.errors} field="name" />
              </Stack>
              <Stack spacing={1}>
                <DocumentTypeInput
                  value={formValues.documentType}
                  setValue={(value) => {
                    setValue("documentType", value, { shouldValidate: false });
                  }}
                  setDocumentNumberValue={(value) => {
                    setValue("documentNumber", value, {
                      shouldValidate: false,
                    });
                  }}
                  register={register}
                  errors={formState.errors}
                  backgroundColor={
                    !formValues.phone_number
                      ? "#FFFFFF"
                      : objectIsEmpty(mainReceiver) || disableFields
                      ? "#F2F4F7"
                      : "#FFFFFF"
                  }
                  disabled={objectIsEmpty(mainReceiver) || disableFields}
                  documentNumberValue={formValues.documentNumber}
                />
              </Stack>
              <Stack spacing={1}>
                <DocumentNumberInput
                  value={formValues.documentNumber}
                  setValue={(value) => {
                    setValue("documentNumber", value, {
                      shouldValidate: false,
                    });
                  }}
                  documentType={formValues.documentType}
                  register={register}
                  errors={formState.errors}
                  backgroundColor={
                    !formValues.phone_number
                      ? "#FFFFFF"
                      : objectIsEmpty(mainReceiver) || disableFields
                      ? "#F2F4F7"
                      : "#FFFFFF"
                  }
                  disabled={objectIsEmpty(mainReceiver) || disableFields}
                />
              </Stack>
              <Stack spacing={1}>
                <RequiredLabel
                  label="Correo electrónico para envío de comprobantes"
                  sx={styles.inputLabel}
                />
                <EmailInput
                  value={formValues.email}
                  setValue={(value) => {
                    setValue("email", value, { shouldValidate: true });
                  }}
                  register={register}
                  disabled={objectIsEmpty(mainReceiver) || disableFields}
                  backgroundColor={
                    objectIsEmpty(mainReceiver) || disableFields
                      ? "#F2F4F7"
                      : "#FFFFFF"
                  }
                />
                <ValidationMessage errors={formState.errors} field="email" />
              </Stack>
              <Stack spacing={1}>
                <label htmlFor="phone_number" style={styles.inputLabel}>
                  Número de teléfono
                </label>
                <PhoneNumberInput
                  phone={formValues.phone_number || ""}
                  setPhone={(value) => {
                    setValue("phone_number", value, { shouldValidate: true });
                  }}
                  disabled={objectIsEmpty(mainReceiver) || disableFields}
                  backgroundColor={
                    objectIsEmpty(mainReceiver) || disableFields
                      ? "#F2F4F7"
                      : "#FFFFFF"
                  }
                  {...register("phone_number", PHONE_NUMBER_VALIDATOR())}
                />
                <ValidationMessage
                  errors={formState.errors}
                  field="phone_number"
                />
              </Stack>
            </Stack>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FCFCFD",
                borderRadius: "8px",
                padding: "24px",
                width: "100%",
              }}
            >
              <Stack spacing={1}>
                <RequiredLabel
                  label="Entidad bancaria"
                  sx={styles.inputLabel}
                />
                <BankEntitySelector
                  bankSelectedId={formValues.bankId}
                  validationProps={register("bankId", { required: true })}
                  context="collections"
                />
                <ValidationMessage errors={formState.errors} field="bankId" />
              </Stack>
              {bankSelected.toLowerCase() !== "nequi" &&
                bankSelected.toLowerCase() !== "daviplata" && (
                  <Stack spacing={1} sx={{ marginTop: 2 }}>
                    <RequiredLabel
                      label="Tipo de cuenta"
                      sx={styles.inputLabel}
                    />
                    <Select
                      size="small"
                      variant="outlined"
                      style={{ fontSize: "14px", backgroundColor: "#FFFFFF" }}
                      value={formValues.accountType}
                      displayEmpty={true}
                      renderValue={renderValueAccountTypeSelect}
                      inputProps={{
                        id: "accountType",
                      }}
                      {...register("accountType", { required: true })}
                    >
                      {accountTypes.map((type) => {
                        return (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ValidationMessage
                      errors={formState.errors}
                      field="accountType"
                    />
                  </Stack>
                )}
              <Stack spacing={1} sx={{ marginTop: 2 }}>
                <RequiredLabel
                  label={
                    bankSelected.toLowerCase() === "nequi" ||
                    bankSelected.toLowerCase() === "daviplata"
                      ? "Número de celular"
                      : "Número de cuenta"
                  }
                  sx={styles.inputLabel}
                />
                <TextField
                  id="accountNumber"
                  size="small"
                  variant="outlined"
                  placeholder={
                    bankSelected.toLowerCase() === "nequi" ||
                    bankSelected.toLowerCase() === "daviplata"
                      ? "Número de celular"
                      : "Número de cuenta"
                  }
                  value={formValues.accountNumber}
                  {...register("accountNumber", ACCOUNT_NUMBER_VALIDATOR())}
                  InputProps={{
                    style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                  }}
                />
                <ValidationMessage
                  errors={formState.errors}
                  field="accountNumber"
                />
              </Stack>
            </div>
          </div>
        </div>
        <DrawerFooter customStyle={{ width: "100%" }}>
          <Stack direction="row" sx={styles.footer}>
            <SecondaryButton text="Cancelar" action={onCancel} width="162px" />
            <PrimaryButton
              text={isRetryFlow ? "Guardar y reintentar pago" : "Guardar"}
              type="submit"
              width={isRetryFlow ? "201px" : "162px"}
              isDisable={createRequestInProcess}
            />
          </Stack>
        </DrawerFooter>
      </div>
    </form>
  );
};
export default ReceiverForm;

import { AutocompleteTable } from "commons/components";
import { toastOptions } from "constants";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getItemWarehouses } from "services";

export const AutocompleteWareHouse = ({
  isCaused,
  params,
  handleOnChangeItem,
}) => {
  const [optionsWareHouses, setOptionsWareHouses] = useState(
    params?.value ? [params.value] : []
  );
  const { row } = params;
  const getWareHouses = async (productId = null, query = "") => {
    try {
      const response = await getItemWarehouses(productId, query);
      if (!Array.isArray(response)) return;
      setOptionsWareHouses(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!row.product?.is_inventory_control) return;

    getWareHouses(row.product.id);
  }, [row]);

  return (
    <AutocompleteTable
      name="warehouse"
      disabled={isCaused}
      options={optionsWareHouses}
      getOptionLabel={(option) => option?.wareHouse}
      onChange={(event, option) => {
        event.preventDefault();
        handleOnChangeItem(params, "warehouse", option);
      }}
      onInputChange={(event) => {
        if (event?.target?.value?.length < 3) return;
        getWareHouses(row.product.id, event?.target?.value);
      }}
      value={params?.value}
      tableConfig={{
        columns: [
          {
            headerName: "Bodega",
            getText: (option) => option?.wareHouse,
            width: "200px",
          },
          {
            headerName: "Saldo",
            getText: (option) => option?.value,
            width: "50px",
          },
        ],
        rowClassName: "causation",
        getRowTooltipText: (option) => `${option?.wareHouse}`,
      }}
    />
  );
};

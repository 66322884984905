import { ContactSupport } from "commons/components";
import DianIntegrationForm from "../DianIntegrationForm";

export const StepOne = ({
  primaryAction,
  dianData,
  credentialsError = false,
  setCredentialsError = () => {},
  data = {},
}) => {
  return (
    <div>
      <p className="dian-modal-title">
        <strong>Conectarse a la DIAN</strong>
      </p>
      <p className="dian-modal-step-1-text">
        <span>
          Necesitarás acceso al correo registrado en el RUT del representante
          legal de la empresa para recibir el <strong>token de acceso</strong>{" "}
          que te enviará la DIAN.
        </span>
      </p>
      <DianIntegrationForm
        primaryAction={primaryAction}
        dianData={dianData}
        credentialsError={credentialsError}
        setCredentialsError={setCredentialsError}
        data={data}
      />
      <ContactSupport />
    </div>
  );
};

import { MenuItem, Select } from "@mui/material";
import { InfoIcon } from "assets";
import { Tooltip } from "commons/components";
import React from "react";
import { NumericFormat } from "react-number-format";
import { typePaymentOnChange } from "../../constants";
import { styles } from "./styles";

export const SelectAdvance = ({
  payment,
  handleOnChangePayment,
  index,
  paymentsAdvances,
}) => {
  const getTooltipTitle = (value) => {
    return (
      <snap>
        El valor del anticipo es{" "}
        {
          <NumericFormat
            tabIndex="0"
            value={value}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        }
      </snap>
    );
  };

  const getRenderValueSelect = (selected) => {
    if (selected === null) return "Comprobante";

    const advance = paymentsAdvances.find(
      (advance) => advance.AcDueBalanceID === selected
    );

    return (
      <div style={styles.selectRenderContainer}>
        <Tooltip title={getTooltipTitle(advance?.Value)}>
          <div style={styles.selectRenderIcon}>
            <InfoIcon color="#1D2939" />
          </div>
        </Tooltip>
        {payment.data.isFromSave ? payment?.data?.DueName : advance?.DueName}
      </div>
    );
  };

  return (
    <Select
      name="advance"
      sx={styles.selectAdvance}
      onChange={(e) =>
        handleOnChangePayment(
          index,
          e.target.value,
          typePaymentOnChange.advance
        )
      }
      value={payment?.data?.AcDueBalanceID || null}
      placeholder="Comprobante"
      renderValue={(selected) => getRenderValueSelect(selected)}
    >
      <MenuItem value={null} style={{ height: "36px" }}>
        {" "}
      </MenuItem>
      {paymentsAdvances.map((advance) => (
        <MenuItem
          key={advance.AcDueBalanceID}
          value={advance.AcDueBalanceID}
          style={{ display: advance?.isUsed ? "none" : "flex", gap: "10px" }}
        >
          {`${advance.DueName} -  `}{" "}
          <NumericFormat
            tabIndex="0"
            value={advance.Value}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

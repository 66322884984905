import { DebtListItem } from "./DebtListItem";
import "./massive-create-debts-list.css";

export const MassiveCreateDebtsList = ({
  debts,
  isChecked,
  toggleSelectedRow,
}) => {
  return (
    <div className="massive-create-debts-list-container">
      <div className="massive-create-debts-list-title">
        Encontramos la siguiente cuenta por pagar
      </div>
      {debts.map((response) =>
        response.debts.map((debt) => {
          return (
            <DebtListItem
              key={debt.debtId}
              debt={debt}
              serviceCompanyName={response.service.companyName}
              serviceCompanyLogoUrl={response.service.companyLogo}
              identifierValue={debt.identifierValue}
              isChecked={isChecked}
              toggleSelectedRow={toggleSelectedRow}
            />
          );
        })
      )}
    </div>
  );
};

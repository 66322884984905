const calcCollectionsComission = (input) => {
  const { amount, comission_percentage, collection_comission_fix_amount } =
    input;
  let comission = 0;
  if (collection_comission_fix_amount) {
    comission = collection_comission_fix_amount;
  } else {
    comission = (amount / 100) * comission_percentage;
  }

  const round_comission = Math.round(comission);

  const total_to_receive = amount - round_comission;

  const receivers = getReceivers(input?.receivers, total_to_receive);
  const receivers_total = receivers.reduce((acc, receiver) => {
    return acc + receiver.round_comission;
  }, 0);
  return {
    comission: parseFloat(comission.toFixed(2)),
    round_comission,
    total_to_receive,
    total_to_collector: total_to_receive - receivers_total,
    receivers,
  };
};

const createReceiverInput = (receiverComission, receiverComissionMethod) => {
  const comissionMethodName =
    receiverComissionMethod === "amount"
      ? "comission_fixed_amount"
      : "comission_percentage";
  return parseFloat(receiverComission)
    ? {
        [comissionMethodName]: parseFloat(receiverComission),
      }
    : null;
};
const receiversListInput = (...receivers) => {
  return receivers.reduce(
    (acc, receiver) => (receiver ? [...acc, receiver] : acc),
    []
  );
};
const getDetailsToCollect = (
  invoiceAmount,
  payanaCollectionComission,
  companyCollectionsFixedCommission,
  firstReceiverInputValue,
  secondReceiverInputValue,
  receiverComissionMethod
) => {
  const input = {
    amount: invoiceAmount,
    comission_percentage: payanaCollectionComission,
    collection_comission_fix_amount: companyCollectionsFixedCommission,
    receivers: receiversListInput(
      createReceiverInput(firstReceiverInputValue, receiverComissionMethod),
      createReceiverInput(secondReceiverInputValue, receiverComissionMethod)
    ),
  };
  return calcCollectionsComission(input);
};

export default calcCollectionsComission;
export { getDetailsToCollect };

const getPercentageOf = (amount, percentage) => (amount / 100) * percentage;

const getComission = (receiver, totalToReceive) => {
  const { comission_percentage, comission_fixed_amount } = receiver;
  const receiver_comission_percentage =
    comission_percentage > 100 ? 100 : comission_percentage;

  const fixedAmount = comission_fixed_amount;
  const percentageAmount = getPercentageOf(
    totalToReceive,
    receiver_comission_percentage
  );
  return fixedAmount ? fixedAmount : percentageAmount;
};

function getReceivers(receivers = [], totalToReceive) {
  const receiversPercentageTotal = receivers.reduce(
    (acc, receiver) => acc + (receiver.comission_percentage || 0),
    0
  );
  const moreThanOneReceiver = receivers.length > 1;
  const recipientsTakeEverything = receiversPercentageTotal === 100;

  return receivers.map((receiver, index) => {
    const comission = getComission(receiver, totalToReceive);
    const isFirstReceiver = index === 0;
    const roundComissionDown = (comission) => Math.floor(comission.toFixed(1));
    const roundComissionBasedOnDecimalRule = (comission) =>
      Math.round(comission.toFixed(1));
    const roundComission =
      !isFirstReceiver && moreThanOneReceiver && recipientsTakeEverything
        ? roundComissionDown(comission)
        : roundComissionBasedOnDecimalRule(comission);
    return {
      comission: parseFloat(comission.toFixed(2)),
      round_comission: roundComission,
    };
  });
}

export const getCollectionComissionOrDefault = (
  collection,
  defaultCollectionComission
) => {
  return Number.isFinite(collection?.comission)
    ? collection?.comission
    : defaultCollectionComission;
};

import React from "react";
import { MenuItem, Select } from "@mui/material";
import { RequiredLabel, ValidationMessage } from "../components";
import { documentTypes } from "../../constants";

export const DocumentTypeInput = ({
  label = "Tipo de identificación",
  value,
  documentNumberValue,
  setDocumentNumberValue,
  register,
  errors,
  disabled = false,
  backgroundColor = "#FFFFFF",
  id = "documentType",
}) => {
  const handleChange = () => {
    if (!value && documentNumberValue) {
      return;
    }
    setDocumentNumberValue("");
    errors.documentNumber = "";
  };

  const idTypeWordings = {
    CC: "Cédula de ciudadanía",
    CE: "Cédula de extranjería",
    TI: "Tarjeta de identidad",
    PPT: "Permiso por protección temporal",
  };

  return (
    <>
      <RequiredLabel
        label={label}
        sx={{ fontSize: "14px", cursor: "pointer", color: "#1D2939" }}
      />
      <Select
        size="small"
        variant="outlined"
        displayEmpty={true}
        renderValue={(val) =>
          val?.length
            ? Array.isArray(val)
              ? val.join(", ")
              : idTypeWordings[val] || val
            : "Tipo de identificación"
        }
        value={value}
        style={{
          fontSize: "14px",
          width: "100%",
          backgroundColor: backgroundColor,
        }}
        disabled={disabled}
        inputProps={{
          id: id,
        }}
        {...register(id, {
          required: true,
          onChange: (e) => handleChange(e.target.value),
        })}
      >
        {documentTypes.length === 0 && (
          <MenuItem key={"type"} value={""}>
            {"Tipo de identificación"}
          </MenuItem>
        )}
        {documentTypes.map((type) => {
          return (
            <MenuItem key={type} value={type}>
              {idTypeWordings[type] || type}
            </MenuItem>
          );
        })}
      </Select>
      <ValidationMessage errors={errors} field={id} />
    </>
  );
};

import { Divider } from "@mui/material";

function DrawerFooter({ children, customStyle = {} }) {
  const styles = {
    zIndex: 1302,
    paddingBottom: 12,
    background: "white",
    width: 365,
    ...customStyle,
  };
  return (
    <div style={styles}>
      <Divider sx={{ backgroundColor: "#D0D5DD" }} />
      {children}
    </div>
  );
}

export default DrawerFooter;

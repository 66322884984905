import React from "react";

export const CurrencyIcon = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M5 13.3333C5 15.1742 6.49238 16.6666 8.33333 16.6666H11.6667C13.5076 16.6666 15 15.1742 15 13.3333C15 11.4923 13.5076 9.99996 11.6667 9.99996H8.33333C6.49238 9.99996 5 8.50758 5 6.66663C5 4.82568 6.49238 3.33329 8.33333 3.33329H11.6667C13.5076 3.33329 15 4.82568 15 6.66663M10 1.66663V18.3333"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

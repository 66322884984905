import { requestContainer } from "../requestContainer";

export async function GetCollectionsInternal({
  pageIndex,
  status = "all",
  customerId = "all",
  perPage = 100,
  sorting = "",
}) {
  return requestContainer({
    method: "get",
    url: `/collections?page=${pageIndex}&status=${status}&customer_id=${customerId}&per_page=${perPage}&sort=${sorting}`,
  });
}

export async function GetCollectionsByHash({
  pageIndex,
  status = "all",
  hash = null,
  perPage = 100,
  sorting = "",
}) {
  return requestContainer({
    method: "get",
    url: `/pay?page=${pageIndex}&status=${status}&hash=${hash}&per_page=${perPage}&sort=${sorting}`,
  });
}

export async function GetTotalCollections({ status, customerId }) {
  return requestContainer({
    method: "get",
    url: `/collections/total?status=${status}&customer_id=${customerId}`,
  });
}

export async function GetTotalCollectionsByHash({ status, hash }) {
  return requestContainer({
    method: "get",
    url: `/collections/total-customer?status=${status}&hash=${hash}`,
  });
}

export async function GetCollectionsExpirationValues() {
  return requestContainer({
    method: "get",
    url: "/collections/to-pay",
  });
}

export async function GetCollection({ id }) {
  return requestContainer({
    method: "get",
    url: `/collections/${id}`,
  });
}

export async function GetCollectionByHash({ id, hash }) {
  return requestContainer({
    method: "get",
    url: `/collections/by-hash/${id}?hash=${hash}`,
  });
}

export async function MarkCollectionsAsPaid({ ids }) {
  const data = { collections: ids };
  return requestContainer({
    method: "put",
    url: `/collections/paid`,
    data: data,
  });
}

export async function DeleteCollection({ id }) {
  return requestContainer({
    method: "delete",
    url: `/collections/${id}`,
  });
}

export async function DeleteCollections(ids) {
  const data = { collections: ids };
  return requestContainer({
    method: "delete",
    url: `/collections`,
    data: data,
  });
}

export function UploadCollectionFileCSV(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/collections",
    data: formData,
  });
}

export function UploadCollectionFileCSV2(formData) {
  return requestContainer({
    method: "post",
    url: "/upload/csv/collections",
    data: formData,
  });
}

export async function CollectionModifyAmountTotal({ id, amount }) {
  const data = { id: id, amount: amount };
  return requestContainer({
    method: "put",
    url: `/collections/update-amount`,
    data: data,
  });
}

export async function CollectionsApproval(ids) {
  const data = { ids: ids, approved: true };
  return requestContainer({
    method: "put",
    url: `/collections/update-approval`,
    data: data,
  });
}

export async function CollectionMakePartialPayment({ id, amount }) {
  const data = { id: id, amount: amount };
  return requestContainer({
    method: "post",
    url: `/collections/partial-payment`,
    data: data,
  });
}

export async function CollectionChargeWithholdings({
  id,
  amount,
  retention_type,
}) {
  const data = { id: id, amount: amount, retention_type: retention_type };
  return requestContainer({
    method: "put",
    url: `/collections/update-withholding-amount`,
    data: data,
  });
}

export async function UpdateCollection({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/collections/${id}`,
    data: { ...formValues },
  });
}

export async function CreateCollection({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/collections`,
    data: { ...formValues },
  });
}

export function GetCollectionsNews() {
  return requestContainer({
    method: "get",
    url: `/collections/news`,
  });
}

export function SendNotification(collectionIds) {
  return requestContainer({
    method: "post",
    url: `/collections/sendEmailToCustomers`,
    data: { collectionIds: collectionIds },
  });
}

export function UploadCollectionsFile(fileToUpload) {
  const formdata = new FormData();
  formdata.append("file", fileToUpload, fileToUpload.name);
  return requestContainer({
    method: "post",
    url: "/upload/single/collections",
    data: formdata,
  });
}

export function UploadCollectionsFileForCompany(fileToUpload, companyId) {
  const formdata = new FormData();
  formdata.append("file", fileToUpload, fileToUpload.name);
  return requestContainer({
    method: "post",
    url: `/backoffice/upload/single/collections?company_id=${companyId}`,
    data: formdata,
  });
}

export async function CreateCollectionTransaction({
  ids,
  status,
  payment_gateway,
  payment_method,
}) {
  const data = status
    ? {
        rows: ids,
        status: status,
        payment_gateway,
        payment_method,
        type: "collection",
        source_of_funds_id: null,
      }
    : {
        rows: ids,
        payment_gateway,
        payment_method,
        type: "collection",
        source_of_funds_id: null,
      };
  return requestContainer({
    method: "post",
    url: `/transactions/collections`,
    data: data,
  });
}

export async function SyncErpCollections(startAt) {
  return requestContainer({
    method: "post",
    url: `/erp/sync/collections`,
    data: { createdStartAt: startAt },
  });
}

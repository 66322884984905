import React from "react";

export const RectangleIcon = ({
  width = "4",
  height = "48",
  color = "#D92D20",
}) => (
  <svg width={width} height={height} viewBox="0 0 4 48" fill="none">
    <rect width="4" height="48" fill={color} />
  </svg>
);

export const SUPPORT_NUMBER = "+573174724545";
export const ASSISTED_EGRESS_YOUTUBE_LINK =
  "https://youtu.be/lapJEhuGJcg?si=bN033uoW2rREIqNa";
export const TERMS_AND_CONDITIONS_LINK = "https://payana.la/terms/";
export const REFERRAL_LINK =
  "https://payana.la/referidos/?utm_source=referidos&utm_medium=topNavbar";
export const BLOCKED_MESSAGE =
  "Su empresa ha sido bloqueada. Por favor, pónganse en contacto con Soporte al +57 322 878 3738.";
export const TRANSACTIONS_FEEDBACK_LINK =
  "https://n0t3r5trjtg.typeform.com/to/oG5n2Eio";
export const BANCOLOMBIA_TUTORIAL_LINK =
  "https://taparg.notion.site/C-mo-descargar-tus-movimientos-en-CSV-desde-Bancolombia-23d72ec88e3a471cb0c0974363f4e4a3";
export const BANK_EXTRACT_TEMPLATE_LINK =
  "https://payana-prod-file-import.s3.amazonaws.com/bank-extract/carga_bancos_template.xlsx";

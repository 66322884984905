import { IconButton } from "@mui/material";
import { EditIcon } from "../../../../assets";

const DianIntegrationValues = ({ loading, dianData, editDianData }) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#F2F4F7",
        borderRadius: 6,
        paddingBottom: "16px",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 12px 0px 12px",
        }}
      >
        <span
          style={{
            color: "#667085",
            fontSize: "14px",
            alignSelf: "center",
          }}
        >
          NIT Representante Legal
        </span>
        <div
          style={{
            color: "#344054",
            display: "flex",
            alignItems: "center",
            gap: 8,
            fontSize: "14px",
          }}
        >
          <span>{dianData.documentNumber}</span>
          <IconButton disabled={loading} onClick={editDianData} title="Editar">
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 12px 0px 12px",
        }}
      >
        <span
          style={{
            color: "#667085",
            fontSize: "14px",
            alignSelf: "center",
          }}
        >
          NIT Empresa
        </span>
        <div
          style={{
            color: "#344054",
            display: "flex",
            alignItems: "center",
            gap: 8,
            fontSize: "14px",
          }}
        >
          <span>{dianData.companyDocumentNumber}</span>
          <IconButton disabled={loading} onClick={editDianData} title="Editar">
            <EditIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default DianIntegrationValues;

import { NumericFormat } from "react-number-format";

export const reconciliationsDebtColumns = (
  handleOnChangeAmountRegister,
  modifiedOnChange,
  isChecked
) => [
  {
    sortable: false,
    renderCell: ({ row }) => {
      return (
        <div className="pill-header">
          <label className="lbl-checkbox-header">
            <input
              type="checkbox"
              className="input"
              onChange={() => modifiedOnChange(row)}
              checked={isChecked(row)}
            />
          </label>
        </div>
      );
    },
    width: 38,
  },
  {
    field: "DuePrefix",
    headerName: "Documento",
    sortable: false,
    headerAlign: "center",
    renderCell: ({ row }) => {
      return row.DuePrefix + "-" + row.DueConsecutive;
    },
    width: 160,
  },
  {
    field: "amount",
    headerName: "Saldo",
    sortable: false,
    headerAlign: "center",
    renderCell: ({ row }) => {
      return (
        <div class="MuiDataGrid-cellContent" title="67" role="presentation">
          <NumericFormat
            value={row.Value}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      );
    },
    width: 126,
  },
  {
    field: "amountRegister",
    headerName: "Monto a registrar",
    sortable: false,
    headerAlign: "center",
    width: 200,
    renderCell: ({ row }) => {
      return (
        <div style={{ margin: "10px", width: "100%" }}>
          <NumericFormat
            style={{
              textAlign: "right",
              width: "100%",
              padding: "5px 12px",
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
              boxShadow: "0px 1px 2px 0px #1018280D",
              opacity: "0px",
              "&:hover": {},
            }}
            id="amountRegister"
            name="amountRegister"
            thousandSeparator="."
            decimalSeparator=","
            prefix={"$"}
            allowNegative={false}
            value={row.amountRegister}
            onChange={(e) => {
              const value = e.target.value
                .replace(/\$|\./g, "")
                .replace(",", ".");
              handleOnChangeAmountRegister(value, row.id);
            }}
          />
        </div>
      );
    },
  },
  {
    field: "DueDateDisplay",
    headerName: "Vencimiento",
    sortable: false,
    headerAlign: "center",
    width: 109,
  },
];

import React, { useContext, useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Collapse,
  Box,
} from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { accountTypes, documentTypes } from "../../../../../constants";
import { BankEntitySelector } from "../../../../components";
import { PrimaryButton, SecondaryButton } from "../../../../buttons";
import { PaymentsContext } from "../../../../../contexts";
import { ValidationMessage } from "../../../../components";
import { BackofficeCompanyContext } from "../../../../../contexts/Backoffice/company";
import { styles } from "../../styles";
import { PHONE_NUMBER_VALIDATOR } from "../../../../../utils";
import {
  DocumentNumberInput,
  DocumentTypeInput,
  EmailInput,
  PhoneNumberInput,
} from "../../../../inputs";

const SupplierForm = ({
  detailedSupplier,
  onCancel,
  onSubmit,
  supplierHasDifferentHolder,
}) => {
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);
  const [bankSelected, setBankSelected] = useState("");
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      defaultValues: detailedSupplier,
      mode: "onBlur",
    });

  const formValues = useWatch({ control });
  const { companies, getCompanies } = useContext(BackofficeCompanyContext);
  const { allBanks } = useContext(PaymentsContext);
  const submitForm = handleSubmit(async (formValues) => {
    const selectedBank = allBanks.find((bank) => bank.id === formValues.bankId);
    setCreateRequestInProcess(true);
    await onSubmit({ ...formValues, bankName: selectedBank?.name });
    setCreateRequestInProcess(false);
  });

  const accountHasDifferentHolder =
    formValues.accountHasDifferentHolder || supplierHasDifferentHolder || false;

  const accountHasDifferentHolderProps = register("accountHasDifferentHolder");

  useEffect(() => {
    reset(detailedSupplier);
  }, [detailedSupplier]);

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    const bank = allBanks.find((bank) => bank.id === formValues.bankId) || {
      name: "",
    };
    setBankSelected(bank ? bank.name : "");
  }, [formValues.bankId]);

  return (
    <form onSubmit={submitForm}>
      <Stack my={2} spacing={2}>
        <Stack spacing={1}>
          <label htmlFor="company" style={styles.inputLabel}>
            Cliente
          </label>
          <Select
            size="small"
            variant="outlined"
            value={formValues.companyId}
            style={{ fontSize: "14px" }}
            {...register("companyId", { required: true })}
          >
            {companies.map((company) => {
              return (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              );
            })}
          </Select>
          <ValidationMessage errors={formState.errors} field="companyId" />
        </Stack>
        <Stack spacing={1} id="supplier_company_name_field">
          <label htmlFor="name" style={styles.inputLabel}>
            Nombre de la empresa
          </label>
          <TextField
            id="name"
            size="small"
            variant="outlined"
            {...register("name", { required: true })}
            value={formValues.name}
            InputProps={{
              style: { fontSize: 14 },
            }}
          />
          <ValidationMessage errors={formState.errors} field="name" />
        </Stack>
        <Stack spacing={1} id="supplier_document_type_field">
          <DocumentTypeInput
            value={formValues.documentType}
            setValue={(value) => {
              setValue("documentType", value, { shouldValidate: false });
            }}
            setDocumentNumberValue={(value) => {
              setValue("documentNumber", value, { shouldValidate: false });
            }}
            register={register}
            errors={formState.errors}
            documentNumberValue={formValues.documentNumber}
          />
        </Stack>
        <Stack spacing={1} id="supplier_document_number_field">
          <DocumentNumberInput
            value={formValues.documentNumber}
            setValue={(value) => {
              setValue("documentNumber", value, { shouldValidate: false });
            }}
            documentType={formValues.documentType}
            register={register}
            errors={formState.errors}
          />
        </Stack>
        <Stack spacing={1} id="supplier_email_field">
          <label htmlFor="email" style={styles.inputLabel}>
            Correo electrónico para envío de comprobantes
          </label>
          <EmailInput
            value={formValues.email}
            setValue={(value) => {
              setValue("email", value, { shouldValidate: true });
            }}
            register={register}
            required={false}
          />
          <ValidationMessage errors={formState.errors} field="email" />
        </Stack>
        <Stack spacing={1} id="supplier_phone_field">
          <label htmlFor="phone" style={styles.inputLabel}>
            Número de teléfono
          </label>
          <PhoneNumberInput
            phone={formValues.phone}
            setPhone={(value) => {
              setValue("phone", value, { shouldValidate: true });
            }}
            {...register("phone", PHONE_NUMBER_VALIDATOR())}
          />
          <ValidationMessage errors={formState.errors} field="phone" />
        </Stack>
        <Stack id="supplier_account_has_different_holder_field">
          <Typography sx={styles.inputLabel}>Datos bancarios</Typography>
          <FormControlLabel
            control={
              <Checkbox
                id="accountHasDifferentHolder"
                my={2}
                checked={accountHasDifferentHolder}
                {...accountHasDifferentHolderProps}
                onChange={() =>
                  accountHasDifferentHolderProps.onChange({
                    target: {
                      name: "accountHasDifferentHolder",
                      value: !accountHasDifferentHolder,
                    },
                  })
                }
              />
            }
            label={
              <label
                htmlFor="accountHasDifferentHolder"
                style={styles.inputLabel}
              >
                La cuenta bancaria tiene un titular diferente
              </label>
            }
            labelPlacement="end"
          />
        </Stack>
        <Collapse in={accountHasDifferentHolder}>
          <Box>
            <Stack spacing={1} id="supplier_account_holder_name_field">
              <label htmlFor="accountHolderName" style={styles.inputLabel}>
                Nombre del titular
              </label>
              <TextField
                id="accountHolderName"
                size="small"
                variant="outlined"
                {...register("accountHolderName", {
                  required: accountHasDifferentHolder,
                })}
                value={formValues.accountHolderName || ""}
                InputProps={{
                  style: { fontSize: 14 },
                }}
              />
              <ValidationMessage
                errors={formState.errors}
                field="accountHolderName"
              />
            </Stack>
            <Stack spacing={1} id="supplier_account_holder_document_type_field">
              <label
                htmlFor="accountHolderDocumentType"
                style={styles.inputLabel}
              >
                Tipo de identificación
              </label>
              <Select
                size="small"
                variant="outlined"
                value={formValues.accountHolderDocumentType || ""}
                {...register("accountHolderDocumentType", {
                  required: accountHasDifferentHolder,
                })}
                inputProps={{
                  id: "accountHolderDocumentType",
                }}
              >
                {documentTypes.map((type) => {
                  return (
                    <MenuItem
                      key={type}
                      value={type}
                      onClick={() => {
                        setValue("accountHolderDocumentNumber", "");
                        formState.errors.accountHolderDocumentNumber = "";
                      }}
                    >
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
              <ValidationMessage
                errors={formState.errors}
                field="accountHolderDocumentType"
              />
            </Stack>
            <Stack
              spacing={1}
              id="supplier_account_holder_document_number_field"
            >
              <label
                htmlFor="accountHolderDocumentNumber"
                style={styles.inputLabel}
              >
                Número de identificación
              </label>
              <Typography sx={styles.subLabel}>
                Coloca el número sin dígito de verificación.
              </Typography>
              <DocumentNumberInput
                value={formValues.accountHolderDocumentNumber}
                setValue={(value) => {
                  setValue("accountHolderDocumentNumber", value, {
                    shouldValidate: false,
                  });
                }}
                documentType={formValues.accountHolderDocumentType}
                register={register}
                errors={formState.errors}
                id="accountHolderDocumentNumber"
                required={accountHasDifferentHolder}
              />
            </Stack>
          </Box>
        </Collapse>
        <Stack spacing={1} id="supplier_bank_id_field">
          <Typography sx={styles.inputLabel}>Entidad bancaria</Typography>
          <BankEntitySelector
            bankSelectedId={formValues.bankId}
            validationProps={register("bankId")}
          />
          <ValidationMessage errors={formState.errors} field="bankId" />
        </Stack>
        {bankSelected.toLowerCase() !== "nequi" &&
          bankSelected.toLowerCase() !== "daviplata" && (
            <Stack spacing={1} id="supplier_account_type_field">
              <label htmlFor="accountType" style={styles.inputLabel}>
                Tipo de cuenta
              </label>
              <Select
                size="small"
                variant="outlined"
                style={{ fontSize: "14px" }}
                value={formValues.accountType}
                inputProps={{
                  id: "accountType",
                }}
                {...register("accountType")}
              >
                {accountTypes.map((type) => {
                  return (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <ValidationMessage
                errors={formState.errors}
                field="accountType"
              />
            </Stack>
          )}
        <Stack spacing={1} id="supplier_account_number_field">
          <label htmlFor="accountNumber" style={styles.inputLabel}>
            {bankSelected.toLowerCase() === "nequi" ||
            bankSelected.toLowerCase() === "daviplata"
              ? "Número de celular"
              : "Número de cuenta"}
          </label>
          <TextField
            id="accountNumber"
            size="small"
            variant="outlined"
            value={formValues.accountNumber}
            {...register("accountNumber", {
              pattern: {
                value: /^[0-9]*$/,
                message: "El número de cuenta debe ser numérico",
              },
              maxLength: {
                value: 30,
                message: "No puede superar los 30 caracteres",
              },
            })}
            inputProps={{
              maxLength: 30,
            }}
            InputProps={{
              style: { fontSize: 14 },
            }}
          />
          <ValidationMessage errors={formState.errors} field="accountNumber" />
        </Stack>
        <Stack direction="row" spacing={1} pt={4} justifyContent="flex-end">
          <SecondaryButton text="Cancelar" action={onCancel} />
          <PrimaryButton
            isDisable={createRequestInProcess}
            text="Guardar"
            type="submit"
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default SupplierForm;

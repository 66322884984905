import React from "react";
import { styles } from "../styles";

export function BannerCausation() {
  return (
    <div style={styles.bannerContainer}>
      <p style={styles.bannerText}>
        <strong>Factura causada correctamente.</strong> Si deseas hacer una
        modificación deberás hacerlo directamente en Siigo.
      </p>
    </div>
  );
}

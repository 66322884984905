import React from "react";

export const ArrowTopRight = ({circle=true, stroke="#6938EF"}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {circle && <rect width="32" height="32" rx="16" fill="#EBE9FE" />}
    <path
      d="M12.6667 19.3333L19.3334 12.6667M19.3334 12.6667H12.6667M19.3334 12.6667V19.3333"
      stroke={stroke}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

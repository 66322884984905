function CardDisabledIcon() {
  return (
    <div
      style={{
        alignSelf: "flex-start",
        marginTop: "8px",
      }}
    >
      <svg
        width="46"
        height="32"
        viewBox="0 0 46 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="45" height="31" rx="3.5" fill="white" />
        <rect
          x="0.5"
          y="0.5"
          width="45"
          height="31"
          rx="3.5"
          stroke="#F2F4F7"
        />
        <path
          d="M33 14H13M22 18H17M13 12.2L13 19.8C13 20.9201 13 21.4802 13.218 21.908C13.4097 22.2843 13.7157 22.5903 14.092 22.782C14.5198 23 15.0799 23 16.2 23L29.8 23C30.9201 23 31.4802 23 31.908 22.782C32.2843 22.5903 32.5903 22.2843 32.782 21.908C33 21.4802 33 20.9201 33 19.8V12.2C33 11.0799 33 10.5198 32.782 10.092C32.5903 9.7157 32.2843 9.40974 31.908 9.21799C31.4802 9 30.9201 9 29.8 9L16.2 9C15.0799 9 14.5198 9 14.092 9.21799C13.7157 9.40973 13.4097 9.71569 13.218 10.092C13 10.5198 13 11.0799 13 12.2Z"
          stroke="#98A2B3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default CardDisabledIcon;

import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { styles } from "../Login/styles";
import { PayanaLogo } from "../../assets";
import { toast } from "react-toastify";
import { requestContainer } from "../../services/api/requestContainer";
import { toastOptions } from "../../constants";
import { useNavigate } from "react-router-dom";
import { EmailInput, ValidationMessage } from "commons";
import { useForm, useWatch } from "react-hook-form";
import { PrimaryButton } from "commons/buttons";

const makeRequest = async (email) => {
  const response = await requestContainer({
    method: "post",
    url: "/restore-password",
    data: {
      email,
    },
  });
  return response;
};

export const RestorePassword = () => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState, control, setError, setValue } =
    useForm({
      defaultValues: {
        email: "",
      },
      mode: "onBlur",
    });
  const formValues = useWatch({ control });

  const navigate = useNavigate();

  const restorePassword = async () => {
    setLoading(true);
    try {
      await makeRequest(formValues.email);
      navigate(-1, { replace: true });
      toast.success("¡Solicitud enviada!", toastOptions);
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
    setLoading(false);
  };

  return (
    <Container>
      <Stack justifyContent="center" alignItems="center" mt={9}>
        <PayanaLogo width={190} height={42} />

        <Stack
          spacing={3}
          sx={{
            width: 360,
          }}
        >
          <Box>
            <Typography sx={styles.title} mb={4} mt={4}>
              Recupera tu contraseña
            </Typography>
            <Typography
              mb={1}
              sx={{
                whiteSpace: "pre-wrap",
                fontWeight: 400,
                color: "rgba(102, 112, 133, 1)",
                lineHeight: "1.5",
                borderRadius: "0px",
                opacity: 1,
              }}
            >
              Ingresa tu email y te enviaremos instrucciones sobre cómo
              restablecerla.
            </Typography>
            <EmailInput
              value={formValues.email}
              setValue={(value) => {
                setValue("email", value, { shouldValidate: true });
              }}
              register={register}
            />
            <ValidationMessage errors={formState.errors} field="email" />
          </Box>
          <PrimaryButton
            width="100%"
            isDisable={!formValues.email}
            action={handleSubmit(restorePassword)}
            text={
              loading ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                "Enviar email"
              )
            }
          />
        </Stack>
      </Stack>
    </Container>
  );
};

import React, { useContext } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import { DianIconSvg, SiigoLogo } from "../../../assets";
import ButtonStepEmptyState from "./ButtonStepEmptyState";
import { UserContext } from "contexts";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { useNavigate } from "react-router-dom";
import { SyncBanner } from "commons";

export const EmptyState = ({
  setIsDianModalVisible,
  setIsSiigoModalVisible,
  syncCS,
  syncBanner,
  setSyncBanner,
}) => {
  const { currentCompany } = useContext(UserContext);
  const navigate = useNavigate();

  const goToInvoices = () => {
    navigate("/payments/invoices", {
      replace: true,
    });
  };

  return (
    <Box sx={styles.emptyStateBox}>
      <Stack spacing={2} p={4} sx={styles.emptyStateStack}>
        <Typography sx={{ ...styles.emptyStateTitle, marginBottom: "4px" }}>
          Ahora causar facturas es mucho más fácil
        </Typography>
        <Typography sx={styles.emptyStateSubtitle}>
          Creamos una solución para que ahorres hasta un 70% de tiempo al causar
          tus facturas con Siigo.
        </Typography>
        {syncBanner.visible && syncBanner.type !== "success" && (
          <SyncBanner
            title={syncBanner.title}
            description={syncBanner.description}
            type={syncBanner.type}
            setSyncBanner={setSyncBanner}
          />
        )}
        <Stack orientation="vertical" size="large" spacing={2}>
          <ButtonStepEmptyState
            icon={
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SiigoLogo />
              </div>
            }
            title={"Sincroniza con Siigo"}
            text={
              "Importaremos tus centros de costos, formas de pago y productos."
            }
            action={setIsSiigoModalVisible}
            syncCS={syncCS?.siigo}
            buttonStep={1}
            isCausationEnabled={currentCompany.isCausationEnabled}
          />
          <ButtonStepEmptyState
            icon={<DianIconSvg />}
            title={"Sincroniza con DIAN"}
            text={"Importaremos tus facturas."}
            action={setIsDianModalVisible}
            syncCS={syncCS?.dian}
            buttonStep={2}
            isCausationEnabled={currentCompany.isCausationEnabled}
          />
        </Stack>
        <PrimaryButton
          text="Comenzar a causar"
          width="100%"
          isDisable={!currentCompany.isCausationEnabled}
          action={() => goToInvoices()}
        />
        <SecondaryButton
          text="Cancelar"
          action={() => goToInvoices()}
          width="100%"
        />
      </Stack>
    </Box>
  );
};

import { NumericFormat } from "react-number-format";
import stylesCSS from "commons/Table/styles.module.css";
import "./debt-list-item.css";
import { DebtListInnerItem } from "./DebtListInnerItem";
import { CompanyLogo } from "./CompanyLogo";
import { Skeleton } from "@mui/material";

export const DebtListItem = ({
  loading,
  debt,
  toggleSelectedRow,
  isChecked,
  serviceCompanyName,
  serviceCompanyLogoUrl,
  identifierValue,
}) => {
  if (loading) {
    return (
      <button className="button-without-default-style">
        <div className="debt-list-item-container">
          <div className="debt-list-item-inner-container">
            <div style={{ display: "flex" }}>
              <input
                style={{ alignSelf: "center", marginRight: "8px" }}
                className={stylesCSS.input}
                type="checkbox"
                disabled
              />
              <CompanyLogo logoUrl={serviceCompanyLogoUrl} />
            </div>
            <div className="debt-list-item-inner-list">
              <Skeleton width={55} height={20} />
              <Skeleton width={115} height={20} />
              <Skeleton width={165} height={20} />
            </div>
          </div>
        </div>
      </button>
    );
  }
  return (
    <button
      className="button-without-default-style"
      onClick={() => toggleSelectedRow(debt)}
    >
      <div className="debt-list-item-container">
        <div className="debt-list-item-inner-container">
          <div style={{ display: "flex" }}>
            <input
              style={{ alignSelf: "center", marginRight: "8px" }}
              className={stylesCSS.input}
              type="checkbox"
              checked={isChecked(debt)}
              onChange={() => {}}
            />
            <CompanyLogo logoUrl={serviceCompanyLogoUrl} />
          </div>
          <div className="debt-list-item-inner-list">
            <DebtListInnerItem>{serviceCompanyName}</DebtListInnerItem>
            <DebtListInnerItem>
              <NumericFormat
                value={parseFloat(debt.amount)}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix="$"
                decimalScale={2}
              />
              {debt.expirationDate && <span> - Vence {debt.expirationDate}</span>}
            </DebtListInnerItem>
            <DebtListInnerItem>{identifierValue}</DebtListInnerItem>
          </div>
        </div>
      </div>
    </button>
  );
};

import React from "react";

export const DownloadFileIcon2 = ({
  height = "10",
  width = "16",
  stroke = "#5925DC",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66634 1.89124V5.33335C9.66634 5.80006 9.66634 6.03342 9.75717 6.21168C9.83706 6.36848 9.96455 6.49596 10.1213 6.57586C10.2996 6.66669 10.533 6.66669 10.9997 6.66669H14.4418M5.49967 12.5L7.99967 15M7.99967 15L10.4997 12.5M7.99967 15L7.99967 9.99996M9.66634 1.66663H5.33301C3.93288 1.66663 3.23281 1.66663 2.69803 1.93911C2.22763 2.17879 1.84517 2.56124 1.60549 3.03165C1.33301 3.56643 1.33301 4.26649 1.33301 5.66663V14.3333C1.33301 15.7334 1.33301 16.4335 1.60549 16.9683C1.84517 17.4387 2.22763 17.8211 2.69803 18.0608C3.23281 18.3333 3.93288 18.3333 5.33301 18.3333H10.6663C12.0665 18.3333 12.7665 18.3333 13.3013 18.0608C13.7717 17.8211 14.1542 17.4387 14.3939 16.9683C14.6663 16.4335 14.6663 15.7334 14.6663 14.3333V6.66663L9.66634 1.66663Z"
      stroke={stroke}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

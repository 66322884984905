import React from "react";

export const Overlade = ({ show, children }) => {
  return (
    <div
      style={{
        ...(show && {
          filter: "blur(4px)",
          pointerEvents: "none",
          opacity: 0.7,
        }),
      }}
    >
      {children}
    </div>
  );
};

import { formatBody } from "commons/modals/ExpandAssistedExpenses/utils";
import { toastOptions } from "constants";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetAssistedExpenses,
  GetPaymentMethods,
  PostAssistedExpenses,
} from "services/api/AssistedExpenses";

const DEFAULT_ERROR_STATE = {
  error: "entryType",
};

export const useAssistedExpenses = ({
  isAssistedEgress = true,
  movementAmount = null,
}) => {
  const navigate = useNavigate();
  const [receiptDetails, setReceiptDetails] = useState({});
  const [items, setItems] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [amountTotalSelect, setAmountTotalSelect] = useState(0);
  const [comment, setComment] = useState({
    payana: "Payana",
    transactions: "Transacción: ",
    documents: "Documentos: ",
  });

  const [modalState, setModalState] = useState({
    type: null,
    open: false,
    message: "",
  });
  const [loading, setLoading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm();
  const formValues = useWatch({ control });

  const getAssistedExpenses = async (document_number, receipt_id) => {
    try {
      const response = await GetAssistedExpenses({
        document_number,
        receipt_id,
      });
      if (response?.response?.status === 400) {
        const state =
          getErrorMessageFormat(response?.response?.data, {
            document_number,
          }) || DEFAULT_ERROR_STATE;
        return navigate(`/transactions/${receipt_id}`, {
          state,
          replace: true,
        });
      }
      setReceiptDetails({
        invoices: response.invoices,
        receipts: response.receipts,
        costCenter: response.costCenter,
        entryType: response.entryType,
        provider: response.provider,
      });
      setItems(
        response.items.map((item, index) => ({
          id: index,
          amountRegister: 0,
          ...item,
        }))
      );
      setValue("paidAt", response.receipts.paid_at);

      if (response.items.length === 0) {
        setValue("type", "Anticipo");
      } else {
        setValue("type", "Abono a deuda");
      }

      const documents = response.invoices
        .map((row) => row.invoice_number)
        .join(", ");

      setComment((prev) => ({
        ...prev,
        transactions: `Transacción: ${response.receipts.hash.toUpperCase()}`,
        documents: `Documentos: ${documents}`,
      }));
      if (response.entryType.length === 1) {
        setValue("invoiceType", response.entryType[0].ERPDocumentTypeID);
      }
      setLoading(false);
    } catch (error) {
      return navigate(`/transactions/${receipt_id}`, {
        state: DEFAULT_ERROR_STATE,
        replace: true,
      });
    }
  };

  const getErrorMessageFormat = (message, data = {}) => {
    const { document_number = "" } = data;
    const errors = {
      "Provider not found": {
        error: "providerNotFound",
        data: { documentNumber: document_number },
      },
      "EntryType not found": {
        error: "entryType",
      },
    };

    return errors[message];
  };

  const getPaymentMethods = async (value = "") => {
    try {
      const response = await GetPaymentMethods(value);
      setPaymentMethods(response);
    } catch (error) {
      toast.error(
        "No pudimos obtener tus métodos de pago, inténtalo nuevamente.",
        toastOptions
      );
    }
  };

  const isChecked = (row) => {
    return !!selectedRows.find((selectedRow) => selectedRow.id === row.id);
  };

  const toggleSelectedRow = (row) => {
    if (isChecked(row)) {
      if ((isAssistedEgress && row.amountRegister > 0) || !isAssistedEgress) {
        const updateItems = [...items];
        if (row.amountRegister) {
          updateItems[row.id].amountRegister = 0;
        }
        setItems(updateItems);
      }
      setSelectedRows((rows) => rows.filter((r) => r.id !== row.id));
    } else {
      const updateRow = updateItemAmountRegister(row);
      if (isAssistedEgress && updateRow.amountRegister <= 0) {
        return;
      }
      setSelectedRows((rows) => [...rows, updateRow]);
    }
  };

  const updateItemAmountRegister = (row) => {
    const updateItems = [...items];
    const item = items.find((item) => item.id === row.id);
    if (row.amountRegister === 0) {
      if (item && item.Value < amountAvailable) {
        updateItems[row.id].amountRegister = item.Value;
        item.amountRegister = item.Value;
      } else if (amountAvailable > 0) {
        updateItems[row.id].amountRegister = amountAvailable;
        item.amountRegister = amountAvailable;
      }
      setItems(updateItems);
    }

    return { ...item };
  };

  const handleOnChangeAmountRegister = useCallback(
    (value, id) => {
      const updateItem = [...items];
      const limitValue =
        updateItem[id].Value < Number(value)
          ? updateItem[id].Value
          : Number(value);
      updateItem[id].amountRegister = limitValue;
      setItems(updateItem);
      if (value <= 0) {
        setSelectedRows((rows) => rows.filter((r) => r.id !== id));
      } else {
        const itemSelected = selectedRows.find((item) => item.id === id);
        if (itemSelected && itemSelected.amountRegister > 0) {
          const updateItemSelected = {
            ...itemSelected,
            amountRegister: limitValue,
          };
          setSelectedRows([
            ...selectedRows.filter((item) => item.id !== id),
            updateItemSelected,
          ]);
        }
      }
    },
    [items]
  );

  const handleOnChangeInputPaymentMethod = (value) => {
    if (value?.length > 0) {
      debounceSearch(value);
    } else if (value?.length === 0) {
      debounceSearch();
    }
  };

  let timeout;

  const debounceSearch = (value = "") => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      getPaymentMethods(value);
    }, 1000);
  };

  const onSubmit = handleSubmit(async (data) => {
    const entryTypeBody = receiptDetails.entryType.find(
      (item) => item.ERPDocumentTypeID === data.invoiceType
    );
    const body = formatBody({
      receiptDetails,
      entryTypeBody,
      data,
      selectedRows,
      comment,
    });
    const receiptId = receiptDetails.receipts.id;
    try {
      setModalState({
        type: "loading",
        open: true,
        message: "",
      });
      const resp = await PostAssistedExpenses(body);
      if (resp?.response?.status === 400) {
        const state =
          getErrorMessageFormat(resp?.response?.data) || DEFAULT_ERROR_STATE;
        return navigate(`/transactions/${receiptId}`, {
          state: state,
          replace: true,
        });
      }
      return setModalState({
        type: "success",
        open: true,
        message: `El egreso se realizó con éxito. ${resp?.accounting_receipt_number}`,
      });
    } catch (error) {
      setModalState({
        type: "error",
        open: false,
        message: "",
      });

      return navigate(`/transactions/${receiptId}`, {
        state: DEFAULT_ERROR_STATE,
        replace: true,
      });
    }
  });

  const handleChangeForm = (name, value) => {
    setValue(name, value);
    setError(name, { message: "" });
    if (name === "type") {
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    const amountTotalSelect = selectedRows.reduce((acc, row) => {
      return +(acc + row?.amountRegister).toFixed(2);
    }, 0);
    setAmountTotalSelect(amountTotalSelect);
  }, [selectedRows]);

  const resultAmountAvailable = +(
    (isAssistedEgress ? receiptDetails?.receipts?.total : movementAmount) -
    amountTotalSelect
  ).toFixed(2);

  const amountAvailable = resultAmountAvailable > 0 ? resultAmountAvailable : 0;

  return {
    receiptDetails,
    paymentMethods,
    amountAvailable,
    comment,
    errors,
    formValues,
    amountTotalSelect,
    modalState,
    selectedRows,
    items,
    loading,
    register,
    getAssistedExpenses,
    getPaymentMethods,
    toggleSelectedRow,
    onSubmit,
    handleOnChangeAmountRegister,
    handleOnChangeInputPaymentMethod,
    setValue,
    isChecked,
    handleChangeForm,
    setItems,
  };
};

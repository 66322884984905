import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import {
  ArrowTopRight,
  ExcelIcon,
  BancolombiaPNG,
  RefreshOneIcon,
  UsersTwoIcon,
} from "../../../assets";
import { PayrollContext, TransactionsContext } from "../../../contexts";
import { styles } from "../styles";
import { useMediaQuery } from "react-responsive";
import { useIsUserAllowedTo } from "utils";
import { permissions } from "constants";

export const PayrollButtonGroup = ({
  closeMenu = () => {},
  openPeriodModal,
  buttonRef,
  isDropdown,
  openUploadFileModal,
}) => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const { setNewPayrollItemDrawerIsOpen, payrolls, employees } =
    useContext(PayrollContext);
  const { companyHasTrxs } = useContext(TransactionsContext);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  return (
    <ButtonGroup
      orientation="vertical"
      fullWidth
      size="large"
      sx={{
        backgroundColor: "#ffffff",
        boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
        borderRadius: "8px",
      }}
    >
      {!isMobile && (
        <>
          <Button
            key="one"
            id={
              isDropdown
                ? "dropdown_button_update_payroll_list"
                : "empty_button_update_payroll_list"
            }
            startIcon={<ExcelIcon width="24" height="24" />}
            sx={styles.btnInGroup}
            onClick={() => {
              buttonRef.current.click();
              closeMenu();
            }}
            disabled={!isUserAllowedTo(permissions.PAYROLLS_CREATE)}
          >
            <Stack ml={2}>
              <Typography sx={styles.emptyStateButtonTitle}>
                Subir un listado con la nómina a pagar
              </Typography>
              <Typography sx={styles.emptyStateButtonSubtitle}>
                Soportamos archivo XLS, XLSX o CSV.
              </Typography>
            </Stack>
          </Button>
          <Button
            key="five"
            id={
              isDropdown
                ? "dropdown_button_input_data"
                : "empty_button_input_data"
            }
            startIcon={<BancolombiaPNG width="24" height="24" />}
            sx={styles.btnInGroup}
            onClick={() => {
              closeMenu();
              openUploadFileModal();
            }}
            disabled={!isUserAllowedTo(permissions.PAYROLLS_CREATE)}
          >
            <Stack ml={2}>
              <Typography sx={styles.emptyStateButtonTitle}>
                Subir pagos preparados con formato Bancolombia
              </Typography>
              <Typography sx={styles.emptyStateButtonSubtitle}>
                Archivo .TXT provisto por varios sistemas contables.
              </Typography>
            </Stack>
          </Button>
        </>
      )}

      <Button
        key="two"
        id={
          isDropdown
            ? "dropdown_button_create_transaction"
            : "empty_button_create_transaction"
        }
        startIcon={
          (payrolls && payrolls.length) || !companyHasTrxs ? (
            <RefreshOneIcon width="20" height="20" strokeColor={"#a8a8a8"} />
          ) : (
            <RefreshOneIcon width="20" height="20" />
          )
        }
        sx={styles.btnInGroup}
        onClick={() => {
          openPeriodModal("clone");
          closeMenu();
        }}
        disabled={
          !isUserAllowedTo(permissions.PAYROLLS_CREATE) ||
          (payrolls && payrolls.length) ||
          !companyHasTrxs
        }
      >
        <Stack ml={2}>
          <Typography sx={styles.emptyStateButtonTitle}>
            Crear a partir de la última transferencia realizada
          </Typography>
          <Typography sx={styles.emptyStateButtonSubtitle}>
            Revisa la información y confirma el pago.
          </Typography>
        </Stack>
      </Button>

      <Button
        key="three"
        id={
          isDropdown
            ? "dropdown_button_create_with_employees"
            : "empty_button_create_with_employees"
        }
        startIcon={
          (payrolls && payrolls.length) || !employees ? (
            <UsersTwoIcon width={20} height={20} strokeColor={"#a8a8a8"} />
          ) : (
            <UsersTwoIcon width={20} height={20} />
          )
        }
        sx={styles.btnInGroup}
        onClick={() => {
          openPeriodModal("byEmployees");
          closeMenu();
        }}
        disabled={
          !isUserAllowedTo(permissions.PAYROLLS_CREATE) ||
          (payrolls && payrolls.length) ||
          !employees
        }
      >
        <Stack ml={2}>
          <Typography sx={styles.emptyStateButtonTitle}>
            Crear utilizando todos los empleados agendados
          </Typography>
          <Typography sx={styles.emptyStateButtonSubtitle}>
            Realiza el pago de la nómina completa.
          </Typography>
        </Stack>
      </Button>
      <Button
        key="four"
        id={
          isDropdown ? "dropdown_button_input_data" : "empty_button_input_data"
        }
        startIcon={<ArrowTopRight circle={false} stroke="#344054" />}
        sx={styles.btnInGroup}
        onClick={() => {
          closeMenu();
          setNewPayrollItemDrawerIsOpen(true);
        }}
        disabled={!isUserAllowedTo(permissions.PAYROLLS_CREATE)}
      >
        <Stack ml={2}>
          <Typography sx={styles.emptyStateButtonTitle}>
            Ingresa los datos manualmente
          </Typography>
          <Typography sx={styles.emptyStateButtonSubtitle}>
            Ingresa los datos del empleado y el monto a pagar.
          </Typography>
        </Stack>
      </Button>
    </ButtonGroup>
  );
};

export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px",
  },
  modalSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
    marginRight: "12px",
  },
  modalPrimaryButton: {
    backgroundColor: "#6938EF",
    border: "1px solid #6938EF",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
  },
  secondaryButton: {
    color: "#5925DC",
  },
  contentSecondaryText: {
    margin: "revert",
    color: "#475467",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
  },
  modalTitle: {
    color: "#039855",
    fontSize: "18px",
    fontWeight: "600",
    marginTop: "12px",
  },
  modalBlockedTitle: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    marginTop: "12px",
    textAlign: "left",
    alignSelf: "flex-start",
  },
  modalAmount: {
    color: "#101828",
    fontSize: "32px",
    fontWeight: "600",
    marginTop: "-12px",
  },
  reference: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    backgroundColor: "#F2F4F7",
    padding: "4px 12px 4px 12px",
    borderRadius: "16px",
  },
  modalText: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    alignSelf: "center",
    maxWidth: "330px",
  },
  modalFooterText: {
    color: "#344054",
    fontSize: "12px",
    fontWeight: "400",
    textAlign: "center",
    alignSelf: "center",
    maxWidth: "330px",
    fontStyle: "italic",
  },
  modalDeclinedText: {
    paddingLeft: "24px",
    paddingRight: "24px",
    color: "#3B3B3B",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
  },
  modalBlockedText: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "left",
    alignSelf: "flex-start",
  },
  modalContainer: {
    backgroundColor: "#EAECF0",
    padding: "2px 12px 2px 10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "30px",
    gap: "8px",
    height: "32px",
  },
  modalBank: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "600",
    textAlign: "center",
    alignSelf: "center",
  },
  modalNewTagContainer: {
    backgroundColor: "#D1FADF",
    borderRadius: "33px",
    padding: "0px 6px 0px 6px",
    height: "20px",
    display: "flex",
  },
  modalNewTag: {
    color: "#054F31",
    fontSize: "10px",
    fontWeight: "700",
    textAlign: "center",
    alignSelf: "center",
  },
  modalBankRow: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    marginBottom: "12px",
  },
  modalBlockedContainer: {
    maxWidth: "416px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

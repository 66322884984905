import React from "react";
import { styles } from "../styles";
import { formatLocaleDate } from "../../../utils";

export const TransactionsDateRow = ({ cell }) => {
  const date = cell.value;
  const formattedDate = formatLocaleDate(date, "dd/MM/yyyy");
  const formattedTime = formatLocaleDate(date, "h:mm a");

  return date ? (
    <div style={styles.revertRow}>
      <p style={styles.customRowDate}>{formattedDate}</p>
      <p style={styles.customRowTime}>{formattedTime}</p>
    </div>
  ) : null;
};

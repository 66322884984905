import { Stack } from "@mui/material";
import { PrimaryButton } from "../../../buttons";
import { CloseButton } from "../../../modals/CloseButton";
import { CheckedIcon } from "../../../../assets/svg/CheckedIcon";
import { UncheckedIcon } from "../../../../assets/svg/UncheckedIcon";
import useRetentionsSelection from "./useRetentionsSelection";
import { RETE_ICA, RETE_FUENTE } from "constants";

function RetentionSelector({ action, close }) {
  const { selectedRetentions, selectRetention } = useRetentionsSelection();
  return (
    <>
      <CloseButton onClick={close} />
      <div>
        <p className="dian-modal-title">
          <strong>Retención a comisión Payana</strong>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <div>
            <span style={{ color: "#667085" }}>
              Selecciona las retenciones que quieres deducir de las comisiones
              de Payana.
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 6,
            }}
          >
            <Retentions
              selectRetention={selectRetention}
              selectedRetentions={selectedRetentions}
            />
          </div>
          <div>
            <span style={{ color: "#667085" }}>
              *Rete ICA aplica solo a empresas que estén radicadas en Bogotá
            </span>
          </div>
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ width: "100%" }}
          >
            <PrimaryButton
              width="100%"
              text="Confirmar"
              isDisable={selectedRetentions.length === 0}
              loading={false}
              action={() => action(selectedRetentions)}
            />
          </Stack>
        </div>
      </div>
    </>
  );
}
const wordingsRetentions = {
  retefuente: "Rete Fuente",
  reteica: "Rete ICA*",
  none: "Ninguna",
};
function RetentionOption({
  name,
  keyname,
  value,
  onClick,
  isSelected,
  setSaveSettingsButtonIsDisabled = () => {},
}) {
  return (
    <button
      style={{
        border: "1px solid #7F56D9",
        padding: "12px",
        borderRadius: 8,
        display: "flex",
        cursor: "pointer",
        flexGrow: 1,
        justifyContent: "space-between",
        width: "512px",
      }}
      className={`rete-option-button ${isSelected ? "selected" : ""}`}
      type="button"
      onClick={() => {
        onClick(keyname);
        setSaveSettingsButtonIsDisabled(false);
      }}
    >
      <div>
        <div
          style={{
            color: "#53389E",
            fontSize: "14px",
            minWidth: 85,
            fontWeight: 500,
            lineHeight: "20px",
            textAlign: "left",
          }}
        >
          {wordingsRetentions[keyname] || keyname}
        </div>
        <div style={{ textAlign: "left", color: "#667085" }}>{value}</div>
      </div>
      <div
        style={{
          display: "flex",
          paddingTop: "2px",
        }}
      >
        {isSelected ? <CheckedIcon /> : <UncheckedIcon />}
      </div>
    </button>
  );
}
export function Retentions({
  selectRetention,
  selectedRetentions,
  setSaveSettingsButtonIsDisabled = () => {},
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <RetentionOption
        name="Ninguna"
        keyname="none"
        onClick={selectRetention}
        isSelected={selectedRetentions.includes("none")}
        setSaveSettingsButtonIsDisabled={setSaveSettingsButtonIsDisabled}
      />
      <RetentionOption
        name="ReteICA"
        keyname="reteica"
        value={RETE_ICA}
        onClick={selectRetention}
        isSelected={selectedRetentions.includes("reteica")}
        setSaveSettingsButtonIsDisabled={setSaveSettingsButtonIsDisabled}
      />
      <RetentionOption
        name="ReteFuente"
        keyname="retefuente"
        value={RETE_FUENTE}
        onClick={selectRetention}
        isSelected={selectedRetentions.includes("retefuente")}
        setSaveSettingsButtonIsDisabled={setSaveSettingsButtonIsDisabled}
      />
    </div>
  );
}

export default RetentionSelector;

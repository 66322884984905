import { NumericFormat } from "react-number-format";

export const reconciliationsColumns = () => {
  const columns = [
    {
      field: "DuePrefix",
      headerName: "Documento",
      accessor: "DuePrefix",
      id: "DuePrefix",
      className: "invoice_number-column",
      disableSortBy: true,
      Header: () => <div className="pill-header">Documento</div>,
      Cell: ({ value, row }) => {
        if (value) {
          return value + " " + row.original.DueConsecutive;
        }
      },
    },
    {
      field: "DueBalance",
      headerName: "Saldo",
      accessor: "DueBalance",
      id: "DueBalance",
      className: "amount_total-column",
      headerClassName: "amount-header",
      Header: () => <div className="pill-header">Saldo</div>,
      Cell: ({ value }) => {
        if (value) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                paddingRight: "32px",
              }}
            >
              <NumericFormat
                value={value}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                decimalScale={2}
              />
            </div>
          );
        }
      },
    },
    {
      field: "DueDateDisplay",
      headerName: "Vencimiento",
      accessor: "DueDateDisplay",
      id: "DueDateDisplay",
      className: "invoice_dates-column",
      Header: () => <div className="pill-header">Vencimiento</div>,
    },
  ];

  return columns;
};

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { FormBilling } from "commons";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import {
  createBillingCompany,
  getSiigoCities,
  updateBOBilling,
  createCompanyBilling,
} from "services";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { TrackJS } from "trackjs";
import { BillingBusinessType } from "./BillingBusinessType";
import { billingTypeItems } from "../utils";

export const ModalBilling = ({
  show = false,
  onHide = () => {},
  billing = null,
  companySelected = null,
  refreshSetCompanyBillings,
  isShowDetail,
  handleSetBilling,
  billings,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: billing,
  });

  const formValues = useWatch({ control });
  const [isLoading, setIsLoading] = useState(false);
  const [businessType, setBusinessType] = useState(billingTypeItems.company);

  const isCreateBilling =
    !billing?.id ||
    businessType === billingTypeItems.conglomerate ||
    businessType === billingTypeItems.accountant;

  const [isEditable, setIsEditable] = useState(isCreateBilling || isShowDetail);

  const onSubmited = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      const formatBilling = formatBillingData(data);

      let textToast = "Información de facturación actualizada";
      let respBilling;

      if (
        billing?.id &&
        (businessType === billingTypeItems.conglomerate ||
          businessType === billingTypeItems.accountant)
      ) {
        respBilling = await createCompanyBilling({
          billingId: billing?.id,
          companyId: companySelected.id,
          type: businessType,
        });
        if (!respBilling) {
          return toast.error(
            "No se pudo actualizar la información de facturación",
            toastOptions
          );
        }
        textToast = "Información de facturación creada";
      } else if (billing?.id) {
        respBilling = await updateBOBilling(formatBilling, billing.id);
        if (!respBilling) {
          return toast.error(
            "No se pudo actualizar la información de facturación",
            toastOptions
          );
        }
        textToast = "Información de facturación actualizada";
      } else {
        respBilling = await createBillingCompany(formatBilling);
        if (!respBilling) {
          return toast.error(
            "No se pudo actualizar la información de facturación",
            toastOptions
          );
        }
        textToast = "Información de facturación creada";
      }

      refreshSetCompanyBillings();
      toast.success(textToast, toastOptions);
      onHide();
    } catch (error) {
      TrackJS.console.error(error);
      toast.error(
        "No se pudo actualizar la información de facturación",
        toastOptions
      );
    } finally {
      setIsLoading(false);
      setIsEditable(false);
    }
  });

  const formatBillingData = (data) => ({
    documentNumber: data.document_number,
    documentType: data.document_type,
    phone: data.phone,
    address: data?.address || "",
    email: data.email,
    contactName: data.contact_name,
    contactSurname: data.contact_surname,
    municipality: data.city?.state_code,
    organizationType: data.organization_type,
    city: data.city?.city_code,
    companyId: companySelected.id,
    businessName: data.business_name,
    type: businessType || billingTypeItems.company,
  });

  useEffect(() => {
    if (billing?.city) {
      getSiigoCities(billing.city).then((reps) => {
        setValue("city", reps);
        setValue("department", reps?.state_name);
      });
    }
  }, [billing]);

  const [showButtonsBillingBusinessType] = useState(
    isEditable && isCreateBilling
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body
        style={{ width: "100%", maxWidth: "400px", minWidth: "568px" }}
      >
        <p
          style={{
            padding: "8px 0px",
            marginBottom: "0px",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          Datos fiscales
        </p>
        {showButtonsBillingBusinessType && (
          <BillingBusinessType
            isShowDetail={isShowDetail}
            isCreateBilling={isCreateBilling}
            businessType={businessType}
            handleChangeBusinessType={(value) => setBusinessType(value)}
            reset={reset}
            handleChangeBilling={handleSetBilling}
            handleChangeEditable={(value) => setIsEditable(value)}
            billings={billings}
          />
        )}
        <FormBilling
          errors={errors}
          formValues={formValues}
          setValue={setValue}
          register={register}
          isEditable={isEditable}
          isCreateBilling={isCreateBilling}
        />
        {(isShowDetail || isCreateBilling) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "20px",
              marginTop: "24px",
            }}
          >
            <SecondaryButton
              isDisable={isLoading}
              text="Cancelar"
              action={onHide}
              width="50%"
            />
            <PrimaryButton
              loading={isLoading}
              text={
                isEditable || showButtonsBillingBusinessType
                  ? "Guardar"
                  : "Editar"
              }
              action={
                isEditable || showButtonsBillingBusinessType
                  ? () => onSubmited()
                  : () => setIsEditable(true)
              }
              width="50%"
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export const styles = {
    row: {
      display: "flex",
      flexDirection: "row",
      marginTop: "16px",
      gap: "12px",
    },
    title: {
      color: "#101828",
      fontSize: "18px",
      fontWeight: "600",
      textAlign: "center",
    },
    text: {
      color: "#667085",
      fontSize: "16px", 
      fontWeight: "400",
      textAlign: "center",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      padding: "16px 32px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "12px",
      gap: "6px",
      width: "100%",
    },
    input: { width: "100%" },
  };
  
import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Divider } from "@mui/material";
import { styles } from "../../../Table/styles";
import { getAccountTypeLabel } from "../../../../constants";
import { ThirdButton } from "../../../buttons";
import {
  AddresseeIcon,
  CornerDownRightIcon,
  DataIcon,
  PaymentIcon,
} from "../../../../assets";
import { format } from "date-fns";
import { CollectionTrxStatus } from "./Status";
import { CollectionsContext, UserContext } from "../../../../contexts";
import PayanaComission from "./PayanaComission";
import {
  getDetailsToCollect,
  getCollectionComissionOrDefault,
} from "../../../../screens/CollectionsScreen/comission/calcCollectionsComission";
import { downloadFile } from "utils";

export const CollectionDetail = ({ collection, customer }) => {
  const userContextValues = useContext(UserContext);
  const { currentCompany } = userContextValues;
  const companyCollectionsCommission = getCollectionComissionOrDefault(
    collection,
    userContextValues.companyCollectionsCommission
  );
  const companyCollectionsFixedCommission =
    userContextValues.companyCollectionsFixedCommission;
  const { mainReceiver, getMainReceiver, banks, getBanks } =
    useContext(CollectionsContext);
  const [selectedBank, setSelectedBank] = useState("");

  useEffect(() => {
    getMainReceiver().then((res) => {
      getBanks().then((res) => {
        setSelectedBank(banks.find((bank) => bank.id === mainReceiver.bank_id));
      });
    });
  }, []);

  const { round_comission, total_to_collector } = getDetailsToCollect(
    collection.amount,
    companyCollectionsCommission,
    collection.comission && collection.comission > 2 ? collection.comission : 0,
    collection?.receivers?.at(0)?.amount,
    collection?.receivers?.at(1)?.amount,
    "amount"
  );
  const amountComission = round_comission;

  const companyAmount = () => total_to_collector || 0;

  return (
    <>
      <div style={styles.buttonsContainer}>
        {collection.receipt_file_path && (
          <ThirdButton
            text={"Descargar soporte"}
            action={() => downloadFile(collection.receipt_file_path)}
            width={235}
          />
        )}
        {collection.file_path && (
          <ThirdButton
            text={"Descargar documento"}
            action={() => downloadFile(collection.file_path)}
            width={235}
          />
        )}
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <AddresseeIcon />
          <span style={styles.cardTitle}>Cliente</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Cliente</span>
          <span style={styles.cardData}>{customer?.name?.toUpperCase()}</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>
            {customer?.document_type ? customer.document_type : "NIT"}
          </span>
          <span style={styles.cardData}>{customer?.document_number}</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Correo electrónico</span>
          <span style={styles.cardData}>{customer?.email}</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>WhatsApp</span>
          <span style={styles.cardData}>{customer?.phone}</span>
        </div>
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <DataIcon />
          <span style={styles.cardTitle}>Datos del pago</span>
        </div>
        {collection.collection_number && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Comprobante</span>
              <span style={styles.cardData}>
                {collection.collection_number}
              </span>
            </div>
          </>
        )}
        {collection.concept && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Concepto</span>
              <span style={styles.cardData}>{collection.concept}</span>
            </div>
          </>
        )}
        {collection.expiration_date && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Fecha de vencimiento</span>
              <span style={styles.cardData}>
                {format(
                  new Date(collection.expiration_date.slice(0, -1)),
                  "dd/MM/yyyy"
                )}
              </span>
            </div>
          </>
        )}
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <PaymentIcon />
          <span style={styles.cardTitle}>Información del pago</span>
        </div>
        <Divider />
        <div
          style={{
            width: "100%",
            backgroundColor: "#F9FAFB",
            padding: "24px",
          }}
        >
          <div style={styles.supplierDataCard}>
            <p
              style={styles.invoiceSupplierCardTitle}
              className="card-subtitle mb-2"
            >
              Importe de la factura
            </p>
            <p
              style={styles.collectionTotalsCardText}
              className="card-subtitle mb-2"
            >
              $
              <NumericFormat
                value={parseInt(collection.amount) || 0}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
              />
            </p>
          </div>
          <PayanaComission
            amount={parseInt(collection.amount) || 0}
            companyCollectionsCommission={companyCollectionsCommission}
            companyCollectionsFixedCommission={
              collection.comission && collection.comission > 2
                ? collection.comission
                : 0
            }
          />
          <Divider sx={{ marginBottom: "8px" }} />
          <div style={styles.supplierDataCard}>
            <p
              style={styles.collectionTotalCardTitle}
              className="card-subtitle mb-2"
            >
              Total a recibir
            </p>
            <p
              style={styles.collectionTotalCardText}
              className="card-subtitle mb-2"
            >
              $
              <NumericFormat
                value={parseInt(collection.amount - amountComission) || 0}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
              />
            </p>
          </div>
          <>
            <div style={styles.receiverDataCard}>
              <p
                style={styles.receiverCardTitle}
                className="card-subtitle mb-2"
              >
                <CornerDownRightIcon /> Destinatario {currentCompany?.name}
              </p>
              <p
                style={styles.collectionTotalsCardText}
                className="card-subtitle mb-2"
              >
                $
                <NumericFormat
                  value={parseInt(companyAmount(collection.amount))}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                />
              </p>
            </div>
            {collection.receivers.map((receiver) => {
              return (
                <div style={styles.receiverDataCard}>
                  <p
                    style={styles.receiverCardTitle}
                    className="card-subtitle mb-2"
                  >
                    <CornerDownRightIcon /> Destinatario {receiver.receiverName}
                  </p>
                  <p
                    style={styles.collectionTotalsCardText}
                    className="card-subtitle mb-2"
                  >
                    $
                    <NumericFormat
                      value={parseInt(receiver.amount)}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                    />
                  </p>
                </div>
              );
            })}
          </>
        </div>
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Estado</span>
          <span>
            <CollectionTrxStatus
              className="transaction-details-status-pill"
              status={
                collection.status === "paid_outside"
                  ? "paid_outside"
                  : collection.status === "in_process"
                  ? "dispersion"
                  : collection.transaction_status
              }
              section="collections"
            />
          </span>
        </div>
        {collection?.transaction_hash && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Código de transacción</span>
              <span style={styles.cardData}>
                {collection?.transaction_hash?.toUpperCase()}
              </span>
            </div>
          </>
        )}

        {collection?.transaction_date && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Fecha de pago</span>
              <span style={styles.cardData}>
                {collection?.transaction_date &&
                  format(
                    new Date(collection?.transaction_date?.slice(0, -1)),
                    "dd/MM/yyyy"
                  )}
              </span>
            </div>
          </>
        )}
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <AddresseeIcon />
          <span style={styles.cardTitle}>
            Dispersión {currentCompany?.name}
          </span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Entidad bancaria</span>
          <span style={styles.cardData}>{selectedBank?.name}</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>
            {mainReceiver?.account_type
              ? getAccountTypeLabel(mainReceiver?.account_type)
              : "Número de celular"}
          </span>
          <span style={styles.cardData}>{mainReceiver?.account_number}</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>
            {mainReceiver?.document_type ? mainReceiver.document_type : "NIT"}
          </span>
          <span style={styles.cardData}>{mainReceiver?.document_number}</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Monto a recibir</span>
          <span style={styles.cardData}>
            <NumericFormat
              value={companyAmount(collection.amount)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </span>
        </div>
      </div>
      {collection.receivers.map((receiver) => {
        return (
          <div style={styles.container}>
            <div style={styles.cardRow}>
              <AddresseeIcon />
              <span style={styles.cardTitle}>
                Dispersión a {receiver.receiverName}
              </span>
            </div>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Entidad bancaria</span>
              <span style={styles.cardData}>{receiver?.bankName}</span>
            </div>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>
                {receiver?.receiverAccountType
                  ? getAccountTypeLabel(receiver.receiverAccountType)
                  : "Número de celular"}
              </span>
              <span style={styles.cardData}>
                {receiver?.receiversAccountNumber}
              </span>
            </div>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>
                {receiver?.document_type ? receiver.document_type : "NIT"}
              </span>
              <span style={styles.cardData}>
                {receiver?.receiversDocumentNumber}
              </span>
            </div>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Monto a recibir</span>
              <span style={styles.cardData}>
                <NumericFormat
                  value={parseInt(receiver.amount)}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                  decimalScale={2}
                />
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export const getTotalTrialDays = (daysRemaining, freeTrialStartAt) => {
  const today = new Date();

  if (!freeTrialStartAt || isNaN(new Date(freeTrialStartAt).getTime())) {
    return daysRemaining;
  }

  const trialStartDate = new Date(freeTrialStartAt);

  const trialStartDateMidnight = new Date(trialStartDate.setHours(0, 0, 0, 0));
  const todayMidnight = new Date(today.setHours(0, 0, 0, 0));

  const elapsedDays = Math.floor(
    (todayMidnight - trialStartDateMidnight) / (1000 * 60 * 60 * 24)
  );

  const totalTrialDays = daysRemaining + elapsedDays;

  return totalTrialDays;
};

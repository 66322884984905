export const SHOW_DEBTS_LIST = "SHOW_DEBTS_LIST";
export const RESET_STATE = "RESET_STATE";
export const HANDLE_SERVICE_SEARCH_OPTION_CHANGED =
  "HANDLE_SERVICE_SEARCH_OPTION_CHANGED";
export const HANDLE_SERVICE_SEARCH_OPTIONS = "HANDLE_SERVICE_SEARCH_OPTIONS";
export const HANDLE_POPULAR_SERVICE_SEARCH_OPTIONS =
  "HANDLE_POPULAR_SERVICE_SEARCH_OPTIONS";
export const HANDLE_SERVICE_IDENTIFIER_VALUE_CHANGED =
  "HANDLE_SERVICE_IDENTIFIER_VALUE_CHANGED";
export const HANDLE_SERVICE_MODALITY_CHANGED =
  "HANDLE_SERVICE_MODALITY_CHANGED";
export const HANDLE_SERVICE_SEARCH_INPUT_TEXT_CHANGED =
  "HANDLE_SERVICE_SEARCH_INPUT_TEXT_CHANGED";
export const HANDLE_BACK_TO_SERVICE_COMPANY_SEARCH =
  "HANDLE_BACK_TO_SERVICE_COMPANY_SEARCH";

export const SERVICES_ACTIONS = {
  resetState: () => ({
    type: RESET_STATE,
  }),
  showDebtsList: (debts) => ({
    type: SHOW_DEBTS_LIST,
    payload: {
      debts,
    },
  }),
  handleModalityChanged: (modality) => ({
    type: HANDLE_SERVICE_MODALITY_CHANGED,
    payload: {
      modality,
    },
  }),
  handleIdentifierValueChanged: (identifierValue) => ({
    type: HANDLE_SERVICE_IDENTIFIER_VALUE_CHANGED,
    payload: {
      identifierValue,
    },
  }),
  handleServicesSearchResults: (services) => ({
    type: HANDLE_SERVICE_SEARCH_OPTIONS,
    payload: {
      services,
    },
  }),
  handlePopularServicesSearchResults: (services) => ({
    type: HANDLE_POPULAR_SERVICE_SEARCH_OPTIONS,
    payload: {
      services,
    },
  }),
  handleServiceToSearchDebtsSelected: (service) => ({
    type: HANDLE_SERVICE_SEARCH_OPTION_CHANGED,
    payload: {
      selectedOption: service,
    },
  }),
  handleServiceSearchInputTextChanged: (serviceSearchInputText) => ({
    type: HANDLE_SERVICE_SEARCH_INPUT_TEXT_CHANGED,
    payload: {
      serviceSearchInputText,
    },
  }),
  handleBackToServiceCompanySearch: () => ({
    type: HANDLE_BACK_TO_SERVICE_COMPANY_SEARCH,
  }),
};

import { Divider } from "@mui/material";
import PaymentSummaryItem from "./PaymentSummaryItem";
import retentionOverComission from "./retentionOverComission";
import { styles } from "./styles";
import { RETE_ICA, RETE_FUENTE } from "constants";

const wordingsRetentions = {
  retefuente: "Rete Fuente",
  reteica: "Rete ICA",
};
const wordingsRetentionsRates = {
  retefuente: RETE_FUENTE,
  reteica: RETE_ICA,
};

function ComissionRetentions({
  comissionRetentionIsEnabled,
  comissionRetentions,
  newPricing,
  selectedPaymentMethod,
  formatAmount,
  payanaComission,
}) {
  const label = (comissionRetention) =>
    `${wordingsRetentions[comissionRetention.key] || comissionRetention.key} (${
      wordingsRetentionsRates[comissionRetention.key]
    })`;
  const rate = (comissionRetention) =>
    `- ${formatAmount.format(
      retentionOverComission(payanaComission, comissionRetention)
    )}`;
  const items = comissionRetentions.map((comissionRetention) => (
    <PaymentSummaryItem
      label={label(comissionRetention)}
      value={rate(comissionRetention)}
    />
  ));
  return (
    comissionRetentionIsEnabled &&
    comissionRetentions.length > 0 &&
    newPricing &&
    selectedPaymentMethod === "others" && (
      <>
        {items}
        <Divider sx={styles.divider} />
      </>
    )
  );
}

export default ComissionRetentions;

export const styles = {
  inputLabel: {
    fontSize: "14px",
    cursor: "pointer",
    color: "#1D2939",
  },
  inputLabelLegend: {
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    color: "#667085",
  },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    textAlign: "left",
  },
  subLabel: {
    fontSize: "14px",
  },
  disclaimerContainer: {
    backgroundColor: "#FEF0C7",
    borderRadius: "8px",
    padding: "8px",
    color: "#7A2E0E",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "8px",
    maxWidth: "360px",
  },
};

import React, { useContext, useState, useRef } from "react";
import {
  renderAutocomplete,
  ServicesEmptyState,
} from "screens/ServicesScreenCommons/components";
import { ListPage } from "screens/ListPage";
import { servicesPaymentColumns, subMenuItems } from "utils";
import { PageFooter } from "./PageFooter";
import { useSelectedRows } from "screens/ListPage/hooks/useSelectedRows";
import { CHECKOUT_MODAL } from "commons/modals/keys";
import { TRANSACTION_TYPE } from "constants/transactions";
import { ServicesCreateButtons } from "../ServicesScreenCommons/components/ServicesCreateButtons";
import { ServicesCreatePaymentModal } from "./ServicesCreatePaymentModal";
import { TransactionsContext } from "contexts";
import { MassiveCreateResultModal } from "./MassiveCreateModal/MassiveCreateResultModal";
import { useAbortableRequest } from "hooks";

export const ServicesPaymentScreen = () => {
  const { selectedRows, isChecked, toggleSelectedRow, toggleSelectedAll } =
    useSelectedRows();
  const csvImportButton = useRef(null);
  const { createInvoicesPayLaterTransaction } = useContext(TransactionsContext);
  const selectedRowsIds = selectedRows.map((row) => row.original.id);
  const [isManualCreateOpen, setIsManualCreateOpen] = useState(false);
  const [refreshId, setRefreshId] = useState(0);
  const totalAmount = selectedRows.reduce(
    (acc, row) => acc + row.original.amount_total,
    0
  );
  const renderPageFooter = (setOpenModalKeys, setClosedModalKeys) => {
    const openCheckoutModal = () => {
      const checkoutModalProps = {
        type: TRANSACTION_TYPE.SERVICE,
        handleClose: () => setClosedModalKeys(CHECKOUT_MODAL),
        totalAmount,
        selectedRowsIds,
      };
      setOpenModalKeys(CHECKOUT_MODAL, checkoutModalProps);
    };
    return (
      <PageFooter
        selectedRows={selectedRows}
        totalAmount={totalAmount}
        selectedRowsIds={selectedRowsIds}
        openWompiModal={openCheckoutModal}
        payLater={() => {
          createInvoicesPayLaterTransaction(
            selectedRowsIds,
            TRANSACTION_TYPE.SERVICE
          );
        }}
        showApprove={false}
        showPayLater={true}
      />
    );
  };
  const openManualCreate = () => setIsManualCreateOpen(true);
  const onClose = () => setIsManualCreateOpen(false);
  const openMassiveCreate = () => csvImportButton.current.click();
  const [query, setQuery] = useState("");
  const [optionsToFilter, setOptionsToFilter] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const getSearchOptions = async (q) => setQuery(q);

  useAbortableRequest({
    requestConfig: {
      url: `/services/agenda?query=${query}`,
    },
    preventRequest: () => query.length > 1 && query.length < 3,
    onInitRequest: () => {},
    onAbortRequest: () => {},
    requestThen: (response) => {
      setOptionsToFilter(response);
    },
  });

  const autocomplete = () =>
    renderAutocomplete({
      isMobile: false,
      inputValue: selectedOption?.name || query,
      searchOptions: optionsToFilter,
      onTableSearchSelected: setSelectedOption,
      getSearchOptions,
    });
  const url = "/services/invoices";
  const queries = {
    ...(selectedOption?.id && { service_id: selectedOption?.id }),
    ...(refreshId && { refresh_id: refreshId }),
  };
  return (
    <>
      <ListPage
        url={url}
        queries={queries}
        selectedRows={selectedRows}
        getTableData={(response) => response?.invoices || []}
        buttons={
          <ServicesCreateButtons
            openManualCreate={openManualCreate}
            openMassiveCreate={openMassiveCreate}
          />
        }
        isChecked={isChecked}
        toggleSelectedRow={toggleSelectedRow}
        toggleSelectedAll={toggleSelectedAll}
        section="services"
        pageTitle="Cuentas por pagar"
        tableColumns={servicesPaymentColumns}
        useCheckboxes
        pageEmptyState={
          <ServicesEmptyState
            openManualCreate={openManualCreate}
            openMassiveCreate={openMassiveCreate}
            currentScreenTitle={"Cuentas por pagar"}
          />
        }
        subMenuItems={subMenuItems}
        renderAutocomplete={autocomplete}
        renderPageFooter={renderPageFooter}
      />
      <ServicesCreatePaymentModal
        visible={isManualCreateOpen}
        onClose={onClose}
      />
      <MassiveCreateResultModal
        csvImportButton={csvImportButton}
        onClose={() => {
          setRefreshId((id) => id + 1);
        }}
      />
    </>
  );
};

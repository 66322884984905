import { objectIsEmpty } from "../../../utils/objectUtils";

const getFormDataFromCollection = (collection) => {
  return {
    customer_id: collection.customer_id || "",
    issue_date: collection.issue_date
      ? new Date(collection.issue_date.slice(0, -1))
      : null,
    expiration_date: collection.expiration_date
      ? new Date(collection.expiration_date.slice(0, -1))
      : null,
    amount: collection.amount,
    type: collection.type,
    concept: collection.concept || "",
    collection_number: collection.collection_number || "",
    amount_type: collection.amount_type || "amount",
    first_receiver_amount:
      collection.amount_type === "amount"
        ? collection?.receivers?.at(0)?.amount
        : collection?.receivers?.at(0)?.percentage,
    second_receiver_amount:
      collection.amount_type === "amount"
        ? collection?.receivers?.at(1)?.amount
        : collection?.receivers?.at(1)?.percentage,
    receivers:
      collection.receivers.map((receiver) => {
        return {
          receiver_id: receiver.id,
          amount: receiver.amount,
          percentage: receiver.percentage,
        };
      }) || [],
    checked: !objectIsEmpty(collection?.receivers),
    firstReceiverOptionSelected: collection?.receivers?.at(0),
    secondReceiverOptionSelected: collection?.receivers?.at(1),
  };
};

const getDefaultFormData = () => {
  return {
    customer_id: "",
    issue_date: null,
    expiration_date: null,
    amount: "",
    type: "",
    concept: "",
    collection_number: "",
    amount_type: "",
    receivers: [],
  };
};

export { getFormDataFromCollection, getDefaultFormData };

export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowMarginTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "24px",
  },
  rowMarginTopBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "24px",
    marginBottom: "24px",
  },
  fixedRight: {
    marginTop: "12px",
    marginLeft: "auto",
  },
  container: {
    backgroundColor: "#FEF0C7",
    border: "1px solid #eaecf0",
    borderRadius: "8px",
    padding: "8px",
    color: "#7A2E0E",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    marginBottom: "20px",
  },
  display: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "6px",
  },
  title: {
    fontWeight: 600,
    fontSize: "14px",
    marginLeft: "6px",
  },
  text: {
    fontWeight: 400,
    fontSize: "12px",
    marginLeft: "6px",
  },
  paidOutsideContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFAEB",
    padding: "0px",
    height: "48px",
  },
  paidOutsideText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#7A2E0E",
  },
  timelineContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F4F3FF",
    padding: "0px",
    height: "118px",
    marginBottom: "24px",
  },
  timelineRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F4F3FF",
    padding: "0px",
    height: "118px",
    width: "720px",
  },
  timelineItemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px",
    height: "78px",
  },
  timelineItemTitle: {
    marginTop: "14px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
  },
  timelineItemText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#667085",
  },
  timelineDivider: {
    width: "120px",
    height: "2px",
    background: "#6938EF",
  },
};

import { Stack, TextField } from "@mui/material";
import { RequiredLabel, ValidationMessage } from "commons";
import React from "react";

export const InputForm = ({
  label,
  name,
  errors,
  disabled,
  register = () => {},
  required = false,
  customInput,
  placeholder,
  width,
}) => {
  return (
    <Stack spacing={1} width={width}>
      {required ? (
        <RequiredLabel
          label={label}
          sx={{
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "16px",
            color: "#101828",
            marginBottom: "12px",
          }}
        />
      ) : (
        <label
          htmlFor={name}
          style={{ fontSize: "14px", fontWeight: 500, lineHeight: "20px" }}
        >
          {label}
        </label>
      )}
      {customInput ? (
        customInput
      ) : (
        <TextField
          id={name}
          name={name}
          size="small"
          variant="outlined"
          InputProps={{
            style: { fontSize: 14 },
          }}
          disabled={disabled}
          {...register(name, { required: required })}
          placeholder={placeholder}
        />
      )}
      {errors && <ValidationMessage errors={errors} field={name} />}
    </Stack>
  );
};

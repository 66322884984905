import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { TrackJS } from "trackjs";
import { ConfirmCobreStepIcon } from "../../../assets";
import { styles } from "./styles";
import { PrimaryButton } from "../../buttons";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";

function ConfirmCobreStep({ primaryAction }) {
  const [loading, setLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  return (
    <Box sx={styles.box} className="fade-in">
      <div style={{ textAlign: "center" }}>
        <ConfirmCobreStepIcon />
      </div>
      <div style={styles.column}>
        <p style={styles.title}>
          <strong>
            Seguimos mejorando nuestra plataforma: nuevo procesador de pago
          </strong>
        </p>
        <p
          style={{
            color: "#475467",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          <span>
            Serás re-dirigido a PayZen, nuestro nuevo procesador de
            <strong> pagos PSE </strong>
            (anteriormente Wompi). Esto nos permite dispersar los pagos más
            rápido.
          </span>
        </p>
        <p
          style={{
            color: "#475467",
            fontSize: 14,
            fontWeight: 400,
            margin: 0,
          }}
        >
          <span>
            A partir de ahora la cuenta de origen desde donde se realizarán los
            pagos está a nombre de:
          </span>
          <p
            style={{
              display: "inline-block",
              marginTop: "4px",
              color: "#344054",
              padding: "4px 8px",
              borderRadius: "4px",
              backgroundColor: "#EAECF0",
            }}
          >
            <strong>PEXTO SOLUCIONES SAS</strong>
          </p>
        </p>
        <p
          style={{
            color: "#475467",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          Al finalizar el pago no olvides hacer clic en el botón
          <br />
          <strong>“Volver a la tienda”</strong>
        </p>
        <div style={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ width: "100%" }}
          >
            <PrimaryButton
              width="100%"
              text="Continuar"
              isDisable={loading || isConfirmed}
              loading={loading}
              action={async () => {
                try {
                  setLoading(true);
                  await primaryAction();
                } catch (error) {
                  TrackJS.console.error(error);
                  toast.error(
                    "Ha ocurrido un error, intente nuevamente",
                    toastOptions
                  );
                } finally {
                  setLoading(false);
                  setIsConfirmed(true);
                }
              }}
            />
          </Stack>
        </div>
      </div>
    </Box>
  );
}

export default ConfirmCobreStep;

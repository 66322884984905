import React from "react";

export const UserCheckIcon = ({ style = {} }) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" style={style}>
    <path
      d="M8.00004 9.33333H5.00004C4.06967 9.33333 3.60448 9.33333 3.22595 9.44816C2.37368 9.70669 1.70673 10.3736 1.4482 11.2259C1.33337 11.6044 1.33337 12.0696 1.33337 13M10.6667 11L12 12.3333L14.6667 9.66667M9.66671 4C9.66671 5.65685 8.32356 7 6.66671 7C5.00985 7 3.66671 5.65685 3.66671 4C3.66671 2.34315 5.00985 1 6.66671 1C8.32356 1 9.66671 2.34315 9.66671 4Z"
      stroke="#667085"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

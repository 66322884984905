import React from "react";

export const ReturnPendingIcon = ({ height = "24px", width = "19px" }) => (
  <svg width={width} height={height} viewBox="0 0 19 24" fill="none">
    <path
      d="M5.12664 10.6703V13.4214M13.3799 10.6703V13.4214M2.37555 9.43232L2.37555 14.6594C2.37555 15.4298 2.37555 15.815 2.52547 16.1092C2.65735 16.368 2.86778 16.5785 3.12661 16.7103C3.42086 16.8603 3.80604 16.8603 4.57642 16.8603L13.9301 16.8603C14.7005 16.8603 15.0857 16.8603 15.3799 16.7103C15.6388 16.5785 15.8492 16.368 15.9811 16.1092C16.131 15.815 16.131 15.4298 16.131 14.6594V9.43232C16.131 8.66194 16.131 8.27675 15.9811 7.98251C15.8492 7.72368 15.6388 7.51325 15.3799 7.38137C15.0857 7.23145 14.7005 7.23145 13.9301 7.23145L4.57642 7.23145C3.80604 7.23145 3.42086 7.23145 3.12661 7.38137C2.86778 7.51325 2.65735 7.72368 2.52547 7.98251C2.37555 8.27675 2.37555 8.66194 2.37555 9.43232ZM10.9727 12.0459C10.9727 12.9955 10.2029 13.7653 9.25328 13.7653C8.30366 13.7653 7.53384 12.9955 7.53384 12.0459C7.53384 11.0962 8.30366 10.3264 9.25328 10.3264C10.2029 10.3264 10.9727 11.0962 10.9727 12.0459Z"
      stroke="#344054"
      strokeWidth="1.37555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3333 19.3333L12.5 21.1667M12.5 21.1667L14.3333 23M12.5 21.1667H14.3333C16.3584 21.1667 18 19.525 18 17.5"
      stroke="#344054"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 4.82715L7 2.82715M7 2.82715L5 0.827148M7 2.82715H5C2.79086 2.82715 1 4.61801 1 6.82715"
      stroke="#344054"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React, { useState } from "react";

import { FileIcon, UserIcon } from "../../../../../assets";

import styles from "./styles.module.css";

const Tabs = ({ onChange, tabDefaultValue }) => {
  const [tabSelected, setTabSelected] = useState(tabDefaultValue);

  const handleChangeTab = (tabSelected) => {
    setTabSelected(tabSelected);
    onChange(tabSelected);
  };

  return (
    <div className={styles.tabsContainer}>
      <button
        className={`${styles.tabBase} ${
          tabSelected === 0 && styles.tabSelected
        } `}
        onClick={() => handleChangeTab(0)}
      >
        <UserIcon color={tabSelected === 0 && "#6938ef"} />
        <label
          className={`${styles.labelBase} ${
            tabSelected === 0 && styles.labelSelected
          }`}
        >
          Proveedor
        </label>
      </button>
      <button
        className={`${styles.tabBase} ${
          tabSelected === 1 && styles.tabSelected
        }`}
        onClick={() => handleChangeTab(1)}
      >
        <FileIcon color={tabSelected === 1 && "#6938ef"} />
        <label
          className={`${styles.labelBase} ${
            tabSelected === 1 && styles.labelSelected
          }`}
        >
          Servicio
        </label>
      </button>
    </div>
  );
};

export default Tabs;

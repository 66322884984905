import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import { IconButton } from "@mui/material";
import { CloseIcon, SiigoIntegrationIcon } from "../../../../assets";

const CloseButton = ({ onClick }) => (
  <div className="dian-modal-close-button">
    <IconButton onClick={onClick} title="Cerrar">
      <CloseIcon />
    </IconButton>
  </div>
);

const Steps = ({
  step,
  primaryAction,
  cancel,
  siigoData,
  isCausation = false,
  credentialsError = false,
  setCredentialsError = () => {},
  data = {},
  errorMessage = {},
}) => {
  const className = `my-container ${
    step === 1
      ? "siigo-step-one"
      : step === 2
      ? "siigo-step-two"
      : step === 4
      ? "siigo-step-four"
      : step === 6
      ? "siigo-step-six"
      : step === 7
      ? "siigo-step-seven"
      : step === 8
      ? "siigo-step-eight"
      : "siigo-step-three"
  }`;
  const steps = {
    1: (
      <>
        <CloseButton onClick={cancel} />
        <div className={className}>
          <div className="dian-modal-header-icon">
            <SiigoIntegrationIcon />
          </div>
          <StepTwo siigoData={siigoData} primaryAction={primaryAction} />
        </div>
      </>
    ),
    2: (
      <>
        <CloseButton onClick={cancel} />
        <div className={className}>
          <div className="dian-modal-header-icon">
            <SiigoIntegrationIcon />
          </div>
          <StepTwo primaryAction={primaryAction} />
        </div>
      </>
    ),
    3: (
      <div className={className}>
        <StepThree
          title="Conectando a Siigo"
          subtitle="Este proceso puede demorar hasta 3 minutos. Por favor no cierres esta
          ventana."
          primaryAction={primaryAction}
        />
      </div>
    ),
    4: (
      <>
        <CloseButton onClick={cancel} />
        <div className={className}>
          <div className="dian-modal-header-icon">
            <SiigoIntegrationIcon />
          </div>
          <StepFour
            cancel={cancel}
            primaryAction={primaryAction}
            showStartDate={!isCausation}
            credentialsError={credentialsError}
            setCredentialsError={setCredentialsError}
            data={data}
          />
        </div>
      </>
    ),
    5: (
      <div className={className}>
        <StepThree
          title="Importando información"
          subtitle="Este proceso puede demorar hasta 3 minutos. Por favor no cierres esta
          ventana."
          primaryAction={primaryAction}
        />
      </div>
    ),
    6: (
      <div className={className}>
        <StepSix
          title="Un error ha ocurrido"
          subtitle="Haga click en reintentar para obtener un nuevo token de acceso."
          cancel={cancel}
          primaryAction={primaryAction}
        />
      </div>
    ),
    7: (
      <>
        <CloseButton onClick={cancel} />
        <div className={className}>
          <div className="dian-modal-header-icon">
            <SiigoIntegrationIcon />
          </div>
          <StepFour
            cancel={cancel}
            primaryAction={primaryAction}
            showStartDate={true}
            onlyShowDate={true}
          />
        </div>
      </>
    ),
    8: (
      <>
        <CloseButton onClick={cancel} />
        <div className={className}>
          <StepFive
            cancel={cancel}
            primaryAction={primaryAction}
            errorMessage={errorMessage}
          />
        </div>
      </>
    ),
  };
  return steps[step];
};

export default Steps;

import { formatLocaleDate } from "./formatLocaleDate";

export const collectionsTransactionsColumns = () => [
  {
    field: "customer_name",
    Header: "Cliente",
    headerName: "Cliente",
    accessor: "customer_name",
    id: "customer_name",
    Cell: ({ value }) => {
      return value?.toUpperCase();
    },
  },
  {
    field: "created_at",
    Header: "Fecha de creación",
    headerName: "Fecha de creación",
    accessor: "created_at",
    id: "created_at",
    Cell: ({ value }) => {
      if (value) {
        return formatLocaleDate(value, "d MMM yyyy, h:mm aaaa");
      }
    },
  },
  {
    field: "paid_at",
    Header: "Fecha de pago",
    headerName: "Fecha de pago",
    accessor: "paid_at",
    id: "paid_at",
    Cell: ({ value }) => {
      if (value) {
        return formatLocaleDate(value, "d MMM yyyy, h:mm aaaa");
      }
    },
  },
  {
    field: "amount",
    Header: "Monto",
    headerName: "Monto",
    accessor: "amount",
    headerClassName: "amount-header",
    id: "amount",
  },
  {
    field: "status",
    Header: "Estado",
    headerName: "Estado",
    accessor: "status",
    id: "status",
  },
];

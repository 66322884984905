import React from "react";
import { Typography } from "@mui/material";
import { styles } from "./styles";

export const BannerInformation = ({
  startIcon,
  endButton,
  text,
  customText,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.iconTextContainer}>
        {startIcon && <div style={styles.iconContainer}>{startIcon}</div>}
        {customText ? (
          customText
        ) : (
          <Typography sx={styles.text}>{text}</Typography>
        )}
      </div>
      {endButton}
    </div>
  );
};

import { toast } from "react-toastify";
import * as CausationService from "../services/api/causation/Causation.service";
import { toastOptions } from "../constants";
import { truncateDecimalsDown } from "utils";
import { useEffect, useState } from "react";
import { INITIAL_PAYMENT_METHOD } from "commons/modals/ExpandCausationModal/constants";

export const useCausation = () => {
  const [paymentsSelected, setPaymentsSelected] = useState([
    { ...INITIAL_PAYMENT_METHOD },
  ]);
  const [paymentsAdvances, setPaymentsAdvances] = useState([]);
  const [hasCompanyWareHouse, setHasCompanyWareHouse] = useState(false);

  const getItemAmountTotal = (name, value = 0, row) => {
    let taxIvaValue;
    let taxRetefuenteValue;
    let { quantity, unitValue, discount, taxIva, taxRetefuente } = row;

    if (name === "quantity") {
      quantity = value || 0;
    }
    if (name === "unitValue") {
      unitValue = value || 0;
    }
    if (name === "discount") {
      discount = value || 0;
    }

    if (name === "taxIva") {
      taxIvaValue = value / 100 || 0;
    } else {
      taxIvaValue = taxIva?.percentage / 100 || 0;
    }

    if (name === "taxRetefuente") {
      taxRetefuenteValue = value / 100 || 0;
    } else {
      taxRetefuenteValue = taxRetefuente?.percentage / 100 || 0;
    }

    const valueQuantity = parseFloat(quantity) * parseFloat(unitValue);
    const amountWithoutTax = valueQuantity - parseFloat(discount);

    const tax = 1 + taxIvaValue - taxRetefuenteValue;
    const amountTotal = amountWithoutTax * tax;

    return truncateDecimalsDown(amountTotal);
  };

  const checkSiigoCredentials = async () => {
    try {
      const resp = await CausationService.checkSiigoCredential();
      // FALSE -> credenciales valida
      // TRUE -> credenciales no valida
      const syncValidation = resp?.syncValidation;
      return syncValidation?.credentials;
    } catch (error) {
      toast.error(
        "Hubo un error al obtener la información de credenciales",
        toastOptions
      );
    }
  };

  const handleSetPaymentsAdvances = (value) => {
    setPaymentsAdvances(value);
  };

  const handleSetPaymentsSelected = (value) => {
    setPaymentsSelected(value);
  };

  useEffect(() => {
    CausationService.hasCompanyWareHouse().then((response) => {
      setHasCompanyWareHouse(response);
    });
  }, []);

  return {
    hasCompanyWareHouse,
    payments: paymentsSelected,
    paymentsAdvances,
    getItemAmountTotal,
    checkSiigoCredentials,
    handleSetPaymentsAdvances,
    handleSetPayments: handleSetPaymentsSelected,
  };
};

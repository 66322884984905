import React, { useContext, useEffect, useState } from "react";
import { Stack, Dialog } from "@mui/material";
import { styles } from "./styles";
import { PaymentsContext, PayrollContext } from "../../../contexts";
import { CloseButton } from "react-bootstrap";
import { EmployeeForm } from "./EmployeeForm";

export const EmployeeDetails = ({
  isOpen,
  handleClose,
  id,
  isBO = false,
  isRetryFlow = false,
  handleSuccess = () => {},
  errorMessage = "",
}) => {
  const { getEmployeeDetails, createEmployee, updateEmployeeDetails } =
    useContext(PayrollContext);
  const { getAllBanks } = useContext(PaymentsContext);
  const [detailedEmployee, setDetailedEmployee] = useState({
    name: "",
    documentType: "",
    documentNumber: "",
    email: "",
    phone: "",
    bankId: "",
    bankName: "",
    accountType: "",
    accountNumber: "",
  });

  const close = () => {
    handleClose();
    setDetailedEmployee({
      name: "",
      documentType: "",
      documentNumber: "",
      email: "",
      phone: "",
      bankId: "",
      bankName: "",
      accountType: "",
      accountNumber: "",
    });
  };

  useEffect(() => {
    if (isOpen) {
      getAllBanks();
    }
    if (id) {
      getEmployeeDetails(isBO, id).then((detailedEmployee) =>
        setDetailedEmployee({
          name: detailedEmployee.name,
          documentType: detailedEmployee.document_type,
          documentNumber: detailedEmployee.document_number,
          phone: detailedEmployee.phone || "",
          email: detailedEmployee.email,
          bankId: detailedEmployee.bank_id,
          bankName: detailedEmployee.bank_name,
          accountType: detailedEmployee.account_type || null,
          accountNumber: detailedEmployee.account_number,
        })
      );
    }
  }, [isOpen, id]);

  const updateOrCreateEmployeeHandler = (formValues) => {
    const formValuesToSend = {
      name: formValues.name,
      document_type: formValues.documentType,
      document_number: formValues.documentNumber,
      phone: formValues.phone,
      email: formValues.email,
      bank_id: formValues.bankId,
      bank_name: formValues.bankName,
      account_type: formValues.accountType || null,
      account_number: formValues.accountNumber,
    };
    if (id) {
      updateEmployeeDetails(isBO, id, formValuesToSend);
      handleSuccess({
        ...formValues,
        id,
      });
    } else {
      createEmployee(formValuesToSend);
    }
    close();
  };

  return (
    <Dialog fullScreen open={isOpen}>
      <Stack direction={"row"} justifyContent="flex-end" sx={styles.container}>
        <CloseButton style={{ boxShadow: "none" }} onClick={close} />
      </Stack>
      <div style={styles.content}>
        <Stack sx={styles.formContainer}>
          <EmployeeForm
            detailedEmployee={detailedEmployee}
            onCancel={close}
            onSubmit={updateOrCreateEmployeeHandler}
            title={id ? "Editar empleado" : "Agendar empleado"}
            isRetryFlow={isRetryFlow}
            errorMessage={errorMessage}
          />
        </Stack>
      </div>
    </Dialog>
  );
};

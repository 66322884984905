import React from "react";

export const CopyUrlIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M10.5898 15.3032L9.41128 16.4817C7.7841 18.1089 5.14591 18.1089 3.51873 16.4817C1.89154 14.8546 1.89154 12.2164 3.51873 10.5892L4.69724 9.41068M15.3038 10.5892L16.4824 9.41067C18.1095 7.78349 18.1095 5.1453 16.4824 3.51812C14.8552 1.89093 12.217 1.89093 10.5898 3.51812L9.41128 4.69663M7.08387 12.9166L12.9172 7.08325"
      stroke="#98A2B3"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { Box, Divider, Stack, Typography } from "@mui/material";
import { styles } from "../styles";

function SettingsRow({ label, children, subLabel }) {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        my={2}
        sx={{ marginLeft: "32px" }}
      >
        <Box>
          <Typography sx={styles.inputLabel} noWrap>
            {label}
          </Typography>
          {subLabel && (
            <Typography sx={styles.inputSubLabel}>{subLabel}</Typography>
          )}
        </Box>
        <Stack flexGrow={1} gap="16px" flexDirection="row">
          {children}
        </Stack>
      </Stack>
      <Divider style={styles.divider} />
    </>
  );
}

export default SettingsRow;

import { useContext } from "react";
import { getStatusPillProps } from "./getStatusPillProps";
import { UserContext } from "../../../contexts";
import { Tooltip } from "commons/components";

export const StatusPill = (props) => {
  const { className = "" } = props;
  const { currentUserIsAdmin, approvationNeeded } = useContext(UserContext);
  const input = {
    ...props,
    currentUserIsAdmin,
    approvationNeeded,
  };
  const { style, label } = getStatusPillProps(input);
  const { tooltipText = null } = props;
  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <span className={className} style={style}>
        {label}
      </span>
    </Tooltip>
  ) : (
    <span className={className} style={style}>
      {label}
    </span>
  );
};

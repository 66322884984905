import retentionOverComission from "../commons/modals/PaymentModal/retentionOverComission";

export const payanaComissionRetentions = (
  payanaComission,
  selectedPaymentMethod,
  retentions
) => {
  return (retentions || []).reduce((acc, retention) => {
    return acc + retentionOverComission(payanaComission, retention);
  }, 0);
};

import React from "react";

import styles from "./styles.module.css";
import { EmailIcon, WppIcon } from "../../../assets";
import { useMediaQuery } from "react-responsive";

export const MediaTabs = ({ tabSelected, setTabSelected }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const handleChangeTab = (tabSelected) => {
    setTabSelected(tabSelected);
  };

  return (
    <div
      className={isMobile ? styles.tabsContainerMobile : styles.tabsContainer}
    >
      <button
        className={`${styles.tabBase} ${
          tabSelected === "whatsapp" && styles.tabSelected
        } `}
        onClick={() => handleChangeTab("whatsapp")}
      >
        <WppIcon color={tabSelected === "whatsapp" && "#6938ef"} />
        <label
          className={`${styles.labelBase} ${
            tabSelected === "whatsapp" && styles.labelSelected
          }`}
        >
          WhatsApp
        </label>
      </button>
      <button
        className={`${styles.tabBase} ${
          tabSelected === "email" && styles.tabSelected
        }`}
        onClick={() => handleChangeTab("email")}
      >
        <EmailIcon color={tabSelected === "email" && "#6938ef"} />
        <label
          className={`${styles.labelBase} ${
            tabSelected === "email" && styles.labelSelected
          }`}
        >
          Email
        </label>
      </button>
    </div>
  );
};

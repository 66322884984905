import React, { useRef, useState } from "react";
import { SecondaryButton, TagsButton } from "../../../commons/buttons";
import { TagsSelector } from "../../../commons";
import { Divider, Menu } from "@mui/material";
import { styles } from "../styles";

export const TagsDropdown = ({
  options,
  selectedValues,
  onSelect,
  onRemove,
  setTags,
  setTagsSelected,
  className = null,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const searchRef = useRef(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    searchRef?.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cleanTags = () => {
    setTagsSelected([]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <TagsButton
          open={open}
          action={handleClick}
          text="Etiquetas"
          quantity={selectedValues.length}
          cleanTags={cleanTags}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          slotProps={{
            paper: {
              style: {
                height: options.length * 70,
                minHeight: 270,
                maxHeight: 420,
                width: 390,
                padding: 16,
              },
            },
          }}
        >
          <TagsSelector
            options={options}
            selectedValues={selectedValues}
            onSelect={onSelect}
            onRemove={onRemove}
            setTags={setTags}
            setTagsSelected={setTagsSelected}
            ref={searchRef}
            className={className}
          />
          <div
            style={{ position: "absolute", bottom: "-340px", width: "100%" }}
          >
            <Divider style={styles.divider} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                gap: "12px",
                marginBottom: "16px",
              }}
            >
              <SecondaryButton
                text="Limpiar"
                action={() => {
                  cleanTags();
                  handleClose();
                }}
                width="88px"
              />
            </div>
          </div>
        </Menu>
      </div>
    </>
  );
};

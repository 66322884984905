export const RedBorderButton = ({
  text,
  action,
  isDisable = false,
  height = "40px",
  width = "100px",
  fontSize = "14px",
}) => {
  const styles = {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    height: height,
    color: isDisable ? "#B4231880" : "#B42318",
    fontSize: fontSize,
    fontWeight: "500",
    lineHeight: "20px",
    width: width,
    border: isDisable ? "2px #FDA29B80 solid" : "2px #FDA29B solid",
    "&:hover": {
      cursor: "pointer",
    },
  };

  return (
    <button type="button" onClick={action} disabled={isDisable} style={styles}>
      {text}
    </button>
  );
};

/* eslint-disable no-undef */
import { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { UserContext } from "../contexts";

export function useChat() {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const { currentCompany, currentUser } = useContext(UserContext);

  const updateInformationChat = () => {
    const updatedUserData = {
      user_id: currentUser?.id,
      name: currentUser?.name,
      phone: currentUser?.phone,
      email: currentUser?.email,
      rol: currentUser?.role,
      CompanyID: +currentCompany?.id,
      company: {
        company_id: currentCompany?.id,
        name: currentCompany?.name,
        created_at: currentCompany?.created_at,
        system_contable: currentCompany?.name_cs,
      },
    };

    Object.assign(window.intercomSettings, updatedUserData);
    Intercom("update", window.intercomSettings);
  };

  const chatVisibility = () => {
    if (isMobile) {
      Intercom("hide");
    } else {
      Object.assign(window.intercomSettings, { hide_default_launcher: false });
    }
    Intercom("update", window.intercomSettings);
  };

  const chatHidden = () => {
    Intercom("hide");
  };

  useEffect(() => {
    const handleChatVisibility = setTimeout(() => {
      chatVisibility();
      updateInformationChat();
    }, 2000);
    return () => clearTimeout(handleChatVisibility);
  }, []);

  return {
    chatHidden,
    updateInformationChat,
  };
}

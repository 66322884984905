export const styles = {
  body: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  modalTitle: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    margin: "0px",
  },
  modalText: {
    color: "#344054",
    textAlign: "center",
    fontWeight: 500,
    margin: "0px",
  },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    textAlign: "left",
  },
  loading: {
    minHeight: "90px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  buttonGroup: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  selectOptionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderBottom: "1px solid #EAECF0",
  },
  selectOptionTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "20px",
  },
  selectOptionSubtitle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#667085",
  },
  inputLabel: {
    color: "#101828",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "20px",
  },
};

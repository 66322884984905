import { IconButton } from "@mui/material";
import { CloseIcon } from "../../assets";

export const CloseButton = ({ onClick }) => {
  return (
    <div className="dian-modal-close-button">
      <IconButton onClick={onClick} title="Cerrar">
        <CloseIcon />
      </IconButton>
    </div>
  );
};

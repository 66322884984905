import { useContext, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteItemModal,
  DifferencesInPayrollModal,
  NotifyWompiModal,
  Submenu,
  Table,
  TableSkeleton,
  PaymentModal,
  WarningModal,
  Footer,
  SelectPayrollPeriodModal,
  PayrollUploadResultModal,
  UploadFileModal,
  AttentionModal,
  RenderIfPermissionEnabled,
  LimitExceededModal,
} from "../../commons";
import { NewButton } from "../../commons/buttons";
import {
  PayrollContext,
  TransactionsContext,
  UserContext,
} from "../../contexts";
import {
  useIsUserAllowedTo,
  filterOptions,
  hasSomeAssociatedDocumentDeleted,
  payrollScreens,
  salariesColumns,
} from "../../utils";
import { styles } from "./styles";
import { PayrollButtonGroup, PayrollEmptyState } from "./components";
import { NewPayrollItem, UploadPayrollFile } from "../../commons/Sidebars";
import { useNavigate } from "react-router";
import {
  EmployeeSearch,
  GetTotalpayrolls,
  GetTotalTransactions,
} from "../../services";
import { FullDeleteModal } from "../../commons/modals/DeleteItemModal/FullDeleteModal";
import { useMediaQuery } from "react-responsive";
import { SearchIcon } from "../../assets";
import CsvHandler from "../../commons/modals/UploadFileModal/CsvHandler";
import { createPortal } from "react-dom";
import { TxReferenceModal } from "../../commons/modals/TxReferenceModal";
import { useChat } from "../../hooks";
import { RegisterSourceOfFundsModal } from "../../commons/modals/RegisterSourceOfFundsModal";
import CompleteEmployeesBeforePayModal from "./CompleteEmployeesBeforePayModal";
import DrawerEmployeeForm from "./DrawerEmployeeForm";
import { BLOCKED_MESSAGE, permissions } from "../../constants";
import { EmployeeDetails } from "commons/modals/EmployeeDetails";
import { SubscriptionModal } from "commons/modals";

const INCOMPLETE_EMPLOYEE_MODAL_KEY =
  "entering_payment_with_employee_incomplete_disclamer";

export const SalariesScreen = (props) => {
  const {
    payrolls,
    deleteFullPayroll,
    markAsPaid,
    deleteSinglePayroll,
    payrollTotalAmount,
    payrollTotalEmployees,
    getPayrolls,
    payrollHasDifferencesModalIsOpen,
    setPayrollHasDifferencesModalIsOpen,
    employees,
    subscription,
  } = useContext(PayrollContext);
  const {
    getMe,
    canPayWithCobre,
    displayCobreAlert,
    blockedCompany,
    sourceOfFundsNeeded,
    approvationNeeded,
    currentUserIsAdmin,
  } = useContext(UserContext);
  const {
    createPayrollPayLaterTransaction,
    setCompanyHasTrxs,
    getTransactions,
  } = useContext(TransactionsContext);
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const navigate = useNavigate();
  const [employeeIdSelected, setEmployeeIdSelected] = useState("all");
  const [totals, setTotals] = useState();
  const [trxsTotals, setTrxsTotals] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [wompiModalIsVisible, setWompiModalIsVisible] = useState(false);
  const [resultModalIsOpen, setResultModalIsOpen] = useState(false);
  const [uploadResult, setUploadResult] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuHandler = Boolean(anchorEl);
  const [deleteItemModalIsVisible, setDeleteItemModalIsVisible] =
    useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const [openBlockedModal, setOpenBlockedModal] = useState(false);
  const [openEmployeesWithoutAmountModal, setOpenEmployeesWithoutAmountModal] =
    useState(false);
  const [openDeletedPayrollsAlert, setOpenDeletedPayrollsAlert] =
    useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [drawerState, setDrawerState] = useState({ key: "none" });
  const [openEmployeeIncomplete, setOpenEmployeeIncomplete] = useState(false);
  const [modalKeys, setModalKeys] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [notifyWompiModalIsVisible, setNotifyWompiModalIsVisible] = useState(
    !!id
  );
  const [searchOptions, setSearchOptions] = useState([]);
  const [markAsPaidWarning, setMarkAsPaidWarning] = useState(false);
  const [flow, setFlow] = useState(null);
  const [limitExceededAlert, setLimitExceededAlert] = useState({});
  const payrollsCsvButton = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const [uploadFileModalIsVisible, setUploadFileModalIsVisible] =
    useState(false);

  const closeUploadFileModal = () => setUploadFileModalIsVisible(false);

  useChat();
  const showModal = (key, flow) => {
    setModalKeys((keys) => [...keys, key]);
    setFlow(flow);
  };
  const closeModal = (key) => {
    setModalKeys((keys) => keys.filter((innerKey) => innerKey !== key));
  };

  const refreshTable = () => {
    getPayrolls(pageIndex, employeeIdSelected);
    GetTotalpayrolls({ employeeId: employeeIdSelected }).then((res) => {
      setTotals(res);
    });
  };

  useEffect(() => {
    getMe();
    GetTotalTransactions({ type: "payroll" }).then((res) => {
      setTrxsTotals(res);
    });
    refreshTable();
  }, []);

  const getSearchOptions = (query) => {
    EmployeeSearch(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const openResultModal = (uploadResult) => {
    setResultModalIsOpen(true);
    setUploadResult(uploadResult);
  };

  const closeResultModal = () => {
    refreshTable();
    navigate("/payroll/salaries", { replace: false });
    setResultModalIsOpen(false);
  };

  const openWompiModal = () => {
    if (blockedCompany) {
      setOpenBlockedModal(true);
    } else {
      const payrollsWithIncompleteEmployee = hasOneIncomplete();
      if (payrollsWithIncompleteEmployee.length > 0) {
        showModal(INCOMPLETE_EMPLOYEE_MODAL_KEY, "pay_now");
      } else {
        setWompiModalIsVisible(true);
      }
    }
  };
  const closeDeleteItemModal = () => {
    GetTotalpayrolls({ employeeId: employeeIdSelected }).then((res) => {
      setTotals(res);
    });
    setDeleteItemModalIsVisible(false);
  };
  const openDeleteItemModal = (id) => {
    setItemToDelete(id);
    setDeleteItemModalIsVisible(true);
  };
  const openFullDeleteModal = () => setDeleteItemModalIsVisible(true);
  const closeNotifyWompiModal = (id) => {
    setNotifyWompiModalIsVisible(false);
    navigate("/transactions", {
      replace: true,
    });
  };
  const closeWompiModal = () => {
    setWompiModalIsVisible(false);
    refreshTable();
  };

  const payLaterAction = () => {
    createPayrollPayLaterTransaction().then((res) => {
      if (res?.errors) {
        setLimitExceededAlert((limitExceeded) => {
          return {
            ...limitExceeded,
            visible: true,
            availableAmount: res.errors[0].availableAmount,
            limitAmount: res.errors[0].limitAmount,
          };
        });
      } else if (hasSomeAssociatedDocumentDeleted(res)) {
        refreshTable();
        setOpenDeletedPayrollsAlert(true);
      } else {
        getTransactions(1, "payroll");
        navigate("/payroll/transactions", { replace: true });
      }
    });
  };

  const payLater = () => {
    if (blockedCompany) {
      setOpenBlockedModal(true);
    } else {
      const payrollsWithIncompleteEmployee = hasOneIncomplete();
      if (payrollsWithIncompleteEmployee.length > 0) {
        showModal(INCOMPLETE_EMPLOYEE_MODAL_KEY, "pay_later");
      } else {
        payLaterAction();
      }
    }
  };

  const handleCloseDeletedPayrollsAlert = () => {
    setOpenDeletedPayrollsAlert(false);
  };

  const markPayrollAsPaid = () => {
    markAsPaid();
    navigate("../transactions", { replace: true });
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (totals) {
      if (totals.total > 200) {
        setTotalPages(Math.ceil(totals.total / 200));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  useEffect(() => {
    if (trxsTotals && trxsTotals.total >= 1) {
      setCompanyHasTrxs(true);
    }
  }, [trxsTotals]);

  useEffect(() => {
    refreshTable();
  }, [pageIndex, employeeIdSelected]);

  const payrollIsIncomplete = (payrollToCheck) => {
    const { id } = payrollToCheck;
    const payroll = payrolls.find((payroll) => payroll.id === id);

    if (!payroll) {
      return false;
    } else {
      const {
        employee_account_type,
        employee_bank_name,
        employee_account_number,
        employee_document_number,
        employee_document_type,
        employee_email,
      } = payroll;
      return (
        (!employee_account_type &&
          employee_bank_name?.toLowerCase() !== "nequi" &&
          employee_bank_name?.toLowerCase() !== "daviplata") ||
        !employee_account_number ||
        !employee_document_number ||
        !employee_document_type ||
        !employee_email
      );
    }
  };

  const hasOneIncomplete = (employee = {}) => {
    const dictionary = payrolls
      .filter((payroll) => {
        return (
          payrollIsIncomplete(payroll) && payroll.employee_id !== employee?.id
        );
      })
      .reduce((acc, payroll) => {
        acc[payroll.employee_id] = payroll;
        return acc;
      }, {});
    return Object.values(dictionary);
  };

  const { selectPeriodModalIsOpen, setSelectPeriodModalIsOpen } =
    useContext(PayrollContext);
  const [periodModalAction, setPeriodModalAction] = useState();

  const openPeriodModal = (action) => {
    setPeriodModalAction(action);
    setSelectPeriodModalIsOpen(true);
  };

  const handleEmptyModalClose = () => {
    setSelectPeriodModalIsOpen(false);
    refreshTable();
  };

  const handleSearchChange = (employee) => {
    if (employee) {
      setEmployeeIdSelected(employee.id);
    } else {
      setEmployeeIdSelected("all");
    }
    setPageIndex(1);
  };

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onChange={(event, option) => {
          if (option?.id) {
            handleSearchChange(option);
          } else {
            handleSearchChange();
          }
        }}
        onInputChange={(event) => {
          if (event?.target?.value?.length >= 3) {
            getSearchOptions(event.target.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar empleado"}
          />
        )}
      />
    );
  };

  const handleConfirm = (id) => {
    navigate("/transactions", {
      replace: true,
    });
  };

  const openEmployeesToCompleteModal = () => {
    closeModal(INCOMPLETE_EMPLOYEE_MODAL_KEY);
    const payrollsWithIncompleteEmployee = hasOneIncomplete();
    if (payrollsWithIncompleteEmployee.length > 0) {
      setDrawerState({
        key: "complete",
        updated: [],
        employeeIdsToComplete: payrollsWithIncompleteEmployee.map(
          (payroll) => payroll.employee_id
        ),
      });
    }
  };

  const handleCloseEmployeeIncomplete = () => {
    setOpenEmployeeIncomplete(null);

    const payrollsWithIncompleteEmployee = hasOneIncomplete();

    if (payrollsWithIncompleteEmployee.length > 0) {
      setDrawerState({
        key: "complete",
        updated: [],
        employeeIdsToComplete: payrollsWithIncompleteEmployee.map(
          (payroll) => payroll.employee_id
        ),
      });
    } else {
      setDrawerIsOpen(openEmployeeIncomplete.employee_id);
    }
  };

  const employeesWithoutAmount = payrolls?.some(
    (payroll) => payroll.amount === 0
  );

  const handleCloseLimitExceededAlert = () => {
    setLimitExceededAlert((limitExceeded) => {
      return {
        ...limitExceeded,
        visible: false,
      };
    });
  };

  return (
    <>
      {resultModalIsOpen &&
        uploadResult &&
        createPortal(
          <PayrollUploadResultModal
            visible={resultModalIsOpen}
            handleClose={closeResultModal}
            serviceResponse={uploadResult}
          />,
          document.body
        )}
      {subscription?.showModalSubscription && (
        <SubscriptionModal subscription={subscription} />
      )}
      <CsvHandler
        entity="payroll"
        licenseKey={process.env.REACT_APP_CSVBOX_PAYROLLS_LICENSE_KEY}
        buttonRef={payrollsCsvButton}
        openResultModal={openResultModal}
      />
      <UploadFileModal
        entity={"payrolls"}
        type={"TXT"}
        visible={uploadFileModalIsVisible}
        handleClose={closeUploadFileModal}
        setTotals={setTotals}
        openResultModal={openResultModal}
      />
      <SelectPayrollPeriodModal
        visible={selectPeriodModalIsOpen}
        handleClose={() => handleEmptyModalClose()}
        action={periodModalAction}
      />
      <TxReferenceModal onConfirm={handleConfirm} />
      {sourceOfFundsNeeded ? (
        <RegisterSourceOfFundsModal
          type="payroll"
          canPayWithCobre={canPayWithCobre}
          displayCobreAlert={displayCobreAlert}
          visible={wompiModalIsVisible}
          totalAmount={payrollTotalAmount}
          handleClose={closeWompiModal}
          selectedRowsIds={(payrolls || []).map((payroll) => payroll.id)}
          setOpenDeletedAlert={setOpenDeletedPayrollsAlert}
          redirectUrl={`${process.env.REACT_APP_BASE_URL}/payroll/salaries`}
          setLimitExceededAlert={setLimitExceededAlert}
        />
      ) : (
        <PaymentModal
          type="payroll"
          canPayWithCobre={canPayWithCobre}
          displayCobreAlert={displayCobreAlert}
          visible={wompiModalIsVisible}
          totalAmount={payrollTotalAmount}
          handleClose={closeWompiModal}
          selectedRowsIds={(payrolls || []).map((payroll) => payroll.id)}
          setOpenDeletedAlert={setOpenDeletedPayrollsAlert}
          redirectUrl={`${process.env.REACT_APP_BASE_URL}/payroll/salaries`}
          setLimitExceededAlert={setLimitExceededAlert}
        />
      )}
      {markAsPaidWarning && (
        <WarningModal
          action={markPayrollAsPaid}
          handleClose={() => setMarkAsPaidWarning(false)}
          title="Marcar nómina como pagada"
          subtitle="Está acción generará una transacción pagada con la nómina actual. ¿Desea continuar?"
          visible={markAsPaidWarning}
        />
      )}
      <NotifyWompiModal
        visible={notifyWompiModalIsVisible}
        handleClose={closeNotifyWompiModal}
      />
      <DifferencesInPayrollModal
        visible={payrollHasDifferencesModalIsOpen}
        handleClose={() => setPayrollHasDifferencesModalIsOpen(false)}
      />
      <DeleteItemModal
        itemId={itemToDelete}
        action={deleteSinglePayroll}
        handleClose={closeDeleteItemModal}
        title="¿Quieres borrar esta nómina?"
        subtitle="Si la eliminas, este empleado no aparecerá más en la nómina"
        visible={itemToDelete && deleteItemModalIsVisible}
      />
      <FullDeleteModal
        title="¿Quieres borrar toda la nómina?"
        subtitle="Si la eliminas, no aparecerá más en Payana"
        handleClose={closeDeleteItemModal}
        visible={!itemToDelete && deleteItemModalIsVisible}
        onClick={async () => {
          await deleteFullPayroll();
          closeDeleteItemModal();
        }}
      />
      <AttentionModal
        title={BLOCKED_MESSAGE}
        onClose={() => setOpenBlockedModal(false)}
        open={openBlockedModal}
      />
      <AttentionModal
        title="Parece que una o más de las nóminas que quieres pagar están eliminadas."
        description="Por favor, vuelve a intentar."
        buttonText="Entendido"
        onClose={handleCloseDeletedPayrollsAlert}
        open={openDeletedPayrollsAlert}
      />
      <AttentionModal
        title="Tienes empleados sin monto asignado"
        description="Para poder realizar el pago, es necesario que les agregues un monto o que los elimines del listado de la nómina a pagar."
        onClose={() => setOpenEmployeesWithoutAmountModal(false)}
        open={openEmployeesWithoutAmountModal}
      />
      <LimitExceededModal
        onClose={handleCloseLimitExceededAlert}
        data={limitExceededAlert}
      />
      <CompleteEmployeesBeforePayModal
        numberOfIncompleted={hasOneIncomplete().length}
        visible={modalKeys.includes(INCOMPLETE_EMPLOYEE_MODAL_KEY)}
        close={() => {
          closeModal(INCOMPLETE_EMPLOYEE_MODAL_KEY);
        }}
        primaryAction={openEmployeesToCompleteModal}
      />
      <UploadPayrollFile handleClose={closeResultModal} />
      <DrawerEmployeeForm
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        onFinish={() => {
          if (blockedCompany) {
            setOpenBlockedModal(true);
          } else {
            getPayrolls(1);
            flow === "pay_now"
              ? setWompiModalIsVisible(true)
              : payLaterAction();
          }
        }}
        flow={flow}
      />
      <EmployeeDetails
        id={Number.isFinite(drawerIsOpen) ? drawerIsOpen : null}
        isOpen={drawerIsOpen}
        handleClose={() => {
          setDrawerIsOpen(false);
        }}
      />
      <NewPayrollItem setAddEmployeeDrawerIsOpen={setDrawerIsOpen} />
      <div
        style={{
          marginBottom: isMobile ? "150px" : "80px",
          backgroundColor: "#FFFFFF",
        }}
      >
        {employees?.length > 0 && <Submenu items={payrollScreens} />}
        {!payrolls ? (
          <TableSkeleton />
        ) : payrolls && payrolls.length ? (
          <div className="layout-container">
            <div style={styles.titleContainer}>
              <Typography sx={styles.title} noWrap>
                Pago de nómina
              </Typography>
              <RenderIfPermissionEnabled
                permission={permissions.PAYROLLS_CREATE}
              >
                <NewButton action={openMenu} text="Nueva nómina" />
              </RenderIfPermissionEnabled>
            </div>
            <Popover
              anchorEl={anchorEl}
              open={openMenuHandler}
              onClose={handleCloseMenu}
              elevation={4}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              disableScrollLock={true}
            >
              <PayrollButtonGroup
                openPeriodModal={openPeriodModal}
                closeMenu={handleCloseMenu}
                buttonRef={payrollsCsvButton}
                openUploadFileModal={() => setUploadFileModalIsVisible(true)}
                isDropdown
              />
            </Popover>
            <Table
              columns={salariesColumns(setDrawerIsOpen)}
              data={payrolls}
              section="salaries"
              deleteIcon={!!isUserAllowedTo(permissions.PAYROLLS_DELETE)}
              openDeleteItemModal={openDeleteItemModal}
              setPageIndex={setPageIndex}
              checkboxVisible={false}
              pageCount={totalPages}
              renderAutocomplete={renderAutocomplete}
            />
          </div>
        ) : (
          <PayrollEmptyState
            handleClose={handleEmptyModalClose}
            buttonRef={payrollsCsvButton}
            openUploadFileModal={() => setUploadFileModalIsVisible(true)}
          />
        )}
      </div>
      {payrolls && payrolls.length > 0 && (
        <Footer
          subscription={subscription}
          disabled={payrolls.length === 0}
          totalAmount={payrollTotalAmount}
          totalEmployees={payrollTotalEmployees}
          showDelete={isUserAllowedTo(permissions.SHOW_FOOTER_PAYROLLS)}
          deleteAction={openFullDeleteModal}
          showPayLater={isUserAllowedTo(permissions.SHOW_FOOTER_PAYROLLS)}
          disablePayLater={payrollTotalAmount <= 0}
          payLaterAction={payLater}
          openWompiModal={openWompiModal}
          disableWompi={payrollTotalAmount <= 0}
          section="payroll"
          showApprove={approvationNeeded && currentUserIsAdmin}
          showWompi={isUserAllowedTo(permissions.SHOW_FOOTER_PAYROLLS)}
          showAttentionModal={() => setOpenEmployeesWithoutAmountModal(true)}
          employeesWithoutAmount={employeesWithoutAmount}
        />
      )}
    </>
  );
};

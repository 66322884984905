import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateDropdown } from "./DateDropdown";
import { TypeDropdown } from "./TypeDropdown";
import { styles } from "../styles";

export const BankMovementsFilters = ({
  date,
  setDate,
  setSelectedDate,
  setPageIndex,
  type,
  setType,
  selectedDate,
  amountSignSelected,
  setAmountSignSelected,
  setPerPageSelected,
}) => {
  return (
    <div style={styles.filtersContainer}>
      <div style={styles.filtersRow}>
        <DateDropdown
          date={date}
          setDate={setDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setPageIndex={setPageIndex}
        />
        <TypeDropdown
          type={type}
          setType={setType}
          setPageIndex={setPageIndex}
          amountSignSelected={amountSignSelected}
          setAmountSignSelected={setAmountSignSelected}
        />
      </div>
      <div className="paginationNav">
        <select
          className="page-link"
          style={styles.paginationPerPage}
          onChange={(e) => {
            setPerPageSelected(e.target.value);
            setPageIndex(1);
          }}
        >
          {[50, 100, 150, 200].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

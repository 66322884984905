import React from "react";
import { Table } from "commons";
import { boCompanyColumns } from "../utils/boCompanyColumns";

export const SectionCompanyInformation = ({ company = {} }) => {
  return (
    <Table
      columns={boCompanyColumns}
      data={[company]}
      showFilters={false}
      perPageVisible={false}
      showPagination={false}
    />
  );
};

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { RejectedIcon } from "assets/svg/RejectedIcon";
import { IconButton } from "@mui/material";
import { CloseIcon } from "assets";

export const ModalSiigo = ({ state, onClose = () => {} }) => {
  const titleDefault = "No pudimos realizar el egreso.";
  const textDefault = "Por favor, intenta de nuevo en unos minutos.";

  const title = {
    siigo: "No pudimos realizar el egreso.",
    providerNotFound: "Proveedor no encontrado.",
    entryType: "Solicitud de información incompleta.",
  };

  const textProviderNotFound = `El número de documento del proveedor en Payana es ${state.documentNumber}.`;
  const alterTextProviderNotFound = `No encontramos en Siigo ningún número de documento que coincida con el Proveedor cargado en Payana.`;

  const text = {
    siigo: "Por favor, intenta de nuevo en unos minutos.",
    providerNotFound: state?.documentNumber
      ? textProviderNotFound
      : alterTextProviderNotFound,
    entryType:
      "No pudimos obtener tu información de Siigo. <br /> Por favor, vuelve a intentarlo más tarde.",
  };

  const getText = (type) => {
    return (
      <>
        <p
          style={styles.description}
          dangerouslySetInnerHTML={{
            __html: text[type] || textDefault,
          }}
        />
        {type === "providerNotFound" && (
          <p style={styles.description}>
            Por favor, revisa que esté cargado en Siigo y vuelve a intentarlo.
          </p>
        )}
      </>
    );
  };

  return (
    <Modal show={state.open} onClose={onClose} centered>
      <Modal.Body style={styles.body}>
        <div>
          <div style={styles.buttonCloseContainer}>
            <IconButton onClick={onClose} title="Cerrar">
              <CloseIcon />
            </IconButton>
          </div>
          <RejectedIcon fill="#FEF0C7" stroke="#DC6803" />
        </div>
        <div style={{ width: "100%" }}>
          <p style={styles.title}>{title[state.type] || titleDefault}</p>
        </div>

        <div style={styles.textContainer}>{getText(state.type)}</div>
        <div style={styles.buttonContainer}>
          <Button style={styles.button} onClick={onClose}>
            Entendido
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const styles = {
  body: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    minWidth: "300px",
    maxWidth: "400px",
  },
  buttonCloseContainer: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    margin: "0px",
  },
  description: {
    color: "#667085",
    fontWeight: 500,
    margin: "0px",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left",
  },
  buttonContainer: { width: "100%", display: "flex", justifyContent: "end" },
  button: {
    padding: "10px 18px 10px 18px",
    background: "#6938EF",
    borderColor: "#6938EF",
  },
  textContainer: {
    textAlign: "center",
    paddingBottom: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
};

export const styles = {
  title: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#101828",
  },
  subtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#667085",
    marginLeft: "2.5%",
  },
  statusOptions: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#667085",
    marginTop: "24px",
    marginLeft: "2.5%",
    // paddingBottom: "12px",
    // borderBottom: "1px solid #667085",
  },
  statusOptionsSelected: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#5925DC",
    marginTop: "24px",
    marginLeft: "2.5%",
    // paddingBottom: "12px",
    // borderBottom: "2px solid #7f56d9",
  },
  expirationContainer: {
    marginTop: "12px",
    height: "80px",
    display: "flex",
    flexDirection: "row",
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  expirationFirstItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    border: "1px solid #EAECF0",
  },
  expirationSecondItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTop: "1px solid #EAECF0",
    borderBottom: "1px solid #EAECF0",
    borderRight: "1px solid #EAECF0",
    backgroundColor: "#FCFCFD",
  },
  expirationThirdItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTop: "1px solid #EAECF0",
    borderBottom: "1px solid #EAECF0",
    backgroundColor: "#FCFCFD",
  },
  expirationLastItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    border: "1px solid #EAECF0",
    backgroundColor: "#FCFCFD",
  },
  expirationItemTitle: {
    color: "#667085",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "12px",
    paddingLeft: "12px",
    lineHeight: "20px",
  },
  expirationItemAmount: {
    color: "#101828",
    fontWeight: "600",
    fontSize: "20px",
    paddingLeft: "12px",
    lineHeight: "12px",
  },
  expirationExpiredTitle: {
    color: "#B42318",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "12px",
    paddingLeft: "12px",
    lineHeight: "20px",
  },
  footer: {
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #EAECF0",
    position: "fixed",
    width: "100%",
    bottom: "0",
    color: "white",
    fontSize: "25px",
    boxShadow: "0px -6px 16px -4px rgba(16, 24, 40, 0.08)",
    height: "64px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  footerElements: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  footerPrimaryButton: {
    backgroundColor: "#6938EF",
    borderRadius: "8px",
    height: "40px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  footerSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    height: "40px",
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    marginRight: "12px",
  },
  footerText: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    marginRight: "12px",
    display: "flex",
    alignSelf: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  titleContainer: {
    justifyContent: "space-between",
    display: "flex",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "16px",
    alignItems: "center",
    height: "40px",
  },
  titleButton: {
    backgroundColor: "#6938EF",
    borderRadius: "8px",
    height: "36px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
};

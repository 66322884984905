import { BackofficeCompanyProvider } from "./Backoffice/company";
import { BackofficeInvoiceProvider } from "./Backoffice/invoice";
import { BackofficePayrollProvider } from "./Backoffice/payroll";
import { BackofficeReceiptProvider } from "./Backoffice/receipts";
import {BackofficeReceiptServicesProvider} from "./Backoffice/receiptsServices"
import { BackofficeSupplierProvider } from "./Backoffice/suppliers";

const combineComponents = (...components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};

const providers = [
  BackofficeInvoiceProvider,
  BackofficeSupplierProvider,
  BackofficeCompanyProvider,
  BackofficeReceiptProvider,
  BackofficeReceiptServicesProvider,
  BackofficePayrollProvider,
];

export const BOMasterProvider = combineComponents(...providers);

import React, { useState } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { InfoIcon } from "assets";
import styles from "../company-switch-modal.module.css";
import { RequiredLabel, ValidationMessage } from "commons/components";
import { EmailInput } from "commons/inputs";
import { useForm, useWatch } from "react-hook-form";
import { PrimaryButton } from "commons/buttons";
import { Spinner } from "react-bootstrap";
import { openInNewTab } from "utils";
import { TERMS_AND_CONDITIONS_LINK } from "constants";

export const ManagerForm = ({ setContent, companyData, setCompanyData }) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState, control, setError, setValue } =
    useForm({
      defaultValues: {
        name: "",
        email: "",
      },
      mode: "onBlur",
    });
  const formValues = useWatch({ control });

  const sendInvitation = () => {
    setCompanyData({
      ...companyData,
      name: formValues.name,
      email: formValues.email,
    });
    setContent("managerConfirm");
  };

  return (
    <form>
      <Stack
        direction={"column"}
        alignItems="center"
        justifyContent="center"
        gap={"8px"}
      >
        <Stack
          direction={"column"}
          alignItems="center"
          justifyContent="flex-start"
          gap={"8px"}
          style={{ width: "100%" }}
        >
          <div className={styles["new-title"]}>
            Agrega los datos del gerente de la empresa
          </div>
          <div className={styles["manager-description"]}>
            Esta persona será administrador principal y creador de la cuenta.
          </div>
        </Stack>
        <Box className={styles["form"]}>
          <RequiredLabel
            label="Nombre del gerente"
            className={styles["label"]}
          />
          <TextField
            className={styles["input"]}
            size="small"
            variant="outlined"
            required={true}
            {...register("name", { required: true })}
            placeholder="Ejemplo: José Pérez"
            InputProps={{ style: { fontSize: 14 } }}
          />
          <ValidationMessage errors={formState.errors} field="name" />
          <RequiredLabel label="Ingresa su email" className={styles["label"]} />
          <EmailInput
            value={formValues.email}
            setValue={(value) => {
              setValue("email", value, { shouldValidate: true });
            }}
            register={register}
            required={true}
          />
          <ValidationMessage errors={formState.errors} field="email" />
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "8px",
            alignSelf: "flex-start",
          }}
        >
          <InfoIcon color="#667085" />
          <div className={styles["hint"]}>
            Este usuario no puede eliminarse dentro de Payana.
          </div>
        </div>
        <PrimaryButton
          action={handleSubmit(sendInvitation)}
          text={
            loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Enviar invitación"
            )
          }
          width={"100%"}
          style={{ marginTop: "16px", marginBottom: "16px" }}
          disabled={loading}
        />
        <div className={styles["terms"]}>
          Al registrarte estarás aceptando los{" "}
          <span
            style={{ color: "#6938EF", cursor: "pointer" }}
            onClick={() => openInNewTab(TERMS_AND_CONDITIONS_LINK)}
          >
            términos y condiciones y políticas de privacidad
          </span>
          .
        </div>
      </Stack>
    </form>
  );
};

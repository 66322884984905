import React from "react";

export const ChevronLeftIcon = ({
  color = "#667085",
  width = "24",
  height = "24",
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path
      d="M15 18L9 12L15 6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

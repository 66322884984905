export const styles = {
  inputLabel: {
    fontSize: "14px",
    cursor: "pointer",
    color: "#1D2939",
  },
  inputLabelLegend: {
    fontSize: "12px",
    fontWeight: "500",
    cursor: "pointer",
    color: "#667085",
  },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    textAlign: "left",
  },
  subLabel: {
    fontSize: "14px",
  },
  disclaimerContainer: {
    backgroundColor: "#FEF0C7",
    borderRadius: "8px",
    padding: "8px",
    color: "#7A2E0E",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "8px",
    maxWidth: "360px",
  },
  container: {
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: "16px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    alignSelf: "center",
  },
  formContainer: {
    overflow: "overlay",
    scrollbarGutter: "stable",
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: "16px",
    width: "100%",
    height: "100%",
  },
  fullHeight: {
    height: "100%",
  },
  centeredFormContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    position: "relative",
    paddingBottom: 10,
    width: "100%",
  },
  formTitle: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "700",
    marginBottom: "12px",
    maxWidth: "808px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "48px",
    alignSelf: "center",
    width: "100%",
  },
  centeredForm: {
    maxWidth: "808px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "48px",
    alignSelf: "center",
    width: "100%",
  },
  footer: {
    marginTop: "16px",
    justifyContent: "flex-end",
    gap: "16px",
  },
  background: {
    backgroundColor: "#FCFCFD",
    borderRadius: "8px",
    padding: "24px",
    width: "100%",
  },
  errorContainer: {
    background: "#FFFBFA",
    padding: "16px 15px",
    borderRadius: "8px",
    border: "1px solid #FDA29B",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "12px",
    maxWidth: "808px",
    alignSelf: "center",
  },
  errorRow: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  errorIcon: {
    width: "28.5px",
    height: "16.25px",
    marginTop: "-4px",
  },
  errorColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  errorSectionTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#B42318",
  },
  errorSectionText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#D92D20",
  },
};

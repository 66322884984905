const getValidationMessage = (type) => {
  if (type === "required") {
    return "Este campo no puede quedar incompleto.";
  }
  if (type === "unique") {
    return "El email ya esta registrado";
  }
  if (type === "email") {
    return "Por favor, ingresa un correo electrónico válido";
  }
  return type;
};

export { getValidationMessage };

export const ValidationMessage = ({ errors, field }) => {
  const error = errors[field];
  if (!error) {
    return null;
  }
  const message = error.message || getValidationMessage(error.type);
  return (
    <span style={{ color: "rgba(240, 68, 56, 1)", fontSize: 12 }}>
      {message}
    </span>
  );
};

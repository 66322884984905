import React from "react";

export function Tick({
  color = "#7F56D9",
  height = "24",
  width = "24",
  circle = true,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14608_1048)">
        {circle && (
          <rect y="0.5" width="24" height="24" rx="12" fill="#F2F3FF" />
        )}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0965 7.89004L9.9365 14.8L8.0365 12.77C7.6865 12.44 7.1365 12.42 6.7365 12.7C6.3465 12.99 6.2365 13.5 6.4765 13.91L8.7265 17.57C8.9465 17.91 9.3265 18.12 9.7565 18.12C10.1665 18.12 10.5565 17.91 10.7765 17.57C11.1365 17.1 18.0065 8.91004 18.0065 8.91004C18.9065 7.99004 17.8165 7.18004 17.0965 7.88004V7.89004Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_14608_1048">
          <rect y="0.5" width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

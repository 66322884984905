import { ListCheckIcon, ListXIcon } from "../../../../assets";
import { styles } from "../styles";
import { RoleChecklistItem } from "./RoleChecklistItem";

export const RoleChecklist = ({ role, label }) => {
  const roles = {
    manager: [
      {
        icon: <ListCheckIcon />,
        text: "Tiene acceso completo a todas las funcionalidades",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede causar facturas",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede crear usuarios",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede aprobar pagos",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede modificar la configuración de la empresa: nombres, cuentas bancarias",
      },
    ],
    admin: [
      {
        icon: <ListCheckIcon />,
        text: "Tiene acceso completo a todas las funcionalidades",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede causar facturas",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede crear usuarios",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede aprobar pagos",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede modificar la configuración de la empresa: nombres, cuentas bancarias",
      },
    ],
    colab: [
      {
        icon: <ListCheckIcon />,
        text: "Puede cargar y acceder a toda la sección de pagos y cobros",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede causar facturas",
      },
      {
        icon: <ListCheckIcon />,
        text: "Si se configura, solo puede pagar con aprobación de un administrador",
      },
      {
        icon: <ListXIcon />,
        text: "No puede crear usuarios",
      },
      {
        icon: <ListXIcon />,
        text: "No puede configurar ninguna información de la empresa",
      },
    ],
    accountant: [
      {
        icon: <ListCheckIcon />,
        text: "Puede revisar la información en modo lectura y descargar archivos de conciliación y soportes",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede causar facturas",
      },
      {
        icon: <ListXIcon />,
        text: "No puede crear usuarios",
      },
      {
        icon: <ListXIcon />,
        text: "No puede cargar ni sacar pagos",
      },
      {
        icon: <ListXIcon />,
        text: "No puede configurar ninguna información de la empresa",
      },
    ],
  };
  return (
    <div style={styles.roleChecklistContainer}>
      <p style={styles.roleChecklistTitle}>¿Qué puede realizar un {label}?</p>
      {roles[role?.toLowerCase()].map((currentRole) => (
        <RoleChecklistItem icon={currentRole.icon} text={currentRole.text} />
      ))}
    </div>
  );
};

import { format } from "date-fns";

export const boReceiptsColumns = [
  {
    field: "transaction_type",
    Header: "Tipo",
    headerName: "Tipo",
    accessor: "transaction_type",
    id: "transaction_type",
    Cell: ({ value }) => {
      if (value) {
        const title = {
          invoice: "Proveedor",
          payroll: "Nómina",
          collection: "Cobro",
        };
        return title[value];
      }
    },
  },
  {
    field: "provider_name",
    Header: "Beneficiario",
    headerName: "Beneficiario",
    accessor: "provider_name",
    id: "provider_name",
  },
  {
    field: "provider_document_number",
    Header: "Doc beneficiario",
    headerName: "Doc beneficiario",
    accessor: "provider_document_number",
    id: "provider_document_number",
  },
  {
    field: "bank_name",
    Header: "Banco destino",
    headerName: "Banco destino",
    accessor: "bank_name",
    id: "bank_name",
  },
  {
    field: "company_name",
    Header: "Empresa",
    headerName: "Empresa",
    accessor: "company_name",
    id: "company_name",
  },
  {
    field: "created_at",
    Header: "Fecha de pago",
    headerName: "Fecha de pago",
    accessor: "created_at",
    id: "created_at",
    Cell: ({ value }) => {
      if (value) {
        return format(new Date(value.slice(0, -1)), "dd/MM/yyyy  HH:mm 'hs'");
      }
    },
  },
  {
    field: "amount",
    Header: "Monto",
    headerName: "Monto",
    accessor: "amount",
    id: "amount",
  },
  {
    field: "payment_gateway",
    Header: "Gateway",
    headerName: "Gateway",
    accessor: "payment_gateway",
    id: "payment_gateway",
    Cell: ({ value }) => {
      if (value) {
        return value.toUpperCase();
      }
    },
  },
  {
    field: "transaction_hash",
    Header: "Código",
    headerName: "Código",
    accessor: "transaction_hash",
    id: "transaction_hash",
    Cell: ({ value }) => {
      if (value) {
        return value.slice(0, 4).toUpperCase();
      }
    },
  },
  {
    field: "status",
    Header: "Estado",
    headerName: "Estado",
    accessor: "status",
    id: "status",
  },
  {
    field: "status_payment_gateway",
    Header: "Estado Cobre",
    headerName: "Estado Cobre",
    accessor: "status_payment_gateway",
    id: "status_payment_gateway",
  },
  {
    field: "file",
    Header: "Doc. soporte",
    headerName: "Doc. soporte",
    accessor: "file",
    id: "file",
  },
];

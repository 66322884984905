import React from "react";

export const PaymentsIcon = () => (
  <svg
    width="19"
    height="23"
    viewBox="0 0 19 23"
    fill="none"
    style={{ marginRight: "6px" }}
  >
    <path
      d="M10.5 1.08691H13.2C14.8802 1.08691 15.7202 1.08691 16.362 1.41389C16.9265 1.70151 17.3854 2.16046 17.673 2.72494C18 3.36668 18 4.20676 18 5.88691V16.2869C18 17.9671 18 18.8071 17.673 19.4489C17.3854 20.0134 16.9265 20.4723 16.362 20.7599C15.7202 21.0869 14.8802 21.0869 13.2 21.0869H6.8C5.11984 21.0869 4.27976 21.0869 3.63803 20.7599C3.07354 20.4723 2.6146 20.0134 2.32698 19.4489C2 18.8071 2 17.9671 2 16.2869V15.5869M14 12.0869H9.5M14 8.08691H10.5M14 16.0869H6M4 9.08691V3.58691C4 2.75849 4.67157 2.08691 5.5 2.08691C6.32843 2.08691 7 2.75849 7 3.58691V9.08691C7 10.7438 5.65685 12.0869 4 12.0869C2.34315 12.0869 1 10.7438 1 9.08691V5.08691"
      stroke="#6938EF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

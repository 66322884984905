import { Select } from "@mui/material";
import React, { useState } from "react";

export const SelectInput = ({ name, onChange, children, className, sx }) => {
  const [state, setState] = useState(null);
  return (
    <Select
      className={className}
      name={name}
      size="small"
      displayEmpty={true}
      onChange={(e) => {
        setState(e.target.value);
        onChange(e);
      }}
      sx={{ ...stylesSelectedTaxs, ...sx }}
      value={state}
    >
      {children}
    </Select>
  );
};

const stylesSelectedTaxs = {
  fontSize: "13px",
  width: "100%",
  height: "100%",
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiSelect-select": {
    padding: "0px !important",
    width: "100%",
    height: "100%",
    maxHeight: "50px",
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  "& .MuiSvgIcon-root": {
    right: "-10px",
  },
};

import { AlegraLogo, SiigoLogo } from "../../assets/svg";
import { FEATURES } from "./features";

export const mockCompany = {
  name: "Payana",
  identification_number: "1235566", // nit
  tax_regime: "Común",
  name_cs: "Siigo",
  password_cs: "123456",
  signed_contract: true,
  user_cs: "payana_cs",
  people: [
    { id: 1, name: "Pola", email: "pola_capo@gmail.com" },
    { id: 2, name: "Cufa", email: "cufa_capo@gmail.com" },
    { id: 3, name: "Pela", email: "pela_capo@gmail.com" },
  ],
  id: 3,
};

export const mockCurrentUser = {
  name: "Cufa",
  lastname: "Varela",
  company_id: 3,
  email: "cufa_capo@gmail.com",
  phone: "159923847",
  id: 2,
  role: "administrator",
};

export const taxRegOptions = ["Simple", "Común"];

export const accountingSystems = [
  {
    name: "Siigo",
    logo: <SiigoLogo width={"58"} height={"32"} />,
  },
  {
    name: "Alegra",
    logo: <AlegraLogo />,
  },
];

export const PAGES_WITHOUT_NAVBAR = [
  "/sales-team",
  "/login",
  "/signup",
  "/change-password",
  "/restore-password",
  "/create-password",
  "/error",
  "/pay",
  "/welcome",
  "/companies",
];

export const HIDE_NAVBAR_MENU_OPTIONS = ["/pay", "/onboarding"];

export const HIDE_NAVBAR_MENU_USER = ["/onboarding"];

export const PAGES_WITH_SERVICE_INTERRUPTION_MODAL = [
  "/payments/invoices",
  "/payroll/salaries",
];

export { FEATURES };

import { Box, Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import { CollectionsButtonGroup } from "./CollectionsButtonGroup";
import { CollectionsContext, UserContext } from "../../../contexts";
import { useContext, useEffect } from "react";
import { objectIsEmpty } from "../../../utils/objectUtils";
import { AlertIcon, EmptyCheckIcon } from "../../../assets";
import { PrimaryButton } from "../../../commons/buttons";
import { getWppLink, openInNewTab } from "../../../utils";

export const CollectionsEmptyState = ({
  openUploadFileModal,
  openCollectionObligationModal,
  setReceiverDrawerIsOpen,
  handleSyncErp,
}) => {
  const { getMainReceiver, mainReceiver } = useContext(CollectionsContext);
  const { currentUserIsAdmin } = useContext(UserContext);

  const phoneNumber = "+573017678849";
  const message =
    "Hola! Me interesa conocer cómo funciona la solución de cobros de Payana.";

  useEffect(() => {
    getMainReceiver();
  }, []);

  return (
    <>
      <Box sx={styles.emptyStateBox}>
        <Stack spacing={2} p={4} sx={styles.emptyStateStack}>
          <Box>
            <Typography sx={styles.emptyStateTitle}>
              Envía tu primer cobro
            </Typography>
            <Typography sx={styles.emptyStateSubtitle}>
              Selecciona cómo ingresarás los detalles del cobro y de tu cliente.
            </Typography>
          </Box>

          {currentUserIsAdmin && objectIsEmpty(mainReceiver) && (
            <div style={styles.emptyStateInfoContainer}>
              <AlertIcon />
              <Typography sx={styles.emptyStateInfo}>
                Para empezar a recaudar,{" "}
                <span
                  style={styles.emptyStateInfoLink}
                  onClick={() => setReceiverDrawerIsOpen(true)}
                >
                  completa tu información bancaria
                </span>
                .
              </Typography>
            </div>
          )}
          <CollectionsButtonGroup
            openUploadFileModal={openUploadFileModal}
            openCollectionObligationModal={openCollectionObligationModal}
            handleSyncErp={handleSyncErp}
            disabled={objectIsEmpty(mainReceiver)}
          />
          <div
            style={{
              padding: 24,
              background: "#FCFAFF",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.06)",
              borderRadius: 8,
              border: "1px #D9D6FE solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "16px",
              display: "flex",
            }}
          >
            <div style={{ width: "100%" }}>
              <Typography sx={styles.emptyStateTitle}>
                ¿Cómo funciona?
              </Typography>
            </div>

            <div style={styles.emptyRow}>
              <div style={{ width: "24px", height: "24px" }}>
                <EmptyCheckIcon bgColor={"#D8D6FE"} color={"#5925DC"} />
              </div>
              <Typography sx={styles.emptyInfoText}>
                Importa tus cobros desde un archivo excel, manualmente o desde
                Siigo.
              </Typography>
            </div>
            <div style={styles.emptyRow}>
              <div style={{ width: "24px", height: "24px" }}>
                <EmptyCheckIcon bgColor={"#D8D6FE"} color={"#5925DC"} />
              </div>
              <Typography sx={styles.emptyInfoText}>
                Envia recordatorios de vencimiento a tus clientes por email y
                WhatsApp mejorando tu tasa de cobrabilidad.
              </Typography>
            </div>
            <div style={styles.emptyRow}>
              <div style={{ width: "24px", height: "24px" }}>
                <EmptyCheckIcon bgColor={"#D8D6FE"} color={"#5925DC"} />
              </div>
              <Typography sx={styles.emptyInfoText}>
                Ofrece múltiples medios de pago como PSE, Nequi, tarjeta de
                crédito y efectivo.
              </Typography>
            </div>
            <div style={styles.emptyRow}>
              <div style={{ width: "24px", height: "24px" }}>
                <EmptyCheckIcon bgColor={"#D8D6FE"} color={"#5925DC"} />
              </div>
              <Typography sx={styles.emptyInfoText}>
                Recibe la acreditacion de tus cobros desde Bancolombia y
                Davivienda en menos de dos horas.
              </Typography>
            </div>
          </div>
          {currentUserIsAdmin && (
            <PrimaryButton
              text="Quiero saber más"
              action={() => openInNewTab(getWppLink(phoneNumber, message))}
              width="100%"
            />
          )}
        </Stack>
      </Box>
    </>
  );
};

import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import ModalShell from "../PaymentModal/ModalShell";
import {
  LoaderSyncAlegra,
  AdviceAlegra,
  FormUploadCredential,
} from "./components";
import { SyncAlegra } from "../../../services";
import { toastOptions } from "../../../constants";
import { UserContext } from "../../../contexts";

export function AlegraIntegrationModal({
  visible,
  onClose = () => {},
  refreshInvoiceTable = () => {},
  showCredential = null,
  section = "",
}) {
  const navigate = useNavigate();
  const { currentCompany } = useContext(UserContext);
  const [step, setStep] = useState(1);

  const handleSetStep = (value) => {
    setStep(value);
  };

  const handleOnClose = () => {
    setStep(1);
    onClose();
  };

  const handleSyncAlegra = async () => {
    try {
      setStep(3);
      const res = await SyncAlegra();
      if (res?.message === "Successful synchronization with Alegra") {
        toast.success("¡Sincronización finalizada con éxito!", toastOptions);
        if (section === "invoices") {
          refreshInvoiceTable();
        } else {
          navigate("/payments", { replace: true });
        }
      } else if (
        res.message ===
        "Estamos sincronizando con Alegra, esto puede tardar unos minutos"
      ) {
        toast.success(
          "Estamos sincronizando con Alegra, actualice la página en unos minutos.",
          toastOptions
        );
      } else {
        toast.error(
          "Ocurrió un error durante la sincronización.",
          toastOptions
        );
      }
      handleOnClose();
    } catch (error) {
      toast.error("Ocurrió un error durante la sincronización.", toastOptions);
      handleOnClose();
    } finally {
      if (section === "invoices") {
        setStep(1);
        handleOnClose();
      }
    }
  };

  const handleNextStep = () => {
    if (
      currentCompany?.user_cs &&
      currentCompany?.password_cs &&
      currentCompany?.name_cs === "Alegra" &&
      !showCredential
    ) {
      handleSyncAlegra();
    } else {
      setStep(2);
    }
  };

  const formUploadCredential = (
    <FormUploadCredential
      onClose={handleOnClose}
      handleSyncAlegra={handleSyncAlegra}
    />
  );

  return (
    <ModalShell visible={visible}>
      {{
        1: (
          <AdviceAlegra
            onClose={handleOnClose}
            handleNextStep={handleNextStep}
          />
        ),
        2: formUploadCredential,
        3: <LoaderSyncAlegra />,
      }[step] || formUploadCredential}
    </ModalShell>
  );
}

import { SwitchWithText } from "commons";
import SettingsRow from "./SettingsRow";
import { styles } from "../styles";

const PARTIAL_PAYMENTS = "collection_checkout_partial_payments";
const RETENTIONS = "collection_checkout_retentions";

const CollectionsCheckoutConfiguration = ({ company, onChange }) => {
  return (
    <SettingsRow
      label="Cobros"
      subLabel="Configura los permisos que le asignarás a tus clientes."
    >
      <SwitchWithText
        text={<span style={styles.switchText}>Pagos parciales</span>}
        checked={company[PARTIAL_PAYMENTS]}
        setChecked={(value) => onChange(PARTIAL_PAYMENTS, value)}
      />
      <SwitchWithText
        text={<span style={styles.switchText}>Retenciones</span>}
        checked={company[RETENTIONS]}
        setChecked={(value) => onChange(RETENTIONS, value)}
      />
    </SettingsRow>
  );
};

export default CollectionsCheckoutConfiguration;

export const MiniLogoutIcon = ({ style = {} }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={style}>
    <path
      d="M9.66667 10.3343L13 7.00098M13 7.00098L9.66667 3.66764M13 7.00098H5M5 1.00098H4.2C3.0799 1.00098 2.51984 1.00098 2.09202 1.21896C1.7157 1.41071 1.40973 1.71667 1.21799 2.093C1 2.52082 1 3.08087 1 4.20098V9.80098C1 10.9211 1 11.4811 1.21799 11.909C1.40973 12.2853 1.71569 12.5912 2.09202 12.783C2.51984 13.001 3.0799 13.001 4.2 13.001H5"
      stroke="#475467"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

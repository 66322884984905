import React, { useState } from "react";
import { Stack } from "@mui/material";
import { TrackJS } from "trackjs";
import { ModalInfoIcon } from "assets";
import styles from "../company-switch-modal.module.css";
import { CreateCompany } from "services";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { Spinner } from "react-bootstrap";

export const ManagerConfirm = ({
  onClose,
  companyData,
  setCreatedCompany,
  setContent,
}) => {
  const [loading, setLoading] = useState(false);

  const createCompany = async () => {
    setLoading(true);
    try {
      const response = await CreateCompany(companyData);
      if (response.id) {
        setCreatedCompany(response);
        setContent("createNoManagerSuccess");
      } else if (
        response.response.data ===
        "Ya existe una empresa con ese número de identificación."
      ) {
        setContent("error");
      }
    } catch (error) {
      TrackJS.console.error(error);
      setContent("newCompany");
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
  };

  return (
    <Stack
      direction={"column"}
      alignItems="center"
      justifyContent="center"
      gap={"8px"}
    >
      <ModalInfoIcon />
      <Stack
        direction={"column"}
        alignItems="center"
        justifyContent="center"
        gap={"8px"}
        style={{ marginBottom: "12px" }}
      >
        <div className={styles["success-title"]}>
          ¿Estás seguro de invitar a {companyData.email} a tu empresa?
        </div>
      </Stack>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        gap={"16px"}
        style={{ width: "100%" }}
      >
        <SecondaryButton
          action={() => onClose()}
          text={loading ? <Spinner animation="border" size="sm" /> : "Cancelar"}
          width={"100%"}
          disabled={loading}
        />
        <PrimaryButton
          action={() => createCompany()}
          text={
            loading ? <Spinner animation="border" size="sm" /> : "Sí, invitar"
          }
          width={"100%"}
          disabled={loading}
        />
      </Stack>
    </Stack>
  );
};

import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { ArrowIcon, ExcelIcon, SiigoLogo } from "../../../assets";
import { styles } from "../styles";
import { permissions } from "constants";
import { useIsUserAllowedTo } from "utils";

export const CollectionsButtonGroup = ({
  openUploadFileModal,
  openCollectionObligationModal,
  disabled,
  handleSyncErp,
}) => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  return (
    <ButtonGroup
      orientation="vertical"
      fullWidth
      size="large"
      sx={{
        backgroundColor: "#ffffff",
        boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
        borderRadius: "8px",
      }}
    >
      <Button
        key="four"
        id="empty_button_create_payment"
        startIcon={<ArrowIcon stroke={"#000"} circle={false} />}
        sx={styles.disabledEmptyStateBtnInGroup}
        onClick={openCollectionObligationModal}
        disabled={disabled || !isUserAllowedTo(permissions.COLLECTIONS_CREATE)}
      >
        <Stack ml={2}>
          <Typography sx={styles.emptyStateButtonTitle}>
            Crea un cobro manualmente
          </Typography>
          <Typography sx={styles.emptyStateButtonSubtitle}>
            Fácil con sólo unos pocos pasos
          </Typography>
        </Stack>
      </Button>
      <Button
        key="three"
        id="empty_button_updload_payments_list"
        startIcon={<ExcelIcon width="24" height="24" />}
        sx={styles.disabledEmptyStateBtnInGroup}
        onClick={() => openUploadFileModal("CSV")}
        disabled={disabled || !isUserAllowedTo(permissions.COLLECTIONS_CREATE)}
      >
        <Stack ml={2}>
          <Typography sx={styles.emptyStateButtonTitle}>
            Importa un archivo
          </Typography>
          <Typography sx={styles.emptyStateButtonSubtitle}>
            Añade un archivo XLS, XLSX o CSV
          </Typography>
        </Stack>
      </Button>
      <Button
        key="one"
        id="empty_button_integration"
        startIcon={<SiigoLogo />}
        sx={{ ...styles.disabledEmptyStateBtnInGroup }}
        onClick={() => handleSyncErp()}
        disabled={disabled || !isUserAllowedTo(permissions.COLLECTIONS_CREATE)}
      >
        <Stack ml={2}>
          <Typography sx={styles.emptyStateButtonTitle}>
            Sincroniza Siigo
          </Typography>
          <Typography sx={styles.emptyStateButtonSubtitle}>
            Importa tus facturas por cobrar y clientes
          </Typography>
        </Stack>
      </Button>
    </ButtonGroup>
  );
};

import {
  ApprovedIcon,
  BancolombiaCircleIcon,
  DaviviendaIcon,
  GrupoAvalIcon,
  ModalWarningIcon,
  TimerIcon,
  WarningIcon,
} from "../../assets";
import { Button, Modal, Spinner } from "react-bootstrap";
import { TrackJS } from "trackjs";
import { NumericFormat } from "react-number-format";
import { styles } from "../../commons/modals/NotifyWompiModal/styles";
import { useEffect, useState } from "react";
import {
  NotifyCustomerPayTransactionHash,
  NotifyTransactionHash,
} from "../../services";
import { RejectedIcon } from "../../assets/svg/RejectedIcon";
import { getWppLink, openInNewTab } from "../../utils";
import { PrimaryButton } from "../buttons";
import { SUPPORT_NUMBER } from "constants";
import { APPROVED } from "constants";
import { IN_PROCESS } from "constants";
import { REJECTED } from "constants";

const TxReferenceModal = ({ onConfirm, section = null }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const txReference = queryParameters.get("reference");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const message =
    section === "customer"
      ? "Hola, estoy teniendo problemas abonando el pago que me enviaron por Payana"
      : "Hola, necesito ayuda con mi pago";

  useEffect(() => {
    if (!txReference) {
      return;
    }
    setLoading(true);
    const payload = { hash: txReference };
    section === "customer"
      ? NotifyCustomerPayTransactionHash(payload)
          .then((response) => setResponse(response.data))
          .catch((error) => {
            TrackJS.console.error(error.message);
          })
          .finally(() => {
            setLoading(false);
          })
      : NotifyTransactionHash(payload)
          .then((response) => setResponse(response.data))
          .catch((error) => {
            TrackJS.console.error(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
  }, []);

  const id = response?.transaction?.id;
  const amount = response?.transaction?.amount;
  const companyName = response?.transaction?.company_name;
  const isApproved = response?.transaction?.status === APPROVED;
  const isInProcess = response?.transaction?.status === IN_PROCESS;
  const isRejected = response?.transaction?.status === REJECTED;
  const isBlocked =
    response?.transaction?.transactionResultDescription ===
      "TRANSACCIÓN DECLINADA POR EL PREAUTORIZADOR, SOSPECHA DE FRAUDE" ||
    response?.transaction?.transactionResultDescription ===
      "TRANSACCIÓN RECHAZADA POR SOSPECHA DE FRAUDE EN ENTIDAD BANCARIA";

  const handleConfirm = () => {
    section === "customer"
      ? onConfirm(id, response?.rows?.at(0)?.customer_hash)
      : onConfirm(id, response?.transaction?.type);
  };

  return (
    <Modal show={!!txReference} centered>
      <Modal.Body style={styles.column}>
        {loading ? (
          <Spinner animation="border" variant="secondary" />
        ) : isBlocked ? (
          <BlockedModal handleConfirm={handleConfirm} />
        ) : isApproved ? (
          section === "customer" ? (
            <CustomerApprovedModal
              handleConfirm={handleConfirm}
              amount={amount}
              hash={response?.transaction?.hash?.toUpperCase()}
              companyName={companyName}
            />
          ) : (
            <ApprovedModal
              handleConfirm={handleConfirm}
              amount={amount}
              hash={response?.transaction?.hash?.toUpperCase()}
            />
          )
        ) : isInProcess ? (
          <InProcessModal handleConfirm={handleConfirm} />
        ) : isRejected ? (
          <RejectedModal handleConfirm={handleConfirm} />
        ) : (
          <SorryModal
            handleConfirm={handleConfirm}
            phoneNumber={SUPPORT_NUMBER}
            message={message}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

function ApprovedModal({ handleConfirm, amount, hash }) {
  return (
    <>
      <ApprovedIcon />
      <p style={styles.modalTitle}>¡Pago exitoso!</p>
      <p style={styles.modalAmount}>
        <NumericFormat
          value={amount}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          decimalScale={2}
        />
      </p>
      <p style={styles.reference}>Código de transacción: {hash}</p>
      <p style={styles.modalText}>El dinero enviado a cuentas de</p>
      <div style={styles.modalBankRow}>
        <div style={styles.modalContainer}>
          <BancolombiaCircleIcon />
          <span style={styles.modalBank}>Bancolombia</span>
        </div>
        <div style={styles.modalContainer}>
          <DaviviendaIcon />
          <span style={styles.modalBank}>Davivienda</span>
        </div>
      </div>
      <div style={styles.modalBankRow}>
        <div style={styles.modalContainer}>
          <GrupoAvalIcon />
          <span style={styles.modalBank}>Grupo Aval</span>
          <div style={styles.modalNewTagContainer}>
            <span style={styles.modalNewTag}>NUEVO</span>
          </div>
        </div>
      </div>
      <p style={styles.modalText}>
        se acreditará en las <strong>próximas 2 horas.</strong>
      </p>
      <p style={styles.modalText}>
        El dinero enviado a otros bancos se acreditará de acuerdo a los{" "}
        <strong>tiempos de procesamiento de ACH.</strong>
      </p>
      <p style={styles.modalFooterText}>Payana procesa pagos todos los días</p>
      <PrimaryButton text="Entendido" action={handleConfirm} width="100%" />
    </>
  );
}

function CustomerApprovedModal({ handleConfirm, amount, hash, companyName }) {
  return (
    <>
      <ApprovedIcon />
      <p style={styles.modalTitle}>¡Pago exitoso!</p>
      <p style={styles.modalAmount}>
        <NumericFormat
          value={amount}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
          decimalScale={2}
        />
      </p>
      <p style={styles.reference}>Código de transacción: {hash}</p>
      <p style={styles.modalText}>
        Le informaremos a <strong>{companyName}</strong>
      </p>
      <p style={styles.modalText}>que el pago fue realizado.</p>
      <Button
        variant="primary"
        style={styles.modalPrimaryButton}
        onClick={handleConfirm}
        width="90%"
      >
        Entendido
      </Button>
    </>
  );
}

function SorryModal({ handleConfirm, phoneNumber, message }) {
  return (
    <>
      <WarningIcon />
      <p style={styles.modalTitle}>¡Lo sentimos!</p>
      <p style={styles.modalDeclinedText}>
        En este momento no es posible realizar el pago. Intenta nuevamente más
        tarde. Si el problema continúa, contáctanos al{" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => openInNewTab(getWppLink(phoneNumber, message))}
        >
          <strong>+57 317 4724545</strong>
        </span>
        .
      </p>
      <Button
        variant="primary"
        style={styles.modalPrimaryButton}
        onClick={handleConfirm}
      >
        Entendido
      </Button>
    </>
  );
}

function InProcessModal({ handleConfirm }) {
  return (
    <>
      <TimerIcon />
      <p style={styles.modalTitle}>Pago pendiente de confirmación</p>
      <p style={styles.modalDeclinedText}>
        Estamos esperando la confirmación del pago. Usualmente tarda segundos,
        pero
        <strong> en algunos casos puede tomar hasta 10 minutos</strong>.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          width: "100%",
        }}
      >
        <Button
          variant="primary"
          style={styles.modalPrimaryButton}
          onClick={handleConfirm}
        >
          Entendido
        </Button>
      </div>
    </>
  );
}

function BlockedModal({ handleConfirm }) {
  return (
    <div style={styles.modalBlockedContainer}>
      <div style={{ alignSelf: "center" }}>
        <ModalWarningIcon />
      </div>
      <p style={styles.modalBlockedTitle}>Alerta de transacción rechazada</p>
      <p style={styles.modalBlockedText}>
        Su banco está bloqueando la transacción. No intente nuevamente, ya que
        su cuenta podría ser bloqueada. Por favor, póngase en contacto con
        nosotros para asistencia.
      </p>
      <PrimaryButton width="100%" action={handleConfirm} text="Entendido" />
    </div>
  );
}

function RejectedModal({ handleConfirm }) {
  return (
    <>
      <RejectedIcon />
      <p style={styles.modalTitle}>Transacción rechazada</p>
      <p style={styles.modalDeclinedText}>
        Por favor escríbenos si necesitas ayuda para realizar el pago.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          width: "100%",
        }}
      >
        <Button
          variant="primary"
          style={styles.modalPrimaryButton}
          onClick={handleConfirm}
        >
          Entendido
        </Button>
        <Button variant="light" style={styles.secondaryButton}>
          Necesito ayuda
        </Button>
      </div>
    </>
  );
}

export { TxReferenceModal };

import { Stack } from "@mui/material";
import { NewServicePayment } from "assets";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage, saveInLocalStorage } from "utils";

export const CommunicationServicesPayment = () => {
  const navigate = useNavigate();

  const [showServicesPaymentModal, setShowServicesPaymentModal] = useState(
    getFromLocalStorage("showServicesPaymentModal") || "true"
  );

  const isShowServicesPaymentModal = showServicesPaymentModal !== "false";

  return (
    <Modal
      show={isShowServicesPaymentModal}
      onHide={() => {
        setShowServicesPaymentModal("false");
        saveInLocalStorage("showServicesPaymentModal", "false");
      }}
    >
      <Modal.Body>
        <Stack
          style={{
            padding: "24px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                width: "86px",
                padding: "8px 10px",
                borderRadius: "72px",
                background: "rgba(122, 90, 248, 1)",
                color: "#FFFF",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: " 28px",
                marginBottom: "24px",
              }}
            >
              ¡Nuevo!
            </p>
          </div>

          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "8px",
              marginBottom: "16px",
            }}
          >
            Pago de Servicios con agenda de vencimiento
          </p>
          <p
            style={{
              color: "#667085",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "21px",
              letterSpacing: "-0.14px",
              marginBottom: "16px",
            }}
          >
            Consulta todas tus deudas en un solo lugar y confirma el pago.
            <br />
            Para tus próximos vencimientos, las cuentas se cargarán
            <br />
            automáticamente.
          </p>
          <NewServicePayment />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <SecondaryButton
              text="Explorar más tarde"
              width="100%"
              style={{ marginTop: "24px" }}
              action={() => {
                setShowServicesPaymentModal("false");
                saveInLocalStorage("showServicesPaymentModal", "false");
              }}
            />
            <PrimaryButton
              text="Ir a servicios"
              width="100%"
              style={{ marginTop: "24px" }}
              action={() => {
                setShowServicesPaymentModal("false");
                saveInLocalStorage("showServicesPaymentModal", "false");
                navigate("/services/payments", { replace: true });
              }}
            />
          </div>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

import { requestContainer } from "../requestContainer";

export function GetBOReceiptsServices({
  pageIndex,
  status = "all",
  serviceId = "all",
  companyId = "all",
  transactionHash = "",
}) {
  return requestContainer({
    method: "get",
    url: `/backoffice/services?page=${pageIndex}&status=${status}&service_id=${serviceId}&company_id=${companyId}&transaction_hash=${transactionHash}`,
  });
}

export async function GetBOReceiptServices({ id }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/services/${id}`,
  });
}

export function GetBOTotalReceiptsServices({ status, serviceId, companyId }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/service/total?status=${status}&service_id=${serviceId}&company_id=${companyId}`,
  });
}

export function SearchServices({ query }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/search/services?q=${query}`,
  });
}

export function SearchServicesCompany({ companyId }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/invoiceservice/search?company_id=${companyId}`,
  });
}

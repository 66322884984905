import React, { useContext, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
  Collapse,
} from "@mui/material";
import { styles } from "../Login/styles";
import { PayanaLogo } from "../../assets";
import { Button } from "react-bootstrap";
import { requestContainer } from "../../services/api/requestContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../../constants";
import { UserContext } from "../../contexts";
import { getPasswordError } from "../../commons/forms/validation";
import { addTrackingMetadata } from "../../utils/addTrackingMetadata";

const makeRequest = async (password, hash) => {
  const response = await requestContainer({
    method: "post",
    url: "/change-password",
    data: {
      password,
      hash,
    },
  });
  return response;
};

export const ChangePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { login } = useContext(UserContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const hash = searchParams.get("hash");

  const loginUser = (email, pass) => {
    login(email, pass).then((res) => {
      if (res.status === 200) {
        addTrackingMetadata(res.user);
        if (res.user.backoffice_user) {
          navigate("/backoffice-receipts", { replace: true });
        } else {
          navigate("/payments/invoices", { replace: true });
        }
      }
    });
  };

  const restorePassword = async () => {
    if (password.length < 8) {
      return setError("La contraseña debe tener al menos 8 caracteres.");
    }
    if (password !== confirmPassword) {
      return setError("Las contraseñas no coinciden.");
    }
    try {
      setLoading(true);
      const { user } = await makeRequest(password, hash);
      setIsSuccess(true);
      toast.success("¡La contraseña se actualizó con éxito!", toastOptions);
      loginUser(user.email, password);
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
    setError("");
    setLoading(false);
  };

  return (
    <Container>
      <Stack justifyContent="center" alignItems="center" mt={9}>
        <PayanaLogo width={190} height={42} />

        <Stack
          spacing={3}
          sx={{
            width: 360,
          }}
        >
          <Box>
            <Typography sx={styles.title} mb={4} mt={4}>
              Recupera tu contraseña
            </Typography>
          </Box>
          <Box>
            <Stack
              mb={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={styles.label} noWrap>
                Ingresar nueva contraseña
              </Typography>
            </Stack>
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              type="password"
              onChange={(e) => {
                if (!getPasswordError(e.target.value)) {
                  setPassword(e.target.value);
                }
              }}
              size="small"
              disabled={isSuccess}
              value={password}
            />
          </Box>
          <Box>
            <Stack
              mb={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={styles.label} noWrap>
                Reingresar la contraseña
              </Typography>
            </Stack>
            <TextField
              variant="outlined"
              type="password"
              fullWidth
              onChange={(e) => setConfirmPassword(e.target.value)}
              size="small"
              disabled={isSuccess}
            />
          </Box>
          <Collapse in={!!error}>
            <Typography sx={styles.errorLabel}>{error}</Typography>
          </Collapse>
          {!loading && (
            <Button
              disabled={!password || isSuccess}
              variant="primary"
              style={styles.primaryButton}
              onClick={() => restorePassword()}
            >
              Confirmar
            </Button>
          )}
          {loading && (
            <Button
              variant="primary"
              style={styles.primaryLoadingButton}
              onClick={() => {}}
            >
              <CircularProgress size={22} color="inherit" />
            </Button>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export const MarkIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11331_54258)">
        <path
          d="M18.3333 9.2381V10.0048C18.3323 11.8018 17.7504 13.5503 16.6744 14.9896C15.5984 16.4289 14.086 17.4818 12.3628 17.9914C10.6395 18.5009 8.79768 18.4397 7.11202 17.8169C5.42636 17.1942 3.98717 16.0432 3.00909 14.5357C2.03101 13.0281 1.56645 11.2448 1.68469 9.4517C1.80293 7.65857 2.49763 5.95171 3.66519 4.58566C4.83275 3.21962 6.41061 2.26759 8.16345 1.87156C9.91629 1.47553 11.7502 1.65671 13.3916 2.3881M18.3333 3.33333L9.99996 11.675L7.49996 9.175"
          stroke="#039855"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11331_54258">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { getWppLink, openInNewTab } from "utils";
import { styles } from "./styles";

const ContactInfo = ({ customer }) => {
  return (
    <div style={styles.contactInfoContainer}>
      <p style={styles.contactInfoText}>
        ¿Tienes alguna consulta? Contáctenos por email a{" "}
        <span
          style={styles.contactInfoLink}
          onClick={() => openInNewTab(`mailto:${customer?.customer_email}`)}
        >
          {customer?.customer_email}
        </span>{" "}
        o por teléfono al{" "}
        <span
          style={styles.contactInfoLink}
          onClick={() => openInNewTab(getWppLink(customer?.customer_phone))}
        >
          {customer?.customer_phone}
        </span>
      </p>
      <p style={styles.contactInfoText}>
        Cobranza gestionada a través de{" "}
        <span
          style={styles.contactInfoLink}
          onClick={() => openInNewTab("https://payana.la/")}
        >
          Payana
        </span>
      </p>
    </div>
  );
};

export { ContactInfo };

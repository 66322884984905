import React from "react";

export const ExitIcon = ({ width = "42", height = "42" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.75 12.25L12.25 29.75M12.25 12.25L29.75 29.75"
        stroke="#D92D20"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

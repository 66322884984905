import React, { useContext, useEffect, useState } from "react";
import ModalShell from "../PaymentModal/ModalShell";
import {
  saveDianData,
  getDianData,
  saveDianDataURL,
  deliverAccessToken,
  interpretError,
} from "../../../services/api/dian";
import Steps from "./Steps";
import { UserContext } from "../../../contexts";
import "./dian-integration-modal.css";

const DianIntegrationModal = ({
  visible,
  setIsDianModalVisible,
  refreshInvoiceTable,
  setSyncBanner = () => {},
}) => {
  const { currentCompany, dianUserType, setDianUserType, getMe } =
    useContext(UserContext);

  const initialStep =
    dianUserType === "legal" ? 2 : dianUserType === "natural" ? 1 : 0;

  const [retry, setRetry] = useState(1);
  const [step, setStep] = useState(initialStep);
  const [dianData, setDianData] = useState({
    companyDocumentNumber: currentCompany?.identification_number,
  });
  const [credentialsError, setCredentialsError] = useState(false);
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState({});

  const showErrorStep = ({ data, title, description, button, action }) => {
    setData(data);
    setCredentialsError(true);
    setErrorMessage({
      title,
      description,
      button,
      action,
    });
    setStep(7);
  };

  const handleInputs = async (data) => {
    setStep(5);
    const res = await saveDianData(data);
    if (res) {
      setDianData(res);
      try {
        await deliverAccessToken();
        setStep(2);
      } catch (error) {
        if (error.message === "INVALID_CREDENTIALS") {
          handleErrorMessage["invalidCredentials"](data);
        } else {
          handleErrorMessage["timeout"](data);
        }
      }
    }
  };

  const handleErrorMessage = {
    timeout: (data) => {
      showErrorStep({
        data,
        title: "No pudimos importar tu información.",
        description: "Por favor intenta de nuevo más tarde.",
        button: "Reintentar",
        action: () => setStep(2),
      });
    },
    invalidCredentials: (data) => {
      showErrorStep({
        data,
        title: "Credenciales incorrectas",
        description: "Revisa la información y vuelve a intentarlo.",
        button: "Ingresar Token", //TODO: Revertir a "Revisar información" cuando se corrija el flujo
        action: () => setStep(2), //TODO: Revertir a 1 cuando se corrija el flujo
      });
    },
    expiredCredentials: (data) => {
      showErrorStep({
        data,
        title: "Credenciales incorrectas",
        description: "Revisa la información y vuelve a intentarlo.",
        button: "Revisar información",
        action: () => setStep(1),
      });
    },
    invalidFormatCredentials: (data) => {
      showErrorStep({
        data,
        title: "El formato de token que ingresaste no es válido.",
        description:
          "Por favor, revisa el link que pegaste y vuelve a intentarlo.",
        button: "Revisar token",
        action: () => setStep(2),
      });
    },
    expiredToken: () => {
      showErrorStep({
        data: {},
        title: "Token vencido",
        description: "Por favor, reenvía el token y vuelve a intentarlo.",
        button: "Reenviar token",
        action: () => handleResendAccessToken(),
      });
    },
  };

  const handleSuccess = ({ created }) => {
    getMe();
    refreshInvoiceTable();
    setSyncBanner((syncBanner) => {
      return {
        ...syncBanner,
        visible: true,
        type: "success",
        title:
          created > 0
            ? `Se han creado ${created} documentos.`
            : "No se encontraron nuevos documentos.",
      };
    });
    setStep(2);
    close();
  };

  const importInvoices = (data) => {
    return saveDianDataURL(data)
      .then((res) => {
        if (res?.response?.data?.error_code) {
          throw new Error(res?.response?.data?.error_code);
        }
        if (res?.response?.status && res?.response?.status !== 200) {
          throw new Error("GENERIC_ERROR");
        }
        return res;
      })
      .then(async (syncResponse) => {
        const updatedDianData = await getDianData();
        setDianData({
          documentType: updatedDianData.id_type,
          documentNumber: updatedDianData.nit_representante,
          companyDocumentNumber: updatedDianData.nit_empresa,
          isFirstTime: updatedDianData.is_first_time,
        });
        return syncResponse;
      })
      .catch((error) => {
        if (error?.message === "INVALID_FORMAT_CREDENTIALS") {
          setData(data);
          throw new Error(error?.message);
        }

        if (error.message === "ERROR_EXPIRED_TOKEN") {
          setData(data);
          throw new Error(error?.message);
        }
        throw error;
      });
  };
  const close = () => {
    setIsDianModalVisible(false);
    setCredentialsError(false);
    setTimeout(() => {
      if (
        (dianData.documentNumber && dianData.documentType) ||
        dianUserType === "natural"
      ) {
        setStep(2);
      }
    }, 500);
  };
  const notifyResponse = (response) => {
    handleSuccess({ created: response.created });
  };

  const handleError = (error, data) => {
    const invalidCredentials = () => {
      handleErrorMessage["invalidCredentials"](data);
    };
    const expiredCredentials = () => {
      handleErrorMessage["expiredCredentials"](data);
    };
    const timeout = () => {
      if (retry === 0) {
        handleErrorMessage["timeout"](data);
      } else {
        setStep(6);
      }
    };
    const genericError = () => {
      handleErrorMessage["timeout"](data);
    };
    const invalidFormatCredentials = () => {
      handleErrorMessage["invalidFormatCredentials"](data);
    };
    const errorExpiredToken = () => {
      handleErrorMessage["expiredToken"](data);
    };

    const errorHandlers = {
      invalidCredentials,
      expiredCredentials,
      timeout,
      genericError,
      invalidFormatCredentials,
      errorExpiredToken,
    };

    interpretError(error, errorHandlers);
  };

  const resendAccessToken = async () => {
    setStep(5);
    setRetry((prevState) => prevState.retry - 1);

    try {
      const res = await deliverAccessToken();
      if (res) {
        setStep(2);
      }
    } catch (error) {
      if (error.message === "INVALID_CREDENTIALS") {
        handleErrorMessage["invalidCredentials"](data);
      } else {
        handleErrorMessage["timeout"](data);
      }
    }
  };

  const primaryAction = async (data) => {
    try {
      if (step === 1) {
        await handleInputs(data);
      } else if (step === 2) {
        setStep(3);
        await importInvoices(data).then(notifyResponse).then(close);
        setStep(2);
      } else if (step === 4 || step === 6) {
        await resendAccessToken();
      } else {
        setStep(1);
      }
    } catch (error) {
      handleError(error, data);
    }
  };

  const editDianData = () => setStep(1);

  const handleResendAccessToken = async () => {
    try {
      await resendAccessToken();
    } catch (error) {
      handleError(error, data);
    }
  };

  const initStateWithDianCredential = (credential) => {
    if (credential) {
      setStep(2);
      setDianData({
        documentType: credential.id_type,
        documentNumber: credential.nit_representante,
        companyDocumentNumber: credential.nit_empresa,
        isFirstTime: credential.is_first_time,
      });
    } else if (!credential && currentCompany?.last_sync_at) {
      setStep(2);
      setDianUserType("natural");
    }
  };

  useEffect(() => {
    getDianData().then(initStateWithDianCredential);
  }, []);

  useEffect(() => {
    const shouldAutocompleteCompanyDocumentNumber =
      currentCompany.identification_number && !dianData.companyDocumentNumber;
    if (shouldAutocompleteCompanyDocumentNumber) {
      setDianData({
        companyDocumentNumber: currentCompany.identification_number,
      });
    }
    const hasCredential =
      dianData.documentType &&
      dianData.documentNumber &&
      dianData.companyDocumentNumber;

    if (visible && hasCredential) {
      resendAccessToken();
    }
  }, [currentCompany, visible]);

  return (
    <ModalShell visible={visible}>
      <Steps
        step={step}
        setStep={setStep}
        primaryAction={primaryAction}
        dianData={dianData}
        cancel={close}
        editDianData={editDianData}
        resendAccessToken={handleResendAccessToken}
        credentialsError={credentialsError}
        setCredentialsError={setCredentialsError}
        data={data}
        errorMessage={errorMessage}
      />
    </ModalShell>
  );
};

export { DianIntegrationModal };

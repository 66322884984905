import React, { useMemo, useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  ChangeStatusModal,
  ExpandItemContainer,
  SendEmailModal,
  Table,
  TableSkeleton,
} from "../../../commons";
import NotesModal from "../../../commons/backofficeComponents/NotesModal";
import {
  boReceiptsColumns,
  filterNITOptions,
  filterOptions,
  phoneChars,
} from "../../../utils";
import {
  SearchAllEmployees,
  SearchAllSuppliers,
  sendChatMessage,
} from "../../../services";
import { UserContext } from "../../../contexts";
import { TableEmptyState } from "../../../commons/Layout/EmptyState";
import { styles } from "../../ReceiptsScreen/styles";
import { StatusFilter } from "../../ReceiptsScreen";
import {
  GetBOTotalReceipts,
  SearchBOCompanies,
  sendChatMessagePayroll,
  sendReceiptToWPP,
} from "../../../services/api/backoffice";
import { BackofficeReceiptContext } from "../../../contexts/Backoffice/receipts";
import { toastOptions } from "../../../constants";

import {
  handleFormatedMessageToChat,
  handleFormattedMessageChat,
  handlePayrollFormattedMessageChat,
} from "./utils";
import {
  HelpIcon,
  PhoneIcon,
  SearchIcon,
  SendIcon,
  MsgIcon,
} from "../../../assets";
import { SupplierDetails } from "../../../commons/backofficeComponents";
import { ReceiptDetails } from "commons/Sidebars";
import { EmployeeDetails } from "commons/modals/EmployeeDetails";
import { CustomerDetails } from "commons/modals/CustomerDetails";

export const BOReceiptsScreen = (props) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [totals, setTotal] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const {
    BOreceipts,
    getBOreceipts,
    statusModalIsOpen,
    setStatusModalIsOpen,
    changeBOReceiptStatus,
    updateSendSupport,
    statusSelected,
    setStatusSelected,
    providerIdSelected,
    setProviderIdSelected,
    companyIdSelected,
    setCompanyIdSelected,
    hashSelected,
    setHashSelected,
    typeSelected,
    setTypeSelected,
    employeeIdSelected,
    setEmployeeIdSelected,
  } = useContext(BackofficeReceiptContext);
  const [notesModalIsOpen, setNotesModalIsOpen] = useState(false);
  const [currentNoteId, setCurrentNoteId] = useState(0);
  const [detailsModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [selectedTrx, setSelectedTrx] = useState();
  const [sendEmailModalIsOpen, setSendEmailModalIsOpen] = useState(false);
  const [suppliersOptions, setSuppliersOptions] = useState([]);
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedTrxStatus, setSelectedTrxStatus] = useState(null);
  const [selectedTrxType, setSelectedTrxType] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [employeeDrawerIsOpen, setEmployeeDrawerIsOpen] = useState(false);
  const [customerDrawerIsOpen, setCustomerDrawerIsOpen] = useState(false);
  const [providerSelected, setProviderSelected] = useState({});
  const [employeeSelected, setEmployeeSelected] = useState({});
  const [companySelected, setCompanySelected] = useState({});
  const [companySearch, setCompanySearch] = useState("");

  useEffect(() => {
    if (!currentUser.backoffice_user) {
      navigate("/payments", { replace: true });
    }
  }, []);

  const [isLoadingScreen, setIsLoadingScreen] = useState(false);

  useEffect(() => {
    setIsLoadingScreen(true);
    Promise.all([
      getBOreceipts({
        pageIndex,
        status: statusSelected,
        providerId: providerIdSelected,
        companyId: companyIdSelected,
        transactionHash: hashSelected,
        employeeId: employeeIdSelected,
        type: typeSelected,
      }),
      GetBOTotalReceipts({
        status: statusSelected,
        providerId: providerIdSelected,
        companyId: companyIdSelected,
        transactionHash: hashSelected,
        employeeId: employeeIdSelected,
        type: typeSelected,
      }).then((res) => {
        setTotal(res);
      }),
    ]).finally(() => {
      setIsLoadingScreen(false);
    });
  }, [
    pageIndex,
    statusSelected,
    providerIdSelected,
    companyIdSelected,
    hashSelected,
    employeeIdSelected,
    typeSelected,
  ]);

  const openSupplierDrawer = (id) => {
    setDrawerIsOpen(true);
    setSelectedSupplier(id);
  };

  const openEmployeeDrawer = (id) => {
    setEmployeeDrawerIsOpen(true);
    setSelectedEmployee(id);
  };

  const openCustomerDrawer = (id) => {
    setCustomerDrawerIsOpen(true);
    setSelectedCustomer(id);
  };

  const getSuppliersOptions = (query) => {
    SearchAllSuppliers(query).then((res) => {
      setSuppliersOptions(res);
    });
  };

  const getEmployeesOptions = (query) => {
    SearchAllEmployees(query).then((res) => {
      setEmployeesOptions(res);
    });
  };

  const handleSupplierSearchChange = (sup) => {
    if (sup) {
      setProviderIdSelected(sup.id);
      setProviderSelected(sup);
    } else {
      setProviderIdSelected("all");
      setProviderSelected({});
    }
    setPageIndex(1);
  };

  const handleEmployeeSearchChange = (emp) => {
    if (emp) {
      setEmployeeIdSelected(emp.id);
      setEmployeeSelected(emp);
    } else {
      setEmployeeIdSelected("all");
      setEmployeeSelected({});
    }
    setPageIndex(1);
  };

  useEffect(() => {
    if (totals && totals.total > 50) {
      setTotalPages(Math.ceil(totals.total / 50));
    } else {
      setTotalPages(1);
    }
  }, [totals]);

  const columns = useMemo(() => boReceiptsColumns, []);

  const openDetailsModal = (id) => {
    setSelectedTrx(id);
    setDetailModalIsOpen(true);
  };

  const openSendEmailModal = (id, type) => {
    setSelectedTrx(id);
    setSelectedTrxType(type);
    setSendEmailModalIsOpen(true);
  };

  const openStatusModal = (id, status) => {
    setSelectedTrx(id);
    setSelectedTrxStatus(status);
    setStatusModalIsOpen(true);
  };

  const handleCompanySearchChange = (company) => {
    if (company) {
      setCompanyIdSelected(company.id);
      setCompanySelected(company);
    } else {
      setCompanyIdSelected("all");
      setCompanySelected({});
      setCompanySearch("");
    }
    setPageIndex(1);
  };

  const handleThirdSearchChange = (hash) => {
    hash ? setHashSelected(hash.toUpperCase()) : setHashSelected("");
  };

  const getCompanySearchOptions = (query) => {
    setCompanySearch(query);
    SearchBOCompanies(query).then((res) => {
      setCompaniesOptions(res);
    });
  };

  const handleRequestHelp = async (row) => {
    const type = row.provider_name ? "invoice" : "payroll";
    const messageFormatted =
      type === "invoice"
        ? handleFormattedMessageChat(row)
        : handlePayrollFormattedMessageChat(row);
    const res =
      type === "invoice"
        ? await sendChatMessage(messageFormatted)
        : await sendChatMessagePayroll(messageFormatted);
    if (res.result === "ok") {
      updateSendSupport(row.id, pageIndex);
      toast.success("El mensaje se envió con exito!", toastOptions);
    } else {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
  };

  const handleDisabledMSG = ({ provider_phone_number, status, file_path }) => {
    if (
      provider_phone_number &&
      (provider_phone_number.length < phoneChars(provider_phone_number) ||
        provider_phone_number.length > phoneChars(provider_phone_number))
    ) {
      return true;
    }

    if (
      !provider_phone_number ||
      provider_phone_number.length < phoneChars(provider_phone_number) ||
      provider_phone_number.length > phoneChars(provider_phone_number) ||
      status !== "in_process" ||
      !file_path
    )
      return true;

    return false;
  };

  const handleSendReceiptToWPP = async (receiptsInfo) => {
    const dataFormatted = handleFormatedMessageToChat(receiptsInfo);
    const res = await sendReceiptToWPP(dataFormatted);
    if (res.result === "ok") {
      toast.success("El mensaje se envio con exito!", toastOptions);
    } else
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
  };

  const renderAutocomplete = () => {
    return (
      <>
        <Select
          value={typeSelected}
          onChange={(e) => setTypeSelected(e.target.value)}
          sx={{ width: "20%", fontSize: "14px" }}
          size="small"
          defaultValue={typeSelected}
        >
          {[
            { label: "Todas", value: "all" },
            { label: "Proveedor", value: "invoice" },
            { label: "Nómina", value: "payroll" },
            { label: "Cobro", value: "collection" },
          ].map((option) => {
            return (
              <MenuItem
                key={option.label}
                sx={{ fontSize: "14px" }}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
        <Autocomplete
          options={suppliersOptions}
          size="small"
          sx={{ width: "35%", minWidth: "164px" }}
          freeSolo
          filterOptions={filterOptions}
          getOptionLabel={(option) => {
            return option?.name || "";
          }}
          onChange={(event, option) => {
            if (option?.id) {
              handleSupplierSearchChange(option);
            } else {
              handleSupplierSearchChange();
            }
          }}
          onInputChange={(event) => {
            if (event?.target?.value?.length >= 3) {
              getSuppliersOptions(event.target.value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: 14 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={"Buscar proveedor"}
            />
          )}
          value={
            providerSelected
              ? providerSelected
              : {
                  name: "",
                }
          }
        />
        <Autocomplete
          options={employeesOptions}
          size="small"
          sx={{ width: "35%", minWidth: "164px" }}
          freeSolo
          filterOptions={filterOptions}
          getOptionLabel={(option) => {
            return option?.name || "";
          }}
          onChange={(event, option) => {
            if (option?.id) {
              handleEmployeeSearchChange(option);
            } else {
              handleEmployeeSearchChange();
            }
          }}
          onInputChange={(event) => {
            if (event?.target?.value?.length >= 3) {
              getEmployeesOptions(event.target.value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: 14 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={"Buscar empleado"}
            />
          )}
          value={
            employeeIdSelected
              ? employeeSelected
              : {
                  name: "",
                }
          }
        />
        <Autocomplete
          options={companiesOptions}
          size="small"
          sx={{ width: "30%", minWidth: "164px" }}
          freeSolo
          filterOptions={filterNITOptions}
          getOptionLabel={(option) => {
            return option.name;
          }}
          onChange={(event, option) => {
            if (option?.id) {
              handleCompanySearchChange(option);
            } else {
              handleCompanySearchChange();
            }
          }}
          onInputChange={(event) => {
            if (event?.target?.value?.length >= 3) {
              getCompanySearchOptions(event.target.value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: 14 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={"Buscar cliente"}
            />
          )}
          value={
            companyIdSelected !== "all"
              ? companySelected
              : {
                  name: companySearch,
                }
          }
        />
      </>
    );
  };

  const renderRowEndButtons = (row) => {
    const type = row.original.provider_name ? "invoice" : "payroll";
    const mailDisabled = () => {
      return type === "invoice"
        ? !row.original.file_path
        : row.original.status !== "paid";
    };

    return (
      <td style={{ width: "13%" }}>
        <IconButton
          onClick={() => openSendEmailModal(row.original.id, type)}
          disabled={mailDisabled()}
        >
          <SendIcon strokeColor={mailDisabled() ? "#98A2B3" : "#6938EF"} />
        </IconButton>
        <ExpandItemContainer
          action={openDetailsModal}
          itemId={row.original.id}
        />
        {type === "invoice" && (
          <IconButton
            onClick={() => handleSendReceiptToWPP(row.original)}
            disabled={handleDisabledMSG(row.original)}
          >
            <PhoneIcon isDisabled={handleDisabledMSG(row.original)} />
          </IconButton>
        )}
        {row.original.transaction_type !== "collection" && (
          <IconButton
            style={row.original.send_help && { opacity: "0.7" }}
            onClick={() => handleRequestHelp(row.original)}
            disabled={row.original.status === "paid"}
          >
            <HelpIcon
              wasHelped={row.original?.send_support === 1}
              isDisabled={row.original.status === "paid"}
            />
          </IconButton>
        )}
        <IconButton
          style={{
            backgroundColor: row?.original?.hasNote ? "#6938EF" : "transparent",
          }}
          onClick={() => {
            setCurrentNoteId(row?.original?.id);
            setNotesModalIsOpen(true);
          }}
        >
          <MsgIcon color={row?.original?.hasNote ? "#FFFFFF" : "#98A2B3"} />
        </IconButton>
      </td>
    );
  };

  return (
    <>
      {notesModalIsOpen && (
        <NotesModal
          currentUserEmail={currentUser?.email}
          handleClose={() => setNotesModalIsOpen(false)}
          id={currentNoteId}
        />
      )}
      {detailsModalIsOpen && (
        <ReceiptDetails
          visible={detailsModalIsOpen}
          onClose={() => setDetailModalIsOpen(false)}
          id={selectedTrx}
        />
      )}
      {sendEmailModalIsOpen && (
        <SendEmailModal
          visible={sendEmailModalIsOpen}
          onClose={() => setSendEmailModalIsOpen(false)}
          id={selectedTrx}
          type={selectedTrxType}
        />
      )}
      {statusModalIsOpen && (
        <ChangeStatusModal
          itemId={selectedTrx}
          isOpen={statusModalIsOpen}
          setIsOpen={setStatusModalIsOpen}
          action={changeBOReceiptStatus}
          prevStatus={selectedTrxStatus}
          pageIndex={pageIndex}
          isReceipts={true}
        />
      )}
      <SupplierDetails
        id={selectedSupplier}
        isOpen={drawerIsOpen}
        handleClose={() => {
          setSelectedSupplier(null);
          setDrawerIsOpen(false);
          getBOreceipts({
            pageIndex,
            status: statusSelected,
            providerId: providerIdSelected,
            companyId: companyIdSelected,
            transactionHash: hashSelected,
            employeeId: employeeIdSelected,
            type: typeSelected,
          });
        }}
      />
      <EmployeeDetails
        id={selectedEmployee}
        isOpen={employeeDrawerIsOpen}
        handleClose={() => {
          setSelectedEmployee(null);
          setEmployeeDrawerIsOpen(false);
          getBOreceipts({
            pageIndex,
            status: statusSelected,
            providerId: providerIdSelected,
            companyId: companyIdSelected,
            transactionHash: hashSelected,
            employeeId: employeeIdSelected,
            type: typeSelected,
          });
        }}
        isBO={true}
      />
      <CustomerDetails
        id={selectedCustomer}
        isOpen={customerDrawerIsOpen}
        handleClose={() => {
          setSelectedCustomer(null);
          setCustomerDrawerIsOpen(false);
          getBOreceipts({
            pageIndex,
            status: statusSelected,
            providerId: providerIdSelected,
            companyId: companyIdSelected,
            transactionHash: hashSelected,
            employeeId: employeeIdSelected,
            type: typeSelected,
          });
        }}
        isBO={true}
      />
      <div style={styles.titleContainer}>
        <Typography sx={styles.title} noWrap>
          Backoffice de soportes
        </Typography>
      </div>
      <StatusFilter
        statusSelected={statusSelected}
        setStatusSelected={setStatusSelected}
      />
      {!BOreceipts || isLoadingScreen ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          data={BOreceipts}
          setPageIndex={setPageIndex}
          section="BOReceipts"
          expandIcon
          expandAction={openDetailsModal}
          pageCount={totalPages}
          showAutocomplete={true}
          suppliersOptions={suppliersOptions}
          handleSupplierSearchChange={handleSupplierSearchChange}
          emailIcon
          helpOnClick={handleRequestHelp}
          statusPillAction={openStatusModal}
          isBO={true}
          renderAutocomplete={renderAutocomplete}
          renderRowEndButtons={renderRowEndButtons}
          pageNumber={pageIndex}
          emptyState={
            <TableEmptyState
              title={"No se encontraron soportes"}
              subtitle={
                "Aquí se mostrarán los pagos o agrupamientos de facturas que realices."
              }
            />
          }
          extraFilters={true}
          thirdSearchPlaceholder="Buscar código"
          handleThirdSearchChange={handleThirdSearchChange}
          openSupplier={openSupplierDrawer}
          openEmployee={openEmployeeDrawer}
          openCustomer={openCustomerDrawer}
        />
      )}
    </>
  );
};

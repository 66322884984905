export const CompanyLogo = ({ logoUrl }) => {
  return (
    <div
      className="debt-list-item-company-logo"
      style={{
        backgroundImage: `url('${logoUrl}')`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  );
};

import { NumericFormat } from "react-number-format";
import { styles } from "../../../Table/styles";
import calcCollectionsComission from "../../../../screens/CollectionsScreen/comission/calcCollectionsComission";

const PayanaComission = ({
  companyCollectionsCommission,
  amount,
  companyCollectionsFixedCommission,
}) => {
  amount = Number.isFinite(amount) ? amount : 0;
  const input = {
    amount,
    comission_percentage: companyCollectionsCommission,
    collection_comission_fix_amount: companyCollectionsFixedCommission,
  };
  const { round_comission } = calcCollectionsComission(input);
  if (!companyCollectionsCommission) {
    return null;
  }
  let formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  return (
    <div style={styles.supplierDataCard}>
      <p style={styles.invoiceSupplierCardTitle} className="card-subtitle mb-2">
        {companyCollectionsFixedCommission
          ? `Comisión Payana (${formatAmount.format(round_comission)})`
          : `Comisión Payana (${companyCollectionsCommission}%)`}
      </p>
      <p style={styles.collectionTotalsCardText} className="card-subtitle mb-2">
        {amount ? (
          <NumericFormat
            value={round_comission}
            allowNegative={false}
            prefix="- $"
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
          />
        ) : (
          "$0"
        )}
      </p>
    </div>
  );
};

export default PayanaComission;

import { requestContainer } from "../requestContainer";

export function GetBOTotalpayrolls({ employeeId, companyId, status }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/payroll/total?employee_id=${employeeId}&company_id=${companyId}&status=${status}`,
  });
}

export async function GetBOPayrolls({
  pageIndex,
  employeeId,
  companyId,
  status,
}) {
  return requestContainer({
    method: "get",
    url: `/backoffice/payroll?page=${pageIndex}&employee_id=${employeeId}&company_id=${companyId}&status=${status}&per_page=50`,
  });
}

export async function UpdatePayrollStatus(id, status) {
  return requestContainer({
    method: "put",
    url: `/backoffice/payrolls/${id}`,
    data: { status: status },
  });
}

export async function SearchBOEmployees(query) {
  return requestContainer({
    method: "get",
    url: `/backoffice/employees/search?q=${query}`,
  });
}

export async function sendChatMessagePayroll(data) {
  return requestContainer({
    method: "post",
    url: `/backoffice/chat/payroll`,
    data,
  });
}

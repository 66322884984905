import React from "react";

export const MoneyFinishedIcon = ({
  stroke = "#EAECF0",
  width = "24",
  height = "24",
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
    <g clipPath="url(#clip0_1454_3842)">
      <rect x="0.5" width="24" height="24" rx="12" fill="white" />
      <rect
        x="1.25"
        y="0.75"
        width="22.5"
        height="22.5"
        rx="11.25"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <circle cx="12.5" cy="12" r="4" fill={stroke} />
    </g>
    <rect x="1" y="0.5" width="23" height="23" rx="11.5" stroke={stroke} />
    <defs>
      <clipPath id="clip0_1454_3842">
        <rect x="0.5" width="24" height="24" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import React from "react";

export const CopyIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 13.3333V15.6667C13.3333 16.6001 13.3333 17.0668 13.1516 17.4233C12.9918 17.7369 12.7369 17.9919 12.4233 18.1517C12.0668 18.3333 11.6 18.3333 10.6666 18.3333H4.33329C3.39987 18.3333 2.93316 18.3333 2.57664 18.1517C2.26304 17.9919 2.00807 17.7369 1.84828 17.4233C1.66663 17.0668 1.66663 16.6001 1.66663 15.6667V9.33333C1.66663 8.39991 1.66663 7.9332 1.84828 7.57668C2.00807 7.26308 2.26304 7.00811 2.57664 6.84832C2.93316 6.66666 3.39987 6.66666 4.33329 6.66666H6.66663M9.33329 13.3333H15.6666C16.6 13.3333 17.0668 13.3333 17.4233 13.1517C17.7369 12.9919 17.9918 12.7369 18.1516 12.4233C18.3333 12.0668 18.3333 11.6001 18.3333 10.6667V4.33333C18.3333 3.39991 18.3333 2.9332 18.1516 2.57668C17.9918 2.26308 17.7369 2.00811 17.4233 1.84832C17.0668 1.66666 16.6 1.66666 15.6666 1.66666H9.33329C8.39987 1.66666 7.93316 1.66666 7.57664 1.84832C7.26304 2.00811 7.00807 2.26308 6.84828 2.57668C6.66663 2.9332 6.66663 3.39991 6.66663 4.33333V10.6667C6.66663 11.6001 6.66663 12.0668 6.84828 12.4233C7.00807 12.7369 7.26304 12.9919 7.57664 13.1517C7.93316 13.3333 8.39987 13.3333 9.33329 13.3333Z"
      stroke="#98A2B3"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { FilterIcon, IconCloseSmall } from "../../assets";
import { Button } from "@mui/material";
import { styles } from "../../screens/InvoicesScreen/styles";

export const TagsButton = ({
  action,
  text,
  quantity,
  open,
  cleanTags = () => {},
}) => {
  return (
    <Button
      id="basic-button"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      {...(action ? { onClick: action } : {})}
      style={styles.tagButton}
    >
      <FilterIcon />
      {quantity > 0 ? (
        <>
          <span style={styles.tagsButtonText}>{text}</span>
          <span style={styles.tagQuantity}>{quantity}</span>
        </>
      ) : (
        <div style={styles.tagButtonTextContainer}>
          <span style={styles.tagsButtonText}>{text}</span>
        </div>
      )}
    </Button>
  );
};

import { requestContainer } from "../requestContainer";

export async function GetReceiptsV2({ pageIndex, providerId, status }) {
  return requestContainer({
    method: "get",
    url: `/receipts?page=${pageIndex}&provider_id=${providerId}&status=${status}&per_page=100`,
  });
}

export async function GetTotalReceipts({ providerId, status }) {
  return requestContainer({
    method: "get",
    url: `/receipts/total?provider_id=${providerId}&status=${status}`,
  });
}

export async function GetReceipt({ id }) {
  return requestContainer({
    method: "get",
    url: `/receipts/${id}`,
  });
}

export async function CreateReceipt({ ids, type = "invoice", status }) {
  const data = status
    ? { rows: ids, type: type, status: status }
    : { rows: ids, type: type };
  return requestContainer({
    method: "post",
    url: `/receipts`,
    data: data,
  });
}

export async function CreateReceiptToPay({ id }) {
  return requestContainer({
    method: "post",
    url: `/receipts/${id}/to-pay`,
  });
}

export async function NotifyReceiptId({ id, type = "invoice" }) {
  const data = { id: id, type: type };
  return requestContainer({
    method: "post",
    url: `/receipts/notification/reference`,
    data: data,
  });
}

export async function DeleteReceipt({ id }) {
  return requestContainer({
    method: "delete",
    url: `/receipts/${id}`,
  });
}

import {
  Box,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useState } from "react";
import { UserContext } from "../../../contexts";
import { taxRegOptions, FEATURES } from "../constants";
import NotificationEmails from "./NotificationEmails";
import { styles } from "../styles";
import ChangeCompanyLogo from "./ChangeCompanyLogo";
import { useForm, useWatch } from "react-hook-form";
import { toastOptions } from "../../../constants";
import { toast } from "react-toastify";
import { Retentions } from "../../../commons/Layout/Navbar/TrialMessage/RetentionSelector";
import { saveRetention } from "../../../services/api/retentions";
import useRetentionsSelection from "../../../commons/Layout/Navbar/TrialMessage/useRetentionsSelection";
import SettingsRow from "./SettingsRow";
import LegalRepresentativeForm from "./LegalRepresentativeForm";
import TagsEditor from "./TagsEditor";
import BankData from "./BankData";
import { SettingsFooter } from "./FooterHandler";
import CollectionsCheckoutConfiguration from "./CollectionsCheckoutConfiguration";
import useFeaturesFlags from "hooks/useFeaturesFlags";
import { Show } from "commons";

export const CompanyTab = () => {
  const {
    currentCompany,
    updateCompany,
    currentUser,
    currentUserIsAdmin,
    getMe,
    canIntegrateWithDian,
    comissionRetentions,
    comissionRetentionIsEnabled,
    updateCollectionsCheckoutConfiguration,
  } = useContext(UserContext);
  const [taxReg, setTaxReg] = useState(currentCompany.tax_regime);
  const [companyName, setCompanyName] = useState(currentCompany.name);
  const [saveSettingsButtonIsDisabled, setSaveSettingsButtonIsDisabled] =
    useState(true);
  const [sourceOfFundsRequired, setSourceOfFundsRequired] = useState(
    currentCompany.source_of_funds_required !== undefined
      ? currentCompany.source_of_funds_required
      : true
  );
  const { selectedRetentions, selectRetention } = useRetentionsSelection(
    comissionRetentions.map((c) => c.key)
  );

  const { register, handleSubmit, formState, control } = useForm({
    defaultValues: {
      documentType: currentCompany.id_type || "",
      documentNumber: currentCompany.nit_representante || "",
    },
  });
  const { isFeatureEnabled } = useFeaturesFlags();
  const formValues = useWatch({ control });
  const onSubmit = async (data) => {
    try {
      await updateCompany({
        legalRepresentativeDocumentType: data.documentType,
        legalRepresentativeDocumentNumber: data.documentNumber,
        taxRegime: taxReg,
        name: companyName,
        sourceOfFundsRequired,
        collectionsCheckoutPartialPayments:
          currentCompany.collection_checkout_partial_payments,
        collectionsCheckoutRetentions:
          currentCompany.collection_checkout_retentions,
      });
      comissionRetentionIsEnabled &&
        selectedRetentions.length > 0 &&
        (await saveRetention(selectedRetentions));
      await getMe();
      toast.success("¡La empresa se actualizó con éxito!", toastOptions);
      setSaveSettingsButtonIsDisabled(true);
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          paddingLeft: "0px",
        }}
        px={4}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          my={2}
          sx={{ marginLeft: "32px" }}
        >
          <Typography sx={styles.inputLabel}>Nombre de la empresa</Typography>
          <TextField
            sx={{ width: "512px" }}
            size="small"
            id="company_name"
            variant="outlined"
            value={companyName}
            onChange={(e) => {
              setCompanyName(e.target.value);
              setSaveSettingsButtonIsDisabled(false);
            }}
            textwrap
            InputProps={{ style: { fontSize: 14 } }}
          />
        </Stack>
        <Divider style={styles.divider} />
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          my={2}
          sx={{ marginLeft: "32px" }}
        >
          <Box>
            <Typography sx={styles.inputLabel} noWrap>
              NIT
            </Typography>
            <Typography sx={styles.inputSubLabel}>
              No incluir número de seguridad.
            </Typography>
          </Box>
          <TextField
            sx={{
              width: "512px",
              backgroundColor: "#F9FAFB",
              color: "#D0D5DD",
            }}
            size="small"
            id="company_name"
            variant="outlined"
            defaultValue={currentCompany.identification_number}
            disabled
            InputProps={{ style: { fontSize: 14 } }}
          />
        </Stack>
        <Divider style={styles.divider} />
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          my={2}
          sx={{ marginLeft: "32px" }}
        >
          <Typography sx={{ ...styles.inputLabel, alignSelf: "baseline" }}>
            Datos bancarios
          </Typography>
          <div style={{ width: "512px" }}>
            <BankData />
          </div>
        </Stack>
        <Divider style={styles.divider} />
        <Show
          condition={isFeatureEnabled(
            FEATURES.COLLECTIONS_CHECKOUT_CONFIGURATION
          )}
        >
          <CollectionsCheckoutConfiguration
            company={currentCompany}
            onChange={updateCollectionsCheckoutConfiguration}
          />
        </Show>
        {canIntegrateWithDian && (
          <SettingsRow
            label="Representante legal"
            subLabel="No incluir dígito de verificación."
          >
            <LegalRepresentativeForm
              register={register}
              formValues={formValues}
              formState={formState}
              isDianActived={
                !!(currentCompany.id_type && currentCompany.nit_representante)
              }
              setSaveSettingsButtonIsDisabled={setSaveSettingsButtonIsDisabled}
            />
          </SettingsRow>
        )}
        {comissionRetentionIsEnabled && (
          <SettingsRow
            label="Retenciones sobre las comisiones"
            subLabel="*Rete ICA aplica solo a empresas que estén radicadas en Bogotá."
          >
            <Retentions
              selectRetention={selectRetention}
              selectedRetentions={selectedRetentions}
              setSaveSettingsButtonIsDisabled={setSaveSettingsButtonIsDisabled}
            />
          </SettingsRow>
        )}
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          my={2}
          sx={{ marginLeft: "32px" }}
        >
          <Typography sx={styles.inputLabel}>Régimen tributario</Typography>
          <Select
            value={taxReg}
            onChange={(e) => {
              setTaxReg(e.target.value);
              setSaveSettingsButtonIsDisabled(false);
            }}
            sx={{ width: "512px", fontSize: "14px" }}
            size="small"
            defaultValue={taxReg}
          >
            {taxRegOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
        <Divider style={styles.divider} />
        {currentUser && currentUserIsAdmin && (
          <>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              my={2}
              sx={{ marginLeft: "32px" }}
            >
              <Box>
                <Typography sx={styles.inputLabel} noWrap>
                  Registro de origen de fondos
                </Typography>
                <Typography sx={styles.inputSubLabel}>
                  Podrás indicar en cada transacción con que cuenta bancaria o
                  tarjeta realizaste el pago. Esto nos permitirá cargar el
                  egreso en tu sistema contable.
                </Typography>
              </Box>
              <Select
                value={sourceOfFundsRequired}
                onChange={(e) => {
                  setSourceOfFundsRequired(e.target.value);
                  setSaveSettingsButtonIsDisabled(false);
                }}
                sx={{ width: "512px", fontSize: "14px" }}
                size="small"
              >
                {[
                  { label: "Sí", value: true },
                  { label: "No", value: false },
                ].map((option) => {
                  return (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
            <Divider style={styles.divider} />
          </>
        )}
        {currentUserIsAdmin && (
          <>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              my={2}
              sx={{ marginLeft: "32px" }}
            >
              <Typography sx={styles.longInputLabel}>Cambiar logo</Typography>
              <ChangeCompanyLogo getMe={getMe} />
            </Stack>
            <Divider style={styles.divider} />

            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              my={2}
              sx={{ marginLeft: "32px" }}
            >
              <Typography sx={styles.longInputLabel}>
                Recibir copia de soportes
              </Typography>
              <NotificationEmails />
            </Stack>
            <Divider style={styles.divider} />
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              my={2}
              sx={{ marginLeft: "32px" }}
            >
              <Box>
                <Typography sx={styles.inputLabel} noWrap>
                  Etiquetado
                </Typography>
                <Typography sx={styles.inputSubLabel}>
                  Gestiona tus etiquetas. Si las eliminas no podrás filtrar por
                  ellas en el listado de documentos y se borrarán de tus cuentas
                  por pagar anteriores.
                </Typography>
              </Box>
              <TagsEditor />
            </Stack>
          </>
        )}
      </Box>
      <SettingsFooter
        saveSettingsChanges={handleSubmit(onSubmit)}
        saveSettingsButtonIsDisable={saveSettingsButtonIsDisabled}
      />
    </form>
  );
};

import React, { useContext, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  Autocomplete,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styles } from "./styles";
import {
  PrimaryButton,
  SecondaryButton,
  WithoutBorderButton,
} from "../../buttons";
import { CloseButton } from "react-bootstrap";
import { PayrollContext } from "../../../contexts";
import { RepeatPayrollModal } from "../../modals";
import { createPortal } from "react-dom";
import { EmployeeSearch } from "../../../services";
import { filterOptions } from "../../../utils";
import { RequiredLabel } from "../../components";

export const NewPayrollItem = ({ setAddEmployeeDrawerIsOpen }) => {
  const {
    employees,
    setNewPayrollItemDrawerIsOpen,
    newPayrollItemDrawerIsOpen,
    getEmployees,
    addSinglePayroll,
    repeatPayrollModalIsOpen,
    setRepeatPayrollModalIsOpen,
  } = useContext(PayrollContext);
  const [formValues, setFormValues] = useState({
    employee_id: "",
    amount: 0,
    period: "",
  });
  const [searchOptions, setSearchOptions] = useState([]);

  const closeAndReset = () => {
    setFormValues({
      employee_id: "",
      amount: 0,
      period: "",
    });
    setNewPayrollItemDrawerIsOpen(false);
  };

  useEffect(() => {
    getEmployees(1, "all");
  }, []);

  useEffect(() => {
    if (newPayrollItemDrawerIsOpen) {
      setFormValues({
        employee_id: "",
        amount: 0,
        period: "",
      });
    }
  }, [newPayrollItemDrawerIsOpen]);

  const getSearchOptions = (query) => {
    EmployeeSearch(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        freeSolo
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onChange={(event, newValue) =>
          setFormValues({
            ...formValues,
            employee_id: newValue.id,
          })
        }
        onInputChange={(event) => {
          if (event?.target?.value?.length >= 3) {
            getSearchOptions(event.target.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
            }}
            placeholder={"Buscar empleado"}
          />
        )}
      />
    );
  };

  return (
    <>
      {repeatPayrollModalIsOpen &&
        createPortal(
          <RepeatPayrollModal
            visible={repeatPayrollModalIsOpen}
            handleClose={() => {
              setRepeatPayrollModalIsOpen(false);
              closeAndReset();
            }}
            action={addSinglePayroll}
            formValues={formValues}
          />,
          document.body
        )}
      {employees && (
        <Drawer
          anchor="right"
          open={newPayrollItemDrawerIsOpen}
          onClose={closeAndReset}
        >
          <Stack
            sx={{ width: "400px", height: "100%" }}
            px={3}
            py={3}
            justifyContent="space-between"
          >
            <Stack direction={"row"} justifyContent="space-between">
              <Typography sx={styles.title}>Nueva nómina</Typography>
              <CloseButton
                style={{ boxShadow: "none" }}
                onClick={closeAndReset}
              />
            </Stack>
            <Stack my={2} spacing={2} sx={{ height: "100%" }}>
              <Stack spacing={1}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RequiredLabel label="Empleado" sx={styles.inputLabel} />
                  <WithoutBorderButton
                    text="Crear nuevo"
                    action={() => setAddEmployeeDrawerIsOpen(true)}
                    width="90px"
                  />
                </div>
                {renderAutocomplete()}
              </Stack>
              <Stack spacing={1}>
                <RequiredLabel label="Monto" sx={styles.inputLabel} />
                <TextField
                  size="small"
                  variant="outlined"
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      amount: parseInt(e.target.value) || 0,
                    })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    style: { fontSize: 14 },
                  }}
                  value={formValues.amount}
                />
              </Stack>
              <Stack spacing={1}>
                <RequiredLabel label="Concepto" sx={styles.inputLabel} />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Prima, viáticos, etc."
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      period: e.target.value,
                    })
                  }
                  InputProps={{
                    style: { fontSize: 14 },
                  }}
                  value={formValues.period}
                />
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              spacing={1}
              pt={4}
              justifyContent="flex-end"
            >
              <SecondaryButton text="Cancelar" action={closeAndReset} />
              <PrimaryButton
                text="Crear"
                action={() => {
                  addSinglePayroll(formValues).then((res) => {
                    setNewPayrollItemDrawerIsOpen(false);
                  });
                }}
                isDisable={
                  !formValues.employee_id ||
                  !formValues.amount ||
                  !formValues.period
                }
              />
            </Stack>
          </Stack>
        </Drawer>
      )}
    </>
  );
};

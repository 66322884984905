import React, { useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { PlusIcon2, XIcon } from "../../assets";
import { Button } from "@mui/material";
import { CreateTag, GetTags } from "../../services";
import { Spinner } from "react-bootstrap";

export const TagsSelector = ({
  options,
  selectedValues = [],
  onSelect,
  onRemove,
  searchBoxStyle = {
    border: "none",
    borderBottom: "1px solid blue",
    borderRadius: "0px",
  },
  inputFieldStyle = {
    fontSize: "14px",
    color: "black",
  },
  disable = false,
  setTags,
  setTagsSelected,
  ref,
  disablePlaceholder = false,
  className = null,
}) => {
  const [tagSearch, setTagSearch] = useState("");
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);

  const createTag = () => {
    if (createRequestInProcess) {
      return;
    }
    setCreateRequestInProcess(true);
    CreateTag({ tag: tagSearch }).then((tagCreated) => {
      GetTags()
        .then((res) => {
          setTags(res);
          setTagsSelected([...selectedValues, tagCreated]);
          document.getElementById("search_input").blur();
        })
        .finally(() => {
          setTimeout(() => {
            document.getElementById("search_input").value = "";
          }, 100);
          setCreateRequestInProcess(false);
          setTagSearch("");
        });
    });
  };

  return (
    <Multiselect
      options={options}
      className={className}
      selectedValues={selectedValues}
      onSelect={onSelect}
      onRemove={onRemove}
      onSearch={(value) => setTagSearch(value)}
      displayValue={"name"}
      placeholder={disablePlaceholder ? "" : "Buscar etiqueta"}
      showCheckbox
      hidePlaceholder
      ref={ref}
      emptyRecordMsg={
        tagSearch.length > 0 ? (
          <Button
            variant="primary"
            style={{
              textTransform: "none",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
              flexDirection: "row",
              color: "#5925DC",
              fontSize: "14px",
              fontWeight: "400",
            }}
            onClick={() => createTag()}
            disabled={createRequestInProcess}
          >
            {createRequestInProcess ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <>
                <div style={{ marginRight: "3px" }}>
                  <PlusIcon2 />
                </div>
                {`Crear etiqueta "${tagSearch}"`}
              </>
            )}
          </Button>
        ) : (
          ""
        )
      }
      avoidHighlightFirstOption
      disable={disable}
      customCloseIcon={
        <div style={{ marginLeft: "6px" }}>
          <XIcon />
        </div>
      }
      style={{
        multiselectContainer: { minWidth: "300px", paddingTop: "0px" },
        chips: {
          background: "#FFFFFF",
          border: "1px solid #D0D5DD",
          borderRadius: "6px",
          color: "black",
          marginBottom: "0px",
        },
        searchBox: searchBoxStyle,
        inputField: inputFieldStyle,
        option: {
          fontSize: "14px",
          backgroundColor: "white",
          color: "black",
          border: "0px solid transparent",
          display: "flex",
          alignItems: "center",
        },
      }}
    />
  );
};

export default TagsSelector;

import React, { useState, useEffect, useContext } from "react";
import { Box, Dialog, MenuItem, Select, Stack } from "@mui/material";
import {
  getSourceOfFunds,
  saveSourceOfFund,
  updateTransactionSourceOfFund,
} from "../../../services/api/SourceOfFunds.service";
import { PaymentsContext, UserContext } from "../../../contexts";
import { useForm, useWatch } from "react-hook-form";
import { getSourceOfFundsLabel } from "./getSourceOfFundsLabel";
import SourceOfFundsSelector from "./SourceOfFundsSelector";
import BankAccountForm from "./BankAccountForm";
import CardReferenceForm from "./CardReferenceForm";
import RegisterModalFooter from "./RegisterModalFooter";
import "./source-of-funds.css";
import { ValidationMessage } from "../../components";
import { useMediaQuery } from "react-responsive";

const CustomPaper = ({ children }) => {
  return <Box sx={{ background: "#fff" }}>{children}</Box>;
};

const EditSourceOfFundsModal = (props) => {
  const {
    visible,
    selectedId,
    handleClose,
    transactionId,
    handleSuccess = () => {},
  } = props;
  const { getAllBanks } = useContext(PaymentsContext);
  const { currentCompany } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [isUpdadingSource, setIsUpdatingSource] = useState(false);
  const [state, setState] = useState({
    sources: [],
    selected: "confirm_source_view",
  });
  const companyId = currentCompany?.id;
  const sourcesOfFunds = state.sources;
  const selected = state.selected;
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const setSelected = (selectedOne) => {
    setState((prevState) => ({ ...prevState, selected: selectedOne }));
  };

  useEffect(() => {
    getAllBanks();
  }, []);

  useEffect(() => {
    if (visible) {
      setLoading(true);
      getSourceOfFunds(currentCompany.id)
        .then((response) => {
          const sources = Array.isArray(response) ? response : [];
          const selected =
            sources.length > 0 ? "confirm_source_view" : "bank_account";
          setState({
            sources,
            selected,
          });
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [visible]);

  const dispatchUpdateSource = () => {
    setIsUpdatingSource(true);
    updateTransactionSourceOfFund(transactionId, state.selectedId)
      .then(() => {
        handleClose();
        handleSuccess(
          state.sources.find((source) => source.id === state.selectedId)
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsUpdatingSource(false);
      });
  };

  const { register, formState, control } = useForm({
    defaultValues: {},
  });

  const formValues = useWatch({ control });
  const sourcesOfFundsOptions = sourcesOfFunds.map((source) => ({
    id: source.id,
    label: getSourceOfFundsLabel(source),
    type: source.type,
  }));

  const onSelected = (selectedId) => {
    setState((prevState) => ({
      ...prevState,
      registering: false,
      selectedId,
    }));
  };

  const saveNewSource = async () => {
    try {
      setLoading(true);
      const sourceOfFundCreated = await saveSourceOfFund(
        formValues,
        companyId,
        selected
      );
      const sources = await getSourceOfFunds(currentCompany.id);
      setState((prevState) => ({
        ...prevState,
        sources,
        registering: false,
        selected: "confirm_source_view",
        selectedId: sourceOfFundCreated?.id,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const closeFlow = () => {
    handleClose();
  };
  const onRegisterNew = () => {
    setSelected("bank_account");
    setState((state) => ({
      ...state,
      from_view: "confirm_source_view",
    }));
  };
  const className = `my-container ${
    selected === "bank_account"
      ? "bank-account"
      : selected === "card_reference"
      ? "card-reference"
      : "register-new"
  }`;

  return (
    <Dialog open={visible} onClose={closeFlow} PaperComponent={CustomPaper}>
      <Box
        style={{
          padding: 24,
          maxWidth: isMobile ? 380 : 420,
          minWidth: isMobile ? 380 : 420,
        }}
      >
        <Stack justifyContent="space-between" style={{ width: "100%" }}>
          <RegisterModalHeader />
          <div className={className}>
            {selected !== "confirm_source_view" && (
              <SourceOfFundsSelector
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {selected === "bank_account" && (
              <BankAccountForm
                register={register}
                formValues={formValues}
                formState={formState}
              />
            )}
            {selected === "card_reference" && (
              <CardReferenceForm
                register={register}
                formValues={formValues}
                formState={formState}
              />
            )}
            {selected === "confirm_source_view" && (
              <div
                className="source-of-funds-form update-source-of-funds-form"
                style={{ display: "inline" }}
              >
                <Stack justifyContent="space-between">
                  <div style={{ marginBottom: 6 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      style={{ marginBottom: 6 }}
                    >
                      <div
                        style={{
                          color: "#344054",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "20px",
                        }}
                      >
                        Seleccione el origen de sus fondos
                      </div>
                      <div
                        style={{
                          color: "#6938EF",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={onRegisterNew}
                      >
                        Agregar
                      </div>
                    </Stack>
                    <Stack spacing={1}>
                      <Select
                        size="small"
                        variant="outlined"
                        displayEmpty
                        inputProps={{
                          id: "sourceOfFunds",
                        }}
                        onChange={(e) => onSelected(e.target.value)}
                        value={state.selectedId || selectedId || ""}
                      >
                        <MenuItem disabled value="">
                          Elige desde dónde quieres pagar
                        </MenuItem>
                        {sourcesOfFundsOptions.map((type) => {
                          return (
                            <MenuItem key={type.id} value={type.id}>
                              {type.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <ValidationMessage errors={{}} field="sourceOfFunds" />
                    </Stack>
                  </div>
                </Stack>
              </div>
            )}
          </div>

          {selected === "confirm_source_view" && (
            <RegisterModalFooter
              closeFlow={closeFlow}
              saveNewSource={dispatchUpdateSource}
              loading={isUpdadingSource}
              disabled={
                selected === "card_reference"
                  ? !formValues.card_reference &&
                    formValues.card_reference?.length < 3
                  : selected === "bank_account"
                  ? !formValues.bankId ||
                    !formValues.account_type ||
                    !formValues.account_number
                  : ""
              }
            />
          )}
          {selected !== "confirm_source_view" && (
            <RegisterModalFooter
              closeFlow={closeFlow}
              saveNewSource={saveNewSource}
              loading={loading}
              disabled={
                selected === "card_reference"
                  ? !formValues.card_reference &&
                    formValues.card_reference?.length < 3
                  : selected === "bank_account"
                  ? !formValues.bankId ||
                    !formValues.account_type ||
                    !formValues.account_number
                  : ""
              }
            />
          )}
        </Stack>
      </Box>
    </Dialog>
  );
};

function RegisterModalHeader() {
  return (
    <p
      style={{
        fontSize: "18px",
        fontWeight: "500",
        textAlign: "center",
        lineHeight: "28px",
      }}
    >
      Actualiza el origen de los fondos
    </p>
  );
}

export { EditSourceOfFundsModal };

export const styles = {
  cardInformationTitle: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  cardInformationListContainer: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "4px",
  },
  cardInformationListText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    margin: "0px",
    color: "#344054",
  },
  contactHelpContainer: {
    padding: "8px",
    background: "#F2F4F7",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
  contactHelpText: {
    fontSize: "14px",
    fontStyle: "normal",
    margin: "0px",
    color: "#344054",
  },
  contactHelpButton: {
    fontSize: "14px",
    fontStyle: "normal",
    margin: "0px",
    color: "#5925DC",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    marginTop: "24px",
  },
  inactiveFristText: {
    color: "#344054",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  inactiveSecondText: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    margin: "0px",
  },
  successTitle: {
    textAlign: "left",
    marginTop: "20px",
    marginBottom: "8px",
    fontSize: "18px",
    fontWeight: 500,
  },
  successFirstText: {
    color: "#667085",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
  },
  successSecondText: {
    color: "#667085",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  successButton: {
    display: "flex",
    justifyContent: "end",
    marginTop: "24px",
  },
  featuresContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "16px",
  },
  featuresText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    margin: "0px",
  },
  featureItemTitle: {
    margin: "0px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  featureItemDiscount: {
    margin: "0px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    borderRadius: "23px",
    background: "#D9D6FE",
    padding: "2px 8px",
    color: "#101828",
  },
  featureItemPrice: {
    margin: "0px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#475467",
  },
  contactHelpCausationContainer: {
    padding: "16px",
    borderRadius: "8px",
    border: "2px solid #BDB4FE",
    background: "#FAFAFF",
  },
  contactHelpCausationTitle: {
    marginBottom: "4px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  contactHelpCausationText: {
    color: "#344054",
    marginBottom: "15px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  contactHelpCausationButton: {
    padding: "10px 18px",
    textAlign: "center",
    borderRadius: "8px",
    border: "1px solid #F2F3FF",
    background: "#F2F3FF",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    width: "100%",
    color: "#5925DC",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    fontStyle: "normal",
  },
  transactionCostTextList: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    margin: "0px",
    color: "#344054",
  },
  cardContainer: {
    width: "474px",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
  },
  journeyRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    gap: "12px",
    marginBottom: "6px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  journeyTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    alignSelf: "center",
    height: "0px",
    color: "#344054",
  },
  journeyText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    alignSelf: "flex-start",
    height: "20px",
    color: "#667085",
  },
};

export const DebtListInnerItem = ({ children }) => {
  return (
    <div
      style={{
        color: "#344054",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
      }}
    >
      {children}
    </div>
  );
};

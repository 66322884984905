import { parse, isBefore } from "date-fns";
import { DEFAULT_PAYMENT_CARD_FEE } from "../../../constants";

const getComissionLabel = (transaction, selectedRowsIds, suppliers) => {
  const formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  const isDateBefore = (dateStr) => {
    const isoDate = new Date(dateStr);
    const limitDate = parse("21/09/2023", "dd/MM/yyyy", new Date());
    return isBefore(isoDate, limitDate);
  };
  if (transaction.payment_method === "card") {
    return `Comisión Tarjeta de crédito (${DEFAULT_PAYMENT_CARD_FEE}%)`;
  }
  if (transaction?.created_at && isDateBefore(transaction?.created_at)) {
    return `Comisión Payana (${formatAmount.format(
      transaction.amount_comission / selectedRowsIds.length
    )}/documento)`;
  }
  if (transaction.comission_type === "percentage") {
    return `Comisión Payana (${transaction.comission_value}%)`;
  }
  return `Comisión Payana (${formatAmount.format(
    transaction.amount_comission / suppliers.length
  )}/proveedor)`;
};

export default getComissionLabel;

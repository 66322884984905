import { useState } from "react";
import { toast } from "react-toastify";
import ModalShell from "../../../modals/PaymentModal/ModalShell";
import { saveRetention } from "../../../../services/api/retentions";
import { toastOptions } from "../../../../constants";
import { PricingIcon } from "../../../../assets/svg/PricingIcon";
import SubscriptionWelcomeMessage from "./SubscriptionWelcomeMessage";
import RetentionSelector from "./RetentionSelector";
import "./TrialMessage.css";

function getMessage(company, nextStep) {
  const { pricing_metadata = {} } = company;
  const { remaining_trial_days, first_payment_date, trial_days } =
    pricing_metadata;
  if (!first_payment_date) {
    return (
      <div data-testid="pricing-alert-element" className="trial-message-navbar">
        <PricingIcon />
        <span className="trial-message-navbar-text">
          Tienes {trial_days} días de prueba
        </span>
      </div>
    );
  }
  return remaining_trial_days > 0 ? (
    <div data-testid="pricing-alert-element" className="trial-message-navbar">
      <PricingIcon />
      <span className="trial-message-navbar-text">
        Tienes {remaining_trial_days} días para probar Payana gratis
      </span>
    </div>
  ) : (
    <button
      type="button"
      onClick={nextStep}
      data-testid="pricing-alert-element"
      className="trial-message-navbar"
    >
      <PricingIcon />
      <span className="trial-message-navbar-text">
        Necesitamos información sobre tus retenciones
      </span>
    </button>
  );
}

function TrialMessage({ company, visible = true, onSaveSuccess = () => {} }) {
  const [visibleModal, setVisible] = useState(false);
  const [step, setStep] = useState(1);
  if (
    !company ||
    !company?.pricing_metadata ||
    company?.pricing_metadata?.trial_days === undefined
  ) {
    return null;
  }
  const open = () => {
    setVisible(true);
  };
  const resolveNextStep = () => {
    if (!visibleModal) {
      open();
    } else {
      setStep(2);
    }
  };
  const message = getMessage(company, open);
  const saveRetentionsHandlers = async (retentions) => {
    try {
      await saveRetention(retentions);
      onSaveSuccess();
      setVisible(false);
      toast.success(
        "¡Tu configuración se ha guardado exitosamente en el sistema!",
        toastOptions
      );
    } catch (error) {
      toast.error("Hubo un error guardando las retenciones", toastOptions);
    }
  };
  const close = () => setVisible(false);
  const restart = () => {
    setTimeout(() => setStep(1), 250);
    close();
  };
  return (
    <>
      {visible && message}
      <ModalShell visible={visibleModal}>
        {step === 1 ? (
          <SubscriptionWelcomeMessage resolveNextStep={resolveNextStep} />
        ) : (
          <RetentionSelector action={saveRetentionsHandlers} close={restart} />
        )}
      </ModalShell>
    </>
  );
}

export default TrialMessage;

import { Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../../buttons";

export const StepFour = ({ cancel, primaryAction }) => {
  return (
    <div>
      <p className="dian-modal-title">
        <strong>El token de acceso ha expirado</strong>
      </p>
      <p className="dian-modal-text">
        <span>
          Haga click en reenviar para recibir un nuevo token de acceso al correo
          registrado en el RUT del representante legal de la empresa.
        </span>
      </p>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%" }}
      >
        <PrimaryButton width="100%" text="Reenviar" action={primaryAction} />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%", marginTop: 6 }}
      >
        <SecondaryButton width="100%" text="Cancelar" action={cancel} />
      </Stack>
    </div>
  );
};

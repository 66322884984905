import React from "react";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { SaveIcon } from "../../../assets";
import { MarkCollectionsAsPaid, MarkInvoicesAsPaid } from "../../../services";
import { useContext } from "react";
import { CollectionsContext, PaymentsContext } from "../../../contexts";
import { useMediaQuery } from "react-responsive";
import { PrimaryButton, SecondaryButton } from "commons/buttons";

export const MarkAsPaidModal = ({
  visible,
  handleClose,
  selectedRowsIds,
  section = "invoices",
}) => {
  const { changeStatus } = useContext(PaymentsContext);
  const { changeStatus: changeCollectionsStatus } =
    useContext(CollectionsContext);

  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const handleClick = async () => {
    const response =
      section === "invoices"
        ? await MarkInvoicesAsPaid({ ids: selectedRowsIds })
        : await MarkCollectionsAsPaid({ ids: selectedRowsIds });
    if (response) {
      section === "invoices"
        ? changeStatus(selectedRowsIds, "paid_outside")
        : changeCollectionsStatus(selectedRowsIds, "paid_outside");
    }

    handleClose(selectedRowsIds);
  };

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body style={{ padding: 24, width: isMobile ? "330px" : "400px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SaveIcon />
          <p
            style={{
              color: "#101828",
              fontSize: "18px",
              fontWeight: "500",
              marginTop: "12px",
              textAlign: "left",
              alignSelf: "flex-start",
            }}
          >
            Cambiar estado de cuentas por{" "}
            {section === "invoices" ? "pagar" : "cobrar"}
          </p>
          <p
            style={{
              color: "#667085",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "left",
              alignSelf: "flex-start",
            }}
          >
            Presionar este botón hará que las cuentas por{" "}
            {section === "invoices" ? "pagar" : "cobrar"} seleccionadas cambien
            su estado a "{section === "invoices" ? "Pagada" : "Cobrada"}"
          </p>
          <div style={{ ...styles.row, gap: "8px", width: "100%" }}>
            <SecondaryButton
              text="Cancelar"
              action={() => handleClose(null)}
              style={{ width: "50%" }}
            />

            <PrimaryButton
              text="Confirmar"
              action={() => handleClick()}
              style={{ width: "50%" }}
            />
          </div>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export const styles = {
  account: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#344054",
    marginLeft: "6px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "250px",
  },
  accountOptions: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#475467",
    marginTop: "2px",
  },
  accountTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#475467",
  },
  companyName: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
    lineHeight: "20px",
  },
  email: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#667085",
    lineHeight: "20px",
    marginTop: "4px",
  },
  containerFullWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoutButton: {
    marginTop: "8px",
    marginLeft: "36px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#344054",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  menuIconText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: "8px",
  },
  divider: {
    height: "1px",
    backgroundColor: "#D0D5DD",
    marginTop: "8px",
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px",
  },
  iconMargin: {
    marginTop: "2px",
  },
};

import React, { useContext, useRef, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Menu } from "@mui/material";
import { DateButton } from "./DateButton";
import { DateRangePicker } from "react-date-range";
import { es } from "date-fns/locale";
import { addMonths, addYears, format } from "date-fns";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { defaultStaticRanges, formatLocaleDate } from "utils";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { UserContext } from "contexts";

export const DateDropdown = ({
  date,
  setDate,
  selectedDate,
  setSelectedDate,
  setPageIndex = () => {},
  defaultText = "Fecha",
}) => {
  const { currentUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const searchRef = useRef(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    searchRef?.current?.focus();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetDate = () => {
    setPageIndex(1);
    handleClose();
    setDate([
      {
        startDate: addMonths(new Date(), -1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setSelectedDate([{ startDate: "all", endDate: "all" }]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <DateButton
          open={open}
          action={handleClick}
          text={
            selectedDate.at(0).startDate === "all"
              ? defaultText
              : `${formatLocaleDate(
                  date.at(0).startDate,
                  "MMM d, yyyy"
                )} - ${formatLocaleDate(date.at(0).endDate, "MMM d, yyyy")}`
          }
          resetDate={resetDate}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          slotProps={{
            paper: {
              style: {
                height: 470,
                minHeight: 90,
                maxHeight: 470,
              },
            },
          }}
        >
          <DateRangePicker
            editableDateInputs={true}
            ranges={date}
            locale={es}
            onChange={(item) => setDate([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            direction="horizontal"
            rangeColors={["#7F56D9"]}
            color="#7F56D9"
            calendarFocus="backwards"
            minDate={new Date("2022-01-01")}
            maxDate={new Date()}
            startDatePlaceholder="Último año"
            endDatePlaceholder="Hoy"
            staticRanges={defaultStaticRanges}
            inputRanges={[]}
            fixedHeight={true}
            shownDate={new Date()}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "12px",
              paddingRight: "16px",
            }}
          >
            <SecondaryButton
              text="Limpiar"
              action={() => {
                resetDate();
              }}
              width="88px"
            />
            <PrimaryButton
              text="Aplicar"
              action={() => {
                amplitude.logEvent("USE_FILTER", {
                  user_id: currentUser.id,
                  filter_type: "date",
                  filter_value: `${format(
                    date.at(0).startDate,
                    "yyyy-MM-dd"
                  )}-${format(date.at(0).endDate, "yyyy-MM-dd")}`,
                });
                setSelectedDate([
                  {
                    startDate: format(date.at(0).startDate, "yyyy-MM-dd"),
                    endDate: format(date.at(0).endDate, "yyyy-MM-dd"),
                  },
                ]);
                setPageIndex(1);
                handleClose();
              }}
              width="88px"
            />
          </div>
        </Menu>
      </div>
    </>
  );
};

import React, { useContext, useState, useEffect } from "react";
import { styles } from "../styles";
import { PayrollContext } from "../../../contexts";
import { NumericFormat } from "react-number-format";
import { CustomPayrollTextField } from "./CustomPayrollTextField";
import { permissions } from "../../../constants";
import { useIsUserAllowedTo } from "../../../utils";

export const AmountInput = ({ row, id }) => {
  const { updatePayrollAmount } = useContext(PayrollContext);
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [amount, setAmount] = useState(row.original.amount || 0);
  const [amountChange, setAmountChange] = useState(null);

  useEffect(() => {
    if (row) {
      setAmount(row.original.amount);
    }
  }, [row]);

  useEffect(() => {
    if (amountChange) {
      const timeoutId = setTimeout(() => {
        updatePayrollAmount(row.original.period, id, amountChange, false);
      }, 300);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [amountChange]);

  return (
    <>
      {isUserAllowedTo(permissions.PAYROLLS_UPDATE) ? (
        <div style={styles.row}>
          <NumericFormat
            id="amount"
            name="amount"
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            isNumericString={true}
            value={amount}
            customInput={CustomPayrollTextField}
            onValueChange={(values, sourceInfo) => {
              const value =
                values.value === "" ? values.value : values.floatValue;
              setAmount(value);
              setAmountChange(value);
            }}
          />
        </div>
      ) : (
        <div style={styles.amountRow}>
          <p style={styles.revert}>
            <NumericFormat
              value={amount}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </p>
        </div>
      )}
    </>
  );
};

import React from "react";

export const BackgroundInformation = () => {
  return (
    <svg
      width="220"
      height="166"
      viewBox="0 0 220 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="110" cy="80" r="80" fill="#E9D7FE" />
      <circle cx="18" cy="12" r="8" fill="#F4EBFF" />
      <circle cx="198" cy="142" r="6" fill="#F4EBFF" />
      <circle cx="25" cy="138" r="10" fill="#F4EBFF" />
      <circle cx="210" cy="46" r="10" fill="#F4EBFF" />
      <circle cx="191" cy="11" r="7" fill="#F4EBFF" />
      <g filter="url(#filter0_dd_748_227453)">
        <rect
          x="27"
          y="22"
          width="166"
          height="104"
          rx="7.25581"
          fill="url(#paint0_linear_748_227453)"
        />
        <rect
          x="41.0703"
          y="46.1855"
          width="29.0233"
          height="21.7674"
          rx="2.4186"
          fill="#D6BBFB"
        />
        <circle cx="166.837" cy="53.4417" r="12.093" fill="#B692F6" />
        <circle cx="152.325" cy="53.4417" r="12.093" fill="#D6BBFB" />
        <path
          d="M41.0703 110.279C41.0703 108.943 42.1532 107.86 43.4889 107.86H67.675C69.0107 107.86 70.0936 108.943 70.0936 110.279C70.0936 111.615 69.0107 112.698 67.675 112.698H43.4889C42.1532 112.698 41.0703 111.615 41.0703 110.279Z"
          fill="#D6BBFB"
        />
        <path
          d="M77.3494 110.279C77.3494 108.943 78.4322 107.86 79.768 107.86H103.954C105.29 107.86 106.373 108.943 106.373 110.279C106.373 111.615 105.29 112.698 103.954 112.698H79.768C78.4322 112.698 77.3494 111.615 77.3494 110.279Z"
          fill="#D6BBFB"
        />
        <path
          d="M113.628 110.279C113.628 108.943 114.711 107.86 116.047 107.86H140.233C141.569 107.86 142.652 108.943 142.652 110.279C142.652 111.615 141.569 112.698 140.233 112.698H116.047C114.711 112.698 113.628 111.615 113.628 110.279Z"
          fill="#D6BBFB"
        />
        <path
          d="M149.908 110.279C149.908 108.943 150.99 107.86 152.326 107.86H176.512C177.848 107.86 178.931 108.943 178.931 110.279C178.931 111.615 177.848 112.698 176.512 112.698H152.326C150.99 112.698 149.908 111.615 149.908 110.279Z"
          fill="#D6BBFB"
        />
      </g>
      <g filter="url(#filter1_b_748_227453)">
        <rect
          x="82"
          y="92"
          width="56"
          height="56"
          rx="28"
          fill="#6941C6"
          fillOpacity="0.4"
        />
        <path
          d="M110 116V120M110 124H110.01M120 120C120 125.523 115.523 130 110 130C104.477 130 100 125.523 100 120C100 114.477 104.477 110 110 110C115.523 110 120 114.477 120 120Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_748_227453"
          x="7"
          y="22"
          width="206"
          height="144"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_748_227453"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_748_227453"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_748_227453"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_748_227453"
            result="effect2_dropShadow_748_227453"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_748_227453"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_748_227453"
          x="74"
          y="84"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_748_227453"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_748_227453"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_748_227453"
          x1="32.7809"
          y1="124.769"
          x2="29.758"
          y2="29.5842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9D7FE" />
          <stop offset="1" stopColor="#F4EBFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from "react";
import { Divider, Stack } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { DataIcon } from "assets";
import { styles } from "../styles";
import { invoicesExpensesColumns } from "../utils";
import { CustomDataGrid } from "./CustomDataGrid";
import { Title } from "./Title";

export function PaymentMade({ receiptDetails }) {
  const { invoices, receipts } = receiptDetails;
  const { name, origen, total } = receipts;
  const numericFormatOptions = {
    displayType: "text",
    thousandSeparator: ".",
    decimalSeparator: ",",
    prefix: "$",
    decimalScale: "2",
  };

  return (
    <Stack gap={2}>
      <Title icon={<DataIcon />} title={"Pago realizado"} />
      <div style={styles.container}>
        <PaymentMadeRow laben={"Proveedor"} value={name} />
        <Divider sx={{ marginBottom: "8px" }} />
        <PaymentMadeRow laben={"Origen de los fondos"} value={origen} />
        <Divider sx={{ marginBottom: "8px" }} />
        <PaymentMadeRow
          laben={"Monto abonado"}
          value={
            <strong>
              <NumericFormat
                value={total}
                displayType={numericFormatOptions.displayType}
                thousandSeparator={numericFormatOptions.thousandSeparator}
                decimalSeparator={numericFormatOptions.decimalSeparator}
                prefix={numericFormatOptions.prefix}
                decimalScale={numericFormatOptions.decimalScale}
              />
            </strong>
          }
        />
      </div>

      <CustomDataGrid columns={invoicesExpensesColumns()} rows={invoices} />
    </Stack>
  );
}

function PaymentMadeRow({ laben, value }) {
  return (
    <div style={styles.cardContainer}>
      <p style={{ ...styles.cardText, color: "#667085" }}>{laben}</p>
      <p style={{ ...styles.cardText, color: "#101828" }}>{value}</p>
    </div>
  );
}

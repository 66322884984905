import { useContext } from "react";
import { PaymentsContext } from "../contexts";
import { SyncAlegra, SyncSiigo } from "../services";
import { toast } from "react-toastify";
import { toastOptions } from "../constants";
import { useNavigate } from "react-router-dom";

const createSyncResponse = (response) => {
  return {
    hasInvalidCredentials:
      response?.response?.data?.msg === "password_invalid" ||
      response?.statusCode === 403,
  };
};

const responseIsSuccessful = (res = {}) =>
  res.message === "Successful synchronization with Alegra";

const responseIsInProcess = (res = {}) =>
  res.message ===
  "Estamos sincronizando con Alegra, esto puede tardar unos minutos";

export const useSyncAccountingSystem = () => {
  const {
    internalGetInvoices,
    setSyncIsLoading,
    setSyncHasError,
    setSyncBtnIsDisabled,
    setMultiAccountModalIsOpen,
  } = useContext(PaymentsContext);
  const navigate = useNavigate();

  const syncAccountingSystem = (system) => {
    setSyncIsLoading(true);
    setSyncHasError(false);
    setSyncBtnIsDisabled(true);

    if (system === "Siigo") {
      return SyncSiigo()
        .then((res) => {
          if (res.statusCode === 200) {
            setSyncIsLoading(false);
            if (JSON.parse(res.body).status === "multi") {
              setMultiAccountModalIsOpen(true);
              return;
            }
            toast.success(
              "¡Sincronización finalizada con éxito!",
              toastOptions
            );
            internalGetInvoices(1);
            navigate("/payments", { replace: true });
          } else {
            toast.error(
              "Ocurrió un error durante la sincronización.",
              toastOptions
            );
            setSyncHasError(true);
            setSyncIsLoading(false);
          }
          return createSyncResponse(res);
        })
        .finally(() => {
          setTimeout(() => setSyncHasError(false), 10 * 1000);
          setTimeout(() => setSyncBtnIsDisabled(false), 3 * 60 * 1000);
        });
    } else if (system === "Alegra") {
      return SyncAlegra()
        .then((res) => {
          if (responseIsSuccessful(res)) {
            setSyncIsLoading(false);
            toast.success(
              "¡Sincronización finalizada con éxito!",
              toastOptions
            );
            internalGetInvoices(1);
            navigate("/payments", { replace: true });
          } else if (responseIsInProcess(res)) {
            toast.success(
              "Estamos sincronizando con Alegra, actualice la página en unos minutos.",
              toastOptions
            );
          } else {
            toast.error(
              "Ocurrió un error durante la sincronización.",
              toastOptions
            );
            setSyncHasError(true);
            setSyncIsLoading(false);
          }
          return createSyncResponse(res);
        })
        .finally(() => {
          setTimeout(() => setSyncHasError(false), 10 * 1000);
          setTimeout(() => setSyncBtnIsDisabled(false), 3 * 60 * 1000);
        });
    }
  };

  return {
    syncAccountingSystem,
  };
};

const DisabledLeyend = () => (
  <div style={{ fontSize: "12px", color: "#667085" }}>
    <p style={{}}>
      Superaste el monto límite semanal permitido para pagos con tarjeta de
      crédito.
    </p>
    <p style={{}}>
      Para más información{" "}
      <a
        target="_blank"
        href="https://payanala.notion.site/Por-qu-no-me-permite-abonar-con-tarjeta-de-cr-dito-f7d78880a1b64dbca6ac51e7c5823675"
      >
        haz click aquí.
      </a>
    </p>
  </div>
);

export default DisabledLeyend;

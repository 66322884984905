import React from "react";
import { NumericFormat } from "react-number-format";

const options = {
  displayType: "text",
  thousandSeparator: ".",
  decimalSeparator: ",",
  prefix: "$",
  decimalScale: "2",
};

const styles = {
  color: "#027A48",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "-0.01em",
};

export const BalanceText = ({ amountTotalSelect, receipt }) => {
  const balance = +(receipt.total - amountTotalSelect).toFixed(2);
  return balance > 0 ? (
    <p style={{ ...styles }}>
      {"Se creará un anticipo al tercero por un valor de: "}
      <NumericFormat
        style={{ ...styles }}
        value={balance}
        displayType={options.displayType}
        thousandSeparator={options.thousandSeparator}
        decimalSeparator={options.decimalSeparator}
        prefix={options.prefix}
        decimalScale={options.decimalScale}
      />
    </p>
  ) : (
    <p style={{ ...styles, color: "#C10505" }}>
      El monto total a registrar debe ser igual al monto abonado (
      <NumericFormat
        style={{ ...styles, color: "#C10505" }}
        value={receipt.total}
        displayType={options.displayType}
        thousandSeparator={options.thousandSeparator}
        decimalSeparator={options.decimalSeparator}
        prefix={options.prefix}
        decimalScale={options.decimalScale}
      />
      )
    </p>
  );
};

import React, { useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import { styles } from "../styles";

export const Comments = ({ comment }) => {
  const getTextareaValue = () => {
    return `${comment.payana}\n${comment.transactions}\n${comment.documents}`;
  };

  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Resetea la altura
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Ajusta la altura
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [comment]);

  return (
    <Stack>
      <label style={styles.inputLabel}>Comentarios</label>
      <textarea
        ref={textareaRef}
        style={styles.textArea}
        name="observations"
        value={getTextareaValue()}
        disabled={true}
      />
    </Stack>
  );
};

import React from "react";
import Dropzone from "react-dropzone";
import { ClipIcon, CloseIcon, ModalDownloadIcon } from "../../../../assets";
import { fileUploadStyles } from "./styles";
import { IconButton } from "@mui/material";

const FileUpload = ({
  selectedFiles,
  setSelectedFiles,
  renderImg = false,
  isInvoiceFile = false,
}) => {
  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
    }
  };
  const { styles } = fileUploadStyles;
  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getRootProps({
              className: isInvoiceFile ? "mini-dropzone" : "dropzone",
            })}
          >
            <input {...getInputProps()} />
            {selectedFiles ? (
              selectedFiles.map((file) => (
                <div
                  className="selected-file"
                  style={{
                    ...styles.imageContainer,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {renderImg && (
                    <img
                      style={styles.image}
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                    />
                  )}
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {file.name}
                  </span>
                  <div style={{ marginTop: "8px" }}>
                    <IconButton
                      onClick={() => setSelectedFiles(null)}
                      color="primary"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : isInvoiceFile ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: "16px",
                }}
              >
                <ClipIcon />
                <p
                  style={{
                    color: "#5925DC",
                    fontSize: "14px",
                    fontWeight: "500",
                    marginLeft: "16px",
                    marginTop: "16px",
                  }}
                >
                  Adjuntar archivo
                </p>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ModalDownloadIcon />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#5925DC",
                      fontSize: "14px",
                      fontWeight: "500",
                      marginRight: "4px",
                    }}
                  >
                    Sube el archivo
                  </p>
                  <p
                    style={{
                      color: "#667085",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    o arrástralo aquí
                  </p>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FileUpload;

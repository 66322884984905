export const permissions = {
  CONFIG_ACCOUNT_TAB: "CONFIG_ACCOUNT_TAB", // mostrar la tab de cuentas en configuracion
  CONFIG_ROLES_PERMISSIONS_TAB: "CONFIG_ROLES_PERMISSIONS_TAB", // mostrar la tab de roles y permisos en configuracion
  CONFIG_APPROVAL_REQUIRMENTS: "CONFIG_APPROVAL_REQUIRMENTS", // habilitar editar la aprobacion de documentos
  CONFIG_COMPANY_TAB: "CONFIG_COMPANY_TAB", // mostrar la tab de la empresa en configuracion
  CONFIG_COUNTABLE_SYSTEM_TAB: "CONFIG_COUNTABLE_SYSTEM_TAB", // mostrar la tab de sistema contable en configuracion
  CONFIG_REMINDERS_TAB: "CONFIG_REMINDERS_TAB", // mostrar la tab de recordatorios en configuracion
  COLLECTIONS_SHOW: "COLLECTIONS_SHOW", // mostrar cobros
  COLLECTIONS_CREATE: "COLLECTIONS_CREATE", // crear cobros
  COLLECTIONS_UPDATE: "COLLECTIONS_UPDATE", // actualizar cobros
  COLLECTIONS_DELETE: "COLLECTIONS_DELETE", // eliminar cobros
  INVOICES_SHOW: "INVOICES_SHOW", // mostrar facturas
  INVOICES_CREATE: "INVOICES_CREATE", // crear facturas
  INVOICES_UPDATE: "INVOICES_UPDATE", // actualizar facturas
  INVOICES_DELETE: "INVOICES_DELETE", // eliiminar facturas
  PAYROLLS_SHOW: "PAYROLLS_SHOW", // mostrar payrolls
  PAYROLLS_CREATE: "PAYROLLS_CREATE", // crear payrolls
  PAYROLLS_UPDATE: "PAYROLLS_UPDATE", // actualizar payrolls
  PAYROLLS_DELETE: "PAYROLLS_DELETE", // eliminar payrolls
  APPROVAL_INVOICES: "APPROVAL_INVOICES", // necesita permiso para aprobar las facturas
  APPROVAL_COLLECTIONS: "APPROVAL_COLLECTIONS", // necesita permiso para aprobar las cobros
  APPROVAL_PAYROLLS: "APPROVAL_PAYROLLS", // necesita permiso para aprobar las payrolls
  SELECT_COLLECTIONS: "SELECT_COLLECTIONS", // habilitar checkbox tabla cobros
  SELECT_INVOICES: "SELECT_INVOICES", // habilitar checkbox tabla cuentas por pagar
  SHOW_FOOTER_INVOICES: "SHOW_FOOTER_INVOICES", // mostrar footer en cuentas por pagar
  SHOW_FOOTER_PAYROLLS: "SHOW_FOOTER_PAYROLLS", // mostrar footer en nómina
  SHOW_FOOTER_COLLECTIONS: "SHOW_FOOTER_COLLECTIONS", // mostrar footer en cobros
};

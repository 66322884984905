export const ChevronRight = ({ pathClassName = "", color = "#5925DC" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      className={pathClassName}
      d="M7.5 15L12.5 10L7.5 5"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

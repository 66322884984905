import { NumericFormat } from "react-number-format";
import { styles } from "./styles";
import { RETE_ICA, RETE_FUENTE } from "constants";

const wordingsRetentionsRates = {
  retefuente: RETE_FUENTE,
  reteica: RETE_ICA,
};

const ComissionRetentions = ({ transaction }) => {
  return (
    transaction.amount_net_comission > 0 &&
    transaction.comission_retentions.length > 0 && (
      <>
        {transaction.comission_retentions.map((retention) => {
          return (
            <div key={retention.id}>
              <div style={styles.trxRow}>
                <div style={styles.column}>
                  <p
                    style={{
                      ...styles.rowTextLeftWithoutLimit,
                      marginTop: "-4px",
                    }}
                    className="card-subtitle mb-2"
                  >
                    {retention.key === "reteica"
                      ? `ReteICA (${wordingsRetentionsRates[retention.key]})`
                      : retention.key === "retefuente"
                      ? `ReteFuente (${wordingsRetentionsRates[retention.key]})`
                      : ""}
                  </p>
                </div>
                <p style={styles.rowText} className="card-subtitle mb-2">
                  <NumericFormat
                    value={parseInt(retention.amount)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"- $"}
                    decimalScale={2}
                    allowNegative={false}
                  />
                </p>
              </div>
              <hr style={styles.separator} />
            </div>
          );
        })}
        <div style={styles.trxRow}>
          <p style={styles.textWithoutLimit} className="card-subtitle mb-2">
            Neto comisión Payana
          </p>
          <p style={styles.rowText} className="card-subtitle mb-2">
            <NumericFormat
              value={parseInt(transaction.amount_net_comission)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </p>
        </div>
        <hr style={styles.separator} />
      </>
    )
  );
};

export default ComissionRetentions;

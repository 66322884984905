import { EmployeeRow } from "../commons";

const rowIsIncomplete = (row) => {
  const {
    employee_account_type,
    employee_bank_name,
    employee_account_number,
    employee_document_number,
    employee_document_type,
    employee_email,
  } = row.original;
  return (
    (!employee_account_type &&
      employee_bank_name?.toLowerCase() !== "nequi" &&
      employee_bank_name?.toLowerCase() !== "daviplata") ||
    !employee_account_number ||
    !employee_document_number ||
    !employee_document_type ||
    !employee_email
  );
};

export const salariesColumns = (setDrawerIsOpen) => [
  {
    field: "employee_name",
    Header: "Empleado",
    headerName: "Empleado",
    accessor: "employee_name",
    id: "employee_name",
    Cell: (column) => {
      const cell = column.cell;
      const row = column.cell.row;
      return (
        <EmployeeRow
          itemId={row.original.id}
          setDrawerIsOpen={setDrawerIsOpen}
          cell={cell}
          isIncomplete={rowIsIncomplete(row)}
          linkTo="/payroll/salaries/"
        />
      );
    },
  },
  {
    field: "employee_document_number",
    Header: "Nro. de identificación",
    headerName: "Nro. de identificación",
    accessor: "employee_document_number",
    id: "employee_document_number",
    Cell: ({ row }) =>
      row.original.employee_document_type +
      " | " +
      row.original.employee_document_number,
  },
  {
    field: "period",
    Header: "Concepto",
    headerName: "Concepto",
    accessor: "period",
    id: "period",
    Cell: ({ value }) => {
      return <p style={{ margin: 0, width: 200 }}>{value}</p>;
    },
  },
  // {
  //   field: "status",
  //   Header: "Estado",
  //   headerName: "Estado",
  //   accessor: "status",
  //   id: "status",
  // },
  {
    field: "employee_account_number",
    Header: "Nro. de cuenta",
    headerName: "Nro. de cuenta",
    accessor: "employee_account_number",
    id: "employee_account_number",
  },
  {
    field: "payroll_amount",
    Header: "Monto",
    headerName: "Monto",
    accessor: "amount",
    id: "amount",
  },
];

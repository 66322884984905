import React from "react";

export const AIStarsIcon = ({ color = "#FFFFFF", size = "24" }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M18.0856 9.2668H18.2731C18.2731 6.97461 20.1387 5.10898 22.4309 5.10898V4.92148C20.1387 4.92148 18.2731 3.05586 18.2731 0.763672H18.0856C18.0856 3.05586 16.2199 4.92148 13.9277 4.92148V5.10898C16.2199 5.10898 18.0856 6.97461 18.0856 9.2668Z"
      fill={color}
    />
    <path
      d="M18.2731 14.7324H18.0856C18.0856 17.0246 16.2199 18.8902 13.9277 18.8902V19.0777C16.2199 19.0777 18.0856 20.9434 18.0856 23.2355H18.2731C18.2731 20.9434 20.1387 19.0777 22.4309 19.0777V18.8902C20.1387 18.8902 18.2731 17.0246 18.2731 14.7324Z"
      fill={color}
    />
    <path
      d="M18.194 11.6323V11.4556C13.6795 11.4556 9.96887 7.74503 9.96887 3.23047H9.79217C9.79217 7.74503 6.08157 11.4556 1.56701 11.4556V11.6323C6.08157 11.6323 9.79217 15.3429 9.79217 19.8575H9.96887C9.96887 15.3429 13.6795 11.6323 18.194 11.6323Z"
      fill={color}
    />
  </svg>
);

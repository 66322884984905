export const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "437px",
  },
  title: {
    color: "#101828",
    fontSize: "16px",
    fontWeight: "500",
    marginTop: "12px",
    textAlign: "flex-start",
    alignSelf: "flex-start",
  },
  subtitle: {
    color: "#475467",
    fontSize: "14px",
    fontWeight: "500",
    maxWidth: "392px",
    textAlign: "flex-start",
    alignSelf: "flex-start",
    marginTop: "-12px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    justifyContent: "center",
  },
  modalSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
    marginRight: "12px",
    "&:focus": {
      border: "none",
    },
  },
  modalPrimaryButton: {
    backgroundColor: "#D92D20",
    border: "none",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    "&:focus": {
      border: "none",
    },
  },
  inputLabel: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
  },
  radioInputLabel: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#344054",
    marginLeft: "2px",
  },
};

import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { TrackJS } from "trackjs";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteItemModal,
  MarkAsPaidModal,
  NotifyWompiModal,
  Submenu,
  Table,
  TableEmptyState,
  TableSkeleton,
  UploadFileModal,
  PaymentModal,
  SiigoIntegrationModal,
  LinearProgressModal,
  AttentionModal,
  UploadResultModal,
  SyncBanner,
  Tooltip,
  CausationModal,
  LimitExceededModal,
} from "commons";
import {
  filterOptions,
  getFromLocalStorage,
  hasSomeAssociatedDocumentDeleted,
  invoicesColumns,
  useIsUserAllowedTo,
  openSiigoSyncStep,
  paymentsScreens,
  saveInLocalStorage,
  SortingQuery,
} from "utils";
import { styles } from "./styles";
import {
  ActionsDropdown,
  CausationFilterDropdown,
  CommunicationBanner,
  InvoicesEmptyState,
  NewDropdown,
  StatusFilters,
  SyncAccountingSystem,
  TagsDropdown,
  SyncDropdown,
  CausationBanner,
  CausationInfoBanner,
  ReconciliationsBanner,
} from "./components";
import {
  GetTags,
  GetTotalInvoices,
  SearchSuppliers,
  UpdateInvoice,
  UpdateSupplier,
  ShowBannerCausation,
} from "services";
import { PaymentsContext, TransactionsContext, UserContext } from "contexts";
import { PaymentObligationModal } from "commons/modals/PaymentObligationModal";
import { ExpandIcon, SearchIcon } from "assets";
import { InvoiceFooter } from "./components/FooterHandler";
import { WelcomeModal } from "commons/modals/WelcomeModal";
import { TxReferenceModal } from "commons/modals/TxReferenceModal";
import { RegisterSourceOfFundsModal } from "commons/modals/RegisterSourceOfFundsModal";
import { useMediaQuery } from "react-responsive";
import { DianIntegrationModal } from "commons/modals/DianIntegrationModal/DianIntegrationModal";
import CsvHandler from "commons/modals/UploadFileModal/CsvHandler";
import DrawerSupplierForm from "./DrawerSupplierForm";
import { useChat } from "hooks";
import { AlegraIntegrationModal } from "commons/modals/AlegraIntegrationModal/AlegraIntegrationModal";
import CompleteProvidersBeforePayModal from "./CompleteProviderBeforePayModal";
import isSupplierIncomplete from "./isSupplierIncomplete";
import { BLOCKED_MESSAGE, permissions, SERVICES } from "../../constants";
import { getInterrumptedServiceCommunication } from "services/api/communications";
import { SupplierDetails } from "commons/modals/SupplierDetails";
import {
  CommunicationMultipleSelect,
  MarkAsPendingModal,
  SubscriptionModal,
  CommunicationServicesPayment,
} from "commons/modals";
import { InvoicesProcess } from "./components/InvoicesProcess";

const INCOMPLETE_SUPPLIER_MODAL_KEY =
  "entering_payment_with_supplier_incomplete_disclamer";

const getRandomInvoiceNumber = () =>
  `ANT-${Math.floor(100000 + Math.random() * 900000)}`;

export const InvoicesScreen = () => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [createdSupplier, setCreatedSupplier] = useState(null);
  const [createdService, setCreatedService] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [openProviderIncomplete, setOpenProviderIncomplete] = useState(false);
  const [openBlockedModal, setOpenBlockedModal] = useState(false);
  const [modalKeys, setModalKeys] = useState([]);
  const [openDeletedInvoicesAlert, setOpenDeletedInvoicesAlert] =
    useState(false);
  const [limitExceededAlert, setLimitExceededAlert] = useState({});
  const [isPageInitialLoading, setIsPageInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const showModal = (key, flow) => {
    setModalKeys((keys) => [...keys, key]);
    setFlow(flow);
  };
  const closeModal = (key) => {
    setModalKeys((keys) => keys.filter((innerKey) => innerKey !== key));
  };

  const isChecked = (row) =>
    !!selectedRows.find((selectedRow) => selectedRow.original.id === row.id);

  const toggleSelectedRow = (row) => {
    if (isChecked(row)) {
      subscription?.handleSetIsPayanaInvoice((prev) =>
        row.origin === "api" ? false : prev
      );
      setSelectedRows((rows) => rows.filter((r) => r.original.id !== row.id)); // si ya está seleccionada, la quitamos
    } else {
      subscription?.handleSetIsPayanaInvoice((prev) =>
        row.origin === "api" ? true : prev
      );
      setSelectedRows((rows) => [...rows, { original: row }]); // si no, agregamos la row las seleccionadas
    }
  };

  const toggleSelectedIds = (ids) => {
    ids.map((id) =>
      setSelectedRows((rows) => rows.filter((r) => r.original.id !== id))
    );
  };

  const toggleSelectedAll = (row, selectAllChecked) => {
    if (selectAllChecked) {
      if (row.origin === "api") {
        subscription?.handleSetIsPayanaInvoice(true);
      }
      !isChecked(row) &&
        setSelectedRows((rows) => [...rows, { original: row }]);
    } else {
      subscription?.handleSetIsPayanaInvoice(false);
      setSelectedRows((rows) => rows.filter((r) => r.original.id !== row.id));
    }
  };
  const navigate = useNavigate();
  const [totals, setTotals] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [defaultInvoiceNumber, setDefaultInvoiceNumber] = useState(
    getRandomInvoiceNumber()
  );
  let selectedRowsAmount = 0;
  let selectedRowsIdsArr = [];
  let selectedSuppliersArr = [];
  const {
    invoices,
    internalGetInvoices,
    deleteSelectedInvoices,
    statusSelected,
    setStatusSelected,
    perPageSelected,
    setPerPageSelected,
    sortingSelected,
    setSortingQuery,
    invoicesScreen,
    getSuppliers,
    tagsSelected,
    setTagsSelected,
    suppliers,
    selectFilterCausation,
    setSelectFilterCausation,
    subscription,
  } = useContext(PaymentsContext);
  const { createInvoicesPayLaterTransaction, getTransactions } =
    useContext(TransactionsContext);
  const {
    getMe,
    currentCompany,
    currentUser,
    sourceOfFundsNeeded,
    canPayWithCobre,
    displayCobreAlert,
    canIntegrateWithDian,
    blockedCompany,
    blockedCausation,
    hasSiigoCredentials,
    isFeatureEnabled,
  } = useContext(UserContext);
  const [invoiceToDelete, setInvoiceToDelete] = useState();
  const [isDianModalVisible, setIsDianModalVisible] = useState(false);
  const [isSiigoModalVisible, setIsSiigoModalVisible] = useState(false);
  const [openModalAlegra, setOpenModalAlegra] = useState(false);
  const [initialStep, setInitialStep] = useState(null);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const providerIdSelected = invoicesScreen?.providerIdSelected?.value;
  const setProviderIdSelected = invoicesScreen?.providerIdSelected?.setter;
  const serviceIdSelected = invoicesScreen?.serviceIdSelected?.value;
  const [tags, setTags] = useState([]);
  const [flow, setFlow] = useState(null);
  const [causationXSellingBannerVisible, setCausationXSellingBannerVisible] =
    useState(false);
  const [causationInfoBannerVisible, setCausationInfoBannerVisible] =
    useState(false);
  const [communication, setCommunication] = useState({ is_active: false });
  const [communicationHash, setCommunicationHash] = useState("");
  const [communicationVisible, setCommunicationVisible] = useState(false);

  const syncBanner = invoicesScreen?.syncBanner?.value;
  const setSyncBanner = invoicesScreen?.syncBanner?.setter;

  const isCommunicationVisible = communicationVisible !== "false";

  const onTagSelect = (tags) => {
    setTagsSelected(tags);
    setPageIndex(1);
  };

  const onTagRemove = (tags) => {
    setTagsSelected(tags);
    setPageIndex(1);
  };

  const pageIndex = invoicesScreen?.pageIndex;
  const setPageIndex = invoicesScreen?.setPageIndex;

  const onStatusSelectedChanged = (status) => {
    setLoading(true);
    setStatusSelected(status);
    setPageIndex(1);
  };

  useChat();

  const setSorting = (fieldToSort) => {
    const sq = new SortingQuery(sortingSelected);
    sq.addField(fieldToSort);
    setSortingQuery(sq.sort);
  };

  const [causationModalIsVisible, setCausationModalIsVisible] = useState(
    getFromLocalStorage("isCausationModalVisible") || "true"
  );
  const isCausationBannerVisible =
    getFromLocalStorage("isCausationBannerVisible") !== "false";
  const isCausationModalVisible = causationModalIsVisible !== "false";

  const [causationInfoVisible, setCausationInfoVisible] = useState(
    getFromLocalStorage("isCausationInfoVisible") || "true"
  );
  const isCausationInfoVisible = causationInfoVisible !== "false";

  const showCausationBanner = () => {
    return (
      !currentCompany.isCausationEnabled &&
      (currentCompany?.name_cs?.toLowerCase() === "dian" ||
        currentCompany?.name_cs?.toLowerCase() === "siigo") &&
      currentCompany.last_sync_at &&
      isCausationBannerVisible
    );
  };

  const [causationBannerVisible, setCausationBannerVisible] = useState(
    showCausationBanner()
  );

  const [reconciliationsBannerVisible, setReconciliationsBannerVisible] =
    useState(getFromLocalStorage("isReconciliationsBannerVisible") || "true");

  const isReconciliationsBannerVisible =
    reconciliationsBannerVisible !== "false";

  useEffect(() => {
    setCausationBannerVisible(showCausationBanner());
  }, [currentCompany?.last_sync_at]);

  useEffect(() => {
    getMe();
    getSuppliers(1, "all");
    GetTags().then((res) => {
      setTags(res);
    });
    refreshInvoiceTable().finally(() => {
      setIsPageInitialLoading(false);
    });
    getInterrumptedServiceCommunication().then((res) => {
      setCommunication(res);
      setCommunicationHash(window.btoa(res.description));
      setCommunicationVisible(
        getFromLocalStorage(window.btoa(res.description))
      );
    });
    setCausationBannerVisible(showCausationBanner());
    ShowBannerCausation().then((res) => {
      setCausationXSellingBannerVisible(res.hasCausationsWithoutPayments);
      const currentDate = new Date("2024/06/21");
      setCausationInfoBannerVisible(
        res.lastCausationDate
          ? currentDate >= new Date(res.lastCausationDate)
          : true
      );
    });
  }, []);

  useEffect(() => {
    if (currentUser.backoffice_user) {
      navigate("/backoffice-receipts", { replace: true });
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    location.state &&
      location.state.openCreateObligationModal &&
      openPaymentObligationModal();
  }, []);

  useEffect(() => {
    const { state } = location;
    if (state?.action === "paySubscription") {
      const { totalAmount, selectedRowsIds } = state;
      setSelectedRowsIds(selectedRowsIds);
      setTotalAmount(totalAmount);
      setWompiModalIsVisible(true);
      window.history.replaceState({}, document.title, location.pathname);
    }
  }, []);

  useEffect(() => {
    if (!isPageInitialLoading) {
      refreshInvoiceTable();
    }
  }, [
    pageIndex,
    statusSelected,
    providerIdSelected,
    serviceIdSelected,
    perPageSelected,
    sortingSelected,
    tagsSelected,
    selectFilterCausation,
  ]);

  useEffect(() => {
    if (totals) {
      if (totals.total > perPageSelected) {
        setTotalPages(Math.ceil(totals.total / perPageSelected));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const [amountTotalInvoicePayana, setAmountTotalInvoicePayana] = useState(0);
  let amountTotalPayana = 0;
  useEffect(() => {
    //obtener array de ids para la transacción
    if (location?.state?.action !== "paySubscription") {
      selectedRows.forEach((row) => {
        if (row.original.amount_total) {
          if (row.original.origin === "api") {
            amountTotalPayana =
              amountTotalPayana + parseInt(row.original.amount_total);
          }
          selectedRowsAmount =
            selectedRowsAmount + parseInt(row.original.amount_total);
        }
        selectedRowsIdsArr.push(row.original.id);
        if (row.original.origin === "api") {
          return;
        }
        if (
          row.original.provider_id &&
          !selectedSuppliersArr.includes(row.original.provider_id)
        ) {
          selectedSuppliersArr.push(row.original.provider_id);
        }
        row.original.service_id &&
          selectedSuppliersArr.push(row.original.service_id);
      });
      setAmountTotalInvoicePayana(amountTotalPayana);
      setTotalAmount(selectedRowsAmount);
      setSelectedRowsIds(selectedRowsIdsArr);
      setSelectedSuppliers(selectedSuppliersArr);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      let rowsUpdated = [];
      selectedRows.forEach((row) => {
        const invoiceFromApi = invoices.find(
          (invoice) => invoice.id === row.original.id
        );
        if (
          invoiceFromApi &&
          JSON.stringify({ original: invoiceFromApi }) !== JSON.stringify(row)
        ) {
          rowsUpdated.push({ original: invoiceFromApi });
        }
      });
      if (rowsUpdated.length > 0) {
        const selectedRowsUpdated = [...selectedRows].filter(
          (row) => row.original.id !== rowsUpdated[0]?.original?.id
        );
        selectedRowsUpdated.push(rowsUpdated[0]);
        setSelectedRows(selectedRowsUpdated);
      }
    }
  }, [invoices]);

  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const payment_obligation = queryParameters.get("payment_obligation");

  const [markAsPaidModalIsVisible, setMarkAsPaidModalIsVisible] =
    useState(false);
  const [markAsPendingModalIsVisible, setMarkAsPendingModalIsVisible] =
    useState(false);
  const [wompiModalIsVisible, setWompiModalIsVisible] = useState(false);
  const [drawerState, setDrawerState] = useState({ key: "none" });

  const [uploadFileModalIsVisible, setUploadFileModalIsVisible] =
    useState(false);
  const [deleteInvoiceModalIsVisible, setDeleteInvoiceModalIsVisible] =
    useState(false);
  const [paymentObligationModalIsVisible, setPaymentObligationModalIsVisible] =
    useState(payment_obligation ? true : false);
  const [obligationModalType, setObligationModalType] = useState("invoice");
  const [notifyWompiModalIsVisible, setNotifyWompiModalIsVisible] =
    useState(id);
  const [isLinearProgressModalVisible, setIsLinearProgressModalVisible] =
    useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [uploadType, setUploadType] = useState();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [resultModalIsOpen, setResultModalIsOpen] = useState(false);
  const [uploadResult, setUploadResult] = useState();
  const invoicesCsvButton = useRef(null);
  const [showCausationModal, setShowCausationModal] = useState(false);

  const getSearchOptions = (query) => {
    SearchSuppliers({ query: query, withAnayap: true }).then((res) => {
      if (res instanceof Error) {
        setSearchOptions([]);
      } else {
        setSearchOptions(res);
      }
    });
  };

  const payLaterAction = () => {
    createInvoicesPayLaterTransaction(selectedRowsIds).then((res) => {
      if (res?.errors) {
        setLimitExceededAlert((limitExceeded) => {
          return {
            ...limitExceeded,
            visible: true,
            availableAmount: res.errors[0].availableAmount,
            limitAmount: res.errors[0].limitAmount,
          };
        });
      } else if (hasSomeAssociatedDocumentDeleted(res)) {
        refreshInvoiceTable();
        setSelectedRows([]);
        subscription?.handleSetIsPayanaInvoice(false);
        setOpenDeletedInvoicesAlert(true);
      } else {
        getTransactions(1, "invoice");
        navigate("/payments/transactions", { replace: true });
      }
    });
  };

  const payLater = () => {
    if (blockedCompany) {
      setOpenBlockedModal(true);
    } else {
      const invoicesWithIncompleteProvider = hasOneIncomplete();
      if (invoicesWithIncompleteProvider.length > 0) {
        showModal(INCOMPLETE_SUPPLIER_MODAL_KEY, "pay_later");
      } else {
        payLaterAction();
      }
    }
  };

  const closeMarkAsPaidModal = (ids) => {
    setMarkAsPaidModalIsVisible(false);
    ids && toggleSelectedIds(ids);
  };
  const openMarkAsPaidModal = () => setMarkAsPaidModalIsVisible(true);

  const closeMarkAsPendingModal = (ids) => {
    setMarkAsPendingModalIsVisible(false);
    ids && toggleSelectedIds(ids);
  };
  const openMarkAsPendingModal = () => setMarkAsPendingModalIsVisible(true);

  const closeUploadFileModal = () => setUploadFileModalIsVisible(false);
  const openUploadFileModal = (type) => {
    if (type === "CSV") {
      invoicesCsvButton.current.click();
    } else {
      setUploadType(type);
      setUploadFileModalIsVisible(true);
    }
  };
  const closeDeleteInvoiceModal = () => setDeleteInvoiceModalIsVisible(false);
  const openDeleteInvoiceModal = (id) => {
    setInvoiceToDelete(id);
    setDeleteInvoiceModalIsVisible(true);
  };
  const closePaymentObligationModal = (redirect = true) => {
    redirect &&
      navigate("/payments/invoices", {
        replace: true,
        state: {},
      });
    setPaymentObligationModalIsVisible(false);
  };
  const openPaymentObligationModal = (type) => {
    setObligationModalType(type);
    setPaymentObligationModalIsVisible(true);
    setDefaultInvoiceNumber(getRandomInvoiceNumber());
  };
  const closeNotifyWompiModal = (id) => {
    setNotifyWompiModalIsVisible(false);
    navigate("/transactions", {
      replace: true,
    });
  };

  const handleSearchChange = (sup) => {
    if (sup) {
      setProviderIdSelected(sup.id);
      invoicesScreen?.supplierFilter?.setter(sup);
    } else {
      setProviderIdSelected("all");
      invoicesScreen?.supplierFilter?.setter({});
    }
    setPageIndex(1);
  };

  const disableExpand = (target, html) => {
    const dropdownOptions =
      html.toLowerCase().includes("dropdown") ||
      html.toLowerCase().includes("descargar") ||
      html.toLowerCase().includes("eliminar") ||
      html.toLowerCase().includes("causación") ||
      html.toLowerCase().includes("causada");

    const modalOptions =
      html.toLowerCase().includes("monto") ||
      html.toLowerCase().includes("$") ||
      html.toLowerCase().includes("pago") ||
      html.toLowerCase().includes("modal");

    return (
      target === "img" ||
      target === "input" ||
      target === "svg" ||
      target === "button" ||
      target === "path" ||
      target === "label" ||
      (dropdownOptions && target === "p") ||
      (dropdownOptions && target === "div") ||
      modalOptions
    );
  };

  const handleOnClickRow = (row, target, html) => {
    !disableExpand(target, html) &&
      navigate(`/payments/invoices/${row.original.id}`);
  };

  const handleCloseProviderIncomplete = () => {
    setOpenProviderIncomplete(false);

    const invoicesWithIncompleteProvider = hasOneIncomplete();

    if (invoicesWithIncompleteProvider.length > 0) {
      setDrawerState({
        key: "complete",
        updated: [],
        providerIdsToComplete: invoicesWithIncompleteProvider.map(
          (invoice) => invoice.original.provider_id
        ),
      });
    } else {
      setDrawerIsOpen(openProviderIncomplete.provider_id);
    }
  };

  const handleCloseDeletedInvoicesAlert = () => {
    setOpenDeletedInvoicesAlert(false);
  };

  const handleCloseLimitExceededAlert = () => {
    setLimitExceededAlert((limitExceeded) => {
      return {
        ...limitExceeded,
        visible: false,
      };
    });
  };

  const renderRowEndButtons = (row) => {
    if (!isUserAllowedTo(permissions.INVOICES_DELETE)) {
      return;
    }

    if (isMobile) {
      return (
        <>
          <ActionsDropdown
            deleteAction={openDeleteInvoiceModal}
            row={row.original}
            disabled={isSupplierIncomplete(row)}
          />
        </>
      );
    } else {
      return (
        <td style={{ backgroundColor: "transparent" }}>
          <Tooltip title="Ver detalle" offset={0}>
            <span
              onClick={() => navigate(`/payments/invoices/${row.original.id}`)}
              style={{ marginRight: "16px" }}
            >
              <ExpandIcon />
            </span>
          </Tooltip>
        </td>
      );
    }
  };

  const renderAutocomplete = () => {
    return (
      <>
        <Autocomplete
          options={searchOptions}
          size="small"
          sx={{
            width: isMobile ? "100%" : "350px",
            minWidth: isMobile ? "164px" : "350px",
          }}
          freeSolo
          filterOptions={filterOptions}
          getOptionLabel={(option) => {
            return option?.name || "";
          }}
          onChange={(event, option) => {
            if (option?.id) {
              handleSearchChange(option);
            } else {
              handleSearchChange();
            }
          }}
          onInputChange={(event) => {
            if (event?.target?.value?.length >= 3) {
              getSearchOptions(event.target.value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: 14 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={"Buscar proveedor"}
            />
          )}
          value={
            invoicesScreen?.supplierFilter?.value || {
              name: "",
            }
          }
        />
        {!isMobile && (
          <TagsDropdown
            options={tags}
            selectedValues={tagsSelected}
            onSelect={onTagSelect}
            onRemove={onTagRemove}
            setTags={setTags}
            setTagsSelected={setTagsSelected}
            className={"tagsList checkbox"}
          />
        )}
        {currentCompany.isCausationEnabled && (
          <CausationFilterDropdown
            selectFilterCausation={selectFilterCausation}
            setSelectFilterCausation={setSelectFilterCausation}
          />
        )}
      </>
    );
  };

  const handleConfirm = (id, type = "invoice") => {
    navigate("/transactions", {
      replace: true,
    });
  };

  const refreshInvoiceTable = async () => {
    setLoading(true);
    try {
      await internalGetInvoices(
        pageIndex,
        statusSelected,
        providerIdSelected,
        serviceIdSelected,
        perPageSelected,
        sortingSelected,
        tagsSelected,
        selectFilterCausation
      );
      const totalInvoices = await GetTotalInvoices({
        status: statusSelected,
        providerId: providerIdSelected,
        serviceId: serviceIdSelected,
        tags: tagsSelected,
        causation: selectFilterCausation?.value,
      });
      setTotals(totalInvoices);
      getMe();
    } catch (error) {
      TrackJS.console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const closeWompiModal = () => {
    refreshInvoiceTable();
    subscription?.handleSetIsPayanaInvoice(false);
    setSelectedRows([]);
    setWompiModalIsVisible(false);
  };

  useEffect(() => {
    return subscription?.handleSetIsPayanaInvoice(false);
  }, []);

  const invoiceIsIncomplete = (invoiceToCheck) => {
    if (invoiceToCheck.service_id) {
      return false;
    } else {
      const { id } = invoiceToCheck;
      const invoice = invoices.find((invoice) => invoice.id === id);
      if (!invoice) {
        return false;
      }
      return (
        !invoice.provider_name ||
        !invoice.provider_document_type ||
        !invoice.provider_document_number ||
        !invoice.provider_bank_name ||
        (!invoice.provider_account_type &&
          invoice.provider_bank_name.toLowerCase() !== "nequi" &&
          invoice.provider_bank_name.toLowerCase() !== "daviplata") ||
        !invoice.provider_account_number
      );
    }
  };

  const hasOneIncomplete = (supplier = {}) => {
    const dictionary = selectedRows
      .filter((invoice) => {
        return (
          invoice.original.status === "pending" &&
          invoiceIsIncomplete(invoice.original) &&
          invoice.original.provider_id !== supplier?.id
        );
      })
      .reduce((acc, invoice) => {
        acc[invoice.original.provider_id] = invoice;
        return acc;
      }, {});
    return Object.values(dictionary);
  };

  const openProvidersToCompleteModal = () => {
    closeModal(INCOMPLETE_SUPPLIER_MODAL_KEY);
    const invoicesWithIncompleteProvider = hasOneIncomplete();
    if (invoicesWithIncompleteProvider.length > 0) {
      setDrawerState({
        key: "complete",
        updated: [],
        providerIdsToComplete: invoicesWithIncompleteProvider.map(
          (invoice) => invoice.original.provider_id
        ),
      });
    }
  };

  const navigateToCausationInvoice = (row) => {
    return navigate(`/payments/causation/${row.id}`, {
      replace: true,
      ...(row.type === "collection_account" &&
        row.pdf_state === "in_process" && {
          state: {
            createCollectionAccount: true,
            invoiceNumber: row.invoice_number,
            amount: row.amount_total,
            issueDate: new Date(row.issue_date),
          },
        }),
    });
  };

  const openResultModal = (uploadResult) => {
    setResultModalIsOpen(true);
    setUploadResult(uploadResult);
  };

  const closeResultModal = () => {
    refreshInvoiceTable();
    setResultModalIsOpen(false);
  };

  const handleUploadResultNextStep = (option, invoices, supplier) => {
    if (option === "yes") {
      invoices.map((invoice) =>
        UpdateInvoice({
          id: parseInt(invoice.id),
          formValues: { provider_id: supplier.id },
        })
      );
      return true;
    } else if (option === "update") {
      UpdateSupplier({
        id: supplier.id,
        formValues: supplier,
      }).then(() => {
        invoices.map((invoice) =>
          UpdateInvoice({
            id: parseInt(invoice.id),
            formValues: { provider_id: supplier.id },
          })
        );
      });
      return true;
    }
  };

  const handleOpenModalPayment = ({ totalAmount, selectedRowsIds }) => {
    setSelectedRows([]);

    setTimeout(() => {
      setSelectedSuppliers([]);
      setSelectedRowsIds(selectedRowsIds);
      setTotalAmount(totalAmount);
      setAmountTotalInvoicePayana(totalAmount);
      setWompiModalIsVisible(true);
    }, 1000);
  };

  const emptyState =
    !loading &&
    invoices &&
    invoices.length === 0 &&
    statusSelected === "all" &&
    providerIdSelected === "all" &&
    serviceIdSelected === "all" &&
    tagsSelected.length === 0 &&
    selectFilterCausation?.value === "";

  const showBannerInvoicesPDFPending = invoices?.some(
    (invoice) =>
      invoice.pdf_state === "in_process" &&
      invoice.origin === "dian" &&
      invoice.causation_state === "draft"
  );

  return (
    <>
      <CommunicationMultipleSelect
        isCausationEnabled={currentCompany?.isCausationEnabled}
      />
      <CommunicationServicesPayment />
      {sourceOfFundsNeeded ? (
        <RegisterSourceOfFundsModal
          type="invoice"
          visible={wompiModalIsVisible}
          totalAmount={totalAmount}
          handleClose={closeWompiModal}
          selectedRowsIds={selectedRowsIds}
          selectedSuppliers={selectedSuppliers}
          canPayWithCobre={canPayWithCobre}
          displayCobreAlert={displayCobreAlert}
          setOpenDeletedAlert={setOpenDeletedInvoicesAlert}
          setLimitExceededAlert={setLimitExceededAlert}
          amountTotalInvoicePayana={amountTotalInvoicePayana}
        />
      ) : (
        <PaymentModal
          type="invoice"
          visible={wompiModalIsVisible}
          totalAmount={totalAmount}
          handleClose={closeWompiModal}
          selectedRowsIds={selectedRowsIds}
          selectedSuppliers={selectedSuppliers}
          canPayWithCobre={canPayWithCobre}
          displayCobreAlert={displayCobreAlert}
          setOpenDeletedAlert={setOpenDeletedInvoicesAlert}
          setLimitExceededAlert={setLimitExceededAlert}
          amountTotalInvoicePayana={amountTotalInvoicePayana}
        />
      )}
      {canIntegrateWithDian && (
        <DianIntegrationModal
          visible={isDianModalVisible}
          setIsDianModalVisible={setIsDianModalVisible}
          refreshInvoiceTable={refreshInvoiceTable}
          setSyncBanner={setSyncBanner}
        />
      )}
      {subscription?.showModalSubscription && (
        <SubscriptionModal
          subscription={subscription}
          openWompiModal={handleOpenModalPayment}
        />
      )}
      <AlegraIntegrationModal
        visible={openModalAlegra}
        onClose={() => setOpenModalAlegra(false)}
        refreshInvoiceTable={refreshInvoiceTable}
        section={"invoices"}
      />
      <SiigoIntegrationModal
        visible={isSiigoModalVisible}
        setIsSiigoModalVisible={setIsSiigoModalVisible}
        refreshTable={refreshInvoiceTable}
        initialStep={initialStep}
        setSyncBanner={setSyncBanner}
      />
      <CsvHandler
        licenseKey={process.env.REACT_APP_CSVBOX_INVOICES_LICENSE_KEY}
        buttonRef={invoicesCsvButton}
        setTotals={setTotals}
        openResultModal={openResultModal}
      />
      <WelcomeModal />
      <TxReferenceModal onConfirm={handleConfirm} />
      <MarkAsPaidModal
        visible={markAsPaidModalIsVisible}
        handleClose={closeMarkAsPaidModal}
        selectedRowsIds={selectedRowsIds}
      />
      <MarkAsPendingModal
        visible={markAsPendingModalIsVisible}
        handleClose={closeMarkAsPendingModal}
        selectedRowsIds={selectedRowsIds}
      />
      <DeleteItemModal
        itemsIds={invoiceToDelete}
        action={deleteSelectedInvoices}
        handleClose={closeDeleteInvoiceModal}
        title="¿Quieres borrar estas facturas?"
        subtitle="Si las eliminas, no aparecerán más en Payana"
        visible={deleteInvoiceModalIsVisible}
        cleanSelectedRows={() => setSelectedRows([])}
        pageIndex={pageIndex}
      />
      <UploadFileModal
        entity={"invoices"}
        type={uploadType}
        visible={uploadFileModalIsVisible}
        handleClose={closeUploadFileModal}
        setTotals={setTotals}
      />
      <PaymentObligationModal
        setDrawerIsOpen={setDrawerIsOpen}
        createdSupplier={createdSupplier}
        createdService={createdService}
        visible={paymentObligationModalIsVisible}
        handleClose={closePaymentObligationModal}
        defaultInvoiceNumber={defaultInvoiceNumber}
        options={tags}
        selectedValues={tagsSelected}
        setTags={setTags}
        type={obligationModalType}
        setLimitExceededAlert={setLimitExceededAlert}
      />
      <NotifyWompiModal
        visible={notifyWompiModalIsVisible}
        handleClose={closeNotifyWompiModal}
      />
      <AttentionModal
        title="¡Falta información sobre tu proveedor!"
        description="Para realizar el pago de esta obligación debes completar la información faltante (Banco, tipo y número de cuenta). Adicionalmente, si incluyes el correo electrónico y el número de celular, le enviaremos un aviso de pago."
        onClose={handleCloseProviderIncomplete}
        open={openProviderIncomplete}
      />
      <AttentionModal
        title="Parece que uno o más de los documentos seleccionados están eliminados."
        description="Por favor, vuelve a realizar la selección."
        buttonText="Entendido"
        onClose={handleCloseDeletedInvoicesAlert}
        open={openDeletedInvoicesAlert}
      />
      <LimitExceededModal
        onClose={handleCloseLimitExceededAlert}
        data={limitExceededAlert}
      />
      <CompleteProvidersBeforePayModal
        numberOfIncompleted={hasOneIncomplete().length}
        visible={modalKeys.includes(INCOMPLETE_SUPPLIER_MODAL_KEY)}
        close={() => {
          closeModal(INCOMPLETE_SUPPLIER_MODAL_KEY);
        }}
        primaryAction={openProvidersToCompleteModal}
      />
      <AttentionModal
        title={BLOCKED_MESSAGE}
        onClose={() => setOpenBlockedModal(false)}
        open={openBlockedModal}
      />
      <UploadResultModal
        visible={resultModalIsOpen}
        handleClose={closeResultModal}
        uploadResult={uploadResult}
        handleNextStep={handleUploadResultNextStep}
        warningTitle="¿Estás seguro que deseas salir sin asociar proveedores?"
        warningText="Si sales, las facturas se cargarán en el sistema sin estar
        asociadas a ningún proveedor y deberás ingresar esta información
        manualmente más tarde."
      />
      <LinearProgressModal
        title="Sincronizando Siigo"
        subtitle="Este proceso puede demorar hasta 3 minutos. Por favor no cierres esta
          ventana."
        visible={isLinearProgressModalVisible}
        handleClose={() => {
          setIsLinearProgressModalVisible(false);
        }}
      />
      {currentCompany.isCausationEnabled && !isMobile && (
        <CausationModal
          visible={showCausationModal}
          handleClose={() => {
            saveInLocalStorage("isCausationModalVisible", "false");
            setCausationModalIsVisible("false");
            setShowCausationModal(false);
          }}
        />
      )}
      {/* <NewFeatureModal
        visible={isMarkAsPendingNewModalVisible && invoices?.length > 0}
        handleClose={() => {
          saveInLocalStorage("showMarkAsPendingNewModal", "false");
          setShowMarkAsPendingNewModal("false");
        }}
        title="Ahora puedes enviar a “por pagar” una factura que hayas marcado como pagada."
        image={<MarkAsPending />}
      /> */}
      <div
        style={{ marginBottom: "150px", backgroundColor: "#FFFFFF" }}
        id="page-wrap"
      >
        {suppliers.length > 0 && <Submenu items={paymentsScreens} />}
        {isPageInitialLoading ? (
          <div className="fade-in-with-translation">
            <TableSkeleton />
          </div>
        ) : emptyState ? (
          <InvoicesEmptyState
            openUploadFileModal={openUploadFileModal}
            openPaymentObligationModal={openPaymentObligationModal}
            setIsDianModalVisible={setIsDianModalVisible}
            setIsSiigoModalVisible={setIsSiigoModalVisible}
            setIsLinearProgressModalVisible={setIsLinearProgressModalVisible}
            setOpenModalAlegra={setOpenModalAlegra}
            setInitialStep={setInitialStep}
            setSyncBanner={setSyncBanner}
          />
        ) : (
          <>
            <div className="layout-container">
              <div style={styles.titleContainer}>
                <Typography sx={styles.title} noWrap>
                  Cuentas por pagar
                </Typography>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  {!currentCompany.name_cs && !currentCompany.last_sync_at ? (
                    <SyncDropdown
                      setIsDianModalVisible={setIsDianModalVisible}
                      setIsSiigoModalVisible={setIsSiigoModalVisible}
                      setOpenModalAlegra={setOpenModalAlegra}
                      setInitialStep={setInitialStep}
                      disabled={!isUserAllowedTo(permissions.INVOICES_CREATE)}
                      setSyncBanner={setSyncBanner}
                    />
                  ) : currentCompany.name_cs !== "DIAN" &&
                    currentCompany.last_sync_at ? (
                    <SyncAccountingSystem
                      openSiigoIntegrationModal={() => {
                        currentCompany.user_cs && currentCompany.password_cs
                          ? openSiigoSyncStep(
                              4,
                              setInitialStep,
                              setIsSiigoModalVisible
                            )
                          : setIsSiigoModalVisible(true);
                      }}
                      refreshInvoiceTable={() => refreshInvoiceTable()}
                      setSyncBanner={setSyncBanner}
                    />
                  ) : currentCompany.name_cs === "DIAN" &&
                    canIntegrateWithDian ? (
                    <SyncAccountingSystem
                      openDianIntegrationModal={() =>
                        setIsDianModalVisible(true)
                      }
                      setSyncBanner={setSyncBanner}
                    />
                  ) : null}
                  <NewDropdown
                    openUploadFileModal={openUploadFileModal}
                    openPaymentObligationModal={openPaymentObligationModal}
                    disabled={!isUserAllowedTo(permissions.INVOICES_CREATE)}
                    isCausationAvailable={currentCompany.isCausationEnabled}
                    blockedCausation={blockedCausation}
                  />
                </div>
              </div>
              {isReconciliationsBannerVisible && hasSiigoCredentials && (
                <ReconciliationsBanner
                  onClose={() => {
                    amplitude.logEvent("CLICK_RECONCILIATIONS_BANNER", {
                      user_id: currentUser.id,
                    });
                    saveInLocalStorage(
                      "isReconciliationsBannerVisible",
                      "false"
                    );
                    setReconciliationsBannerVisible("false");
                  }}
                />
              )}
              {!emptyState &&
                communication &&
                communication.is_active &&
                isCommunicationVisible && (
                  <CommunicationBanner
                    title={communication.title}
                    description={communication.description}
                    setCommunicationHash={setCommunicationHash}
                    saveInLocalStorage={saveInLocalStorage}
                    setCommunicationVisible={setCommunicationVisible}
                  />
                )}
              {causationBannerVisible && (
                <CausationBanner
                  saveInLocalStorage={saveInLocalStorage}
                  setCausationBannerVisible={setCausationBannerVisible}
                  type={currentCompany?.name_cs?.toLowerCase()}
                />
              )}
              {causationXSellingBannerVisible && (
                <CausationBanner
                  type={"xselling"}
                  saveInLocalStorage={saveInLocalStorage}
                  setCausationBannerVisible={setCausationBannerVisible}
                />
              )}
              {currentCompany.isCausationEnabled &&
                !isMobile &&
                causationInfoBannerVisible &&
                isCausationInfoVisible && (
                  <CausationInfoBanner
                    setShowCausationModal={setShowCausationModal}
                    saveInLocalStorage={saveInLocalStorage}
                    setCausationModalVisible={setCausationModalIsVisible}
                    setCausationInfoVisible={setCausationInfoVisible}
                  />
                )}
              {showBannerInvoicesPDFPending && (
                <InvoicesProcess
                  reloadInvoices={refreshInvoiceTable}
                  onClick={refreshInvoiceTable}
                />
              )}
              {syncBanner.visible && (
                <SyncBanner
                  title={syncBanner.title}
                  description={syncBanner.description}
                  type={syncBanner.type}
                  setSyncBanner={setSyncBanner}
                />
              )}
              {emptyState ? null : isMobile ? (
                <StatusFilters
                  statusSelected={statusSelected}
                  setStatusSelected={onStatusSelectedChanged}
                  isMobile={isMobile}
                />
              ) : (
                <div className="invoices-data-filters">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                      margin: "0px 2.5%",
                      justifyContent: "space-between",
                      flex: "0 0 auto",
                    }}
                  >
                    <StatusFilters
                      statusSelected={statusSelected}
                      setStatusSelected={onStatusSelectedChanged}
                      isMobile={isMobile}
                    />
                  </Box>
                </div>
              )}
              <Table
                loading={loading}
                loadingSkeleton={<TableSkeleton />}
                pageNumber={pageIndex}
                columns={invoicesColumns(
                  (supplier) => setDrawerIsOpen(supplier),
                  setSorting,
                  sortingSelected,
                  navigateToCausationInvoice,
                  currentCompany.isCausationEnabled,
                  refreshInvoiceTable,
                  blockedCausation
                )}
                data={invoices}
                amountModal
                expandIcon
                expandLink={`/payments/invoices/`}
                setSelectedRows={() => {}}
                setPageIndex={setPageIndex}
                checkboxVisible={isUserAllowedTo(permissions.SELECT_INVOICES)}
                pageCount={totalPages}
                section="invoices"
                hasFileIcon
                toggleSelectedRow={toggleSelectedRow}
                isChecked={isChecked}
                renderRowEndButtons={renderRowEndButtons}
                toggleSelectedAll={toggleSelectedAll}
                perPage={perPageSelected}
                setPerPage={setPerPageSelected}
                perPageVisible={true}
                statusSelected={statusSelected}
                providerIdSelected={providerIdSelected}
                serviceIdSelected={serviceIdSelected}
                onClickRow={handleOnClickRow}
                renderAutocomplete={renderAutocomplete}
                tagsSelected={tagsSelected}
                emptyState={
                  <TableEmptyState
                    title={"No se encontraron resultados para esta búsqueda"}
                    subtitle={"Por favor intente nuevamente"}
                  />
                }
                isCausationAvailable={currentCompany.isCausationEnabled}
                causationFilter={selectFilterCausation?.value}
              />
            </div>
          </>
        )}
      </div>
      {invoices.length > 0 && (
        <InvoiceFooter
          selectedRows={selectedRows}
          selectedRowsIds={selectedRowsIds}
          totalAmount={totalAmount}
          deleteSelectedInvoices={openDeleteInvoiceModal}
          openMarkAsPaidModal={openMarkAsPaidModal}
          openMarkAsPendingModal={openMarkAsPendingModal}
          payLater={payLater}
          openWompiModal={() => {
            if (blockedCompany) {
              setOpenBlockedModal(true);
            } else {
              const invoicesWithIncompleteProvider = hasOneIncomplete();
              if (invoicesWithIncompleteProvider.length > 0) {
                showModal(INCOMPLETE_SUPPLIER_MODAL_KEY, "pay_now");
              } else {
                setWompiModalIsVisible(true);
              }
            }
          }}
          totalSelected={selectedRows.length}
          pageIndex={pageIndex}
          disabled={!isUserAllowedTo(permissions.SHOW_FOOTER_INVOICES)}
          footerDropdown={true}
        />
      )}
      <DrawerSupplierForm
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        onFinish={() => {
          if (blockedCompany) {
            setOpenBlockedModal(true);
          } else {
            refreshInvoiceTable();
            flow === "pay_now"
              ? setWompiModalIsVisible(true)
              : payLaterAction();
          }
        }}
      />
      <SupplierDetails
        isOnlySupplier
        isOpen={drawerIsOpen}
        id={Number.isFinite(drawerIsOpen) ? drawerIsOpen : null}
        handleClose={() => {
          setDrawerIsOpen(false);
        }}
        handleSuccess={(supplier, service) => {
          refreshInvoiceTable();
          if (service) {
            setCreatedService(service);
          } else {
            setCreatedSupplier(supplier);
          }
          setDrawerIsOpen(false);
        }}
      />
    </>
  );
};

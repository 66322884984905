import React from "react";
import { styles } from "./styles";

export const ContactHelpCausation = ({ onClick }) => {
  return (
    <div style={styles.contactHelpCausationContainer}>
      <p style={styles.contactHelpCausationTitle}>Si eres contador...</p>
      <p style={styles.contactHelpCausationText}>
        Y si tienes múltiples empresas tenemos planes especiales para ti.
      </p>
      <button style={styles.contactHelpCausationButton} onClick={onClick}>
        Contactar a ventas para un plan especial
      </button>
    </div>
  );
};

import { Box, LinearProgress } from "@mui/material";
import { styles } from "../../SupplierDetails/styles";

const StepThree = ({ title, subtitle }) => {
  return (
    <div style={styles.column}>
      <p style={{ ...styles.title, textAlign: "center" }}>
        <strong>{title}</strong>
      </p>
      <p
        style={{
          color: "#475467",
          fontSize: 14,
          textAlign: "center",
          fontWeight: 400,
        }}
      >
        <span>{subtitle}</span>
      </p>
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default StepThree;

import React, { useContext, useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Modal, Spinner } from "react-bootstrap";
import { styles } from "./styles";
import {
  PrimaryButton,
  SecondaryButton,
  WithoutBorderButton,
} from "../../buttons";
import { getReconciliationsToken, importBankMovements } from "services";
import { BankEntitySelector, ValidationMessage } from "commons/components";
import { useForm, useWatch } from "react-hook-form";
import { PaymentsContext, UserContext } from "contexts";
import { openInNewTab, saveInLocalStorage } from "utils";
import { ExternalLinkIcon } from "assets";
import BankExtractFileUpload from "./components/FileUpload";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { BANCOLOMBIA_TUTORIAL_LINK } from "constants";

export const BankExtractModal = ({
  visible,
  setIsBankExtractModalVisible,
  reconciliationsCsvButton,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [accountingAccountOptions, setAccountingAccountOptions] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(undefined);

  const { register, handleSubmit, formState, reset, control } = useForm({
    defaultValues: { accountingAccount: "", bankId: "", alias: "" },
    mode: "onBlur",
  });

  const formValues = useWatch({ control });
  const { getAllBanks, allBanks } = useContext(PaymentsContext);

  const submitForm = handleSubmit(async (formValues) => {
    setRequestInProcess(true);
    try {
      saveInLocalStorage(
        "reconciliationsBankData",
        JSON.stringify({
          erpExternalAccount: formValues.accountingAccount,
          bankId: formValues.bankId,
          bankAlias: formValues.alias,
        })
      );

      if (
        allBanks.find((bank) => bank.id === formValues.bankId).name ===
        "BANCOLOMBIA"
      ) {
        amplitude.logEvent("START_BANK_MOVEMENTS_IMPORT", {
          user_id: currentUser.id,
        });

        const res = await importBankMovements({
          erpExternalAccount: formValues.accountingAccount,
          bankId: formValues.bankId,
          bankAlias: formValues.alias,
          bankName: "BANCOLOMBIA",
          file: selectedFiles[0],
        });

        const response = res.lambdaResponse.Payload;

        if (response && response?.statusCode !== 200) {
          throw new Error("Error al registrar");
        }

        saveInLocalStorage(
          "reconciliationsBankId",
          response.reconciliationBankId
        );
        setIsBankExtractModalVisible(false);
        setRequestInProcess(false);
        reset({
          accountingAccount: "",
          bankId: "",
          alias: "",
        });
        setSelectedFiles(undefined);
        navigate("/reconciliations", {
          replace: false,
          state: { importSuccess: true },
        });
        setRequestInProcess(false);
      } else {
        setIsBankExtractModalVisible(false);
        setRequestInProcess(false);
        reconciliationsCsvButton.current.click();
      }
    } catch (error) {
      setRequestInProcess(false);
      toast.error(
        "Ocurrió un error, por favor intente nuevamente.",
        toastOptions
      );
    }
  });

  useEffect(() => {
    if (visible) {
      saveInLocalStorage("isNewBank", true);
      getReconciliationsToken().then(() => {
        getAllBanks();
      });
    }
  }, [visible]);

  return (
    <Modal
      show={visible}
      onHide={() => setIsBankExtractModalVisible(false)}
      centered
    >
      <Modal.Body style={{ boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)" }}>
        <form onSubmit={submitForm} style={{ height: "100%" }}>
          <Box sx={styles.container}>
            <p style={styles.title}>Importación de extracto financiero</p>
            <p style={styles.subtitle}>
              Esto nos permitirá conciliar tus movimientos bancarios con tu
              sistema contable.
            </p>
            <div
              style={{
                backgroundColor: "#F9FAFB",
                padding: "16px",
                borderRadius: "8px",
                gap: "12px",
                width: "392px",
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: loading ? "center" : "normal",
              }}
            >
              {loading ? (
                <Spinner animation="border" variant="secondary" />
              ) : (
                <>
                  <label htmlFor="accountingAccount" style={styles.inputLabel}>
                    Cuenta contable en Siigo a conciliar
                  </label>
                  <Select
                    name="accountingAccount"
                    size="small"
                    variant="outlined"
                    displayEmpty={true}
                    {...register("accountingAccount", { required: true })}
                    style={{
                      fontSize: "14px",
                      backgroundColor: "#FFFFFF",
                      color: formValues.accountingAccount === "" && "#98A2B3",
                    }}
                    inputProps={{
                      id: "accountingAccount",
                    }}
                    value={formValues.accountingAccount}
                  >
                    <MenuItem
                      disabled
                      key={"type"}
                      value={""}
                      style={{ fontSize: "14px" }}
                    >
                      Selecciona del listado
                    </MenuItem>
                    {accountingAccountOptions.map((option) => (
                      <MenuItem
                        key={option.Code}
                        value={option.Code}
                        style={{ fontSize: "14px" }}
                      >
                        {option.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  <ValidationMessage
                    errors={formState.errors}
                    field="accountingAccount"
                  />
                  <label htmlFor="documentType" style={styles.inputLabel}>
                    Elige tu banco
                  </label>
                  <BankEntitySelector
                    bankSelectedId={formValues.bankId}
                    validationProps={register("bankId", { required: true })}
                  />
                  <ValidationMessage errors={formState.errors} field="bankId" />
                  <label htmlFor="documentType" style={styles.inputLabel}>
                    Alias
                  </label>
                  <TextField
                    id="accountNumber"
                    size="small"
                    variant="outlined"
                    placeholder="Ejemplo: CC 2521253"
                    value={formValues.alias}
                    {...register("alias", { required: true })}
                    InputProps={{
                      style: { fontSize: 14, backgroundColor: "#FFFFFF" },
                    }}
                  />
                  <ValidationMessage errors={formState.errors} field="alias" />
                  {formValues.bankId &&
                    allBanks.find((bank) => bank.id === formValues.bankId)
                      .name === "BANCOLOMBIA" && (
                      <>
                        <label htmlFor="documentType" style={styles.inputLabel}>
                          Importa tus movimientos bancarios
                        </label>
                        <BankExtractFileUpload
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "600",
                              color: "#475467",
                            }}
                          >
                            ¿Cómo descargar movimientos bancarios?
                          </Typography>
                          <WithoutBorderButton
                            text="Ver tutorial"
                            action={() =>
                              openInNewTab(BANCOLOMBIA_TUTORIAL_LINK)
                            }
                            width="96px"
                            style={{ height: "20px", paddingRight: "0px" }}
                            contentStyle={{ justifyContent: "flex-end" }}
                            endIcon={<ExternalLinkIcon stroke="#5925DC" />}
                          />
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
            <Stack
              direction={"row"}
              spacing={1}
              pt={4}
              justifyContent="space-between"
              sx={{ width: "392px", alignSelf: "center" }}
            >
              <SecondaryButton
                text="Cancelar"
                action={() => setIsBankExtractModalVisible(false)}
                width="100%"
                isDisable={requestInProcess}
              />
              <PrimaryButton
                text={
                  requestInProcess ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Confirmar"
                  )
                }
                type="submit"
                width="100%"
                isDisable={
                  (formValues.bankId &&
                    allBanks.find((bank) => bank.id === formValues.bankId)
                      .name === "BANCOLOMBIA" &&
                    (!selectedFiles ||
                      (selectedFiles && selectedFiles.length === 0))) ||
                  requestInProcess
                }
              />
            </Stack>
          </Box>
        </form>
      </Modal.Body>
    </Modal>
  );
};

import { IconButton } from "@mui/material";
import { DeleteIcon } from "assets";
import React from "react";

export const Delete = ({ handleDeletePayment, index }) => {
  return (
    <IconButton onClick={() => handleDeletePayment(index)}>
      <DeleteIcon stroke="#5925DC" />
    </IconButton>
  );
};

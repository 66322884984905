import { IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { CopyUrlIcon } from "assets";
import { toastOptions } from "constants";
import { Tooltip } from "commons";

const COPY_MESSAGE =
  "El link al portal del cliente fue copiado a tu portapapeles con éxito.";

const copyCustomerUrl = (hash) => {
  navigator.clipboard.writeText(
    `${process.env.REACT_APP_BASE_URL}/pay?hash=${hash}`
  );
  toast.success(COPY_MESSAGE, toastOptions);
};

const CustomerCopyLink = ({ hash, handleAction = null }) => {
  const onClick = () => {
    if (handleAction) {
      handleAction(() => copyCustomerUrl(hash));
      return;
    }

    return copyCustomerUrl(hash);
  };

  return (
    <Tooltip title="Copiar link al portal del cliente">
      <IconButton onClick={onClick}>
        <CopyUrlIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CustomerCopyLink;

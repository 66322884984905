import { Autocomplete, TextField } from "@mui/material";
import CustomPaper from "./CustomPaper";
import OptionComponent from "./OptionComponent";
import { Tooltip } from "../Tooltip";

const AutocompleteTable = ({
  disabled,
  name,
  options,
  tabIndex,
  getOptionLabel,
  onChange,
  onInputChange,
  value,
  tableConfig,
  ...props
}) => {
  const { columns, getRowTooltipText, rowClassName } = tableConfig;
  return (
    <Autocomplete
      {...props}
      PaperComponent={CustomPaper(columns)}
      disabled={disabled}
      onKeyDown={(event) => {
        if (event.key === " ") {
          event.stopPropagation();
        }
      }}
      ListboxProps={{
        style: {
          padding: 0,
          marginRight: "-15px",
        },
      }}
      name={name}
      options={options}
      size="small"
      tabIndex={tabIndex}
      sx={{
        width: "89%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        "& fieldset": {
          border: "none",
        },
      }}
      freeSolo
      autoHighlight
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      onInputChange={onInputChange}
      value={value}
      renderOption={(props, option) => (
        <OptionComponent
          columns={columns}
          props={props}
          option={option}
          className={rowClassName}
          getRowTooltipText={getRowTooltipText}
        />
      )}
      renderInput={(params) => {
        return (
          <Tooltip title={params.inputProps.value}>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: 14,
                },
              }}
            />
          </Tooltip>
        );
      }}
    />
  );
};

export default AutocompleteTable;

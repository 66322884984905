import React from "react";

export const ArrowIcon = ({
  stroke = "#344054",
  width = "16",
  height = "16",
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path
      d="M4.6665 11.3333L11.3332 4.66663M11.3332 4.66663H4.6665M11.3332 4.66663V11.3333"
      stroke={stroke}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

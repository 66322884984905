export const customerPayStatusesStyles = {
  sent: {
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  paid: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  in_process: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  paid_outside: {
    color: "#027A48",
    backgroundColor: "#ECFDF3",
  },
  pending_deposit: {
    color: "#026AA2",
    backgroundColor: "#F0F9FF",
  },
};

export const customerPayStatusPillWording = {
  sent: "Por pagar",
  paid: "Pagada",
  in_process: "Pagada",
  paid_outside: "Pagada por fuera",
  pending_deposit: "Depósito pendiente",
};

import { createContext, useState } from "react";
import { GetBOCompanies } from "../../services";

export const BackofficeCompanyContext = createContext();

export function BackofficeCompanyProvider({ children }) {
  const [companies, setCompanies] = useState([
    //PROVISORIO HASTA TENER EL GET
    {
      id: 2,
      name: "",
    },
    {
      id: 3,
      name: "",
    },
  ]);

  const getCompanies = async () => {
    const gottenCompanies = await GetBOCompanies();
    if (gottenCompanies !== null) {
      setCompanies(gottenCompanies);
    }
  };

  return (
    <BackofficeCompanyContext.Provider
      value={{
        companies,
        getCompanies,
      }}
    >
      {children}
    </BackofficeCompanyContext.Provider>
  );
}

import React from "react";

export const OneFileIcon = ({
  strokeColor = "#6938EF",
  bgColor = "#EBE9FE",
}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill={bgColor} />
    <path
      d="M17.3334 9.51302V12.2667C17.3334 12.6401 17.3334 12.8268 17.4061 12.9694C17.47 13.0948 17.572 13.1968 17.6974 13.2607C17.84 13.3334 18.0267 13.3334 18.4001 13.3334H21.1538M18.6667 16.6667H13.3334M18.6667 19.3333H13.3334M14.6667 14H13.3334M17.3334 9.33333H13.8667C12.7466 9.33333 12.1866 9.33333 11.7588 9.55132C11.3824 9.74306 11.0765 10.049 10.8847 10.4253C10.6667 10.8532 10.6667 11.4132 10.6667 12.5333V19.4667C10.6667 20.5868 10.6667 21.1468 10.8847 21.5746C11.0765 21.951 11.3824 22.2569 11.7588 22.4487C12.1866 22.6667 12.7466 22.6667 13.8667 22.6667H18.1334C19.2535 22.6667 19.8136 22.6667 20.2414 22.4487C20.6177 22.2569 20.9237 21.951 21.1154 21.5746C21.3334 21.1468 21.3334 20.5868 21.3334 19.4667V13.3333L17.3334 9.33333Z"
      stroke={strokeColor}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

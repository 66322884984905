export const SKELETON_RECEIVER = {
  name: "",
  documentType: "",
  documentNumber: "",
  email: "",
  phone_number: "",
  bankId: "",
  accountType: "",
  accountNumber: "",
  accountHolderName: "",
  accountHolderDocumentType: "",
  accountHolderDocumentNumber: "",
};

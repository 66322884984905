import React from "react";

export const CalendarIcon = ({
  width = "18",
  height = "20",
  stroke = "#1D2939",
}) => (
  <svg width={width} height={height} viewBox="0 0 18 20" fill="none">
    <path
      d="M16.5 8.33464H1.5M12.3333 1.66797V5.0013M5.66667 1.66797V5.0013M5.5 18.3346H12.5C13.9001 18.3346 14.6002 18.3346 15.135 18.0622C15.6054 17.8225 15.9878 17.44 16.2275 16.9696C16.5 16.4348 16.5 15.7348 16.5 14.3346V7.33464C16.5 5.9345 16.5 5.23444 16.2275 4.69966C15.9878 4.22925 15.6054 3.8468 15.135 3.60712C14.6002 3.33464 13.9001 3.33464 12.5 3.33464H5.5C4.09987 3.33464 3.3998 3.33464 2.86502 3.60712C2.39462 3.8468 2.01217 4.22925 1.77248 4.69966C1.5 5.23444 1.5 5.9345 1.5 7.33464V14.3346C1.5 15.7348 1.5 16.4348 1.77248 16.9696C2.01217 17.44 2.39462 17.8225 2.86502 18.0622C3.3998 18.3346 4.09987 18.3346 5.5 18.3346Z"
      stroke={stroke}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

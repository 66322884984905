import React, { useState } from "react";
import { styles } from "../styles";
import { Divider } from "@mui/material";
import { ChevronDownIcon, ChevronUpIcon } from "assets";
import { DropdownButton } from "commons/buttons";
import { Dropdown } from "react-bootstrap";

export const CloseDropdown = ({
  setSelectedProcessId,
  setSelectedBank,
  setIsEndPeriodModalVisible,
}) => {
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen((prevValue) => !prevValue);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Dropdown
          onToggle={() => {
            toggleDropdown();
          }}
        >
          <DropdownButton
            text="Cerrar"
            open={open}
            openedIcon={<ChevronUpIcon color="#FFFFFF" />}
            closedIcon={<ChevronDownIcon color="#FFFFFF" />}
            style={styles.closeButton}
          />

          <Dropdown.Menu style={styles.noPadding}>
            <Dropdown.Item
              onClick={() => {
                setSelectedProcessId(null);
                setSelectedBank(null);
              }}
            >
              <div style={styles.dropdownItemContainer} type="dropdown">
                <div style={styles.dropdownItemTextContainer}>
                  <p style={styles.dropdownItemText}>Guardar para más tarde</p>
                </div>
              </div>
            </Dropdown.Item>
            <Divider />
            <Dropdown.Item
              onClick={() => {
                setIsEndPeriodModalVisible(true);
              }}
            >
              <div style={styles.dropdownItemContainer} type="dropdown">
                <div
                  style={{
                    ...styles.dropdownItemTextContainer,
                    marginTop: "0px",
                  }}
                >
                  <p style={styles.dropdownItemText}>Finalizar período</p>
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

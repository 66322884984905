import React, { useContext, useState } from "react";
import { Divider, MenuItem, Select, Stack } from "@mui/material";
import { TrackJS } from "trackjs";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { ValidationMessage } from "../../components";
import { styles } from "../PaymentModal/styles";
import { toast } from "react-toastify";
import { DEFAULT_PAYMENT_CARD_FEE, toastOptions } from "../../../constants";
import { UserContext } from "../../../contexts";
import ComissionRetentions from "../PaymentModal/ComissionRetentions";
import { payanaComissionRetentions } from "../../../utils";
import getComissionDetails from "../PaymentModal/getComissionDetails";
import PaymentModalRow from "../PaymentModal/PaymentModalRow";
import getPayanaComission from "../PaymentModal/getPayanaComission";

const ConfirmSourceOfFundsModal = ({
  sourcesOfFunds,
  selectedId = "",
  loading = false,
  canPayWithCobre,
  onRegisterNew = () => {},
  onSelected = () => {},
  onSkipFlow = () => {},
  openCobreFlow = () => {},
  openWompiFlow = () => {},
  totalAmount,
  selectedRowsIds,
  selectedSuppliers,
  type,
  section,
  setTotalAmountToPay,
  amountTotalInvoicePayana,
}) => {
  const [linkGenerated, setLinkGenerated] = useState(false);
  const {
    newPricing,
    newPricingMetadata,
    comissionRetentions,
    comissionRetentionIsEnabled,
  } = useContext(UserContext);
  const [isLoadingPayment, setLoadingPayment] = useState(false);
  const selectedSourcesOfFunds = sourcesOfFunds.find(
    (sourceOfFund) => sourceOfFund.id === selectedId
  );
  const value =
    selectedSourcesOfFunds?.type === "card_reference"
      ? "card"
      : selectedSourcesOfFunds?.type === "bank_account"
      ? "others"
      : "";

  let formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const filteredSuppliers = selectedSuppliers
    ? selectedSuppliers.filter(
        (item, index) => selectedSuppliers.indexOf(item) === index
      )
    : [];

  const cardFeePercentage = DEFAULT_PAYMENT_CARD_FEE;

  const payanaComission = getPayanaComission({
    amountTotalInvoicePayana,
    newPricing,
    newPricingMetadata,
    selectedPaymentMethod: value,
    totalAmount,
    type,
    selectedSuppliers: filteredSuppliers,
    cardFeePercentage,
    selectedRowsIds,
  });

  const totalToPay = (selectedPaymentMethod, retentions) => {
    const amountToPay =
      comissionRetentionIsEnabled && selectedPaymentMethod === "others"
        ? newPricing
          ? payanaComission -
            payanaComissionRetentions(
              payanaComission,
              selectedPaymentMethod,
              retentions
            ) +
            totalAmount
          : totalAmount
        : payanaComission + totalAmount;
    setTotalAmountToPay(amountToPay);
    return amountToPay;
  };

  const comission = getComissionDetails(
    newPricing,
    newPricingMetadata,
    value,
    cardFeePercentage,
    formatAmount,
    type,
    payanaComission,
    section
  );

  return (
    <div
      className="source-of-funds-form register-new-form"
      style={{ display: "inline" }}
    >
      <Stack justifyContent="space-between">
        {sourcesOfFunds.length > 0 ? (
          <SourceOfFundsSelector
            onRegisterNew={onRegisterNew}
            onSelected={onSelected}
            selectedId={selectedId}
            sourcesOfFunds={sourcesOfFunds}
          />
        ) : (
          <PrimaryButton
            width="100%"
            text={"Agregar origen de fondos"}
            action={onRegisterNew}
          />
        )}
        <Stack style={{ marginTop: 12 }}>
          {value === "others" && (
            <div style={styles.sourceOfFundsInfoContainer}>
              <div style={styles.sourceOfFundsInfoRow}>
                <p style={styles.sourceOfFundsInfoTitle}>
                  Pagos a realizar (x{selectedRowsIds.length})
                </p>
                <p style={styles.sourceOfFundsInfoTotal}>
                  {formatAmount.format(totalAmount)}
                </p>
              </div>
              {comission && (
                <PaymentModalRow
                  label={comission.label}
                  value={comission.value}
                />
              )}
              <Divider sx={styles.divider} />
              <ComissionRetentions
                comissionRetentionIsEnabled={comissionRetentionIsEnabled}
                comissionRetentions={comissionRetentions}
                newPricing={newPricing}
                selectedPaymentMethod={value}
                formatAmount={formatAmount}
                payanaComission={payanaComission}
              />
              <div style={styles.sourceOfFundsInfoRow}>
                <p style={styles.sourceOfFundsInfoTotal}>Total a pagar</p>
                <p style={styles.sourceOfFundsInfoTotal}>
                  {formatAmount.format(totalToPay(value, comissionRetentions))}
                </p>
              </div>
            </div>
          )}
          {value === "card" && (
            <div style={styles.sourceOfFundsInfoContainer}>
              <div style={styles.sourceOfFundsInfoRow}>
                <p style={styles.sourceOfFundsInfoTitle}>
                  Pagos a realizar (x{selectedRowsIds.length})
                </p>
                <p style={styles.sourceOfFundsInfoTotal}>
                  {formatAmount.format(totalAmount)}
                </p>
              </div>
              {payanaComission > 0 && (
                <div style={styles.sourceOfFundsInfoRow}>
                  <p style={styles.sourceOfFundsInfoTitle}>
                    Comisión Tarjeta de Crédito ({cardFeePercentage}%)
                  </p>
                  <p style={styles.sourceOfFundsInfoTotal}>
                    {formatAmount.format(payanaComission)}
                  </p>
                </div>
              )}
              <Divider sx={styles.divider} />
              <div style={styles.sourceOfFundsInfoRow}>
                <p style={styles.sourceOfFundsInfoTotal}>Total a pagar</p>
                <p style={styles.sourceOfFundsInfoTotal}>
                  {formatAmount.format(totalToPay(value, comissionRetentions))}
                </p>
              </div>
            </div>
          )}
        </Stack>
        <Buttons
          disabled={!selectedId || linkGenerated || isLoadingPayment}
          isLoadingPayment={isLoadingPayment || loading}
          skipFlow={onSkipFlow}
          handleClick={async () => {
            try {
              setLoadingPayment(true);
              if (canPayWithCobre && value !== "card") {
                await openCobreFlow();
              } else {
                await openWompiFlow();
              }
              setLinkGenerated(true);
            } catch (error) {
              TrackJS.console.error(error);
              toast.error(
                "Ha ocurrido un error, intente nuevamente",
                toastOptions
              );
            } finally {
              setLoadingPayment(false);
            }
          }}
          value={value}
        />
      </Stack>
    </div>
  );
};

function SourceOfFundsSelector({
  onRegisterNew,
  onSelected,
  selectedId,
  sourcesOfFunds,
}) {
  return (
    <div style={{ marginBottom: 6 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ marginBottom: 6 }}
      >
        <div
          style={{
            color: "#344054",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
          }}
        >
          Seleccione el origen de sus fondos
        </div>
        <div
          style={{
            color: "#6938EF",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            cursor: "pointer",
          }}
          onClick={onRegisterNew}
        >
          Agregar
        </div>
      </Stack>
      <Stack spacing={1}>
        <Select
          size="small"
          variant="outlined"
          displayEmpty
          inputProps={{
            id: "sourceOfFunds",
          }}
          onChange={(e) => onSelected(e.target.value)}
          value={selectedId || ""}
        >
          <MenuItem disabled value="">
            Elige desde dónde quieres pagar
          </MenuItem>
          {sourcesOfFunds.map((type) => {
            return (
              <MenuItem key={type.id} value={type.id}>
                {type.label}
              </MenuItem>
            );
          })}
        </Select>
        <ValidationMessage errors={{}} field="sourceOfFunds" />
      </Stack>
    </div>
  );
}
function Buttons({
  handleClick,
  isLoadingPayment = false,
  skipFlow,
  disabled,
  value,
}) {
  return (
    <>
      {value && (
        <Stack direction="row" justifyContent="space-between" pt={1}>
          <PrimaryButton
            isDisable={disabled}
            loading={isLoadingPayment}
            width="100%"
            text={
              value === "others"
                ? "Pagar con PSE"
                : "Pagar con tarjeta de crédito"
            }
            action={handleClick}
          />
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between" pt={1}>
        <SecondaryButton
          loading={isLoadingPayment}
          width="100%"
          text="No registrar origen de los fondos"
          action={skipFlow}
        />
      </Stack>
    </>
  );
}

export { ConfirmSourceOfFundsModal };

import React from "react";
import { Stack } from "@mui/material";
import { CreateCompanyIcon, PlusCircleIcon } from "assets";
import styles from "../company-switch-modal.module.css";
import { ButtonWithIcon } from "commons/components";

export const EmptyState = ({ onClick }) => {
  return (
    <Stack
      direction={"column"}
      alignItems="center"
      justifyContent="center"
      gap={"24px"}
      style={{ marginTop: "48px", marginBottom: "48px" }}
    >
      <CreateCompanyIcon />
      <Stack
        direction={"column"}
        alignItems="center"
        justifyContent="center"
        gap={"8px"}
      >
        <div className={styles["title"]}>
          Crea una nueva empresa y elige tu rol
        </div>
        <div className={styles["centered-description"]}>
          Luego, podrás cambiar fácilmente entre tus empresas dentro de la
          plataforma.
        </div>
      </Stack>

      <div className={styles["description"]}>
        ¡Empieza ahora y simplifica tu gestión empresarial!
      </div>
      <ButtonWithIcon
        action={() => onClick()}
        buttonLabel={"Crear empresa"}
        icon={<PlusCircleIcon stroke="#FFFFFF" width="20" height="20" />}
        width={"155px"}
      />
    </Stack>
  );
};

import { requestContainer } from "../requestContainer";

export async function GetEmployees({ pageIndex, employeeId }) {
  return requestContainer({
    method: "get",
    url: `/employees?page=${pageIndex}&employee_id=${employeeId}&per_page=100`,
  });
}

export async function GetTotalEmployees({ employeeId }) {
  return requestContainer({
    method: "get",
    url: `/employees/total?employee_id=${employeeId}`,
  });
}

export async function GetEmployee({ id }) {
  return requestContainer({
    method: "get",
    url: `/employees/${id}`,
  });
}

export async function GetBOEmployee({ id }) {
  return requestContainer({
    method: "get",
    url: `/backoffice/employees/${id}`,
  });
}

export async function EmployeeSearch(query) {
  return requestContainer({
    method: "get",
    url: `/employees/search?q=${query}`,
  });
}

export async function CreateEmployee({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/employees`,
    data: { ...formValues },
  });
}

export async function UpdateEmployee({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/employees/${id}`,
    data: { ...formValues },
  });
}

export async function UpdateBOEmployee({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/backoffice/employees/${id}`,
    data: { ...formValues },
  });
}

export async function DeleteEmployee({ id }) {
  return requestContainer({
    method: "delete",
    url: `/employees/${id}`,
  });
}

export async function SearchEmployees({ q }) {
  return requestContainer({
    method: "get",
    url: `/employees/search?q=${q}`,
  });
}

export async function SearchAllEmployees(query) {
  return requestContainer({
    method: "get",
    url: `/backoffice/employees/search?q=${query}`,
  });
}

export function ExportEmployeesFile() {
  return requestContainer({
    method: "get",
    url: `/export/employees`,
    responseType: "blob",
  });
}

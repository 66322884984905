import React from "react";

export const FileSearchIcon = ({ color }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      d="M9.83332 7.3335H5.83332M7.16666 10.0002H5.83332M11.1667 4.66683H5.83332M13.8333 7.00016V4.5335C13.8333 3.41339 13.8333 2.85334 13.6153 2.42552C13.4236 2.04919 13.1176 1.74323 12.7413 1.55148C12.3135 1.3335 11.7534 1.3335 10.6333 1.3335H6.36666C5.24655 1.3335 4.6865 1.3335 4.25868 1.55148C3.88235 1.74323 3.57639 2.04919 3.38464 2.42552C3.16666 2.85334 3.16666 3.41339 3.16666 4.5335V11.4668C3.16666 12.5869 3.16666 13.147 3.38464 13.5748C3.57639 13.9511 3.88235 14.2571 4.25868 14.4488C4.6865 14.6668 5.24655 14.6668 6.36666 14.6668H8.16666M15.1667 14.6668L14.1667 13.6668M14.8333 12.0002C14.8333 13.2888 13.7887 14.3335 12.5 14.3335C11.2113 14.3335 10.1667 13.2888 10.1667 12.0002C10.1667 10.7115 11.2113 9.66683 12.5 9.66683C13.7887 9.66683 14.8333 10.7115 14.8333 12.0002Z"
      stroke={color ? color : "#667085"}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { TextField } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import { styles } from "./styles";
import { regexNumberWIthDecimal } from "utils";

export const InputAmount = ({ payment, handleOnChangePayment, index }) => {
  const handleOnChangeAmount = (value) => {
    if (!regexNumberWIthDecimal(value)) return;

    handleOnChangePayment(index, value, "amount");
  };

  return (
    <TextField
      name="paymentAmount"
      disabled={payment?.accounting_concept === ""}
      value={payment?.amount || 0}
      type="text"
      onChange={(e) => {
        handleOnChangeAmount(+e.target.value);
      }}
      sx={styles.inputAmount}
      InputProps={{
        inputComponent: MoneyFormatCustom,
      }}
    />
  );
};

const MoneyFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"$"}
      fixedDecimalScale={true}
      style={{
        textAlign: "right",
      }}
    />
  );
});

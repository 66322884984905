import React from "react";

export const TrendDownIcon = ({ stroke = "#344054", circle = true }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
    <path
      d="M0.666504 0.666748L7.33317 7.33341M7.33317 7.33341V0.666748M7.33317 7.33341H0.666504"
      stroke={stroke}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

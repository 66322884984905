import {
  availableStatusesFilters,
  collectionStatusPillWording,
  collectionStatusesStyles,
  customerPayStatusPillWording,
  customerPayStatusesStyles,
  statusPillWording,
  statusesStyles,
  statusCausationPillWording,
  statusCausationStyles,
  causationStates,
} from "../constants";

export const findStatusLabel = (value) => {
  const index = availableStatusesFilters.findIndex(
    (status) => status.value === value
  );
  return availableStatusesFilters[index].label.toLowerCase();
};

export const getStatusPillStyle = (
  status,
  isMobile = false,
  section = null
) => {
  let pillStatus = {
    padding: "2px 8px",
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "16px",
    textAlign: "center",
    width: "fit-content",
    textWrap: "nowrap",
  };
  const statusStyle = status
    ? section === "collections" || section === "collectionTrx"
      ? collectionStatusesStyles[status.toLowerCase()]
      : section === "customer"
      ? customerPayStatusesStyles[status.toLowerCase()]
      : statusesStyles[status.toLowerCase()]
    : section === "collections"
    ? collectionStatusesStyles["not_started"]
    : section === "customer"
    ? customerPayStatusesStyles["not_started"]
    : statusesStyles["not_started"];
  pillStatus = { ...pillStatus, margin: isMobile ? "" : "revert" };
  return { ...pillStatus, ...statusStyle };
};

export const getStatusWording = (status, isMobile = false, section = null) => {
  return status?.key === "dispersed"
    ? statusPillWording.dispersed(status)
    : status
    ? section === "collections" || section === "collectionTrx"
      ? collectionStatusPillWording[status.toLowerCase()]
      : section === "customer"
      ? customerPayStatusPillWording[status.toLowerCase()]
      : statusPillWording[status.toLowerCase()]
    : section === "collections"
    ? collectionStatusPillWording["not_started"]
    : section === "customer"
    ? customerPayStatusPillWording["not_started"]
    : statusPillWording["not_started"];
};

export const getStatusCausationWording = (status) => {
  return status
    ? statusCausationPillWording[status.toLowerCase()]
    : statusCausationPillWording[causationStates.draft];
};

export const getStatusCausationPillStyle = (status, isMobile = false) => {
  let pillStatus = {
    padding: "2px 8px",
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "16px",
    textAlign: "center",
    width: "fit-content",
  };
  const statusStyle = status
    ? statusCausationStyles[status.toLowerCase()]
    : statusCausationStyles[causationStates.draft];
  pillStatus = { ...pillStatus, margin: isMobile ? "" : "revert" };
  return { ...pillStatus, ...statusStyle };
};

import { Typography } from "@mui/material";
import { TrxStepDoneIcon, TrxStepPendingIcon } from "../../../../assets";
import { styles } from "../styles";
import { formatLocaleDate } from "../../../../utils";

const CollectionTimelineItem = ({ isDone, text, date }) => {
  return (
    <div style={styles.timelineItemContainer}>
      {isDone ? <TrxStepDoneIcon /> : <TrxStepPendingIcon />}
      <Typography sx={styles.timelineItemTitle}>{text}</Typography>
      <Typography sx={styles.timelineItemText}>
        {date ? formatLocaleDate(date, "d MMM yyyy, h:mmaaa") : ""}
      </Typography>
    </div>
  );
};

export default CollectionTimelineItem;

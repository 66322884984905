import React, { useContext, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { ModalWarningIcon } from "../../../assets";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { PayrollContext } from "../../../contexts";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

export const SelectPayrollPeriodModal = ({
  visible,
  handleClose,
  action = "byEmployees",
  trxId = null,
}) => {
  const { createPayrollByEmployees, createPayrollByTrx } =
    useContext(PayrollContext);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const navigate = useNavigate();

  const closeModal = () => {
    setSelectedPeriod("");
    handleClose();
    navigate("/payroll/salaries", { replace: true });
  };

  const cloneAction = () => {
    createPayrollByTrx(trxId, selectedPeriod);
    closeModal();
  };

  const createByEmployees = () => {
    createPayrollByEmployees(selectedPeriod);
    closeModal();
  };

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Body>
        <Box sx={styles.container}>
          <ModalWarningIcon />
          <p style={styles.title}>
            {action === "clone"
              ? "Crear nómina utilizando la última transferencia"
              : "Crear nómina utilizando los empleados"}
          </p>
          <p style={styles.subtitle}>
            {action === "clone"
              ? "Para crear una nómina utilizando la última transferencia realizada, por favor, elija un concepto."
              : "Para crear una nómina utilizando los empleados de la empresa, por favor, elija un concepto."}
          </p>
          <Stack spacing={1} my={2}>
            <Typography sx={styles.inputLabel}>Concepto</Typography>
            <TextField
              variant="outlined"
              onChange={(e) => setSelectedPeriod(e.target.value)}
              value={selectedPeriod}
            />
          </Stack>
          <Stack spacing={2} direction={"row"} my={4}>
            <SecondaryButton text="Cancelar" action={closeModal} />
            <PrimaryButton
              text="Aceptar"
              action={() => {
                action === "clone" ? cloneAction() : createByEmployees();
              }}
            />
          </Stack>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

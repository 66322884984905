import { Alert } from "@mui/material";
import { WarningIcon } from "assets";
import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { toastOptions } from "constants";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateBOSubscriptionCompany } from "services";
import { TrackJS } from "trackjs";

export const ModalAlert = ({
  show = false,
  onHide = () => {},
  features = [],
  companySelected = null,
  refreshSubscription,
  hasBilling = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setIsLoading(true);

      const subscriptions = [];
      features.forEach((feature) => {
        if (!feature?.featureId) return;
        subscriptions.push({
          ...(feature?.id && { id: feature.id }),
          ...(feature?.status && { status: feature.status }),
          retention: companySelected?.retentions
            ? calculateRetention({
                price: feature.features.find((f) => f.id === feature.featureId)
                  .price,
                discount: feature.discount,
                retention: companySelected?.retentions,
              })
            : 0,
          freeDays: feature.freeDays || 0,
          featureId: feature.featureId,
          discount: feature?.discount || 0,
          featureName: feature.featureName,
          price: feature.price || 0,
          billingId: feature?.billingId || null,
        });
      });

      const body = {
        companyId: companySelected?.id,
        subscribes: subscriptions,
      };

      if (subscriptions.length === 0) {
        return toast.warning(
          "No se han seleccionado suscripciones",
          toastOptions
        );
      }

      const resp = await updateBOSubscriptionCompany(body);
      if (!resp) {
        return toast.error("Error al actualizar la suscripción", toastOptions);
      }

      refreshSubscription();
      toast.success("Suscripción actualizada correctamente", toastOptions);
    } catch (error) {
      TrackJS.console.error(error);
      toast.error("Error al actualizar la suscripción", toastOptions);
    } finally {
      setIsLoading(false);
      onHide();
    }
  };

  const calculateRetention = ({ price, discount, retention }) => {
    const subtotal = price - discount;
    return Math.round(subtotal * (retention / 100));
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <WarningIcon />
        </div>
        <p
          style={{
            margin: "18px 0px",
            textAlign: "center",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
          }}
        >
          ¿Estás seguro que deseas editar esta suscripción?
        </p>

        {!hasBilling && (
          <Alert severity="warning">
            Esta editando una suscripción que no tiene facturación
          </Alert>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            marginTop: "24px",
          }}
        >
          <SecondaryButton
            isDisable={isLoading}
            text="Cancelar"
            action={onHide}
          />
          <PrimaryButton
            loading={isLoading}
            text="Aceptar"
            action={onConfirm}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

import React from "react";
import { NumericFormat } from "react-number-format";
import { Divider } from "@mui/material";
import { styles } from "../../../Table/styles";
import { PAID_OUTSIDE, getAccountTypeLabel } from "../../../../constants";
import { ThirdButton } from "../../../buttons";
import { AddresseeIcon, DataIcon, PaymentIcon } from "../../../../assets";
import { format } from "date-fns";
import { CustomerCollectionTrxStatus } from "./Status";
import { downloadFile } from "utils";

export const CustomerCollectionDetail = ({ collection }) => {
  return (
    <>
      <div style={styles.buttonsContainer}>
        {collection.receipt_file_path && (
          <ThirdButton
            text={"Descargar soporte"}
            action={() => downloadFile(collection.receipt_file_path)}
            width={235}
          />
        )}
        {collection.file_path && (
          <ThirdButton
            text={"Descargar documento"}
            action={() => downloadFile(collection.file_path)}
            width={235}
          />
        )}
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <DataIcon />
          <span style={styles.cardTitle}>Datos</span>
        </div>
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Monto</span>
          <span style={styles.cardData}>
            <NumericFormat
              value={parseInt(collection.amount)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </span>
        </div>
        {collection.collection_number && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Comprobante</span>
              <span style={styles.cardData}>
                {collection.collection_number}
              </span>
            </div>
          </>
        )}
        {collection.concept && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Concepto</span>
              <span style={styles.cardData}>{collection.concept}</span>
            </div>
          </>
        )}
        {collection.expiration_date && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Fecha de vencimiento</span>
              <span style={styles.cardData}>
                {format(
                  new Date(collection.expiration_date.slice(0, -1)),
                  "dd/MM/yyyy"
                )}
              </span>
            </div>
          </>
        )}
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <AddresseeIcon />
          <span style={styles.cardTitle}>Destinatario</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Proveedor</span>
          <span style={styles.cardData}>
            {collection?.receiverName?.toUpperCase()}
          </span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Entidad bancaria</span>
          <span style={styles.cardData}>{collection?.receiverBankName}</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>
            {collection?.receiverAccountType
              ? getAccountTypeLabel(collection?.receiverAccountType)
              : "Número de celular"}
          </span>
          <span style={styles.cardData}>
            {collection?.receiverAccountNumber}
          </span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>
            {collection?.receiverDocumentType}
          </span>
          <span style={styles.cardData}>
            {collection?.receiverDocumentNumber}
          </span>
        </div>
      </div>
      <div style={styles.container}>
        <div style={styles.cardRow}>
          <PaymentIcon />
          <span style={styles.cardTitle}>Información del pago</span>
        </div>
        <Divider />
        <div style={styles.cardRowSpaceBetween}>
          <span style={styles.cardText}>Estado</span>
          <span>
            <CustomerCollectionTrxStatus
              className="transaction-details-status-pill"
              status={
                collection.status === PAID_OUTSIDE
                  ? PAID_OUTSIDE
                  : collection.transaction_status
              }
            />
          </span>
        </div>
        {collection?.transaction_hash && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Código de transacción</span>
              <span style={styles.cardData}>
                {collection?.transaction_hash?.toUpperCase()}
              </span>
            </div>
          </>
        )}

        {collection?.transaction_date && (
          <>
            <Divider />
            <div style={styles.cardRowSpaceBetween}>
              <span style={styles.cardText}>Fecha de pago</span>
              <span style={styles.cardData}>
                {collection?.transaction_date &&
                  format(
                    new Date(collection?.transaction_date?.slice(0, -1)),
                    "dd/MM/yyyy"
                  )}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

import React from "react";
import { Button } from "@mui/material";
import { styles } from "../styles";

export const AgendaFilter = ({
  typeSelected,
  setTypeSelected,
  setPageIndex,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.rowSpaceBetween}>
        <div style={styles.row}>
          <Button
            variant="text"
            key="supplier"
            sx={
              typeSelected === "supplier"
                ? styles.firstStatusOptionsSelected
                : styles.firstStatusOptions
            }
            onClick={() => {
              setTypeSelected("supplier");
              setPageIndex(1);
            }}
          >
            Proveedores
          </Button>
          <Button
            variant="text"
            key="service"
            sx={
              typeSelected === "service"
                ? styles.firstStatusOptionsSelected
                : styles.firstStatusOptions
            }
            onClick={() => {
              setTypeSelected("service");
              setPageIndex(1);
            }}
          >
            Servicios
          </Button>
        </div>
      </div>
      <hr style={styles.separator} />
    </div>
  );
};

import React from "react";

export const ModalDeleteIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
    <rect
      x="1.52501"
      y="1.325"
      width="60.95"
      height="60.95"
      rx="30.475"
      fill="#FEE4E2"
    />
    <rect
      x="1.52501"
      y="1.325"
      width="60.95"
      height="60.95"
      rx="30.475"
      stroke="#F4F3FF"
      strokeWidth="2.65"
    />
    <path
      d="M28.025 19.875H35.975M20.075 23.85H43.925M41.275 23.85L40.3458 37.7881C40.2064 39.8792 40.1367 40.9248 39.685 41.7176C39.2874 42.4156 38.6876 42.9768 37.9647 43.3271C37.1436 43.725 36.0957 43.725 33.9999 43.725H30.0001C27.9043 43.725 26.8564 43.725 26.0353 43.3271C25.3124 42.9768 24.7126 42.4156 24.315 41.7176C23.8633 40.9248 23.7936 39.8792 23.6542 37.7881L22.725 23.85M29.35 29.8125V36.4375M34.65 29.8125V36.4375"
      stroke="#F04438"
      strokeWidth="2.65"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

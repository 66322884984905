import React, { useContext } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { styles } from "./styles";
import { ListCheckIcon, ListXIcon, PlusCircleIcon } from "../../../assets";
import { SecondaryButton } from "../../buttons";
import { TutorialTableHeader, TutorialTableItem } from "./components";
import { ButtonWithIcon } from "../../components";
import { ADMIN } from "constants";
import { UserContext } from "contexts";
import { CloseButton } from "react-bootstrap";

export const UserTutorialModal = ({
  visible,
  handleClose,
  openFormUsersModal,
}) => {
  const { currentUser } = useContext(UserContext);

  const roles = {
    admin: [
      {
        icon: <ListCheckIcon />,
        text: "Acceso completo a todas las funcionalidades",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede causar facturas",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede crear usuarios",
      },
      {
        icon: <ListCheckIcon />,
        text: "Aprobador de pagos y/o cobros",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede modificar la configuración de la empresa: nombres, cuentas bancarias",
      },
    ],
    colab: [
      {
        icon: <ListCheckIcon />,
        text: "Puede cargar y acceder a toda la sección de pagos y cobros",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede causar facturas",
      },
      {
        icon: <ListXIcon />,
        text: "No puede crear usuarios",
      },
      {
        icon: <ListCheckIcon />,
        text: "Si se configura, sólo puede pagar con aprobación de un admin",
      },
      {
        icon: <ListXIcon />,
        text: "No puede configurar ninguna información de la empresa",
      },
    ],
    accountant: [
      {
        icon: <ListCheckIcon />,
        text: "Puede ver en modo lectura y descargar archivos de conciliación y soportes",
      },
      {
        icon: <ListCheckIcon />,
        text: "Puede causar facturas",
      },
      {
        icon: <ListXIcon />,
        text: "No puede crear usuarios",
      },
      {
        icon: <ListXIcon />,
        text: "No puede cargar ni sacar pagos",
      },
      {
        icon: <ListXIcon />,
        text: "No puede configurar ninguna información de la empresa",
      },
    ],
  };
  return (
    <Dialog
      onClose={handleClose}
      open={visible}
      maxWidth="lg"
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Roles y permisos</DialogTitle>
      <CloseButton
        style={{ position: "absolute", right: "16px", top: "20px" }}
        onClick={handleClose}
      />
      <DialogContent dividers={true}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <div style={styles.headerContainer}>
            <p style={styles.headerTitle}>¿Cómo funciona?</p>
            <div style={styles.headerTextContainer}>
              <p style={styles.headerText}>
                • Puedes agregar usuarios para que te ayuden a manejar tus
                transacciones.
              </p>
            </div>
            <div style={styles.headerTextContainer}>
              <p style={styles.headerText}>
                • Puedes elegir luego qué flujos requieren de aprobación del
                administrador.
              </p>
            </div>
          </div>
          <p style={styles.contentTitle}>¿Cuáles son los roles?</p>
          <div style={styles.tableContainer}>
            <div style={styles.tableColumn}>
              <TutorialTableHeader text={"Administrador"} />
              {roles["admin"].map((currentRole) => (
                <TutorialTableItem
                  icon={currentRole.icon}
                  text={currentRole.text}
                />
              ))}
            </div>
            <div style={styles.tableColumn}>
              <TutorialTableHeader text={"Colaborador"} />
              {roles["colab"].map((currentRole) => (
                <TutorialTableItem
                  icon={currentRole.icon}
                  text={currentRole.text}
                />
              ))}
            </div>
            <div style={styles.tableColumn}>
              <TutorialTableHeader text={"Contador"} />
              {roles["accountant"].map((currentRole) => (
                <TutorialTableItem
                  icon={currentRole.icon}
                  text={currentRole.text}
                />
              ))}
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          action={() => handleClose()}
          width="118px"
          text="Entendido"
        />
        {currentUser.role.name === ADMIN && (
          <ButtonWithIcon
            action={() => {
              handleClose();
              openFormUsersModal(null, "create");
            }}
            buttonLabel={"Crear usuario"}
            icon={<PlusCircleIcon stroke="#FFFFFF" width="16" height="20" />}
            width={"171px"}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

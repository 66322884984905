import { Dropdown } from "react-bootstrap";
import { DotsIcon } from "../../../assets";
import { styles } from "../../../commons/Table/styles";
import { Tooltip } from "commons";

export const ActionsButton = ({ action, disabled }) => {
  return (
    <Dropdown.Toggle
      {...(action ? { onClick: action } : {})}
      style={styles.dropdownContainer}
      id="dropdown-basic"
      disabled={disabled}
    >
      <Tooltip title="Acciones">
        <div style={styles.dropdownButton}>
          <DotsIcon />
        </div>
      </Tooltip>
    </Dropdown.Toggle>
  );
};

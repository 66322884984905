import {
  StepZero,
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepSix,
  StepSeven,
} from "./components";
import { CloseButton } from "../../../modals/CloseButton";
import { DianIntegrationIcon } from "../../../../assets/svg/DianIntegrationIcon";
import { useContext } from "react";
import { UserContext } from "contexts";

const Steps = ({
  step,
  setStep,
  primaryAction,
  cancel,
  dianData,
  editDianData,
  resendAccessToken,
  credentialsError = false,
  setCredentialsError = () => {},
  data = {},
  errorMessage = {},
}) => {
  const { currentCompany, dianUserType } = useContext(UserContext);

  let showStartDate;
  if (dianUserType === "natural") {
    showStartDate = currentCompany.last_sync_at ? false : true;
  } else {
    showStartDate = dianData.isFirstTime;
  }

  const className = `my-container ${
    step === 0
      ? "step-zero"
      : step === 1
      ? "step-one"
      : step === 2 && showStartDate
      ? "step-two-with-startdate"
      : step === 2 && !showStartDate
      ? "step-two"
      : step === 4
      ? "step-four"
      : step === 6
      ? "step-six"
      : step === 7
      ? "step-seven"
      : "step-three"
  }`;
  const steps = {
    0: (
      <>
        <CloseButton onClick={cancel} />
        <div className={className}>
          <div className="dian-modal-header-icon">
            <DianIntegrationIcon />
          </div>
          <StepZero
            dianData={dianData}
            primaryAction={primaryAction}
            credentialsError={credentialsError}
            setCredentialsError={setCredentialsError}
            data={data}
            errorMessage={errorMessage}
            setStep={setStep}
          />
        </div>
      </>
    ),
    1: (
      <>
        <CloseButton onClick={cancel} />
        <div className={className}>
          <div className="dian-modal-header-icon">
            <DianIntegrationIcon />
          </div>
          <StepOne
            dianData={dianData}
            primaryAction={primaryAction}
            credentialsError={credentialsError}
            setCredentialsError={setCredentialsError}
            data={data}
            errorMessage={errorMessage}
          />
        </div>
      </>
    ),
    2: (
      <>
        <CloseButton onClick={cancel} />
        <div className={className}>
          <div className="dian-modal-header-icon">
            <DianIntegrationIcon />
          </div>
          <StepTwo
            resendAccessToken={resendAccessToken}
            dianData={dianData}
            primaryAction={primaryAction}
            editDianData={editDianData}
            showStartDate={showStartDate}
            credentialsError={credentialsError}
            setCredentialsError={setCredentialsError}
            data={data}
          />
        </div>
      </>
    ),
    3: (
      <div className={className}>
        <StepThree
          title="Importando información"
          subtitle="Este proceso puede demorar hasta 3 minutos. Por favor no cierres esta
          ventana."
          primaryAction={primaryAction}
        />
      </div>
    ),
    4: (
      <div className={className}>
        <StepFour cancel={cancel} primaryAction={primaryAction} />
      </div>
    ),
    5: (
      <div className={className}>
        <StepThree
          title="Enviando token de acceso vía email"
          subtitle="Este proceso puede demorar. Por favor no cierres esta ventana."
          primaryAction={primaryAction}
        />
      </div>
    ),
    6: (
      <div className={className}>
        <StepSix
          title="Un error ha ocurrido"
          subtitle="Haga click en reintentar para obtener un nuevo token de acceso."
          cancel={cancel}
          primaryAction={primaryAction}
        />
      </div>
    ),
    7: (
      <>
        <CloseButton onClick={cancel} />
        <div className={className}>
          <StepSeven cancel={cancel} errorMessage={errorMessage} />
        </div>
      </>
    ),
  };
  return steps[step];
};

export default Steps;

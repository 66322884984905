import { Tooltip } from "commons/components";
import React from "react";
import { NumericFormat } from "react-number-format";

export const InputNumberRow = ({ value }) => {
  return (
    <Tooltip title={<ValueFormat value={value} />}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
          overflow: "hidden",
        }}
      >
        <ValueFormat value={value} />
      </div>
    </Tooltip>
  );
};

const ValueFormat = ({ value }) => (
  <NumericFormat
    tabIndex="0"
    value={value}
    displayType={"text"}
    thousandSeparator={"."}
    decimalSeparator={","}
    prefix={"$"}
    decimalScale={2}
    fixedDecimalScale={true}
  />
);

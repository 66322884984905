export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "0",
    marginTop: "16px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    justifyContent: "center",
  },
  modalSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
    marginRight: "12px",
  },
  modalPrimaryButton: {
    backgroundColor: "#6938EF",
    border: "1px solid #6938EF",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
  },
  modalBoxSM: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100%",
  },
  modalTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  modalTitleText: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    textAlign: "center",
  },
  modalInfoContainer: {
    width: "100%",
    backgroundColor: "#FAFAFF",
    border: "1px solid #EBE9FE",
    display: "flex",
    flexDirection: "row",
    marginBottom: "24px",
    padding: "8px",
    alignItems: "center"
  },
  modalInfoContainerText: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
    marginTop: "-4px",
  },
  modalInfoText: {
    color: "#3E1C96",
    fontSize: "14px",
    fontWeight: "400",
    margin: "revert",
  },
  modalInfoSubText: {
    color: "#7A59F8",
    fontSize: "14px",
    fontWeight: "400",
    marginTop: "-8px",
  },
  modalInfoCSVButton: {
    textTransform: "inherit",
    textAlign: "inherit",
    justifyContent: "flex-start",
    padding: "10px",
    marginBottom: "10px",
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "0",
    marginTop: "16px",
    gap: "10px"
  },
  modalBodyContainer: {
    width: "100%",
    height: "100%",
  },
  modalBodyContainerLoading: { textAlign: "center" },
  modalBodyContainerLoadingText: {
    width: "100%",
    backgroundColor: "#FAFAFF",
    border: "1px solid #EBE9FE",
    display: "flex",
    flexDirection: "row",
    marginBottom: "24px",
    padding: "8px",
  },
  modalBodyLoadingText: {
    color: "#7A59F8",
    fontSize: "14px",
    fontWeight: "400",
  },
  modalBodyContainerDropzone: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalBodyContainerDropzoneText: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  modalBodyDropzoneText: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "500",
    marginRight: "4px",
},
modalBodyDropzoneSpan: {color: "#5925DC"},
modalBodyDropzoneSubText: {
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
  textAlign: "center",
},
modalBodyDropzoneTextMaxFile: {
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
  textAlign: "center",
}
};

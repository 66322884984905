import useSWR from "swr";
import { requestContainer } from "../services/api/requestContainer";

const fetcher = (requestConfig) => requestContainer(requestConfig);

const useFeaturesFlags = () => {
  const { data, error, isLoading } = useSWR(
    {
      method: "get",
      url: "/features-flags",
    },
    fetcher
  );
  const isFeatureEnabled = (featureKey) => {
    return (data?.features_flags || []).some(
      (feature) => feature.key === featureKey
    );
  };
  return {
    data,
    error,
    isLoading,
    isFeatureEnabled,
  };
};

export default useFeaturesFlags;

import React from "react";
import { useMediaQuery } from "react-responsive";
import { Stack, Box } from "@mui/material";
import { CustomerCollectionDetail } from ".";

export const CustomerCollectionFormWithImage = ({ collection, filePath }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  if (isMobile) {
    return (
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ margin: "auto", height: "100%" }}
      >
        <Box>
          <CustomerCollectionDetail collection={collection} />
        </Box>
        {filePath ? (
          <Box>
            <iframe
              src={`${filePath}#toolbar=0#view=fitH`}
              height="100%"
              width="100%"
              title="customer_collection_file"
            />
          </Box>
        ) : null}
      </Stack>
    );
  } else {
    return (
      <Stack
        direction={"row"}
        spacing={2}
        sx={{ margin: "auto", height: "100%" }}
      >
        {filePath ? (
          <Box sx={{ width: "50%" }}>
            <iframe
              src={`${filePath}#toolbar=0#view=fitH`}
              height="100%"
              width="100%"
              title="customer_collection_file"
            />
          </Box>
        ) : null}
        <Box
          sx={{
            width: filePath ? "50%" : "100%",
            minWidth: "540px",
            maxWidth: "540px",
          }}
        >
          <CustomerCollectionDetail collection={collection} />
        </Box>
      </Stack>
    );
  }
};

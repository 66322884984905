const descriptions = {
  service_not_found: "Empresa inválida",
  SDE04342: "No se encontró deuda pendiente.",
};
const getErrorDescription = (item) => {
  return (
    descriptions[item.errorDescription] ||
    "Error a consultar la deuda, vuelve a intentar más tarde.."
  );
};

export const getFailedResults = (items) =>
  items
    .filter((item) => item.errorDescription)
    .map((item) => {
      return {
        ...item,
        errorDescription: getErrorDescription(item),
      };
    });

export const getSucceededResults = (items) =>
  items.filter((item) => !item.errorDescription);

import React from "react";
import { Container, Divider, Stack, Typography } from "@mui/material";
import { styles } from "./styles";
import { PayanaLogo } from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../commons/buttons";
import { getWppLink, openInNewTab } from "../../utils";

export const ErrorScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumber = "+573017678849";
  const message =
    "Hola! Necesito ayuda con Payana, me figura que la página que estoy buscando no existe.";
  const showButton =
    !location.state || (location.state && !location.state.isCustomer);

  return (
    <Container sx={{ width: "100%", marginTop: "121px" }}>
      <Stack
        justifyContent="center"
        alignItems="center"
        mt={5}
        sx={{ gap: "64px" }}
      >
        <Stack justifyContent="center" alignItems="center">
          <PayanaLogo width={190} height={42} />
          <Divider sx={styles.divider} />
        </Stack>
        <Stack spacing={3}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ gap: "24px" }}
          >
            <Typography sx={styles.title}>Oops!</Typography>
            <Typography sx={styles.label}>
              La página que estás buscando no existe.
            </Typography>
            {showButton && (
              <PrimaryButton
                text="Ir a inicio"
                action={() => navigate("/payments/invoices")}
              />
            )}
          </Stack>
        </Stack>
        <Stack spacing={3}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ gap: "6px" }}
          >
            <Typography sx={styles.footerTitle}>Chatea con nosotros</Typography>
            <Typography sx={styles.footerLabel}>
              ¿Tienes alguna duda? Escrìbenos al{" "}
              <span
                onClick={() => openInNewTab(getWppLink(phoneNumber, message))}
              >
                <strong style={{ cursor: "pointer" }}>+57 301 7678849</strong>
              </span>
              .
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

import React from "react";

export const CompanyIcon = () => (
  <svg width="48" height="48" viewBox="0 0 56 56" fill="none">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#EBE9FE" />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#F4F3FF"
      strokeWidth="8"
    />
    <path
      d="M23.5 27H20.6C20.0399 27 19.7599 27 19.546 27.109C19.3578 27.2049 19.2049 27.3578 19.109 27.546C19 27.7599 19 28.0399 19 28.6V37M32.5 27H35.4C35.9601 27 36.2401 27 36.454 27.109C36.6422 27.2049 36.7951 27.3578 36.891 27.546C37 27.7599 37 28.0399 37 28.6V37M32.5 37V22.2C32.5 21.0799 32.5 20.5198 32.282 20.092C32.0903 19.7157 31.7843 19.4097 31.408 19.218C30.9802 19 30.4201 19 29.3 19H26.7C25.5799 19 25.0198 19 24.592 19.218C24.2157 19.4097 23.9097 19.7157 23.718 20.092C23.5 20.5198 23.5 21.0799 23.5 22.2V37M38 37H18M27 23H29M27 27H29M27 31H29"
      stroke="#7F56D9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";

export const ArrowNarrowBottomLeftIcon = ({
  width = "25",
  height = "24",
  stroke = "#039855",
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
    <path
      d="M18.5 6L6.5 18M6.5 18H14.5M6.5 18V10"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

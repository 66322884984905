import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { MailOutline } from "@mui/icons-material";
import { EMAIL_VALIDATOR } from "../../utils";

export const EmailInput = ({
  value,
  setValue,
  register,
  required = true,
  backgroundColor = "white",
  disabled = false,
}) => {
  return (
    <TextField
      id="email"
      size="small"
      variant="outlined"
      placeholder="Ejemplo: correo@empresa.com"
      fullWidth
      InputProps={{
        style: { fontSize: 14 },
        startAdornment: (
          <InputAdornment position="start">
            <MailOutline fontSize="small" />
          </InputAdornment>
        ),
      }}
      style={{
        width: "100%",
        backgroundColor: backgroundColor,
        borderRadius: "8px",
      }}
      value={value}
      disabled={disabled}
      onChange={(event) => setValue(event.target.value)}
      {...register("email", EMAIL_VALIDATOR(required))}
    />
  );
};

import { Dropdown } from "react-bootstrap";
import { PlusIcon } from "../../assets";

export const NewButton = ({ action, text, disabled = false, style = {} }) => {
  const styles = {
    backgroundColor: "#6938EF",
    borderRadius: "8px",
    height: "40px",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    borderColor: "transparent",
    outline: "0",
    boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 0%)",
    padding: "8px 16px 8px 12px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    ...style,
  };
  return (
    <Dropdown.Toggle
      {...(action ? { onClick: action } : {})}
      disabled={disabled}
      style={styles}
      id="dropdown-basic"
    >
      <div
        style={{
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <PlusIcon />
        <span style={{ paddingLeft: 6, alignSelf: "center" }}>{text}</span>
      </div>
    </Dropdown.Toggle>
  );
};

import React from "react";

export const ApprovedIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none">
    <rect width="56" height="56" rx="28" fill="#D1FADF" />
    <path
      d="M24.4999 28L26.8333 30.3333L32.0833 25.0833M22.556 18.4552C23.4938 18.3803 24.3841 18.0115 25.1001 17.4013C26.7711 15.9773 29.2287 15.9773 30.8997 17.4013C31.6158 18.0115 32.506 18.3803 33.4439 18.4552C35.6323 18.6298 37.3701 20.3676 37.5448 22.5561C37.6196 23.4939 37.9884 24.3842 38.5986 25.1002C40.0226 26.7712 40.0226 29.2288 38.5986 30.8998C37.9884 31.6158 37.6196 32.5061 37.5448 33.4439C37.3701 35.6324 35.6323 37.3702 33.4439 37.5449C32.506 37.6197 31.6158 37.9885 30.8997 38.5987C29.2287 40.0227 26.7711 40.0227 25.1001 38.5987C24.3841 37.9885 23.4938 37.6197 22.556 37.5449C20.3675 37.3702 18.6297 35.6324 18.4551 33.4439C18.3802 32.5061 18.0115 31.6158 17.4012 30.8998C15.9773 29.2288 15.9773 26.7712 17.4012 25.1002C18.0115 24.3842 18.3802 23.4939 18.4551 22.5561C18.6297 20.3676 20.3675 18.6298 22.556 18.4552Z"
      stroke="#039855"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowDownIcon = ({ on = false }) =>
  on ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 3.33325V12.6666M8.00016 12.6666L12.6668 7.99992M8.00016 12.6666L3.3335 7.99992"
        stroke={on ? "#344054" : "#667085"}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 3 }}
    >
      <path
        d="M0.666504 7.99996L3.99984 11.3333L7.33317 7.99996M0.666504 3.99996L3.99984 0.666626L7.33317 3.99996"
        stroke="#667085"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

import React from "react";

export const MoneyRejectedIcon = ({
  stroke = "#6938EF",
  fill = "#F9FAFB",
  width = "24",
  height = "24",
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
    <rect x="1.25" y="0.75" width="22.5" height="22.5" rx="11.25" fill={fill} />
    <rect
      x="1.25"
      y="0.75"
      width="22.5"
      height="22.5"
      rx="11.25"
      stroke={stroke}
      strokeWidth="1.5"
    />
    <path
      d="M10.6999 7.61469L18.6811 16.0467L18.6926 16.0467C19.5011 16.8947 18.5656 18.1783 17.5029 17.1184C17.5029 17.1184 8.04333 9.02789 7.50047 8.60393C7.11784 8.30511 6.86521 7.88556 6.86521 7.40271C6.86521 6.89632 7.11784 6.4187 7.50047 6.18972C8.10786 5.91399 8.60287 5.96046 9.09788 6.18972C9.59288 6.41899 10.6999 7.61469 10.6999 7.61469Z"
      fill={stroke}
    />
    <path
      d="M14.9151 8.99754L6.81698 17.0254L6.80543 17.0254C5.99692 17.8733 6.93248 19.157 7.9951 18.0971C7.9951 18.0971 17.4547 10.0065 17.9976 9.58258C18.3802 9.28376 18.6328 8.86421 18.6328 8.38137C18.6328 7.98813 18.3802 7.68767 18.1267 7.5726C17.5193 7.29687 17.0122 7.34331 16.5171 7.57257C16.0221 7.80183 14.9151 8.99754 14.9151 8.99754Z"
      fill={stroke}
    />
  </svg>
);

import { Stack, TextField, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { PrimaryButton } from "../../../buttons";
import { useContext, useState } from "react";
import { UserContext } from "../../../../contexts";

const SiigoIntegrationForm = ({ primaryAction }) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {},
  });
  const [loading, setLoading] = useState(false);
  const formValues = useWatch({ control });
  const [syncError, setSyncError] = useState("");

  const { currentCompany } = useContext(UserContext);
  const [user, setUser] = useState(currentCompany.user_cs || "");
  const [pass, setPass] = useState(currentCompany.password_cs || "");

  const onSubmit = async (data) => {
    setLoading(true);
    await primaryAction(data);
    setLoading(false);
  };

  const onUserInputChanged = (e) => {
    setUser(e.target.value);
    syncError !== "" && setSyncError("");
  };
  const onPasswordInputChanged = (e) => {
    setPass(e.target.value);
    syncError !== "" && setSyncError("");
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} my={2}>
        <Stack spacing={1}>
          <Typography sx={{ fontWeight: "600" }} noWrap>
            Usuario
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            placeholder="nombre@empresa.com"
            type="email"
            onChange={onUserInputChanged}
            value={formValues.user}
            {...register("user", { required: true })}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography sx={{ fontWeight: "600" }} noWrap>
            Contraseña
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            placeholder="********"
            type="password"
            onChange={onPasswordInputChanged}
            value={formValues.pass}
            {...register("pass", { required: true })}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%" }}
      >
        <PrimaryButton
          width="100%"
          text="Continuar"
          isDisable={loading || !formValues.user || !formValues.pass}
          loading={loading}
          type="submit"
        />
      </Stack>
    </form>
  );
};
export default SiigoIntegrationForm;

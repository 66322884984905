export const userRolesWithManager = [
  { label: "Gerente", value: "manager", id: 1 },
  { label: "Administrador", value: "admin", id: 1 },
  { label: "Colaborador", value: "colab", id: 2 },
  { label: "Contador", value: "accountant", id: 3 },
];

export const userRoles = [
  { label: "Administrador", value: "admin", id: 1 },
  { label: "Colaborador", value: "colab", id: 2 },
  { label: "Contador", value: "accountant", id: 3 },
];

export const ArrowUpIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 12.6666V3.33331M7.99992 3.33331L3.33325 7.99998M7.99992 3.33331L12.6666 7.99998"
      stroke="#344054"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";

export const XIcon = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
    <path
      d="M7 1L1 7M1 1L7 7"
      stroke="#98A2B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

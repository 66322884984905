import React from "react";

export const CreateUserIcon = () => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none">
    <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#EBE9FE" />
    <rect
      x="4.5"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#F4F3FF"
      strokeWidth="8"
    />
    <path
      d="M28.5 31.5H24C22.6044 31.5 21.9067 31.5 21.3389 31.6722C20.0605 32.06 19.06 33.0605 18.6722 34.3389C18.5 34.9067 18.5 35.6044 18.5 37M35.5 37V31M32.5 34H38.5M31 23.5C31 25.9853 28.9853 28 26.5 28C24.0147 28 22 25.9853 22 23.5C22 21.0147 24.0147 19 26.5 19C28.9853 19 31 21.0147 31 23.5Z"
      stroke="#7F56D9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";
import "../stylesSubscription.css";
import { styles } from "./styles";

export const FeatureItem = ({
  title,
  price,
  discount,
  checked,
  value,
  onClick,
}) => (
  <div style={{ display: "flex", gap: "10px" }}>
    <div>
      <input
        type="checkbox"
        className={"custom-checkbox"}
        checked={checked}
        value={value}
        onClick={onClick}
      />
    </div>
    <div>
      <div style={{ display: "flex", gap: "6px" }}>
        <p style={styles.featureItemTitle}>{title}</p>
        {discount && <span style={styles.featureItemDiscount}>{discount}</span>}
      </div>
      <p style={styles.featureItemPrice}>{price}</p>
    </div>
  </div>
);

import { Stack } from "@mui/material";
import { CompanyLogo } from "../CompanyLogo";

export const CompanySearchOption = ({ company }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <CompanyLogo logoUrl={company.companyLogo || company.logoUrl} />
      <span> {company.name}</span>
    </Stack>
  );
};

import React from "react";

export const PlusIcon = ({ stroke = "#FFFFFF" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M9.99996 4.16663V15.8333M4.16663 9.99996H15.8333"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SwitchIcon = ({ style = {} }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" style={style}>
    <path
      d="M2.33325 9.91667H11.6666M11.6666 9.91667L9.33325 7.58333M11.6666 9.91667L9.33325 12.25M11.6666 4.08333H2.33325M2.33325 4.08333L4.66659 1.75M2.33325 4.08333L4.66659 6.41667"
      stroke="#737373"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";

export const DocumentIcon = ({ width = "32", height = "32", stroke = "#6938EF", circle=true }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {circle && <rect width="32" height="32" rx="16" fill="#F4F3FF" />}
    <path
      d="M17.3332 9.51306V12.2668C17.3332 12.6401 17.3332 12.8268 17.4058 12.9694C17.4697 13.0949 17.5717 13.1968 17.6972 13.2608C17.8398 13.3334 18.0265 13.3334 18.3998 13.3334H21.1535M18.6665 16.6667H13.3332M18.6665 19.3334H13.3332M14.6665 14H13.3332M17.3332 9.33337H13.8665C12.7464 9.33337 12.1863 9.33337 11.7585 9.55136C11.3822 9.74311 11.0762 10.0491 10.8845 10.4254C10.6665 10.8532 10.6665 11.4133 10.6665 12.5334V19.4667C10.6665 20.5868 10.6665 21.1469 10.8845 21.5747C11.0762 21.951 11.3822 22.257 11.7585 22.4487C12.1863 22.6667 12.7464 22.6667 13.8665 22.6667H18.1332C19.2533 22.6667 19.8133 22.6667 20.2412 22.4487C20.6175 22.257 20.9234 21.951 21.1152 21.5747C21.3332 21.1469 21.3332 20.5868 21.3332 19.4667V13.3334L17.3332 9.33337Z"
      stroke={stroke}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

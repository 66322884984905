import React from "react";

export const FilesStackIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#EBE9FE" />
    <g clipPath="url(#clip0_8557_16254)">
      <path
        d="M9.33325 16L15.7614 19.2141C15.8489 19.2578 15.8926 19.2797 15.9385 19.2883C15.9791 19.2959 16.0208 19.2959 16.0614 19.2883C16.1073 19.2797 16.151 19.2578 16.2384 19.2141L22.6666 16M9.33325 19.3333L15.7614 22.5474C15.8489 22.5911 15.8926 22.613 15.9385 22.6216C15.9791 22.6292 16.0208 22.6292 16.0614 22.6216C16.1073 22.613 16.151 22.5911 16.2384 22.5474L22.6666 19.3333M9.33325 12.6667L15.7614 9.45259C15.8489 9.40886 15.8926 9.387 15.9385 9.37839C15.9791 9.37077 16.0208 9.37077 16.0614 9.37839C16.1073 9.387 16.151 9.40886 16.2384 9.45259L22.6666 12.6667L16.2384 15.8807C16.151 15.9245 16.1073 15.9463 16.0614 15.9549C16.0208 15.9626 15.9791 15.9626 15.9385 15.9549C15.8926 15.9463 15.8489 15.9245 15.7614 15.8807L9.33325 12.6667Z"
        stroke="#7F56D9"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8557_16254">
        <rect width="16" height="16" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

import React, { useContext } from "react";
import { styles } from "../styles";
import { Box, Stack } from "@mui/material";
import { CloseButton, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SubscriptionButton, SecondaryButton } from "../../../buttons";
import { causationStates } from "../../../../constants";
import { CausationContext } from "contexts";
import { CheckCircleIcon } from "assets";
import { updateInvoices } from "services";

export function ExpandCausationHeader({
  handleSendCausation,
  handleSaveCausation,
  stateCausation = causationStates.draft,
  id,
  requestInProcess,
  subscription,
}) {
  const navigate = useNavigate();
  const { causationScreen } = useContext(CausationContext);

  const setSyncBanner = causationScreen?.syncBanner?.setter;

  const hideSyncBanner = () => {
    setSyncBanner((syncBanner) => {
      return {
        ...syncBanner,
        visible: false,
      };
    });
  };

  return (
    <div className="modal-header-payana">
      <div style={styles.headerRow}>
        <div
          onClick={() => {
            hideSyncBanner();
            navigate("/payments/invoices", { replace: true });
          }}
        >
          <CloseButton style={{ boxShadow: "none" }} />
        </div>
        <div
          className="modalTitle"
          style={{ marginLeft: "16px", fontSize: "18px" }}
        >
          Detalle de cuenta de cobro
        </div>
      </div>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!id || (id && stateCausation === causationStates.draft) ? (
          <Stack gap={"8px"} flexDirection={"row"}>
            <SecondaryButton
              text={
                requestInProcess ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Guardar"
                )
              }
              action={() => {
                hideSyncBanner();
                handleSaveCausation();
              }}
              width="80px"
              isDisable={requestInProcess}
            />
            {id && stateCausation === causationStates.draft && (
              <SecondaryButton
                text={
                  requestInProcess ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Marcar como causada"
                  )
                }
                action={() => {
                  updateInvoices([id]);
                  hideSyncBanner();
                  handleSaveCausation(true);
                }}
                icon={
                  !requestInProcess && (
                    <CheckCircleIcon
                      color={"#344054"}
                      height={"20px"}
                      width={"20px"}
                    />
                  )
                }
                width="208px"
                isDisable={requestInProcess}
                textStyle={{ marginLeft: "8px" }}
              />
            )}
            <SubscriptionButton
              subscription={subscription}
              isDisable={requestInProcess}
              text={
                requestInProcess ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Causar"
                )
              }
              action={() => {
                hideSyncBanner();
                handleSendCausation();
              }}
              width="120px"
            />
          </Stack>
        ) : (
          <SecondaryButton
            text="Visualizar en Siigo"
            action={() =>
              window.open(
                "https://siigonube.siigo.com/ISIIGO2/Login.aspx?ReturnUrl=%2FISIIGO2%2Findex.html%23%2Freports%2F2000%2F5451%3FTabID%3D1408",
                "_blank"
              )
            }
            width="100%"
            style={{ marginRight: "10px" }}
          />
        )}
      </Box>
    </div>
  );
}

import { createContext, useState } from "react";
import {
  GetBOReceipts,
  UpdateReceiptStatus,
  UpdateSendSupport,
  GetAllBanksBO,
} from "../../services";
import { toast } from "react-toastify";
import { toastOptions } from "../../constants";

export const BackofficeReceiptContext = createContext();

export function BackofficeReceiptProvider({ children }) {
  const [BOreceipts, setBOreceipts] = useState([]);
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);
  const [statusSelected, setStatusSelected] = useState("all");
  const [providerIdSelected, setProviderIdSelected] = useState("all");
  const [employeeIdSelected, setEmployeeIdSelected] = useState("all");
  const [companyIdSelected, setCompanyIdSelected] = useState("all");
  const [typeSelected, setTypeSelected] = useState("all");
  const [hashSelected, setHashSelected] = useState("");
  const [allBanksBO, setAllBanksBO] = useState([]);

  const getBOreceipts = async ({
    pageIndex = 1,
    status,
    providerId,
    companyId,
    transactionHash,
    employeeId,
    type,
  }) => {
    const gottenReceipts = await GetBOReceipts({
      pageIndex: pageIndex,
      status: status || statusSelected,
      providerId: providerId || providerIdSelected,
      companyId: companyId || companyIdSelected,
      transactionHash: transactionHash || hashSelected,
      employeeId: employeeId || employeeIdSelected,
      type: type || typeSelected,
    });
    if (gottenReceipts) {
      setBOreceipts(gottenReceipts);
    }
  };

  const changeBOReceiptStatus = async (id, status, pageIndex = 1) => {
    const response = await UpdateReceiptStatus(id, status);
    if (response) {
      toast.success("¡El soporte se actualizó con éxito!", toastOptions);
      getBOreceipts({
        pageIndex: pageIndex,
        status: statusSelected,
        providerId: providerIdSelected,
        companyId: companyIdSelected,
        transactionHash: hashSelected,
        employeeId: employeeIdSelected,
        type: typeSelected,
      });
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const updateSendSupport = async (id, pageIndex = 1) => {
    const response = await UpdateSendSupport(id);
    if (response) {
      toast.success("¡El soporte se actualizó con éxito!", toastOptions);
      getBOreceipts({
        pageIndex: pageIndex,
        status: statusSelected,
        providerId: providerIdSelected,
        companyId: companyIdSelected,
        transactionHash: hashSelected,
        employeeId: employeeIdSelected,
        type: typeSelected,
      });
    } else {
      toast.error("Hubo un error al actulizar el soporte", toastOptions);
    }
  };

  const getAllBanksBO = async () => {
    const gottenBanks = await GetAllBanksBO();
    if (gottenBanks !== null) {
      setAllBanksBO(gottenBanks);
    }
  };

  return (
    <BackofficeReceiptContext.Provider
      value={{
        BOreceipts,
        getBOreceipts,
        statusModalIsOpen,
        setStatusModalIsOpen,
        changeBOReceiptStatus,
        updateSendSupport,
        statusSelected,
        setStatusSelected,
        providerIdSelected,
        setProviderIdSelected,
        companyIdSelected,
        setCompanyIdSelected,
        hashSelected,
        setHashSelected,
        getAllBanksBO,
        allBanksBO,
        typeSelected,
        setTypeSelected,
        employeeIdSelected,
        setEmployeeIdSelected,
      }}
    >
      {children}
    </BackofficeReceiptContext.Provider>
  );
}

import React from "react";
import { styles } from "../styles";

export const Title = ({ title, icon }) => {
  return (
    <div>
      <div style={styles.cardRow}>
        {icon}
        <span style={styles.cardTitle}>{title}</span>
      </div>
      <hr style={{ border: "1px solid #D0D5DD", margin: "0px" }} />
    </div>
  );
};

import { MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { documentTypes } from "../../../../constants";
import { ValidationMessage } from "../../../components";
import { useForm, useWatch } from "react-hook-form";
import { styles } from "../../SupplierDetails/styles";
import { PrimaryButton } from "../../../buttons";
import { useState } from "react";
import { getValidators } from "../../../../utils";
import { DocumentNumberInput, DocumentTypeInput } from "commons/inputs";

const idTypeWordings = {
  CC: "Cédula de ciudadanía",
  CE: "Cédula de extranjería",
  TI: "Tarjeta de identidad",
  PPT: "Permiso por protección temporal",
};

const DianIntegrationForm = ({
  primaryAction,
  dianData,
  credentialsError = false,
  setCredentialsError = () => {},
  data = {},
}) => {
  const { register, handleSubmit, formState, control, setValue } = useForm({
    defaultValues: data || {
      documentType: dianData.documentType || "",
      documentNumber: dianData.documentNumber || "",
      companyDocumentNumber: dianData.companyDocumentNumber || "",
    },
  });
  const [loading, setLoading] = useState(false);
  const formValues = useWatch({ control });

  const helperText = credentialsError ? "Revisa los datos." : "";

  const onSubmit = async (data) => {
    setLoading(true);
    setCredentialsError(false);
    await primaryAction(data);
    setLoading(false);
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Stack my={2} spacing={2}>
        <Stack spacing={1}>
          <DocumentTypeInput
            label="Tipo de documento del Representante legal"
            value={formValues.documentType}
            setValue={(value) => {
              setValue("documentType", value, { shouldValidate: false });
            }}
            setDocumentNumberValue={(value) => {
              setValue("documentNumber", value, { shouldValidate: false });
            }}
            register={register}
            errors={formState.errors}
            documentNumberValue={formValues.documentNumber}
          />
          {helperText && (
            <Typography sx={styles.inputError} noWrap>
              Revisar selección.
            </Typography>
          )}
        </Stack>
        <Stack spacing={1}>
          <DocumentNumberInput
            label="Número de documento del Representante legal"
            value={formValues.documentNumber}
            setValue={(value) => {
              setValue("documentNumber", value, { shouldValidate: false });
            }}
            documentType={formValues.documentType}
            register={register}
            errors={formState.errors}
          />
          {helperText && (
            <Typography sx={styles.inputError} noWrap>
              {helperText}
            </Typography>
          )}
        </Stack>
        <Stack spacing={1}>
          <DocumentNumberInput
            label="NIT empresa"
            value={formValues.identification_number}
            setValue={(value) => {
              setValue("companyDocumentNumber", value, {
                shouldValidate: false,
              });
            }}
            documentType="NIT"
            register={register}
            errors={formState.errors}
            id="companyDocumentNumber"
          />
          {helperText && (
            <Typography sx={styles.inputError} noWrap>
              {helperText}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ width: "100%" }}
      >
        <PrimaryButton
          width="100%"
          text="Continuar"
          isDisable={
            loading ||
            !formValues.documentType ||
            !formValues.documentNumber ||
            !formValues.companyDocumentNumber
          }
          loading={loading}
          type="submit"
        />
      </Stack>
    </form>
  );
};
export default DianIntegrationForm;

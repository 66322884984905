import React from "react";
import { Button } from "@mui/material";
import { styles } from "../styles";

export const StatusFilter = ({ statusSelected, setStatusSelected }) => {
  return (
    <div>
      <div style={styles.row}>
        <Button
          variant="text"
          sx={
            statusSelected === "all"
              ? styles.firstStatusOptionsSelected
              : styles.firstStatusOptions
          }
          onClick={() => {
            setStatusSelected("all");
          }}
        >
          Todas
        </Button>
        <Button
          variant="text"
          sx={
            statusSelected === "in_process"
              ? styles.statusOptionsSelected
              : styles.statusOptions
          }
          onClick={() => {
            setStatusSelected("in_process");
          }}
        >
          En proceso
        </Button>
        <Button
          variant="text"
          sx={
            statusSelected === "paid"
              ? styles.statusOptionsSelected
              : styles.statusOptions
          }
          onClick={() => {
            setStatusSelected("paid");
          }}
        >
          Pagadas
        </Button>
      </div>
      <hr style={styles.separator} />
    </div>
  );
};

import { beneficiaryTypeOptions } from "constants";
import { requestContainer } from "../requestContainer";
import { TYPE_DEFAULT_PARAMETER } from "screens/TransactionsScreen/constants";

export async function GetTransactions(pageIndex, type = "invoice", query = "") {
  return requestContainer({
    method: "get",
    url: `/transactions?page=${pageIndex}&type=${type}&text=${query}&per_page=100`,
  });
}

export async function GetTransactionsV2(
  pageIndex,
  beneficiaryIdSelected,
  beneficiaryType,
  startDate,
  endDate,
  transactionType
) {
  return requestContainer({
    method: "get",
    url: `/transactions/panel?page=${pageIndex}&${beneficiaryType}=${beneficiaryIdSelected}&per_page=5&start=${startDate}&end=${endDate}&transaction_type=${transactionType}`,
  });
}

export async function GetTotalTransactions({
  type = "invoice",
  text = "",
  customerId = "all",
  startDate = "all",
  endDate = "all",
}) {
  return requestContainer({
    method: "get",
    url: `/transactions/total?type=${type}&text=${text}&customer_id=${customerId}&start=${startDate}&end=${endDate}`,
  });
}

export async function GetTotalTransactionsV2({
  beneficiaryIdSelected = "all",
  beneficiaryTypeSelected = "provider",
  startDate = "all",
  endDate = "all",
  transactionType = TYPE_DEFAULT_PARAMETER,
}) {
  return requestContainer({
    method: "get",
    url: `/transactions/panel/total?start=${startDate}&end=${endDate}&${beneficiaryTypeOptions[beneficiaryTypeSelected]}=${beneficiaryIdSelected}&transaction_type=${transactionType}`,
  });
}

export async function GetTransaction({ id }) {
  return requestContainer({
    method: "get",
    url: `/transactions/${id}`,
  });
}

export async function GetTransactionV2({
  id,
  type = "",
  beneficiaryType = "",
}) {
  return requestContainer({
    method: "get",
    url: `/transactions/panel/${id}?type=${type}&beneficiary_type=${beneficiaryType}`,
  });
}

export async function CreateTransaction({
  ids,
  type = "invoice",
  status,
  source_of_funds_id,
  payment_gateway,
  payment_method,
  ...otherProps
}) {
  const data = status
    ? {
        rows: ids,
        type: type,
        status: status,
        source_of_funds_id,
        payment_gateway,
        payment_method,
      }
    : {
        rows: ids,
        type: type,
        source_of_funds_id,
        payment_gateway,
        payment_method,
      };
  return requestContainer({
    method: "post",
    url: `/transactions`,
    data: { ...data, ...otherProps },
  });
}

export async function CreateTransactionToPay({
  id,
  payment_gateway,
  payment_method,
  source_of_funds_id,
}) {
  return requestContainer({
    method: "post",
    url: `/transactions/${id}/to-pay`,
    data: {
      payment_gateway,
      payment_method,
      source_of_funds_id,
    },
  });
}

export async function NotifyTransactionId({ id }) {
  return requestContainer({
    method: "get",
    url: `/transactions/notification/reference/${id}`,
  });
}
export async function NotifyCollectionTransactionId({ id }) {
  return requestContainer({
    method: "get",
    url: `/transactions/collections/notification/reference/${id}`,
  });
}

export async function NotifyTransactionHash({ hash }) {
  return requestContainer({
    method: "get",
    url: `/transactions/by-hash/${hash}`,
  });
}

export async function NotifyCustomerPayTransactionHash({ hash }) {
  return requestContainer({
    method: "get",
    url: `/transactions/collections/by-hash/${hash}`,
  });
}

export async function DeleteTransaction({ id }) {
  return requestContainer({
    method: "delete",
    url: `/transactions/${id}`,
  });
}

export async function DeleteCollectionTransaction({ id }) {
  return requestContainer({
    method: "delete",
    url: `/transactions/collections/${id}`,
  });
}

export async function GetCollectionTransactions(pageIndex, customerId) {
  return requestContainer({
    method: "get",
    url: `/transactions/collections?page=${pageIndex}&customer_id=${customerId}&per_page=100`,
  });
}

export async function GetCollectionTransaction({ id }) {
  return requestContainer({
    method: "get",
    url: `/transactions/collections/${id}`,
  });
}

export function ExportTransactionsFile(dateFrom, dateTo) {
  return requestContainer({
    method: "post",
    url: `/export/reconciliation-report?start=${dateFrom}&end=${dateTo}`,
  });
}

export function RetryPayment({ receiptId }) {
  return requestContainer({
    method: "post",
    url: `/payout/retry`,
    data: {
      receiptId,
    },
  });
}

export async function SearchTransactionBeneficiary(query) {
  return requestContainer({
    method: "get",
    url: `/transactions/search?q=${query}`,
  });
}

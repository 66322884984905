import React from "react";
import { Stack } from "@mui/material";
import { ChevronRight, ModalSuccessIcon } from "assets";
import styles from "../company-switch-modal.module.css";
import { ButtonWithIcon } from "commons/components";
import { ChangeUserCompany } from "services";
import { useNavigate } from "react-router-dom";
import { userRolesWithManager } from "constants";

export const CreateSuccess = ({
  createdCompany,
  onClose,
  isManager,
  companyData = {},
}) => {
  const navigate = useNavigate();

  const switchCompany = () => {
    ChangeUserCompany({ id: createdCompany.id }).then(() => {
      onClose();
      navigate(
        companyData.roleName === "accountant"
          ? "/payments/invoices"
          : "/onboarding",
        { replace: true }
      );
      window.location.reload(true);
    });
  };

  return (
    <Stack
      direction={"column"}
      alignItems="center"
      justifyContent="center"
      gap={"8px"}
    >
      <ModalSuccessIcon />
      <Stack
        direction={"column"}
        alignItems="center"
        justifyContent="center"
        gap={"8px"}
        style={{ marginBottom: "12px" }}
      >
        <div className={styles["success-title"]}>¡Listo!</div>
        <div className={styles["description"]}>
          {isManager
            ? `La empresa ${createdCompany.name} fue creada con éxito.`
            : "Le enviamos un mail de confirmación al dueño/gerente."}
        </div>
        {!isManager && (
          <div className={styles["description"]}>
            Mientras tanto, puedes ingresar como{" "}
            {
              userRolesWithManager.find(
                ({ value }) => value === companyData.roleName
              )?.label
            }{" "}
            dentro de Payana.
          </div>
        )}
      </Stack>
      <ButtonWithIcon
        action={() => switchCompany()}
        buttonLabel={"Ingresar"}
        endIcon={<ChevronRight color="#FFFFFF" />}
        width={"155px"}
        style={{ alignSelf: "flex-end" }}
      />
    </Stack>
  );
};

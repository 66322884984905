import { useMediaQuery } from "react-responsive";
import { getStatusPillStyle, getStatusWording } from "../../../../utils";

export const CollectionTrxStatus = ({
  status,
  className = "",
  section = null,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  return (
    <p
      className={className}
      style={getStatusPillStyle(status, isMobile, section)}
    >
      {getStatusWording(status, isMobile, section)}
    </p>
  );
};

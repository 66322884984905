import React from "react";
import { Grid } from "@mui/material";
import FetchingPDF from "../../ExpandInvoiceModal/components/FetchingPDF";

export function PdfViewContainer({
  children,
  filePath,
  checked,
  filePathIsFromDian = false,
}) {
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      spacing={2}
      sx={{
        margin: "10px auto",
        height: "100%",
        width: "95%",
      }}
    >
      {checked &&
        (filePathIsFromDian ? (
          <FetchingPDF />
        ) : (
          <Grid item xs={12} sm={8} md={7} style={{ paddingLeft: "0px" }}>
            {filePath && (
              <iframe
                src={`${filePath}#toolbar=0#view=fitH`}
                height="100%"
                width="100%"
                title="invoice_file"
              />
            )}
          </Grid>
        ))}
      <Grid
        item
        xs={12}
        sm={checked ? 4 : 12}
        md={checked ? 5 : 12}
        style={{ maxWidth: checked ? "" : "600px" }}
      >
        {children}
      </Grid>
    </Grid>
  );
}

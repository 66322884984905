import { CircularProgress } from "@mui/material";
import { Button } from "react-bootstrap";
import { styles } from "../../screens/Login/styles";

const LoadingButton = ({ loading, onClick, disabled, children }) => {
  return !loading ? (
    <Button
      className="boton-color-verde"
      disabled={disabled}
      variant="primary"
      style={styles.primaryButton}
      onClick={onClick}
    >
      {children}
    </Button>
  ) : (
    <Button
      variant="primary"
      style={styles.primaryLoadingButton}
      onClick={() => {}}
    >
      <CircularProgress size={22} color="inherit" />
    </Button>
  );
};

export default LoadingButton;

import { requestContainer } from "../requestContainer";

export async function GetReceivers({ pageIndex = 1, receiverId = "all" }) {
  return requestContainer({
    method: "get",
    url: `/receivers?page=${pageIndex}&receiver_id=${receiverId}`,
  });
}

export async function GetReceiver({ id }) {
  return requestContainer({
    method: "get",
    url: `/receivers/${id}`,
  });
}

export async function GetTotalReceivers({ receiverId }) {
  return requestContainer({
    method: "get",
    url: `/receivers/total?receiver_id=${receiverId}`,
  });
}

export async function GetMainReceiver() {
  return requestContainer({
    method: "get",
    url: `/receivers/main`,
  });
}

export async function CreateReceiver({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/receivers`,
    data: {
      ...formValues,
    },
  });
}

export async function UpdateReceiver({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/receivers/${id}`,
    data: {
      ...formValues,
    },
  });
}

export async function DeleteReceiver({ id }) {
  return requestContainer({
    method: "delete",
    url: `/receivers/${id}`,
  });
}

export async function GetBanks({ pageIndex }) {
  return requestContainer({
    method: "get",
    url: `/banks?page=${pageIndex}`,
  });
}

export async function SearchReceivers(query) {
  return requestContainer({
    method: "get",
    url: `/receivers/search?q=${query}`,
  });
}

import React, { useContext, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Box, Stack, Typography } from "@mui/material";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { es } from "date-fns/locale";
import { ExportTransactionsFile } from "../../../services";
import { addMonths, format } from "date-fns";
import createXLSX from "./downloadXLSX";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import { UserContext } from "contexts";
import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges } from "utils";

export const PickDatesToExportModal = ({ visible, handleClose }) => {
  const { currentUser } = useContext(UserContext);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [date, setDate] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const exportFile = () => {
    const firstDate = format(date.at(0).startDate, "yyyy-MM-dd");
    const secondDate = format(date.at(0).endDate, "yyyy-MM-dd");

    return ExportTransactionsFile(firstDate, secondDate);
  };

  const runExport = () => {
    exportFile()
      .then(createXLSX)
      .then(() => {
        amplitude.logEvent("DOWNLOAD_CONCILIATION_REPORT", {
          user_id: currentUser.id,
        });
        toast.success("¡Archivo exportado con éxito!", toastOptions);
      })
      .catch((error) => {
        toast.error(
          "Hubo un error exportando el archivo, por favor intente nuevamente.",
          toastOptions
        );
      });
    closeAndReset();
  };

  const closeAndReset = () => {
    setDateFrom(null);
    setDateTo(null);
    handleClose();
  };

  return (
    <Modal show={visible} onHide={closeAndReset} centered>
      <Modal.Body
        style={{
          padding: "20px",
          maxWidth: "600px",
        }}
      >
        <Box>
          <Typography variant="h6" align="left" mb={2}>
            Descarga todos tus movimientos en Payana
          </Typography>
          <Typography style={styles.subtitle}>
            Elige un período y exporta la hoja de cálculo para conciliar: pagos
            a proveedores, pagos a nóminas y recaudos.
          </Typography>
          <Box
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              justifyContent: "space-between",
            }}
          >
            <DateRangePicker
              editableDateInputs={true}
              ranges={date}
              locale={es}
              onChange={(item) => setDate([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              direction="horizontal"
              rangeColors={["#7F56D9"]}
              color="#7F56D9"
              calendarFocus="backwards"
              minDate={new Date("2022-01-01")}
              maxDate={new Date()}
              startDatePlaceholder="Último año"
              endDatePlaceholder="Hoy"
              staticRanges={defaultStaticRanges}
              inputRanges={[]}
              fixedHeight={true}
              shownDate={new Date()}
            />
          </Box>
          <Stack
            direction={"row"}
            pt={4}
            justifyContent={"space-between"}
            sx={{
              gap: "16px",
            }}
          >
            <SecondaryButton
              width={"100%"}
              text="Cancelar"
              action={closeAndReset}
            />
            <PrimaryButton
              text="Exportar"
              width={"100%"}
              action={runExport}
              isDisable={!date.at(0).startDate || !date.at(0).endDate}
            />
          </Stack>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

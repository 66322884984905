import { requestContainer } from "../requestContainer";

export const saveDianData = (data) => {
  return requestContainer({
    url: "/dian",
    method: "post",
    data,
    timeout: 2 * 60 * 1000,
  }).then((res) => {
    if (res?.response?.data?.error_code) {
      throw new Error(res?.response?.data?.error_code);
    }
    if (res?.code === "ECONNABORTED") {
      throw new Error(res?.code);
    }
    return res;
  });
};

export const deliverAccessToken = () => {
  return requestContainer({
    url: "/dian/token",
    method: "post",
    timeout: 2 * 60 * 1000,
  }).then((res) => {
    if (res?.response?.status === 400) {
      throw new Error(res.response.data.message);
    }
    if (res?.response?.data?.error_code) {
      throw new Error(res?.response?.data?.error_code);
    }
    if (res?.code === "ECONNABORTED") {
      throw new Error(res?.code);
    }
    return res;
  });
};

export const saveDianDataURL = (data) => {
  return requestContainer({
    url: "/dian",
    method: "put",
    data,
    timeout: 5 * 60 * 1000,
  }).then((res) => {
    if (res?.response?.status === 400) {
      throw new Error(res?.response?.data?.message);
    }
    if (res?.response?.data?.error_code) {
      throw new Error(res?.response?.data?.error_code);
    }
    if (res?.code === "ECONNABORTED") {
      throw new Error(res?.code);
    }
    return res;
  });
};

export const getDianData = () => {
  return requestContainer({
    url: "/dian",
  });
};

export const interpretError = (error, errorHandler) => {
  if (error.message === "INVALID_CREDENTIALS") {
    errorHandler.invalidCredentials();
  } else if (error.message === "EXPIRED_CREDENTIALS") {
    errorHandler.expiredCredentials();
  } else if (error.message === "ECONNABORTED") {
    errorHandler.timeout();
  } else if (error.message === "DATA_FAILED") {
    errorHandler.timeout();
  } else if (error.message === "INVALID_FORMAT_CREDENTIALS") {
    errorHandler.invalidFormatCredentials();
  } else if (error.message === "ERROR_EXPIRED_TOKEN") {
    errorHandler.errorExpiredToken();
  } else {
    errorHandler.genericError();
  }
};

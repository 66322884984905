import React from "react";

export const MsgIcon = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M19 10C19 14.9706 14.9706 19 10 19C8.80286 19 7.6603 18.7663 6.61549 18.3419C6.41552 18.2607 6.31554 18.2201 6.23472 18.202C6.15566 18.1843 6.09715 18.1778 6.01613 18.1778C5.9333 18.1778 5.84309 18.1928 5.66265 18.2229L2.10476 18.8159C1.73218 18.878 1.54589 18.909 1.41118 18.8512C1.29328 18.8007 1.19933 18.7067 1.14876 18.5888C1.09098 18.4541 1.12203 18.2678 1.18413 17.8952L1.77711 14.3374C1.80718 14.1569 1.82222 14.0667 1.82221 13.9839C1.8222 13.9028 1.81572 13.8443 1.798 13.7653C1.77988 13.6845 1.73927 13.5845 1.65806 13.3845C1.23374 12.3397 1 11.1971 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      stroke={color ? color : "#667085"}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const styles = {
  inputLabel: { fontSize: "15px", fontWeight: "500" },
  title: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    textAlign: "left",
  },
  subLabel: {
    fontSize: "14px",
  },
};

import { CustomerRow, SortingHeader } from "../commons/Table/components";
import { formatLocaleDate } from "./formatLocaleDate";
import { isNewRow } from "./isNewRow";

const rowIsIncomplete = (row) => {
  const { name, document_type, document_number, email, phone } = row.original;
  return (
    !name ||
    !document_type ||
    !document_number ||
    ((!email || email === "") && (!phone || phone === "+57"))
  );
};

export const collectionsColumns = (setDrawerIsOpen, setSorting, sorting) => [
  {
    field: "name",
    headerName: "Cliente",
    accessor: "name",
    id: "name",
    disableSortBy: true,
    className: "customer-name-column",
    Cell: (column) => {
      const cell = column.cell;
      const row = column.cell.row;
      return (
        <CustomerRow
          itemId={row.original.id}
          isNew={isNewRow(row)}
          setDrawerIsOpen={setDrawerIsOpen}
          cell={cell}
          isIncomplete={rowIsIncomplete(row)}
          linkTo="/collections/index/"
        />
      );
    },
    Header: () => <div className="pill-header">Cliente</div>,
  },
  {
    field: "concept",
    headerName: "Concepto",
    accessor: "concept",
    id: "concept",
    className: "invoice_number-column",
    disableSortBy: true,
    Header: () => <div className="pill-header">Concepto</div>,
    Cell: ({ value }) => {
      if (value) {
        return value.toUpperCase();
      }
    },
  },
  {
    field: "collection_number",
    headerName: "Comprobante",
    accessor: "collection_number",
    id: "collection_number",
    className: "invoice_number-column",
    disableSortBy: true,
    Header: () => <div className="pill-header">Comprobante</div>,
    Cell: ({ value }) => {
      if (value) {
        return value.toUpperCase();
      }
    },
  },
  {
    field: "issue_date",
    headerName: "Fecha de emisión",
    accessor: "issue_date",
    id: "issue_date",
    className: "invoice_dates-column",
    Cell: ({ value }) => {
      if (value) {
        return formatLocaleDate(value.slice(0, -1), "dd MMM, y");
      }
    },
    Header: () => {
      return (
        <SortingHeader
          fieldName="issue_date"
          label="Fecha de emisión"
          setSorting={setSorting}
          sorting={sorting}
        />
      );
    },
  },
  {
    field: "expiration_date",
    headerName: "Fecha de vto.",
    accessor: "expiration_date",
    id: "expiration_date",
    className: "invoice_dates-column",
    Header: () => {
      return (
        <SortingHeader
          fieldName="expiration_date"
          label="Fecha de vto."
          setSorting={setSorting}
          sorting={sorting}
        />
      );
    },
  },
  {
    field: "amount",
    headerName: "Monto",
    accessor: "amount",
    id: "amount",
    className: "amount_total-column",
    Header: () => {
      return (
        <SortingHeader
          className="sorting-amount"
          fieldName="amount"
          label="Monto"
          setSorting={setSorting}
          sorting={sorting}
        />
      );
    },
  },
  {
    field: "status",
    headerName: "Estado",
    accessor: "status",
    id: "status",
    disableSortBy: true,
    Header: () => <div className="pill-header">Estado</div>,
  },
];

import { PrimaryButton } from "commons/buttons";
import { DebtListItem } from "./DebtListItem";
import { DebtListEmptyState } from "./DebtListEmptyState";

const styles = {
  container: {
    minWidth: 405,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "#1D2939",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "28px",
    marginBottom: "32px",
  },
  buttonContainer: {
    marginBottom: "32px",
  },
};

export const DebtsList = ({
  isLoading,
  debts,
  service,
  identifierValue,
  isChecked,
  toggleSelectedRow,
  backToServiceCompanySearch,
}) => {
  const className = isLoading
    ? " delayed-fade-in-with-translation"
    : " fade-out-with-translation";
  if (!isLoading && debts && debts.length === 0) {
    return (
      <DebtListEmptyState
        backToServiceCompanySearch={backToServiceCompanySearch}
      />
    );
  }
  if (isLoading) {
    return (
      <div
        style={styles.container}
        className={`debts-search-container-loading${className}`}
      >
        <div style={styles.title}>Buscando...</div>
        <DebtListItem loading />
        <DebtListItem loading />
      </div>
    );
  }
  return (
    <div style={styles.container}>
      <div style={styles.title}>
        Encontramos las siguientes facturas por pagar
      </div>
      <div style={styles.buttonContainer}>
        <PrimaryButton
          text="Revisar información"
          action={backToServiceCompanySearch}
          width="150px"
        />
      </div>
      {debts.map((debt) => {
        return (
          <DebtListItem
            key={debt.debtId}
            debt={debt}
            serviceCompanyName={service.name}
            serviceCompanyLogoUrl={service.logoUrl}
            identifierValue={identifierValue}
            isChecked={isChecked}
            toggleSelectedRow={toggleSelectedRow}
          />
        );
      })}
    </div>
  );
};

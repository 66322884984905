import {
  ArrowNarrowBottomLeftIcon,
  ArrowNarrowUpRightIcon,
  RectangleIcon,
} from "assets";
import { formatLocaleDate } from "./formatLocaleDate";
import { operationType } from "constants";
import { Circle } from "commons";

export const transactionsColumns = (canDisplayAssistedEgress = false) => [
  {
    field: "firstColumn",
    Header: "",
    headerName: "",
    accessor: "firstColumn",
    id: "firstColumn",
    Cell: ({ row }) => {
      if (row.original.type === "section") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <RectangleIcon
              color={
                row.original.operation_type === "collections"
                  ? "#039855"
                  : "#D92D20"
              }
            />
            <div style={{ marginLeft: "24px" }}>
              {row.original.operation_type === "collections" ? (
                <ArrowNarrowBottomLeftIcon />
              ) : (
                <ArrowNarrowUpRightIcon />
              )}
            </div>
          </div>
        );
      }
      return null;
    },
  },
  {
    field: "operation_type",
    Header: "Tipo de operación",
    headerName: "Tipo de operación",
    accessor: "operation_type",
    id: "operation_type",
    Cell: ({ value, row }) => {
      const hasEgressPending =
        canDisplayAssistedEgress &&
        !row.original?.accounting_receipt_numbers &&
        row.original?.status === "paid";
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {value === "invoice" && hasEgressPending && (
            <Circle
              title="Egreso pendiente"
              placement={"top"}
              fill={"#0086C9"}
              style={{
                marginRight: "30px",
                marginLeft: "-40px",
              }}
              className="egressCircle"
            />
          )}
          {value &&
            (value === "collection"
              ? row.original.beneficiary_type === "receiver"
                ? operationType["collectionReceiver"]
                : operationType["collectionCustomer"]
              : operationType[value])}
        </div>
      );
    },
  },
  {
    field: "beneficiary_name",
    Header: "De/Para",
    headerName: "De/Para",
    accessor: "beneficiary_name",
    id: "beneficiary_name",
    Cell: ({ value }) => {
      if (value) {
        return value;
      }
    },
  },
  {
    field: "total_amount",
    Header: "Monto",
    headerName: "Monto",
    accessor: "total_amount",
    headerClassName: "amount-header",
    id: "total_amount",
  },
  {
    field: "date_hour",
    Header: "Fecha y hora",
    headerName: "Fecha y hora",
    accessor: "date_hour",
    id: "date_hour",
    Cell: ({ row, value }) => {
      if (row.original.date_hour) {
        return row.original.type === "section"
          ? formatLocaleDate(value, "dd/MM/yyyy")
          : formatLocaleDate(value, "d MMM yyyy, h:mm aaaa");
      }
    },
  },
  {
    field: "source_of_funds",
    Header: "Origen/Destino de fondos",
    headerName: "Origen/Destino de fondos",
    accessor: "source_of_funds",
    id: "source_of_funds",
  },
  {
    field: "status",
    Header: "Estado",
    headerName: "Estado",
    accessor: "status",
    id: "status",
  },
];

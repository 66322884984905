import React from "react";
import { Steps } from "intro.js-react";
import { styles } from "../styles";

export const TransactionsScreenIntro = ({ enabled, onComplete, onExit }) => {
  const tutorialSteps = [
    {
      element: ".firstBatch",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Lotes abonados o cobrados</p>
          <p style={styles.tooltipSubtitle}>
            Aquí verás la información completa del lote para que puedas
            compararlo directamente con tu movimiento bancario.
          </p>
        </div>
      ),
      position: "bottom",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
    {
      element: ".firstBatchRow",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Dispersiones y recaudos</p>
          <p style={styles.tooltipSubtitle}>
            En estas filas verás cada pago o cobro a un tercero dentro de cada
            lote.
          </p>
        </div>
      ),
      position: "bottom",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    },
  ];

  document.getElementsByClassName("egressCircle").length > 0 &&
    tutorialSteps.push({
      element: ".egressCircle",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Egresos en Siigo</p>
          <p style={styles.tooltipSubtitle}>
            Si la fila tiene un círculo celeste al inicio, significa que tiene
            registros de pagos pendientes en Siigo. Ingresa al detalle para
            realizarlo.
          </p>
        </div>
      ),
      position: "right",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    });

  tutorialSteps.push({
    element: ".firstBatchRowStatus",
    intro: (
      <div style={styles.column}>
        <p style={styles.tooltipTitle}>Estado de dispersiones</p>
        <p style={styles.tooltipSubtitle}>
          Cada dispersión tiene su estado para que puedas hacer el seguimiento
          completo.
        </p>
      </div>
    ),
    position: "left",
    tooltipClass: "tx-higlight-tooltip",
    highlightClass: "highlight",
  });

  document.getElementsByClassName("firstBatchRowDownloadButton").length > 0 &&
    tutorialSteps.push({
      element: ".firstBatchRowDownloadButton",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>Descarga el soporte</p>
          <p style={styles.tooltipSubtitle}>
            Puedes descargar el soporte de pago desde aquí.
          </p>
        </div>
      ),
      position: "left",
      tooltipClass: "tx-higlight-tooltip",
      highlightClass: "highlight",
    });

  tutorialSteps.push({
    element: ".firstBatchRowDetailsButton",
    intro: (
      <div style={styles.column}>
        <p style={styles.tooltipTitle}>Ver detalle de la transacción</p>
        <p style={styles.tooltipSubtitle}>
          Aquí encontrarás toda la información relevante sobre la dispersión.
        </p>
      </div>
    ),
    position: "left",
    tooltipClass: "tx-higlight-tooltip",
    highlightClass: "highlight",
  });

  return (
    <Steps
      enabled={enabled}
      steps={tutorialSteps}
      initialStep={0}
      onComplete={onComplete}
      onExit={onExit}
      options={{
        showBullets: true,
        prevLabel: "Anterior",
        nextLabel: "Siguiente",
        doneLabel: "Entendido",
        hidePrev: true,
      }}
    />
  );
};
